import { DatePipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Renderer2, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Ein Accessor, um mit Inputs vom typ `datetime-local` als Dates arbeiten zu können.
 *
 * #### Beispiel
 *
 * Template:
 *
 * `<input matInput type="datetime-local" formControlName="time" dateTimeLocalAsDate />`
 *
 * TypeScript:
 *
 * `time = new FormControl<Date | null>(new Date());`
 *
 * #### Vorlage / Idee / Links
 *
 * - https://johanneshoppe.github.io/angular-date-value-accessor/
 * - https://github.com/JohannesHoppe/angular-date-value-accessor/blob/master/workspace/projects/date-value-accessor/src/lib/local-iso-date-value-accessor.ts
 * - https://angular.io/api/forms/ControlValueAccessor
 * - https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
 * - https://developer.mozilla.org/en-US/docs/Web/HTML/Date_and_time_formats#local_date_and_time_strings
 *
 *
 * #### TODO: Tests
 *
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dateTimeLocalAsDate]',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatetimeLocalAccessorDirective), multi: true },
  ],

  standalone: true,
})
export class DatetimeLocalAccessorDirective {
  @HostListener('input', ['$event.target.value']) onInput = (dateString: string) => {
    const date = new Date(dateString);
    this.onChange(date);
  };

  @HostListener('blur', []) onTouched = () => {
    // Kann überschrieben werden
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onChange: any = () => {
    // Kann überschrieben werden
  };

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private datePipe: DatePipe) {}

  writeValue(date?: Date): void {
    const dateTimeLocalString = this.datePipe.transform(date, 'YYYY-MM-ddTHH:mm');
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', dateTimeLocalString);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
}
