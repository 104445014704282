import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, TierschadenDTO } from 'src/app/api/build/openapi';

export const tierschadenActions = createActionGroup({
  source: 'Tierschaeden',
  events: {
    'Reset Store': emptyProps(),

    'Get Tierschaeden': props<{ lageId: string }>(),
    'Get Tierschaeden Success': props<{ tierschadenDTOs: TierschadenDTO[]; lageId: string }>(),
    'Get Tierschaeden Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Tierschaden': props<{ lageId: string; tierschadenDTO: TierschadenDTO }>(),
    'Create Tierschaden Success': props<{ createdTierschadenDTO: TierschadenDTO }>(),
    'Create Tierschaden Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Tierschaden': props<{ lageId: string; tierschadenDTO: TierschadenDTO }>(),
    'Patch Tierschaden Success': props<{ patchedTierschadenDTO: TierschadenDTO }>(),
    'Patch Tierschaden Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Tierschaden': props<{ lageId: string; tierschadenDTO: TierschadenDTO }>(),
    'Delete Tierschaden Success': props<{ deletedTierschadenDTO: TierschadenDTO }>(),
    'Delete Tierschaden Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
