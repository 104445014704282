import { createSelector } from '@ngrx/store';
import { einsatztagebuchLoadedLageSelector } from '../einsatztagebuch/+state/einsatztagebuch.selectors';
import { fuehrungsebenenLoadedLageSelector } from '../lagedarstellung/fuehrungsebene/+state/fuehrungsebene.selectors';
import { lagemassnahmenLoadedLageSelector } from '../lagedarstellung/massnahmen/+state/lage-massnahme.selectors';
import { anlaesseLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/anlass-ereignis/+state/anlass.selectors';
import { befehlsstellenLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/befehlsstelle/+state/befehlsstelle.selectors';
import { fahrzeugeLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/fahrzeuge/+state/fahrzeug.selectors';
import { fotosLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/foto/+state/foto.selectors';
import { gebaeudeLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/gebaeude/+state/gebaeude.selectors';
import { gebieteLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/gebiete/+state/gebiet.selectors';
import { gefahrenLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/gefahren/+state/gefahr.selectors';
import { fuehrungsebeneMassnahmenLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/massnahmen/+state/fuehrungsebene-massnahme.selectors';
import { personenLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/personen/+state/person.selectors';
import { personenschaedenLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/personenschaden/+state/personenschaden.selectors';
import { stellenLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/stelle-einrichtung/+state/stelle.selectors';
import { taktischeFormationenLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/taktische-formation/+state/taktische-formation.selectors';
import { tierschaedenLoadedLageSelector } from '../lagedarstellung/taktische-zeichen/tierschaden/+state/tierschaden.selectors';
import { wetterLoadedLageSelector } from '../lagedarstellung/wetter/+state/wetter.selectors';

/**
 * Meta-Selektor, der die aktuell geladenen Lage-IDs aus den unterschiedlichen States liefert. Die Lage-IDs werden immer gesetzt, wenn der State vollständig geladen ist.
 *
 * Entstanden dadurch, dass sichergestellt sein muss, dass alles Store die gleiche Lage geladen haben.
 * "In absehbarer Zeit" MUSS also in Allen die selbe ID (String) stehen, davon abhängig ist ein Teil der Logik im NATS-Connector.
 * Die Reihenfolge und die Benennung ist irrelevant.
 *
 * https://ngrx.io/guide/store/selectors#using-a-selector-for-one-piece-of-state
 */
export const combinedLoadedLageSelector = createSelector({
  personenLoadedLageSelector,
  fahrzeugeLoadedLageSelector,
  fuehrungsebenenLoadedLageSelector,
  einsatztagebuchLoadedLageSelector,
  lagemassnahmenLoadedLageSelector,
  anlaesseLoadedLageSelector,
  befehlsstellenLoadedLageSelector,
  fotosLoadedLageSelector,
  gebaeudeLoadedLageSelector,
  gebieteLoadedLageSelector,
  gefahrenLoadedLageSelector,
  fuehrungsebeneMassnahmenLoadedLageSelector,
  personenschaedenLoadedLageSelector,
  stellenLoadedLageSelector,
  taktischeFormationenLoadedLageSelector,
  tierschaedenLoadedLageSelector,
  wetterLoadedLageSelector,
});
