import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneDTO, Fuehrungsebenentyp } from 'src/app/api/build/openapi';
import { AuftragHeaderElementComponent } from '../../auftrag/auftrag-header-element/auftrag-header-element.component';
import { fuehrungsebeneActions } from '../../fuehrungsebene/+state/fuehrungsebene.actions';
import { currentFuehrungsebeneSelector } from '../../fuehrungsebene/+state/fuehrungsebene.selectors';
import {
  FuehrungsebeneDetailsDialogComponent,
  FuehrungsebeneDialogData,
} from '../../fuehrungsebene/fuehrungsebene-details-dialog/fuehrungsebene-details-dialog.component';
import { FuehrungsebeneService, FuehrungsebenentypSettings } from '../../fuehrungsebene/fuehrungsebene.service';
import { KontaktService } from '../../kontakt/kontakt.service';

/**
 * Karte zur Darstellung einer einzelnen Führungsebene im Organigramm
 */
@Component({
  selector: 'app-organigramm-fuehrungsebene-card',
  templateUrl: './organigramm-fuehrungsebene-card.component.html',
  styleUrls: ['./organigramm-fuehrungsebene-card.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    NgIf,
    MatToolbarModule,
    NgStyle,
    NgClass,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    AuftragHeaderElementComponent,
    NgFor,
  ],
})
export class OrganigrammFuehrungsebeneCardComponent implements OnInit {
  @Input()
  leitung = false;

  @Input()
  fuehrungsebeneDTO?: FuehrungsebeneDTO;
  currentFuehrungsebene?: FuehrungsebeneDTO;
  // Erlaubt das setzen des currentFuehrungsebenes, falls der Fuehrungsebenentyp es zulässt
  fuehrungsebeneNavigationAllowed = false;

  // Setzen, wenn es möglich sein soll, über ein Plus-Icon, eine neue Führungsebene dieses Typs anzulegen
  @Input()
  allowAddOfType?: Fuehrungsebenentyp;

  // Setzen, wenn es möglich sein soll, über ein Pfeil-Icon, diese Führungsebene als Current-Führungsebene zu setzen
  @Input()
  allowNavigation = true;

  protected fuehrungsebeneService = inject(FuehrungsebeneService);
  protected fuehrungsebenentypSettings?: FuehrungsebenentypSettings;

  constructor(
    private store: Store<AppStateInterface>,
    protected kontaktService: KontaktService,
    private dialog: MatDialog
  ) {
    store
      .select(currentFuehrungsebeneSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((currentFuehrungsebene) => {
        this.currentFuehrungsebene = currentFuehrungsebene || undefined;
      });
  }

  ngOnInit() {
    if (this.fuehrungsebeneDTO?.typ) {
      this.fuehrungsebeneNavigationAllowed =
        this.allowNavigation &&
        this.fuehrungsebeneService.mainFuehrungsebenentypen.includes(this.fuehrungsebeneDTO.typ);

      this.fuehrungsebenentypSettings = this.fuehrungsebeneService.fuehrungsebenentypMapping.get(
        this.fuehrungsebeneDTO.typ
      );
    } else if (this.allowAddOfType) {
      this.fuehrungsebenentypSettings = this.fuehrungsebeneService.fuehrungsebenentypMapping.get(this.allowAddOfType);
    }
  }

  navigateToFuehrungsebene() {
    if (this.fuehrungsebeneNavigationAllowed && this.fuehrungsebeneDTO) {
      this.store.dispatch(
        fuehrungsebeneActions.setCurrentFuehrungsebene({ currentFuehrungsebene: this.fuehrungsebeneDTO })
      );
    }
  }

  add() {
    if (this.allowAddOfType) {
      const newFuehrungsebene = this.fuehrungsebeneService.prepareNewFuehrungsebene(
        this.allowAddOfType,
        this.fuehrungsebeneService.getCurrentLage()?.id
      );
      if (!newFuehrungsebene) {
        console.warn('Es konnte keine Führungsebene vorbereitet werden.');
        return;
      }

      const inputData: FuehrungsebeneDialogData = {
        fuehrungsebeneDTO: { ...newFuehrungsebene },
        filteredTypes: [this.allowAddOfType],
      };
      this.dialog.open(FuehrungsebeneDetailsDialogComponent, {
        data: inputData,
      });
    }
  }

  edit() {
    if (!this.fuehrungsebeneDTO) {
      console.warn('Es konnte keine Führungsebene vorbereitet werden.');
      return;
    }
    const inputData: FuehrungsebeneDialogData = {
      fuehrungsebeneDTO: { ...this.fuehrungsebeneDTO },
    };
    this.dialog.open(FuehrungsebeneDetailsDialogComponent, {
      data: inputData,
    });
  }

  getLeitung(): string {
    const leitung =
      (this.fuehrungsebeneDTO?.leiterName || '') +
      (this.fuehrungsebeneDTO?.leiterName && this.fuehrungsebeneDTO?.leiterDienstgrad ? ', ' : '') +
      (this.fuehrungsebeneDTO?.leiterDienstgrad || '');

    return leitung.length > 0 ? leitung : 'keine';
  }
}
