import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FuehrungsebeneDTO, GebietDTO } from 'src/app/api/build/openapi';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { TaktischesZeichenPopup } from '../../taktische-zeichen.interface';
import { TzPopupToolbarComponent } from '../../tz-popup-toolbar/tz-popup-toolbar.component';
import { GebietService } from '../gebiet.service';

@Component({
  selector: 'app-gebiet-popup',
  standalone: true,
  imports: [CommonModule, FuehrungsebeneIconAndTextComponent, TzPopupToolbarComponent],
  templateUrl: './gebiet-popup.component.html',
  styleUrls: ['./gebiet-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GebietPopupComponent implements OnInit, TaktischesZeichenPopup {
  @Input({ required: true }) dto!: GebietDTO;

  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private gebietService = inject(GebietService);

  protected parentFuehrungsebene?: FuehrungsebeneDTO;
  protected gebietstyp?: string;
  protected gebietsauspraegung?: string;

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.dto.fuehrungsebeneId);

    if (this.dto.gebietstyp) {
      this.gebietstyp = this.gebietService.gebietstypMapping.get(this.dto.gebietstyp);
    }
    if (this.dto.gebietsauspraegung) {
      this.gebietsauspraegung = this.gebietService.gebietsauspraegungMapping.get(this.dto.gebietsauspraegung);
    }
  }
}
