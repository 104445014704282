<mat-toolbar>
  <h2>Einsatztagebuch - Eintrag {{ eintragDTO.id ? "bearbeiten" : "hinzufügen" }}</h2>
</mat-toolbar>

<div class="content">
  <h3 *ngIf="username">{{ username }}</h3>

  <mat-form-field>
    <mat-label>Text</mat-label>
    <textarea matInput [rows]="10" [formControl]="eintragText"></textarea>
    <mat-error>{{ getErrorMessage() }}</mat-error>
  </mat-form-field>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="save()">Speichern</button>
</mat-dialog-actions>
