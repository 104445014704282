import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { personenuebersichtSelector } from '../../fuehrungsebene/+state/fuehrungsebene.selectors';

/**
 * Kleine farbige MANV Komponente zur Anzeige in kleiner Custom-Element Kachel in Monitor 2.
 * Dient nur zur Anzeige der Personenanzahlen in verschiedenen Kategorien.
 */
@Component({
  selector: 'app-manv-small',
  templateUrl: './manv-small.component.html',
  styleUrls: ['./manv-small.component.scss'],
  standalone: true,
  imports: [NgIf, MatToolbarModule, MatCardModule, MatTooltipModule, AsyncPipe],
})
export class ManvSmallComponent {
  private store = inject(Store);
  personenuebersicht$ = this.store.select(personenuebersichtSelector).pipe(takeUntilDestroyed());
}
