import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as L from 'leaflet';
import { Layer } from '../layer.interface';
import { LayerService } from '../layer.service';
import { Tool, ToolType } from '../tool.interface';
import { ToolService } from '../tool.service';
import { ToolHandler } from '../tools/tool-handler';

/**
 * Handler, der sich um die Steuerung der Leaflet Karte kümmert.
 */
@Injectable({
  providedIn: 'root',
})
export class MapHandler {
  // Karte, auf der Objekte angelegt werden
  private map: L.Map | null = null;
  private selectedTool: Tool | null = null;

  /**
   * Mapping von GeoJSON Daten aus dem Backend zu generierten Objekten auf der Karte.
   * Wird benötigt, um die generierten Objekte wieder einzeln entfernen zu können.
   */
  private mapping = new Map<GeoJSON.Feature, L.Layer>();

  constructor(private toolService: ToolService, private layerService: LayerService, private toolHandler: ToolHandler) {
    // Wenn sich die Sichtbarkeit eines Layers ändert, sollen dessen Features ein/ausgeblendet werden
    layerService.layerVisibilityChanged$.pipe(takeUntilDestroyed()).subscribe((layer: Layer) => {
      if (layer.visible) {
        for (const feature of layer.featureCollection.features) {
          this.addFeature(feature);
        }
      } else {
        for (const feature of layer.featureCollection.features) {
          this.removeFeature(feature);
        }
      }
    });

    // Wenn Layer im Service gelöscht werden, entferne Objekte von der Karte
    layerService.layerDeleted$.pipe(takeUntilDestroyed()).subscribe((layer: Layer) => {
      for (const feature of layer.featureCollection.features) {
        this.removeFeature(feature);
      }
    });

    // Ändert sich der Style eines Features, wird es neugezeichnet
    layerService.featureStyleChanged$.pipe(takeUntilDestroyed()).subscribe((feature) => {
      this.removeFeature(feature);
      this.addFeature(feature);
    });

    toolService.selectedTool$.pipe(takeUntilDestroyed()).subscribe((selectedTool) => {
      this.selectedTool = selectedTool;
    });
  }

  init(map: L.Map) {
    this.map = map;
    this.toolHandler.init(this.map);
    /**
     * Mousedown startet die Nutzung des Rectangle-, Circle- und Polygon-Werkzeugs.
     * Initiale Koordinate wird zwischengespeichert und Dragging/Zooming wird disabled.
     */
    this.map.on('mousedown', (event: L.LeafletMouseEvent) => {
      this.toolHandler.createFeature(event);
    });

    /**
     * Mousemove aktualisiert aktuell generiertes Objekt mit neuen Koordinaten.
     */
    this.map.on('mousemove', (event) => {
      this.toolHandler.updateFeature(event);
    });

    /**
     * Mouseup beendet die Nutzung des Rectangle-, Circle- und Polygon-Werkzeugs.
     * Endkoordinaten werden in Objekte gesetzt und Werkzeuge werden für die nächste Nutzung zurückgesetzt.
     */
    this.map.on('mouseup', (event) => {
      switch (this.selectedTool?.type) {
        case ToolType.Line:
        case ToolType.Distance:
        case ToolType.Polygon:
          this.toolHandler.extendFeature(event);
          this.toolService.setShowToolHint(true);
          break;
        case ToolType.Ping:
        case ToolType.Point:
        case ToolType.Rectangle:
        case ToolType.Circle:
        case ToolType.Text: {
          const feature = this.toolHandler.finalizeFeature(event);
          if (feature) {
            this.addFeature(feature);
          }
        }
      }
    });

    // Lines und Polygone mit Rechtsklick abschließen
    this.map.on('contextmenu', (event) => {
      switch (this.selectedTool?.type) {
        case ToolType.Line:
        case ToolType.Distance:
        case ToolType.Polygon: {
          const feature = this.toolHandler.finalizeFeature(event);
          if (feature) {
            this.addFeature(feature);
          }
          this.toolService.setShowToolHint(false);
        }
      }
    });

    this.addLayersToMap();
  }

  /**
   * Erzeugt Karten-Objekte für alle Features aus allen sichtbaren Layern.
   */
  addLayersToMap() {
    for (const layer of this.layerService.layers) {
      if (layer.visible) {
        for (const feature of layer.featureCollection.features) {
          this.addFeature(feature);
        }
      }
    }
  }

  /**
   * Fügt übergebenes GeoJsonObjekt zur Karte hinzu.
   * Sollte ein einzelnes Feature sein, damit der resultierende Layer korrekt im mapping abgelegt wird.
   */
  addFeature(feature: GeoJSON.Feature) {
    const options = { ...ToolHandler.extraMarkerOptions };
    const style = feature.properties ? feature.properties['style'] : undefined;

    if (style) {
      options.style = style;
    }
    const geoLayer = L.geoJSON(feature, options);
    this.map?.addLayer(geoLayer);
    this.mapping.set(feature, geoLayer);
    this.addFeatureListener(geoLayer, feature);
  }

  /**
   * Entfernt übergebenes GeoJsonObjekt von der Karte, wenn es im mapping gefunden wird.
   */
  removeFeature(feature: GeoJSON.Feature) {
    const geoLayer = this.mapping.get(feature);
    if (geoLayer) {
      this.map?.removeLayer(geoLayer);
      this.mapping.delete(feature);
    }
  }

  /**
   * Setzt Listener auf Objekt, damit es per Klick selektiert und mit dem Lösch-Werkzeug entfernt werden kann.
   */
  addFeatureListener(layer: L.Layer, feature: GeoJSON.Feature) {
    layer.on('click', (event) => {
      if (this.selectedTool?.type === ToolType.Remove) {
        this.map?.removeLayer(event.sourceTarget);
      } else {
        this.layerService.setCurrentFeature(feature);
      }
    });
  }

  addLine(line: L.Polyline) {
    this.map?.addLayer(line);
  }

  addLinePoints(startPoint: number[], endPoint: number[]): L.Polyline {
    return this.addLineLatLng(new L.LatLng(startPoint[0], startPoint[1]), new L.LatLng(endPoint[0], endPoint[1]));
  }

  addLineLatLng(startPoint: L.LatLngExpression, endPoint: L.LatLngExpression): L.Polyline {
    const line = new L.Polyline([startPoint, endPoint]);
    this.map?.addLayer(line);
    return line;
  }

  removeLine(line: L.Polyline) {
    this.map?.removeLayer(line);
  }

  /**
   * Positioniert die Karte auf das übergebene Objekt. Hat das Objekt mehrere Koordinaten, wird zum Mittelpunkt positioniert.
   */
  jumpToFeature(feature: GeoJSON.Feature) {
    const geojson = L.geoJSON(feature);
    this.map?.setView(geojson.getBounds().getCenter(), 17);
  }

  containerPointToLatLng(point: number[]): L.LatLng | undefined {
    return this.map?.containerPointToLatLng([point[0], point[1]]);
  }

  getMapLatLngBounds(): L.LatLngBounds | undefined {
    return this.map?.getBounds();
  }

  addEventHandler(action: string, handler: L.LeafletEventHandlerFn) {
    this.map?.on(action, handler);
  }

  public focusGeometry(geometry: GeoJSON.Geometry) {
    if (this.map) {
      MapHandler.setMapViewToGeometry(this.map, geometry);
    }
  }

  public static setMapViewToGeometry(map: L.Map, geometry: GeoJSON.Geometry): boolean {
    switch (geometry.type) {
      case 'Point':
        map.setView(L.latLng(geometry.coordinates[1], geometry.coordinates[0]), 16);
        return true;
      case 'Polygon': {
        const polygonCoords = geometry.coordinates[0].map((position) => L.latLng(position[1], position[0]));
        map.fitBounds(L.polygon(polygonCoords).getBounds());
        return true;
      }
    }
    return false;
  }
}
