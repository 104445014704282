import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, throwError } from 'rxjs';
import { ErrorResponse, LageDTO, LageResourceService } from 'src/app/api/build/openapi';
import { lageActions } from './lage.actions';

@Injectable()
export class LageEffects {
  getLagen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.getLagen),
      mergeMap(() =>
        this.lageResourceSevice.getLagen().pipe(
          map((lagen) => lageActions.getLagenSuccess({ lagen })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(lageActions.getLagenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  createLage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.createLage),
      map((action) => action.newLage),
      switchMap((newLage: LageDTO) =>
        this.lageResourceSevice.createLage(newLage).pipe(
          map((newLage) => lageActions.createLageSuccess({ newLage })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(lageActions.createLageFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Nachdem eine neue Lage hinzugefügt wurde, diese als currentLage setzen,
   * damit automatisch Effects für Führungsebenen und Taktische Zeichen zum Nachladen angestoßen werden
   */
  createLageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.createLageSuccess),
      map((action) => action.newLage),
      switchMap((newLage: LageDTO) => of(lageActions.setCurrentLage({ currentLage: newLage })))
    )
  );

  /**
   * Existierende Lage editieren
   */
  patchLage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.patchLage),
      map((action) => action.patchedLage),
      switchMap((updatedLage: LageDTO) => {
        if (!updatedLage.id) {
          return throwError(() => new Error('Lage muss eine Id besitzen'));
        }
        return this.lageResourceSevice.patchLage(updatedLage.id, updatedLage).pipe(
          map((patchedLage) => lageActions.patchLageSuccess({ patchedLage })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(lageActions.patchLageFailure({ errorResponse }));
          })
        );
      })
    )
  );

  /**
   * Lagezeit einer Lage patchen
   */
  patchLagezeit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.patchLagezeit),
      switchMap((props) =>
        this.lageResourceSevice.patchLagezeit(props.lageId, { lagezeit: props.lagezeit }).pipe(
          map((patchedLage) => lageActions.patchLagezeitSuccess({ patchedLage })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(lageActions.patchLagezeitFailure({ errorResponse }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private lageResourceSevice: LageResourceService) {}
}
