/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Organisation = 'ARBEITER_SAMARITER_BUND' | 'BETRIEBSFEUERWEHR' | 'BUNDESPOLIZEI' | 'BUNDESWEHR' | 'DEUTSCHE_LEBENS_RETTUNGS_GESELLSCHAFT' | 'DEUTSCHES_ROTES_KREUZ' | 'FEUERWEHR' | 'HILFSORGANISATION' | 'JOHANNITER_UNFALL_HILFE' | 'MALTESER_HILFSDIENST' | 'POLIZEI' | 'TECHNISCHES_HILFSWERK' | 'WERKSFEUERWEHR';

export const Organisation = {
    ArbeiterSamariterBund: 'ARBEITER_SAMARITER_BUND' as Organisation,
    Betriebsfeuerwehr: 'BETRIEBSFEUERWEHR' as Organisation,
    Bundespolizei: 'BUNDESPOLIZEI' as Organisation,
    Bundeswehr: 'BUNDESWEHR' as Organisation,
    DeutscheLebensRettungsGesellschaft: 'DEUTSCHE_LEBENS_RETTUNGS_GESELLSCHAFT' as Organisation,
    DeutschesRotesKreuz: 'DEUTSCHES_ROTES_KREUZ' as Organisation,
    Feuerwehr: 'FEUERWEHR' as Organisation,
    Hilfsorganisation: 'HILFSORGANISATION' as Organisation,
    JohanniterUnfallHilfe: 'JOHANNITER_UNFALL_HILFE' as Organisation,
    MalteserHilfsdienst: 'MALTESER_HILFSDIENST' as Organisation,
    Polizei: 'POLIZEI' as Organisation,
    TechnischesHilfswerk: 'TECHNISCHES_HILFSWERK' as Organisation,
    Werksfeuerwehr: 'WERKSFEUERWEHR' as Organisation
};

