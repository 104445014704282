import { ElementRef, Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BubblemenueComponent } from './bubblemenue/bubblemenue.component';

export interface BubbleMenueConfig {
  matIcon: string;
  action?: () => void;
  tooltip?: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
  providedIn: 'root',
})
export class BubblemenueService {
  private currentMenue?: MatDialogRef<BubblemenueComponent>;
  private currentRef?: ViewContainerRef;
  constructor(public dialog: MatDialog) {}

  openMenue(viewRef: ViewContainerRef, config: BubbleMenueConfig[]): Observable<any> {
    this.currentRef = viewRef;

    this.currentRef.element.nativeElement.classList.add('bubble-menu-caller');
    this.currentMenue = this.dialog.open(BubblemenueComponent, {
      data: config,
      hasBackdrop: true,
      viewContainerRef: viewRef,
      position: this.getPositionCircle(viewRef.element),
      panelClass: 'clean-dialog',
      disableClose: false,
      backdropClass: 'clean-dialog-backdrop',
      width: `${this.getBiggerValue(viewRef.element)}px`,
      height: `${this.getBiggerValue(viewRef.element)}px`,
    });

    this.currentMenue.afterClosed().subscribe(() => {
      this.afterClose();
      viewRef.element.nativeElement.classList.remove('bubble-menu-caller');
    });
    return this.currentMenue.afterClosed();
  }

  private afterClose() {
    this.currentRef = undefined;
    this.currentMenue = undefined;
  }

  private getPositionCircle(elementRef: ElementRef<any>): { top?: string; left?: string } {
    const top = elementRef.nativeElement.getBoundingClientRect().top - 2;
    const left = elementRef.nativeElement.getBoundingClientRect().left;
    return { top: `${top}px`, left: `${left}px` };
  }

  private getBiggerValue(elementRef: ElementRef<any>): number {
    const rect = elementRef.nativeElement.getBoundingClientRect();
    return rect.width > rect.height ? rect.width : rect.height;
  }

  /**Auskommentiert, weil zu schön zum wegwerfen und die möglichkeit des graden Menues beibehalten werden  */
  /**private getPosition(elements: number, elementRef: ElementRef<any>): { top?: string; right?: string; left?: string } {
    let proxWidth: number =
      elementRef.nativeElement.getBoundingClientRect().left +
      elements * (elementRef.nativeElement.getBoundingClientRect().width - 16);
    let posTop = elementRef.nativeElement.getBoundingClientRect().top - 50;


    if (proxWidth > window.innerWidth) {
      return {
        top: `${posTop}px`,
        right: `${window.innerWidth - elementRef.nativeElement.getBoundingClientRect().right - 16}px`,
      };
    } else {
      return { top: `${posTop}px`, left: `${elementRef.nativeElement.getBoundingClientRect().left}px` };
    }
  }
  */
}
