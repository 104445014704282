export enum ToolType {
  Cursor,
  Point,
  Line,
  Rectangle,
  Circle,
  Polygon,
  Ping,
  Text,
  Distance,
  Remove,
}

export interface Tool {
  name: string;
  type: ToolType;
  icon: string;
  description?: string;
}

export interface ToolSettings {
  color: string;
  fillOpacity: number;
  weight: number;
}
