<div class="massnahmen">
  <app-stab-massnahmen (clickedReload)="onClickReloadMassnahmen()" />
</div>
<div class="zeitstrahl">
  <app-zeitstrahl
    (elementClicked)="onClickZeitstrtahlElement($event)"
    (elementHovered)="onHoverZeitstrahlElement($event)"
    (elementBeenden)="onElementBeenden($event)"
  />
</div>
