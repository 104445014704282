import { Injectable } from '@angular/core';
import { latLng, LatLng, LeafletMouseEvent, Map, Polygon } from 'leaflet';
import { LayerService } from '../layer.service';
import { ToolSettings } from '../tool.interface';
import { FeatureGenerator } from './tool-handler';

/**
 * Generator für Polygone auf einer Leaflet-Karte über den ToolHandler
 */
@Injectable({
  providedIn: 'root',
})
export class PolygonGenerator implements FeatureGenerator {
  private toolStartCoords: L.LatLng = new LatLng(0, 0);
  private toolPolygon: L.Polygon | null = null;
  private map: Map | null = null;

  constructor(private layerService: LayerService) {}

  init(map: Map): void {
    this.map = map;
  }

  createFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): void {
    this.toolStartCoords = event.latlng;
    if (!this.toolPolygon) {
      this.toolPolygon = new Polygon(
        [
          [
            this.toolStartCoords,
            // Normalerweise 2x toolStartCoords, aber Leaflet filtert scheinbar gleiche Koordinaten bei Polygonen heraus
            latLng(this.toolStartCoords.lat + 0.000001, this.toolStartCoords.lng + 0.000001),
          ],
        ],
        toolSettings
      );
      this.map?.addLayer(this.toolPolygon);
    }
  }

  updateFeature(event: LeafletMouseEvent): void {
    if (this.toolPolygon) {
      const latLngs = this.toolPolygon.getLatLngs()[0] as L.LatLng[];
      latLngs[latLngs.length - 1] = event.latlng;
      this.toolPolygon.setLatLngs([latLngs]);
    }
  }

  extendFeature(event: LeafletMouseEvent): void {
    this.toolPolygon?.addLatLng(event.latlng);
  }

  finalizeFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.toolPolygon) {
      return null;
    }

    const polyCoords: L.LatLng[] = (<L.LatLng[][]>this.toolPolygon.getLatLngs())[0];
    polyCoords.pop();
    this.map?.removeLayer(this.toolPolygon);
    this.toolPolygon = null;
    return this.layerService.addPolygonToCurrentLayer(
      [polyCoords.map((value) => [value.lng, value.lat])],
      toolSettings
    );
  }
}
