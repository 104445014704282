import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EinsatzraumuebersichtDTO, ErrorResponse } from 'src/app/api/build/openapi';
import { FuehrungsebeneDTO } from 'src/app/api/build/openapi/model/fuehrungsebeneDTO';
import { PersonenuebersichtDTO } from 'src/app/api/build/openapi/model/personenuebersichtDTO';

/* Aktionen, die für einen State ausgelöst werden können */
export const fuehrungsebeneActions = createActionGroup({
  source: 'Fuehrungsebenen',
  events: {
    'Reset Store': emptyProps(),

    'Get Fuehrungsebenen': props<{ lageId: string }>(),
    'Get Fuehrungsebenen Success': props<{ fuehrungsebenen: FuehrungsebeneDTO[]; loadedLageId: string }>(),
    'Get Fuehrungsebenen Failure': props<{ errorResponse: ErrorResponse }>(),

    'Set Current Fuehrungsebene': props<{ currentFuehrungsebene: FuehrungsebeneDTO | null }>(),
    'Set Current Fuehrungsebene Id': props<{ currentFuehrungsebeneId: string }>(),

    'Create Fuehrungsebene': props<{ fuehrungsebene: FuehrungsebeneDTO }>(),
    'Create Fuehrungsebene Success': props<{ createdFuehrungsebene: FuehrungsebeneDTO }>(),
    'Create Fuehrungsebene Success Do Nothing': emptyProps(),
    'Create Fuehrungsebene Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Fuehrungsebene': props<{ fuehrungsebene: FuehrungsebeneDTO }>(),
    'Patch Fuehrungsebene Success': props<{ patchedFuehrungsebene: FuehrungsebeneDTO }>(),
    'Patch Fuehrungsebene Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Fuehrungsebene': props<{ fuehrungsebene: FuehrungsebeneDTO }>(),
    'Delete Fuehrungsebene Success': props<{ deletedFuehrungsebeneDTO: FuehrungsebeneDTO }>(),
    'Delete Fuehrungsebene Failure': props<{ errorResponse: ErrorResponse }>(),

    'Get Personenuebersicht': props<{ fuehrungsebene: FuehrungsebeneDTO }>(),
    'Get Personenuebersicht Success': props<{ personenuebersicht: PersonenuebersichtDTO }>(),
    'Get Personenuebersicht Failure': props<{ errorResponse: ErrorResponse }>(),

    'Get Personenuebersicht Lage': props<{ lageId: string }>(),
    'Get Personenuebersicht Lage Success': props<{ personenuebersicht: PersonenuebersichtDTO }>(),
    'Get Personenuebersicht Lage Failure': props<{ errorResponse: ErrorResponse }>(),

    'Get Einsatzraumuebersicht Lage': props<{ lageId: string }>(),
    'Get Einsatzraumuebersicht Lage Success': props<{ einsatzraumuebersicht: EinsatzraumuebersichtDTO[] }>(),
    'Get Einsatzraumuebersicht Lage Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
