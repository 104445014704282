import { NgClass, NgFor } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Tool, ToolSettings, ToolType } from '../tool.interface';
import { ToolService } from '../tool.service';

@Component({
  selector: 'app-lagedarstellung-sidebar-tools',
  templateUrl: './lagedarstellung-sidebar-tools.component.html',
  styleUrls: ['./lagedarstellung-sidebar-tools.component.scss'],
  standalone: true,
  imports: [MatButtonToggleModule, NgFor, MatTooltipModule, MatIconModule, MatCardModule, NgClass, MatSliderModule],
})
export class LagedarstellungSidebarToolsComponent implements OnInit, AfterViewInit {
  private destroyRef = inject(DestroyRef);

  protected readonly tools: Tool[];
  protected selectedTool: Tool | null = null;
  public showToolHint = false;

  // Buttons zum Toggeln der Settings-Panels
  colorSettingsToggle: Element | null = null;
  opacitySettingsToggle: Element | null = null;
  weightSettingsToggle: Element | null = null;

  // Settings-Panels
  colorSettings: Element | null = null;
  opacitySettings: Element | null = null;
  weightSettings: Element | null = null;

  // Aktuelle Sichtbarkeit der verschiedenen Settings-Panels
  colorSettingsVisible = false;
  opacitySettingsVisible = false;
  weightSettingsVisible = false;

  toolSettings: ToolSettings | null = null;
  readonly toolColors: string[] = [];

  constructor(private toolService: ToolService) {
    this.tools = this.toolService.tools;
    this.toolColors = this.toolService.toolColors;

    // Listener, der offene Settings-Panels schließt, wenn irgendwo außerhalb geklickt
    document.addEventListener('click', (event) => {
      const target = event.target as Node;
      if (
        this.colorSettingsVisible &&
        !this.colorSettings?.contains(target) &&
        !this.colorSettingsToggle?.contains(target)
      ) {
        this.toggleSettingVisibility('color');
      } else if (
        this.opacitySettingsVisible &&
        !this.opacitySettings?.contains(target) &&
        !this.opacitySettingsToggle?.contains(target)
      ) {
        this.toggleSettingVisibility('opacity');
      } else if (
        this.weightSettingsVisible &&
        !this.weightSettings?.contains(target) &&
        !this.weightSettingsToggle?.contains(target)
      ) {
        this.toggleSettingVisibility('weight');
      }
    });
  }

  ngOnInit(): void {
    this.toolService.selectedTool$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((selectedTool) => {
      this.selectedTool = selectedTool;
    });

    this.toolService.toolSettings$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((toolSettings) => {
      this.toolSettings = toolSettings;
    });
  }

  ngAfterViewInit() {
    this.colorSettingsToggle = document.querySelector('#color-settings-toggle');
    this.opacitySettingsToggle = document.querySelector('#opacity-settings-toggle');
    this.weightSettingsToggle = document.querySelector('#weight-settings-toggle');
    this.colorSettings = document.querySelector('#color-settings');
    this.opacitySettings = document.querySelector('#opacity-settings');
    this.weightSettings = document.querySelector('#weight-settings');
  }

  selectTool(event: MatButtonToggleChange) {
    this.toolService.selectTool(event.value);
    this.showToolHint = this.selectedTool?.type == ToolType.Line || this.selectedTool?.type == ToolType.Polygon;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  changeToolSetting(setting: string, value: any) {
    this.toolService.changeToolSetting(setting, value);
  }

  /**
   * Toggelt die Sichtbarkeit der übergebenen ToolSettings.
   * Werden die Settings sichtbar, werden alle anderen Settings ausgeblendet.
   */
  toggleSettingVisibility(setting: string) {
    if (setting === 'color') {
      this.colorSettingsVisible = !this.colorSettingsVisible;
    } else if (setting === 'opacity') {
      this.opacitySettingsVisible = !this.opacitySettingsVisible;
    } else if (setting === 'weight') {
      this.weightSettingsVisible = !this.weightSettingsVisible;
    }
    this.hideOtherSettings(setting);
  }

  private hideOtherSettings(setting: string) {
    if (setting === 'color') {
      this.opacitySettingsVisible = false;
      this.weightSettingsVisible = false;
    } else if (setting === 'opacity') {
      this.colorSettingsVisible = false;
      this.weightSettingsVisible = false;
    } else if (setting === 'weight') {
      this.colorSettingsVisible = false;
      this.opacitySettingsVisible = false;
    }
  }

  formatOpacity(value: number): string {
    return `${value * 100}%`;
  }

  formatWeight(value: number): string {
    return `${value}px`;
  }
}
