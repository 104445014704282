import { createReducer, on } from '@ngrx/store';
import { stabMassnahmenActions } from './stabmassnahmen.actions';
import { StabMassnahmenStateInterface } from './stabmassnahmenState.interface';

const initialState: StabMassnahmenStateInterface = {
  isLoading: false,
  isAdding: false,
  isPatching: false,
  currentMassnahmen: [],
  errorResponse: undefined,
};

export const stabMassnahmenReducer = createReducer(
  initialState,
  //Get Massnahmen
  on(stabMassnahmenActions.getStabMassnahmen, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(stabMassnahmenActions.getStabMassnahmenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    currentMassnahmen: action.massnahmen,
    errorResponse: undefined,
  })),
  on(stabMassnahmenActions.getStabMassnahmenFailure, (state, action) => ({
    ...state,
    isLoadingInformation: false,
    errorResponse: action.errorResponse,
  })),
  on(stabMassnahmenActions.addStabMassnahme, (state) => ({ ...state, isAdding: true, errorResponse: undefined })),
  on(stabMassnahmenActions.addStabMassnahmeSuccess, (state, action) => ({
    ...state,
    isAdding: false,
    currentMassnahmen: [...state.currentMassnahmen, action.massnahme],
    errorResponse: undefined,
  })),
  on(stabMassnahmenActions.addStabMassnahmeFailure, (state, action) => ({
    ...state,
    isAdding: false,
    errorResponse: action.errorResponse,
  })),
  on(stabMassnahmenActions.patchStabMassnahme, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(stabMassnahmenActions.patchStabMassnahmeSuccess, (state, action) => {
    const massnahmen = state.currentMassnahmen.map((massnahme) =>
      massnahme.id === action.patchedMassnahme.id ? action.patchedMassnahme : massnahme
    );
    return { ...state, currentMassnahmen: massnahmen, isPatching: false, errorResponse: undefined };
  }),
  on(stabMassnahmenActions.patchStabMassnahmeFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  }))
);
