import { Injectable } from '@angular/core';

/**
 *
 * UUID des Clients, mit der Events gefiltert werden können.
 *
 * Wird bei jedem HTTP Rrequest gesetzt (via AuthInterceptor).
 *
 * Wird ein Event mit dieser ID empfangen, kann dieses ignoriert werden,
 * da es durch sich selbst ausgelöst wude.
 */
@Injectable({
  providedIn: 'root',
})
export class IseSourceIdService {
  private iseSourceId: string;

  constructor() {
    this.iseSourceId = crypto.randomUUID();
  }

  getIseSourceId() {
    return this.iseSourceId;
  }
}
