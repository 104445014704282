<mat-toolbar>
  <h2>{{ "Lage" + (lageDTO.id ? " bearbeiten" : " hinzufügen") }}</h2>
</mat-toolbar>

<mat-dialog-content>
  <app-fuehrungsebene-details-panel [fuehrungsebeneDTO]="lageDTO">
    <app-chip-list-input [control]="fcStichworte" label="Stichworte" itemName="Stichworte" class="mb-1" />
  </app-fuehrungsebene-details-panel>
</mat-dialog-content>

<div class="footer">
  <app-last-updated *ngIf="lageDTO.id" [lastUpdated]="lageDTO" />
  <mat-dialog-actions class="end">
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="accent" (click)="save()" class="mr-05">Speichern</button>
  </mat-dialog-actions>
</div>
