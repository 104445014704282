import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuehrungsebeneDTO, TaktischesZeichenStatus, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TaktischeZeichenContainerComponent } from '../../taktische-zeichen/taktische-zeichen-container/taktische-zeichen-container.component';
import { FuehrungsebeneHeaderComponent } from '../fuehrungsebene-header/fuehrungsebene-header.component';

@Component({
  selector: 'app-grundschutz',
  templateUrl: './grundschutz.component.html',
  styleUrls: ['./grundschutz.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FuehrungsebeneHeaderComponent,
    TaktischeZeichenContainerComponent,
  ],
})
export class GrundschutzComponent {
  @Input()
  grundschutz: FuehrungsebeneDTO | undefined;
  @Input()
  readonly allowedTzTypes: TaktischesZeichenTyp[] = [
    TaktischesZeichenTyp.Fahrzeug,
    TaktischesZeichenTyp.Person,
    TaktischesZeichenTyp.TaktischeFormation,
  ];

  @Output()
  createGrundschutzClicked: EventEmitter<null> = new EventEmitter();
  @Output()
  editClicked: EventEmitter<FuehrungsebeneDTO> = new EventEmitter();

  TaktischesZeichenStatus = TaktischesZeichenStatus;

  createGrundschutz() {
    this.createGrundschutzClicked.emit();
  }

  edit() {
    if (this.grundschutz) {
      this.editClicked.emit(this.grundschutz);
    }
  }
}
