<mat-card>
  <mat-toolbar>Lagebesprechung</mat-toolbar>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-dialog-content>
      <div class="flex-col">
        <mat-form-field>
          <mat-label>nächste Lagebesprechung</mat-label>
          <input matInput type="datetime-local" formControlName="time" dateTimeLocalAsDate />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Erinnerung</mat-label>
          <mat-select formControlName="notification">
            <mat-option [value]="option" *ngFor="let option of reminderOptions">
              {{ option.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-checkbox formControlName="erledigt">erledigt</mat-checkbox>

        <div *ngIf="erledigt.value">
          <mat-checkbox formControlName="folgebesprechung">Folgebesprechung anlegen</mat-checkbox>

          <div class="flex-col" *ngIf="folgebesprechung.value">
            <p>Minuten bis zur nächsten Besprechung:</p>

            <mat-form-field>
              <mat-label>Minuten</mat-label>
              <input matInput type="number" formControlName="naechsteBesprechung" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-raised-button color="warn" matTooltip="Bricht die angesetzte Lagebesprechung ab." (click)="cancel()">
        Löschen
      </button>
      <button mat-raised-button>Abbrechen</button>
      <button mat-raised-button color="accent" type="submit">Speichern</button>
    </mat-dialog-actions>
  </form>
</mat-card>
