/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TaktischeFormationSelectableInfoType = 'NEW_FOR_BIB' | 'NEW_FOR_LAGE' | 'EXISTING';

export const TaktischeFormationSelectableInfoType = {
    NewForBib: 'NEW_FOR_BIB' as TaktischeFormationSelectableInfoType,
    NewForLage: 'NEW_FOR_LAGE' as TaktischeFormationSelectableInfoType,
    Existing: 'EXISTING' as TaktischeFormationSelectableInfoType
};

