import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { VerwaltungsebenenTreeComponent } from '../verwaltungsebenen-tree/verwaltungsebenen-tree.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { Store } from '@ngrx/store';
import { verwaltungsebenenSelector } from '../+state/bibliothek.selectors';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { BibliothekZeichenService } from '../bibliothek-zeichen.service';
import { BibTzDTO } from '../verwaltungsebene-details/verwaltungsebene-details.component';

export interface DialogData {
  taktischesZeichen: TaktischesZeichenDTO;
}

@Component({
  selector: 'app-bibliothek-zeichen-move',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, VerwaltungsebenenTreeComponent, MatDialogModule, MatButtonModule],
  templateUrl: './bibliothek-zeichen-move.component.html',
  styleUrls: ['./bibliothek-zeichen-move.component.scss'],
})
export class BibliothekZeichenMoveComponent {
  verwaltungsebenen$ = this.store.select(verwaltungsebenenSelector);

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
    private dialogRef: MatDialogRef<BibliothekZeichenMoveComponent>,
    private store: Store<AppStateInterface>,
    private bibZeichenService: BibliothekZeichenService
  ) {}

  moveZeichen = (verwaltungsebeneId: string) => {
    // ist das hier mit dem BibTz richtig?!
    const dtoToUpdate: BibTzDTO = { ...(this.dialogData.taktischesZeichen as BibTzDTO), verwaltungsebeneId };

    this.bibZeichenService.saveBibZeichenDto(dtoToUpdate);
    // TODO irgendwann irgendwie warten?
    this.dialogRef.close();
  };
}
