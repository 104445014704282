<div class="form-container">
  <form [formGroup]="formGroup" class="actual-form">
    <mat-card>
      <mat-card-subtitle>Zeichen Informationen</mat-card-subtitle>
      <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />

      <div class="radio-group">
        <mat-label>Art der Stelle/Einrichtung</mat-label>
        <mat-radio-group [formControl]="fcGrundzeichenId">
          <mat-radio-button value="stelle">Mobil</mat-radio-button>
          <mat-radio-button value="ortsfeste-stelle">Ortsfest</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field
        class="less-margin"
        (mouseover)="organisationMouseover = true"
        (mouseleave)="organisationMouseover = false"
      >
        <mat-label>Organisation</mat-label>
        <mat-select [formControl]="fcOrganisation">
          <mat-option *ngFor="let organisation of organisationValues" [value]="organisation">
            {{ organisation.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="fcOrganisation.value && organisationMouseover"
          matSuffix
          mat-icon-button
          (click)="fcOrganisation.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
        class="less-margin"
        (mouseover)="fachaufgabeMouseover = true"
        (mouseleave)="fachaufgabeMouseover = false"
      >
        <mat-label>Fachaufgabe</mat-label>
        <mat-select [formControl]="fcFachaufgabe">
          <mat-option *ngFor="let fachaufgabe of fachaufgabeValues" [value]="fachaufgabe">
            {{ fachaufgabe.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="fcFachaufgabe.value && fachaufgabeMouseover"
          matSuffix
          mat-icon-button
          (click)="fcFachaufgabe.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="less-margin" (mouseover)="symbolMouseover = true" (mouseleave)="symbolMouseover = false">
        <mat-label>Symbol</mat-label>
        <mat-select [formControl]="fcSymbol">
          <mat-option *ngFor="let symbol of symbolValues" [value]="symbol">
            {{ symbol.label }}
          </mat-option>
        </mat-select>
        <button *ngIf="fcSymbol.value && symbolMouseover" matSuffix mat-icon-button (click)="fcSymbol.reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Text</mat-label>
        <input matInput [formControl]="fcZeichenText" />
        <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['maxlength']">{{
          getErrorMessage(fcZeichenText)
        }}</mat-error>
        <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['pattern']"
          >Keine Umlaute oder Sonderzeichen</mat-error
        >
      </mat-form-field>
    </mat-card>

    <mat-card>
      <mat-card-subtitle>Information zur Stelle/Einrichtung</mat-card-subtitle>
      <app-cleanable-form-field label="Ortsangabe" [formGroup]="formGroup" formControlName="ortsangabe" />

      <app-tz-combobox
        label="Leiter"
        formControlName="leiter"
        [formGroup]="formGroup"
        [data$]="leiterValues$"
        [displayedFields]="['anzeigename']"
      />

      <app-tz-combobox
        label="Befehlsstelle"
        formControlName="befehlsstelle"
        [formGroup]="formGroup"
        [data$]="befehlsstelleValues$"
        [displayedFields]="['anzeigename']"
      />
    </mat-card>
  </form>

  <app-kontakt-list [kommunikationOptionen]="stelleToEdit?.kommunikationOptionen || []" />
  <app-auftrag-list />
</div>
