import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { PersonenschadenDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { Manv } from 'src/app/lagedarstellung/triage/manv.interface';
import { ManvComponent } from 'src/app/lagedarstellung/triage/manv/manv.component';
import { Personen } from 'src/app/lagedarstellung/triage/personen.interface';
import { PersonenuebersichtSubsetComponent } from 'src/app/lagedarstellung/triage/personenuebersicht-subset/personenuebersicht-subset.component';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { TaktischesZeichen, erzeugeTaktischesZeichen } from 'taktische-zeichen-core';
import { DataUrlChangeEvent } from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-personenschaden-form',
  templateUrl: './personenschaden-form.component.html',
  styleUrls: ['./personenschaden-form.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    CleanableFormFieldComponent,
    PersonenuebersichtSubsetComponent,
    ManvComponent,
  ],
})
export class PersonenschadenFormComponent implements TaktischesZeichenForm {
  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  dataUrlChanged = new EventEmitter<DataUrlChangeEvent>();

  @ViewChild('personenBevComponent')
  personenBevComponent!: PersonenuebersichtSubsetComponent;

  @ViewChild('personenEkComponent')
  personenEkComponent!: PersonenuebersichtSubsetComponent;

  @ViewChild('manvComponent')
  manvComponent!: ManvComponent;

  showVerletzteError = false;
  showToteError = false;

  readonly EMPTY_ZEICHEN: TaktischesZeichen = { grundzeichen: 'person' };
  editorZeichen: TaktischesZeichen = { ...this.EMPTY_ZEICHEN };
  currentZeichenDataUrl: string = erzeugeTaktischesZeichen(this.editorZeichen).dataUrl;
  customZeichen = false;

  dtoToEdit?: PersonenschadenDTO;

  private formBuilder = inject(NonNullableFormBuilder);
  fcAnzeigename = this.formBuilder.control('', [Validators.maxLength(30), Validators.required]);
  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
  });

  personenschadenBev: Personen = {
    verletzt: 0,
    tot: 0,
    vermisst: 0,
    betroffen: 0,
    gerettet: 0,
    transportiert: 0,
  };

  personenschadenEk: Personen = {
    verletzt: 0,
    tot: 0,
    vermisst: 0,
    betroffen: 0,
    gerettet: 0,
    transportiert: 0,
  };

  manv: Manv = {
    category1: 0,
    category2: 0,
    category3: 0,
    category4: 0,
    dead: 0,
  };

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });
  }

  setCustomTz(dataUrl: string) {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
      this.customZeichen = true;
    } else {
      this.customZeichen = false;
      this.generateTaktischesZeichen();
    }
  }

  setDTO(dto: TaktischesZeichenDTO) {
    this.dtoToEdit = dto as PersonenschadenDTO;
    if (this.dtoToEdit) {
      this.dtoToFormGroup(this.dtoToEdit);
      if (this.dtoToEdit.customZeichen && this.dtoToEdit.dataUrl) {
        this.generateTaktischesZeichen(this.dtoToEdit.dataUrl);
        return;
      }
    }
    this.generateTaktischesZeichen();
  }

  /**
   * Aktualisiertes GefahrDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): PersonenschadenDTO | null {
    if (
      !this.formGroup.valid ||
      !this.personenBevComponent.isValid() ||
      !this.personenEkComponent.isValid() ||
      !this.manvComponent.isValid()
    ) {
      this.formGroup.markAllAsTouched();
      return null;
    }

    const personenBev = this.personenBevComponent.getDto();
    const personenEk = this.personenEkComponent.getDto();
    const manv = this.manvComponent.getDto();

    this.showVerletzteError =
      manv.category1 + manv.category2 + manv.category3 + manv.category4 > personenBev.verletzt + personenEk.verletzt;
    this.showToteError = manv.dead > personenBev.tot + personenEk.tot;

    if (this.showToteError || this.showVerletzteError) {
      return null;
    }

    return {
      ...this.dtoToEdit,
      anzeigename: this.fcAnzeigename?.value.trim(),
      bevVerletzt: personenBev.verletzt,
      bevTot: personenBev.tot,
      bevVermisst: personenBev.vermisst,
      bevBetroffen: personenBev.betroffen,
      bevGerettet: personenBev.gerettet,
      bevTransportiert: personenBev.transportiert,
      ekVerletzt: personenEk.verletzt,
      ekTot: personenEk.tot,
      ekVermisst: personenEk.vermisst,
      ekBetroffen: personenEk.betroffen,
      ekGerettet: personenEk.gerettet,
      ekTransportiert: personenEk.transportiert,
      manv1: manv.category1,
      manv2: manv.category2,
      manv3: manv.category3,
      manv4: manv.category4,
      manvTote: manv.dead,
      dataUrl: this.currentZeichenDataUrl,
      customZeichen: this.customZeichen,
      typ: TaktischesZeichenTyp.Personenschaden,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(dto: PersonenschadenDTO): void {
    this.fcAnzeigename.setValue(dto.anzeigename);
    this.personenschadenBev = {
      verletzt: dto.bevVerletzt || 0,
      tot: dto.bevTot || 0,
      vermisst: dto.bevVermisst || 0,
      betroffen: dto.bevBetroffen || 0,
      gerettet: dto.bevGerettet || 0,
      transportiert: dto.bevTransportiert || 0,
    };

    this.personenschadenEk = {
      verletzt: dto.ekVerletzt || 0,
      tot: dto.ekTot || 0,
      vermisst: dto.ekVermisst || 0,
      betroffen: dto.ekBetroffen || 0,
      gerettet: dto.ekGerettet || 0,
      transportiert: dto.ekTransportiert || 0,
    };

    this.manv = {
      category1: dto.manv1 || 0,
      category2: dto.manv2 || 0,
      category3: dto.manv3 || 0,
      category4: dto.manv4 || 0,
      dead: dto.manvTote || 0,
    };
    this.customZeichen = dto.customZeichen || false;
  }

  /**
   * DataURL an Parent senden und dort aktualisieren.
   */
  generateTaktischesZeichen(dataUrl = '') {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
    } else {
      const zeichen = erzeugeTaktischesZeichen(this.editorZeichen);
      this.currentZeichenDataUrl = zeichen.dataUrl;
    }

    this.dataUrlChanged.emit({ dataUrl: this.currentZeichenDataUrl, customZeichen: this.customZeichen });
  }
}
