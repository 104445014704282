/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Bereich = 'LEITER_DES_STABES' | 'KGS' | 'BUMA' | 'SMS' | 'EMS' | 'STABSHILFSPERSONAL';

export const Bereich = {
    LeiterDesStabes: 'LEITER_DES_STABES' as Bereich,
    Kgs: 'KGS' as Bereich,
    Buma: 'BUMA' as Bereich,
    Sms: 'SMS' as Bereich,
    Ems: 'EMS' as Bereich,
    Stabshilfspersonal: 'STABSHILFSPERSONAL' as Bereich
};

