<div class="custom-bar" *ngIf="currentLage$ | async as currentLage" [style.flexBasis.%]="customCollapsed ? 0 : 20">
  <mat-card class="custom-element">
    <div class="lage-info">
      <mat-toolbar>Einsatzbeginn</mat-toolbar>
      <h1 class="lage-info-content">{{ currentLage.startedOn ? (currentLage.startedOn | dateTime | async) : "" }}</h1>
    </div>
    <div class="lage-info">
      <mat-toolbar>
        Lagebesprechung
        <div class="align-right">
          <mat-icon class="gray">{{ (notificationTime$ | async) ? "notifications" : "notifications_off" }}</mat-icon>
          <button mat-icon-button (click)="openLagebesprechungDialog()" matTooltip="Lagebesprechung bearbeiten">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </mat-toolbar>
      <div class="lage-info-content">
        <h1>{{ nextLagebesprechung$ | async }}</h1>
      </div>
    </div>
  </mat-card>

  <mat-card class="custom-element">
    <div class="lage-info">
      <mat-toolbar>Einsatzort</mat-toolbar>
      <h1 class="lage-info-content">{{ currentLage.adresse }}</h1>
    </div>

    <div class="lage-info">
      <mat-toolbar>Einsatzstichworte</mat-toolbar>
      <div class="lage-info-content">
        <mat-chip-set style="min-width: unset">
          <mat-chip *ngFor="let stichwort of currentLage.stichworte"> {{ stichwort }}</mat-chip>
        </mat-chip-set>
      </div>
    </div>
  </mat-card>

  <mat-card class="custom-element">
    <button mat-icon-button [disabled]="true">
      <mat-icon>add</mat-icon>
    </button>
  </mat-card>

  <mat-card class="custom-element">
    <button mat-icon-button [disabled]="true">
      <mat-icon>add</mat-icon>
    </button>
  </mat-card>

  <mat-card class="custom-element">
    <button mat-icon-button [disabled]="true">
      <mat-icon>add</mat-icon>
    </button>
  </mat-card>
</div>

<!-- Button zum ein-/ausklappen der Custom-Elemente -->
<mat-icon
  class="custom-collapse"
  (click)="toggleCollapsed()"
  [matTooltip]="'Custom-Elemente ' + (customCollapsed ? 'ausklappen' : 'einklappen')"
  >{{ customCollapsed ? "expand_more" : "expand_less" }}
</mat-icon>
<div class="content-container">
  <mat-card id="organigram">
    <app-organigramm></app-organigramm>
  </mat-card>
  <mat-card id="massnahmen">
    <app-massnahmen></app-massnahmen>
  </mat-card>
</div>
