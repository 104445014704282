<div class="container">
  <mat-toolbar class="header">
    <h2>Personenschäden</h2>
  </mat-toolbar>

  <div class="charts">
    <mat-card class="dashboard-container">
      <div class="chart-container">
        <canvas id="personenchart" #personenchart role="img"></canvas>
      </div>
    </mat-card>

    <mat-card class="manvchart-container">
      <canvas id="manvchart" #manvchart role="img"></canvas>
    </mat-card>
  </div>
</div>
