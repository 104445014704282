import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { LageDTO } from 'src/app/api/build/openapi';
import { lagenSelector } from './+state/lage.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class LageService {
  private lagen: LageDTO[] = [];

  constructor() {
    inject(Store<AppStateInterface>)
      .select(lagenSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((lagen) => (this.lagen = lagen));
  }

  public getLageById(lageId: string): LageDTO | undefined {
    return this.lagen.find((lage) => lage.id === lageId);
  }
}
