/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AnalogTonruf = 'TONRUF1' | 'TONRUF2';

export const AnalogTonruf = {
    Tonruf1: 'TONRUF1' as AnalogTonruf,
    Tonruf2: 'TONRUF2' as AnalogTonruf
};

