import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  iif,
  map,
  switchMap,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { LageDTO, LagebesprechungDTO, LagebesprechungResourceService } from 'src/app/api/build/openapi';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { currentLageSelector } from '../lagen/+state/lage.selectors';

const LAGEBESPRECHUNG_NOTIFIED = 'lagebesprechungNotified';
const LAGEBESPRECHUNG = 'lagebesprechung';

@Injectable({
  providedIn: 'root',
})
export class LagebesprechungService {
  // ID der aktuell selektierten Lage
  private lageId$ = this.store.select(currentLageSelector).pipe(
    filter((lage): lage is LageDTO => lage?.id !== undefined),
    map((v) => v.id)
  );

  // Die aktuelle nächste Lagebesprechung
  nextLagebesprechung$ = new BehaviorSubject<LagebesprechungDTO | undefined>(undefined);

  // Timer, um regelmäßig zu prüfen, ob an eine Lagebesprechung erinnert werden muss.
  checkTimer$ = timer(0, 10_000).pipe(switchMap(() => this.nextLagebesprechung$.pipe(distinctUntilChanged())));

  constructor(
    private lagebesprechungResourceService: LagebesprechungResourceService,
    private store: Store<AppStateInterface>,
    snackbarService: SnackbarService
  ) {
    // Bei Selektion und Wechsel einer Lge die Lagebsprechung laden
    this.lageId$
      .pipe(
        takeUntilDestroyed(),
        switchMap((id) => {
          if (!id) {
            return throwError(() => new Error('Keine LageId gefunden'));
          }
          return this.lagebesprechungResourceService.getLastLagebesprechungByLageId(id);
        }),
        tap((bespr) => this.updateLagebesprechung(bespr))
      )
      .subscribe();

    // Prüfung, ob an Lagebesprechung erinnert werden muss.
    combineLatest([this.checkTimer$, this.nextLagebesprechung$])
      .pipe(takeUntilDestroyed())
      .subscribe(([_, lagebesprechung]) => {
        if (lagebesprechung && lagebesprechung.zeitpunkt) {
          const now = new Date();
          const nextLagebesprechung = new Date(lagebesprechung.zeitpunkt);

          const sessionLagebesprechung = sessionStorage.getItem(LAGEBESPRECHUNG);
          if (!sessionLagebesprechung) {
            sessionStorage.setItem(LAGEBESPRECHUNG, nextLagebesprechung.toISOString());
          }

          const notified = sessionStorage.getItem(LAGEBESPRECHUNG_NOTIFIED) === 'true';

          if (sessionLagebesprechung) {
            const sessionLagebesprechungDate = new Date(sessionLagebesprechung);
            if (sessionLagebesprechungDate === nextLagebesprechung && notified) {
              return;
            } else if (sessionLagebesprechungDate.getTime() !== nextLagebesprechung.getTime()) {
              sessionStorage.setItem(LAGEBESPRECHUNG, nextLagebesprechung.toISOString());
              sessionStorage.setItem(LAGEBESPRECHUNG_NOTIFIED, 'false');
            }
          }

          const reminderTimeInMillis = (lagebesprechung.erinnerungInMinuten || 0) * 60 * 1000;
          const diff = nextLagebesprechung.getTime() - now.getTime();
          const notify = diff <= reminderTimeInMillis && diff > -1;

          if (notify && !notified) {
            snackbarService.showDismissable('Lagebesprechung anstehend!', 'OK');
            sessionStorage.setItem(LAGEBESPRECHUNG_NOTIFIED, 'true');
          }
        }
      });
  }

  // Aktualisiert die Lagebesprechung. Z.B. auch bei Event
  updateLagebesprechung(bespr: LagebesprechungDTO) {
    this.nextLagebesprechung$.next(bespr);
  }

  cancelLagebesprechung(lagebesprechung: LagebesprechungDTO) {
    const toCancel: LagebesprechungDTO = { ...lagebesprechung, status: LagebesprechungDTO.StatusEnum.Abgebrochen };
    return this.updateDto$(toCancel);
  }

  /**
   *
   * @param dto  neue DTO Daten, LageID wird mit aktueller überschrieben
   */
  createOrUpdateLagebesprechung(lagebesprechung: LagebesprechungDTO) {
    return this.lageId$.pipe(
      map((lageId) => {
        if (!lagebesprechung.lageId) {
          return { ...lagebesprechung, lageId: lageId } as LagebesprechungDTO;
        }
        return lagebesprechung;
      }),
      switchMap((lagebesprechung) =>
        iif(
          () => !lagebesprechung.id || lagebesprechung.id.length === 0,
          this.createNewDto$(lagebesprechung),
          this.updateDto$(lagebesprechung)
        )
      ),
      tap((v) => {
        this.nextLagebesprechung$.next(v);
      })
    );
  }

  private createNewDto$ = (newLagebesprechung: LagebesprechungDTO) => {
    return this.lagebesprechungResourceService.createLagebesprechung(newLagebesprechung.lageId, newLagebesprechung);
  };

  private updateDto$ = (updateLagebesprechung: LagebesprechungDTO) => {
    if (!updateLagebesprechung.id) {
      return throwError(() => new Error('Lagebesprechung muss zum Patchen eine Id besitzen'));
    }
    return this.lagebesprechungResourceService.patchLagebesprechung(
      updateLagebesprechung.lageId,
      updateLagebesprechung.id,
      updateLagebesprechung
    );
  };
}
