import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneDTO, Fuehrungsebenentyp } from 'src/app/api/build/openapi';
import { fuehrungsebeneActions } from '../+state/fuehrungsebene.actions';
import { AuftragHeaderElementComponent } from '../../auftrag/auftrag-header-element/auftrag-header-element.component';
import { MapHandler } from '../../base-map/map-handler';
import { KontaktIconsComponent } from '../../kontakt/kontakt-icons/kontakt-icons.component';
import {
  FuehrungsebeneDetailsDialogComponent,
  FuehrungsebeneDialogData,
} from '../fuehrungsebene-details-dialog/fuehrungsebene-details-dialog.component';
import { FuehrungsebeneIconAndTextComponent } from '../fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService, FuehrungsebenentypSettings } from '../fuehrungsebene.service';

@Component({
  selector: 'app-fuehrungsebene-popup',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatToolbarModule,
    KontaktIconsComponent,
    AuftragHeaderElementComponent,
    FuehrungsebeneIconAndTextComponent,
  ],
  templateUrl: './fuehrungsebene-popup.component.html',
  styleUrls: ['./fuehrungsebene-popup.component.scss'],
})
export class FuehrungsebenePopupComponent implements OnInit {
  @Input({ required: true })
  fuehrungsebeneDTO!: FuehrungsebeneDTO;

  @Output()
  actionExecuted = new EventEmitter<void>();

  protected parentFuehrungsebene?: FuehrungsebeneDTO;
  allowNavigation = false;

  private store = inject(Store<AppStateInterface>);
  private mapHandler = inject(MapHandler);
  private dialog = inject(MatDialog);
  private fuehrungsebeneService = inject(FuehrungsebeneService);
  protected fuehrungsebenentypSettings?: FuehrungsebenentypSettings;

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.fuehrungsebeneDTO.parentFuehrungsebeneId);
    this.allowNavigation =
      this.fuehrungsebeneService.mainFuehrungsebenentypen.includes(this.fuehrungsebeneDTO.typ) &&
      this.parentFuehrungsebene?.typ !== Fuehrungsebenentyp.LogistikEinsatzabschnitt &&
      this.parentFuehrungsebene?.typ !== Fuehrungsebenentyp.LogistikEinsatzstelle;
    this.fuehrungsebenentypSettings = this.fuehrungsebeneService.fuehrungsebenentypMapping.get(
      this.fuehrungsebeneDTO.typ
    );
  }

  navigateToFuehrungsebene() {
    if (this.allowNavigation) {
      this.store.dispatch(
        fuehrungsebeneActions.setCurrentFuehrungsebene({ currentFuehrungsebene: this.fuehrungsebeneDTO })
      );
      this.actionExecuted.emit();
    }
  }

  focusFuehrungsebene() {
    if (this.fuehrungsebeneDTO && this.fuehrungsebeneDTO.geometry) {
      this.mapHandler.focusGeometry(this.fuehrungsebeneDTO.geometry as GeoJSON.Geometry);
      this.actionExecuted.emit();
    }
  }

  editFuehrungsebene() {
    const inputData: FuehrungsebeneDialogData = {
      fuehrungsebeneDTO: { ...this.fuehrungsebeneDTO },
    };

    this.dialog
      .open(FuehrungsebeneDetailsDialogComponent, {
        data: inputData,
      })
      .afterClosed()
      .subscribe(() => this.actionExecuted.emit());
  }
}
