<form>
  <h3>{{ header }}</h3>

  <!-- Straße, Hausnummer, Objekt -->
  <span class="flex-row gap-1">
    <mat-form-field class="flex-3">
      <mat-label>Straße</mat-label>
      <input matInput [formControl]="fcStrasse" readonly />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Hausnr.</mat-label>
      <input matInput [formControl]="fcHausnummer" readonly />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Objekt</mat-label>
      <input matInput [formControl]="fcObjekt" readonly />
    </mat-form-field>
  </span>

  <!-- Plz, Gemeinde(-teil) -->
  <span class="flex-row gap-1">
    <mat-form-field class="flex-1">
      <mat-label>PLZ</mat-label>
      <input matInput [formControl]="fcPlz" readonly />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Gemeinde</mat-label>
      <input matInput [formControl]="fcGemeinde" readonly />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Gemeindeteil</mat-label>
      <input matInput [formControl]="fcGemeindeteil" readonly />
    </mat-form-field>
  </span>

  <!-- Land, Bundesland -->
  <span class="flex-row gap-1">
    <mat-form-field class="flex-1">
      <mat-label>Land</mat-label>
      <input matInput [formControl]="fcLand" readonly />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Bundesland</mat-label>
      <input matInput [formControl]="fcBundesland" readonly />
    </mat-form-field>
  </span>

  <span class="flex-row gap-1">
    <mat-form-field class="flex-1">
      <mat-label>Regierungsbezirk</mat-label>
      <input matInput [formControl]="fcRegierungsbezirk" readonly />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Landkreis</mat-label>
      <input matInput [formControl]="fcLandkreis" readonly />
    </mat-form-field>
  </span>

  <span class="flex-row gap-1">
    <mat-form-field class="flex-1">
      <mat-label>Kreisfreie Stadt</mat-label>
      <input matInput [formControl]="fcKreisfreieStadt" readonly />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Verbandsgemeinde</mat-label>
      <input matInput [formControl]="fcVerbandsgemeinde" readonly />
    </mat-form-field>
  </span>
</form>
