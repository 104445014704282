export enum VisibilityProperty {
  GESAMT = 'allVisible',
  GEOMETRIE = 'geometryVisible',
  POLITISCH_GESAMTVERANTWORTLICH = 'politischGesamtverantwortlichVisible',
  ADMINISTRATIV_ORGANISATORISCH = 'administrativOrganisatorischVisible',
  OPERATIV_TAKTISCH = 'operativTaktischVisible',
  LEITSTELLE = 'leitstelleVisible',
  BEREITSTELLUNGSRAUM = 'bereitstellungsraeumeVisible',
  GRUNDSCHUTZ = 'grundschutzVisible',
  LOGISTIK = 'logistikVisible',
  OERTLICH = 'oertlichVisible',
  FAHRZEUG = 'fahrzeugeVisible',
  PERSON = 'personenVisible',
  MASSNAHME = 'massnahmenVisible',
  GEFAHR = 'gefahrenVisible',
  BEFEHLSSTELLE = 'befehlsstellenVisible',
  GEBAEUDE = 'gebaeudeVisible',
  ANLASS = 'anlaesseVisible',
  STELLE = 'stellenVisible',
  TAKTISCHE_FORMATION = 'taktischeFormationenVisible',
  PERSONENSCHADEN = 'personenschaedenVisible',
  GEBIET = 'gebieteVisible',
  FOTO = 'fotosVisible',
  TIERSCHADEN = 'tierschaedenVisible',
  EINSATZ = 'einsaetzeVisible',
}
