<a
  mat-button
  [routerLink]="['/planung/voreinstellungen']"
  [ngClass]="{ 'nav-selected': router.url === '/planung/voreinstellungen' }"
>
  Voreinstellungen
</a>

<a
  mat-button
  [routerLink]="['/planung/bibliothek']"
  ngClass="nav-selected"
  [ngClass]="{ 'nav-selected': router.url === '/planung/bibliothek' }"
>
  Bibliothek
</a>

<a
  mat-button
  [routerLink]="['/planung/karte']"
  ngClass="nav-selected"
  [ngClass]="{ 'nav-selected': router.url === '/planung/karte' }"
>
  Karte
</a>

<a mat-button [routerLink]="['/planung/kontakte']" [ngClass]="{ 'nav-selected': router.url === '/planung/kontakte' }"
  >Kontakte
</a>

<!-- [ngClass]="{ 'nav-selected': router.url === '/lagen', 'nav-disabled': !(currentLage$ | async) }" -->
