import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { loginPath } from '../app.routes';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated$.pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          return of(authenticated);
        } else {
          return of(this.router.createUrlTree([loginPath]));
        }
      }),
      catchError(() => {
        return of(this.router.createUrlTree([loginPath]));
      })
    );
  }

  constructor(private authService: AuthService, private router: Router) {}
}
