import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-informationen-navigation',
  standalone: true,
  imports: [CommonModule, RouterModule, MatButtonModule],
  templateUrl: './informationen-navigation.component.html',
  styleUrls: ['./informationen-navigation.component.scss'],
})
export class InformationenNavigationComponent {
  protected router = inject(Router);
}
