import { AsyncPipe } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { EinsatzDTO, FuehrungsebeneDTO } from 'src/app/api/build/openapi';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { IconAndTextComponent } from 'src/app/shared/components/icon-and-text/icon-and-text.component';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { EinsatzDialogComponent, EinsatzDialogInputData } from '../einsatz-dialog/einsatz-dialog.component';
import { EinsatzService } from '../einsatz.service';

@Component({
  selector: 'app-einsatz-popup',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    IconAndTextComponent,
    FuehrungsebeneIconAndTextComponent,
    DateTimePipe,
    AsyncPipe,
  ],
  templateUrl: './einsatz-popup.component.html',
  styleUrl: './einsatz-popup.component.scss',
})
export class EinsatzPopupComponent {
  private dialog = inject(MatDialog);
  protected einsatzService = inject(EinsatzService);

  @Input({ required: true })
  einsatzDto!: EinsatzDTO;

  @Input({ required: true })
  parentFuehrungsebeneDto!: FuehrungsebeneDTO;

  openEinsatzDialog() {
    const inputData: EinsatzDialogInputData = {
      einsatzDTO: this.einsatzDto,
    };
    this.dialog.open(EinsatzDialogComponent, { data: inputData });
  }
}
