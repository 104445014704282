import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, filter, from, map } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { LageDTO } from 'src/app/api/build/openapi';
import { currentLageSelector } from '../lagen/+state/lage.selectors';

const STORAGEKEY_MULTIWINDOW = 'LAGE-useMultiWindowSetup';

const CLOSING_EVENT = 'closing';

@Injectable({
  providedIn: 'root',
})
export class WindowManagerService {
  private destroyRef = inject(DestroyRef);

  hasUserPermission$ = new BehaviorSubject<boolean>(false);

  //** Das aktuelle/eigene Fenster */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private window = window as any;

  /**
   * Die aktuell erkannten Monitor-Detials.
   * **Abfrage erfordert Nutzer-Berechtigung!** */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private screenDetails$ = new BehaviorSubject<any>(undefined);

  /** Der Bildschrim, auf dem die Anwendung geöffnet wurde */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private currentScreen: any = undefined;

  /** Array aller Biltschirm-Details*/
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private screens: any[] = [];

  /** Monitor-Einstellungen neuladen */
  //@ts-ignore 'ScreenDetails' sind unbekannt
  private updateScreenDetails$: Observable<ScreenDetails>;

  // externe Fenster, die geöffnet sind
  private secondaryWindows: Window[] = [];

  private bc?: BroadcastChannel;

  private userSettingMultiWindow$ = new BehaviorSubject<boolean>(
    localStorage.getItem(STORAGEKEY_MULTIWINDOW) === 'true' || false
  );

  /** Flag, ob die "Lagedarstellung" in drei separaten Fenstern (`true`) oder in drei Tabs (`false`) dargestellt werden soll */
  useMultiWindowSetup$ = new BehaviorSubject<boolean>(false);
  currentLage?: LageDTO;

  constructor(private router: Router, private store: Store<AppStateInterface>) {
    navigator.permissions
      //@ts-ignore
      .query({ name: 'window-management' })
      .then((status) => {
        if (status.state === 'denied') {
          // TODO bessere Warnung für Nutzer?
          console.warn("LAGE: Erlaubnis zur 'Fensterverwaltung' NICHT erhalten. 3-Monitor-Modus nicht möglich.");
        } else if (status.state === 'prompt') {
          // console.log("LAGE:  Erlaubnis zur 'Fensterverwaltung' steht aus ...");
        }

        if (status.state === 'granted') {
          this.hasUserPermission$.next(true);
          this.init();
        }
      })
      .catch((err) => console.error('LAGE: Permissions konnten nicht geprüft werden!', err));

    this.store
      .select(currentLageSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((l) => (this.currentLage = l || undefined));
  }

  /**
   * Initialisierung des WindowManagers.
   *
   * !!! 'Permissions' müssen vorher geprüft worden sein!
   *
   */
  private init() {
    this.updateScreenDetails$ = from(this.window.getScreenDetails()).pipe(
      map((details) => this.screenDetails$.next(details))
    );

    this.bc = new BroadcastChannel('ScreenBroadcast');
    this.bc.onmessage = (event) => {
      // Wenn "closing" event von sekunärem Fenster kommt und es in diesem Fenster keinen "Opener" gibt = Hauptfenster
      if (event.data == CLOSING_EVENT && this.window.opener == null) {
        this.closeSecondaryWindows();
      }
    };

    // Event-Listener für das Schließen des aktuellen Fenster. Sekundäre Fenster haben derzeit eigene Instanz. D.h. alle Fenster/Popups haben eigenen "WindowManager"
    // Sekunäre Fenster schließen, wenn das Hauptfenster geschlossen wird.
    addEventListener('beforeunload', (_) => {
      // Wenn Hauptfenster geschlossen wird, müssen die sekunären Fenster geschlossen werden.
      if (this.window.opener == null) {
        this.closeSecondaryWindows();

        if (this.bc) {
          this.bc.postMessage(CLOSING_EVENT);
        }
      }
    });

    // update user setting in local storage
    this.userSettingMultiWindow$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((v) => {
      localStorage.setItem(STORAGEKEY_MULTIWINDOW, '' + v);
    });

    this.screenDetails$
      .pipe(
        filter((v) => v != undefined),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((screenDetails) => {
        this.currentScreen = screenDetails.currentScreen;
        this.screens = screenDetails.screens;
      });

    this.updateScreenDetails$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((_) => {
      if (this.isWindowsDetected() && this.screens.length > 2) {
        // initialer Wert, wenn 3 Bildschirme erkannt
        this.useMultiWindowSetup$.next(this.userSettingMultiWindow$.getValue());
      }
    });
  }

  /** Wurden grundsätzlich Bildschirme erkannt? */
  private isWindowsDetected = (): boolean => this.currentScreen && this.screens.length != 0;

  /**
   * Umschalten zwischen drei Fenster- oder Tab-Ansicht für die Lagedarstellung
   */
  toggleUseMultiWindowSetup() {
    if (!this.hasUserPermission$.value) {
      return;
    }

    this.userSettingMultiWindow$.next(!this.userSettingMultiWindow$.getValue());

    this.updateScreenDetails$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (_) => {
        this.useMultiWindowSetup$.next(!this.useMultiWindowSetup$.getValue());

        if (this.useMultiWindowSetup$.getValue() && this.router.url == '/lagedarstellung') {
          this.openSecondaryWindows();
        } else {
          this.closeSecondaryWindows();
        }
      },
      error: (_) => {
        if (!this.isWindowsDetected()) {
          if (this.useMultiWindowSetup$.getValue()) {
            this.useMultiWindowSetup$.next(false);
          }
          return;
        }
      },
    });
  }
  /**
   * Sekundäre Fenster öffnen, wenn genügend Monitore vorhanden bzw. in Menü ausgewählt
   */
  openSecondaryWindows() {
    if (!this.hasUserPermission$.value) {
      return;
    }

    if (this.useMultiWindowSetup$.getValue()) {
      this.updateScreenDetails$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (_) => {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const screens: any[] = this.findSecondaryScreens() || [this.currentScreen, this.currentScreen];

        if (this.currentLage?.id) {
          await this.openNewPopup(`/popup/res/${this.currentLage.id}`, this.getSecondaryWindowOptions(screens[0]));
          this.openNewPopup(`/popup/org/${this.currentLage.id}`, this.getSecondaryWindowOptions(screens[1]));
        }
      });
    }
  }

  /** Alle geöffneten sekunänren Fenster schließen */
  closeSecondaryWindows() {
    if (!this.hasUserPermission$.value) {
      return;
    }

    this.secondaryWindows.forEach((w) => w.close());
    this.secondaryWindows = [];
    this.useMultiWindowSetup$.next(false);
  }

  /** Öffnet sekundäre Fenster und "merkt" sich diese */
  private async openNewPopup(url: string | URL, options: string) {
    const popup = await window.open(url, '' + url, options);

    if (popup) {
      this.secondaryWindows.push(popup);
    }
  }

  /** Optionen für sekundäre Fenster anhand von `ScreenDetailed` bestimmen */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private getSecondaryWindowOptions(screen: any): string {
    const offset = 75;

    let posOffset = 0;
    if (screen == this.currentScreen) {
      posOffset += offset * (this.secondaryWindows.length + 1);
    }

    const left = screen.availLeft + posOffset;
    const top = screen.availTop + posOffset;
    const width = screen.availWidth - posOffset;
    const height = screen.availHeight - posOffset;

    return 'left=' + +left + ',top=' + top + ',width=' + width + ',height=' + height;
  }

  /**
   * Werden 3 oder mehr Bildschirme erkannt, wird versucht, die "besten" Monitore für die "Sekundärfenster" auszuwähen.
   *
   * Als "beste" Monitore werden jene Zwei angenommen, die unmittelbar neben dem Bildschrim liegen, auf dem LAGE geöffnet wurde (Origin).
   *
   *  Im Optimalfall, liegt "Origin" zwischen zwei Monitoren. Als sekundäre Monitore werden dann der jeweils linke und rechte Monitor geliefert
   *
   * Ist "Origin" der erste bzw. letzte Monitor, dann werden die zwei nächsten bzw. vorherigen Monitore geliefert.
   *
   * @returns Array mit zwei (2) Werten von `ScreenDetailed` als `any`, für die zu verwendenden sekundären Monitore. Reihenfolge "links nach rechts". Undefined, falls keine oder weniger als drei (3) Monitore erkannt wurden.
   */
  // Gibt bestimmt einen pfiffigeren Algorithmus oder Vorgehen...
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private findSecondaryScreens(): any[] | undefined {
    // keine Bildschirme ODER weniger als Drei erkannt
    if (!this.isWindowsDetected() || this.screens.length < 3) {
      return undefined;
    }

    const currentScreenIndex = this.screens.indexOf(this.currentScreen);

    /**
     *
     * | M1 | M2 | M3 | ...
     *   *     1    2
     *
     * M1: origin
     * M2: sec 1
     * M3: sec 2
     *
     */
    if (currentScreenIndex == 0) {
      return this.screens.slice(1, 3);
    }

    /**
     *
     * ... | M3 | M4 | M5 |
     *       1    2    *
     * M3: sec 1
     * M4: sec 2
     * M5: origin
     *
     */
    if (currentScreenIndex == this.screens.length - 1) {
      return this.screens.slice(currentScreenIndex - 2, currentScreenIndex);
    }

    /**
     *
     * ... | M2 | M3 | M4 | ...
     *       1    *    2
     * M2: sec 1
     * M3: origin
     * M4: sec 2
     *
     *
     * end: + 1 + 1, da erster zu ignorierender Index verwendet werden muss
     * filter, da current hier enthalten sein würde
     */
    return this.screens
      .slice(currentScreenIndex - 1, currentScreenIndex + 1 + 1)
      .filter((screen) => screen != this.currentScreen);
  }
}
