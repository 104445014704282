<mat-card>
  <mat-toolbar class="manv-header">
    <h3>MANV-Sichtung</h3>
  </mat-toolbar>

  <form class="columns" *ngIf="manv" [formGroup]="formGroup">
    <div class="column headers">
      <div class="column-data">
        <h5>Verletzte</h5>
      </div>
      <div class="column-header">
        <h5>Sichtungskat.</h5>
      </div>
    </div>

    <mat-card class="column">
      <div class="column-data">
        <h3 *ngIf="disabled">{{ category1.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" min="0" [formControl]="category1" />
        </mat-form-field>
      </div>
      <div class="column-header category1">
        <h2>I</h2>
        <h5>Akute, vitale Bedrohung</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h3 *ngIf="disabled">{{ category2.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" min="0" [formControl]="category2" />
        </mat-form-field>
      </div>
      <div class="column-header category2">
        <h2>II</h2>
        <h5>Schwer verletzt/<wbr />erkrankt</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h3 *ngIf="disabled">{{ category3.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" min="0" [formControl]="category3" />
        </mat-form-field>
      </div>
      <div class="column-header category3">
        <h2>III</h2>
        <h5>Leicht verletzt/<wbr />erkrankt</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h3>{{ (manv.category1 || 0) + (manv.category2 || 0) + (manv.category3 || 0) }}</h3>
      </div>
      <div class="column-header sum" matTooltip="Summe aus Kategorien I, II, III">
        <h3>Summe</h3>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h3 *ngIf="disabled">{{ category4.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" min="0" [formControl]="category4" />
        </mat-form-field>
      </div>
      <div class="column-header category4">
        <h2>IV</h2>
        <h5>Ohne Überlebenschance</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h3 *ngIf="disabled">{{ dead.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" min="0" [formControl]="dead" [readonly]="disabled" />
        </mat-form-field>
      </div>
      <div class="column-header dead">
        <h3>Tote</h3>
      </div>
    </mat-card>
  </form>
</mat-card>
