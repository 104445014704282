import { Injectable } from '@angular/core';
import { Circle, LatLng, latLngBounds, LeafletMouseEvent, Map } from 'leaflet';
import { LayerService } from '../layer.service';
import { ToolSettings } from '../tool.interface';
import { FeatureGenerator } from './tool-handler';

/**
 * Generator für Kreise auf einer Leaflet-Karte über den ToolHandler
 */
@Injectable({
  providedIn: 'root',
})
export class CircleGenerator implements FeatureGenerator {
  private toolStartCoords: L.LatLng = new LatLng(0, 0);
  private toolCircle: Circle | null = null;
  private map: Map | null = null;

  constructor(private layerService: LayerService) {}

  init(map: Map): void {
    this.map = map;
  }

  createFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): void {
    this.toolStartCoords = event.latlng;
    this.toolCircle = new Circle(event.latlng, {
      radius: 0,
      ...toolSettings,
    });
    this.map?.addLayer(this.toolCircle);
  }

  updateFeature(event: LeafletMouseEvent): void {
    this.toolCircle?.setLatLng(latLngBounds(this.toolStartCoords, event.latlng).getCenter());
    this.toolCircle?.setRadius(this.toolStartCoords.distanceTo(event.latlng) / 2);
  }

  extendFeature(event: LeafletMouseEvent): void {
    // Do nothing
  }

  finalizeFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.toolCircle) {
      return null;
    }
    const circleCoords = this.toolCircle.getLatLng();
    const radius = this.toolCircle.getRadius();
    this.map?.removeLayer(this.toolCircle);
    this.toolCircle = null;
    return this.layerService.addCircleToCurrentLayer([circleCoords.lng, circleCoords.lat], radius, toolSettings);
  }
}
