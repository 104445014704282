import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'natoZuluDateTime',
    standalone: true,
})
export class NatoZuluDateTimePipe implements PipeTransform {
  constructor(private date: DatePipe) {}

  /**
   * NATO-Zulu-Zeit => UTC im NATO-Format  https://de.wikipedia.org/wiki/Datum/Zeit-Gruppe
   */
  transform(value: Date): string {
    const dayHoursMinutes = this.date.transform(value, 'ddHHmm', 'UTC');
    const monthYear = this.date.transform(value, 'MMM YY', 'UTC')?.toLocaleLowerCase();

    return dayHoursMinutes && monthYear ? `${dayHoursMinutes}Z ${monthYear}` : '';
  }
}
