import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { AnlassResourceService, ErrorResponse } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { anlassActions } from './anlass.actions';

@Injectable()
export class AnlassEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Anlässe anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return anlassActions.getAnlaesse({ lageId: props.currentLage.id });
        } else {
          return anlassActions.resetStore();
        }
      })
    )
  );

  /**
   * Lädt alle Anlässe einer Lage
   */
  getAnlaesse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anlassActions.getAnlaesse),
      mergeMap((props) =>
        this.anlaesseResourceService.getAnlaesseByLageId(props.lageId).pipe(
          map((anlaesse) => anlassActions.getAnlaesseSuccess({ anlassDTOs: anlaesse, lageId: props.lageId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(anlassActions.getAnlaesseFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Legt einen neuen Anlass an.
   */
  createAnlass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anlassActions.createAnlass),
      mergeMap((props) => {
        return this.anlaesseResourceService.createAnlass(props.lageId, props.anlassDTO).pipe(
          map((createdAnlassDTO) => anlassActions.createAnlassSuccess({ createdAnlassDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(anlassActions.createAnlassFailure({ errorResponse }));
          })
        );
      })
    )
  );

  /**
   * Patcht einen existierenden Anlass
   */
  patchAnlass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anlassActions.patchAnlass),
      mergeMap((props) => {
        if (!props.anlassDTO.id) {
          return throwError(() => new Error('Anlass muss eine Id besitzen.'));
        }
        return this.anlaesseResourceService.patchAnlass(props.anlassDTO.id, props.lageId, props.anlassDTO).pipe(
          map((patchedAnlassDTO) => anlassActions.patchAnlassSuccess({ patchedAnlassDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(anlassActions.patchAnlassFailure({ errorResponse }));
          })
        );
      })
    )
  );

  deleteAnlass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anlassActions.deleteAnlass),
      mergeMap((props) => {
        if (!props.anlassDTO.id) {
          return throwError(() => new Error('Anlass muss eine Id besitzen.'));
        }
        return this.anlaesseResourceService.deleteAnlass(props.anlassDTO.id, props.lageId).pipe(
          map(() => anlassActions.deleteAnlassSuccess({ deletedAnlassDTO: props.anlassDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(anlassActions.createAnlassFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private anlaesseResourceService: AnlassResourceService) {}
}
