import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { StabMassnahmeDTO, FuehrungsebeneMassnahmeDTO } from 'src/app/api/build/openapi';
import { currentLageSelector } from 'src/app/lagedarstellung/lagen/+state/lage.selectors';
import { fuehrungsebeneMassnahmeActions } from 'src/app/lagedarstellung/taktische-zeichen/massnahmen/+state/fuehrungsebene-massnahme.actions';
import { fuehrungsebeneMassnahmenSelector } from 'src/app/lagedarstellung/taktische-zeichen/massnahmen/+state/fuehrungsebene-massnahme.selectors';
import { stabMassnahmenActions } from '../stab-massnahmen/+state/stabmassnahmen.actions';
import { currentStabMassnahmenSelector } from '../stab-massnahmen/+state/stabmassnahmen.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface ZeitstrahlElement {
  id: string;
  positionInProzent: number;
  massnahme?: StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO;
  top: boolean;
  dataUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ZeitstrahlService {
  private store: Store<AppStateInterface> = inject(Store);
  private lageId: string | undefined = '';

  stabMassnahmen$: BehaviorSubject<StabMassnahmeDTO[]> = new BehaviorSubject<StabMassnahmeDTO[]>([]);
  abschnittMassnahmen$: BehaviorSubject<FuehrungsebeneMassnahmeDTO[]> = new BehaviorSubject<
    FuehrungsebeneMassnahmeDTO[]
  >([]);

  constructor() {
    this.store.pipe(select(currentLageSelector), takeUntilDestroyed()).subscribe((lage) => (this.lageId = lage?.id));

    this.store
      .select(fuehrungsebeneMassnahmenSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((abschnittMassnahmen) => this.abschnittMassnahmen$.next(abschnittMassnahmen));

    this.store
      .select(currentStabMassnahmenSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((stabMassnahmen) => this.stabMassnahmen$.next(stabMassnahmen));
  }

  reload() {
    if (this.lageId) {
      this.store.dispatch(stabMassnahmenActions.getStabMassnahmen({ lageId: this.lageId }));
      this.store.dispatch(fuehrungsebeneMassnahmeActions.getFuehrungsebeneMassnahmen({ lageId: this.lageId }));
    }
  }

  static isStabMassnahme(massnahme: StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO) {
    return !('dataUrl' in massnahme);
  }

  static isAbschnitt(massnahme: StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO) {
    return 'dataUrl' in massnahme;
  }
}
