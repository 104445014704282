<form [formGroup]="formGroup">
  <mat-card>
    <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />

    <app-cleanable-form-field
      label="Beschreibung"
      [formGroup]="formGroup"
      formControlName="beschreibung"
      [useTextarea]="true"
    />

    <div class="radio-group">
      <mat-label>Art der Gefahr</mat-label>
      <mat-radio-group [formControl]="fcGefahrtyp">
        <mat-radio-button value="gefahr">Normal</mat-radio-button>
        <mat-radio-button value="gefahr-akut">Akut</mat-radio-button>
        <mat-radio-button value="gefahr-vermutet">Vermutet</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field class="less-margin" (mouseover)="symbolMouseover = true" (mouseleave)="symbolMouseover = false">
      <mat-label>Symbol</mat-label>
      <mat-select [formControl]="fcSymbol">
        <mat-option *ngFor="let symbol of symbolValues" [value]="symbol">
          {{ symbol.label }}
        </mat-option>
      </mat-select>
      <button *ngIf="fcSymbol.value && symbolMouseover" matSuffix mat-icon-button (click)="fcSymbol.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="less-margin">
      <mat-label>Farbe</mat-label>
      <mat-select [formControl]="fcColor">
        <mat-option *ngFor="let farbe of farben" [value]="farbe.farbe">
          {{ farbe.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Text</mat-label>
      <input matInput [formControl]="fcZeichenText" />
      <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['maxlength']">{{
        getErrorMessage(fcZeichenText)
      }}</mat-error>
      <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['pattern']"
        >Keine Umlaute oder Sonderzeichen</mat-error
      >
    </mat-form-field>

    <div>
      <h4>Schadenumfang</h4>
      <div class="schadenumfang">
        <mat-checkbox class="checkbox" [formControl]="fcSchadenumfangMenschen">Menschen</mat-checkbox><br />
        <mat-checkbox class="checkbox" [formControl]="fcSchadenumfangTiere">Tiere</mat-checkbox><br />
        <mat-checkbox class="checkbox" [formControl]="fcSchadenumfangUmwelt">Umwelt</mat-checkbox><br />
        <mat-checkbox class="checkbox" [formControl]="fcSchadenumfangSachwerte">Sachwerte</mat-checkbox><br />
      </div>
    </div>
  </mat-card>
</form>
