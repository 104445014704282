import { createSelector } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState.personen;

export const personenSelector = createSelector(selectFeature, (state) => state.personen);
export const personenErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingPersonenSelector = createSelector(selectFeature, (state) => state.isLoading);
export const personenLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const personenOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.personen.filter((person) => person.fuehrungsebeneId === fuehrungsebeneId)
  );
