import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { Chart } from 'chart.js';
import { EinsatzraumDataSet } from '../kennzahlen-dashboad.service';

@Component({
  selector: 'app-chart-container',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './chart-container.component.html',
  styleUrls: ['./chart-container.component.scss'],
})
export class ChartContainerComponent implements AfterViewInit {
  @Input()
  dataset?: EinsatzraumDataSet;

  @ViewChild('einsatzstellenchart')
  canvas!: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit(): void {
    const ctx = this.canvas.nativeElement;
    new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Anlass / Ereignisse', 'Gefahren', 'Maßnahmen'],

        datasets: [
          {
            data: this.dataset!.data,
            backgroundColor: ['rgb(255, 77, 174)', 'rgb(255, 64, 129)', 'rgb(179, 18, 106)'],
            hoverOffset: 4,
            circumference: 180,
            rotation: 270,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',

            labels: {
              generateLabels: (chart) => {
                const datasets: any = chart.data.datasets;
                return datasets[0].data.map((data: any, i: number) => ({
                  text: `${chart.data.labels![i]} ${data}`,
                  fillStyle: datasets![0].backgroundColor![i],
                  index: i,
                }));
              },
            },
          },
        },
      },
    });
  }
}
