import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { Observable, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { BefehlsstelleDTO, GebaeudeDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { TzComboboxComponent } from 'src/app/shared/tz-combobox/tz-combobox.component';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import {
  Fachaufgabe,
  Organisation,
  Symbol,
  TaktischesZeichen,
  erzeugeTaktischesZeichen,
  fachaufgaben,
  organisationen,
  symbole,
} from 'taktische-zeichen-core';
import { befehlsstellenSelector } from '../../befehlsstelle/+state/befehlsstelle.selectors';
import { DataUrlChangeEvent } from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-gebaeude-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    KontaktListComponent,
    CleanableFormFieldComponent,
    TzComboboxComponent,
    MatTooltipModule,
    MatInputModule,
  ],
  templateUrl: './gebaeude-form.component.html',
  styleUrls: ['./gebaeude-form.component.scss'],
})
export class GebaeudeFormComponent implements TaktischesZeichenForm {
  @ViewChild(KontaktListComponent)
  kontaktList!: KontaktListComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  dataUrlChanged = new EventEmitter<DataUrlChangeEvent>();

  formGroup: FormGroup;

  readonly EMPTY_ZEICHEN: TaktischesZeichen = { grundzeichen: 'gebaeude' };
  editorZeichen: TaktischesZeichen = { ...this.EMPTY_ZEICHEN };
  currentZeichenDataUrl: string = erzeugeTaktischesZeichen(this.editorZeichen).dataUrl;
  customZeichen = false;

  // Dropdown-Werte
  organisationValues: Organisation[] = organisationen.sort(compareLabels);
  fachaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  symbolValues: Symbol[] = symbole.sort(compareLabels);
  befehlsstelleValues$: Observable<BefehlsstelleDTO[]>;

  fachaufgabeMouseover = false;
  symbolMouseover = false;
  organisationMouseover = false;
  befehlsstelleMouseover = false;

  gebaeudeToEdit?: GebaeudeDTO;

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);
  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcOrganisation = this.formBuilder.control<Organisation | undefined>(undefined);
  fcFachaufgabe = this.formBuilder.control<Fachaufgabe | undefined>(undefined);
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [
    Validators.maxLength(255),
    Validators.pattern('[a-zA-Z0-9-/]*'),
  ]);
  fcBefehlsstelle = this.formBuilder.control<BefehlsstelleDTO | undefined>(undefined);

  constructor(store: Store<AppStateInterface>) {
    this.befehlsstelleValues$ = store.select(befehlsstellenSelector).pipe(takeUntilDestroyed());

    this.formGroup = this.formBuilder.group({
      anzeigename: this.fcAnzeigename,
      organisation: this.fcOrganisation,
      fachaufgabe: this.fcFachaufgabe,
      symbol: this.fcSymbol,
      zeichenText: this.fcZeichenText,
      befehlsstelle: this.fcBefehlsstelle,
    });

    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });

    this.fcOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.organisation = v ? v.id : undefined;
        this.generateTaktischesZeichen();
      }
    });

    this.fcFachaufgabe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.fachaufgabe = v ? v.id : undefined;
        this.generateTaktischesZeichen();
      }
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.symbol = v ? v.id : undefined;
        this.generateTaktischesZeichen();
      }
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen && this.fcZeichenText.valid) {
        this.editorZeichen.text = v?.trim() || '';
        this.generateTaktischesZeichen();
      }
    });
  }

  setCustomTz(dataUrl: string) {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
      this.customZeichen = true;
    } else {
      this.customZeichen = false;
      this.generateTaktischesZeichen();
    }
  }

  setDTO(gebaeudeDTO: GebaeudeDTO) {
    this.gebaeudeToEdit = gebaeudeDTO;
    if (this.gebaeudeToEdit) {
      this.dtoToFormGroup(this.gebaeudeToEdit);
      if (this.gebaeudeToEdit.customZeichen && this.gebaeudeToEdit.dataUrl) {
        this.generateTaktischesZeichen(this.gebaeudeToEdit.dataUrl);
        return;
      }
    }
    this.generateTaktischesZeichen();
  }

  /**
   * Aktualisiertes GebaeudeDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): GebaeudeDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): GebaeudeDTO {
    return {
      ...this.gebaeudeToEdit,
      anzeigename: this.fcAnzeigename.value?.trim(),
      organisation: this.fcOrganisation.value?.id,
      fachaufgabe: this.fcFachaufgabe.value?.id,
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      befehlsstelleId: this.fcBefehlsstelle.value?.id,
      dataUrl: this.currentZeichenDataUrl,
      kommunikationOptionen: this.kontaktList.getKommunikationOptionen(),
      customZeichen: this.customZeichen,
      typ: TaktischesZeichenTyp.Gebaeude,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(gebaeudeDTO: GebaeudeDTO): void {
    this.fcAnzeigename.setValue(gebaeudeDTO.anzeigename);
    this.fcOrganisation.setValue(organisationen.find((v) => v.id === gebaeudeDTO.organisation));
    this.fcFachaufgabe.setValue(fachaufgaben.find((v) => v.id === gebaeudeDTO.fachaufgabe));
    this.fcSymbol.setValue(symbole.find((v) => v.id === gebaeudeDTO.symbol));
    this.fcZeichenText.setValue(gebaeudeDTO.zeichenText);
    if (gebaeudeDTO.befehlsstelleId) {
      this.befehlsstelleValues$
        .pipe(take(1))
        .subscribe((befehlsstelle) =>
          this.fcBefehlsstelle.setValue(befehlsstelle.find((b) => b.id === gebaeudeDTO.befehlsstelleId))
        );
    }
    this.customZeichen = gebaeudeDTO.customZeichen || false;
  }

  /**
   * DataURL an Parent senden und dort aktualisieren.
   */
  generateTaktischesZeichen(dataUrl = '') {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
    } else {
      const zeichen = erzeugeTaktischesZeichen(this.editorZeichen);
      this.currentZeichenDataUrl = zeichen.dataUrl;
    }

    this.dataUrlChanged.emit({ dataUrl: this.currentZeichenDataUrl, customZeichen: this.customZeichen });
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
