import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Service zur einheitlichen Anzeige von Meldungen an den Nutzer über Snackbars.
 */
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  /**
   * Zeigt message für 1.5 Sekunden in einer Snackbar an und verschwindet dann automatisch.
   */
  showShort(message: string) {
    this.snackbar.open(message, undefined, { duration: 1500 });
  }

  /**
   * Zeigt message für 3 Sekunden in einer Snackbar an und verschwindet dann automatisch.
   */
  showLong(message: string) {
    this.snackbar.open(message, undefined, { duration: 3000 });
  }

  /**
   * Zeigt message für in einer Snackbar solange an, bis Nutzer die Meldung bestätigt.
   */
  showDismissable(message: string, dismissText?: string) {
    this.snackbar.open(message, dismissText || 'OK');
  }
}
