import { createSelector } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState.lagen;

export const isLoadingSelector = createSelector(selectFeature, (state) => state.isLoading);
export const isSavingSelector = createSelector(selectFeature, (state) => state.isSaving);
export const lagenSelector = createSelector(selectFeature, (state) => state.lagen);
export const lageErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const currentLageSelector = createSelector(selectFeature, (state) => state.currentLage);
export const lagezeitSelector = createSelector(selectFeature, (state) => state.lagezeit);
export const isLoadingEinsaetzeSelector = createSelector(selectFeature, (state) => state.isLoadingEinsaetze);
