import { inject, Injectable } from '@angular/core';
import { ErrorResponse, ErrorService } from '@product/ise-error-lib';
import { geoJSON, latLngBounds } from 'leaflet';
import { BehaviorSubject, catchError, EMPTY, from, Observable, switchMap, take } from 'rxjs';
import { ApplicationSettingsDTO, ApplicationSettingsResourceService } from './api/build/openapi';
import { AuthService } from './auth/auth.service';

export enum ApplicationType {
  'LAGE' = 'LAGE',
  'LAGEK' = 'LAGE-K',
}

// BoundingBox DE
export const BBOX_GER = latLngBounds([47.3024876979, 5.98865807458], [54.983104153, 15.0169958839]);

/**
 * Service für Einstellungen der gesamten Anwendung.
 * - Aktivieren des Darkmodes (Wird im LocalStorage persistiert).
 * - Setzen des ApplicationTypes (LAGE/LAGE-K)
 * - Laden der Mandanten-Settings (Standard-Kartenausschnitt, Organisation, ...)
 */
@Injectable({
  providedIn: 'root',
})
export class AppService {
  // DarkMode
  private darkMode = false;

  // ApplicationType
  private selectedApplicationStorageKey: string = 'selected-application';
  private selectedApplication$ = new BehaviorSubject<ApplicationType | undefined>(undefined);

  // ApplicationSettings
  private authService = inject(AuthService);
  private applicationSettingsResourceService = inject(ApplicationSettingsResourceService);
  private errorService = inject(ErrorService);
  private applicationSettingsSubject$ = new BehaviorSubject<ApplicationSettingsDTO | undefined>(undefined);
  public applicationSettings$ = from(this.applicationSettingsSubject$);

  constructor() {
    this.darkMode = 'true' === localStorage.getItem('darkmode');
    this.applyDarkMode();

    const selectedApp = localStorage.getItem(this.selectedApplicationStorageKey);
    if (selectedApp) {
      this.selectedApplication$.next(selectedApp as ApplicationType);
    }

    this.fetchApplicationSettings();
  }

  /*
   * DarkMode
   */
  isDarkMode(): boolean {
    return this.darkMode;
  }

  toggleDarkMode(): boolean {
    this.darkMode = !this.darkMode;
    localStorage.setItem('darkmode', String(this.darkMode));
    this.applyDarkMode();

    return this.darkMode;
  }

  private applyDarkMode() {
    if (this.darkMode) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }

  /*
   * ApplicationType
   */
  selectApplication(applicationType: ApplicationType) {
    localStorage.setItem(this.selectedApplicationStorageKey, applicationType);
    this.selectedApplication$.next(applicationType);
  }

  selectedApplicationObservable(): Observable<ApplicationType | undefined> {
    return this.selectedApplication$.asObservable();
  }

  /*
   * ApplicationSettings
   */
  fetchApplicationSettings() {
    this.authService.isAuthenticated$
      .pipe(
        take(1),
        switchMap((authenticated) => {
          if (authenticated) {
            return this.applicationSettingsResourceService.getApplicationSettings().pipe(
              catchError((error: ErrorResponse) => {
                this.errorService.showError(error);
                return EMPTY;
              })
            );
          }
          return EMPTY;
        })
      )
      .subscribe((applicationSettings: ApplicationSettingsDTO) => {
        this.applicationSettingsSubject$.next(applicationSettings);
      });
  }

  patchApplicationSettings(applicationSettings: ApplicationSettingsDTO) {
    this.applicationSettingsResourceService
      .patchApplicationSettings(applicationSettings)
      .pipe(
        catchError((error: ErrorResponse) => {
          this.errorService.showError(error);
          return EMPTY;
        })
      )
      .subscribe((applicationSettings: ApplicationSettingsDTO) => {
        this.applicationSettingsSubject$.next(applicationSettings);
      });
  }

  getSettingsMapBounds() {
    const applicationSettings = this.applicationSettingsSubject$.value;
    if (!applicationSettings) {
      console.warn(
        'Default MapBounds konnten nicht berechnet werden, da ApplicationSettings noch nicht verfügbar sind.'
      );
      this.fetchApplicationSettings();
      return BBOX_GER;
    }

    if (!applicationSettings.defaultGeometry) {
      console.warn(
        'Default MapBounds konnten nicht berechnet werden, da ApplicationSettings keine DefaultGeometry besitzt.'
      );
      return BBOX_GER;
    }

    return geoJSON(applicationSettings.defaultGeometry as GeoJSON.Geometry).getBounds();
  }
}
