import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLageComponent } from './dashboard-lage/dashboard-lage.component';

import { DashboardFahrzeugeComponent } from './dashboard-fahrzeuge/dashboard-fahrzeuge.component';
import { DashboardPersonenComponent } from './dashboard-personen/dashboard-personen.component';
import {
  EinsatzraumDataSet,
  FahrzeugeDataset,
  KennzahlenDashboadService,
  ManvDataset,
  PersonenDataset,
} from './kennzahlen-dashboad.service';
import { Personen } from 'src/app/lagedarstellung/triage/personen.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-kennzahlen-dashboard',
  standalone: true,
  imports: [CommonModule, DashboardLageComponent, DashboardFahrzeugeComponent, DashboardPersonenComponent],
  templateUrl: './kennzahlen-dashboard.component.html',
  styleUrls: ['./kennzahlen-dashboard.component.scss'],
})
export class KennzahlenDashboardComponent {
  private service: KennzahlenDashboadService = inject(KennzahlenDashboadService);

  einsatzraumeGes = 0;
  schadensgebietDataset: EinsatzraumDataSet = {
    name: 'Schadensgebiet',
    einsatzstellen: 0,
    data: [0, 0, 0],
  };
  einsatzraeumeDatasets: EinsatzraumDataSet[] = [];
  fahrzeugeDataset: FahrzeugeDataset = {
    fahrzeuge: 0,
    staerke1: 0,
    staerke2: 0,
    staerke3: 0,
  };
  personenDatasetBevoelkerung: PersonenDataset = {
    name: 'Bevölkerung',
    personen: this.defaultPersonen(),
  };

  personenDatasetEinsatzkraefte: PersonenDataset = {
    name: 'Einsatzkräfte',
    personen: this.defaultPersonen(),
  };

  PersonenDatasetManv: ManvDataset = {
    name: 'MANV',
    manv: { category1: 0, category2: 0, category3: 0, category4: 0, dead: 0 },
  };

  constructor() {
    this.service
      .subscribeEinsatraume()
      .pipe(takeUntilDestroyed())
      .subscribe((datasets) => (this.einsatzraeumeDatasets = datasets));
    this.service
      .subscribeSchadensgebiet()
      .pipe(takeUntilDestroyed())
      .subscribe((dataset) => (this.schadensgebietDataset = dataset));
    this.service
      .subscribeFahrzeuge()
      .pipe(takeUntilDestroyed())
      .subscribe((fahrzeuge) => (this.fahrzeugeDataset = fahrzeuge));
    this.service
      .subscribePersonenEinsatzkraefte()
      .pipe(takeUntilDestroyed())
      .subscribe((dataset) => (this.personenDatasetEinsatzkraefte = dataset));
    this.service
      .subscribePersonenBevoelkerung()
      .pipe(takeUntilDestroyed())
      .subscribe((dataset) => (this.personenDatasetBevoelkerung = dataset));
    this.service
      .subscribePersonenManv()
      .pipe(takeUntilDestroyed())
      .subscribe((manv) => (this.PersonenDatasetManv = manv));
  }

  reload() {
    this.service.reload();
  }

  private defaultPersonen(): Personen {
    return { betroffen: 0, gerettet: 0, tot: 0, transportiert: 0, verletzt: 0, vermisst: 0 };
  }
}
