import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, switchMap } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  TaktischeFormationNestedDTO,
  TaktischeFormationPatchDTO,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { bibliothekActions } from 'src/app/planung/bibliothek/+state/bibliothek.actions';
import { taktischeFormationActions } from '../+state/taktische-formation.actions';
import { TaktischeZeichenItemComponent } from '../../taktische-zeichen-container/taktische-zeichen-item/taktische-zeichen-item.component';
import {
  EditTzDialogData,
  TaktischeZeichenDialogComponent,
} from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import {
  SelectDialogInputData,
  SelectDialogOutputData,
  TaktischeFormationSelectDialogComponent,
} from '../taktische-formation-select-dialog/taktische-formation-select-dialog.component';
import { TaktischeFormationService } from '../taktische-formation.service';

@Component({
  selector: 'app-taktische-formation-container',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    TaktischeZeichenItemComponent,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './taktische-formation-container.component.html',
  styleUrls: ['./taktische-formation-container.component.scss'],
})
export class TaktischeFormationContainerComponent {
  @Input()
  selectedTaktischeFormationNested$!: BehaviorSubject<TaktischeFormationNestedDTO>;

  @Output()
  taktischeFormationPatched = new EventEmitter<void>();

  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private taktischeFormationService = inject(TaktischeFormationService);

  constructor(private store: Store<AppStateInterface>, private dialog: MatDialog) {}

  selectEinheiten(): void {
    if (!this.selectedTaktischeFormationNested$.value.id) {
      return;
    }

    const dialogData: SelectDialogInputData = {
      taktischeFormationId: this.selectedTaktischeFormationNested$.value.id,
      shownTzs: [TaktischesZeichenTyp.Person, TaktischesZeichenTyp.Fahrzeug, TaktischesZeichenTyp.TaktischeFormation],
      multiSelect: true,
      headerText: 'Einheiten auswählen',
      selectedPersonen: this.selectedTaktischeFormationNested$.value.personen,
      selectedFahrzeuge: this.selectedTaktischeFormationNested$.value.fahrzeuge,
      selectedTaktischeFormationen: this.selectedTaktischeFormationNested$.value.taktischeFormationen,
    };
    this.dialog
      .open(TaktischeFormationSelectDialogComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((dialogResult: SelectDialogOutputData) => {
        if (!dialogResult) {
          return;
        }

        const taktischeFormation = this.selectedTaktischeFormationNested$.value;
        if (!taktischeFormation.id) {
          return;
        }

        const einheiten: TaktischeFormationPatchDTO = {
          fahrzeugIds: dialogResult.fahrzeugIds || [],
          personIds: dialogResult.personIds || [],
          taktischeFormationIds: dialogResult.taktischeFormationIds || [],
        };

        // Taktische Formation speichern, nachdem Einheiten ausgewählt wurden
        this.taktischeFormationService
          .setTaktischeFormationEinheiten(taktischeFormation.id, einheiten)
          .pipe(
            switchMap((patchedTaktischeFormation) => {
              if (!patchedTaktischeFormation.id) {
                return EMPTY;
              }
              return this.taktischeFormationService.getTaktischeFormationNested(patchedTaktischeFormation.id);
            })
          )
          .subscribe((patchedTaktischeFormationNested) => {
            if (this.fuehrungsebeneService.getCurrentLage()) {
              this.store.dispatch(
                taktischeFormationActions.patchTaktischeFormationSuccess({
                  patchedTaktischeFormationDTO: patchedTaktischeFormationNested,
                })
              );
            } else {
              this.store.dispatch(
                bibliothekActions.patchTaktischeFormationSuccess({
                  patchedTaktischeFormation: patchedTaktischeFormationNested,
                })
              );
            }
            this.selectedTaktischeFormationNested$.next(patchedTaktischeFormationNested);
            this.taktischeFormationPatched.emit();
          });
      });
  }

  /**
   * Ausgewähltes Taktisches Zeichen bearbeiten
   */
  editTaktischesZeichen(dto: TaktischesZeichenDTO) {
    const editTzDialogData: EditTzDialogData = {
      dto: dto,
      taktischesZeichenTyp: dto.typ,
    };

    const dialogRef = this.dialog.open(TaktischeZeichenDialogComponent, {
      data: editTzDialogData,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.taktischeFormationPatched.emit();
    });
  }
}
