<mat-card class="left-container">
  <mat-toolbar>
    <h2 class="pointer" (click)="focusVerwaltungsebene(undefined)">Verwaltungsebenen</h2>
    <button
      *ngIf="!selectionMode"
      mat-icon-button
      matTooltip="Verwaltungsebene anlegen"
      mat-icon
      (click)="openVerwaltungsebeneDialog()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="toggleTree()"
      [matTooltip]="'Hierarchie ' + (expandNavTree ? 'zusammenklappen' : 'ausklappen')"
    >
      <mat-icon>{{ expandNavTree ? "unfold_less" : "unfold_more" }}</mat-icon>
    </button>
  </mat-toolbar>

  <div class="scrollable">
    <mat-card-content>
      <app-verwaltungsebenen-tree
        [expandTree]="expandNavTree"
        [enableEdit]="!selectionMode"
        [verwaltungsebenen]="verwaltungsebenenTree"
        (treeNodeClicked)="focusVerwaltungsebene($event)"
        (createVerwaltungsebeneTriggered)="openVerwaltungsebeneDialog($event)"
      />
    </mat-card-content>
    <h3 class="keine-daten-text" *ngIf="!verwaltungsebenenTree.length">Keine Verwaltungsebenen vorhanden</h3>
  </div>
</mat-card>

<div class="right-container">
  <mat-card>
    <mat-card-content class="filter">
      <h3>Filter:</h3>

      <mat-form-field>
        <mat-label>Suche...</mat-label>
        <input matInput type="text" matTooltip="Suche nach Namen" [formControl]="fcFilterText" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Organisationen</mat-label>
        <mat-select matInput multiple [formControl]="fcFilterOrganisationen">
          <mat-option *ngFor="let option of organisationOptions | keyvalue" [value]="option.value">{{
            option.key
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Grundzeichen</mat-label>
        <mat-select matInput multiple [formControl]="fcFilterGrundzeichen">
          <mat-option *ngFor="let option of grundzeichenOptions | keyvalue" [value]="option.value">{{
            option.key
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-checkbox [formControl]="fcFilterVorlagen">Nur Vorlagen</mat-checkbox>

      <button mat-icon-button matTooltip="Filter entfernen" (click)="clearFilter()">
        <mat-icon>delete</mat-icon>
      </button>

      <button
        mat-icon-button
        (click)="toggleAccordion()"
        [matTooltip]="'Hierarchie ' + (accordionExpanded ? 'zusammenklappen' : 'ausklappen')"
      >
        <mat-icon>{{ accordionExpanded ? "unfold_less" : "unfold_more" }}</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <div class="accordion-container">
    <mat-accordion class="zeichen-container" multi *ngIf="verwaltungsebenen$ | async as verwaltungsebenen">
      <app-verwaltungsebene-details
        *ngFor="let verwaltungsebene of verwaltungsebenen"
        [verwaltungsebene]="verwaltungsebene"
        [expand]="verwaltungsebenen.length === 1"
        [expandChildren]="accordionExpanded"
        [selectionMode]="selectionMode"
        [bibliothekFilter$]="bibliothekFilter$"
      />
    </mat-accordion>
  </div>
</div>
