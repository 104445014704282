import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { NatsConnectorService } from 'src/app/shared/services/nats/nats-connector.service';

@Component({
  selector: 'app-planung-navigation',
  standalone: true,
  imports: [CommonModule, RouterModule, MatButtonModule],
  templateUrl: './planung-navigation.component.html',
  styleUrls: ['./planung-navigation.component.scss'],
})
export class PlanungNavigationComponent {
  private store = inject(Store);
  private natsConnector = inject(NatsConnectorService);

  /**
   * Beim Navigieren in die Bibliothek, aktuelle Lage abwählen
   */
  constructor(protected router: Router) {
    this.store.dispatch(lageActions.setCurrentLage({ currentLage: null }));
    this.natsConnector.updateSubscriptions(undefined, false, true);
  }
}
