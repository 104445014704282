import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map, of, switchMap, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  BibFahrzeugDTO,
  BibPersonDTO,
  BibTaktischeFormationDTO,
  FahrzeugDTO,
  Kommunikation,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischesZeichenTyp,
  VerwaltungsebeneDTO,
} from 'src/app/api/build/openapi';
import { KontaktService } from 'src/app/lagedarstellung/kontakt/kontakt.service';
import {
  CreateTzDialogData,
  EditTzDialogData,
  TaktischeZeichenDialogComponent,
} from 'src/app/lagedarstellung/taktische-zeichen/taktische-zeichen-dialog/taktische-zeichen-dialog.component';

import { Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogAction, DialogService } from '@product/ise-dialog-lib';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { LageService } from 'src/app/lagedarstellung/lagen/lage.service';
import {
  MultiplyDialogComponent,
  MultiplyDialogInputData,
} from 'src/app/lagedarstellung/taktische-zeichen/multiply-dialog/multiply-dialog.component';
import {
  TaktischeFormationDialogComponent,
  TaktischeFormationDialogInputData,
  TaktischeFormationDialogOrigin,
} from 'src/app/lagedarstellung/taktische-zeichen/taktische-formation/taktische-formation-dialog/taktische-formation-dialog.component';
import { TaktischeFormationService } from 'src/app/lagedarstellung/taktische-zeichen/taktische-formation/taktische-formation.service';
import { ContextMenuHelper } from 'src/app/shared/contextmenu/context-menu-helper';
import { BubbleMenueConfig, BubblemenueService } from 'src/app/shared/services/bubblemenue/bubblemenue.service';
import { bibliothekActions } from '../+state/bibliothek.actions';
import {
  fahrzeugeWithAnyVerwaltungsebeneIdSelector,
  fahrzeugeWithVerwaltungsebeneIdSelector,
  personenWithAnyVerwaltungsebeneIdSelector,
  personenWithVerwaltungsebeneIdSelector,
  taktischeFormationenWithAnyVerwaltungsebeneIdSelector,
  taktischeFormationenWithVerwaltungsebeneIdSelector,
} from '../+state/bibliothek.selectors';
import {
  TaktischeZeichenItemComponent,
  TzClickedEvent,
} from '../../../lagedarstellung/taktische-zeichen/taktische-zeichen-container/taktische-zeichen-item/taktische-zeichen-item.component';
import { BibliothekFilter } from '../bibliothek-base/bibliothek-base.component';
import { BibliothekZeichenMoveComponent } from '../bibliothek-zeichen-move/bibliothek-zeichen-move.component';
import { BibliothekZeichenService } from '../bibliothek-zeichen.service';
import { VerwaltungsebeneDialogComponent } from '../verwaltungsebene-dialog/verwaltungsebene-dialog.component';
import { VerwaltungsebenenTreeComponent } from '../verwaltungsebenen-tree/verwaltungsebenen-tree.component';
import { verwaltungsebenenMap } from '../verwaltungsebenenMap';
import { TaktischeZeichenSelectionService } from './taktische-zeichen-selection.service';

export type BibTzDTO = BibFahrzeugDTO | BibPersonDTO | BibTaktischeFormationDTO;

@Component({
  selector: 'app-verwaltungsebene-details',
  standalone: true,
  templateUrl: './verwaltungsebene-details.component.html',
  styleUrls: ['./verwaltungsebene-details.component.scss'],
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    TaktischeZeichenItemComponent,
    MatExpansionModule,
    VerwaltungsebenenTreeComponent,
  ],
})
export class VerwaltungsebeneDetailsComponent implements OnInit, OnChanges {
  private destroyRef = inject(DestroyRef);

  TaktischesZeichenTyp = TaktischesZeichenTyp;
  verwaltungsebenenMap = verwaltungsebenenMap;
  @Input({ required: true }) verwaltungsebene!: VerwaltungsebeneDTO;
  @Input() expand = false;
  @Input() expandChildren = false;

  /**
   * Für die Verwendung in einer Lage.
   *
   * Es wird das Editieren der Taktischen Zeichen und Verwaltungsebenen unterbunden.
   *
   * Beim Selektieren eines TZ wird dies dem "TaktischeZeichenSelectionService" gemeldet.
   */
  @Input() selectionMode = false;

  // accordionExpanded = false;
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;

  @Input({ required: true })
  bibliothekFilter$!: Observable<BibliothekFilter>;

  fahrzeuge: BibFahrzeugDTO[] = [];
  personen: BibPersonDTO[] = [];
  taktischeFormationen: BibTaktischeFormationDTO[] = [];

  private bubbleMenueService = inject(BubblemenueService);
  private taktischeFormationService = inject(TaktischeFormationService);
  private lageService = inject(LageService);
  private dialogService = inject(DialogService);

  private anyTzInLage$: Observable<boolean> = of(false);

  constructor(
    private dialog: MatDialog,
    private kontaktService: KontaktService,
    private store: Store<AppStateInterface>,
    private selectionService: TaktischeZeichenSelectionService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private bibZeichenService: BibliothekZeichenService
  ) {}

  ngOnInit(): void {
    if (!this.verwaltungsebene.id) {
      return;
    }

    // BibliothekFilter auf Fahrzeuge anwenden
    combineLatest([
      this.store.select(fahrzeugeWithVerwaltungsebeneIdSelector(this.verwaltungsebene.id)),
      this.bibliothekFilter$,
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(([fahrzeugDTOs, filter]) => this.filterTZs(fahrzeugDTOs, filter) as BibFahrzeugDTO[])
      )
      .subscribe((fahrzeuge) => (this.fahrzeuge = fahrzeuge));

    // BibliothekFilter auf Personen anwenden
    combineLatest([
      this.store.select(personenWithVerwaltungsebeneIdSelector(this.verwaltungsebene.id)),
      this.bibliothekFilter$,
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(([personDTOs, filter]) => this.filterTZs(personDTOs, filter) as BibPersonDTO[])
      )
      .subscribe((personen) => (this.personen = personen));

    // BibliothekFilter auf Taktische Formationen anwenden
    combineLatest([
      this.store.select(taktischeFormationenWithVerwaltungsebeneIdSelector(this.verwaltungsebene.id)),
      this.bibliothekFilter$,
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(
          ([taktischeFormationDTOs, filter]) =>
            this.filterTZs(taktischeFormationDTOs, filter) as BibTaktischeFormationDTO[]
        )
      )
      .subscribe((taktischeFormationen) => (this.taktischeFormationen = taktischeFormationen));

    // FIXME ggf. wg. Performance nicht direkt alle TZ sammeln und erst dann prüfen? Besser erst TF, Personen und dann Fahrzeuge?
    /**
     * Die ID einer Verwaltungsebene und die aller Kinder (rekursiv) werden zusammengesucht.
     * Es werden alle Fahrzeuge, Personen und Taktischen Formationen die zu einer Verwaltungsebene passen darauf überprüft, ob sie in einer Lage sind.
     * Observable liefert `false` sobald nur eins der gefundnen in einer Lage ist
     */
    this.anyTzInLage$ = of(this.getAllVerwaltungsebenenIds(this.verwaltungsebene)).pipe(
      switchMap((verwaltungsebenenIds) =>
        combineLatest([
          this.store.select(fahrzeugeWithAnyVerwaltungsebeneIdSelector(verwaltungsebenenIds)),
          this.store.select(personenWithAnyVerwaltungsebeneIdSelector(verwaltungsebenenIds)),
          this.store.select(taktischeFormationenWithAnyVerwaltungsebeneIdSelector(verwaltungsebenenIds)),
        ]).pipe(
          takeUntilDestroyed(this.destroyRef),
          map(
            ([personen, fahrzeuge, taktischeFormationen]) =>
              personen.some((p) => !!p.lageId) ||
              fahrzeuge.some((p) => !!p.lageId) ||
              taktischeFormationen.some((p) => !!p.lageId)
          )
        )
      )
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.accordion) {
      if (changes['expandChildren'] && !changes['expandChildren'].currentValue) {
        this.accordion.closeAll();
      } else {
        this.accordion.openAll();
      }
    }
  }

  edit(event: MouseEvent) {
    this.anyTzInLage$.pipe(take(1)).subscribe((v) => {
      event.stopPropagation();
      this.dialog.open(VerwaltungsebeneDialogComponent, {
        data: { verwaltungsebene: this.verwaltungsebene, canDelete: v },
      });
    });
  }

  /**
   * Bestehendes Taktisches Zeichen bearbeiten
   */
  editTaktischesZeichen(dto: TaktischesZeichenDTO) {
    const editTzDialogData: EditTzDialogData = {
      dto,
      taktischesZeichenTyp: dto.typ,
      preventSaveActions: true,
    };
    const dialogRef = this.dialog.open(TaktischeZeichenDialogComponent, {
      data: editTzDialogData,
      disableClose: false,
    });

    // Susbscrptions beenden
    dialogRef.afterClosed().subscribe(() => {
      saveSubscription.unsubscribe();
    });

    const saveSubscription = dialogRef.componentInstance.handleSave.subscribe((dto: BibTzDTO) => {
      this.saveDto(dto);
      dialogRef.close();
    });
  }

  /**
   * Bestehendes Taktisches Zeichen bearbeiten
   */
  openBubbleMenu(dto: TaktischesZeichenDTO, event: TzClickedEvent) {
    if (this.selectionMode || this.hasLage(dto as BibTzDTO)) {
      return;
    }

    const menuConfigs: BubbleMenueConfig[] = [
      // Bubble-Item zum Editieren
      {
        matIcon: 'edit',
        action: () => this.editTaktischesZeichen(dto),
        tooltip: 'Bearbeiten',
      },
    ];

    // Bubble-Item für Hierarchie einer Taktischen Formation
    if (dto.typ === TaktischesZeichenTyp.TaktischeFormation) {
      menuConfigs.push({
        matIcon: 'account_tree',
        action: () => this.openTaktischeFormationHierarchie(dto as BibTaktischeFormationDTO),
        tooltip: 'Einheiten verwalten',
      });
    }

    if (
      dto.typ === TaktischesZeichenTyp.Fahrzeug ||
      dto.typ === TaktischesZeichenTyp.Person ||
      dto.typ === TaktischesZeichenTyp.TaktischeFormation
    ) {
      menuConfigs.push({
        matIcon: 'move_down',
        action: () => {
          this.initializeMoveZeichen(dto);
        },
        tooltip: 'Verschieben',
      });

      menuConfigs.push({
        matIcon: 'copy',
        action: () => this.openMultiplyDialog(dto as FahrzeugDTO | PersonDTO | TaktischeFormationDTO),
        tooltip: 'Duplizieren',
      });
    }

    // Bubble-Item zum Löschen
    menuConfigs.push({
      matIcon: 'delete',
      action: () => this.askDelete(dto),
      tooltip: 'Entfernen',
    });

    this.bubbleMenueService.openMenue(event.viewContainerRef, menuConfigs).subscribe();
  }

  askDelete(dto: TaktischesZeichenDTO) {
    this.dialogService
      .openConfirmDialog(
        'Taktisches Zeichen entfernen?',
        'Taktisches Zeichen sicher entfernen? Alle Referenzen auf dieses Objekt werden dadurch ebenfalls entfernt.'
      )
      .afterClosed()
      .subscribe((result: DialogAction) => {
        if (result === DialogAction.OK && dto.id) {
          let deleteAction;
          switch (dto.typ) {
            case TaktischesZeichenTyp.Fahrzeug:
              deleteAction = bibliothekActions.deleteFahrzeug({ fahrzeugId: dto.id });
              break;
            case TaktischesZeichenTyp.Person:
              deleteAction = bibliothekActions.deletePerson({ personId: dto.id });
              break;
            case TaktischesZeichenTyp.TaktischeFormation:
              deleteAction = bibliothekActions.deleteTaktischeFormation({ taktischeFormationId: dto.id });
              break;
          }
          if (deleteAction) {
            this.store.dispatch(deleteAction);
          }
        }
      });
  }

  openTaktischeFormationHierarchie(dto: BibTaktischeFormationDTO) {
    if (!dto.id) {
      return;
    }

    this.taktischeFormationService.getTaktischeFormationNested(dto.id).subscribe((taktischeFormationNestedDTO) => {
      const dialogData: TaktischeFormationDialogInputData = {
        taktischeFormationNestedDTO: taktischeFormationNestedDTO,
        origin: TaktischeFormationDialogOrigin.BIBLIOTHEK,
      };
      this.dialog.open(TaktischeFormationDialogComponent, { data: dialogData });
    });
  }

  /**
   * Neues Taktisches Zeichen erstellen
   */
  createTaktischesZeichen(event: MouseEvent, verwaltungsebene: VerwaltungsebeneDTO) {
    event.stopPropagation();

    const createTzDialogData: CreateTzDialogData = {
      allowedTzTypes: [
        TaktischesZeichenTyp.Fahrzeug,
        TaktischesZeichenTyp.Person,
        TaktischesZeichenTyp.TaktischeFormation,
      ],
      preventSaveActions: true,
      taktischesZeichenTyp: TaktischesZeichenTyp.Fahrzeug,
    };

    const dialogRef = this.dialog.open(TaktischeZeichenDialogComponent, {
      data: createTzDialogData,
      disableClose: false,
    });

    const subscription = dialogRef.componentInstance.handleSave.subscribe((dto: TaktischesZeichenDTO) => {
      const bibTzDto = dto as BibTzDTO;

      // LageID darf für Verwaltungsebene nicht gesetzt sein
      const dtoToCreate: BibTzDTO = { ...bibTzDto, verwaltungsebeneId: verwaltungsebene.id, lageId: undefined };
      this.saveDto(dtoToCreate);

      dialogRef.close();
      subscription.unsubscribe();
    });
  }

  private saveDto = (dtoToSave: BibTzDTO) => {
    this.bibZeichenService.saveBibZeichenDto(dtoToSave);
  };

  hasKommunikationsOptionen = () =>
    this.verwaltungsebene.kommunikationOptionen && this.verwaltungsebene.kommunikationOptionen.length > 0;

  summarizeKommunikation(kommunikation: Kommunikation): string {
    return this.kontaktService.summarizeKommunikation(kommunikation);
  }

  getKommunikationIcon(kommunikation: Kommunikation): string {
    const value = kommunikation.kontaktTyp
      ? this.kontaktService.KontaktTypMapping.get(kommunikation.kontaktTyp)
      : undefined;
    return value?.icon || '';
  }

  // #########################
  // Nur im "selectionMode"
  // #########################

  toggleSelection(zeichen: TaktischesZeichenDTO) {
    if (!this.selectionMode) return;

    this.selectionService.toggleSelection(zeichen);
  }

  isSelected(zeichen: TaktischesZeichenDTO): boolean {
    return this.selectionService.isSelected(zeichen);
  }

  getTooltip(zeichen: BibTzDTO): string {
    let tooltip: string = `${zeichen.anzeigename}`;
    if (this.hasLage(zeichen)) {
      const lage = this.lageService.getLageById(zeichen.reservedByLageId ? zeichen.reservedByLageId : '');
      tooltip = tooltip + (lage !== undefined ? ` | Lage: ${lage.name}` : '');
    }

    return tooltip;
  }

  /**
   * Prüft, ob das übergebene TZ eine Lage-ID besitzt, also einer Lage zugeordnet ist.
   *
   * Sorgt damit dafür (im Template), dass das Zeichen nicht editiert werden kann.
   *
   * @param zeichen
   * @returns
   */
  hasLage(zeichen: BibTzDTO): boolean {
    return !!zeichen.lageTzId;
  }

  private getAllVerwaltungsebenenIds = (verwaltungsebene: VerwaltungsebeneDTO): string[] => {
    let resp: string[] = [];

    if (!verwaltungsebene.id) {
      return [];
    }

    if (verwaltungsebene.verwaltungsebenen) {
      verwaltungsebene.verwaltungsebenen.forEach((v) => (resp = resp.concat(this.getAllVerwaltungsebenenIds(v))));
    }

    return [verwaltungsebene.id, ...resp];
  };

  /**
   * BibliothekFilter für verschiedene TZs anwenden
   */
  private filterTZs(tzDTOs: BibTzDTO[], filter: BibliothekFilter) {
    if (!tzDTOs || !tzDTOs.length) {
      return [];
    }

    if (filter.grundzeichen.length) {
      if (
        (tzDTOs[0].typ === TaktischesZeichenTyp.Fahrzeug && !filter.grundzeichen.includes('fahrzeug')) ||
        (tzDTOs[0].typ === TaktischesZeichenTyp.Person && !filter.grundzeichen.includes('person')) ||
        (tzDTOs[0].typ === TaktischesZeichenTyp.TaktischeFormation &&
          !filter.grundzeichen.includes('taktische-formation'))
      ) {
        return [];
      }
    }

    return tzDTOs.filter((tzDTO: BibTzDTO) => {
      if (filter.filterText.length && !tzDTO.anzeigename.toLowerCase().includes(filter.filterText.toLowerCase())) {
        return false;
      }

      if (
        filter.organisationen.length &&
        (!tzDTO.organisation || !filter.organisationen.includes(tzDTO.organisation))
      ) {
        return false;
      }

      if (filter.nurVorlagen && !tzDTO.vorlage) {
        return false;
      }

      return true;
    });
  }

  initializeMoveZeichen(zeichen: TaktischesZeichenDTO) {
    this.dialog.open(BibliothekZeichenMoveComponent, { data: { taktischesZeichen: zeichen } });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @ViewChild('zeichenContextMenu') allContextMenu: TemplateRef<any> | undefined = undefined;
  private contextMenu?: ContextMenuHelper;

  openContextMenu(event: MouseEvent, zeichen: BibTzDTO) {
    event.preventDefault();

    if (this.contextMenu) {
      this.contextMenu.closeContextMenu();
    }

    if (!this.allContextMenu) {
      return;
    }

    const portal = new TemplatePortal(this.allContextMenu, this.viewContainerRef, {
      zeichen: zeichen,
    });
    this.contextMenu = ContextMenuHelper.spawn(portal, this.overlay, event);
  }

  protected moveZeichen(verwaltungsebenenId: string, zeichen: BibTzDTO) {
    // Verwaltsungsebene im Zeichen updaten
    const dtoToUpdate: BibTzDTO = { ...zeichen, verwaltungsebeneId: verwaltungsebenenId };
    this.saveDto(dtoToUpdate);

    // Verschieben-Menü schließen
    if (this.contextMenu) {
      this.contextMenu.closeContextMenu();
    }
  }

  openMultiplyDialog(tzDto: FahrzeugDTO | PersonDTO | TaktischeFormationDTO) {
    const inputData: MultiplyDialogInputData = {
      tzDto,
    };
    this.dialog.open(MultiplyDialogComponent, { data: inputData });
  }
}
