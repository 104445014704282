import { createReducer, on } from '@ngrx/store';
import { vorlagen as templates } from './taktische-zeichen-templates';
import { TaktischeZeichenActions } from './taktische-zeichen.actions';
import { TatktischeZeichenStateInterface } from './taktischeZeichenState.interface';

export const initialState: TatktischeZeichenStateInterface = {
  collection: [],
  templates: templates,
};

const localStorageKey = 'TaktischeZeichen';

function getInitialState(): TatktischeZeichenStateInterface {
  const fromLocal = localStorage.getItem(localStorageKey);

  if (fromLocal) {
    return JSON.parse(fromLocal);
  }

  return initialState;
}

export const taktischeZeichenReducer = createReducer(
  getInitialState(),
  on(TaktischeZeichenActions.saveTaktischesZeichen, (state, action) => {
    // TODO check valid?
    const newCollection = [...state.collection];
    newCollection.push(action.taktischesZeichenData);

    const resp = { ...state, collection: newCollection };

    localStorage.setItem(localStorageKey, JSON.stringify(resp));

    return resp;
  }),
  on(TaktischeZeichenActions.updateTaktischeZeichen, (state) => {
    const fromLocal = localStorage.getItem(localStorageKey);

    if (fromLocal) {
      return JSON.parse(fromLocal);
    }

    return state;
  })
);
