import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorService } from '@product/ise-error-lib';
import { PersonDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { AuftragListComponent } from 'src/app/lagedarstellung/auftrag/auftrag-list/auftrag-list.component';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import {
  Einheit,
  EinheitId,
  Fachaufgabe,
  Funktion,
  Organisation,
  TaktischesZeichen,
  Verwaltungsstufe,
  einheiten,
  erzeugeTaktischesZeichen,
  fachaufgaben,
  funktionen,
  organisationen,
  verwaltungsstufen,
} from 'taktische-zeichen-core';
import { CleanableFormFieldComponent } from '../../../../shared/cleanable-form-field/cleanable-form-field.component';
import { DataUrlChangeEvent } from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    CleanableFormFieldComponent,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    KontaktListComponent,
    AuftragListComponent,
  ],
})
export class PersonFormComponent implements TaktischesZeichenForm {
  @ViewChild(KontaktListComponent) kontaktListe!: KontaktListComponent;
  @ViewChild(AuftragListComponent) auftragListe!: AuftragListComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();
  @Output()
  dataUrlChanged = new EventEmitter<DataUrlChangeEvent>();

  readonly EMPTY_ZEICHEN: TaktischesZeichen = { grundzeichen: 'person' };
  editorZeichen: TaktischesZeichen = { ...this.EMPTY_ZEICHEN };
  currentZeichenDataUrl: string = erzeugeTaktischesZeichen(this.editorZeichen).dataUrl;
  customZeichen = false;
  personToEdit?: PersonDTO;

  // Hover-Merker
  organisationMouseover = false;
  fachaufgabeMouseover = false;
  groessenordnungMouseover = false;
  verwaltungsstufeMouseover = false;
  funktionMouseover = false;

  // Dropdown-Werte
  organisationenValues: Organisation[] = organisationen.sort(compareLabels);
  fauchaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  groessenordnungValues: Einheit[] = einheiten.sort(compareLabels);
  verwaltungsstufeValues: Verwaltungsstufe[] = verwaltungsstufen.sort(compareLabels);
  funktionValues: Funktion[] = funktionen.sort(compareLabels);

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);

  // Form Controls
  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcOrganisation = this.formBuilder.control<Organisation | undefined>(undefined);
  fcFachaufgabe = this.formBuilder.control<Fachaufgabe | undefined>(undefined);
  fcGroessenordnung = this.formBuilder.control<Einheit | undefined>(undefined);
  fcMannschaftsstaerke1 = this.formBuilder.control<number>(0);
  fcMannschaftsstaerke2 = this.formBuilder.control<number>(0);
  fcMannschaftsstaerke3 = this.formBuilder.control<number>(1);
  fcVerwaltungsstufe = this.formBuilder.control<Verwaltungsstufe | undefined>(undefined);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcFunktion = this.formBuilder.control<Funktion | undefined>(undefined, [Validators.maxLength(255)]);
  fcFunktionKurztext = this.formBuilder.control<string | undefined>(undefined, [
    Validators.maxLength(6),
    Validators.pattern('[a-zA-Z0-9-/]*'),
  ]);
  fcVorname = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcNachname = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcDienstgrad = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcHerkunft = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcGliederung = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);

  // Form Group
  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    organisation: this.fcOrganisation,
    fachaufgabe: this.fcFachaufgabe,
    groessenordnung: this.fcGroessenordnung,
    mannschaftsstaerke1: this.fcMannschaftsstaerke1,
    mannschaftsstaerke2: this.fcMannschaftsstaerke2,
    mannschaftsstaerke3: this.fcMannschaftsstaerke3,
    verwaltungsstufe: this.fcVerwaltungsstufe,
    ortsangabe: this.fcOrtsangabe,
    funktion: this.fcFunktion,
    funktionKurztext: this.fcFunktionKurztext,
    vorname: this.fcVorname,
    nachname: this.fcNachname,
    dienstgrad: this.fcDienstgrad,
    herkunft: this.fcHerkunft,
    gliederung: this.fcGliederung,
  });

  constructor() {
    this.fcAnzeigename.valueChanges.subscribe((v) => {
      this.anzeigenameChanged.emit(v.trim());
    });

    this.fcFachaufgabe.valueChanges.subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.fachaufgabe = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcOrganisation.valueChanges.subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.organisation = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcGroessenordnung.valueChanges.subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.einheit = v?.id;
        this.generateTaktischesZeichen();
        this.updateMannschaftsstaerke(v?.id);
      }
    });

    this.fcVerwaltungsstufe.valueChanges.subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.verwaltungsstufe = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcFunktion.valueChanges.subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.funktion = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcFunktionKurztext.valueChanges.subscribe((v) => {
      if (!this.customZeichen && this.fcFunktionKurztext.valid) {
        this.editorZeichen.text = v?.trim();
        this.generateTaktischesZeichen();
      }
    });
  }

  setCustomTz(dataUrl: string) {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
      this.customZeichen = true;
    } else {
      this.customZeichen = false;
      this.generateTaktischesZeichen();
    }
  }

  private updateMannschaftsstaerke(groessenordnung?: EinheitId): void {
    this.fcMannschaftsstaerke1.setValue(
      groessenordnung &&
        ['abteilung', 'zug', 'zugtrupp', 'bereitschaft', 'abteilung', 'grossverband'].includes(groessenordnung)
        ? 1
        : 0
    );
    this.fcMannschaftsstaerke2.setValue(groessenordnung === 'gruppe' || groessenordnung === 'staffel' ? 1 : 0);
    this.fcMannschaftsstaerke3.setValue(!groessenordnung || groessenordnung === 'trupp' ? 1 : 0);
  }

  /**
   * PersonDTO Model von außen setzen
   */
  setDTO(personDTO: TaktischesZeichenDTO) {
    this.personToEdit = personDTO as PersonDTO;
    if (this.personToEdit) {
      this.dtoToFormGroup(this.personToEdit);
      if (this.personToEdit.customZeichen && this.personToEdit.dataUrl) {
        this.generateTaktischesZeichen(this.personToEdit.dataUrl);
        return;
      }
    }
    this.generateTaktischesZeichen();
  }

  /**
   * Aktualisiertes PersonDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): PersonDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): PersonDTO {
    return {
      ...this.personToEdit,
      anzeigename: this.fcAnzeigename.value.trim(),
      organisation: this.fcOrganisation.value?.id,
      fachaufgabe: this.fcFachaufgabe.value?.id,
      groessenordnung: this.fcGroessenordnung.value?.id,
      mannschaftsstaerke1: this.fcMannschaftsstaerke1.value || 0,
      mannschaftsstaerke2: this.fcMannschaftsstaerke2.value || 0,
      mannschaftsstaerke3: this.fcMannschaftsstaerke3.value || 0,
      verwaltungsstufe: this.fcVerwaltungsstufe.value?.id,
      dataUrl: this.currentZeichenDataUrl,
      ortsangabe: this.fcOrtsangabe.value?.trim(),
      funktion: this.fcFunktion.value?.id,
      funktionKurztext: this.fcFunktionKurztext.value?.trim(),
      vorname: this.fcVorname.value?.trim(),
      nachname: this.fcNachname.value?.trim(),
      dienstgrad: this.fcDienstgrad.value?.trim(),
      herkunft: this.fcHerkunft.value?.trim(),
      gliederung: this.fcGliederung.value?.trim(),
      kommunikationOptionen: this.kontaktListe.getKommunikationOptionen(),
      auftraege: this.auftragListe.getAuftraege(),
      customZeichen: this.customZeichen,
      typ: TaktischesZeichenTyp.Person,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(personDTO: PersonDTO) {
    this.fcAnzeigename.setValue(personDTO.anzeigename);
    this.fcOrtsangabe.setValue(personDTO.ortsangabe);
    this.fcFunktionKurztext.setValue(personDTO.funktionKurztext);
    this.fcVorname.setValue(personDTO.vorname);
    this.fcNachname.setValue(personDTO.nachname);
    this.fcDienstgrad.setValue(personDTO.dienstgrad);
    this.fcHerkunft.setValue(personDTO.herkunft);
    this.fcGliederung.setValue(personDTO.gliederung);
    this.fcOrganisation.setValue(organisationen.find((v) => v.id === personDTO.organisation));
    this.fcFachaufgabe.setValue(fachaufgaben.find((v) => v.id === personDTO.fachaufgabe));
    this.fcGroessenordnung.setValue(einheiten.find((v) => v.id === personDTO.groessenordnung));
    this.updateMannschaftsstaerke(this.fcGroessenordnung.value?.id);
    this.fcVerwaltungsstufe.setValue(verwaltungsstufen.find((v) => v.id === personDTO.verwaltungsstufe));
    this.fcFunktion.setValue(funktionen.find((v) => v.id === personDTO.funktion));
    this.fcMannschaftsstaerke1.setValue(personDTO.mannschaftsstaerke1 || 0);
    this.fcMannschaftsstaerke2.setValue(personDTO.mannschaftsstaerke2 || 0);
    this.fcMannschaftsstaerke3.setValue(personDTO.mannschaftsstaerke3 || 0);
    this.auftragListe.setAuftraege(personDTO.auftraege || []);
    this.customZeichen = personDTO.customZeichen || false;
  }

  /**
   * DataURL an Parent senden und dort aktualisieren.
   */
  generateTaktischesZeichen(dataUrl = '') {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
    } else {
      const zeichen = erzeugeTaktischesZeichen(this.editorZeichen);
      this.currentZeichenDataUrl = zeichen.dataUrl;
    }

    this.dataUrlChanged.emit({ dataUrl: this.currentZeichenDataUrl, customZeichen: this.customZeichen });
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
