import { Pipe, PipeTransform } from '@angular/core';
import { Funktion } from 'src/app/api/build/openapi';

@Pipe({
  name: 'funktion',
  standalone: true,
})
export class FunktionPipe implements PipeTransform {
  /**
   *
   * @returns liefert einen lesbaren String fuer Stabsfunktionen
   */
  transform(funktion: Funktion): string {
    if (!funktion) {
      return '';
    }
    switch (funktion) {
      case 'BEHOERDE':
        return 'Behörde';
      case 'EINSATZLEITUNG':
        return 'Einsatzleitung';
      case 'FACHKUNDIGER':
        return 'Fachkundiger';
      case 'FEUER_UND_KATASTROPHENSCHUTZ':
        return 'Feuer und Katastrophenschutz';
      case 'GEMEINDE':
        return 'Gemeinde';
      case 'GESUNDHEIT':
        return 'Gesundheit';
      case 'INNERER_DIENST_LEITER':
        return 'Innerer Dienst: Leiter';
      case 'LAGE_UND_DOKUMENTATION_LEITER':
        return 'Lage und Dokumentaion: Leiter';
      case 'LAGE_UND_DOKUMENTATION_MITARBEITER':
        return 'Lage und Dokumentation: Mitarbeiter';
      case 'LEITER':
        return 'Leiter';
      case 'MITARBEITER':
        return 'Mitarbeiter';
      case 'POLIZEI':
        return 'Polizei';
      case 'SCHREIBDIENST':
        return 'Schreibdienst';
      case 'SICHERHEIT_UND_ORDNUNG':
        return 'Sicherheit und Ordnung';
      case 'SOZIALES':
        return 'Soziales';
      case 'STELLVERTRETER':
        return 'Stellvertreter';
      case 'UMWELT':
        return 'Umwelt';
      default:
        return funktion;
    }
  }
}
