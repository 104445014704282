import { createReducer, on } from '@ngrx/store';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { FotoStateInterface } from './foto-state.interface';
import { fotoActions } from './foto.actions';

export const initialState: FotoStateInterface = {
  isCreating: false,
  isLoading: false,
  isPatching: false,
  isDeleting: false,
  fotos: [],
  loadedLageId: '',
};

export const fotoReducer = createReducer(
  initialState,

  // Reset Store
  on(fotoActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Fotos
  on(fotoActions.getFotos, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(fotoActions.getFotosSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    fotos: action.fotoDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(fotoActions.getFotosFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Foto
  on(fotoActions.createFoto, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(fotoActions.createFotoSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    fotos: [...state.fotos, action.createdFotoDTO],
    errorResponse: undefined,
  })),
  on(fotoActions.createFotoFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Foto
  on(fotoActions.patchFoto, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(fotoActions.patchFotoSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    fotos: replaceOrKeepCurrent(state.fotos, action.patchedFotoDTO),
    errorResponse: undefined,
  })),
  on(fotoActions.patchFotoFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Foto
  on(fotoActions.deleteFoto, (state) => ({ ...state, isDeleting: true })),
  on(fotoActions.deleteFotoSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    fotos: state.fotos.filter((foto) => foto.id !== action.deletedFotoDTO.id),
  })),
  on(fotoActions.deleteFotoFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  }))
);
