import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TaktischeFormationInfoDto } from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';

export interface TzClickedEvent {
  dto: TaktischesZeichenDTO;
  viewContainerRef: ViewContainerRef;
}

@Component({
  selector: 'app-taktische-zeichen-item',
  templateUrl: './taktische-zeichen-item.component.html',
  styleUrls: ['./taktische-zeichen-item.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatCardModule, MatTooltipModule],
})
export class TaktischeZeichenItemComponent implements OnChanges {
  @Input()
  item!: TaktischesZeichenDTO;

  parentFormationInfoDtos: TaktischeFormationInfoDto[] = [];

  @Input()
  selected = false;

  @Input()
  useDefaultTooltip = true;

  @Output()
  tzClicked = new EventEmitter<TzClickedEvent>();

  protected hovering = false;
  protected isVorlage = false;
  protected isUnavailable = false;

  constructor(private viewContainerRef: ViewContainerRef) {}
  onClickEdit(dto: TaktischesZeichenDTO) {
    this.tzClicked.emit({ dto: dto, viewContainerRef: this.viewContainerRef });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      const taktischesZeichen = changes['item'].currentValue;
      this.parentFormationInfoDtos = taktischesZeichen.parentFormationInfoDtos || [];
      this.isVorlage = taktischesZeichen.vorlage;
      this.isUnavailable = taktischesZeichen.unavailable;
    }
  }
}
