import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DefaultDateTimePipe } from '../pipes/default-date-time.pipe';
import { NatoDateTimePipe } from '../pipes/nato-date-time.pipe';
import { NatoZuluDateTimePipe } from '../pipes/nato-long-date-time.pipe';

export enum LageDateTimeFormat {
  NORMAL = 'Normal (01.01.1970 12:00)',
  NATO = 'NATO (011200jan70)',
  NATO_ZULU = 'NATO Zulu (011200Z jan 70)',
}

@Injectable({
  providedIn: 'root',
})
export class FormatService {
  private readonly LOCAL_STORAGE_KEY = 'datetime-format';
  timeFormat$ = new BehaviorSubject<PipeTransform>(this.natoTimePipe);

  formatMap: Map<LageDateTimeFormat, PipeTransform>;

  constructor(
    private defaultTimePipe: DefaultDateTimePipe,
    private natoTimePipe: NatoDateTimePipe,
    private natoZuluPipe: NatoZuluDateTimePipe
  ) {
    this.formatMap = new Map<LageDateTimeFormat, PipeTransform>([
      [LageDateTimeFormat.NORMAL, this.defaultTimePipe],
      [LageDateTimeFormat.NATO, this.natoTimePipe],
      [LageDateTimeFormat.NATO_ZULU, this.natoZuluPipe],
    ]);

    this.setLageDateTimeFormat(localStorage.getItem(this.LOCAL_STORAGE_KEY) as LageDateTimeFormat);
  }

  getLageDateTimeFormat(): LageDateTimeFormat {
    return localStorage.getItem(this.LOCAL_STORAGE_KEY) as LageDateTimeFormat;
  }

  setLageDateTimeFormat(format: LageDateTimeFormat) {
    const pipe = this.formatMap.get(format);
    if (pipe) {
      this.timeFormat$.next(pipe);
      localStorage.setItem(this.LOCAL_STORAGE_KEY, format);
    } else {
      // default
      // TODO Warnung ausgeben?
      this.timeFormat$.next(this.natoTimePipe);
    }
  }
}
