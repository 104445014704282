import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorService } from '@product/ise-error-lib';
import { TagebucheintragDTO } from 'src/app/api/build/openapi';

export interface TagebucheintragDialogInputData {
  newEntry: boolean;
  tagebucheintrag: TagebucheintragDTO;
}

@Component({
  selector: 'app-tagebucheintrag-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './tagebucheintrag-dialog.component.html',
  styleUrls: ['./tagebucheintrag-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagebucheintragDialogComponent {
  // Model
  tagebucheintrag: TagebucheintragDTO;
  newEntry = false;

  private formBuilder = inject(NonNullableFormBuilder);
  fcFreitext = this.formBuilder.control<string | undefined>(undefined, [Validators.required]);
  formGroup = this.formBuilder.group({
    freitext: this.fcFreitext,
  });

  constructor(
    private dialogRef: MatDialogRef<TagebucheintragDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: TagebucheintragDialogInputData,
    private errorService: ErrorService
  ) {
    this.tagebucheintrag = data.tagebucheintrag as TagebucheintragDTO;
    this.newEntry = data.newEntry as boolean;
    this.fcFreitext.setValue(this.tagebucheintrag.freitext);
  }

  /**
   * Eingaben validieren und fehlerhafte Felder markieren.
   */
  validate() {
    if (this.formGroup.valid) {
      return true;
    }
    this.formGroup.markAllAsTouched();
    return false;
  }

  /**
   * Schließt den Dialog und liefert einen Tagebucheintrag als DialogResult zurück
   */
  save() {
    if (this.validate()) {
      // Wenn neuer Tagebucheintrag, Datum/Uhrzeit setzen
      const datum = this.tagebucheintrag.datum ? this.tagebucheintrag.datum : new Date().toISOString();
      const resultModel = {
        ...this.tagebucheintrag,
        datum: datum,
        freitext: this.fcFreitext.value?.trim(),
      };
      this.dialogRef.close(resultModel);
    }
  }

  getErrorMessage(formControlName: string): string {
    const formControl = this.formGroup.get(formControlName);
    if (!formControl) {
      return '';
    }
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
