<mat-card class="list-card">
  <mat-toolbar class="list-header">
    <h3>{{ headerText }}</h3>
    <span>
      <button mat-icon-button (click)="openEinsatzfilterSelectDialog()" matTooltip="Einsatzfilter auswählen">
        <mat-icon>checklist</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="openEinsatzfilterMoveDialog()"
        [disabled]="selectedIndex < 0 || !fuehrungsebeneDTO?.id"
        matTooltip="Einsatzfilter verschieben"
      >
        <mat-icon>move_down</mat-icon>
      </button>
      <button mat-icon-button (click)="askDelete()" [disabled]="selectedIndex < 0" matTooltip="Einsatzfilter entfernen">
        <mat-icon>delete</mat-icon>
      </button>
    </span>
  </mat-toolbar>

  <mat-card-content *ngIf="einsatzfilterDTOs$ | async as einsatzfilter">
    <mat-action-list>
      <mat-list-item
        *ngFor="let filter of einsatzfilter; let index = index"
        (click)="selectedIndex = index"
        [ngClass]="{ 'selected-list-row': selectedIndex === index }"
      >
        <div class="summary">
          <span>{{ filter.name }}</span>
        </div>
      </mat-list-item>
    </mat-action-list>

    <h3 class="keine-daten-text" *ngIf="!einsatzfilter.length">Keine Einsatzfilter ausgewählt</h3>
  </mat-card-content>
</mat-card>
