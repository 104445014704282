import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { LogService } from 'src/app/shared/services/log.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AppService, ApplicationType } from '../../app.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPageComponent implements OnInit {
  isLoggedIn = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private logger: LogService,
    private snackbar: SnackbarService,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe({
      next: (v) => {
        this.isLoggedIn = v;
        if (v) {
          this.appService
            .selectedApplicationObservable()
            .pipe(first())
            .subscribe((a) => {
              switch (a) {
                case undefined:
                  this.router.navigateByUrl('/program-select');
                  break;
                case ApplicationType.LAGE:
                  this.router.navigate(['lagen']);
                  break;
                case ApplicationType.LAGEK:
                  this.router.navigate(['lage-k']);
                  break;
              }
            });
        }
      },
      error: (e) => {
        this.logger.error(e);
        this.snackbar.showLong('Authentifizierung fehlgeschlagen');
      },
    });
  }

  toggleLogin(): void {
    if (this.isLoggedIn) {
      this.logout();
    } else {
      this.login();
    }
  }

  private login() {
    this.authService.login();
  }

  private logout() {
    this.authService.logout();
  }
}
