import { DatePipe, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';

import { AnwesenheitDTO, Bereich, Funktion, Prioritaet, Rolle, StabMassnahmeDTO } from 'src/app/api/build/openapi';
import { LagePrio } from 'src/app/lagedarstellung/massnahmen/massnahme-add-dialog/massnahme-add-dialog.component';
import { AnwesenheitslisteService } from 'src/app/anwesenheitsliste/anwesenheitsliste.service';
import { BereichPipe } from 'src/app/shared/pipes/bereich.pipe';
import { FunktionPipe } from 'src/app/shared/pipes/funktion.pipe';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  Validators,
  NonNullableFormBuilder,
} from '@angular/forms';
import { MassnahmenService } from 'src/app/lagedarstellung/massnahmen.service';

@Component({
  selector: 'app-stab-massnahme-add-dialog',
  templateUrl: './stab-massnahme-add-dialog.component.html',
  styleUrls: ['./stab-massnahme-add-dialog.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    TextFieldModule,
    NgIf,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    MatCardModule,
    KeyValuePipe,
    FunktionPipe,
    BereichPipe,
  ],
})
export class StabMassnahmeAddDialogComponent {
  private pipeDe: DatePipe = new DatePipe('de-DE');

  protected anwesenheitService = inject(AnwesenheitslisteService);

  roleTextMapping: Map<Rolle, string> = MassnahmenService.rolleMapping;
  prioMapping: Map<Prioritaet, LagePrio> = MassnahmenService.prioMapping;

  Prioritaet = Prioritaet;
  Funktionen = Funktion;
  Bereiche = Bereich;

  isEdit = false;

  private fb: NonNullableFormBuilder = inject(NonNullableFormBuilder);
  massnahmeForm = this.fb.group({
    anzeigename: ['', Validators.required],
    text: ['', Validators.required],
    prioritaet: [Prioritaet.Normal, Validators.required],
    erledigt: [false, Validators.required],
    zuErledigenFunktion: this.fb.control<Funktion | undefined>(undefined),
    zuErledigenBereich: this.fb.control<Bereich | undefined>(undefined),
    zuErledigenDatum: [''],
    bisWannAnwesenheit: this.fb.control<AnwesenheitDTO | undefined>(undefined),
    bisWannDatum: [''],
    erledigtDurchAnwesenheit: this.fb.control<AnwesenheitDTO | undefined>(undefined),
    erledigtDurchDatum: [''],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StabMassnahmeDTO,
    private matDialogRef: MatDialogRef<StabMassnahmeAddDialogComponent>
  ) {
    this.massnahmeForm.controls.erledigtDurchAnwesenheit.disable();
    this.massnahmeForm.controls.erledigtDurchDatum.disable();

    if (data != null) {
      this.massnahmeToForm(data);
      this.isEdit = true;
      this.massnahmeForm.controls.erledigt.disable();

      if (data.erledigt) {
        this.massnahmeForm.disable();
      }
    }
  }

  onClickAbbrechen() {
    this.matDialogRef.close(undefined);
  }

  onClickSpeichern() {
    if (this.massnahmeForm.valid) {
      this.matDialogRef.close(this.getMassnahmeFromForm());
    } else {
      this.massnahmeForm.markAllAsTouched();
    }
  }

  private getMassnahmeFromForm(): StabMassnahmeDTO {
    const massnahme: StabMassnahmeDTO = {
      anzeigename: this.massnahmeForm.value.anzeigename,
      text: this.massnahmeForm.value.text,
      prioritaet: this.massnahmeForm.value.prioritaet,
      erledigt: this.massnahmeForm.value.erledigt,
      zuErledigenFunktion: this.massnahmeForm.value.zuErledigenFunktion,
      zuErledigenBereich: this.massnahmeForm.value.zuErledigenBereich,
    };
    massnahme.bisWannAnwesenheitId = this.massnahmeForm.value.bisWannAnwesenheit?.id;
    massnahme.erledigtDurchAnwesenheitId = this.massnahmeForm.value.erledigtDurchAnwesenheit?.id;

    const zuErledigenDatum = this.massnahmeForm.value.zuErledigenDatum;
    const bisWannDatum = this.massnahmeForm.value.bisWannDatum;
    const erledigtDurchDatum = this.massnahmeForm.value.erledigtDurchDatum;
    if (zuErledigenDatum) {
      massnahme.zuErledigenDatum = new Date(zuErledigenDatum).toISOString();
    }
    if (bisWannDatum) {
      massnahme.bisWannDatum = new Date(bisWannDatum).toISOString();
    }
    if (erledigtDurchDatum) {
      massnahme.erledigtDurchDatum = new Date(erledigtDurchDatum).toISOString();
    }

    if (this.isEdit) {
      massnahme.id = this.data.id;
      massnahme.lageId = this.data.lageId;
      massnahme.lastUpdatedOn = this.data.lastUpdatedOn;
      massnahme.lastUpdatedBy = this.data.lastUpdatedBy;
      massnahme.abgelaufen = this.data.abgelaufen;
      massnahme.version = this.data.version;
    }

    return massnahme;
  }

  private massnahmeToForm(massnahme: StabMassnahmeDTO) {
    if (massnahme) {
      this.massnahmeForm.patchValue({
        anzeigename: massnahme.anzeigename,
        text: massnahme.text,
        erledigt: massnahme.erledigt,
        zuErledigenFunktion: massnahme.zuErledigenFunktion,
        zuErledigenBereich: massnahme.zuErledigenBereich,
        prioritaet: massnahme.prioritaet,
        zuErledigenDatum: massnahme.zuErledigenDatum ? this.toDateTimeLocal(new Date(massnahme.zuErledigenDatum)) : '',
        bisWannAnwesenheit: massnahme.bisWannAnwesenheitId
          ? this.anwesenheitService.getAnweisenheitById(massnahme.bisWannAnwesenheitId)
          : undefined,
        bisWannDatum: massnahme.bisWannDatum ? this.toDateTimeLocal(new Date(massnahme.bisWannDatum)) : '',
        erledigtDurchAnwesenheit: massnahme.erledigtDurchAnwesenheitId
          ? this.anwesenheitService.getAnweisenheitById(massnahme.erledigtDurchAnwesenheitId)
          : undefined,
        erledigtDurchDatum: massnahme.erledigtDurchDatum
          ? this.toDateTimeLocal(new Date(massnahme.erledigtDurchDatum))
          : '',
      });

      if (massnahme.erledigt) {
        this.massnahmeForm.disable();
      }
    }
  }

  private toDateTimeLocal(date: Date): string {
    return this.pipeDe.transform(date, 'yyyy-MM-ddTHH:mm')!.toString();
  }
}
