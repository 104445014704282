import { NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorService } from '@product/ise-error-lib';
import { catchError, EMPTY } from 'rxjs';
import {
  AnlassResourceService,
  BefehlsstelleResourceService,
  FahrzeugResourceService,
  FotoResourceService,
  FuehrungsebeneMassnahmeResourceService,
  GebaeudeResourceService,
  GebietResourceService,
  GefahrResourceService,
  MultiplyDto,
  PersonenschadenResourceService,
  PersonResourceService,
  StelleResourceService,
  TaktischeFormationDTO,
  TaktischeFormationResourceService,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
  TierschadenResourceService,
} from 'src/app/api/build/openapi';

export interface MultiplyDialogInputData {
  tzDto: TaktischesZeichenDTO;
}

@Component({
  selector: 'app-multiply-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatButtonModule,
    NgIf,
    MatIconModule,
  ],
  templateUrl: './multiply-dialog.component.html',
  styleUrl: './multiply-dialog.component.scss',
})
export class MultiplyDialogComponent implements OnInit {
  TaktischesZeichenTyp = TaktischesZeichenTyp;

  protected errorService = inject(ErrorService);
  protected inputData: MultiplyDialogInputData = inject(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef);
  private formBuilder = inject(NonNullableFormBuilder);

  private anlassResourceService = inject(AnlassResourceService);
  private befehlsstelleResourceService = inject(BefehlsstelleResourceService);
  private fahrzeugResourceService = inject(FahrzeugResourceService);
  private fotoResourceService = inject(FotoResourceService);
  private gebaeudeResourceService = inject(GebaeudeResourceService);
  private gebietResourceService = inject(GebietResourceService);
  private gefahrResourceService = inject(GefahrResourceService);
  private massnahmeResourceService = inject(FuehrungsebeneMassnahmeResourceService);
  private personResourceService = inject(PersonResourceService);
  private personenschadenResourceService = inject(PersonenschadenResourceService);
  private stelleResourceService = inject(StelleResourceService);
  private taktischeFormationResourceService = inject(TaktischeFormationResourceService);
  private tierschadenResourceService = inject(TierschadenResourceService);

  isBibFormation = false;

  fcCount = this.formBuilder.control<number>(1, [Validators.min(1), Validators.max(10)]);
  fcRecursive = this.formBuilder.control<boolean>(true);
  fcReference = this.formBuilder.control<boolean>(false);

  isSaving = false;

  constructor() {
    this.fcRecursive.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (v) {
        this.fcReference.enable();
      } else {
        this.fcReference.disable();
        this.fcReference.setValue(false);
      }
    });
  }

  ngOnInit(): void {
    this.isBibFormation =
      this.inputData.tzDto.typ === TaktischesZeichenTyp.TaktischeFormation &&
      !!(this.inputData.tzDto as TaktischeFormationDTO).lageId;
  }

  multiply() {
    if (!this.inputData.tzDto.id) {
      console.warn('TZ kann nicht dupliziert werden, da keine Id vorhanden ist.');
      return;
    }

    if (!this.fcCount.valid) {
      this.fcCount.markAsTouched();
      return;
    }

    const multiplyDto: MultiplyDto = {
      entityId: this.inputData.tzDto.id,
      count: this.fcCount.value,
      recursive: this.fcRecursive.value,
      referenceChildren: this.fcReference.value,
    };

    const request = this.createCopyRequest(this.inputData.tzDto.typ, multiplyDto);
    if (request) {
      this.isSaving = true;
      request
        .pipe(
          catchError((errorResponse) => {
            this.isSaving = false;
            this.errorService.showError({ errorMessages: [errorResponse.statusText] });
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.dialogRef.close();
        });
    }
  }

  private createCopyRequest(tzTyp: TaktischesZeichenTyp, multiplyDto: MultiplyDto) {
    // Wird von API benötigt, wird aber nicht genutzt
    const dummyLageId = '-1';

    switch (tzTyp) {
      case TaktischesZeichenTyp.Anlass:
        return this.anlassResourceService.multiplyAnlass(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Befehlsstelle:
        return this.befehlsstelleResourceService.multiplyBefehlsstelle(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Fahrzeug:
        return this.fahrzeugResourceService.multiplyFahrzeug(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Foto:
        return this.fotoResourceService.multiplyFoto(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Gebaeude:
        return this.gebaeudeResourceService.multiplyGebaeude(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Gebiet:
        return this.gebietResourceService.multiplyGebiet(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Gefahr:
        return this.gefahrResourceService.multiplyGefahr(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Massnahme:
        return this.massnahmeResourceService.multiplyFuehrungsebeneMassnahme(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Person:
        return this.personResourceService.multiplyPerson(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Personenschaden:
        return this.personenschadenResourceService.multiplyPersonenschaden(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Stelle:
        return this.stelleResourceService.multiplyStelle(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.TaktischeFormation:
        return this.taktischeFormationResourceService.multiplyTaktischeFormation(dummyLageId, multiplyDto);
      case TaktischesZeichenTyp.Tierschaden:
        return this.tierschadenResourceService.multiplyTierschaden(dummyLageId, multiplyDto);
    }
    return undefined;
  }
}
