import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { currentLageSelector } from '../../lagedarstellung/lagen/+state/lage.selectors';
import { LageInfobarComponent } from '../../lagedarstellung/lagen/lage-infobar/lage-infobar.component';
import { ComponentQualificationService } from '../../misc/component-qualification.service';

@Component({
  selector: 'app-lage-k-navigation',
  standalone: true,
  imports: [CommonModule, RouterModule, MatButtonModule, LageInfobarComponent],
  templateUrl: './lage-k-navigation.component.html',
  styleUrls: ['./lage-k-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LageKNavigationComponent {
  protected router = inject(Router);
  private store = inject(Store);
  private componentService = inject(ComponentQualificationService);
  protected isShowDevelopment$ = this.componentService.isShowDevelopment$;
  protected currentUrl = '/lagedarstellung';

  protected currentLageSelected$ = this.store.select(currentLageSelector).pipe(
    takeUntilDestroyed(),
    map((value) => value !== null)
  );

  constructor() {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    this.router.events.pipe(takeUntilDestroyed()).subscribe((event: any) => {
      if (event.url) {
        this.currentUrl = event.url;
      }
    });
  }
}
