/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Witterung = 'WOLKENLOS' | 'BEWOELKT' | 'SCHAUER' | 'REGEN' | 'GEWITTER' | 'SCHNEE';

export const Witterung = {
    Wolkenlos: 'WOLKENLOS' as Witterung,
    Bewoelkt: 'BEWOELKT' as Witterung,
    Schauer: 'SCHAUER' as Witterung,
    Regen: 'REGEN' as Witterung,
    Gewitter: 'GEWITTER' as Witterung,
    Schnee: 'SCHNEE' as Witterung
};

