import { TextFieldModule } from '@angular/cdk/text-field';
import { DatePipe, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';

import { LageMassnahmeDTO, Prioritaet, Rolle, Sachgebiet } from 'src/app/api/build/openapi';
import { MassnahmenAddDialogData, MassnahmenService } from '../../massnahmen.service';

export interface LagePrio {
  name: string;
  wert: number;
  icon: string;
  farbe: string;
}

@Component({
  selector: 'app-massnahme-add-dialog',
  templateUrl: './massnahme-add-dialog.component.html',
  styleUrls: ['./massnahme-add-dialog.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    TextFieldModule,
    NgIf,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    MatCardModule,
    KeyValuePipe,
  ],
})
export class MassnahmeAddDialogComponent {
  private pipeDe: DatePipe = new DatePipe('de-DE');

  roleTextMapping: Map<Rolle, string> = MassnahmenService.rolleMapping;
  prioMapping: Map<Prioritaet, LagePrio> = MassnahmenService.prioMapping;

  Rolle = Rolle;
  Sachgebiet = Sachgebiet;
  Prioritaet = Prioritaet;

  isEdit = false;

  massnahmeForm = inject(FormBuilder).group({
    id: [''],
    version: [0],
    lageId: [''],
    abgelaufen: [false],
    prioritaet: [Prioritaet.Normal],
    erledigt: [{ value: false, disabled: true }],
    zuErledigenSachgebiet: [Sachgebiet.S1],
    zuErledigenDatum: [''],
    bisWannRolle: [Rolle.Einsatzleiter],
    bisWannName: [''],
    bisWannDatum: ['', [Validators.required]],
    erledigtDurchRolle: [{ value: Rolle.Einsatzleiter, disabled: true }],
    erledigtDurchName: [{ value: '', disabled: true }],
    erledigtDurchDatum: [{ value: '', disabled: true }],
    text: [''],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MassnahmenAddDialogData,
    private matDialogRef: MatDialogRef<MassnahmeAddDialogComponent>,
    protected massnahmenService: MassnahmenService
  ) {
    if (data != null) {
      this.massnahmeToForm(data.massnahme);
      this.isEdit = true;
    }
  }

  onClickAbbrechen() {
    this.matDialogRef.close(undefined);
  }

  onClickSpeichern() {
    if (this.massnahmeForm.valid) {
      this.matDialogRef.close(this.getMassnahmeFromForm());
    } else {
      this.massnahmeForm.markAllAsTouched();
    }
  }

  private getMassnahmeFromForm(): LageMassnahmeDTO {
    const massnahme: LageMassnahmeDTO = {
      abgelaufen: this.massnahmeForm.get('abgelaufen')?.value || undefined,
      bisWannName: this.massnahmeForm.get('bisWannName')?.value || undefined,
      bisWannRolle: this.massnahmeForm.get('bisWannRolle')?.value || undefined,
      erledigt: this.massnahmeForm.get('erledigt')?.value || undefined,
      erledigtDurchName: this.massnahmeForm.get('erledigtDurchName')?.value || undefined,
      id: this.massnahmeForm.get('id')?.value || undefined,
      erledigtDurchRolle: this.massnahmeForm.get('erledigtDurchRolle')?.value || undefined,
      lageId: this.massnahmeForm.get('lageId')?.value || undefined,
      prioritaet: this.massnahmeForm.get('prioritaet')?.value || undefined,
      text: this.massnahmeForm.get('text')?.value || undefined,
      version: this.massnahmeForm.get('version')?.value || undefined,
      zuErledigenSachgebiet: this.massnahmeForm.get('zuErledigenSachgebiet')?.value || undefined,
    };

    const zuErledigenDatum = this.massnahmeForm.get('bisWannDatum')?.value;
    const bisWannDatum = this.massnahmeForm.get('bisWannDatum')?.value;
    const erledigtDurchDatum = this.massnahmeForm.get('erledigtDurchDatum')?.value;
    if (zuErledigenDatum) {
      massnahme.zuErledigenDatum = new Date(zuErledigenDatum).toISOString();
    }
    if (bisWannDatum) {
      massnahme.bisWannDatum = new Date(bisWannDatum).toISOString();
    }
    if (erledigtDurchDatum) {
      massnahme.erledigtDurchDatum = new Date(erledigtDurchDatum).toISOString();
    }
    return massnahme;
  }

  private massnahmeToForm(massnahme: LageMassnahmeDTO) {
    if (massnahme) {
      this.massnahmeForm.get('id')?.setValue(massnahme.id || null);
      this.massnahmeForm.get('version')?.setValue(massnahme.version || null);
      this.massnahmeForm.get('lageId')?.setValue(massnahme.lageId || null);
      this.massnahmeForm.get('prioritaet')?.setValue(massnahme.prioritaet || null);
      this.massnahmeForm.get('erledigt')?.setValue(massnahme.erledigt || null);
      this.massnahmeForm.get('abgelaufen')?.setValue(massnahme.abgelaufen || null);
      this.massnahmeForm.get('text')?.setValue(massnahme.text || null);
      this.massnahmeForm.get('zuErledigenSachgebiet')?.setValue(massnahme.zuErledigenSachgebiet || null);
      this.massnahmeForm.get('bisWannRolle')?.setValue(massnahme.bisWannRolle || null);
      this.massnahmeForm.get('bisWannName')?.setValue(massnahme.bisWannName || null);
      this.massnahmeForm.get('erledigtDurchRolle')?.setValue(massnahme.erledigtDurchRolle || null);
      this.massnahmeForm.get('erledigtDurchName')?.setValue(massnahme.erledigtDurchName || null);

      if (massnahme.bisWannDatum) {
        this.massnahmeForm
          .get('bisWannDatum')
          ?.setValue(this.toDateTimeLocal(new Date(massnahme.bisWannDatum)) || null);
      }

      if (massnahme.erledigtDurchDatum) {
        this.massnahmeForm
          .get('erledigtDurchDatum')
          ?.setValue(this.toDateTimeLocal(new Date(massnahme.erledigtDurchDatum)) || null);
      }

      if (massnahme.zuErledigenDatum) {
        this.massnahmeForm
          .get('zuErledigenDatum')
          ?.setValue(this.toDateTimeLocal(new Date(massnahme.zuErledigenDatum)) || null);
      }

      if (massnahme.erledigt) {
        this.massnahmeForm.disable();
      }
    }
  }

  private toDateTimeLocal(date: Date): string {
    return this.pipeDe.transform(date, 'yyyy-MM-ddTHH:mm')?.toString() || '';
  }
}
