import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, WetterDTO } from 'src/app/api/build/openapi';

export const wetterActions = createActionGroup({
  source: 'Wetter',
  events: {
    'Reset Store': emptyProps(),

    'Get Wetter': props<{ lageId: string }>(),
    'Get Wetter Success': props<{ wetter: WetterDTO; lageId: string }>(),
    'Get Wetter Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Wetter': props<{ lageId: string; wetter: WetterDTO }>(),
    'Create Wetter Success': props<{ createdWetter: WetterDTO }>(),
    'Create Wetter Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Wetter': props<{ wetter: WetterDTO }>(),
    'Patch Wetter Success': props<{ patchedWetter: WetterDTO }>(),
    'Patch Wetter Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
