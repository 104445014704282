import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { FuehrungsebeneDTO, TaktischesZeichenStatus } from 'src/app/api/build/openapi';
import { TaktischeZeichenContainerComponent } from '../../taktische-zeichen/taktische-zeichen-container/taktische-zeichen-container.component';
import { FuehrungsebeneHeaderComponent } from '../fuehrungsebene-header/fuehrungsebene-header.component';
import { FuehrungsebeneService } from '../fuehrungsebene.service';

/**
 * Führungsebene Panel, das neben der Karte angezeigt wird (Bis zu 5x)
 * Zeigt den Namen und eine Übersicht der Gefahren/Personen/Einheiten/Einsätze in Form von Taktischen Zeichen an.
 */
@Component({
  selector: 'app-fuehrungsebene-card',
  templateUrl: './fuehrungsebene-card.component.html',
  styleUrls: ['./fuehrungsebene-card.component.scss'],
  standalone: true,
  imports: [MatCardModule, FuehrungsebeneHeaderComponent, TaktischeZeichenContainerComponent],
})
export class FuehrungsebeneCardComponent {
  @Input()
  fuehrungsebeneDTO: FuehrungsebeneDTO | null = null;

  @Output()
  edit = new EventEmitter();

  constructor(protected fuehrungsebeneService: FuehrungsebeneService) {}

  TaktischesZeichenStatus = TaktischesZeichenStatus;
}
