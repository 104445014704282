import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, throwError } from 'rxjs';
import { ErrorResponse } from 'src/app/api/build/openapi';
import { LageMassnahmeResourceService } from '../../../api/build/openapi/api/lageMassnahmeResource.service';
import { lageActions } from '../../lagen/+state/lage.actions';
import { lageMassnahmeActions } from './lage-massnahme.actions';

@Injectable()
export class LageMassnahmeEffects {
  /**
   * Wenn Sich die aktuelle Lage ändert, Neuladen der Wetterinformation anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return lageMassnahmeActions.getLageMassnahmen({ lageId: props.currentLage.id });
        } else {
          return lageMassnahmeActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle LageMassnahmen einer Lage laden
   */
  getMassnahmen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageMassnahmeActions.getLageMassnahmen),
      switchMap((props) =>
        this.lageMassnahmeResourceService.getLageMassnahmenByLageId(props.lageId).pipe(
          map((result) => lageMassnahmeActions.getLageMassnahmenSuccess({ massnahmen: result, lageId: props.lageId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(lageMassnahmeActions.getLageMassnahmenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neue LageMassnahme anlegen
   */
  addMassnahme$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(lageMassnahmeActions.addLageMassnahme),
        switchMap((props) => {
          if (!props.lageId) {
            return throwError(() => new Error('LageMassnahme muss eine lageId besitzen'));
          }
          return this.lageMassnahmeResourceService.createLageMassnahme(props.lageId, props.massnahme);
        })
      )
      .pipe(
        map((massnahme) => lageMassnahmeActions.addLageMassnahmeSuccess({ massnahme })),
        catchError((error: HttpErrorResponse) => {
          const errorResponse: ErrorResponse = error.error;
          return of(lageMassnahmeActions.addLageMassnahmeFailure({ errorResponse }));
        })
      )
  );

  /**
   * Existierende LageMassnahme editieren
   */
  patchMassnahme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageMassnahmeActions.patchLageMassnahme),
      switchMap((props) => {
        return this.lageMassnahmeResourceService
          .patchLageMassnahme(props.lageid, props.massnahmeId, props.massnahme)
          .pipe(
            map((patchedMassnahme) => lageMassnahmeActions.patchLageMassnahmeSuccess({ patchedMassnahme })),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(lageMassnahmeActions.patchLageMassnahmeFailure({ errorResponse }));
            })
          );
      })
    )
  );

  constructor(private actions$: Actions, private lageMassnahmeResourceService: LageMassnahmeResourceService) {}
}
