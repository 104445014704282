import { Injectable } from '@angular/core';
import { Gebietsauspraegung, Gebietstyp } from 'src/app/api/build/openapi';

@Injectable({
  providedIn: 'root',
})
export class GebietService {
  readonly gebietstypMapping = new Map<Gebietstyp, string>([
    [Gebietstyp.Flaechenbrand, 'Flächenbrand'],
    [Gebietstyp.Ueberschwemmung, 'Überschwemmung'],
    [Gebietstyp.Duerre, 'Dürre'],
    [Gebietstyp.Ausfall, 'Einschränkung/Ausfall der Versorgung'],
    [Gebietstyp.Kontamination, 'Kontamination'],
    [Gebietstyp.Sonstiges, 'Sonstiges'],
  ]);

  readonly gebietsauspraegungMapping = new Map<Gebietsauspraegung, string>([
    [Gebietsauspraegung.Akut, 'Akute Gefahr'],
    [Gebietsauspraegung.Drohend, 'Drohende Gefahr'],
    [Gebietsauspraegung.Ehemals, 'Noch/Ehemals betroffen'],
  ]);
}
