/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Gebietsauspraegung = 'DROHEND' | 'AKUT' | 'EHEMALS';

export const Gebietsauspraegung = {
    Drohend: 'DROHEND' as Gebietsauspraegung,
    Akut: 'AKUT' as Gebietsauspraegung,
    Ehemals: 'EHEMALS' as Gebietsauspraegung
};

