<ng-container *ngIf="personenuebersicht$ | async as personen">
  <mat-toolbar class="header">
    <h4>MANV-Sichtung</h4>
  </mat-toolbar>

  <div class="columns">
    <div class="column headers">
      <div class="column-data">
        <h5>Verletzte</h5>
      </div>
      <div class="column-header">
        <h5>Sichtungsk.</h5>
      </div>
    </div>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ personen.manv1 }}</h5>
      </div>
      <div class="column-header category1" matTooltip="Akute, vitale Bedrohung">
        <h5>I</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ personen.manv2 }}</h5>
      </div>
      <div class="column-header category2" matTooltip="Schwer verletzt/erkrankt">
        <h5>II</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ personen.manv3 }}</h5>
      </div>
      <div class="column-header category3" matTooltip="Leicht verletzt/erkrankt">
        <h5>III</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ (personen.manv1 || 0) + (personen.manv2 || 0) + (personen.manv3 || 0) }}</h5>
      </div>
      <div class="column-header sum" matTooltip="Summe aus Kategorien I, II, III">
        <h5>SUMME</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ personen.manv4 }}</h5>
      </div>
      <div class="column-header category4" matTooltip="Ohne Überlebenschance">
        <h5>IV</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ personen.manvTote }}</h5>
      </div>
      <div class="column-header dead" matTooltip="Tote">
        <h5>TOTE</h5>
      </div>
    </mat-card>
  </div>
</ng-container>
