import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Observable, debounceTime } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneDTO, Fuehrungsebenentyp } from 'src/app/api/build/openapi';
import { LoadableComponentComponent } from 'src/app/shared/components/loadable-component/loadable-component.component';
import {
  currentFuehrungsebeneSelector,
  currentLogistikChildrenSelector,
  currentLogistikSelector,
  isLoadingFuehrungsebeneSelector,
} from '../../+state/fuehrungsebene.selectors';
import {
  FuehrungsebeneDetailsDialogComponent,
  FuehrungsebeneDialogData,
} from '../../fuehrungsebene-details-dialog/fuehrungsebene-details-dialog.component';
import { FuehrungsebeneHeaderComponent } from '../../fuehrungsebene-header/fuehrungsebene-header.component';
import { FuehrungsebeneService } from '../../fuehrungsebene.service';
import { LogistikDialogComponent } from '../logistik-dialog/logistik-dialog.component';

/**
 * Kleine Logistik-Komponente als Custom-Element auf Monitor 2.
 * Existiert für den Current-Führungsebene noch keine Logistik, kann über man sie über einen Button hinzufügen.
 * Manche Führungsebenentypen dürfen keine Logistik haben. Für Current-Führungsebenen dieser Typen bleibt die Komponente leer.
 */
@Component({
  selector: 'app-logistik-small',
  templateUrl: './logistik-small.component.html',
  styleUrls: ['./logistik-small.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FuehrungsebeneHeaderComponent,
    MatTooltipModule,
    NgFor,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    AsyncPipe,
    LoadableComponentComponent,
  ],
})
export class LogistikSmallComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  Fuehrungsebenentyp = Fuehrungsebenentyp;

  currentFuehrungsebene: FuehrungsebeneDTO | null = null;
  logistikDTO?: FuehrungsebeneDTO;
  logistikChildren: FuehrungsebeneDTO[] = [];

  logistikButtonVisible = false;
  logistikButtonText = '';
  logistikMessageVisible = false;

  isLoadingFuehrungsebenen$: Observable<boolean>;
  protected fuehrungsebeneService = inject(FuehrungsebeneService);

  constructor(private dialog: MatDialog, private store: Store<AppStateInterface>) {
    this.isLoadingFuehrungsebenen$ = this.store
      .select(isLoadingFuehrungsebeneSelector)
      .pipe(takeUntilDestroyed(), debounceTime(500));
  }

  ngOnInit(): void {
    this.store
      .select(currentFuehrungsebeneSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentFuehrungsebene) => {
        this.currentFuehrungsebene = currentFuehrungsebene;
        this.updateLogistikButton();
      });

    this.store
      .select(currentLogistikSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((logistik) => {
        this.logistikDTO = logistik.length ? logistik[0] : undefined;
        this.updateLogistikButton();
      });

    this.store
      .select(currentLogistikChildrenSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((logistikChildren) => {
        this.logistikChildren = logistikChildren;
        this.updateLogistikButton();
      });
  }

  /**
   * Logistik-Button erlaubt das Hinzufügen einer Logistik-Führungsebene zum currentFuehrungsebene.
   * Current-Führungsebene muss aber vom Typ SG, ER oder ESt sein und darf noch keine Logistik besitzen
   */
  updateLogistikButton() {
    const logistikAllowedForCurrentFuehrungsebene =
      !!this.currentFuehrungsebene &&
      (this.currentFuehrungsebene.typ === Fuehrungsebenentyp.Schadengebiet ||
        this.currentFuehrungsebene.typ === Fuehrungsebenentyp.Einsatzstelle ||
        this.currentFuehrungsebene.typ === Fuehrungsebenentyp.Einsatzraum);

    // Button anzeigen, wenn keine Logistik vorhanden und der Typ der Current-Führungsebene passt
    this.logistikButtonVisible = !this.logistikDTO && logistikAllowedForCurrentFuehrungsebene;
    this.logistikMessageVisible = !this.logistikButtonVisible && !logistikAllowedForCurrentFuehrungsebene;

    // Update button text
    if (
      this.currentFuehrungsebene?.typ === Fuehrungsebenentyp.Schadengebiet ||
      this.currentFuehrungsebene?.typ === Fuehrungsebenentyp.Einsatzraum
    ) {
      this.logistikButtonText =
        this.fuehrungsebeneService.fuehrungsebenentypMapping.get(Fuehrungsebenentyp.LogistikEinsatzstelle)?.longname ||
        '';
    } else if (this.currentFuehrungsebene?.typ == Fuehrungsebenentyp.Einsatzstelle) {
      this.logistikButtonText =
        this.fuehrungsebeneService.fuehrungsebenentypMapping.get(Fuehrungsebenentyp.LogistikEinsatzabschnitt)
          ?.longname || '';
    } else {
      this.logistikButtonText = '';
    }
  }

  /**
   * Wenn noch keine Logistik für currentFuehrungsebene existiert,
   * Dialog zum Hinzufügen einer neuen Logistik-Führungsebene öffnen.
   */
  createLogistik() {
    if (this.logistikDTO) {
      console.warn('Es existiert bereits eine Logistik-Führungsebene');
      return;
    }
    const newLogistikDTO = this.fuehrungsebeneService.prepareNewLogistikFuehrungsebene();
    if (!newLogistikDTO) {
      console.warn('Es konnte keine neue Logistik-Führungsebene angelegt werden');
      return;
    }

    const inputData: FuehrungsebeneDialogData = {
      fuehrungsebeneDTO: newLogistikDTO,
      filteredTypes: [newLogistikDTO.typ],
    };
    this.dialog.open(FuehrungsebeneDetailsDialogComponent, { data: inputData });
  }

  /**
   * Existiert bereits Logistik im currentFuehrungsebene,
   * diesen und dessen Children im Logistik-Dialog anzeigen.
   */
  editLogistik() {
    this.dialog.open(LogistikDialogComponent);
  }
}
