<mat-toolbar>
  <h2>Wettermessung {{ newObject ? "hinzufügen" : "bearbeiten" }}</h2>
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="wettermessungForm">
    <mat-form-field appearance="outline">
      <mat-label>Datum/Uhrzeit</mat-label>
      <input matInput type="datetime-local" formControlName="datum" dateTimeLocalAsDate />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Windgeschwindigkeit</mat-label>
      <input
        matInput
        type="number"
        formControlName="windgeschwindigkeit"
        min="0"
        (change)="onChangeWindgeschwindigkeit()"
      />
      <span matTextSuffix>km/h</span>
      <mat-error>{{ getErrorMessage("windgeschwindigkeit") }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Windstärke (0-12)</mat-label>
      <input matInput type="number" formControlName="windstaerke" min="0" max="12" />
      <span matTextSuffix>bft</span>
      <mat-error>{{ getErrorMessage("windstaerke") }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Temperatur</mat-label>
      <input matInput type="number" formControlName="temperatur" min="-50" max="50" />
      <span matTextSuffix>°C</span>
      <mat-error>{{ getErrorMessage("temperatur") }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Luftdruck</mat-label>
      <input matInput type="number" formControlName="luftdruck" min="0" />
      <span matTextSuffix>mbar</span>
      <mat-error>{{ getErrorMessage("luftdruck") }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Bewölkung (0-9)</mat-label>
      <input matInput type="number" formControlName="bewoelkung" min="0" max="9" />
      <span matTextSuffix>/8</span>
      <mat-error>{{ getErrorMessage("bewoelkung") }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Niederschlag</mat-label>
      <input matInput type="number" formControlName="niederschlag" min="0" />
      <span matTextSuffix>l/m²</span>
      <mat-error>{{ getErrorMessage("niederschlag") }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Sicht</mat-label>
      <input matInput type="number" formControlName="sicht" min="0" />
      <span matTextSuffix>km</span>
      <mat-error>{{ getErrorMessage("sicht") }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="onClickSpeichern()" class="mr-05">Speichern</button>
</mat-dialog-actions>
