import { Component, inject, OnInit } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { EinsatzDTO } from 'src/app/api/build/openapi';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { EinsatzortFormComponent } from '../einsatzort-form/einsatzort-form.component';

export interface EinsatzDialogInputData {
  einsatzDTO: EinsatzDTO;
}

@Component({
  selector: 'app-einsatz-dialog',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    EinsatzortFormComponent,
    MatDialogModule,
    DatetimeLocalAccessorDirective,
  ],
  templateUrl: './einsatz-dialog.component.html',
  styleUrl: './einsatz-dialog.component.scss',
})
export class EinsatzDialogComponent implements OnInit {
  private dialogData?: EinsatzDialogInputData = inject(MAT_DIALOG_DATA);
  private formBuilder = inject(NonNullableFormBuilder);

  einsatzDTO?: EinsatzDTO = {};

  fcEinsatznummer = this.formBuilder.control<string | undefined>(undefined);
  fcEinsatzstatus = this.formBuilder.control<string | undefined>(undefined);
  fcEinsatzeroeffnung = this.formBuilder.control<Date | undefined>(undefined);
  fcEinsatzart1 = this.formBuilder.control<string | undefined>(undefined);
  fcEinsatzstichwort1 = this.formBuilder.control<string | undefined>(undefined);
  fcEinsatzart2 = this.formBuilder.control<string | undefined>(undefined);
  fcEinsatzstichwort2 = this.formBuilder.control<string | undefined>(undefined);
  fcMeldebild = this.formBuilder.control<string | undefined>(undefined);
  fcMuttereinsatznummer = this.formBuilder.control<string | undefined>(undefined);

  ngOnInit(): void {
    if (this.dialogData?.einsatzDTO) {
      this.einsatzDTO = this.dialogData.einsatzDTO;
    }
    this.dtoToFormGroup();
  }

  /**
   * Schreibt alle DTO-Werte zurück in die Eingabefelder
   */
  private dtoToFormGroup() {
    if (!this.einsatzDTO) {
      return;
    }

    this.fcEinsatznummer.setValue(this.einsatzDTO.einsatznummer);
    this.fcEinsatzstatus.setValue(this.einsatzDTO.einsatzstatus);
    this.fcEinsatzeroeffnung.setValue(
      this.einsatzDTO.einsatzeroeffnung ? new Date(Date.parse(this.einsatzDTO.einsatzeroeffnung)) : undefined
    );
    this.fcEinsatzart1.setValue(this.einsatzDTO.einsatzart1);
    this.fcEinsatzstichwort1.setValue(this.einsatzDTO.einsatzstichwort1);
    this.fcEinsatzart2.setValue(this.einsatzDTO.einsatzart1);
    this.fcEinsatzstichwort2.setValue(this.einsatzDTO.einsatzstichwort2);
    this.fcMeldebild.setValue(this.einsatzDTO.meldebild);
    this.fcMuttereinsatznummer.setValue(this.einsatzDTO.muttereinsatznummer);
  }
}
