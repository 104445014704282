import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AppStateInterface } from '../+state/appState.interface';
import { Store } from '@ngrx/store';
import { selectAnwesenheiten } from './+state/anwesenheiten.selectors';
import { anwesenheitenActions } from './+state/anwesenheiten.actions';
import { currentLageSelector } from '../lagedarstellung/lagen/+state/lage.selectors';
import { AnwesenheitDTO } from '../api/build/openapi';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class AnwesenheitslisteService {
  private store: Store<AppStateInterface> = inject(Store);
  private currentLageId: string = '';
  anwesenheiten: AnwesenheitDTO[] = [];
  anwesenheiten$: Observable<AnwesenheitDTO[]> = this.store.select(selectAnwesenheiten).pipe(takeUntilDestroyed());

  constructor() {
    this.store
      .select(currentLageSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((l) => (this.currentLageId = l?.id || ''));
    this.store
      .select(selectAnwesenheiten)
      .pipe(takeUntilDestroyed())
      .subscribe((a) => (this.anwesenheiten = a));
  }

  registerAnswesenheit(anwesenheit: AnwesenheitDTO) {
    anwesenheit.lageId = this.currentLageId;
    this.store.dispatch(
      anwesenheitenActions.createAnwesenheit({ anwesenheit: anwesenheit, lageId: this.currentLageId })
    );
  }

  deregisterAnwesenheit(anwesenheit: AnwesenheitDTO) {
    if (anwesenheit.id) {
      this.store.dispatch(
        anwesenheitenActions.deleteAnwesenheit({ lageId: this.currentLageId, anwesenheitId: anwesenheit.id })
      );
    }
  }

  updateAnwesenheit(anwesenheit: AnwesenheitDTO) {
    if (anwesenheit.id) {
      this.store.dispatch(anwesenheitenActions.patchAnwesenheit({ anwesenheit: anwesenheit }));
    }
  }

  getAnweisenheitById(anwesenheitId: string): AnwesenheitDTO | undefined {
    return this.anwesenheiten.find((a) => a.id === anwesenheitId);
  }
}
