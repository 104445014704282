import { createReducer, on } from '@ngrx/store';
import { MapLayerConfig } from 'src/app/api/build/openapi';
import { addOrReplaceWithLatest } from 'src/app/shared/dto-version-tools';
import { MapLayerConfigStateInterface } from './map-layer-config-state.interface';
import { mapLayerConfigActions } from './map-layer-config.actions';

export const initialState: MapLayerConfigStateInterface = {
  isCreating: false,
  isLoading: false,
  isDeleting: false,
  isPatching: false,
  mapLayerConfigs: [],
};

export const mapLayerConfigReducer = createReducer(
  initialState,

  // Reset Store
  on(mapLayerConfigActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get MapLayerConfigs
  on(mapLayerConfigActions.getMapLayerConfigs, (state) => ({ ...state, isLoading: true })),
  on(mapLayerConfigActions.getMapLayerConfigsSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    mapLayerConfigs: action.mapLayerConfigs,
  })),
  on(mapLayerConfigActions.getMapLayerConfigsFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create MapLayerConfig
  on(mapLayerConfigActions.createMapLayerConfig, (state) => ({ ...state, isCreating: true })),
  on(mapLayerConfigActions.createMapLayerConfigSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    mapLayerConfigs: [...state.mapLayerConfigs, action.createdMapLayerConfig],
  })),
  on(mapLayerConfigActions.createMapLayerConfigFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch MapLayerConfig
  on(mapLayerConfigActions.patchMapLayerConfig, (state) => ({ ...state, isPatching: true })),
  on(mapLayerConfigActions.patchMapLayerConfigSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    mapLayerConfigs: addOrReplaceWithLatest(state.mapLayerConfigs, action.patchedMapLayerConfig),
  })),
  on(mapLayerConfigActions.patchMapLayerConfigFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete MapLayerConfig
  on(mapLayerConfigActions.deleteMapLayerConfig, (state) => ({ ...state, isDeleting: true })),
  on(mapLayerConfigActions.deleteMapLayerConfigSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    mapLayerConfigs: state.mapLayerConfigs.filter(
      (mapLayerConfig: MapLayerConfig) => mapLayerConfig?.id !== action.deletedMapLayerConfigId
    ),
  })),
  on(mapLayerConfigActions.deleteMapLayerConfigFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  }))
);
