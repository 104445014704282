<div *ngIf="formGroup && formControlName" [formGroup]="formGroup">
  <mat-form-field class="less-margin" (mouseover)="mouseover = true" (mouseleave)="mouseover = false">
    <mat-label>{{ label }}</mat-label>

    <input
      *ngIf="!useTextarea; else textarea"
      matInput
      [formControlName]="formControlName"
      [maxlength]="maxLength!"
      [placeholder]="placeholderValue"
      [matTooltip]="tooltip ? tooltip : ''"
    />
    <ng-template #textarea>
      <textarea
        matInput
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="textAreaRows"
        [cdkAutosizeMaxRows]="textAreaRows"
        [formControlName]="formControlName"
        [placeholder]="placeholderValue"
        [matTooltip]="tooltip ? tooltip : ''"
      >
      </textarea>
    </ng-template>

    <button
      [ngClass]="{ hide: !(formGroup.get(formControlName)?.value && mouseover) }"
      (click)="formGroup.get(formControlName)?.reset()"
      mat-icon-button
      aria-label="Clear"
      matSuffix
      matTooltip="Zurücksetzen"
    >
      <mat-icon>close</mat-icon>
    </button>

    <mat-error>{{ getErrorMessage(formControlName) }}</mat-error>
  </mat-form-field>
</div>
