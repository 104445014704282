<div class="form-container">
  <form [formGroup]="formGroup" class="actual-form">
    <mat-card>
      <mat-card-subtitle>Zeichen Informationen</mat-card-subtitle>
      <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />

      <mat-form-field
        class="less-margin"
        (mouseover)="organisationMouseover = true"
        (mouseleave)="organisationMouseover = false"
      >
        <mat-label>Organisation</mat-label>
        <mat-select formControlName="organisation">
          <mat-option *ngFor="let organisation of organisationValues" [value]="organisation">
            {{ organisation.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="formGroup.get('organisation')?.value && organisationMouseover"
          matSuffix
          mat-icon-button
          (click)="formGroup.get('organisation')?.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
        class="less-margin"
        (mouseover)="fachaufgabeMouseover = true"
        (mouseleave)="fachaufgabeMouseover = false"
      >
        <mat-label>Fachaufgabe</mat-label>
        <mat-select formControlName="fachaufgabe">
          <mat-option *ngFor="let fachaufgabe of fachaufgabeValues" [value]="fachaufgabe">
            {{ fachaufgabe.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="formGroup.get('fachaufgabe')?.value && fachaufgabeMouseover"
          matSuffix
          mat-icon-button
          (click)="formGroup.get('fachaufgabe')?.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="less-margin" (mouseover)="einheitMouseover = true" (mouseleave)="einheitMouseover = false">
        <mat-label>Einheit</mat-label>
        <mat-select formControlName="einheit">
          <mat-option *ngFor="let einheit of einheitValues" [value]="einheit">
            {{ einheit.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="formGroup.get('einheit')?.value && einheitMouseover"
          matSuffix
          mat-icon-button
          (click)="formGroup.get('einheit')?.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
        class="less-margin"
        (mouseover)="verwaltungsstufeMouseover = true"
        (mouseleave)="verwaltungsstufeMouseover = false"
      >
        <mat-label>Verwaltungsstufe</mat-label>
        <mat-select formControlName="verwaltungsstufe">
          <mat-option *ngFor="let verwaltungsstufe of verwaltungsstufeValues" [value]="verwaltungsstufe">
            {{ verwaltungsstufe.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="formGroup.get('verwaltungsstufe')?.value && verwaltungsstufeMouseover"
          matSuffix
          mat-icon-button
          (click)="formGroup.get('verwaltungsstufe')?.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="less-margin" (mouseover)="symbolMouseover = true" (mouseleave)="symbolMouseover = false">
        <mat-label>Symbol</mat-label>
        <mat-select formControlName="symbol">
          <mat-option *ngFor="let symbol of symbolValues" [value]="symbol">
            {{ symbol.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="formGroup.get('symbol')?.value && symbolMouseover"
          matSuffix
          mat-icon-button
          (click)="formGroup.get('symbol')?.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Text</mat-label>
        <input matInput [formControl]="fcZeichenText" />
        <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['maxlength']">{{
          getErrorMessage(fcZeichenText)
        }}</mat-error>
        <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['pattern']"
          >Keine Umlaute oder Sonderzeichen</mat-error
        >
      </mat-form-field>
    </mat-card>

    <mat-card>
      <mat-card-subtitle>Information zur Stelle/Einrichtung</mat-card-subtitle>
      <app-tz-combobox
        label="Leiter"
        formControlName="leiter"
        [formGroup]="formGroup"
        [data$]="leiterValues$"
        [displayedFields]="['anzeigename']"
      />

      <div class="radio-group">
        <mat-label>Art der Befehlsstelle</mat-label>
        <mat-radio-group formControlName="beweglichkeit">
          <mat-radio-button [value]="Beweglichkeit.Beweglich">Beweglich</mat-radio-button>
          <mat-radio-button [value]="Beweglichkeit.Ortsfest">Ortsfest</mat-radio-button>
        </mat-radio-group>
      </div>

      <app-tz-combobox
        *ngIf="isBeweglich"
        label="Fahrzeug"
        formControlName="fahrzeug"
        [formGroup]="formGroup"
        [data$]="fahrzeugValues$"
        [displayedFields]="['anzeigename']"
      />

      <mat-form-field
        *ngIf="!isBeweglich"
        (mouseover)="gebaeudeMouseover = true"
        (mouseleave)="gebaeudeMouseover = false"
        class="mb-3"
      >
        <mat-label>Gebäude</mat-label>
        <mat-select [formControl]="fcGebaeude">
          <mat-select-trigger>
            <img [src]="fcGebaeude.value?.dataUrl" />
            {{ fcGebaeude.value?.anzeigename }}
          </mat-select-trigger>
          <mat-option *ngFor="let gebaeudeDTO of gebaeudeValues$ | async" [value]="gebaeudeDTO">
            <img [src]="gebaeudeDTO.dataUrl" />
            <span class="text">{{ gebaeudeDTO.anzeigename }}</span>
            <span *ngIf="gebaeudeDTO.befehlsstelleId && gebaeudeDTO.befehlsstelleId !== befehlsstelleToEdit?.id">
              <mat-icon matTooltip="Gebäude ist bereits mit einer anderen Befehlsstelle verknüpft">link</mat-icon>
            </span>
          </mat-option>
        </mat-select>
        <button *ngIf="fcGebaeude.value && gebaeudeMouseover" matSuffix mat-icon-button (click)="fcGebaeude.reset()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-hint
          >Hinweis: 1-zu-1 Beziehung zwischen Gebäude und Befehlsstelle. Auswahl setzt automatisch Rückverbindung und
          überschreibt ggf. existierende Verbindung</mat-hint
        >
      </mat-form-field>

      <app-cleanable-form-field
        *ngIf="!isBeweglich"
        label="Ortsangabe"
        [formGroup]="formGroup"
        formControlName="ortsangabe"
      />
    </mat-card>
  </form>
  <app-kontakt-list
    #kontakte
    [showButtons]="true"
    headerText="Kontaktmöglichkeiten"
    [kommunikationOptionen]="befehlsstelleToEdit?.kommunikationOptionen || []"
  />

  <app-kontakt-list
    #kontakteExtern
    [showButtons]="false"
    [kommunikationOptionen]="kommunikationOptionenExtern"
    [headerText]="
      isBeweglich ? 'Kontaktmöglichkeiten des ausgewählten Fahrzeugs' : 'Kontaktmöglichkeiten des ausgewählten Gebäudes'
    "
  />
</div>
