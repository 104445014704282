import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse } from '../../api/build/openapi/model/errorResponse';
import { AnwesenheitDTO } from 'src/app/api/build/openapi';

/* Aktionen, die für einen State ausgelöst werden können */
export const anwesenheitenActions = createActionGroup({
  source: 'Anwesenheitenliste',
  events: {
    'Reset Store': emptyProps(),

    'Get Anwesenheiten': props<{ lageId: string }>(),
    'Get Anwesenheiten Success': props<{ anwesenheiten: AnwesenheitDTO[] }>(),
    'Get Anwesenheiten Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Anwesenheit': props<{ lageId: string; anwesenheit: AnwesenheitDTO }>(),
    'Create Anwesenheit Success': props<{ anwesenheit: AnwesenheitDTO }>(),
    'Create Anwesenheit Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Anwesenheit': props<{ anwesenheit: AnwesenheitDTO }>(),
    'Patch Anwesenheit Success': props<{ patchedAnwesenheit: AnwesenheitDTO }>(),
    'Patch Anwesenheit Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Anwesenheit': props<{ lageId: string; anwesenheitId: string }>(),
    'Delete Anwesenheit Success': props<{ deletedAnwesenheitId: string }>(),
    'Delete Anwesenheit Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
