<div class="container">
  <div class="oben">
    <app-dashboard-lage
      [schadensgebietDataset]="schadensgebietDataset"
      [einsatzraumeDatasets]="einsatzraeumeDatasets"
      (reload)="reload()"
    />
    <app-dashboard-fahrzeuge [fahrzeugeDataset]="fahrzeugeDataset" />
  </div>
  <div class="unten">
    <app-dashboard-personen
      [bevoelkerungDataset]="personenDatasetBevoelkerung"
      [einsatzkraefteDataset]="personenDatasetEinsatzkraefte"
      [manvDataset]="PersonenDatasetManv"
    />
  </div>
</div>
