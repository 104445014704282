import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, TierschadenResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { tierschadenActions } from './tierschaden.actions';

@Injectable()
export class TierschadenEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Tierschäden anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return tierschadenActions.getTierschaeden({ lageId: props.currentLage.id });
        } else {
          return tierschadenActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle Tierschäden einer Lage laden
   */
  getTierschaeden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tierschadenActions.getTierschaeden),
      mergeMap((props) =>
        this.tierschaedenResourceService.getTierschaedenByLageId(props.lageId).pipe(
          map((tierschaeden) =>
            tierschadenActions.getTierschaedenSuccess({ tierschadenDTOs: tierschaeden, lageId: props.lageId })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(tierschadenActions.getTierschaedenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neuen Tierschaden anlegen
   */
  createTierschaden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tierschadenActions.createTierschaden),
      mergeMap((props) =>
        this.tierschaedenResourceService.createTierschaden(props.lageId, props.tierschadenDTO).pipe(
          map((createdTierschadenDTO) => tierschadenActions.createTierschadenSuccess({ createdTierschadenDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(tierschadenActions.createTierschadenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Existierenden Tierschaden editieren
   */
  patchTierschaden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tierschadenActions.patchTierschaden),
      mergeMap((props) => {
        if (!props.tierschadenDTO.id) {
          return throwError(() => new Error('Tierschaden muss eine Id besitzen'));
        }
        return this.tierschaedenResourceService
          .patchTierschaden(props.lageId, props.tierschadenDTO.id, props.tierschadenDTO)
          .pipe(
            map((patchedTierschadenDTO) => tierschadenActions.patchTierschadenSuccess({ patchedTierschadenDTO })),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(tierschadenActions.patchTierschadenFailure({ errorResponse }));
            })
          );
      })
    )
  );

  /**
   * Existierenden Tierschaden löschen
   */
  deleteTierschaden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tierschadenActions.deleteTierschaden),
      mergeMap((props) => {
        if (!props.tierschadenDTO.id || !props.tierschadenDTO.fuehrungsebeneId) {
          return throwError(() => new Error('Tierschaden muss eine Führungsebene und eine Id besitzen'));
        }
        return this.tierschaedenResourceService
          .deleteTierschaden(props.tierschadenDTO.fuehrungsebeneId, props.lageId, props.tierschadenDTO.id)
          .pipe(
            map(() => tierschadenActions.deleteTierschadenSuccess({ deletedTierschadenDTO: props.tierschadenDTO })),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(tierschadenActions.createTierschadenFailure({ errorResponse }));
            })
          );
      })
    )
  );

  constructor(private actions$: Actions, private tierschaedenResourceService: TierschadenResourceService) {}
}
