import { Component, Input } from '@angular/core';
import { DateTimePipe } from '../pipes/date-time.pipe';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-last-updated',
  standalone: true,
  templateUrl: './last-updated.component.html',
  styleUrl: './last-updated.component.scss',
  imports: [DateTimePipe, AsyncPipe],
})
export class LastUpdatedComponent {
  @Input({ required: true }) lastUpdated!: { lastUpdatedBy?: string; lastUpdatedOn?: string };
}
