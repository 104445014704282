import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, StelleResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { stelleActions } from './stelle.actions';

@Injectable()
export class StelleEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Stellen anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return stelleActions.getStellen({ lageId: props.currentLage.id });
        } else {
          return stelleActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle Stellen einer Lage laden
   */
  getStellen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stelleActions.getStellen),
      mergeMap((props) =>
        this.stellenResourceService.getStellenByLageId(props.lageId).pipe(
          map((stellen) => stelleActions.getStellenSuccess({ stelleDTOs: stellen, lageId: props.lageId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(stelleActions.getStellenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neue Stelle anlegen
   */
  createStelle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stelleActions.createStelle),
      mergeMap((props) =>
        this.stellenResourceService.createStelle(props.lageId, props.stelleDTO).pipe(
          map((createdStelleDTO) => stelleActions.createStelleSuccess({ createdStelleDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(stelleActions.createStelleFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Existierende Stelle editieren
   */
  patchStelle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stelleActions.patchStelle),
      mergeMap((props) => {
        if (!props.stelleDTO.id) {
          return throwError(() => new Error('Stelle muss eine Id besitzen'));
        }
        return this.stellenResourceService.patchStelle(props.lageId, props.stelleDTO.id, props.stelleDTO).pipe(
          map((patchedStelleDTO) => stelleActions.patchStelleSuccess({ patchedStelleDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(stelleActions.patchStelleFailure({ errorResponse }));
          })
        );
      })
    )
  );

  /**
   * Existierende Stelle löschen
   */
  deleteStelle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stelleActions.deleteStelle),
      mergeMap((props) => {
        if (!props.stelleDTO.id) {
          return throwError(() => new Error('Stelle muss eine Id besitzen'));
        }
        return this.stellenResourceService.deleteStelle(props.lageId, props.stelleDTO.id).pipe(
          map(() => stelleActions.deleteStelleSuccess({ deletedStelleDTO: props.stelleDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(stelleActions.createStelleFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private stellenResourceService: StelleResourceService) {}
}
