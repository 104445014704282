/**
 * Typen für verschiedene Actions, die über eine Toolbar angestoßen werden können.
 */
export enum ToolbarAction {
  ADD,
  EDIT,
  DELETE,
  COPY,
}

export enum Direction {
  ASC,
  DESC,
}
