<div *ngIf="formGroup && formControlName" [formGroup]="formGroup">
  <mat-form-field class="less-margin" (mouseover)="mouseover = true" (mouseleave)="mouseover = false">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-select [formControlName]="formControlName" [compareWith]="compareTZs">
      <mat-select-trigger> <img [src]="selectedItemSvg" />{{ selectedItemText }}</mat-select-trigger>
      <mat-option *ngFor="let item of data$ | async" [value]="item">
        <img [src]="item.dataUrl" />
        <span>{{ getText(item) }}</span>
      </mat-option>
    </mat-select>
    <button
      *ngIf="formGroup.get(formControlName)?.value && mouseover"
      matSuffix
      mat-icon-button
      (click)="formGroup.get(formControlName)?.reset()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
