<div class="container">
  <mat-toolbar class="header">
    <div class="unerledigt-overlay" *ngIf="this.massnahmenService.getUnerledigt() > 0"></div>

    <div class="search-control" [formGroup]="searchControll">
      <mat-form-field appearance="outline" class="textfield">
        <mat-label>Suche</mat-label>
        <input matInput formControlName="searchstring" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <mat-button-toggle-group #group="matButtonToggleGroup" [(value)]="sortierung" (valueChange)="updateSortierung()">
        <mat-button-toggle value="prio" matTooltip="Priorität">
          <mat-icon>priority_high</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="date" matTooltip="Zu Erledigen">
          <mat-icon>date_range</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div>
      <button mat-icon-button (click)="onClickReload()"><mat-icon>refresh</mat-icon></button>

      <button
        mat-icon-button
        [matTooltip]="'Erledigte Maßnahmen ' + (showErledigt ? 'ausblenden' : 'einblenden')"
        (click)="onClickShowErledigt()"
      >
        <mat-icon>{{ showErledigt ? "visibility" : "visibility_off" }}</mat-icon>
      </button>
      <button class="add-button" mat-icon-button (click)="onClickHinzufuegen()" matTooltip="Maßnahme hinzufügen">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <div class="progress-bar">
    <mat-progress-bar
      mode="determinate"
      [value]="massnahmenService.getErledigtProzent()"
      color="accent"
    ></mat-progress-bar>
    <p>{{ massnahmenService.erledigteMassnahmen }} von {{ massnahmenService.mengeMassnahmen }} erledigt</p>
  </div>

  <div class="aufgaben-liste">
    <div
      class="aufgabe"
      *ngFor="let massnahme of massnahmen"
      [ngClass]="{ invisible: massnahme.erledigt && !showErledigt, glowing: massnahme.id === hoveredMassnahmeId }"
    >
      <mat-toolbar class="aufgabe-menu">
        <button mat-icon-button (click)="onClickBeenden(massnahme)" [disabled]="massnahme.erledigt">
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button (click)="onClickBearbeiten(massnahme)">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-toolbar>

      <div
        class="prio"
        [ngClass]="{
          done: massnahme.erledigt,
          abgelaufen: massnahme.abgelaufen,
        }"
      >
        <div class="circle" [style]="'background-color: ' + this.prioMapping.get(massnahme.prioritaet!)?.farbe">
          <span class="prio-text"
            ><mat-icon>{{ this.prioMapping.get(massnahme.prioritaet!)?.icon }}</mat-icon></span
          >
        </div>
      </div>
      <div class="text">
        <div class="aufgabe-text">{{ massnahme.text }}</div>
        <div class="erledigen-bis">
          <hr />
          <span class="info-text">Erledigen bis: {{ massnahme.bisWannDatum | dateTime | async }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
