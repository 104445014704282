<mat-card class="tagebuch-list-card">
  <mat-toolbar class="tagebuch-list-header">
    <h3>Tagebuch</h3>
    <span>
      <button mat-icon-button (click)="addTagebucheintrag()" matTooltip="Tagebucheintrag hinzufügen">
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="editTagebucheintrag()"
        [disabled]="selectedIndex < 0"
        matTooltip="Tagebucheintrag bearbeiten"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="askDeleteTagebucheintrag()"
        [disabled]="selectedIndex < 0"
        matTooltip="Tagebucheintrag löschen"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </span>
  </mat-toolbar>

  <mat-card-content class="tagebuch-list">
    <mat-action-list>
      <mat-list-item
        *ngFor="let tagebucheintrag of tagebucheintraege$ | async; let index = index"
        (click)="selectedIndex = index"
        [ngClass]="{ 'selected-list-row': selectedIndex === index }"
      >
        <div
          class="tagebuch-summary"
          matTooltip="[{{ tagebucheintrag.datum | date : 'dd.MM.yyyy HH:mm' }}] {{ tagebucheintrag.freitext }}"
        >
          <p class="tagebuch-text">
            [{{ tagebucheintrag.datum | date : "dd.MM.yyyy HH:mm" }}] {{ tagebucheintrag.freitext }}
          </p>
        </div>
      </mat-list-item>
    </mat-action-list>
  </mat-card-content>
</mat-card>
