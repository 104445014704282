import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, debounceTime, map, startWith, switchMap } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { VerwaltungsebeneDTO } from 'src/app/api/build/openapi';
import { GrundzeichenId, OrganisationId } from 'taktische-zeichen-core';
import { bibliothekActions } from '../+state/bibliothek.actions';
import { verwaltungsebenenIdSelector, verwaltungsebenenSelector } from '../+state/bibliothek.selectors';
import { VerwaltungsebeneDetailsComponent } from '../verwaltungsebene-details/verwaltungsebene-details.component';
import { VerwaltungsebeneDialogComponent } from '../verwaltungsebene-dialog/verwaltungsebene-dialog.component';
import { VerwaltungsebenenTreeComponent } from '../verwaltungsebenen-tree/verwaltungsebenen-tree.component';

export interface BibliothekFilter {
  filterText: string;
  organisationen: string[];
  grundzeichen: string[];
  nurVorlagen: boolean;
}

@Component({
  selector: 'app-bibliothek-base',
  standalone: true,
  templateUrl: './bibliothek-base.component.html',
  styleUrls: ['./bibliothek-base.component.scss'],
  imports: [
    CommonModule,
    MatCardModule,
    VerwaltungsebeneDetailsComponent,
    MatToolbarModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    VerwaltungsebenenTreeComponent,
    MatExpansionModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
  ],
})
export class BibliothekBaseComponent {
  /**
   * Für die Verwendung in einer Lage.
   *
   * Es wird das Editieren der Taktischen Zeichen und Verwaltungsebenen unterbunden.
   */
  @Input() selectionMode = false;

  accordionExpanded = false;
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  // Ob die seitliche Tree-Navigation für die Verwaltungsebenen ausgeklappt ist
  // Zu Beginn nicht
  expandNavTree = false;

  grundzeichenOptions = new Map<string, GrundzeichenId>([
    ['Personen', 'person'],
    ['Fahrzeuge', 'fahrzeug'],
    ['Taktische Formationen', 'taktische-formation'],
  ]);

  organisationOptions = new Map<string, OrganisationId>([
    ['Bundeswehr', 'bundeswehr'],
    ['Feuerwehr', 'feuerwehr'],
    ['Führung', 'fuehrung'],
    ['Gefahrenabwehr', 'gefahrenabwehr'],
    ['Hilfsorganisationen', 'hilfsorganisation'],
    ['Polizei', 'polizei'],
    ['THW', 'thw'],
  ]);

  fokusId$ = new BehaviorSubject<string | undefined>(undefined);

  verwaltungsebenenTree: VerwaltungsebeneDTO[] = [];
  verwaltungsebenen$ = this.fokusId$.pipe(switchMap((v) => this.store.select(verwaltungsebenenIdSelector(v))));

  private formBuilder = inject(NonNullableFormBuilder);

  protected fcFilterText = this.formBuilder.control<string>('');
  protected fcFilterOrganisationen = this.formBuilder.control<string[]>([]);
  protected fcFilterGrundzeichen = this.formBuilder.control<string[]>([]);
  protected fcFilterVorlagen = this.formBuilder.control<boolean>(false);

  /**
   * Sammelt verschiedene Filter-Eingaben ein und verpackt sie in ein FilterObjekt.
   */
  bibliothekFilter$ = combineLatest([
    this.fcFilterText.valueChanges.pipe(startWith(this.fcFilterText.value)),
    this.fcFilterOrganisationen.valueChanges.pipe(startWith(this.fcFilterOrganisationen.value)),
    this.fcFilterGrundzeichen.valueChanges.pipe(startWith(this.fcFilterGrundzeichen.value)),
    this.fcFilterVorlagen.valueChanges.pipe(startWith(this.fcFilterVorlagen.value)),
  ]).pipe(
    debounceTime(200),
    map(([filterText, organisationen, grundzeichen, nurVorlagen]) => {
      const filter: BibliothekFilter = {
        filterText: filterText,
        organisationen: organisationen,
        grundzeichen: grundzeichen,
        nurVorlagen: nurVorlagen,
      };
      return filter;
    })
  );

  constructor(private dialog: MatDialog, private store: Store<AppStateInterface>) {
    this.store.dispatch(bibliothekActions.getVerwaltungsebenen());
    this.store.dispatch(bibliothekActions.getFahrzeugeWithVerwaltungsebene());
    this.store.dispatch(bibliothekActions.getPersonenWithVerwaltungsebene());
    this.store.dispatch(bibliothekActions.getTaktischeFormationenWithVerwaltungsebene());
    this.store
      .select(verwaltungsebenenSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((verwaltungsebeneDTOs) => (this.verwaltungsebenenTree = verwaltungsebeneDTOs));
  }

  openVerwaltungsebeneDialog(parentVerwaltungsebene?: VerwaltungsebeneDTO) {
    if (parentVerwaltungsebene) {
      this.dialog.open(VerwaltungsebeneDialogComponent, { data: { parentVerwaltungsebene } });
    } else {
      this.dialog.open(VerwaltungsebeneDialogComponent);
    }
  }

  toggleTree = () => (this.expandNavTree = !this.expandNavTree);

  toggleAccordion() {
    if (this.accordionExpanded) {
      this.accordion.closeAll();
    } else {
      this.accordion.openAll();
    }

    this.accordionExpanded = !this.accordionExpanded;
  }

  focusVerwaltungsebene(verwaltungsebeneId: string | undefined) {
    this.fokusId$.next(verwaltungsebeneId);
  }

  clearFilter() {
    this.fcFilterText.setValue('');
    this.fcFilterGrundzeichen.setValue([]);
    this.fcFilterOrganisationen.setValue([]);
    this.fcFilterVorlagen.setValue(false);
  }
}
