<div class="container" *ngIf="fahrzeugeDataset">
  <mat-toolbar class="header">
    <h2>Fahrzeuge</h2>
  </mat-toolbar>

  <mat-card class="dashboard-container">
    <div class="fahrzeuge-container">
      <img src="assets\png\tz\fahrzeug.png" />
      <h2 class="fahrzeuge-counter">{{ fahrzeugeDataset.fahrzeuge }}</h2>
      <h2>
        {{ fahrzeugeDataset.staerke1 }} / {{ fahrzeugeDataset.staerke2 }} / {{ fahrzeugeDataset.staerke3 }} =
        {{ fahrzeugeDataset.staerke1 + fahrzeugeDataset.staerke2 + fahrzeugeDataset.staerke3 }}
      </h2>
    </div>
  </mat-card>
</div>
