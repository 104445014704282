<form [formGroup]="formGroup">
  <mat-card>
    <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />

    <mat-form-field
      class="less-margin"
      (mouseover)="organisationMouseover = true"
      (mouseleave)="organisationMouseover = false"
    >
      <mat-label>Organisation</mat-label>
      <mat-select formControlName="organisation">
        <mat-option *ngFor="let organisation of organisationValues" [value]="organisation">
          {{ organisation.label }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="fcOrganisation.value && organisationMouseover"
        matSuffix
        mat-icon-button
        (click)="fcOrganisation.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field
      class="less-margin"
      (mouseover)="fachaufgabeMouseover = true"
      (mouseleave)="fachaufgabeMouseover = false"
    >
      <mat-label>Fachaufgabe</mat-label>
      <mat-select formControlName="fachaufgabe">
        <mat-option *ngFor="let fachaufgabe of fachaufgabeValues" [value]="fachaufgabe">
          {{ fachaufgabe.label }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="fcFachaufgabe.value && fachaufgabeMouseover"
        matSuffix
        mat-icon-button
        (click)="fcFachaufgabe.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="less-margin" (mouseover)="symbolMouseover = true" (mouseleave)="symbolMouseover = false">
      <mat-label>Symbol</mat-label>
      <mat-select formControlName="symbol">
        <mat-option *ngFor="let symbol of symbolValues" [value]="symbol">
          {{ symbol.label }}
        </mat-option>
      </mat-select>
      <button *ngIf="fcSymbol.value && symbolMouseover" matSuffix mat-icon-button (click)="fcSymbol.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Text</mat-label>
      <input matInput [formControl]="fcZeichenText" />
      <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['maxlength']">{{
        getErrorMessage(fcZeichenText)
      }}</mat-error>
      <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['pattern']"
        >Keine Umlaute oder Sonderzeichen</mat-error
      >
    </mat-form-field>

    <mat-form-field
      class="mb-3"
      (mouseover)="befehlsstelleMouseover = true"
      (mouseleave)="befehlsstelleMouseover = false"
    >
      <mat-label>Befehlsstelle</mat-label>
      <mat-select [formControl]="fcBefehlsstelle">
        <mat-select-trigger>
          <img [src]="fcBefehlsstelle.value?.dataUrl" />
          {{ fcBefehlsstelle.value?.anzeigename }}
        </mat-select-trigger>
        <mat-option *ngFor="let befehlsstelleDTO of befehlsstelleValues$ | async" [value]="befehlsstelleDTO">
          <img [src]="befehlsstelleDTO.dataUrl" />
          <span class="text">{{ befehlsstelleDTO.anzeigename }}</span>
          <span *ngIf="befehlsstelleDTO.gebaeudeId && befehlsstelleDTO.gebaeudeId !== gebaeudeToEdit?.id">
            <mat-icon matTooltip="Befehlsstelle ist bereits mit einem anderen Gebäude verknüpft">link</mat-icon>
          </span>
        </mat-option>
      </mat-select>
      <button
        *ngIf="fcBefehlsstelle.value && befehlsstelleMouseover"
        matSuffix
        mat-icon-button
        (click)="fcBefehlsstelle.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint
        >Hinweis: 1-zu-1 Beziehung zwischen Gebäude und Befehlsstelle. Auswahl setzt automatisch Rückverbindung und
        überschreibt ggf. existierende Verbindung</mat-hint
      >
    </mat-form-field>
  </mat-card>

  <app-kontakt-list [kommunikationOptionen]="gebaeudeToEdit?.kommunikationOptionen || []" />
</form>
