import { NgIf } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TaktischesZeichenDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { IconAndTextComponent } from 'src/app/shared/components/icon-and-text/icon-and-text.component';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { MapHandler } from '../../base-map/map-handler';
import {
  EditTzDialogData,
  TaktischeZeichenDialogComponent,
} from '../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischeZeichenTypInfo } from '../taktische-zeichen.interface';

@Component({
  selector: 'app-tz-popup-toolbar',
  standalone: true,
  imports: [IconAndTextComponent, MatToolbarModule, MatIconModule, MatButtonModule, MatTooltipModule, NgIf],
  templateUrl: './tz-popup-toolbar.component.html',
  styleUrl: './tz-popup-toolbar.component.scss',
})
export class TzPopupToolbarComponent implements OnInit {
  @Input({ required: true })
  tzDto!: TaktischesZeichenDTO;

  protected tzInfo?: TaktischeZeichenTypInfo;

  private dialog = inject(MatDialog);
  private mapHandler = inject(MapHandler);
  private taktischeZeichenService = inject(TaktischeZeichenService);

  ngOnInit(): void {
    if (this.tzDto.typ) {
      this.tzInfo = this.taktischeZeichenService.taktischeZeichenInfoMapping.get(this.tzDto.typ);
    }
  }

  focusDto() {
    if (this.tzDto.geometry) {
      this.mapHandler.focusGeometry(this.tzDto.geometry as GeoJSON.Geometry);
    }
  }

  moveDto() {
    if (!this.tzDto.fuehrungsebeneId) {
      return;
    }

    if (
      this.tzDto.typ === TaktischesZeichenTyp.Fahrzeug ||
      this.tzDto.typ === TaktischesZeichenTyp.Person ||
      this.tzDto.typ === TaktischesZeichenTyp.TaktischeFormation
    ) {
      this.taktischeZeichenService.openVerschiebeRessourceTzDialog(this.tzDto);
    } else {
      this.taktischeZeichenService.openVerschiebeTzDialog(this.tzDto);
    }
  }

  editDto() {
    const editTzDialogData: EditTzDialogData = {
      dto: this.tzDto,
      taktischesZeichenTyp: this.tzDto.typ,
    };
    this.dialog.open(TaktischeZeichenDialogComponent, {
      data: editTzDialogData,
      disableClose: false,
    });
  }
}
