<app-loadable [isLoading$]="isLoading$">
  <ng-container *ngIf="wetter; else addWetter">
    <mat-toolbar class="wetter-component-header">
      <h2>Wetter</h2>
      <span class="spacer"></span>
      <button mat-icon-button (click)="editWetter()" matTooltip="Wetter bearbeiten">
        <mat-icon>edit</mat-icon>
      </button>
    </mat-toolbar>

    <div class="container">
      <mat-toolbar class="informationen">
        <h2>Wetter</h2>
        <div class="header-column">
          <h4>Messp.: {{ wetter.messpunkt }}</h4>
          <h4>Verant.: {{ wetter.verantwortlich }}</h4>
        </div>
      </mat-toolbar>

      <div class="bottom">
        <div class="wetterinfos">
          <ise-kompass
            [editable]="false"
            [value]="wetter.windrichtung!"
            [windgeschwindigkeit]="10"
            [umlaufend]="wetter.umlaufend!"
          />

          <div class="witterung" style="background-image: url('assets/wetter/wetter_{{ wetter.witterung }}.png');">
            <p *ngIf="latestWettermessung">{{ latestWettermessung.temperatur }}°C</p>
            <img
              *ngIf="latestWettermessung"
              class="bewoelkung"
              src="assets/bewoelkung/bewoelkung_{{ latestWettermessung.bewoelkung }}.png"
              width="32"
              matTooltip="Bewölkung {{ latestWettermessung.bewoelkung }} /8"
            />
          </div>
        </div>

        <div class="wettermessung">
          <h3 class="keine-daten-text" *ngIf="wetter && !latestWettermessung">Keine Messungen vorhanden</h3>

          <span *ngIf="latestWettermessung">Letzte Messung: {{ latestWettermessung.datum | dateTime | async }}</span>
          <table *ngIf="latestWettermessung">
            <tr>
              <td><img src="assets/wind_icon.png" width="18" /></td>
              <td class="text-right" matTooltip="Windgeschwindigkeit">{{ latestWettermessung.windgeschwindigkeit }}</td>
              <td matTooltip="Windgeschwindigkeit">kmh/h</td>
              <td class="text-right" matTooltip="Windstärke">{{ latestWettermessung.windstaerke }}</td>
              <td matTooltip="Windstärke">bft</td>
            </tr>
            <tr>
              <td><img src="assets/luftdruck_icon.png" width="18" /></td>
              <td class="text-right" matTooltip="Luftdruck">{{ latestWettermessung.luftdruck }}</td>
              <td matTooltip="Luftdruck">mbar</td>
              <td class="text-right" matTooltip="Bewölkung">{{ latestWettermessung.bewoelkung }}</td>
              <td matTooltip="Bewölkung">/8</td>
            </tr>
            <tr>
              <td><img src="assets/niederschlag_icon.png" width="18" /></td>
              <td class="text-right" matTooltip="Niederschlag">{{ latestWettermessung.niederschlag }}</td>
              <td matTooltip="Niederschlag">l/m²</td>
              <td class="text-right" matTooltip="Sicht">{{ latestWettermessung.sicht }}</td>
              <td matTooltip="Sicht">km</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</app-loadable>

<ng-template #addWetter>
  <div class="wetter-button">
    <button mat-raised-button color="accent" (click)="createWetter()">
      <mat-icon>add</mat-icon>
      Wetter
    </button>
  </div>
</ng-template>
