<ng-container *ngIf="{ currentLageSelected: (currentLageSelected$ | async) } as obj">
  <a
    *ngIf="isShowDevelopment$ | async"
    mat-button
    [disabled]="!obj.currentLageSelected"
    [routerLink]="['/einsaetze']"
    [ngClass]="{ 'nav-selected': currentUrl === '/einsaetze', 'nav-disabled': !obj.currentLageSelected }"
    >ELS Einsätze</a
  >
  <a
    mat-button
    [disabled]="!obj.currentLageSelected"
    [routerLink]="['/meldungen']"
    [ngClass]="{ 'nav-selected': currentUrl === '/meldungen', 'nav-disabled': !obj.currentLageSelected }"
    >Meldungen</a
  >
  <a
    mat-button
    [disabled]="!obj.currentLageSelected"
    [routerLink]="['/lagedarstellung']"
    [ngClass]="{ 'nav-selected': currentUrl === '/lagedarstellung', 'nav-disabled': !obj.currentLageSelected }"
    >Lagedarstellung</a
  >
  <a
    mat-button
    [disabled]="!obj.currentLageSelected"
    [routerLink]="['/einsatztagebuch']"
    [ngClass]="{ 'nav-selected': currentUrl === '/einsatztagebuch', 'nav-disabled': !obj.currentLageSelected }"
    >Einsatztagebuch</a
  >
  <app-lage-infobar />
</ng-container>
