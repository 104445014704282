import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ErrorService } from '@product/ise-error-lib';
import { GefahrDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import { GrundzeichenId, Symbol, TaktischesZeichen, erzeugeTaktischesZeichen, symbole } from 'taktische-zeichen-core';
import { DataUrlChangeEvent } from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

interface Farbe {
  name: string;
  farbe: string;
}

@Component({
  selector: 'app-gefahr-form',
  templateUrl: './gefahr-form.component.html',
  styleUrls: ['./gefahr-form.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    CleanableFormFieldComponent,
    MatInputModule,
  ],
})
export class GefahrFormComponent implements TaktischesZeichenForm {
  public readonly farben: Farbe[] = [
    { name: 'Schwarz', farbe: '#000000' },
    { name: 'Blau', farbe: '#2d2af7' },
    { name: 'Rot', farbe: '#e60b0b' },
  ];

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  dataUrlChanged = new EventEmitter<DataUrlChangeEvent>();

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);

  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcBeschreibung = this.formBuilder.control<string | undefined>(undefined);
  fcGefahrtyp = this.formBuilder.control<GrundzeichenId>('gefahr');
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [
    Validators.maxLength(255),
    Validators.pattern('[a-zA-Z0-9-/]*'),
  ]);
  fcColor = this.formBuilder.control<string>('#000000');
  fcSchadenumfangMenschen = this.formBuilder.control<boolean>(false);
  fcSchadenumfangTiere = this.formBuilder.control<boolean>(false);
  fcSchadenumfangUmwelt = this.formBuilder.control<boolean>(false);
  fcSchadenumfangSachwerte = this.formBuilder.control<boolean>(false);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    beschreibung: this.fcBeschreibung,
    gefahrtyp: this.fcGefahrtyp,
    symbol: this.fcSymbol,
    zeichenText: this.fcZeichenText,
    color: this.fcColor,
    schadenumfangMenschen: this.fcSchadenumfangMenschen,
    schadenumfangTiere: this.fcSchadenumfangTiere,
    schadenumfangUmwelt: this.fcSchadenumfangUmwelt,
    schadenumfangSachwerte: this.fcSchadenumfangSachwerte,
  });

  readonly EMPTY_ZEICHEN: TaktischesZeichen = { grundzeichen: 'gefahr', farbe: '#000000' };
  editorZeichen: TaktischesZeichen = { ...this.EMPTY_ZEICHEN };
  currentZeichenDataUrl: string = erzeugeTaktischesZeichen(this.editorZeichen).dataUrl;
  customZeichen = false;

  symbolValues: Symbol[] = symbole.sort(compareLabels);
  symbolMouseover = false;

  gefahrToEdit?: GefahrDTO;

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v.trim());
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.symbol = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcGefahrtyp.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.grundzeichen = v;
        this.generateTaktischesZeichen();
      }
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen && this.fcZeichenText.valid) {
        this.editorZeichen.text = v?.trim();
        this.generateTaktischesZeichen();
      }
    });

    this.fcColor.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.farbe = v;
        this.generateTaktischesZeichen();
      }
    });
  }

  setCustomTz(dataUrl: string) {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
      this.customZeichen = true;
    } else {
      this.customZeichen = false;
      this.generateTaktischesZeichen();
    }
  }

  setDTO(gefahrDTO: GefahrDTO) {
    this.gefahrToEdit = gefahrDTO;
    if (this.gefahrToEdit) {
      this.dtoToFormGroup(this.gefahrToEdit);
      if (this.gefahrToEdit.customZeichen && this.gefahrToEdit.dataUrl) {
        this.generateTaktischesZeichen(this.gefahrToEdit.dataUrl);
        return;
      }
    }
    this.generateTaktischesZeichen();
  }

  /**
   * Aktualisiertes GefahrDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): GefahrDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): GefahrDTO {
    return {
      ...this.gefahrToEdit,
      anzeigename: this.fcAnzeigename.value.trim(),
      beschreibung: this.fcBeschreibung.value?.trim(),
      gefahrtyp: this.fcGefahrtyp.value,
      color: this.fcColor.value,
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      schadenumfangMenschen: this.fcSchadenumfangMenschen.value,
      schadenumfangTiere: this.fcSchadenumfangTiere.value,
      schadenumfangUmwelt: this.fcSchadenumfangUmwelt.value,
      schadenumfangSachwerte: this.fcSchadenumfangSachwerte.value,
      dataUrl: this.currentZeichenDataUrl,
      customZeichen: this.customZeichen,
      typ: TaktischesZeichenTyp.Gefahr,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(gefahrDTO: GefahrDTO): void {
    this.fcAnzeigename.setValue(gefahrDTO.anzeigename);
    this.fcBeschreibung.setValue(gefahrDTO.beschreibung);
    this.fcGefahrtyp.setValue((gefahrDTO.gefahrtyp as GrundzeichenId) || 'gefahr');
    this.fcColor.setValue(gefahrDTO.color || '#000000');
    this.fcSymbol.setValue(symbole.find((v) => v.id === gefahrDTO.symbol));
    this.fcZeichenText.setValue(gefahrDTO.zeichenText);
    this.fcSchadenumfangMenschen.setValue(gefahrDTO.schadenumfangMenschen || false);
    this.fcSchadenumfangTiere.setValue(gefahrDTO.schadenumfangTiere || false);
    this.fcSchadenumfangUmwelt.setValue(gefahrDTO.schadenumfangUmwelt || false);
    this.fcSchadenumfangSachwerte.setValue(gefahrDTO.schadenumfangSachwerte || false);
    this.customZeichen = gefahrDTO.customZeichen || false;
  }

  /**
   * DataURL an Parent senden und dort aktualisieren.
   */
  generateTaktischesZeichen(dataUrl = '') {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
    } else {
      const zeichen = erzeugeTaktischesZeichen(this.editorZeichen);
      this.currentZeichenDataUrl = zeichen.dataUrl;
    }

    this.dataUrlChanged.emit({ dataUrl: this.currentZeichenDataUrl, customZeichen: this.customZeichen });
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
