import { createReducer, on } from '@ngrx/store';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { EinsatztagebuchStateInterface } from './einsatztagebuch-state.interface';
import { einsatztagebuchActions } from './einsatztagebuch.actions';

const initialState: EinsatztagebuchStateInterface = {
  isLoading: false,
  isSaving: false,
  einsatztagebuch: [],
  loadedLageId: '',
};

export const einsatztagebuchReducer = createReducer(
  initialState,

  // Reset Store
  on(einsatztagebuchActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Einsatztagebuch
  on(einsatztagebuchActions.getEinsatztagebuch, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(einsatztagebuchActions.getEinsatztagebuchSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    einsatztagebuch: action.eintraege,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(einsatztagebuchActions.getEinsatztagebuchFailure, (state, action) => ({
    ...state,
    isLoading: false,
    einsatztagebuch: [],
    errorResponse: action.errorResponse,
  })),

  // Create Einsatztagebucheintrag
  on(einsatztagebuchActions.createEinsatztagebucheintrag, (state) => ({
    ...state,
    isSaving: true,
    errorResponse: undefined,
  })),
  on(einsatztagebuchActions.createEinsatztagebucheintragSuccess, (state, action) => ({
    ...state,
    einsatztagebuch: [...state.einsatztagebuch, action.newEintrag],
    isSaving: false,
    errorResponse: undefined,
  })),
  on(einsatztagebuchActions.createEinsatztagebucheintragFailure, (state, action) => ({
    ...state,
    isSaving: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Einsatztagebucheintrag
  on(einsatztagebuchActions.patchEinsatztagebucheintrag, (state) => ({
    ...state,
    isSaving: true,
    errorResponse: undefined,
  })),
  on(einsatztagebuchActions.patchEinsatztagebucheintragSuccess, (state, action) => ({
    ...state,
    einsatztagebuch: replaceOrKeepCurrent(state.einsatztagebuch, action.patchedEintrag),
    isSaving: false,
    errorResponse: undefined,
  })),
  on(einsatztagebuchActions.patchEinsatztagebucheintragFailure, (state, action) => ({
    ...state,
    isSaving: false,
    errorResponse: action.errorResponse,
  }))
);
