import { createReducer, on } from '@ngrx/store';
import { AnlassDTO } from 'src/app/api/build/openapi';
import { addOrReplaceWithLatest } from 'src/app/shared/dto-version-tools';
import { AnlassStateInterface } from './anlass-state.interface';
import { anlassActions } from './anlass.actions';

export const initialState: AnlassStateInterface = {
  isCreating: false,
  isLoading: false,
  isDeleting: false,
  isPatching: false,
  anlaesse: [],
  loadedLageId: '',
};

export const anlassReducer = createReducer(
  initialState,

  // Reset Store
  on(anlassActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Anlaesse
  on(anlassActions.getAnlaesse, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(anlassActions.getAnlaesseSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    anlaesse: action.anlassDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(anlassActions.getAnlaesseFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Anlass
  on(anlassActions.createAnlass, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(anlassActions.createAnlassSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    anlaesse: [...state.anlaesse, action.createdAnlassDTO],
    errorResponse: undefined,
  })),
  on(anlassActions.createAnlassFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Anlass
  on(anlassActions.patchAnlass, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(anlassActions.patchAnlassSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    anlaesse: addOrReplaceWithLatest(state.anlaesse, action.patchedAnlassDTO),
    errorResponse: undefined,
  })),
  on(anlassActions.patchAnlassFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Anlass
  on(anlassActions.deleteAnlass, (state) => ({ ...state, isDeleting: true, errorResponse: undefined })),
  on(anlassActions.deleteAnlassSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    anlaesse: state.anlaesse.filter((anlass: AnlassDTO) => anlass.id !== action.deletedAnlassDTO.id),
    errorResponse: undefined,
  })),
  on(anlassActions.deleteAnlassFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  }))
);
