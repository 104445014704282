<mat-toolbar>
  <h3>{{ title }}</h3>

  <button mat-icon-button (click)="refreshClicked.emit()">
    <mat-icon>refresh</mat-icon>
  </button>
</mat-toolbar>

<div class="content" *ngIf="einsatzDTOs$ | async as einsatzDTOs">
  <table mat-table [dataSource]="einsatzDTOs">
    <ng-container matColumnDef="einsatznummer">
      <th mat-header-cell *matHeaderCellDef>Einsatznummer</th>
      <td mat-cell *matCellDef="let einsatzDTO">{{ einsatzDTO.einsatznummer }}</td>
    </ng-container>

    <ng-container matColumnDef="einsatzstatus">
      <th mat-header-cell *matHeaderCellDef>Einsatzstatus</th>
      <td mat-cell *matCellDef="let einsatzDTO">{{ einsatzDTO.einsatzstatus }}</td>
    </ng-container>

    <ng-container matColumnDef="einsatzeroeffnung">
      <th mat-header-cell *matHeaderCellDef>Einsatzeröffnung</th>
      <td mat-cell *matCellDef="let einsatzDTO">{{ einsatzDTO.einsatzeroeffnung | dateTime | async }}</td>
    </ng-container>

    <ng-container matColumnDef="einsatzart1">
      <th mat-header-cell *matHeaderCellDef>Einsatzart 1</th>
      <td mat-cell *matCellDef="let einsatzDTO">{{ einsatzDTO.einsatzart1 }}</td>
    </ng-container>

    <ng-container matColumnDef="einsatzstichwort1">
      <th mat-header-cell *matHeaderCellDef>Stichwort 1</th>
      <td mat-cell *matCellDef="let einsatzDTO">{{ einsatzDTO.einsatzstichwort1 }}</td>
    </ng-container>

    <ng-container matColumnDef="einsatzart2">
      <th mat-header-cell *matHeaderCellDef>Einsatzart 2</th>
      <td mat-cell *matCellDef="let einsatzDTO">{{ einsatzDTO.einsatzart2 }}</td>
    </ng-container>

    <ng-container matColumnDef="einsatzstichwort2">
      <th mat-header-cell *matHeaderCellDef>Stichwort 2</th>
      <td mat-cell *matCellDef="let einsatzDTO">{{ einsatzDTO.einsatzstichwort2 }}</td>
    </ng-container>

    <ng-container matColumnDef="meldebild">
      <th mat-header-cell *matHeaderCellDef>Meldebild</th>
      <td mat-cell *matCellDef="let einsatzDTO">{{ einsatzDTO.meldebild }}</td>
    </ng-container>

    <ng-container matColumnDef="einsatzort">
      <th mat-header-cell *matHeaderCellDef>Einsatzort</th>
      <td mat-cell *matCellDef="let einsatzDTO">{{ einsatzDTO.einsatzort?.strasse }}</td>
    </ng-container>

    <tr class="headers" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let einsatzDTO; columns: displayedColumns" (click)="openEinsatzDialog(einsatzDTO)"></tr>
  </table>

  <h3 class="keine-daten-text" *ngIf="!einsatzDTOs.length">Keine Einsätze vorhanden</h3>
</div>
