import { inject, Injectable, isDevMode } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ConsoleTransport,
  faro,
  getWebInstrumentations,
  initializeFaro,
  InternalLoggerLevel,
} from '@grafana/faro-web-sdk';
import { combineLatest, interval } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

const LOCALSTORAGEKEY_METRICS_ID = 'metrics_id';

const app = {
  name: 'lage',
  version: '1.0.0',
};

interface MemoryMetrics {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
}

// TODO: kommt man hier besser dran? https://web.dev/articles/monitor-total-page-memory-usage?hl=de

/**
 * Service, der Metriken via Faro sendet
 */
@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  authService = inject(AuthService);

  constructor() {
    // Faro initialisieren, wenn authentifizert
    this.authService.isAuthenticated$.pipe(takeUntilDestroyed()).subscribe((authenticated) => {
      if (!authenticated) {
        return;
      }

      this.initFaro();
      faro.api.setUser({ id: this.getOrCreateId() });
    });

    // Wenn authentifiziert, regelmäßig Speicher auslesen und Metrik senden
    combineLatest([this.authService.isAuthenticated$, interval(30_000)])
      .pipe(takeUntilDestroyed())
      .subscribe(([authenticated, _]) => {
        if (!authenticated) {
          return;
        }

        const memMetrics = this.readMemory();

        faro.api.pushMeasurement({
          type: 'client_memory_info',
          values: {
            used_js_heap_size_bytes: memMetrics.usedJSHeapSize,
            total_js_heap_size_bytes: memMetrics.totalJSHeapSize,
            js_heap_size_limit_bytes: memMetrics.jsHeapSizeLimit,
          },
        });
      });
  }

  private readMemory(): MemoryMetrics {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const memory = (performance as any).memory;

    return {
      jsHeapSizeLimit: memory.jsHeapSizeLimit,
      totalJSHeapSize: memory.totalJSHeapSize,
      usedJSHeapSize: memory.usedJSHeapSize,
    };
  }

  /**
   * Faro initialisieren: https://github.com/grafana/faro-web-sdk/blob/main/docs/sources/tutorials/quick-start-browser.md
   */
  public initFaro() {
    if (isDevMode()) {
      // Initialisieren für lokale Entwicklung
      initializeFaro({
        app,
        transports: [new ConsoleTransport()],
      });
    } else {
      // Initialisieren für Produktiv-Betrieb
      initializeFaro({
        url: '/api/faro-proxy/collect',
        // TODO - muss der jetzt raus oder rein!?!?!?! https://gitlab.ise-dev.de/product/lage/lage-frontend/-/merge_requests/453/diffs
        apiKey: 'GyTbrzQRR14q5VfwjQPL',
        // instrumentations: [...getWebInstrumentations(), new TracingInstrumentation()],
        instrumentations: [...getWebInstrumentations({ captureConsole: true })],
        app,
        internalLoggerLevel: InternalLoggerLevel.INFO,
      });
    }
  }

  /**
   * Im local storage wird eine UUID hinterlegt, um Metriken eines Clients zusammenfassen zu können.
   *
   * Es besteht zunächst kein Zusammenhang zwischen angemeldetem Nutzer und Client (=Browser).
   *
   * UUID sollte gleich bleiben ...
   * ... bis  local storage gelöscht wird
   * ... über mehrere Sessions hinweg
   * ... auch über mehrere Browserfenster hinweg (bisher keine Unterscheideung)
   *
   *
   * @returns liefert UUID-String
   */
  private getOrCreateId(): string {
    const existingId = localStorage.getItem(LOCALSTORAGEKEY_METRICS_ID);

    if (existingId) {
      return existingId;
    }

    const createdId = crypto.randomUUID();
    localStorage.setItem(LOCALSTORAGEKEY_METRICS_ID, createdId);

    return createdId;
  }
}
