import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WetterService {
  public kmhToBeaufort(kmh: number): number {
    if (kmh >= 117) {
      return 12;
    } else if (kmh >= 103) {
      return 11;
    } else if (kmh >= 89) {
      return 10;
    } else if (kmh >= 75) {
      return 9;
    } else if (kmh >= 62) {
      return 8;
    } else if (kmh >= 50) {
      return 7;
    } else if (kmh >= 39) {
      return 6;
    } else if (kmh >= 29) {
      return 5;
    } else if (kmh >= 20) {
      return 4;
    } else if (kmh >= 12) {
      return 3;
    } else if (kmh >= 6) {
      return 2;
    } else if (kmh >= 1) {
      return 1;
    }
    return 0;
  }
}
