<mat-toolbar>
  <h2>{{ "Kontaktmöglichkeit" + (newContact ? " hinzufügen" : " bearbeiten") }}</h2>
</mat-toolbar>

<form [formGroup]="formGroup">
  <!-- Bezeichner für diesen Kontaktweg -->
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [formControlName]="name" required="true" />
    <mat-error>Verpflichtend</mat-error>
  </mat-form-field>

  <!-- KontaktTyp -->
  <div class="radio-group">
    <label required="true" for="kontaktTyp">Kontakt Typ</label>
    <mat-radio-group [formControlName]="kontaktTyp" name="kontaktTyp" required="true">
      <mat-radio-button [value]="KontaktTyp.Mobiltelefon">{{
        kontaktService.KontaktTypMapping.get(KontaktTyp.Mobiltelefon)!.text
      }}</mat-radio-button>
      <mat-radio-button [value]="KontaktTyp.Festnetz">{{
        kontaktService.KontaktTypMapping.get(KontaktTyp.Festnetz)!.text
      }}</mat-radio-button>
      <mat-radio-button [value]="KontaktTyp.Fax">{{
        kontaktService.KontaktTypMapping.get(KontaktTyp.Fax)!.text
      }}</mat-radio-button>
      <mat-radio-button [value]="KontaktTyp.Email">{{
        kontaktService.KontaktTypMapping.get(KontaktTyp.Email)!.text
      }}</mat-radio-button>
      <mat-radio-button [value]="KontaktTyp.Funk">{{
        kontaktService.KontaktTypMapping.get(KontaktTyp.Funk)!.text
      }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- Festnetz-, Fax-, Mobilnummer oder Email wenn KontaktTyp FESTNETZ, FAX, MOBILTELEFON oder EMAIL -->
  <mat-form-field *ngIf="formGroup.get(kontaktTyp)?.value === KontaktTyp.Mobiltelefon">
    <mat-label>Mobiltelefon Nummer</mat-label>
    <input matInput [formControlName]="mobiltelefon" type="tel" required="true" />
    <mat-error>Muss aus 10-15 Zahlen bestehen</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="formGroup.get(kontaktTyp)?.value === KontaktTyp.Festnetz">
    <mat-label>Festnetz Nummer</mat-label>
    <input matInput [formControlName]="festnetz" type="tel" />
    <mat-error>Muss aus 10-15 Zahlen bestehen</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="formGroup.get(kontaktTyp)?.value === KontaktTyp.Fax">
    <mat-label>Fax Nummer</mat-label>
    <input matInput [formControlName]="fax" type="tel" />
    <mat-error>Muss aus 10-15 Zahlen bestehen</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="formGroup.get(kontaktTyp)?.value === KontaktTyp.Email">
    <mat-label>E-Mail Adresse</mat-label>
    <input matInput [formControlName]="email" type="email" />
    <mat-error>E-Mail Format muss verwendet werden</mat-error>
  </mat-form-field>

  <!-- Funktechnik wenn KontaktTyp FUNK -->
  <div class="radio-group" *ngIf="formGroup.get(kontaktTyp)?.value === KontaktTyp.Funk">
    <label for="funktechnik">Funktechnik</label>
    <mat-radio-group [formControlName]="funktechnik" name="funktechnik">
      <mat-radio-button [value]="Funktechnik.Digital">{{
        kontaktService.FunktechnikMapping.get(Funktechnik.Digital)
      }}</mat-radio-button>
      <mat-radio-button [value]="Funktechnik.Analog">{{
        kontaktService.FunktechnikMapping.get(Funktechnik.Analog)
      }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- DIGITAL CONTAINER -->
  <div *ngIf="formGroup.get(funktechnik)?.value === Funktechnik.Digital">
    <!-- Betriebsart wenn Funktechnik Digital -->
    <div class="radio-group">
      <label for="digitalBetriebsart">Betriebsart</label>
      <mat-radio-group [formControlName]="digitalBetriebsart" name="digitalBetriebsart">
        <mat-radio-button [value]="DigitalBetriebsart.Dmo">{{
          kontaktService.DigitalBetriebsartMapping.get(DigitalBetriebsart.Dmo)
        }}</mat-radio-button>
        <mat-radio-button [value]="DigitalBetriebsart.Tmo">{{
          kontaktService.DigitalBetriebsartMapping.get(DigitalBetriebsart.Tmo)
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Rufgruppe -->
    <mat-form-field>
      <mat-label>Rufgruppe</mat-label>
      <input matInput [formControlName]="digitalFunkrufgruppe" />
    </mat-form-field>
  </div>

  <!-- ANALOG CONTAINER -->
  <div *ngIf="formGroup.get(funktechnik)?.value === Funktechnik.Analog">
    <!-- Band wenn Funktechnik Analog -->
    <div class="radio-group">
      <label for="analogBand">Band</label>
      <mat-radio-group [formControlName]="analogBand" name="analogBand">
        <mat-radio-button [value]="AnalogBand.ZweiMeter">{{
          kontaktService.AnalogBandMapping.get(AnalogBand.ZweiMeter)
        }}</mat-radio-button>
        <mat-radio-button [value]="AnalogBand.VierMeter">{{
          kontaktService.AnalogBandMapping.get(AnalogBand.VierMeter)
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Betriebsart wenn Funktechnik Analog -->
    <div class="radio-group">
      <label for="analogBetriebsart">Betriebsart</label>
      <mat-radio-group class="flex-col" [formControlName]="analogBetriebsart" name="analogBetriebsart">
        <mat-radio-button [value]="AnalogBetriebsart.W">{{
          kontaktService.AnalogBetriebsartMapping.get(AnalogBetriebsart.W)
        }}</mat-radio-button>
        <mat-radio-button [value]="AnalogBetriebsart.G">{{
          kontaktService.AnalogBetriebsartMapping.get(AnalogBetriebsart.G)
        }}</mat-radio-button>
        <mat-radio-button [value]="AnalogBetriebsart.Bg">{{
          kontaktService.AnalogBetriebsartMapping.get(AnalogBetriebsart.Bg)
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Relaisbetrieb wenn Funktechnik Analog -->
    <div class="radio-group">
      <label for="analogRelaisbetrieb">Relaisbetrieb</label>
      <mat-radio-group [formControlName]="analogRelaisbetrieb" name="analogRelaisbetrieb">
        <mat-radio-button [value]="AnalogRelaisbetrieb.Rs1">{{
          kontaktService.AnalogRelaisbetriebMapping.get(AnalogRelaisbetrieb.Rs1)
        }}</mat-radio-button>
        <mat-radio-button [value]="AnalogRelaisbetrieb.Rs2">{{
          kontaktService.AnalogRelaisbetriebMapping.get(AnalogRelaisbetrieb.Rs2)
        }}</mat-radio-button>
        <mat-radio-button [value]="AnalogRelaisbetrieb.Rs3">{{
          kontaktService.AnalogRelaisbetriebMapping.get(AnalogRelaisbetrieb.Rs3)
        }}</mat-radio-button>
        <mat-radio-button [value]="AnalogRelaisbetrieb.Rs4">{{
          kontaktService.AnalogRelaisbetriebMapping.get(AnalogRelaisbetrieb.Rs4)
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Tonruf wenn Funktechnik Analog -->
    <div class="radio-group">
      <label for="analogTonruf">Tonruf</label>
      <mat-radio-group [formControlName]="analogTonruf" name="analogTonruf">
        <mat-radio-button [value]="AnalogTonruf.Tonruf1">{{
          kontaktService.AnalogTonrufMapping.get(AnalogTonruf.Tonruf1)
        }}</mat-radio-button>
        <mat-radio-button [value]="AnalogTonruf.Tonruf2">{{
          kontaktService.AnalogTonrufMapping.get(AnalogTonruf.Tonruf2)
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Funkkanal -->
    <mat-form-field>
      <mat-label>Funkkanal</mat-label>
      <input matInput [formControlName]="analogFunkkanal" />
    </mat-form-field>
  </div>

  <!-- Funkrufname -->
  <mat-form-field *ngIf="formGroup.get(kontaktTyp)?.value === KontaktTyp.Funk">
    <mat-label>Funkrufname</mat-label>
    <input matInput [formControlName]="funkrufname" required="true" />
  </mat-form-field>
</form>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="save()" class="mr-1">Speichern</button>
</mat-dialog-actions>
