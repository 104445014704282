import { KeyValue } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store, select } from '@ngrx/store';
import { Observable, timer } from 'rxjs';
import { AppStateInterface } from '../+state/appState.interface';
import { LageMassnahmeDTO, Prioritaet, Rolle, Sachgebiet } from '../api/build/openapi';
import { currentLageSelector } from './lagen/+state/lage.selectors';
import { lageMassnahmeActions } from './massnahmen/+state/lage-massnahme.actions';
import {
  currentLageMassnahmenSelector,
  isLoadingMassnahmenSelector,
} from './massnahmen/+state/lage-massnahme.selectors';
import { LagePrio } from './massnahmen/massnahme-add-dialog/massnahme-add-dialog.component';
import { PersonenInfo } from './massnahmen/massnahme-beenden-dialog/massnahme-beenden-dialog.component';

export interface MassnahmenAddDialogData {
  massnahme: LageMassnahmeDTO;
}

@Injectable({
  providedIn: 'root',
})
export class MassnahmenService {
  static readonly rolleMapping = new Map<Rolle, string>([
    [Rolle.Einsatzleiter, 'Einsatzleiter'],
    [Rolle.LeiterFuehrungsstab, 'Leiter Führungsstab'],
    [Rolle.Fachberater, 'Fachberater'],
    [Rolle.Verbindungsperson, 'Verbindungsperson'],
    [Rolle.S1Leiter, 'S1 Leiter'],
    [Rolle.S2Leiter, 'S2 Leiter'],
    [Rolle.S3Leiter, 'S3 Leiter'],
    [Rolle.S4Leiter, 'S4 Leiter'],
    [Rolle.S5Leiter, 'S5 Leiter'],
    [Rolle.S6Leiter, 'S6 Leiter'],
    [Rolle.FernmeldedienstLeiter, 'Fernmeldedienst Leiter'],
    [Rolle.S1Fuehrungsassistent, 'S1 Führungsassistent'],
    [Rolle.S2Fuehrungsassistent, 'S2 Führungsassistent'],
    [Rolle.S3Fuehrungsassistent, 'S3 Führungsassistent'],
    [Rolle.S4Fuehrungsassistent, 'S4 Führungsassistent'],
    [Rolle.S5Fuehrungsassistent, 'S5 Führungsassistent'],
    [Rolle.S6Fuehrungsassistent, 'S6 Führungsassistent'],
    [Rolle.FernmeldedienstFuehrungsassistent, 'Fernmeldedienst Führungsassistent'],
    [Rolle.S1Fuehrungshilfspersonal, 'S1 Führungshilfspersonal'],
    [Rolle.S2Fuehrungshilfspersonal, 'S2 Führungshilfspersonal'],
    [Rolle.S3Fuehrungshilfspersonal, 'S3 Führungshilfspersonal'],
    [Rolle.S4Fuehrungshilfspersonal, 'S4 Führungshilfspersonal'],
    [Rolle.S5Fuehrungshilfspersonal, 'S5 Führungshilfspersonal'],
    [Rolle.S6Fuehrungshilfspersonal, 'S6 Führungshilfspersonal'],
    [Rolle.FernmeldedienstFuehrungshilfspersonal, 'Fernmeldedienst Führungshilfspersonal'],
  ]);

  static readonly sachgebietMapping = new Map<Sachgebiet, string>([
    [Sachgebiet.S1, 'S1'],
    [Sachgebiet.S2, 'S2'],
    [Sachgebiet.S3, 'S3'],
    [Sachgebiet.S4, 'S4'],
    [Sachgebiet.S5, 'S5'],
    [Sachgebiet.S6, 'S6'],
    [Sachgebiet.Fernmeldedienst, 'Fernmeldedienst'],
  ]);

  static readonly prioMapping: Map<Prioritaet, LagePrio> = new Map<Prioritaet, LagePrio>([
    [Prioritaet.Blitz, { name: 'Blitz', wert: 3, icon: 'flash_on', farbe: 'rgb(214, 28, 78)' }],
    [Prioritaet.Sofort, { name: 'Sofort', wert: 2, icon: 'arrow_upward', farbe: 'rgb(255, 159, 41)' }],
    [Prioritaet.Normal, { name: 'Normal', wert: 1, icon: 'format_align_justify', farbe: 'rgb(139, 219, 129)' }],
    [Prioritaet.Zurueckgestellt, { name: 'Zurückgestellt', wert: 0, icon: 'arrow_downward', farbe: 'lightgray' }],
  ]);

  //Sortierfunktion für keyvalue pipe
  static prioSort = (a: KeyValue<Prioritaet, LagePrio>, b: KeyValue<Prioritaet, LagePrio>): number => {
    return b.value.wert - a.value.wert;
  };

  store: Store<AppStateInterface> = inject(Store);
  private lageId?: string;

  erledigteMassnahmen = 0;
  mengeMassnahmen = 0;
  massnahmen: LageMassnahmeDTO[] = [];
  isLoadingMassnahmen$: Observable<boolean>;

  private unerledigteMassnahmenCount = 0;

  constructor() {
    this.store.pipe(select(currentLageSelector)).subscribe((lage) => (this.lageId = lage?.id));

    this.isLoadingMassnahmen$ = this.store.select(isLoadingMassnahmenSelector).pipe(takeUntilDestroyed());

    timer(10000, 10000)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.countUnerledigt());

    this.store
      .select(currentLageMassnahmenSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((m) => {
        this.erledigteMassnahmen = m.filter((m) => m.erledigt === true).length;
        this.mengeMassnahmen = m.length;
        this.massnahmen = m;
      });
  }

  public addMassnahme(massnahme: LageMassnahmeDTO) {
    this.store.dispatch(lageMassnahmeActions.addLageMassnahme({ lageId: this.lageId, massnahme: massnahme }));
  }

  public getErledigtProzent(): number {
    return Math.floor((this.erledigteMassnahmen / this.mengeMassnahmen) * 100);
  }

  public updateMassnahme(massnahme: LageMassnahmeDTO) {
    if (!this.lageId || !massnahme.id) {
      return;
    }

    this.store.dispatch(
      lageMassnahmeActions.patchLageMassnahme({
        lageid: this.lageId,
        massnahmeId: massnahme.id,
        massnahme: massnahme,
      })
    );
  }

  public massnahmeBeenden(massnahme: LageMassnahmeDTO, person: PersonenInfo) {
    if (!this.lageId || !massnahme.id) {
      return;
    }

    const patchedMassnahme: LageMassnahmeDTO = {
      ...massnahme,
      erledigt: true,
      abgelaufen: false,
      erledigtDurchRolle: person.rolle,
      erledigtDurchName: person.name,
      erledigtDurchDatum: person.datum.toString(),
    };

    this.store.dispatch(
      lageMassnahmeActions.patchLageMassnahme({
        lageid: this.lageId,
        massnahmeId: massnahme.id,
        massnahme: patchedMassnahme,
      })
    );
  }

  //Massnahmen, die erledigt sein sollten abhaenig von eledigt und personBisWann.date
  public getUnerledigt(): number {
    return this.unerledigteMassnahmenCount;
  }

  private countUnerledigt() {
    const abgelaufen: LageMassnahmeDTO[] = this.massnahmen
      .filter((a) => !a.erledigt)
      .filter((a) => a.bisWannDatum && new Date(a.bisWannDatum) < new Date());
    abgelaufen.forEach((a) => {
      if (a.abgelaufen) {
        return;
      }
      this.updateMassnahme({ ...a, abgelaufen: true });
    });
    this.unerledigteMassnahmenCount = abgelaufen.length;
  }

  private sortMassnahmen(a: LageMassnahmeDTO, b: LageMassnahmeDTO): number {
    if (a.abgelaufen || b.abgelaufen) {
      return a.abgelaufen ? -1 : 1;
    }

    if (a.erledigt || b.erledigt) {
      return a.erledigt ? 1 : -1;
    }

    if (a.prioritaet === b.prioritaet) {
      if (!a.bisWannDatum) {
        if (!b.bisWannDatum) {
          return 0;
        }
        return -1;
      }
      if (!b.bisWannDatum) {
        return 1;
      }

      const dA: Date = new Date(a.bisWannDatum);
      const dB: Date = new Date(b.bisWannDatum);
      return dA.getTime() - dB.getTime();
    } else {
      //return b.prioritaet!.wert? - a.prio.wert!;
      return 1;
    }
  }
}
