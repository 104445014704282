import { createSelector } from '@ngrx/store';
import { AppStateInterface, taktischeFormationen } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[taktischeFormationen];

export const taktischeFormationenSelector = createSelector(selectFeature, (state) => state.taktischeFormationen);
export const taktischeFormationenErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingTaktischeFormationenSelector = createSelector(selectFeature, (state) => state.isLoading);
export const taktischeFormationenLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const taktischeFormationenOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.taktischeFormationen.filter((taktischeFormation) => taktischeFormation.fuehrungsebeneId === fuehrungsebeneId)
  );
