import {
  Component,
  ViewChild,
  OnInit,
  ElementRef,
  AfterViewInit,
  Input,
  ViewChildren,
  ViewContainerRef,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Chart, registerables } from 'chart.js/auto';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCard, MatCardModule } from '@angular/material/card';
import { ChartContainerComponent } from '../chart-container/chart-container.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EventEmitter } from '@angular/core';
import { EinsatzraumDataSet } from '../kennzahlen-dashboad.service';
Chart.register(...registerables);

@Component({
  selector: 'app-dashboard-lage',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatCardModule, MatButtonModule, MatIconModule, ChartContainerComponent],
  templateUrl: './dashboard-lage.component.html',
  styleUrls: ['./dashboard-lage.component.scss'],
})
export class DashboardLageComponent implements AfterViewInit {
  @ViewChild('tzchart')
  canvas!: ElementRef<HTMLCanvasElement>;

  @ViewChildren('einsatzstellenchart')
  private chart?: Chart<any>;
  private data: number[] = [];
  einsatzraeumne: EinsatzraumDataSet[] = [];
  schadensgebiet?: EinsatzraumDataSet;

  @ViewChild('einsatzraume', { read: ViewContainerRef })
  einsatzraeumeContainer?: ViewContainerRef;

  @Input()
  set schadensgebietDataset(schadensgebiet: EinsatzraumDataSet) {
    this.schadensgebiet = schadensgebiet;
    this.updateSchadensgebiet();
  }

  @Input()
  set einsatzraumeDatasets(dataSets: EinsatzraumDataSet[]) {
    this.einsatzraeumne = dataSets;
    this.updateEinsatzraeume();
  }

  @Output()
  reload: EventEmitter<void> = new EventEmitter<void>();

  ngAfterViewInit(): void {
    Chart.defaults.animation = false;
    const ctx = this.canvas.nativeElement;
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Anlass / Ereignisse', 'Gefahren', 'Maßnahmen'],

        datasets: [
          {
            data: this.data,
            backgroundColor: ['rgb(255, 77, 174)', 'rgb(255, 64, 129)', 'rgb(179, 18, 106)'],
            hoverOffset: 4,
            circumference: 180,
            rotation: 270,
          },
        ],
      },
      options: {
        animation: false,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',

            labels: {
              generateLabels: (chart) => {
                const datasets: any = chart.data.datasets;
                return datasets[0].data.map((data: any, i: number) => ({
                  text: `${chart.data.labels![i]} ${data}`,
                  fillStyle: datasets![0].backgroundColor![i],
                  index: i,
                }));
              },
            },
          },
        },
      },
    });

    this.updateEinsatzraeume();
    this.updateSchadensgebiet();
  }

  updateSchadensgebiet() {
    if (this.chart && this.schadensgebiet) {
      this.chart.data.datasets[0].data = this.schadensgebiet.data;
      this.chart.update();
    }
  }

  updateEinsatzraeume() {
    if (this.einsatzraeumeContainer) {
      this.einsatzraeumeContainer.clear();
      this.einsatzraeumne.forEach((dataset) => {
        const c = this.einsatzraeumeContainer?.createComponent(ChartContainerComponent);
        c!.setInput('dataset', dataset);
        c!.changeDetectorRef.detectChanges();
      });
    }
  }

  onClickReload() {
    this.reload.emit();
  }
}
