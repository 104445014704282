import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { WindowManagerService } from 'src/app/lagedarstellung/monitor-manager/window-manager.service';
import { EinsatzmittelBaseComponent } from '../einsatzmittel-base/einsatzmittel-base.component';
import { LageMapComponent } from '../lage-map/lage-map.component';
import { ScreenThreeComponent } from '../screen-three/screen-three.component';

@Component({
  selector: 'app-lagedarstellung-window-tabs',
  templateUrl: './lagedarstellung-window-tabs.component.html',
  styleUrls: ['./lagedarstellung-window-tabs.component.scss'],
  standalone: true,
  imports: [NgIf, MatTabsModule, EinsatzmittelBaseComponent, LageMapComponent, ScreenThreeComponent, AsyncPipe],
})
export class LagedarstellungWindowTabsComponent implements OnInit, OnDestroy {
  constructor(protected monitorManager: WindowManagerService) {}

  ngOnInit(): void {
    this.monitorManager.openSecondaryWindows();
  }

  ngOnDestroy(): void {
    this.monitorManager.closeSecondaryWindows();
  }

  // Resize Event feuern, da Leaflet Map beim Tab-Wechsel sonst nicht auf gesamtes Div gestreckt sind
  selectedTabChange() {
    window.dispatchEvent(new Event('resize'));
  }
}
