<app-tz-popup-toolbar [tzDto]="dto" />

<div class="content">
  <app-fuehrungsebene-icon-and-text *ngIf="parentFuehrungsebene" [fuehrungsebeneDTO]="parentFuehrungsebene" />

  <div class="columns">
    <mat-card class="column">
      <div class="column-header">
        <img src="assets/svg/tz/person-verletzt.svg" />
        <h5>verletzt</h5>
      </div>
      <div class="column-data">
        <h5>{{ dto.bevVerletzt + dto.ekVerletzt }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img src="assets/svg/tz/person-tot.svg" />
        <h5>tot</h5>
      </div>
      <div class="column-data">
        <h5>{{ dto.bevTot + dto.ekTot }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img src="assets/svg/tz/person-vermisst.svg" />
        <h5>vermisst</h5>
      </div>
      <div class="column-data">
        <h5>{{ dto.bevVermisst + dto.ekVermisst }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img src="assets/svg/tz/person-betroffen.svg" />
        <h5>betroffen</h5>
      </div>
      <div class="column-data">
        <h5>{{ dto.bevBetroffen + dto.ekBetroffen }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img src="assets/svg/tz/person-gerettet.svg" />
        <h5>gerettet</h5>
      </div>
      <div class="column-data">
        <h5>{{ dto.bevGerettet + dto.ekGerettet }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img src="assets/svg/tz/person-transportiert.svg" />
        <h5>transp.</h5>
      </div>
      <div class="column-data">
        <h5>{{ dto.bevTransportiert + dto.ekTransportiert }}</h5>
      </div>
    </mat-card>
  </div>

  <div class="columns">
    <mat-card class="column">
      <div class="column-data">
        <h5>{{ dto.manv1 }}</h5>
      </div>
      <div class="column-header category1" matTooltip="Akute, vitale Bedrohung">
        <h5>I</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ dto.manv2 }}</h5>
      </div>
      <div class="column-header category2" matTooltip="Schwer verletzt/erkrankt">
        <h5>II</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ dto.manv3 }}</h5>
      </div>
      <div class="column-header category3" matTooltip="Leicht verletzt/erkrankt">
        <h5>III</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ (dto.manv1 || 0) + (dto.manv2 || 0) + (dto.manv3 || 0) }}</h5>
      </div>
      <div class="column-header sum" matTooltip="Summe aus Kategorien I, II, III">
        <h5>SUMME</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ dto.manv4 }}</h5>
      </div>
      <div class="column-header category4" matTooltip="Ohne Überlebenschance">
        <h5>IV</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-data">
        <h5>{{ dto.manvTote }}</h5>
      </div>
      <div class="column-header dead" matTooltip="Tote">
        <h5>TOTE</h5>
      </div>
    </mat-card>
  </div>
</div>
