import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'natoDateTime',
    standalone: true,
})
export class NatoDateTimePipe implements PipeTransform {
  constructor(private date: DatePipe) {}

  transform(value: Date): string {
    return this.date.transform(value, 'ddHHmmMMMYY')?.toLowerCase() || '';
  }
}
