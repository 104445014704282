import { Injectable, inject } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { FotoDTO, FotoPreviewDTO, FotoResourceService } from 'src/app/api/build/openapi';
import { FuehrungsebeneService } from '../../fuehrungsebene/fuehrungsebene.service';

@Injectable({
  providedIn: 'root',
})
export class FotoService {
  private fotoResourceService = inject(FotoResourceService);
  private fuehrungsebeneService = inject(FuehrungsebeneService);

  /**
   * Lädt das vollständige FotoDTO mit Bilddaten zu der übergebenen FotoPreview
   */
  getFotoDTO(fotoPreview: FotoPreviewDTO): Observable<FotoDTO> {
    if (!fotoPreview.id) {
      return EMPTY;
    }

    const currentLage = this.fuehrungsebeneService.getCurrentLage();
    if (!currentLage || !currentLage.id) {
      return EMPTY;
    }

    return this.fotoResourceService.getFotoById(fotoPreview.id, currentLage.id);
  }
}
