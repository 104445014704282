<mat-tab-group
  class="full-height"
  selectedIndex="1"
  (selectedTabChange)="selectedTabChange()"
  *ngIf="(monitorManager.useMultiWindowSetup$ | async) === false; else lagedarstellung"
>
  <!-- Tab 1 -->
  <mat-tab label="Ressourcen">
    <app-einsatzmittel-base class="full-height" />
  </mat-tab>

  <!-- Tab 2 -->
  <mat-tab label="Lagekarte & Räumliche Gliederung">
    <app-lage-map class="full-height" />
  </mat-tab>

  <!-- Tab 3 -->
  <mat-tab label="Organisation & Maßnahmen">
    <app-screen-three class="full-height" />
  </mat-tab>
</mat-tab-group>

<ng-template #lagedarstellung>
  <app-lage-map class="full-height" />
</ng-template>
