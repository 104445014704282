import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogAction, DialogService } from '@product/ise-dialog-lib';
import { BehaviorSubject } from 'rxjs';
import { TagebucheintragDTO } from 'src/app/api/build/openapi';
import {
  TagebucheintragDialogComponent,
  TagebucheintragDialogInputData,
} from '../tagebucheintrag-dialog/tagebucheintrag-dialog.component';

@Component({
  selector: 'app-tagebuch',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
  ],
  templateUrl: './tagebuch.component.html',
  styleUrls: ['./tagebuch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagebuchComponent implements OnChanges {
  private dialog = inject(MatDialog);
  private dialogService = inject(DialogService);

  @Input()
  tagebucheintraege: TagebucheintragDTO[] = [];

  protected tagebucheintraege$ = new BehaviorSubject<TagebucheintragDTO[]>([]);
  protected selectedIndex = -1;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tagebucheintraege']) {
      this.tagebucheintraege$.next(this.tagebucheintraege);
    }
  }

  /**
   * Öffnet den TagebucheintragDialogComponent, um einen neuen Tagebucheintrag anzulegen
   */
  addTagebucheintrag() {
    const dialogData: TagebucheintragDialogInputData = { tagebucheintrag: {}, newEntry: true };
    this.dialog
      .open(TagebucheintragDialogComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.tagebucheintraege$.next([...this.tagebucheintraege$.value, dialogResult]);
          this.selectedIndex = this.tagebucheintraege$.value.length - 1;
        }
      });
  }

  /**
   * Öffnet den TagebucheintragDialogComponent, um die ausgewählten Tagebucheintrag zu bearbeiten
   */
  editTagebucheintrag() {
    const dialogData: TagebucheintragDialogInputData = {
      tagebucheintrag: { ...this.tagebucheintraege$.value[this.selectedIndex] },
      newEntry: false,
    };
    this.dialog
      .open(TagebucheintragDialogComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.tagebucheintraege$.next(
            this.tagebucheintraege$.value.map((tagebucheintrag, index) =>
              index === this.selectedIndex ? dialogResult : tagebucheintrag
            )
          );
        }
      });
  }

  /**
   * Nachfragen, ob der ausgewählte Tagebucheintrag gelöscht werden soll
   */
  askDeleteTagebucheintrag() {
    this.dialogService
      .openConfirmDialog('Tagebucheintrag löschen?', 'Tagebucheintrag sicher löschen?')
      .afterClosed()
      .subscribe((result: DialogAction) => {
        if (result === DialogAction.OK) {
          this.tagebucheintraege$.next(
            this.tagebucheintraege$.value.filter((_, index) => index !== this.selectedIndex)
          );

          this.selectedIndex = -1;
        }
      });
  }

  getTagebucheintraege(): TagebucheintragDTO[] {
    return this.tagebucheintraege$.getValue();
  }
}
