// Angular Module
import { environment } from 'src/environments/environment';

import { Configuration, ConfigurationParameters } from './api/build/openapi';

// Locale
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function apiConfig(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.basePath,
  };
  return new Configuration(params);
}
