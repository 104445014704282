import { createReducer, on } from '@ngrx/store';
import { EinsatzfilterDTO } from 'src/app/api/build/openapi';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { EinsatzfilterStateInterface } from './einsatzfilter-state.interface';
import { einsatzfilterActions } from './einsatzfilter.actions';

export const initialState: EinsatzfilterStateInterface = {
  isCreating: false,
  isLoading: false,
  isDeleting: false,
  isPatching: false,
  einsatzfilter: [],
  loadedLageId: '',
};

export const einsatzfilterReducer = createReducer(
  initialState,

  // Reset Store
  on(einsatzfilterActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Einsatzfilter
  on(einsatzfilterActions.getEinsatzfilter, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(einsatzfilterActions.getEinsatzfilterSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    einsatzfilter: action.einsatzfilterDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(einsatzfilterActions.getEinsatzfilterFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Einsatzfilter
  on(einsatzfilterActions.createEinsatzfilter, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(einsatzfilterActions.createEinsatzfilterSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    einsatzfilter: [...state.einsatzfilter, action.createdEinsatzfilterDTO],
    errorResponse: undefined,
  })),
  on(einsatzfilterActions.createEinsatzfilterFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Einsatzfilter
  on(einsatzfilterActions.patchEinsatzfilter, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(einsatzfilterActions.patchEinsatzfilterSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    einsatzfilter: replaceOrKeepCurrent(state.einsatzfilter, action.patchedEinsatzfilterDTO),
    errorResponse: undefined,
  })),
  on(einsatzfilterActions.patchEinsatzfilterFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Einsatzfilter
  on(einsatzfilterActions.deleteEinsatzfilter, (state) => ({ ...state, isDeleting: true, errorResponse: undefined })),
  on(einsatzfilterActions.deleteEinsatzfilterSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    einsatzfilter: state.einsatzfilter.filter(
      (einsatzfilter: EinsatzfilterDTO) => einsatzfilter.id != action.deletedEinsatzfilterId
    ),
    errorResponse: undefined,
  })),
  on(einsatzfilterActions.deleteEinsatzfilterFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  }))
);
