import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FotoDTO } from 'src/app/api/build/openapi';

export interface FotoDialogData {
  fotoDTO: FotoDTO;
}

@Component({
  selector: 'app-foto-dialog',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatDialogModule, MatButtonModule],
  templateUrl: './foto-dialog.component.html',
  styleUrls: ['./foto-dialog.component.scss'],
})
export class FotoDialogComponent {
  fotoDTO?: FotoDTO;

  constructor(@Inject(MAT_DIALOG_DATA) dialogData: FotoDialogData) {
    this.fotoDTO = dialogData.fotoDTO;
  }
}
