import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EinsatzfilterDTO, ErrorResponse } from 'src/app/api/build/openapi';

export const einsatzfilterActions = createActionGroup({
  source: 'Einsatzfilter',
  events: {
    'Reset Store': emptyProps(),

    'Get Einsatzfilter': props<{ lageId: string }>(),
    'Get Einsatzfilter Success': props<{ einsatzfilterDTOs: EinsatzfilterDTO[]; lageId: string }>(),
    'Get Einsatzfilter Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Einsatzfilter': props<{ lageId: string; einsatzfilterDTO: EinsatzfilterDTO }>(),
    'Create Einsatzfilter Success': props<{ createdEinsatzfilterDTO: EinsatzfilterDTO }>(),
    'Create Einsatzfilter Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Einsatzfilter': props<{ lageId: string; einsatzfilterDTO: EinsatzfilterDTO }>(),
    'Patch Einsatzfilter Success': props<{ patchedEinsatzfilterDTO: EinsatzfilterDTO }>(),
    'Patch Einsatzfilter Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Einsatzfilter': props<{ lageId: string; einsatzfilterDTO: EinsatzfilterDTO }>(),
    'Delete Einsatzfilter Success': props<{ deletedEinsatzfilterId: string }>(),
    'Delete Einsatzfilter Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
