import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FuehrungsebeneDTO, Fuehrungsebenentyp } from '../../../api/build/openapi';
import { FuehrungsebeneDisplayService } from '../fuehrungsebene-display.service';
import { FuehrungsebeneService } from '../fuehrungsebene.service';

/**
 * Zeigt an, wie viele und welche direkten Children eine Führungsebene hat und
 * wie viele davon links und rechts neben der Karte dargestellt werden.
 */
@Component({
  selector: 'app-fuehrungsebene-child-counter',
  standalone: true,
  imports: [NgIf],
  templateUrl: './fuehrungsebene-child-counter.component.html',
  styleUrl: './fuehrungsebene-child-counter.component.scss',
})
export class FuehrungsebeneChildCounterComponent implements OnChanges {
  @Input({ required: true })
  fuehrungsebeneDTO!: FuehrungsebeneDTO;

  fuehrungsebeneService = inject(FuehrungsebeneService);
  fuehrungsebeneDisplayService = inject(FuehrungsebeneDisplayService);

  showEinsatzraumCount = false;
  einsatzraumChildren: FuehrungsebeneDTO[] = [];
  einsatzraumChildrenCountDisplayed = 0;

  showEinsatzstelleCount = false;
  einsatzstelleChildren: FuehrungsebeneDTO[] = [];
  einsatzstelleChildrenCountDisplayed = 0;

  showEinsatzabschnittCount = false;
  einsatzabschnittChildren: FuehrungsebeneDTO[] = [];
  einsatzabschnittChildrenCountDisplayed = 0;

  showUnterabschnittCount = false;
  unterabschnittChildren: FuehrungsebeneDTO[] = [];
  unterabschnittChildrenCountDisplayed = 0;

  constructor() {
    this.fuehrungsebeneDisplayService.displayedFuehrungsebenen$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.update());
  }

  ngOnChanges(): void {
    this.update();
  }

  private update() {
    if (!this.fuehrungsebeneDTO?.id) {
      this.showEinsatzraumCount = false;
      this.einsatzraumChildren = [];
      this.einsatzraumChildrenCountDisplayed = 0;

      this.showEinsatzstelleCount = false;
      this.einsatzstelleChildren = [];
      this.einsatzstelleChildrenCountDisplayed = 0;

      this.showEinsatzabschnittCount = false;
      this.einsatzabschnittChildren = [];
      this.einsatzabschnittChildrenCountDisplayed = 0;

      this.showUnterabschnittCount = false;
      this.unterabschnittChildren = [];
      this.unterabschnittChildrenCountDisplayed = 0;
      return;
    }

    const children = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .filter((potentialChild) => potentialChild.parentFuehrungsebeneId === this.fuehrungsebeneDTO?.id);

    const displayedChildren = this.fuehrungsebeneDisplayService.getSelectedFuehrungsebenen(this.fuehrungsebeneDTO.id);

    // Einsatzräume
    this.showEinsatzraumCount = this.fuehrungsebeneDTO?.typ === Fuehrungsebenentyp.Schadengebiet;
    this.einsatzraumChildren = children.filter((child) => child.typ === Fuehrungsebenentyp.Einsatzraum);
    this.einsatzraumChildrenCountDisplayed = displayedChildren
      ? this.einsatzraumChildren.filter((child) => child.id && displayedChildren?.includes(child.id)).length
      : this.einsatzraumChildren.length;

    // Einsatzstellen und Logistik-Einsatzstellen
    this.showEinsatzstelleCount =
      this.fuehrungsebeneDTO?.typ === Fuehrungsebenentyp.Schadengebiet ||
      this.fuehrungsebeneDTO?.typ === Fuehrungsebenentyp.Einsatzraum;
    this.einsatzstelleChildren = children.filter(
      (child) =>
        child.typ === Fuehrungsebenentyp.Einsatzstelle || child.typ === Fuehrungsebenentyp.LogistikEinsatzstelle
    );
    this.einsatzstelleChildrenCountDisplayed = displayedChildren
      ? this.einsatzstelleChildren.filter((child) => child.id && displayedChildren?.includes(child.id)).length
      : this.einsatzstelleChildren.length;

    // Einsatzabschnitte und Logistik-Einsatzabschnitte
    this.showEinsatzabschnittCount =
      this.fuehrungsebeneDTO?.typ === Fuehrungsebenentyp.Einsatzstelle ||
      this.fuehrungsebeneDTO?.typ === Fuehrungsebenentyp.LogistikEinsatzstelle;
    this.einsatzabschnittChildren = children.filter(
      (child) =>
        child.typ === Fuehrungsebenentyp.Einsatzabschnitt ||
        child.typ === Fuehrungsebenentyp.LogistikEinsatzabschnitt ||
        child.typ === Fuehrungsebenentyp.LogistikEinsatzstelleChild
    );
    this.einsatzabschnittChildrenCountDisplayed = displayedChildren
      ? this.einsatzabschnittChildren.filter((child) => child.id && displayedChildren?.includes(child.id)).length
      : this.einsatzabschnittChildren.length;

    // Unterabschnitte
    this.showUnterabschnittCount =
      this.fuehrungsebeneDTO?.typ === Fuehrungsebenentyp.Einsatzabschnitt ||
      this.fuehrungsebeneDTO?.typ === Fuehrungsebenentyp.LogistikEinsatzabschnitt;
    this.unterabschnittChildren = children.filter(
      (child) =>
        child.typ === Fuehrungsebenentyp.Unterabschnitt ||
        child.typ === Fuehrungsebenentyp.LogistikEinsatzabschnittChild
    );
    this.unterabschnittChildrenCountDisplayed = displayedChildren
      ? this.unterabschnittChildren.filter((child) => child.id && displayedChildren?.includes(child.id)).length
      : this.unterabschnittChildren.length;
  }
}
