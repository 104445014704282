import { createSelector } from '@ngrx/store';
import { AppStateInterface, gefahren } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[gefahren];

export const gefahrenSelector = createSelector(selectFeature, (state) => state.gefahren);
export const gefahrenErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingGefahrenSelector = createSelector(selectFeature, (state) => state.isLoading);
export const gefahrenLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const gefahrenOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.gefahren.filter((gefahr) => gefahr.fuehrungsebeneId === fuehrungsebeneId)
  );
