<mat-card>
  <form [formGroup]="formGroup" *ngIf="dtoToEdit">
    <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />
    <app-cleanable-form-field
      label="Beschreibung"
      [formGroup]="formGroup"
      formControlName="beschreibung"
      [useTextarea]="true"
    />
    <input type="file" (change)="setFile($event)" />
    <mat-error *ngIf="showFileSizeError">Maximal erlaubte Dateigröße ist 5MB</mat-error>
    <mat-error *ngIf="showFileTypeError">Nur Bilder dürfen ausgewählt werden</mat-error>
    <mat-error *ngIf="showFileRequiredError">Bilddatei ist verpflichtend</mat-error>
  </form>
  <img [src]="uploadURL" />

  <mat-spinner *ngIf="!dtoToEdit" />
</mat-card>
