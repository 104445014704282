/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LagebesprechungDTO { 
    id?: string;
    version?: number;
    lastUpdatedOn?: string;
    lastUpdatedBy?: string;
    lageId: string;
    status?: LagebesprechungDTO.StatusEnum;
    zeitpunkt?: string;
    erinnerungInMinuten?: number;
    naechsteAnlegenInMinuten?: number;
}
export namespace LagebesprechungDTO {
    export type StatusEnum = 'ANSTEHEND' | 'ERLEDIGT' | 'ABGEBROCHEN';
    export const StatusEnum = {
        Anstehend: 'ANSTEHEND' as StatusEnum,
        Erledigt: 'ERLEDIGT' as StatusEnum,
        Abgebrochen: 'ABGEBROCHEN' as StatusEnum
    };
}


