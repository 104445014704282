import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { anwesenheitenActions } from './anwesenheiten.actions';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { AnwesenheitResourceService } from 'src/app/api/build/openapi';

@Injectable()
export class AnwesenheitenEffects {
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage) {
          return anwesenheitenActions.getAnwesenheiten({ lageId: props.currentLage!.id! });
        } else {
          return anwesenheitenActions.resetStore();
        }
      })
    )
  );

  getAnwesenheiten$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anwesenheitenActions.getAnwesenheiten),
      switchMap((props) =>
        this.anwesenheitenService.getAnwesenheitByLageId(props.lageId).pipe(
          map((anwesenheit) => anwesenheitenActions.getAnwesenheitenSuccess({ anwesenheiten: anwesenheit })),
          catchError((error) => of(anwesenheitenActions.getAnwesenheitenFailure({ errorResponse: error.message })))
        )
      )
    )
  );

  createAnwesenheit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anwesenheitenActions.createAnwesenheit),
      switchMap((action) => {
        return this.anwesenheitenService.createAnwesenheit(action.lageId, action.anwesenheit).pipe(
          map((registeredAnwesenheit) => {
            return anwesenheitenActions.createAnwesenheitSuccess({ anwesenheit: registeredAnwesenheit });
          }),
          catchError((error) => of(anwesenheitenActions.createAnwesenheitFailure({ errorResponse: error.message })))
        );
      })
    )
  );

  patchAnwesenheit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anwesenheitenActions.patchAnwesenheit),
      switchMap((action) =>
        this.anwesenheitenService
          .patchAnwesenheit(action.anwesenheit.id!, action.anwesenheit.lageId!, action.anwesenheit)
          .pipe(
            map((patchedAnwesenheit) =>
              anwesenheitenActions.patchAnwesenheitSuccess({ patchedAnwesenheit: patchedAnwesenheit })
            ),
            catchError((error) => of(anwesenheitenActions.patchAnwesenheitFailure({ errorResponse: error.message })))
          )
      )
    )
  );

  deleteAnwesenheit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(anwesenheitenActions.deleteAnwesenheit),
      switchMap((action) =>
        this.anwesenheitenService.deleteAnwesenheit(action.anwesenheitId, action.lageId).pipe(
          map((deletedAnwesenheitId) =>
            anwesenheitenActions.deleteAnwesenheitSuccess({ deletedAnwesenheitId: deletedAnwesenheitId })
          ),
          catchError((error) => of(anwesenheitenActions.deleteAnwesenheitFailure({ errorResponse: error.message })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private anwesenheitenService: AnwesenheitResourceService) {}
}
