<mat-toolbar>
  <h2>Maßnahme beenden</h2>
</mat-toolbar>

<mat-card class="container">
  <mat-dialog-content>
    <p>Bitte Person erfassen, die die Maßnahme beendet hat</p>
    <form [formGroup]="formGroup">
      <mat-form-field class="textfield-100">
        <mat-label>Anwesenheit</mat-label>
        <mat-select formControlName="erledigtDurchAnwesenheit">
          <mat-option *ngFor="let anwesenheit of anwesenheitenService.anwesenheiten" [value]="anwesenheit">{{
            anwesenheit.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="erledigtDurchDatum" />
        <mat-error>{{ getErrorMessage("datum") }}</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
</mat-card>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onClickAbbrechen()">Abbrechen</button>
  <button mat-raised-button color="accent" (click)="onClickBeenden()" class="mr-05">Aufgabe beenden</button>
</mat-dialog-actions>
