import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, TaktischeFormationDTO } from 'src/app/api/build/openapi';

export const taktischeFormationActions = createActionGroup({
  source: 'TaktischeFormationen',
  events: {
    'Reset Store': emptyProps(),

    'Get Taktische Formationen': props<{ lageId: string }>(),
    'Get Taktische Formationen Success': props<{ taktischeFormationDTOs: TaktischeFormationDTO[]; lageId: string }>(),
    'Get Taktische Formationen Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Taktische Formation': props<{ lageId: string; taktischeFormationDTO: TaktischeFormationDTO }>(),
    'Create Taktische Formation Success': props<{ createdTaktischeFormationDTO: TaktischeFormationDTO }>(),
    'Create Taktische Formation Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Taktische Formation': props<{ lageId: string; taktischeFormationDTO: TaktischeFormationDTO }>(),
    'Patch Taktische Formation Success': props<{ patchedTaktischeFormationDTO: TaktischeFormationDTO }>(),
    'Patch Taktische Formation Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Taktische Formation': props<{ lageId: string; taktischeFormationDTO: TaktischeFormationDTO }>(),
    'Delete Taktische Formation Success': props<{ deletedTaktischeFormationDTO: TaktischeFormationDTO }>(),
    'Delete Taktische Formation Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
