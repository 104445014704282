import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TierschadenSubset } from '../tierschaden-subset.interface';

/**
 * Große Komponente zur Übersicht von Personen verschiedener Zustände.
 * Personenanzahl kann vom Benutzer editiert werden.
 */
@Component({
  selector: 'app-tierschaden-subset',
  templateUrl: './tierschaden-subset.component.html',
  styleUrls: ['./tierschaden-subset.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TierschadenSubsetComponent implements OnChanges {
  @Input({ required: true })
  header!: string;

  @Input()
  tierschadenSubset?: TierschadenSubset;

  private formBuilder = inject(NonNullableFormBuilder);

  protected verletzt = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected tot = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected betroffen = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);

  protected formGroup = this.formBuilder.group({
    verletzt: this.verletzt,
    tot: this.tot,
    betroffen: this.betroffen,
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tierschadenSubset']) {
      this.formGroup.setValue(changes['tierschadenSubset'].currentValue);
    }
  }

  isValid(): boolean {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return false;
    }

    return true;
  }

  getDto(): TierschadenSubset {
    return {
      verletzt: this.verletzt.value || 0,
      tot: this.tot.value || 0,
      betroffen: this.betroffen.value || 0,
    };
  }
}
