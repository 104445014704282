import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, PersonResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { personActions } from './person.actions';

@Injectable()
export class PersonEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Führungsebenen anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return personActions.getPersonen({ lageId: props.currentLage.id });
        } else {
          return personActions.resetStore();
        }
      })
    )
  );

  getPersonen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(personActions.getPersonen),
      mergeMap((props) =>
        this.personenResourceService.getPersonenByLageId(props.lageId).pipe(
          map((personen) => personActions.getPersonenSuccess({ personen, lageId: props.lageId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(personActions.getPersonenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  createPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(personActions.createPerson),
      mergeMap((props) =>
        this.personenResourceService.createPerson(props.lageId, props.person).pipe(
          map((newPerson) => personActions.createPersonSuccess({ newPerson })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(personActions.createPersonFailure({ errorResponse }));
          })
        )
      )
    )
  );

  patchPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(personActions.patchPerson),

      mergeMap((props) =>
        this.personenResourceService.patchPerson(props.lageId, props.personId, props.personDTO).pipe(
          map((patchedPerson) => personActions.patchPersonSuccess({ patchedPerson })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(personActions.patchPersonFailure({ errorResponse }));
          })
        )
      )
    )
  );

  deletePerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(personActions.deletePerson),
      mergeMap((props) => {
        if (!props.personDTO.id) {
          return throwError(() => 'Person kann nicht gelöscht werden. Sie besitzt keine Id.');
        }
        return this.personenResourceService.deletePerson(props.lageId, props.personDTO.id).pipe(
          map(() => personActions.deletePersonSuccess({ deletedPersonDTO: props.personDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(personActions.createPersonFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private personenResourceService: PersonResourceService) {}
}
