import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { combineLatest, startWith } from 'rxjs';
import { FuehrungsebeneDTO, Fuehrungsebenentyp } from 'src/app/api/build/openapi';
import { FuehrungsebeneHierarchieService } from '../../fuehrungsebene-hierarchie/fuehrungsebene-hierarchie.service';
import {
  administrativOrganisatorischSelector,
  childFuehrungsebenenSelector,
  currentBereitstellungsraeumeSelector,
  currentFuehrungsebeneSelector,
  currentGrundschutzeSelector,
  leitstellenSelector,
  operativTaktischSelector,
  parentFuehrungsebeneSelector,
  politischGesamtverantwortlichSelector,
} from '../../fuehrungsebene/+state/fuehrungsebene.selectors';
import { OrganigrammFuehrungsebeneCardComponent } from '../organigramm-fuehrungsebene-card/organigramm-fuehrungsebene-card.component';

/**
 * Organigramm der Lage aus Sicht der aktuellen Führungsebene.
 * Unterteilt in 4 Ebenen:
 * - Einsatzleitung, Krisenstab, Leitstelle und Spezial-Führungsebenen der aktuellen Führungsebene (Grundschutz, Bereitstellungsräume, Logistik etc.)
 * - Parent-Führungsebene der aktuellen Führungsebene
 * - Aktuelle Führungsebene
 * - Child-Führungsebenen der aktuellen Führungsebene
 */
@Component({
  selector: 'app-organigramm',
  templateUrl: './organigramm.component.html',
  styleUrls: ['./organigramm.component.scss'],
  standalone: true,
  imports: [NgStyle, OrganigrammFuehrungsebeneCardComponent, NgIf, NgFor, AsyncPipe],
})
export class OrganigrammComponent implements OnInit {
  Fuehrungsebenentyp = Fuehrungsebenentyp;

  private destroyRef = inject(DestroyRef);
  private store = inject(Store);
  protected hierarchyService = inject(FuehrungsebeneHierarchieService);

  leitstellen: FuehrungsebeneDTO[] = [];
  politischGesamtverantwortlich: FuehrungsebeneDTO[] = [];
  administrativOrganisatorisch: FuehrungsebeneDTO[] = [];
  operativTaktisch: FuehrungsebeneDTO[] = [];

  // Parent-Führungsebene der currentFuehrungsebene
  parentFuehrungsebene?: FuehrungsebeneDTO;
  parentHasParent = false;

  // Aktuell ausgewählte Führungsebene
  currentFuehrungsebene?: FuehrungsebeneDTO;
  // Sammlung von BRs, Grundschutz etc. zur einfacheren Verwaltung
  spezialFuehrungsebenen: FuehrungsebeneDTO[] = [];

  // Child-Führungsebenen des currentFuehrungsebenes
  childFuehrungsebenen: FuehrungsebeneDTO[] = [];

  ngOnInit(): void {
    this.store
      .select(leitstellenSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((leitstellen) => {
        this.leitstellen = leitstellen;
      });

    this.store
      .select(politischGesamtverantwortlichSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((politischGesamtverantwortlich) => {
        this.politischGesamtverantwortlich = politischGesamtverantwortlich;
      });

    this.store
      .select(administrativOrganisatorischSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((administrativOrganisatorisch) => {
        this.administrativOrganisatorisch = administrativOrganisatorisch;
      });

    this.store
      .select(operativTaktischSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((operativTaktisch) => {
        this.operativTaktisch = operativTaktisch;
      });

    this.store
      .select(parentFuehrungsebeneSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((parentFuehrungsebene) => {
        this.parentFuehrungsebene = parentFuehrungsebene || undefined;
        this.parentHasParent = parentFuehrungsebene?.id
          ? this.hierarchyService.fuehrungsebeneHasParent(parentFuehrungsebene.id)
          : false;
      });

    this.store
      .select(currentFuehrungsebeneSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentFuehrungsebene) => {
        this.currentFuehrungsebene = currentFuehrungsebene || undefined;
      });

    this.store
      .select(childFuehrungsebenenSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((childFuehrungsebenen) => {
        this.childFuehrungsebenen = childFuehrungsebenen;
      });

    combineLatest([
      this.store.select(currentBereitstellungsraeumeSelector),
      this.store.select(currentGrundschutzeSelector),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef), startWith([[], []]))
      .subscribe(([bereitstellungsraeume, grundschutz]) => {
        this.spezialFuehrungsebenen = [...grundschutz, ...bereitstellungsraeume];
      });
  }
}
