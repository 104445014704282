<!-- 
  Leitung
 -->
<div class="leitung">
  <!-- PolitischGesamtverantwortlicher -->
  <app-organigramm-fuehrungsebene-card
    [fuehrungsebeneDTO]="politischGesamtverantwortlich.length ? politischGesamtverantwortlich[0] : undefined"
    [allowAddOfType]="Fuehrungsebenentyp.PolitischGesamtverantwortlicher"
  />

  <svg width="1" height="20">
    <line x1="0" y1="0" x2="0" y2="20" stroke="black" stroke-width="2" />
  </svg>

  <div class="admin-operativ">
    <!-- AdministrativOrganisatorisch -->
    <div class="fuehrungsebene-wrapper">
      <svg width="100%" height="1">
        <line x1="50%" y1="0" x2="100%" y2="0" stroke="black" stroke-width="2" />
      </svg>
      <svg width="1" height="20">
        <line x1="0" y1="0" x2="0" y2="20" stroke="black" stroke-width="2" />
      </svg>
      <app-organigramm-fuehrungsebene-card
        [fuehrungsebeneDTO]="administrativOrganisatorisch.length ? administrativOrganisatorisch[0] : undefined"
        [allowAddOfType]="Fuehrungsebenentyp.AdministrativOrganisatorisch"
      />
    </div>

    <!-- OperativTaktisch -->
    <div class="fuehrungsebene-wrapper">
      <svg width="100%" height="1">
        <line x1="0" y1="0" x2="50%" y2="0" stroke="black" stroke-width="2" />
      </svg>
      <svg width="1" height="20">
        <line x1="0" y1="0" x2="0" y2="20" stroke="black" stroke-width="2" />
      </svg>
      <app-organigramm-fuehrungsebene-card
        [fuehrungsebeneDTO]="operativTaktisch.length ? operativTaktisch[0] : undefined"
        [allowAddOfType]="Fuehrungsebenentyp.OperativTaktisch"
      />

      <!-- Verbindungslinie zum Füllen der Lücke nach unten, wenn AdministrativOrganisatorisch existiert aber OperativTaktisch nicht -->
      <svg width="1" height="100" *ngIf="administrativOrganisatorisch.length && !operativTaktisch.length">
        <line x1="0" y1="0" x2="0" y2="100" stroke="black" stroke-width="2" />
      </svg>
    </div>
  </div>
</div>

<!-- 
  Leitstelle
 -->
<div class="leitstelle">
  <div class="verbindung">
    <svg width="1" height="100">
      <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2" />
    </svg>
    <!-- Vertikale, gestrichelte/durchgezogene Linie zwischen parent und Horizontal-Linie zur Leitstelle -->
    <svg width="1" height="100" *ngIf="parentHasParent">
      <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2" stroke-dasharray="3,3" />
    </svg>
    <svg width="1" height="100" *ngIf="!parentHasParent">
      <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2" />
    </svg>
  </div>

  <svg width="100" height="1">
    <line x1="0" y1="0" x2="100%" y2="0" stroke="black" stroke-width="2" />
  </svg>
  <app-organigramm-fuehrungsebene-card
    [fuehrungsebeneDTO]="leitstellen.length ? leitstellen[0] : undefined"
    [allowAddOfType]="Fuehrungsebenentyp.Leitstelle"
  />
</div>

<!-- 
  Parent-Führungsebene
 -->
<div class="parent-fuehrungsebene" *ngIf="parentFuehrungsebene">
  <app-organigramm-fuehrungsebene-card [fuehrungsebeneDTO]="parentFuehrungsebene" />
  <!-- Vertikale Linie zu Current-Führungsebene -->
  <svg width="1" height="50">
    <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2" />
  </svg>
</div>

<div class="current-fuehrungsebene-row">
  <!-- 
      Platzhalter links vom Current-Führungsebene
     -->
  <div class="platzhalter"></div>

  <!-- 
      Current-Führungsebene
    -->
  <div class="fuehrungsebene-wrapper">
    <app-organigramm-fuehrungsebene-card *ngIf="currentFuehrungsebene" [fuehrungsebeneDTO]="currentFuehrungsebene" />
    <svg width="1" height="70" *ngIf="childFuehrungsebenen.length">
      <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2" />
    </svg>
  </div>

  <!-- 
      Spezial-Führungsebenen wie Grundschutz, Bereitstellungsraum, Logistik, ... rechts von der Current-Führungsebene
    -->
  <div class="special-fuehrungsebenen">
    <div class="fuehrungsebene-wrapper" *ngFor="let fuehrungsebeneDTO of spezialFuehrungsebenen; let i = index">
      <svg width="100%" height="1" *ngIf="spezialFuehrungsebenen.length">
        <line
          x1="0"
          y1="0"
          [attr.x2]="i < spezialFuehrungsebenen.length - 1 ? '100%' : '50%'"
          y2="0"
          stroke="black"
          stroke-width="2"
        />
      </svg>
      <svg width="1" height="20">
        <line x1="0" y1="0" x2="0" y2="20" stroke="black" stroke-width="2" />
      </svg>
      <app-organigramm-fuehrungsebene-card [fuehrungsebeneDTO]="fuehrungsebeneDTO" />
    </div>
  </div>
</div>

<!-- 
  Child-Führungsebenen
 -->
<div class="child-fuehrungsebenen" *ngIf="childFuehrungsebenen.length">
  <!-- Vertikale Linie zu Current-Führungsebene -->
  <svg width="1" height="20" *ngIf="childFuehrungsebenen.length">
    <line x1="0" y1="0" x2="0" y2="20" stroke="black" stroke-width="2" />
  </svg>

  <!-- Zeile mit allen Child-Führungsebenen -->
  <div class="child-fuehrungsebene-row">
    <div class="fuehrungsebene-wrapper" *ngFor="let childFuehrungsebene of childFuehrungsebenen; let i = index">
      <!-- Horizontale Linie oberhalb der Child-Führungsebenen, um diese zu verbinden -->
      <svg width="100%" height="1" *ngIf="childFuehrungsebenen.length > 1">
        <line
          [attr.x1]="i > 0 ? '0' : '50%'"
          y1="0"
          [attr.x2]="i < childFuehrungsebenen.length - 1 ? '100%' : '50%'"
          y2="0"
          stroke="black"
          stroke-width="2"
        />
      </svg>

      <!-- Vertikale Linie zwischen Child-Führungsebene und der horizontalen Verbindungslinie aller Child-Führungsebenen -->
      <svg width="1" height="20">
        <line x1="0" y1="0" x2="0" y2="20" stroke="black" stroke-width="2" />
      </svg>
      <app-organigramm-fuehrungsebene-card [fuehrungsebeneDTO]="childFuehrungsebene" />
      <svg width="1" height="70" *ngIf="hierarchyService.fuehrungsebeneHasChild(childFuehrungsebene.id!)">
        <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2" stroke-dasharray="3,3" />
      </svg>
    </div>
  </div>
</div>
