import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Kommunikation } from 'src/app/api/build/openapi';
import { KontaktService } from '../kontakt.service';

@Component({
  selector: 'app-kontakt-icons',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './kontakt-icons.component.html',
  styleUrls: ['./kontakt-icons.component.scss'],
})
export class KontaktIconsComponent {
  @Input()
  kontaktmoeglichkeiten: Kommunikation[] = [];

  private kontaktService = inject(KontaktService);

  protected summarizeKommunikation(kommunikation: Kommunikation): string {
    return this.kontaktService.summarizeKommunikation(kommunikation);
  }

  protected getKommunikationIcon(kommunikation: Kommunikation): string {
    if (!kommunikation.kontaktTyp) {
      return '';
    }
    return this.kontaktService.KontaktTypMapping.get(kommunikation.kontaktTyp)?.icon || '';
  }
}
