<div class="content">
  <button mat-icon-button matTooltip="Navigationsbaum" (click)="openNavigationsbaum()">
    <mat-icon fontSet="material-icons-outlined">account_tree</mat-icon>
  </button>

  <span *ngFor="let fuehrungsebene of fuehrungsebeneBreadcrumbs$ | async; let last = last" class="breadcrumb-item">
    <app-fuehrungsebene-icon-and-text
      class="breadcrumb-text"
      (click)="navigateToFuehrungsebene(fuehrungsebene)"
      [fuehrungsebeneDTO]="fuehrungsebene"
      [textSize]="last ? 'large' : 'medium'"
    />
    <span class="separator" *ngIf="!last">/</span>
  </span>
</div>
