<h3>Koordinate oder Bereich auswählen:</h3>
<div class="map" [id]="mapId"></div>
<mat-card class="tools">
  <mat-button-toggle-group vertical="true">
    <mat-button-toggle
      *ngFor="let tool of tools"
      [checked]="tool === selectedTool"
      (change)="selectTool(tool.type)"
      matTooltip="{{ tool.name }}"
      matTooltipPosition="right"
      matTooltipClass="tooltip"
    >
      <mat-icon>{{ tool.icon }}</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</mat-card>

<mat-card *ngIf="drawingActive" class="info-box">Zeichnen mit Rechtsklick abschließen</mat-card>
