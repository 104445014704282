import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FahrzeugDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { AuftragListComponent } from 'src/app/lagedarstellung/auftrag/auftrag-list/auftrag-list.component';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import {
  Einheit,
  EinheitId,
  Fachaufgabe,
  Grundzeichen,
  GrundzeichenId,
  Organisation,
  TaktischesZeichen,
  Verwaltungsstufe,
  einheiten,
  erzeugeTaktischesZeichen,
  fachaufgaben,
  grundzeichen,
  organisationen,
  verwaltungsstufen,
} from 'taktische-zeichen-core';
import { CleanableFormFieldComponent } from '../../../../shared/cleanable-form-field/cleanable-form-field.component';
import { KontaktListComponent as KontaktListComponent_1 } from '../../../kontakt/kontakt-list/kontakt-list.component';
import { DataUrlChangeEvent } from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-fahrzeug-form',
  templateUrl: './fahrzeug-form.component.html',
  styleUrls: ['./fahrzeug-form.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    CleanableFormFieldComponent,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    KontaktListComponent_1,
    AuftragListComponent,
  ],
})
export class FahrzeugFormComponent implements TaktischesZeichenForm {
  @ViewChild(KontaktListComponent)
  kontaktListe!: KontaktListComponent;

  @ViewChild(AuftragListComponent)
  auftragListe!: AuftragListComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  dataUrlChanged = new EventEmitter<DataUrlChangeEvent>();

  // Hover-Merker
  organisationMouseover = false;
  fachaufgabeMouseover = false;
  groessenordnungMouseover = false;
  mannschaftsstaerkeMousever = false;
  verwaltungsstufeMouseover = false;
  einsatzmittelartMouseover = false;

  // Dropdown-Werte
  organisationenValues: Organisation[] = organisationen.sort(compareLabels);
  fauchaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  groessenordnungValues: Einheit[] = einheiten.filter((e) => ['trupp', 'staffel', 'gruppe'].some((v) => v === e.id));
  verwaltungsstufeValues: Verwaltungsstufe[] = verwaltungsstufen.sort(compareLabels);

  fahrzeugGrundzeichenIds: GrundzeichenId[] = [
    'fahrzeug',
    'kraftfahrzeug-landgebunden',
    'kraftfahrzeug-gelaendegaengig',
    'wechsellader',
    'abrollbehaelter',
    'anhaenger',
    'schienenfahrzeug',
    'kettenfahrzeug',
    'fahrrad',
    'kraftrad',
    'wasserfahrzeug',
    'flugzeug',
    'hubschrauber',
  ];
  einsatzmittelartValues: Grundzeichen[] = grundzeichen.filter((grundzeichen) =>
    this.fahrzeugGrundzeichenIds.includes(grundzeichen.id)
  );

  private formBuilder = inject(NonNullableFormBuilder);

  // Form Controls
  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcFachaufgabe = this.formBuilder.control<Fachaufgabe | undefined>(undefined);
  fcOrganisation = this.formBuilder.control<Organisation | undefined>(undefined);
  fcGroessenordnung = this.formBuilder.control<Einheit | undefined>(undefined);
  fcVerwaltungsstufe = this.formBuilder.control<Verwaltungsstufe | undefined>(undefined);
  fcEinsatzmittelart = this.formBuilder.control<Grundzeichen | undefined>(undefined);
  fcFunkrufname = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcMannschaftsstaerke1 = this.formBuilder.control<number>(0);
  fcMannschaftsstaerke2 = this.formBuilder.control<number>(0);
  fcMannschaftsstaerke3 = this.formBuilder.control<number>(0);
  fcInnereBezeichnung = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);

  // Form Group
  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    organisation: this.fcOrganisation,
    fachaufgabe: this.fcFachaufgabe,
    groessenordnung: this.fcGroessenordnung,
    mannschaftsstaerke1: this.fcMannschaftsstaerke1,
    mannschaftsstaerke2: this.fcMannschaftsstaerke2,
    mannschaftsstaerke3: this.fcMannschaftsstaerke3,
    verwaltungsstufe: this.fcVerwaltungsstufe,
    innereBezeichnung: this.fcInnereBezeichnung,
    einsatzmittelart: this.fcEinsatzmittelart,
    ortsangabe: this.fcOrtsangabe,
  });

  readonly mannschaftsstarkeMap = new Map<EinheitId, number[]>([
    ['trupp', [0, 0, 3]],
    ['staffel', [0, 1, 5]],
    ['gruppe', [0, 1, 8]],
  ]);

  readonly EMPTY_ZEICHEN: TaktischesZeichen = { grundzeichen: 'fahrzeug' };
  editorZeichen: TaktischesZeichen = { ...this.EMPTY_ZEICHEN };
  currentZeichenDataUrl: string = erzeugeTaktischesZeichen(this.editorZeichen).dataUrl;
  customZeichen = false;
  protected fahrzeugToEdit: FahrzeugDTO | undefined;

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v.trim());
    });

    this.fcFachaufgabe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.fachaufgabe = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.organisation = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcGroessenordnung.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (v && v.id) {
        if (!this.customZeichen) {
          this.editorZeichen.einheit = v.id;
        }
        this.setMannschaftsstaerke(this.mannschaftsstarkeMap.get(v.id));
      }

      if (!this.customZeichen) {
        this.generateTaktischesZeichen();
      }
    });

    this.fcVerwaltungsstufe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.verwaltungsstufe = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcEinsatzmittelart.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      // 'fahrzeug' = default
      if (!this.customZeichen) {
        this.editorZeichen.grundzeichen = v ? v.id : 'fahrzeug';
        this.generateTaktischesZeichen();
      }
    });
  }

  setCustomTz(dataUrl: string) {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
      this.customZeichen = true;
    } else {
      this.customZeichen = false;
      this.generateTaktischesZeichen();
    }
  }

  /**
   * Fahrzeug Model von außen setzen. Ist entweder ein neues oder ein existierendes Fahrzeug
   */
  setDTO(fahrzeugDTO: TaktischesZeichenDTO) {
    this.fahrzeugToEdit = fahrzeugDTO as FahrzeugDTO;
    if (this.fahrzeugToEdit) {
      this.setFromDTO(this.fahrzeugToEdit);
      if (this.fahrzeugToEdit.customZeichen && this.fahrzeugToEdit.dataUrl) {
        this.generateTaktischesZeichen(this.fahrzeugToEdit.dataUrl);
        return;
      }
    }
    this.generateTaktischesZeichen();
  }

  /**
   * Aktualisiertes FahrzeugDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): FahrzeugDTO | null {
    if (this.formGroup.valid) {
      return this.toDTO();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * DataURL an Parent senden und dort aktualisieren.
   */
  generateTaktischesZeichen(dataUrl = '') {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
    } else {
      const zeichen = erzeugeTaktischesZeichen(this.editorZeichen);
      this.currentZeichenDataUrl = zeichen.dataUrl;
    }

    this.dataUrlChanged.emit({ dataUrl: this.currentZeichenDataUrl, customZeichen: this.customZeichen });
  }

  /**
   * Felder für Mannschaftsstärke zurücksetzen
   */
  resetMannschaftsstaerke() {
    this.fcMannschaftsstaerke1.setValue(0);
    this.fcMannschaftsstaerke2.setValue(0);
    this.fcMannschaftsstaerke3.setValue(0);
  }

  private setMannschaftsstaerke(mannschaftsstaerke: number[] | undefined) {
    if (!mannschaftsstaerke) return;

    this.fcMannschaftsstaerke1.setValue(mannschaftsstaerke[0]);
    this.fcMannschaftsstaerke2.setValue(mannschaftsstaerke[1]);
    this.fcMannschaftsstaerke3.setValue(mannschaftsstaerke[2]);
  }

  /**
   * FormGroup Werte in FahrzeugDTO schreiben und zurückliefern
   */
  toDTO(): FahrzeugDTO {
    const dto: FahrzeugDTO = {
      ...this.fahrzeugToEdit,
      anzeigename: this.fcAnzeigename.value.trim(),
      organisation: this.fcOrganisation.value?.id,
      fachaufgabe: this.fcFachaufgabe.value?.id,
      groessenordnung: this.fcGroessenordnung.value?.id,
      mannschaftsstaerke1: this.fcMannschaftsstaerke1.value,
      mannschaftsstaerke2: this.fcMannschaftsstaerke2.value,
      mannschaftsstaerke3: this.fcMannschaftsstaerke3.value,
      verwaltungsstufe: this.fcVerwaltungsstufe.value?.id,
      innereBezeichnung: this.fcInnereBezeichnung.value?.trim(),
      einsatzmittelart: this.fcEinsatzmittelart.value?.id,
      dataUrl: this.currentZeichenDataUrl,
      ortsangabe: this.fcOrtsangabe.value?.trim(),
      kommunikationOptionen: this.kontaktListe.getKommunikationOptionen(),
      auftraege: this.auftragListe.getAuftraege(),
      customZeichen: this.customZeichen,
      typ: TaktischesZeichenTyp.Fahrzeug,
    };
    return dto;
  }

  /**
   * FormGroup mit Werten aus FahrzeugDTO füllen
   */
  setFromDTO(fahrzeugDTO: FahrzeugDTO) {
    this.fcAnzeigename.setValue(fahrzeugDTO.anzeigename);
    this.fcInnereBezeichnung.setValue(fahrzeugDTO.innereBezeichnung);
    this.fcOrtsangabe.setValue(fahrzeugDTO.ortsangabe);
    this.fcGroessenordnung.setValue(einheiten.find((v) => v.id === fahrzeugDTO.groessenordnung));
    this.fcMannschaftsstaerke1.setValue(fahrzeugDTO.mannschaftsstaerke1 || 0);
    this.fcMannschaftsstaerke2.setValue(fahrzeugDTO.mannschaftsstaerke2 || 0);
    this.fcMannschaftsstaerke3.setValue(fahrzeugDTO.mannschaftsstaerke3 || 0);
    this.fcOrganisation.setValue(organisationen.find((v) => v.id === fahrzeugDTO.organisation));
    this.fcFachaufgabe.setValue(fachaufgaben.find((v) => v.id === fahrzeugDTO.fachaufgabe));
    this.fcVerwaltungsstufe.setValue(verwaltungsstufen.find((v) => v.id === fahrzeugDTO.verwaltungsstufe));
    this.fcEinsatzmittelart.setValue(grundzeichen.find((v) => v.id === fahrzeugDTO.einsatzmittelart));
    this.auftragListe.setAuftraege(fahrzeugDTO.auftraege || []);
    this.customZeichen = fahrzeugDTO.customZeichen || false;
  }
}
