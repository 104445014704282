<div class="dialog">
  <mat-toolbar class="flex-row">
    <h2>{{ heading }}</h2>
  </mat-toolbar>

  <mat-dialog-content class="row">
    <div class="column">
      <p *ngIf="fuehrungsebeneMessage">{{ fuehrungsebeneMessage }}</p>
      <p *ngIf="statusMessage">{{ statusMessage }}</p>
      <form [formGroup]="formGroup" class="flex-col flex-grow">
        <mat-form-field>
          <mat-label>Datum/Uhrzeit des Wechsels</mat-label>
          <input matInput type="datetime-local" [formControl]="fcDatum" dateTimeLocalAsDate />
          <mat-error>Bitte gültiges Datum eingeben</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Adresse (Straße, Stadt, ...)</mat-label>
          <textarea matInput cdkTextareaAutosize [formControl]="fcOrtsangabe"></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Koordinate</mat-label>
          <input matInput [value]="geometry | geometryAsString" readonly />
          <button *ngIf="geometry" matSuffix mat-icon-button (click)="copyGeometryToClipboard($event)">
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </div>

    <app-tz-geometry-map class="map" (geometryChanged)="setKoordinate($event)" />
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="accent" (click)="prepareResult()" class="mr-05">Speichern</button>
  </mat-dialog-actions>
</div>
