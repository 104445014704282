<mat-card>
  <mat-toolbar>
    <h3>Einheiten der Taktischen Formation</h3>
    <span>
      <button mat-icon-button (click)="selectEinheiten()" matTooltip="Einheiten auswählen">
        <mat-icon>checklist</mat-icon>
      </button>
    </span>
  </mat-toolbar>

  <mat-card-content *ngIf="selectedTaktischeFormationNested$ | async as taktischeFormationNested">
    <!-- Taktische Formationen -->
    <app-taktische-zeichen-item
      *ngFor="let taktischeFormation of taktischeFormationNested.taktischeFormationen"
      [item]="taktischeFormation"
      (tzClicked)="editTaktischesZeichen(taktischeFormation)"
    />

    <!-- Fahrzeuge -->
    <app-taktische-zeichen-item
      *ngFor="let fahrzeug of taktischeFormationNested.fahrzeuge"
      [item]="fahrzeug"
      (tzClicked)="editTaktischesZeichen(fahrzeug)"
    />

    <!-- Personen -->
    <app-taktische-zeichen-item
      *ngFor="let person of taktischeFormationNested.personen"
      [item]="person"
      (tzClicked)="editTaktischesZeichen(person)"
    />
  </mat-card-content>
</mat-card>
