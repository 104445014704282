/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MapConfigTyp = 'WMS' | 'WMTS' | 'XYZ';

export const MapConfigTyp = {
    Wms: 'WMS' as MapConfigTyp,
    Wmts: 'WMTS' as MapConfigTyp,
    Xyz: 'XYZ' as MapConfigTyp
};

