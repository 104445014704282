import { Injectable } from '@angular/core';
import { LatLng, LatLngBounds, LeafletMouseEvent, Map, Rectangle } from 'leaflet';
import { LayerService } from '../layer.service';
import { ToolSettings } from '../tool.interface';
import { FeatureGenerator } from './tool-handler';

/**
 * Generator für Rechtecke auf einer Leaflet-Karte über den ToolHandler
 */
@Injectable({
  providedIn: 'root',
})
export class RectangleGenerator implements FeatureGenerator {
  private toolStartCoords: L.LatLng = new LatLng(0, 0);
  private toolRect: Rectangle | null = null;
  private map: Map | null = null;

  constructor(private layerService: LayerService) {}

  init(map: Map): void {
    this.map = map;
  }

  createFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): void {
    this.toolStartCoords = event.latlng;
    this.toolRect = new Rectangle(new LatLngBounds(event.latlng, event.latlng), toolSettings);
    this.map?.addLayer(this.toolRect);
  }

  updateFeature(event: LeafletMouseEvent): void {
    this.toolRect?.setBounds(new LatLngBounds(this.toolStartCoords, event.latlng));
  }

  extendFeature(event: LeafletMouseEvent): void {
    // Do nothing
  }

  finalizeFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.toolRect) {
      return null;
    }

    const bounds = this.toolRect.getBounds();
    const coords: [number, number][] = [
      [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
      [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
      [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
      [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
      [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
    ];
    this.map?.removeLayer(this.toolRect);
    this.toolRect = null;
    return this.layerService.addRecangleToCurrentLayer([coords], toolSettings);
  }
}
