import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EinsatzDTO } from '../../api/build/openapi';
import { EinsatzService } from '../einsatz.service';

@Component({
  selector: 'app-einsatz-item-grouped',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatBadgeModule, MatTooltipModule],
  templateUrl: './einsatz-item-grouped.component.html',
  styleUrls: ['./einsatz-item-grouped.component.scss'],
})
export class EinsatzItemGroupedComponent {
  @Input({ required: true })
  einsatzDtos: EinsatzDTO[] = [];

  protected hovering = false;

  protected einsatzService = inject(EinsatzService);
}
