import { JsMsg, MsgHdrs } from 'nats.ws';
import {
  HEADER_METHOD,
  HEADER_SCHEMA,
  HEADER_SOURCE_ID,
  METHOD_CREATED,
  METHOD_DELETED,
  METHOD_UPDATED,
} from './headers';

/*
  Kurze Hilfsmehtoden
  */
export const isCreated = (headers: MsgHdrs) => headers.get(HEADER_METHOD) === METHOD_CREATED;
export const isUpdated = (headers: MsgHdrs) => headers.get(HEADER_METHOD) === METHOD_UPDATED;
export const isDeleted = (headers: MsgHdrs) => headers.get(HEADER_METHOD) === METHOD_DELETED;
export const getSchema = (headers: MsgHdrs) => headers.get(HEADER_SCHEMA);
export const getMethod = (headers: MsgHdrs) => headers.get(HEADER_METHOD);
export const getIseSourceId = (headers: MsgHdrs) => headers.get(HEADER_SOURCE_ID);

/*
   Hilfsmehtoden für Logs
  */
export const getHeaderMap = (headers?: MsgHdrs): Map<string, string> | undefined => {
  if (!headers) return undefined;

  const h = new Map();
  for (const key of headers.keys()) {
    h.set(key, headers.get(key));
  }

  return h;
};

export const logUnkownEvent = (message: JsMsg, info: string | undefined = undefined) =>
  console.warn(
    'Unkown Message',
    info ? ' - info: ' + info : '',
    ' - headers',
    getHeaderMap(message.headers),
    'json',
    message.json()
  );
