import { Injectable } from '@angular/core';
import { erzeugeTaktischesZeichen } from 'taktische-zeichen-core';
import { EinsatzDTO, EinsatzfilterDTO } from '../api/build/openapi';

@Injectable({
  providedIn: 'root',
})
export class EinsatzService {
  readonly EINSATZ_ICON = erzeugeTaktischesZeichen({
    grundzeichen: 'stelle',
    organisation: 'fuehrung',
    text: 'ESt',
  }).dataUrl;

  public getDistinctEinsatzDtos(einsatzfilterDtos?: EinsatzfilterDTO[]): EinsatzDTO[] {
    if (!einsatzfilterDtos) {
      return [];
    }

    const einsatzMapping: Map<string, EinsatzDTO> = new Map();
    einsatzfilterDtos.forEach((filter) => {
      filter.einsaetze?.forEach((einsatz) => {
        if (einsatz.id && !einsatzMapping.has(einsatz.id)) {
          einsatzMapping.set(einsatz.id, einsatz);
        }
      });
    });

    return Array.from(einsatzMapping.values());
  }
}
