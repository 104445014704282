<div class="form-container">
  <form class="actual-form" [formGroup]="formGroup">
    <!-- Linke Spalte mit Informationen zur Erzeugung des Zeichens -->
    <mat-card>
      <mat-card-subtitle>Zeichen Informationen</mat-card-subtitle>

      <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />

      <!-- Auswahl Organisation: Feuerwehr, Polizei, ... -->
      <mat-form-field
        class="less-margin"
        (mouseover)="organisationMouseover = true"
        (mouseleave)="organisationMouseover = false"
      >
        <mat-label>Organisation</mat-label>
        <mat-select formControlName="organisation">
          <mat-option *ngFor="let organisation of organisationenValues" [value]="organisation">
            {{ organisation.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="fcOrganisation.value && organisationMouseover"
          matSuffix
          mat-icon-button
          (click)="fcOrganisation.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Auswahl Fachaufgabe: Brandbekämpfung, Höhenrettung, ... -->
      <mat-form-field
        class="less-margin"
        #fachaufgabeFormField
        (mouseover)="fachaufgabeMouseover = true"
        (mouseleave)="fachaufgabeMouseover = false"
      >
        <mat-label>Fachaufgabe</mat-label>
        <mat-select formControlName="fachaufgabe">
          <mat-option *ngFor="let fachaufgabe of fauchaufgabeValues" [value]="fachaufgabe">
            {{ fachaufgabe.label }}</mat-option
          >
        </mat-select>
        <button
          *ngIf="fcFachaufgabe.value && fachaufgabeMouseover"
          matSuffix
          mat-icon-button
          (click)="fcFachaufgabe.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Auswahl Größenordnung: Gruppe, Trupp, ... -->
      <mat-form-field
        class="less-margin"
        (mouseover)="groessenordnungMouseover = true"
        (mouseleave)="groessenordnungMouseover = false"
      >
        <mat-label>Größenordnung</mat-label>
        <mat-select formControlName="groessenordnung">
          <mat-option *ngFor="let groesse of groessenordnungValues" [value]="groesse"> {{ groesse.label }}</mat-option>
        </mat-select>
        <button
          *ngIf="fcGroessenordnung.value && groessenordnungMouseover"
          matSuffix
          mat-icon-button
          (click)="fcGroessenordnung.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Eingabe Mannschaftsstärke in drei Kategorien mit automatischer Summierung -->
      <mat-form-field class="less-margin">
        <mat-label>Mannschaftsstärke</mat-label>
        <div class="flex-row">
          <input matInput type="number" min="0" [formControl]="fcMannschaftsstaerke1" [defaultValue]="0" />
          <span class="mr-05 op-70">/</span>
          <input matInput type="number" min="0" [formControl]="fcMannschaftsstaerke2" [defaultValue]="0" />
          <span class="mr-05 op-70">/</span>
          <input matInput type="number" min="0" [formControl]="fcMannschaftsstaerke3" [defaultValue]="0" />
          <span class="mr-05 op-70">=</span>
          <span> {{ fcMannschaftsstaerke1.value! + fcMannschaftsstaerke2.value! + fcMannschaftsstaerke3.value! }}</span>
        </div>
      </mat-form-field>

      <!-- Auswahl Verwaltungsstufe: Gemeinde, Bezirk, ... -->
      <mat-form-field
        class="less-margin"
        (mouseover)="verwaltungsstufeMouseover = true"
        (mouseleave)="verwaltungsstufeMouseover = false"
      >
        <mat-label>Verwaltungsstufe</mat-label>
        <mat-select formControlName="verwaltungsstufe">
          <mat-option *ngFor="let verwaltungsstufe of verwaltungsstufeValues" [value]="verwaltungsstufe">
            {{ verwaltungsstufe.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="fcVerwaltungsstufe.value && verwaltungsstufeMouseover"
          matSuffix
          mat-icon-button
          (click)="fcVerwaltungsstufe.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Auswahl Funktion: Führungskraft, Fachberater -->
      <mat-form-field
        class="less-margin"
        (mouseover)="funktionMouseover = true"
        (mouseleave)="funktionMouseover = false"
      >
        <mat-label>Funktion</mat-label>
        <mat-select formControlName="funktion">
          <mat-option *ngFor="let funktion of funktionValues" [value]="funktion">
            {{ funktion.label }}
          </mat-option>
        </mat-select>
        <button *ngIf="fcFunktion.value && funktionMouseover" matSuffix mat-icon-button (click)="fcFunktion.reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Funktion Kurzztext -->
      <mat-form-field>
        <mat-label>Funktion Kurztext (max. 6 Zeichen)</mat-label>
        <input matInput [formControl]="fcFunktionKurztext" [maxLength]="6" />
        <mat-error *ngIf="fcFunktionKurztext.errors !== null && fcFunktionKurztext.errors['maxlength']">{{
          getErrorMessage(fcFunktionKurztext)
        }}</mat-error>
        <mat-error *ngIf="fcFunktionKurztext.errors !== null && fcFunktionKurztext.errors['pattern']"
          >Keine Umlaute oder Sonderzeichen</mat-error
        >
      </mat-form-field>
    </mat-card>

    <!-- Rechte Spalte mit Informationen zur Person -->
    <mat-card>
      <mat-card-subtitle>Informationen zur Person</mat-card-subtitle>

      <app-cleanable-form-field label="Vorname" [formGroup]="formGroup" formControlName="vorname" />

      <app-cleanable-form-field label="Nachname" [formGroup]="formGroup" formControlName="nachname" />

      <app-cleanable-form-field label="Dienstgrad" [formGroup]="formGroup" formControlName="dienstgrad" />

      <app-cleanable-form-field
        label="Ortsangaben - Ausgangspunkt"
        [formGroup]="formGroup"
        formControlName="ortsangabe"
      />

      <app-cleanable-form-field label="Herkunft" [formGroup]="formGroup" formControlName="herkunft" />

      <app-cleanable-form-field label="Gliederung" [formGroup]="formGroup" formControlName="gliederung" />
    </mat-card>
  </form>

  <app-kontakt-list [kommunikationOptionen]="personToEdit?.kommunikationOptionen || []" />

  <app-auftrag-list />
</div>
