import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneDTO, PersonenuebersichtDTO } from 'src/app/api/build/openapi';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import {
  childFuehrungsebenenSelector,
  currentFuehrungsebeneSelector,
  personenuebersichtSelector,
} from '../../fuehrungsebene/+state/fuehrungsebene.selectors';
import { FuehrungsebeneIconAndTextComponent } from '../../fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from '../../fuehrungsebene/fuehrungsebene.service';
import { Manv } from '../manv.interface';
import { ManvComponent } from '../manv/manv.component';
import { Personen } from '../personen.interface';
import { PersonenuebersichtSubsetComponent } from '../personenuebersicht-subset/personenuebersicht-subset.component';

/**
 * Dialog zum Bearbeiten von Betroffenen Personen einer Lage oder eines bestimmten EAs.
 * Die Current-Führungsebene bzw. eine Kind-Führungsebene der Current-Führungsebene kann über ein Dropdown ausgewählt werden.
 * Setzt sich zusammen aus drei Personenübersicht-Komponenten und einer MANV Komponente.
 *
 * Die Personenübersicht "Gesamt" berechnet die Summe aus den Zahlen von Bevölkerung + Einsatzkräfte sowie den Gesamtsummen aller Kind-Führungsebenen.
 * Bevölkerung und Einsatzkräfte beziehen sich immer nur auf der ausgewählten Führungsebene.
 */
@Component({
  selector: 'app-personenuebersicht-dialog',
  templateUrl: './personenuebersicht-dialog.component.html',
  styleUrls: ['./personenuebersicht-dialog.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    NgFor,
    MatInputModule,
    MatDatepickerModule,
    PersonenuebersichtSubsetComponent,
    ManvComponent,
    MatDialogModule,
    MatButtonModule,
    AsyncPipe,
    MatToolbarModule,
    DatetimeLocalAccessorDirective,
    ReactiveFormsModule,
    FuehrungsebeneIconAndTextComponent,
  ],
})
export class PersonenuebersichtDialogComponent {
  currentFuehrungsebene$: Observable<FuehrungsebeneDTO | null>;
  childFuehrungsebenen$: Observable<FuehrungsebeneDTO[]>;
  personenuebersicht?: PersonenuebersichtDTO;
  letzteBearbeitungTime?: string;
  manv?: Manv;
  personenBevoelkerung?: Personen;
  personenEinsatzkraefte?: Personen;
  personenGesamt?: Personen;

  letzteBearbeitung = new FormControl<Date | null>(null);

  constructor(store: Store<AppStateInterface>, private fuehrungsebeneService: FuehrungsebeneService) {
    this.currentFuehrungsebene$ = store.select(currentFuehrungsebeneSelector);
    this.childFuehrungsebenen$ = store.select(childFuehrungsebenenSelector);
    store
      .select(personenuebersichtSelector)
      .pipe(take(1))
      .subscribe((personenuebersicht) => {
        if (!personenuebersicht) {
          return;
        }
        this.setPersonenuebersicht(personenuebersicht);
      });
  }

  private setPersonenuebersicht(personenuebersicht: PersonenuebersichtDTO) {
    this.personenuebersicht = personenuebersicht;
    this.letzteBearbeitung.setValue(
      personenuebersicht.letzteBearbeitung ? new Date(personenuebersicht.letzteBearbeitung) : new Date()
    );

    this.manv = {
      category1: personenuebersicht.manv1 || 0,
      category2: personenuebersicht.manv2 || 0,
      category3: personenuebersicht.manv3 || 0,
      category4: personenuebersicht.manv4 || 0,
      dead: personenuebersicht.manvTote || 0,
    };

    this.personenBevoelkerung = {
      verletzt: personenuebersicht.bevVerletzt || 0,
      tot: personenuebersicht.bevTot || 0,
      vermisst: personenuebersicht.bevVermisst || 0,
      betroffen: personenuebersicht.bevBetroffen || 0,
      gerettet: personenuebersicht.bevGerettet || 0,
      transportiert: personenuebersicht.bevTransportiert || 0,
    };

    this.personenEinsatzkraefte = {
      verletzt: personenuebersicht.ekVerletzt || 0,
      tot: personenuebersicht.ekTot || 0,
      vermisst: personenuebersicht.ekVermisst || 0,
      betroffen: personenuebersicht.ekBetroffen || 0,
      gerettet: personenuebersicht.ekGerettet || 0,
      transportiert: personenuebersicht.ekTransportiert || 0,
    };

    this.personenGesamt = {
      verletzt: personenuebersicht.gesamtVerletzt || 0,
      tot: personenuebersicht.gesamtTot || 0,
      vermisst: personenuebersicht.gesamtVermisst || 0,
      betroffen: personenuebersicht.gesamtBetroffen || 0,
      gerettet: personenuebersicht.gesamtGerettet || 0,
      transportiert: personenuebersicht.gesamtTransportiert || 0,
    };
  }

  fuehrungsebeneChanged(selection: MatSelectChange) {
    this.fuehrungsebeneService
      .getPersonenuebersicht(selection.value)
      .subscribe((personenuebersicht) => this.setPersonenuebersicht(personenuebersicht));
  }
}
