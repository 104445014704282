export class Format {
  public static readonly TIME_REGEX = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  public static formatDistance(distanceMeters: number): string {
    if (!distanceMeters || distanceMeters <= 0) {
      return '0 m';
    } else if (distanceMeters >= 1000) {
      return (distanceMeters / 1000.0).toFixed(2) + ' km';
    } else {
      return distanceMeters.toFixed(1) + ' m';
    }
  }

  /**
   * Zeit kommt als vollständiges Datum aus dem Backend, es soll aber nur hh:mm extrahiert werden.
   * Beispiel: 2023-02-02T12:06:32.6541175
   * Kann auch null sein
   */
  public static extractTime(datetime: Date | string | null | undefined): string {
    if (datetime) {
      const date = new Date(datetime);
      return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    }
    return '';
  }

  /**
   * Kombiniert `date` und `time` zu einem neuen Datum und gibt den "ISO"-String zurück.
   *
   * @param date Datum
   * @param time Zeitstring
   * @returns den ISO-Sting des kombinierten `Dates`
   */
  public static combineDateTimeAsISOString(date: Date, time: string): string {
    if (!date || !time || !time.length) {
      return '';
    }

    const timeSplit = time.split(':');
    if (!timeSplit || timeSplit.length !== 2) {
      return '';
    }

    const hours = timeSplit[0].padStart(2, '0');
    const minutes = timeSplit[1].padStart(2, '0');

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), +hours, +minutes).toISOString();
  }
}
