import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, throwError } from 'rxjs';
import { ErrorResponse, WetterDTO } from 'src/app/api/build/openapi';
import { WetterResourceService } from '../../../api/build/openapi/api/wetterResource.service';
import { lageActions } from '../../lagen/+state/lage.actions';
import { wetterActions } from './wetter.actions';

@Injectable()
export class WetterEffects {
  /**
   * Wenn Sich die aktuelle Lage ändert, Neuladen des Wetters anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return wetterActions.getWetter({ lageId: props.currentLage.id });
        } else {
          return wetterActions.resetStore();
        }
      })
    )
  );

  /**
   * Wetter zu einer Lage laden
   */
  getWetter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wetterActions.getWetter),
      switchMap((props) =>
        this.WetterResourceService.getWetterByLageId(props.lageId).pipe(
          map((result) => {
            return wetterActions.getWetterSuccess({ wetter: result || undefined, lageId: props.lageId });
          }),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(wetterActions.getWetterFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neues Wetter anlegen
   */
  createWetter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wetterActions.createWetter),
      switchMap((props) =>
        this.WetterResourceService.createWetter(props.lageId, props.wetter).pipe(
          map((createdWetter: WetterDTO) => wetterActions.createWetterSuccess({ createdWetter })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(wetterActions.createWetterFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Existierendes Wetter aktualisieren
   */
  patchWetter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wetterActions.patchWetter),
      switchMap((props) => {
        if (!props.wetter.lageId) {
          return throwError(() => new Error('Wetter muss eine lageId besitzen'));
        }
        return this.WetterResourceService.patchWetter(props.wetter.lageId, props.wetter).pipe(
          map((patchedWetter: WetterDTO) => wetterActions.patchWetterSuccess({ patchedWetter })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(wetterActions.patchWetterFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private WetterResourceService: WetterResourceService) {}
}
