import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FotoDTO, FotoPreviewDTO, FuehrungsebeneDTO } from 'src/app/api/build/openapi';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { IconAndTextComponent } from 'src/app/shared/components/icon-and-text/icon-and-text.component';
import { TaktischesZeichenPopup } from '../../taktische-zeichen.interface';
import { TzPopupToolbarComponent } from '../../tz-popup-toolbar/tz-popup-toolbar.component';
import { FotoDialogComponent } from '../foto-dialog/foto-dialog.component';
import { FotoService } from '../foto.service';

/**
 * Popup für Fotos als Taktische Zeichen auf der Hauptkarte.
 * Zeigt die kleine Version des hinterlegten Fotos. Durch Klick auf die Vorschau
 * wird das Foto vergrößert.
 */
@Component({
  selector: 'app-foto-popup',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    IconAndTextComponent,
    FuehrungsebeneIconAndTextComponent,
    TzPopupToolbarComponent,
  ],
  templateUrl: './foto-popup.component.html',
  styleUrls: ['./foto-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FotoPopupComponent implements OnInit, TaktischesZeichenPopup {
  @Input({ required: true }) fotoPreviewDTO!: FotoPreviewDTO;
  @Output() actionExecuted = new EventEmitter<void>();
  @Output() fotoLoaded = new EventEmitter<void>();

  private changeDetectorRef = inject(ChangeDetectorRef);
  private fotoService = inject(FotoService);
  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private dialog = inject(MatDialog);

  protected parentFuehrungsebene?: FuehrungsebeneDTO;
  protected fotoDTO?: FotoDTO;
  protected loadingFoto = false;

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.fotoPreviewDTO.fuehrungsebeneId);
  }

  loadFoto() {
    this.loadingFoto = true;
    this.fotoService.getFotoDTO(this.fotoPreviewDTO).subscribe((fotoDTO) => {
      this.fotoDTO = fotoDTO;
      this.loadingFoto = false;
      this.fotoLoaded.emit();
      this.changeDetectorRef.detectChanges();
    });
  }

  openFoto() {
    this.dialog.open(FotoDialogComponent, { data: { fotoDTO: this.fotoDTO } });
    this.actionExecuted.emit();
  }
}
