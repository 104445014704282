import { Component } from '@angular/core';
import { LagedarstellungToolbarComponent } from '../lagedarstellung-toolbar/lagedarstellung-toolbar.component';
import { LagedarstellungWindowTabsComponent } from '../lagedarstellung-window-tabs/lagedarstellung-window-tabs.component';

@Component({
  selector: 'app-lagedarstellung-base',
  templateUrl: './lagedarstellung-base.component.html',
  styleUrls: ['./lagedarstellung-base.component.scss'],
  standalone: true,
  imports: [LagedarstellungToolbarComponent, LagedarstellungWindowTabsComponent],
})
export class LagedarstellungBaseComponent {}
