import { NestedTreeControl } from '@angular/cdk/tree';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { FuehrungsebeneVisibilityNode, Fuehrungsebenentyp, MapLayerConfig } from 'src/app/api/build/openapi';
import { LayerConfigService } from 'src/app/planung/karte/layer-config.service';
import { VisibilityProperty } from '../../fuehrungsebene-hierarchie/fuehrungsebene-hierarchie.interface';
import { FuehrungsebeneHierarchieService } from '../../fuehrungsebene-hierarchie/fuehrungsebene-hierarchie.service';
import { FuehrungsebeneService } from '../../fuehrungsebene/fuehrungsebene.service';
import { FuehrungsebeneHierarchyTreeItemComponent } from '../fuehrungsebene-hierarchy-tree-item/fuehrungsebene-hierarchy-tree-item.component';

@Component({
  selector: 'app-fuehrungsebene-hierarchie-tree',
  templateUrl: './fuehrungsebene-hierarchie-tree.component.html',
  styleUrls: ['./fuehrungsebene-hierarchie-tree.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatToolbarModule,
    NgIf,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    NgFor,
    NgClass,
    MatTreeModule,
    AsyncPipe,
    MatCheckboxModule,
    FuehrungsebeneHierarchyTreeItemComponent,
    MatButtonToggleModule,
  ],
})
export class FuehrungsebeneHierarchieTreeComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  Fuehrungsebenentyp = Fuehrungsebenentyp;
  VisibilityProperty = VisibilityProperty;

  treeControl = new NestedTreeControl<FuehrungsebeneVisibilityNode>((node) => node.mainTypeChildNodes);
  dataSource = new MatTreeNestedDataSource<FuehrungsebeneVisibilityNode>();
  private rootVisibilityNode?: FuehrungsebeneVisibilityNode;

  selectedBaseLayerConfig?: MapLayerConfig;
  selectedOverlayConfigs: MapLayerConfig[] = [];

  protected hierarchieService = inject(FuehrungsebeneHierarchieService);
  protected layerConfigService = inject(LayerConfigService);
  protected fuehrungsebeneService = inject(FuehrungsebeneService);

  ngOnInit(): void {
    this.layerConfigService.selectedBaseLayerConfig$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((baseLayerConfig) => (this.selectedBaseLayerConfig = baseLayerConfig));

    this.layerConfigService.selectedOverlayConfigs$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((overlayConfigs) => (this.selectedOverlayConfigs = overlayConfigs));

    /**
     * Baum neu zeichnen, nachdem sich das gesamte modell verändert hat
     */
    this.hierarchieService.fuehrungsebeneTree$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((root) => {
      this.rootVisibilityNode = root;
      this.resetRootModel();
      if (this.dataSource.data.length) {
        this.treeControl.expandDescendants(this.dataSource.data[0]);
      }
    });

    /**
     * Baum neu zeichnen, nachdem sich ein VisibilityNode verändert hat
     */
    this.hierarchieService.nodeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.resetRootModel();
    });
  }

  /**
   * Tree-Model neu setzen. Tree bekommst sonst keine Änderungen mit.
   */
  private resetRootModel() {
    if (this.rootVisibilityNode) {
      this.dataSource.data = [];
      this.dataSource.data = [this.rootVisibilityNode];
      if (this.dataSource.data.length) {
        this.treeControl.expandDescendants(this.dataSource.data[0]);
      }
    }
  }

  toggleOverlayConfig(toggledOverlayConfig: MapLayerConfig) {
    if (this.selectedOverlayConfigs.includes(toggledOverlayConfig)) {
      this.layerConfigService.setOverlayConfigs(
        this.selectedOverlayConfigs.filter((config) => config.id !== toggledOverlayConfig.id)
      );
    } else {
      this.layerConfigService.setOverlayConfigs([...this.selectedOverlayConfigs, toggledOverlayConfig]);
    }
  }

  hasChildren = (_: number, node: FuehrungsebeneVisibilityNode) => node.mainTypeChildNodes.length;

  trackBy = (_: number, node: FuehrungsebeneVisibilityNode) => node.fuehrungsebeneDTO.id;

  dontClose($event: MouseEvent) {
    $event.stopPropagation();
  }
}
