import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, FahrzeugResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { fahrzeugActions } from './fahrzeug.actions';

@Injectable()
export class FahrzeugEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Fahrzeuge anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return fahrzeugActions.getFahrzeuge({ lageId: props.currentLage.id });
        } else {
          return fahrzeugActions.resetStore();
        }
      })
    )
  );

  getFahrzeuge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fahrzeugActions.getFahrzeuge),
      mergeMap((props) =>
        this.fahrzeugeResourceService.getFahrzeugeByLageId(props.lageId).pipe(
          map((fahrzeuge) => fahrzeugActions.getFahrzeugeSuccess({ fahrzeuge, lageId: props.lageId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(fahrzeugActions.getFahrzeugeFailure({ errorResponse }));
          })
        )
      )
    )
  );

  createFahrzeug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fahrzeugActions.createFahrzeug),
      mergeMap((props) =>
        this.fahrzeugeResourceService.createFahrzeug(props.lageId, props.fahrzeug).pipe(
          map((newFahrzeug) => fahrzeugActions.createFahrzeugSuccess({ newFahrzeug })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(fahrzeugActions.createFahrzeugFailure({ errorResponse }));
          })
        )
      )
    )
  );

  patchFahrzeug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fahrzeugActions.patchFahrzeug),

      mergeMap((props) =>
        this.fahrzeugeResourceService.patchFahrzeug(props.fahrzeugId, props.lageId, props.fahrzeugDTO).pipe(
          map((patchedFahrzeug) => fahrzeugActions.patchFahrzeugSuccess({ patchedFahrzeug })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(fahrzeugActions.patchFahrzeugFailure({ errorResponse }));
          })
        )
      )
    )
  );

  deleteFahrzeug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fahrzeugActions.deleteFahrzeug),
      mergeMap((props) => {
        if (!props.fahrzeugDTO.id) {
          return throwError(() => 'Fahrzeug kann nicht gelöscht werden. Zu löschendes Fahrzeug hat keine Id.');
        }
        return this.fahrzeugeResourceService.deleteFahrzeug(props.fahrzeugDTO.id, props.lageId).pipe(
          map(() => fahrzeugActions.deleteFahrzeugSuccess({ deletedFahrzeugDTO: props.fahrzeugDTO })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(fahrzeugActions.deleteFahrzeugFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private fahrzeugeResourceService: FahrzeugResourceService) {}
}
