import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, forwardRef, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuehrungsebeneDTO, TaktischesZeichenStatus } from 'src/app/api/build/openapi';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { TaktischesZeichenTyp } from '../../../../api/build/openapi/model/taktischesZeichenTyp';
import { TaktischeZeichenContainerComponent } from '../taktische-zeichen-container.component';

export interface DialogData {
  fuehrungsebeneDTO: FuehrungsebeneDTO;
  taktischesZeichenStatus: TaktischesZeichenStatus;
  taktischesZeichenTypen: TaktischesZeichenTyp[];
  showEinsaetze?: boolean;
}

@Component({
  selector: 'app-taktische-zeichen-container-dialog',
  templateUrl: './taktische-zeichen-container-dialog.component.html',
  styleUrls: ['./taktische-zeichen-container-dialog.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatSlideToggleModule,
    FormsModule,
    FuehrungsebeneIconAndTextComponent,
    NgIf,

    // Circular Dependency auflösen
    forwardRef(() => TaktischeZeichenContainerComponent),
  ],
})
export class TaktischeZeichenContainerDialogComponent implements OnInit {
  fuehrungsebeneDTO!: FuehrungsebeneDTO;
  taktischesZeichenStatus!: TaktischesZeichenStatus;
  taktischesZeichenTypen: TaktischesZeichenTyp[] = [];
  showEinsaetze = false;
  protected taktischeZeichenService = inject(TaktischeZeichenService);

  showAll = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: DialogData) {}

  ngOnInit(): void {
    this.taktischesZeichenStatus = this.data.taktischesZeichenStatus;
    this.taktischesZeichenTypen = this.data.taktischesZeichenTypen || [];
    this.fuehrungsebeneDTO = this.data.fuehrungsebeneDTO;
    this.showEinsaetze = this.data.showEinsaetze || false;
  }
}
