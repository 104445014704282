<mat-tree [treeControl]="treeControl" [dataSource]="dataSource" #tree class="example-tree">
  <!-- Leafs -->
  <mat-tree-node
    matTreeNodePadding
    [matTreeNodePaddingIndent]="matTreeNodePaddingIndent"
    *matTreeNodeDef="let node"
    class="node"
    (click)="emitTreeNodeClicked(node)"
  >
    <span class="node-name margin-l">
      {{ node.name }}
    </span>

    <ng-container *ngTemplateOutlet="buttons; context: { node }" />
  </mat-tree-node>

  <!-- expandable -->
  <mat-tree-node
    matTreeNodePadding
    [matTreeNodePaddingIndent]="matTreeNodePaddingIndent"
    *matTreeNodeDef="let node; when: hasChild"
    class="node"
    (click)="emitTreeNodeClicked(node)"
  >
    <button mat-icon-button matTreeNodeToggle>
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
      </mat-icon>
    </button>
    <span class="node-name">
      {{ node.name }}
    </span>
    <ng-container *ngTemplateOutlet="buttons; context: { node }" />
  </mat-tree-node>
</mat-tree>

<!-- Buttons für Tree-Nodes -->
<ng-template #buttons let-node="node">
  <!-- Verwaltungsebene anlegen -->
  <button
    *ngIf="enableEdit && !enableSelection"
    class="button"
    mat-icon-button
    matTooltip="Verwaltungsebene anlegen"
    mat-icon
    (click)="openVerwaltungsebeneDialog($event, node)"
  >
    <mat-icon>add</mat-icon>
  </button>

  <!-- Aktion: Verwaltungsebene selektieren, wofür ein Event ausgelöst wird-->
  <button
    *ngIf="enableSelection"
    class="button"
    mat-icon-button
    [matTooltip]="node.name + ' wählen.'"
    mat-icon
    (click)="emitSelectedVerwaltungsebene($event, node)"
  >
    <mat-icon>arrow_forward</mat-icon>
  </button>
</ng-template>
