import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { EinsatzfilterResourceService, ErrorResponse } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { einsatzfilterActions } from './einsatzfilter.actions';

@Injectable()
export class EinsatzfilterEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Einsatzfilter anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return einsatzfilterActions.getEinsatzfilter({ lageId: props.currentLage.id });
        } else {
          return einsatzfilterActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle Einsatzfilter einer Lage laden
   */
  getEinsatzfilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(einsatzfilterActions.getEinsatzfilter),
      mergeMap((props) =>
        this.einsatzfilterResourceService.getEinsatzfilterByLageId(props.lageId).pipe(
          map((einsatzfilter) =>
            einsatzfilterActions.getEinsatzfilterSuccess({ einsatzfilterDTOs: einsatzfilter, lageId: props.lageId })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(einsatzfilterActions.getEinsatzfilterFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neuen Einsatzfilter anlegen
   */
  createEinsatzfilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(einsatzfilterActions.createEinsatzfilter),
      mergeMap((props) =>
        this.einsatzfilterResourceService.createEinsatzfilter(props.lageId, props.einsatzfilterDTO).pipe(
          map((createdEinsatzfilterDTO) =>
            einsatzfilterActions.createEinsatzfilterSuccess({ createdEinsatzfilterDTO })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(einsatzfilterActions.createEinsatzfilterFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Existierenden Einsatzfilter editieren
   */
  patchEinsatzfilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(einsatzfilterActions.patchEinsatzfilter),
      mergeMap((props) => {
        if (!props.einsatzfilterDTO.id) {
          return throwError(() => new Error('Einsatzfilter muss eine Id besitzen'));
        }
        return this.einsatzfilterResourceService
          .patchEinsatzfilter(props.einsatzfilterDTO.id, props.lageId, props.einsatzfilterDTO)
          .pipe(
            map((patchedEinsatzfilterDTO) =>
              einsatzfilterActions.patchEinsatzfilterSuccess({ patchedEinsatzfilterDTO })
            ),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(einsatzfilterActions.patchEinsatzfilterFailure({ errorResponse }));
            })
          );
      })
    )
  );

  /**
   * Einsatzfilter löschen
   */
  deleteEinsatzfilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(einsatzfilterActions.deleteEinsatzfilter),
      mergeMap((props) => {
        if (!props.einsatzfilterDTO.id) {
          return throwError(() => new Error('Einsatzfilter muss eine Id besitzen'));
        }
        return this.einsatzfilterResourceService.deleteEinsatzfilter(props.einsatzfilterDTO.id, props.lageId).pipe(
          map((deletedEinsatzfilterId) => einsatzfilterActions.deleteEinsatzfilterSuccess({ deletedEinsatzfilterId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(einsatzfilterActions.deleteEinsatzfilterFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private einsatzfilterResourceService: EinsatzfilterResourceService) {}
}
