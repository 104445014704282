import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuehrungsebeneVisibilityNode } from 'src/app/api/build/openapi';
import { ComponentQualificationService } from 'src/app/misc/component-qualification.service';
import { FuehrungsebeneIconAndTextComponent } from '../../fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from '../../fuehrungsebene/fuehrungsebene.service';
import { VisibilityProperty } from '../fuehrungsebene-hierarchie.interface';
import { FuehrungsebeneHierarchieService } from '../fuehrungsebene-hierarchie.service';

@Component({
  selector: 'app-fuehrungsebene-hierarchy-tree-item',
  standalone: true,
  imports: [MatIconModule, NgIf, MatTooltipModule, FuehrungsebeneIconAndTextComponent, AsyncPipe],
  templateUrl: './fuehrungsebene-hierarchy-tree-item.component.html',
  styleUrl: './fuehrungsebene-hierarchy-tree-item.component.scss',
})
export class FuehrungsebeneHierarchyTreeItemComponent {
  @Input({ required: true }) node!: FuehrungsebeneVisibilityNode;

  VisibilityProperty = VisibilityProperty;

  private hierarchyService = inject(FuehrungsebeneHierarchieService);
  protected fuehrungsebeneService = inject(FuehrungsebeneService);
  protected componentService = inject(ComponentQualificationService);

  toggleLayerVisibility(
    node: FuehrungsebeneVisibilityNode | undefined,
    visibilityProperty: VisibilityProperty,
    recursively: boolean
  ) {
    this.hierarchyService.toggleLayerVisibility(node, visibilityProperty, recursively);
  }
}
