<mat-toolbar>
  <h2>Wetter</h2>
</mat-toolbar>

<mat-dialog-content>
  <form class="messpunkte-form" [formGroup]="wetterForm">
    <mat-form-field>
      <mat-label>Messpunkt</mat-label>
      <input matInput type="text" formControlName="messpunkt" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Veranwtortlich</mat-label>
      <input matInput type="text" formControlName="verantwortlich" />
    </mat-form-field>
  </form>

  <div class="mitte">
    <div class="witterung-windrichtung">
      <mat-card class="wetter-card">
        <h3>Witterung</h3>
        <img
          id="witterungicon"
          src="assets/wetter/wetter_{{ this.wetterForm.get('witterung')?.value }}.png"
          (click)="toggleWitterung()"
        />
      </mat-card>

      <mat-card class="wetter-card">
        <h3>Windrichtung</h3>
        <ise-kompass
          [editable]="true"
          (needlePositionChanged)="onWindrichtungChange($event)"
          [value]="this.wetterForm.get('windrichtung')?.value"
          [umlaufend]="this.wetterForm.get('umlaufend')?.value"
        ></ise-kompass>
        <form class="messpunkte-form" [formGroup]="wetterForm">
          <mat-checkbox formControlName="umlaufend">umlaufend</mat-checkbox>
        </form>
      </mat-card>
    </div>

    <mat-card class="wettermessungen">
      <mat-toolbar>
        <h3>Messungen</h3>
        <button mat-icon-button matTooltip="Messung hinzufügen" (click)="onClickHinzufuegen()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>

      <div class="messungen">
        <div class="labels">
          <p>Datum/Uhrzeit</p>
          <p>Windgeschw.</p>
          <p>Windstärke</p>
          <p>Temperatur</p>
          <p>Luftdruck</p>
          <p>Bewölkung</p>
          <p>Niederschlag</p>
          <p>Sicht</p>
        </div>

        <div class="values-container">
          <div
            class="values list-row"
            *ngFor="let messung of wettermessungen; let index = index"
            (click)="editWettermessung(index)"
          >
            <p>{{ messung.datum | dateTime | async }}</p>
            <p>{{ messung.windgeschwindigkeit }} km/h</p>
            <p>{{ messung.windstaerke }} bft</p>
            <p>{{ messung.temperatur }} °C</p>
            <p>{{ messung.luftdruck }} mbar</p>
            <p>{{ messung.bewoelkung }} /8</p>
            <p>{{ messung.niederschlag }} l/m²</p>
            <p>{{ messung.sicht }} km</p>
          </div>
          <h3 class="keine-daten-text" *ngIf="!wettermessungen.length">Keine Messungen vorhanden</h3>
        </div>
      </div>
    </mat-card>
  </div>

  <mat-card class="prognosen">
    <mat-toolbar>
      <h3>Prognosen</h3>
    </mat-toolbar>

    <form class="prognosen-form" [formGroup]="wetterForm">
      <mat-form-field>
        <mat-label>Prognose 6h</mat-label>
        <input matInput type="text" formControlName="prognose6h" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Prognose 12h</mat-label>
        <input matInput type="text" formControlName="prognose12h" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Prognose 24h</mat-label>
        <input matInput type="text" formControlName="prognose24h" />
      </mat-form-field>
    </form>
  </mat-card>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="onClickSave()" class="mr-05">Speichern</button>
</mat-dialog-actions>
