import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultDateTime',
  standalone: true,
})
export class DefaultDateTimePipe implements PipeTransform {
  constructor(private date: DatePipe) {}

  transform(value: Date, showSeconds = false): string {
    return this.date.transform(value, showSeconds ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy HH:mm') || '';
  }
}
