import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { debounceTime } from 'rxjs';
import { LoadableComponentComponent } from 'src/app/shared/components/loadable-component/loadable-component.component';
import {
  isLoadingFuehrungsebeneSelector,
  personenuebersichtSelector,
} from '../../fuehrungsebene/+state/fuehrungsebene.selectors';
import { ManvSmallComponent } from '../manv-small/manv-small.component';
import { PersonenuebersichtDialogComponent } from '../personenuebersicht-dialog/personenuebersicht-dialog.component';
import { PersonenuebersichtSubsetSmallComponent } from '../personenuebersicht-subset-small/personenuebersicht-subset-small.component';

@Component({
  selector: 'app-personenuebersicht',
  templateUrl: './personenuebersicht.component.html',
  styleUrls: ['./personenuebersicht.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    PersonenuebersichtSubsetSmallComponent,
    ManvSmallComponent,
    MatTooltipModule,
    NgIf,
    LoadableComponentComponent,
  ],
})
export class PersonenuebersichtComponent {
  private store = inject(Store);
  private dialog = inject(MatDialog);

  protected personenuebersicht$ = this.store.select(personenuebersichtSelector).pipe(takeUntilDestroyed());
  protected isLoadingPersonenuebersicht$ = this.store
    .select(isLoadingFuehrungsebeneSelector)
    .pipe(takeUntilDestroyed(), debounceTime(500));

  edit() {
    this.dialog.open(PersonenuebersichtDialogComponent);
  }
}
