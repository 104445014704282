import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, throwError } from 'rxjs';
import { ErrorResponse, MapConfigResourceService, MapLayerConfig } from 'src/app/api/build/openapi';
import { mapLayerConfigActions } from './map-layer-config.actions';

@Injectable()
export class MapLayerConfigEffects {
  /**
   * Alle MapLayerConfigs laden
   */
  getMapLayerConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapLayerConfigActions.getMapLayerConfigs),
      mergeMap(() =>
        this.mapConfigsResourceService.getMapLayerConfigs().pipe(
          map((mapLayerConfigs: MapLayerConfig[]) =>
            mapLayerConfigActions.getMapLayerConfigsSuccess({ mapLayerConfigs: mapLayerConfigs })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(mapLayerConfigActions.getMapLayerConfigsFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neue MapLayerConfig anlegen
   */
  createMapLayerConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapLayerConfigActions.createMapLayerConfig),
      mergeMap((props) =>
        this.mapConfigsResourceService.createMapLayerConfig(props.mapLayerConfig).pipe(
          map((createdMapLayerConfig: MapLayerConfig) =>
            mapLayerConfigActions.createMapLayerConfigSuccess({ createdMapLayerConfig })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(mapLayerConfigActions.createMapLayerConfigFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Existierende MapLayerConfig editieren
   */
  patchMapLayerConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapLayerConfigActions.patchMapLayerConfig),
      mergeMap((props) => {
        if (!props.mapLayerConfig.id) {
          return throwError(() => new Error('MapLayerConfig muss eine Id besitzen'));
        }
        return this.mapConfigsResourceService
          .patchMapLayerConfig('' + props.mapLayerConfig.id, props.mapLayerConfig)
          .pipe(
            map((patchedMapLayerConfig: MapLayerConfig) =>
              mapLayerConfigActions.patchMapLayerConfigSuccess({ patchedMapLayerConfig })
            ),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(mapLayerConfigActions.patchMapLayerConfigFailure({ errorResponse }));
            })
          );
      })
    )
  );

  /**
   * Existierende MapLayerConfig löschen
   */
  deleteMapLayerConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapLayerConfigActions.deleteMapLayerConfig),
      mergeMap((props) => {
        if (!props.mapLayerConfig.id) {
          return throwError(() => new Error('MapLayerConfig muss eine Id besitzen'));
        }
        return this.mapConfigsResourceService.deleteMapLayerConfig('' + props.mapLayerConfig.id).pipe(
          map(() =>
            mapLayerConfigActions.deleteMapLayerConfigSuccess({
              deletedMapLayerConfigId: props.mapLayerConfig.id as number,
            })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(mapLayerConfigActions.deleteMapLayerConfigFailure({ errorResponse }));
          })
        );
      })
    )
  );

  /**
   * Eine BaseLayerConfig als neuen Favoriten setzen
   */
  setFavoriteBaseLayerConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapLayerConfigActions.setFavoriteBaseLayerConfig),
      switchMap((props) =>
        this.mapConfigsResourceService
          .setBaseLayerFavorite(props.configId)
          .pipe(map(() => mapLayerConfigActions.getMapLayerConfigs()))
      )
    )
  );

  /**
   * Liste von OverLayConfigs als neue Favoriten setzen
   */
  setFavoriteOverlayConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapLayerConfigActions.setFavoriteOverlayConfigs),
      mergeMap((props) =>
        this.mapConfigsResourceService
          .setOverlayFavorites(props.configIds)
          .pipe(map(() => mapLayerConfigActions.getMapLayerConfigs()))
      )
    )
  );

  /**
   * Neue Position einer MapLayerConfig festlegen
   */
  setMapLayerOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapLayerConfigActions.setMapLayerOrder),
      switchMap((props) =>
        this.mapConfigsResourceService
          .patchOrder(props.configId, props.newOrderNumber + '')
          .pipe(map(() => mapLayerConfigActions.getMapLayerConfigs()))
      )
    )
  );

  constructor(private actions$: Actions, private mapConfigsResourceService: MapConfigResourceService) {}
}
