/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Verwaltungsebenentyp = 'KEINE' | 'EU' | 'BUND' | 'BUNDESLAND' | 'STADTSTAAT' | 'REGIERUNGSBEZIRK' | 'LAND_KREIS' | 'KREISFREIE_STADT' | 'AMT' | 'GEMEINDE';

export const Verwaltungsebenentyp = {
    Keine: 'KEINE' as Verwaltungsebenentyp,
    Eu: 'EU' as Verwaltungsebenentyp,
    Bund: 'BUND' as Verwaltungsebenentyp,
    Bundesland: 'BUNDESLAND' as Verwaltungsebenentyp,
    Stadtstaat: 'STADTSTAAT' as Verwaltungsebenentyp,
    Regierungsbezirk: 'REGIERUNGSBEZIRK' as Verwaltungsebenentyp,
    LandKreis: 'LAND_KREIS' as Verwaltungsebenentyp,
    KreisfreieStadt: 'KREISFREIE_STADT' as Verwaltungsebenentyp,
    Amt: 'AMT' as Verwaltungsebenentyp,
    Gemeinde: 'GEMEINDE' as Verwaltungsebenentyp
};

