<mat-toolbar>
  <h2>Taktische Zeichen Editor</h2>
</mat-toolbar>
<mat-dialog-content>
  <app-taktische-zeichen-form #taktischeZeichenForm></app-taktische-zeichen-form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="submit()" class="mr-05">Auswählen</button>
</mat-dialog-actions>
