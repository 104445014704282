<form [formGroup]="formGroup" class="left-side">
  <mat-form-field *ngIf="showTypeSelection">
    <mat-label>Typ</mat-label>
    <mat-select
      matInput
      [(ngModel)]="fuehrungsebeneDTO!.typ"
      [ngModelOptions]="{ standalone: true }"
      (selectionChange)="updateGeometryStyle()"
    >
      <mat-option *ngFor="let type of selectableTypes" [value]="type">{{
        fuehrungsebeneService.fuehrungsebenentypMapping.get(type)?.longname
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="name-field">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput required="true" [formControl]="fcName" />
      <mat-error>{{ getErrorMessage(fcName) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Kürzel</mat-label>
      <input matInput [formControl]="fcKuerzel" [maxlength]="4" />
      <mat-error *ngIf="fcKuerzel.errors !== null && fcKuerzel.errors['maxlength']">{{
        getErrorMessage(fcKuerzel)
      }}</mat-error>
      <mat-error *ngIf="fcKuerzel.errors !== null && fcKuerzel.errors['pattern']"
        >Keine Umlaute oder Sonderzeichen</mat-error
      >
    </mat-form-field>
  </div>

  <ng-content></ng-content>

  <mat-form-field>
    <mat-label>Startzeit</mat-label>
    <input matInput type="datetime-local" [formControl]="fcStartedOn" dateTimeLocalAsDate />
    <mat-error>{{ getErrorMessage(fcStartedOn) }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Adresse</mat-label>
    <input matInput [formControl]="fcAdresse" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Leiter Name</mat-label>
    <input matInput [formControl]="fcLeiterName" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Leiter Dienstgrad</mat-label>
    <input matInput [formControl]="fcLeiterDienstgrad" />
  </mat-form-field>

  <ng-container *ngIf="showEinsatzleitung">
    <mat-checkbox class="checkbox" [formControl]="fcEinsatzleitung">Einsatzleitung</mat-checkbox>

    <label for="einsatzleitungstyp">Art der Einsatzleitung</label>
    <mat-radio-group [formControl]="fcEinsatzleitungstyp" name="einsatzleitungstyp">
      <mat-radio-button [value]="Einsatzleitungstyp.Paragraf33">Einsatzleitung §33 BHKG</mat-radio-button>
      <mat-radio-button [value]="Einsatzleitungstyp.Paragraf37">Einsatzleitung §37 BHKG</mat-radio-button>
    </mat-radio-group>
  </ng-container>

  <ng-container *ngIf="showMedizinischeRettung">
    <mat-checkbox class="checkbox" [formControl]="fcMedizinischeRettung">Medizinische Rettung</mat-checkbox>
  </ng-container>

  <app-kontakt-list class="mb-1" [kommunikationOptionen]="fuehrungsebeneDTO.kommunikationOptionen || []" />
  <app-auftrag-list class="mb-1" />
  <app-einsatzfilter-selection-list
    [einsatzfilterDTOs]="fuehrungsebeneDTO.einsatzfilter || []"
    [fuehrungsebeneDTO]="fuehrungsebeneDTO"
    *ngIf="showEinsatzfilter"
  />
</form>

<div class="right-side">
  <mat-error *ngIf="!geometryValid">Bitte eine Koordinate oder einen Bereich festlegen</mat-error>
  <app-simple-map
    [feature]="feature"
    [other]="other"
    (featureChanged)="featureChanged($event)"
    (errorHint)="showError($event)"
  />
</div>
