import { Pipe, PipeTransform } from '@angular/core';
import { Bereich } from 'src/app/api/build/openapi';

@Pipe({
  name: 'bereich',
  standalone: true,
})
export class BereichPipe implements PipeTransform {
  /**
   *
   * @returns liefert einen lesbaren String fuer Stabsbereiche
   */
  transform(bereich: Bereich): string {
    if (!bereich) {
      return '';
    }
    switch (bereich) {
      case 'LEITER_DES_STABES':
        return 'Leiter des Stabes';
      case 'STABSHILFSPERSONAL':
        return 'Stabshilfspersonal';
      default:
        return bereich;
    }
  }
}
