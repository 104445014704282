<mat-toolbar>
  <h2>Einsatzfilter {{ dialogMode === DialogMode.CREATE ? "hinzufügen" : "bearbeiten" }}</h2>
</mat-toolbar>

<div class="content">
  <div class="top">
    <div class="filters">
      <mat-form-field>
        <mat-label>Filtername</mat-label>
        <input matInput [formControl]="fcName" />
        <mat-error>{{ getErrorMessage(fcName) }}</mat-error>
      </mat-form-field>

      <!-- Filter auf der linken Seite -->
      <mat-card class="filters">
        <mat-toolbar>
          <h3>Aktive Filter</h3>
        </mat-toolbar>

        <div class="filter-list">
          <!-- Auflistung aller Filter -->
          <app-chip-list-input
            *ngFor="let filterField of usedFilterFields$ | async"
            [label]="filterFieldLabelMapping.get(filterField)"
            [control]="filterFieldControlMapping.get(filterField)!"
          />

          <!-- Neue Filter hinzufügen -->
          <span>
            <mat-form-field>
              <mat-label>Weitere Filter</mat-label>
              <mat-select matInput [formControl]="fcFilterField">
                <mat-option *ngFor="let filterField of availableFilterFields" [value]="filterField">{{
                  filterFieldLabelMapping.get(filterField)
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <button
              mat-raised-button
              color="accent"
              (click)="useCurrentFilterField()"
              [disabled]="!fcFilterField.value"
            >
              Filter hinzufügen
            </button>
          </span>
        </div>
      </mat-card>
    </div>

    <!-- Karte auf der rechten Seite -->
    <div id="map">
      <h3>Platzhalter Karte</h3>
    </div>
  </div>

  <button class="vorschau" mat-raised-button color="accent" (click)="refreshPreviewEinsatzDTOs()">Vorschau</button>

  <mat-card class="einsatz-list-card">
    <app-einsatz-list (refreshClicked)="refreshPreviewEinsatzDTOs()" [einsatzDTOs$]="previewEinsatzDTOs$" />
  </mat-card>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="save()">Speichern</button>
</mat-dialog-actions>
