import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { LeafletMouseEvent, Map, circle, divIcon, marker } from 'leaflet';
import { LayerService } from '../layer.service';
import { Tool, ToolSettings, ToolType } from '../tool.interface';
import { ToolService } from '../tool.service';
import { CircleGenerator } from './circle-generator';
import { DistanceGenerator } from './distance-generator';
import { LineGenerator } from './line-generator';
import { PingGenerator } from './ping-generator';
import { PointGenerator } from './point-generator';
import { PolygonGenerator } from './polygon-generator';
import { RectangleGenerator } from './rectangle-generator';
import { TextGenerator } from './text-generator';

/**
 * Basisklasse für das Erzeugen von GeoJSON Features.
 */
export interface FeatureGenerator {
  createFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): void;
  updateFeature(event: LeafletMouseEvent): void;
  extendFeature(event: LeafletMouseEvent): void;
  finalizeFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): GeoJSON.Feature | null;
}

/**
 * ToolHandler ist verantwortlich für die Verwendung aller Karten-Tools. Übernimmt die Generierung von GeoJSON-Features.
 * Je nach ausgewähltem Tool wird der entsprechende FeatureGenerator genutzt, um Features auf der Karte zu erzeugen.
 */
@Injectable({
  providedIn: 'root',
})
export class ToolHandler {
  // Allgemeiner FeatureGenerator, der je nach ausgewähltem Tool die passende Implementierung vorhält.
  private featureGenerator: FeatureGenerator | null = null;
  // True, wenn aktuell ein Werkzeug genutzt wird
  private toolActive = false;
  private map: Map | null = null;
  private selectedTool: Tool | null = null;
  private toolSettings: ToolSettings | null = null;
  private mapLocked = false;

  private readonly RIGHT_MOUSE_BUTTON = 2;

  constructor(
    toolService: ToolService,
    layerService: LayerService,
    private pointGenerator: PointGenerator,
    private lineGenerator: LineGenerator,
    private rectangleGenerator: RectangleGenerator,
    private circleGenerator: CircleGenerator,
    private polygonGenerator: PolygonGenerator,
    private pingGenerator: PingGenerator,
    private textGenerator: TextGenerator,
    private distanceGenerator: DistanceGenerator
  ) {
    toolService.selectedTool$.pipe(takeUntilDestroyed()).subscribe((selectedTool) => {
      this.selectedTool = selectedTool;
      // Implementierung des FeatureGenerators aktualisieren, wenn sich das ausgewählte Tool ändert.
      this.updateFeatureGenerator(selectedTool.type);
    });

    toolService.toolSettings$.pipe(takeUntilDestroyed()).subscribe((toolSettings) => {
      this.toolSettings = toolSettings;
    });

    layerService.mapLocked$.pipe(takeUntilDestroyed()).subscribe((mapLocked) => (this.mapLocked = mapLocked));
  }

  /**
   * Setzt den allgemeinen FeatureGenerator auf die zum ToolType passende Implementierung.
   */
  updateFeatureGenerator(toolType: ToolType) {
    switch (toolType) {
      case ToolType.Point:
        this.featureGenerator = this.pointGenerator;
        break;
      case ToolType.Line:
        this.featureGenerator = this.lineGenerator;
        break;
      case ToolType.Rectangle:
        this.featureGenerator = this.rectangleGenerator;
        break;
      case ToolType.Circle:
        this.featureGenerator = this.circleGenerator;
        break;
      case ToolType.Polygon:
        this.featureGenerator = this.polygonGenerator;
        break;
      case ToolType.Ping:
        this.featureGenerator = this.pingGenerator;
        break;
      case ToolType.Text:
        this.featureGenerator = this.textGenerator;
        break;
      case ToolType.Distance:
        this.featureGenerator = this.distanceGenerator;
        break;
    }
  }

  init(map: Map): void {
    this.map = map;
    this.pointGenerator.init(map);
    this.lineGenerator.init(map);
    this.rectangleGenerator.init(map);
    this.circleGenerator.init(map);
    this.polygonGenerator.init(map);
    this.pingGenerator.init(map);
    this.textGenerator.init(map);
    this.distanceGenerator.init(map);
  }

  /**
   * Startet die Erzeugung eines neuen GeoJSON-Features und deaktiviert die Karten-Steuerung.
   */
  createFeature(event: LeafletMouseEvent): void {
    if (
      this.selectedTool?.type !== ToolType.Cursor &&
      this.selectedTool?.type !== ToolType.Remove &&
      !(event.originalEvent.button == this.RIGHT_MOUSE_BUTTON)
    ) {
      if (!this.toolSettings) {
        return;
      }

      this.featureGenerator?.createFeature(event, this.toolSettings);
      this.toolActive = true;
      this.map?.dragging.disable();
      this.map?.scrollWheelZoom.disable();
    }
  }

  /**
   * Aktualisiert das aktuell bearbeitete GeoJSON-Feature.
   */
  updateFeature(event: LeafletMouseEvent): void {
    switch (this.selectedTool?.type) {
      case ToolType.Line:
      case ToolType.Distance:
      case ToolType.Polygon:
        this.featureGenerator?.updateFeature(event);
        break;
      case ToolType.Point:
      case ToolType.Ping:
      case ToolType.Rectangle:
      case ToolType.Circle:
      case ToolType.Text:
        if (this.toolActive) {
          this.featureGenerator?.updateFeature(event);
        }
        break;
    }
  }

  /**
   * Erweitert das aktuell bearbeitete GeoJSON-Feature um zusätzliche Werte.
   */
  extendFeature(event: LeafletMouseEvent): void {
    if (this.toolActive && event.originalEvent.button != this.RIGHT_MOUSE_BUTTON) {
      this.featureGenerator?.extendFeature(event);
    }
  }

  /**
   * Finalisiert das aktuell bearbeitete GeoJSON-Feature und gibt die Karten-Steuerung ggf. wieder frei.
   */
  finalizeFeature(event: LeafletMouseEvent): Feature<Geometry, GeoJsonProperties> | null {
    this.toolActive = false;
    if (!this.mapLocked) {
      this.map?.dragging.enable();
      this.map?.scrollWheelZoom.enable();
    }

    if (!this.toolSettings) {
      return null;
    }

    return this.featureGenerator?.finalizeFeature(event, this.toolSettings) || null;
  }

  /**
   * Zusätzliche Implementierungsdetails für Features, die auf Markern basieren.
   * Je nach Feature-Typ werden spezielle Informationen aus den GeoJSON-Feature-Properties verwendet.
   */
  public static extraMarkerOptions: L.GeoJSONOptions = {
    pointToLayer: (feature: GeoJSON.Feature, latlng: L.LatLng) => {
      if (!feature.properties) {
        return marker(latlng);
      }

      const featureStyle = feature.properties['style'];
      switch (feature.properties['shape']) {
        case 'Point':
          return marker(latlng, { icon: PointGenerator.getPinDivIcon(featureStyle.color) });
        case 'Circle':
          return circle(latlng, feature.properties['radius']);
        case 'Ping':
          return marker(latlng, {
            icon: PingGenerator.getPingDivIcon(featureStyle.color),
          });
        case 'Text': {
          const featureText = feature.properties['text'];
          const textIcon = divIcon({ className: 'tool-text', html: featureText });
          return marker(latlng, {
            icon: textIcon,
          });
        }
      }
      return marker(latlng);
    },
  };
}
