<mat-card>
  <!-- Header-Zeile mit Auswahl-Menü für TileLayer -->
  <mat-toolbar class="header">
    <h2>Kartenlayer</h2>
    <span class="buttons">
      <mat-button-toggle
        (click)="hierarchieService.toggleMapVisibilityAuto()"
        [checked]="hierarchieService.mapVisibilityAuto$ | async"
        matTooltip="Automatische Layersichtbarkeit"
      >
        <mat-icon>spellcheck</mat-icon>
      </mat-button-toggle>
      <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Tilelayer einstellen">
        <mat-icon>map</mat-icon>
      </button>
    </span>
    <mat-menu #menu="matMenu">
      <a
        mat-menu-item
        *ngFor="let baseLayerConfig of layerConfigService.baseLayerConfigs$ | async"
        (click)="layerConfigService.selectBaseLayerConfig(baseLayerConfig)"
        [ngClass]="{ 'selected-list-row': selectedBaseLayerConfig?.id === baseLayerConfig.id }"
        (click)="dontClose($event)"
        >{{ baseLayerConfig.name }}</a
      >

      <mat-checkbox
        *ngFor="let overlayConfig of layerConfigService.overlayConfigs$ | async"
        [checked]="selectedOverlayConfigs.includes(overlayConfig)"
        (change)="toggleOverlayConfig(overlayConfig)"
        (click)="dontClose($event)"
      >
        {{ overlayConfig.name }}
      </mat-checkbox>
    </mat-menu>
  </mat-toolbar>

  <div class="tree-wrapper">
    <!-- Tree mit Führungsebene-Hierarchie -->
    <mat-tree class="example-tree m-05" [dataSource]="dataSource" [treeControl]="treeControl" [trackBy]="trackBy">
      <!-- "Blatt" -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <app-fuehrungsebene-hierarchy-tree-item class="tree-node-row" [node]="node" />
      </mat-tree-node>

      <!-- Knoten -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChildren">
        <div class="tree-node-row">
          <mat-icon class="toggle-icon" matTreeNodeToggle>
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
          <app-fuehrungsebene-hierarchy-tree-item [node]="node" />
        </div>

        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</mat-card>
