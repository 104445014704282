import { createSelector } from '@ngrx/store';
import { AppStateInterface, anlaesse } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[anlaesse];

export const anlaesseSelector = createSelector(selectFeature, (state) => state.anlaesse);
export const anlaesseErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingAnlaesseSelector = createSelector(selectFeature, (state) => state.isLoading);
export const anlaesseLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const anlaesseOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.anlaesse.filter((anlass) => anlass.fuehrungsebeneId === fuehrungsebeneId)
  );
