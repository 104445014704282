import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { IseSourceIdService } from './ise-source-id.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService, private sourceIdService: IseSourceIdService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // TODO check URL!!!!!

    let headers = request.headers.set('X-Requested-With', 'JavaScript');

    const sourceId = this.sourceIdService.getIseSourceId();
    if (sourceId) {
      headers = headers.set('ise-source-id', sourceId);
    }

    const req = request.clone({ headers });
    return next.handle(req).pipe(catchError((resp) => this.handleError(resp)));
  }

  handleError(resp: HttpErrorResponse) {
    if (resp.status === 401 || resp.status === 403 || resp.status === 499) {
      this.router.navigate(['/login']);
    }

    return throwError(() => resp);
  }
}
