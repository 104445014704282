import { Injectable } from '@angular/core';
import { LatLng, LeafletMouseEvent, Map, Polyline, popup } from 'leaflet';
import { Format } from 'src/app/shared/format';
import { FeatureGenerator } from './tool-handler';

/**
 * Generator für Distanz-Messung auf einer Leaflet-Karte über den ToolHandler.
 * Erstellt zusätzlich ein Popup, das die aktuelle Distanz anzeigt.
 */
@Injectable({
  providedIn: 'root',
})
export class DistanceGenerator implements FeatureGenerator {
  private toolStartCoords: LatLng = new LatLng(0, 0);
  private toolLine: Polyline | null = null;
  private infoPopup: L.Popup | null = null;
  private map: Map | null = null;

  init(map: Map): void {
    this.map = map;
  }

  createFeature(event: LeafletMouseEvent): void {
    this.toolStartCoords = event.latlng;
    if (!this.toolLine) {
      this.toolLine = new Polyline([this.toolStartCoords, this.toolStartCoords], {
        color: 'black',
        dashArray: [10, 5],
      });
      this.map?.addLayer(this.toolLine);

      this.infoPopup = popup({
        closeButton: false,
        closeOnClick: false,
      })
        .setLatLng(this.toolStartCoords)
        .setContent('Distanz: 0 m');
      this.map?.openPopup(this.infoPopup);
    }
  }

  updateFeature(event: LeafletMouseEvent): void {
    if (this.toolLine) {
      const latLngs = this.toolLine.getLatLngs();
      latLngs[latLngs.length - 1] = event.latlng;
      this.toolLine.setLatLngs(latLngs);
      this.updateDistance(this.toolLine.getLatLngs() as L.LatLng[]);
    }
  }

  extendFeature(event: LeafletMouseEvent): void {
    this.toolLine?.addLatLng(event.latlng);
  }

  finalizeFeature(event: LeafletMouseEvent): GeoJSON.Feature | null {
    if (!this.toolLine) {
      return null;
    }
    this.map?.removeLayer(this.toolLine);
    this.toolLine = null;

    if (!this.infoPopup) {
      return null;
    }
    this.map?.removeLayer(this.infoPopup);
    this.infoPopup = null;
    return null;
  }

  /**
   * Berechnet die Distanz zwischen allen übergebenen Punkten und aktualisiert das Popup.
   */
  updateDistance(coordinates: L.LatLng[]) {
    if (!this.map) {
      return;
    }

    let distance = 0;
    for (let i = 0; i < coordinates.length - 1; i++) {
      distance += this.map.distance(coordinates[i], coordinates[i + 1]);
    }
    this.infoPopup?.setContent('Distanz: ' + Format.formatDistance(distance));
  }
}
