import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TaktischesZeichenData } from './taktischeZeichenState.interface';

export const TaktischeZeichenActions = createActionGroup({
  source: 'Taktische Zeichen',
  events: {
    'Save Taktisches Zeichen': props<{ taktischesZeichenData: TaktischesZeichenData }>(),
    'Update Taktische Zeichen': emptyProps(),
  },
});
