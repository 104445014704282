<form [formGroup]="formGroup">
  <mat-card>
    <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />

    <mat-form-field
      (mouseover)="gebietstypMouseover = true"
      (mouseleave)="gebietstypMouseover = false"
      class="less-margin"
    >
      <mat-label>Art des Gebiets</mat-label>
      <mat-select [formControl]="fcGebietstyp">
        <mat-option [value]="Gebietstyp.Flaechenbrand">Flächenbrand</mat-option>
        <mat-option [value]="Gebietstyp.Ueberschwemmung">Überschwemmung</mat-option>
        <mat-option [value]="Gebietstyp.Duerre">Dürre</mat-option>
        <mat-option [value]="Gebietstyp.Ausfall">Einschränkung/Ausfall der Versorgung</mat-option>
        <mat-option [value]="Gebietstyp.Kontamination">Kontamination</mat-option>
        <mat-option [value]="Gebietstyp.Sonstiges">Sonstiges</mat-option>
      </mat-select>

      <button
        *ngIf="fcGebietstyp.value && gebietstypMouseover"
        matSuffix
        mat-icon-button
        (click)="fcGebietstyp.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field
      (mouseover)="gebietsauspraegungMouseover = true"
      (mouseleave)="gebietsauspraegungMouseover = false"
      *ngIf="fcGebietstyp.value"
      class="less-margin"
    >
      <mat-label>Ausprägung des Gebiets</mat-label>
      <mat-select [formControl]="fcGebietsauspraegung">
        <mat-option [value]="Gebietsauspraegung.Drohend">Drohende Gefahr</mat-option>
        <mat-option [value]="Gebietsauspraegung.Akut">Akute Gefahr</mat-option>
        <mat-option [value]="Gebietsauspraegung.Ehemals">Noch/Ehemals betroffen</mat-option>
      </mat-select>

      <button
        *ngIf="fcGebietsauspraegung.value && gebietsauspraegungMouseover"
        matSuffix
        mat-icon-button
        (click)="fcGebietsauspraegung.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <app-cleanable-form-field label="Ortsangabe" [formGroup]="formGroup" formControlName="ortsangabe" />
  </mat-card>
</form>
