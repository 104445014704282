<mat-card class="main" *ngIf="fuehrungsebeneDTO" [style.backgroundColor]="fuehrungsebenentypSettings?.color">
  <!-- Titel-Leiste, die nur beim Hovern erscheint -->
  <mat-toolbar class="fuehrungsebene-header">
    <app-fuehrungsebene-icon-and-text [fuehrungsebeneDTO]="fuehrungsebeneDTO" class="icon-and-name" />

    <span class="buttons">
      <!-- Button: In Führungsebene springen -->
      <button
        mat-icon-button
        (click)="navigateToFuehrungsebene()"
        *ngIf="allowNavigation"
        [matTooltip]="'In ' + fuehrungsebenentypSettings?.longname + ' springen'"
      >
        <mat-icon>south_east</mat-icon>
      </button>

      <!-- Button: Führungsebene fokussieren -->
      <button
        mat-icon-button
        (click)="focusFuehrungsebene()"
        *ngIf="allowFocus"
        [matTooltip]="fuehrungsebenentypSettings?.longname + ' fokussieren'"
      >
        <mat-icon>center_focus_strong</mat-icon>
      </button>

      <!-- Button: Führungsebene verschieben -->
      <button
        mat-icon-button
        (click)="moveFuehrungsebene()"
        *ngIf="allowMove"
        [matTooltip]="fuehrungsebenentypSettings?.longname + ' verschieben'"
      >
        <mat-icon>move_down</mat-icon>
      </button>

      <!-- Button: Führungsebene bearbeiten -->
      <button mat-icon-button (click)="edit.emit()" [matTooltip]="fuehrungsebenentypSettings?.longname + ' bearbeiten'">
        <mat-icon>edit</mat-icon>
      </button>
    </span>
  </mat-toolbar>

  <div class="content">
    <!-- Spalte 1, speziell für Logistik -->
    <div
      class="column-1 logistik"
      *ngIf="
        fuehrungsebeneDTO.typ === Fuehrungsebenentyp.LogistikEinsatzabschnitt ||
        fuehrungsebeneDTO.typ === Fuehrungsebenentyp.LogistikEinsatzstelle
      "
    >
      <mat-card class="name">
        {{ fuehrungsebeneDTO.name }}
      </mat-card>
      <img src="assets/svg/tz/logistik.svg" />
    </div>

    <!-- Spalte 1 -->
    <div
      class="column-1"
      *ngIf="
        fuehrungsebeneDTO.typ !== Fuehrungsebenentyp.LogistikEinsatzabschnitt &&
        fuehrungsebeneDTO.typ !== Fuehrungsebenentyp.LogistikEinsatzstelle
      "
    >
      <mat-card class="br-short" *ngIf="fuehrungsebenentypSettings?.shortname">{{
        fuehrungsebenentypSettings?.shortname
      }}</mat-card>
      <mat-card class="br-long" *ngIf="fuehrungsebenentypSettings?.subtitle">{{
        fuehrungsebenentypSettings?.subtitle
      }}</mat-card>
      <mat-card class="el" *ngIf="fuehrungsebenentypSettings?.middle">{{
        fuehrungsebenentypSettings?.middle
      }}</mat-card>
      <mat-card class="name" *ngIf="fuehrungsebeneDTO.name">{{ fuehrungsebeneDTO.name }}</mat-card>
    </div>

    <!-- Spalte 2 -->
    <mat-card class="column-2">
      <table>
        <tr>
          <td>Meldek.:</td>
          <td>{{ fuehrungsebeneDTO.adresse }}</td>
        </tr>
        <tr>
          <td>Leiter:</td>
          <td>
            {{ fuehrungsebeneDTO.leiterName }},{{ fuehrungsebeneDTO.leiterDienstgrad }},
            {{ fuehrungsebeneDTO.leiterFunkrufname }}
          </td>
        </tr>
        <tr>
          <td>Komm.:</td>
          <!-- Zeile mit Icons für alle Kommunikationsmöglichkeiten und Tooltips mit weiteren Informationen -->
          <td>
            <app-kontakt-icons
              *ngIf="fuehrungsebeneDTO.kommunikationOptionen"
              [kontaktmoeglichkeiten]="fuehrungsebeneDTO.kommunikationOptionen"
            />
          </td>
        </tr>
        <tr>
          <td>Aufträge:</td>
          <td>
            <app-auftrag-header-element [auftraege]="fuehrungsebeneDTO.auftraege || []"></app-auftrag-header-element>
          </td>
        </tr>
      </table>
    </mat-card>

    <!-- Spalte 3 -->
    <div class="column-3">
      <mat-card class="einsatzbeginn">
        <h5>Einsatzbeginn</h5>
        <span class="started-on">{{ fuehrungsebeneDTO.startedOn | dateTime | async }}</span>
      </mat-card>

      <mat-card class="kraefteuebersicht">
        <h5>Kräfteübersicht</h5>
        <div class="kraefteuebersicht-content">
          <div class="mannschaft">
            <p>Mannschaft</p>
            <p>
              {{ fuehrungsebeneDTO.mannschaftsstaerke1 }} / {{ fuehrungsebeneDTO.mannschaftsstaerke2 }} /
              {{ fuehrungsebeneDTO.mannschaftsstaerke3 }} =
              {{ fuehrungsebeneDTO.mannschaftsstaerkeSumme }}
            </p>
          </div>
          <div class="fahrzeuge">
            <p>Fahrzeuge</p>
            <p>{{ fuehrungsebeneDTO.anzahlFahrzeuge }}</p>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</mat-card>
