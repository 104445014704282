import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { FuehrungsebeneDTO, TierschadenDTO } from 'src/app/api/build/openapi';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { TaktischesZeichenPopup } from '../../taktische-zeichen.interface';
import { TzPopupToolbarComponent } from '../../tz-popup-toolbar/tz-popup-toolbar.component';
import { TierschadenSubset } from '../tierschaden-subset.interface';

/**
 * Kleine Komponente zur Übersicht von Personen verschiedener Zustände.
 * Personenanzahl wird nur angezeigt und kann nicht vom Benutzer editiert werden.
 */
@Component({
  selector: 'app-tierschaden-popup',
  templateUrl: './tierschaden-popup.component.html',
  styleUrls: ['./tierschaden-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, MatCardModule, FuehrungsebeneIconAndTextComponent, TzPopupToolbarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TierschadenPopupComponent implements TaktischesZeichenPopup, OnInit {
  @Input({ required: true }) dto!: TierschadenDTO;

  private fuehrungsebeneService = inject(FuehrungsebeneService);

  protected parentFuehrungsebene?: FuehrungsebeneDTO;
  protected tierschadenSubset: TierschadenSubset = {
    betroffen: -1,
    tot: -1,
    verletzt: -1,
  };

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.dto.fuehrungsebeneId);

    this.tierschadenSubset = {
      betroffen: this.dto.ekBetroffen + this.dto.tierBetroffen,
      tot: this.dto.ekTot + this.dto.tierTot,
      verletzt: this.dto.ekVerletzt + this.dto.tierVerletzt,
    };
  }
}
