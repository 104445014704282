/**
 *  'id' und 'version' wurden nur als Optional definiert, um das Handling mit DTOs zu vereinfachen.
 */
interface IdAndVersion {
  id?: string | number;
  version?: number;
}

/**
 *
 * Ersetzt einen Eintrag in einem Array, wenn der gegebene Wert neuer ist. Ist der neue Eintrag unbekannt, wo wird er dem Array hinzugefügt.
 *
 *
 * @param currentArray Array in dem der Eintrag hinzugefügt bzw. ersetzt werden soll.
 * @param toAddOrReplace das Objekt, das im Array in der aktuellsten Version enthalten sein soll.
 *
 * @returns ein neues angepasstes Array
 */
export const addOrReplaceWithLatest = <T extends IdAndVersion>(currentArray: T[], toAddOrReplace: T): T[] => {
  // Es wird angenommen, dass die bekannten Einträge im 'currentArray' eine ID haben.

  if (toAddOrReplace.id === undefined) {
    throw new Error("toAddOrReplace MUST have an 'id'");
  }

  return currentArray.some((entry) => entry.id === toAddOrReplace.id)
    ? currentArray.map((entry) => (entry.id === toAddOrReplace.id ? getNewest(entry, toAddOrReplace) : entry))
    : [...currentArray, toAddOrReplace];
};

/**
 * Ersetzt einen Eintrag in einem Array, wenn der gegebene Wert neuer ist.
 *
 * @param currentArray Array in dem `replacement` ersetzt werden soll
 * @param replacement das Objekt, was im Array ersetzt werden soll. Gibt es bereits eine neuer Version oder ist es nicht vorhanden, wird es ignoriert.
 *
 * @returns das ggf. aktualisierte Array
 */
export const replaceOrKeepCurrent = <T extends IdAndVersion>(currentArray: T[], replacement: T): T[] => {
  if (replacement.id === undefined) {
    throw new Error("replacement MUST have an 'id'");
  }

  return currentArray.map((entry) => (entry.id === replacement.id ? getNewest(entry, replacement) : entry));
};

/**
 *
 *
 * @param current das Objekt, für das geprüft werden soll, dass es die kleinere oder gleiche Version hat.
 * @param supposedlyNewest das Objekt, von dem angenommen wird, dass es das aktuellste ist.
 *
 * @warn beide Objekte MÜSEEN den parameter `version` haben!
 *
 * @returns den aktuelleren der beiden Parameter
 *
 * aktueller == version ist größer
 */
export const getNewest = <T extends IdAndVersion>(current: T, supposedlyNewest: T): T => {
  if (current.version === undefined || supposedlyNewest.version === undefined) {
    throw new Error("current and supposedlyNewest MUST have a 'version'");
  }

  return supposedlyNewest.version >= current.version ? supposedlyNewest : current;
};
