import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Observable, of } from 'rxjs';
import { EinsatzDTO } from 'src/app/api/build/openapi';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { EinsatzDialogComponent, EinsatzDialogInputData } from '../einsatz-dialog/einsatz-dialog.component';

/**
 * Liste aus übergebenen Einsätzen.
 */
@Component({
  selector: 'app-einsatz-list',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    DateTimePipe,
  ],
  templateUrl: './einsatz-list.component.html',
  styleUrls: ['./einsatz-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinsatzListComponent {
  @Input()
  einsatzDTOs$: Observable<EinsatzDTO[]> = of([]);

  @Input()
  title = 'Einsätze';

  @Output()
  refreshClicked = new EventEmitter();

  private dialog = inject(MatDialog);

  readonly displayedColumns: string[] = [
    'einsatznummer',
    'einsatzstatus',
    'einsatzeroeffnung',
    'einsatzart1',
    'einsatzstichwort1',
    'einsatzart2',
    'einsatzstichwort2',
    'meldebild',
    'einsatzort',
  ];

  openEinsatzDialog(einsatzDto: EinsatzDTO) {
    const inputData: EinsatzDialogInputData = {
      einsatzDTO: einsatzDto,
    };
    this.dialog.open(EinsatzDialogComponent, { data: inputData });
  }
}
