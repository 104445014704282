import { createSelector } from '@ngrx/store';
import { AppStateInterface, gebaeude } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[gebaeude];

export const gebaeudeSelector = createSelector(selectFeature, (state) => state.gebaeude);
export const gebaeudeErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingGebaeudeSelector = createSelector(selectFeature, (state) => state.isLoading);
export const gebaeudeLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const gebaeudeOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.gebaeude.filter((gebaeude) => gebaeude.fuehrungsebeneId === fuehrungsebeneId)
  );

export const gebaeudeByIdSelector = (gebaeudeId?: string) =>
  createSelector(selectFeature, (state) => state.gebaeude.find((gebaeude) => gebaeude.id === gebaeudeId));
