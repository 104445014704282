import { createActionGroup, props } from '@ngrx/store';
import { ErrorResponse, MeldungDTO } from 'src/app/api/build/openapi';

/* Aktionen, die für einen State ausgelöst werden können */
export const meldungActions = createActionGroup({
  source: 'Meldungen',
  events: {
    'Get Meldungen': props<{ lageId: string }>(),
    'Get Meldungen Success': props<{ meldungen: MeldungDTO[] }>(),
    'Get Meldungen Failure': props<{ errorResponse: ErrorResponse }>(),

    'Set Current Meldung': props<{ currentMeldung: MeldungDTO | null }>(),

    'Create Meldung': props<{ lageId: string; copy: boolean }>(),
    'Create Meldung Success': props<{ newMeldung: MeldungDTO }>(),
    'Create Meldung Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Meldung': props<{ patchedMeldung: MeldungDTO }>(),
    'Patch Meldung Success': props<{ patchedMeldung: MeldungDTO }>(),
    'Patch Meldung Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Meldung': props<{ meldung: MeldungDTO }>(),
    'Delete Meldung Success': props<{ deletedMeldungId: string }>(),
    'Delete Meldung Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
