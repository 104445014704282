import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { JsMsg, MsgHdrs } from 'nats.ws';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  AnlassDTO,
  BefehlsstelleDTO,
  BibFahrzeugDTO,
  BibPersonDTO,
  BibTaktischeFormationDTO,
  EinsatzfilterDTO,
  EinsatztagebucheintragDTO,
  FahrzeugDTO,
  FotoDTO,
  FuehrungsebeneDTO,
  FuehrungsebeneMassnahmeDTO,
  GebaeudeDTO,
  GebietDTO,
  GefahrDTO,
  LageDTO,
  LageMassnahmeDTO,
  LagebesprechungDTO,
  MapLayerConfig,
  MeldungDTO,
  PersonDTO,
  PersonenschadenDTO,
  StelleDTO,
  TaktischeFormationDTO,
  TierschadenDTO,
  VerwaltungsebeneDeletedDTO,
  VerwaltungsebenenHierarchyDTO,
  WetterDTO,
} from 'src/app/api/build/openapi';
import { IseSourceIdService } from 'src/app/auth/ise-source-id.service';
import { einsatzfilterActions } from 'src/app/einsatz/+state/einsatzfilter.actions';
import { einsatztagebuchActions } from 'src/app/einsatztagebuch/+state/einsatztagebuch.actions';
import { fuehrungsebeneActions } from 'src/app/lagedarstellung/fuehrungsebene/+state/fuehrungsebene.actions';
import { LagebesprechungService } from 'src/app/lagedarstellung/lagebesprechung/lagebesprechung.service';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { lageMassnahmeActions } from 'src/app/lagedarstellung/massnahmen/+state/lage-massnahme.actions';
import { anlassActions } from 'src/app/lagedarstellung/taktische-zeichen/anlass-ereignis/+state/anlass.actions';
import { befehlsstelleActions } from 'src/app/lagedarstellung/taktische-zeichen/befehlsstelle/+state/befehlsstelle.actions';
import { fahrzeugActions } from 'src/app/lagedarstellung/taktische-zeichen/fahrzeuge/+state/fahrzeug.actions';
import { fotoActions } from 'src/app/lagedarstellung/taktische-zeichen/foto/+state/foto.actions';
import { gebaeudeActions } from 'src/app/lagedarstellung/taktische-zeichen/gebaeude/+state/gebaeude.actions';
import { gebietActions } from 'src/app/lagedarstellung/taktische-zeichen/gebiete/+state/gebiet.actions';
import { gefahrActions } from 'src/app/lagedarstellung/taktische-zeichen/gefahren/+state/gefahr.actions';
import { fuehrungsebeneMassnahmeActions } from 'src/app/lagedarstellung/taktische-zeichen/massnahmen/+state/fuehrungsebene-massnahme.actions';
import { personActions } from 'src/app/lagedarstellung/taktische-zeichen/personen/+state/person.actions';
import { personenschadenActions } from 'src/app/lagedarstellung/taktische-zeichen/personenschaden/+state/personenschaden.actions';
import { stelleActions } from 'src/app/lagedarstellung/taktische-zeichen/stelle-einrichtung/+state/stelle.actions';
import { taktischeFormationActions } from 'src/app/lagedarstellung/taktische-zeichen/taktische-formation/+state/taktische-formation.actions';
import { tierschadenActions } from 'src/app/lagedarstellung/taktische-zeichen/tierschaden/+state/tierschaden.actions';
import { wetterActions } from 'src/app/lagedarstellung/wetter/+state/wetter.actions';
import { meldungActions } from 'src/app/meldungen/+state/meldung.actions';
import { bibliothekActions } from 'src/app/planung/bibliothek/+state/bibliothek.actions';
import { mapLayerConfigActions } from 'src/app/planung/karte/+state/map-layer-config.actions';
import { LogService } from '../log.service';
import {
  METHOD_CREATED,
  METHOD_DELETED,
  METHOD_UPDATED,
  SCHEMA_BIB_TZ_FAHRZEUG,
  SCHEMA_BIB_TZ_PERSON,
  SCHEMA_BIB_TZ_TAKTISCHE_FORMATION,
  SCHEMA_EINSATZFILTER,
  SCHEMA_EINSATZTAGEBUCH,
  SCHEMA_FUEHRUNGSEBENE,
  SCHEMA_LAGE,
  SCHEMA_LAGEBESPRECHUNG,
  SCHEMA_LAGE_MASSNAMHMEN,
  SCHEMA_MAP_LAYER_CONFIG,
  SCHEMA_MELDUNG,
  SCHEMA_TZ_ANLASS,
  SCHEMA_TZ_BEFEHLSSTELLE,
  SCHEMA_TZ_FAHRZEUG,
  SCHEMA_TZ_FOTO,
  SCHEMA_TZ_FUEHRUNGSEBENE_MASSNAHME,
  SCHEMA_TZ_GEBAUDE,
  SCHEMA_TZ_GEBIET,
  SCHEMA_TZ_GEFAHR,
  SCHEMA_TZ_PERSON,
  SCHEMA_TZ_PERSONENSCHADEN,
  SCHEMA_TZ_STELLE,
  SCHEMA_TZ_TAKTISCHE_FORMATION,
  SCHEMA_TZ_TIERSCHADEN,
  SCHEMA_VERWALTUNGSEBENEN_HIERARCHIE,
  SCHEMA_VERWALTUNGSEBENE_DELETED,
  SCHEMA_WETTER,
} from './headers';
import { getHeaderMap, getIseSourceId, getMethod, getSchema, logUnkownEvent } from './helpers';

@Injectable({
  providedIn: 'root',
})
export class NatsEventHandlerService {
  constructor(
    private store: Store<AppStateInterface>,
    private sourceIdService: IseSourceIdService,
    private logService: LogService,
    private lagebesprechungService: LagebesprechungService
  ) {}

  handle = (m: JsMsg) => {
    const headers = m.headers;
    if (headers) {
      if (getIseSourceId(headers) === 'ignored') {
        this.logService.devWarn('sourceId == ignored', m.subject, getHeaderMap(m.headers), m.json());
      }

      if (!this.isRelevant(headers)) {
        this.logService.devInfo('skip irrelevant event - subject', m.subject, getHeaderMap(m.headers), m.json());
        // unrelevantes Event bestätigen, damit nicht erneut übermittelt.
        m.ack();
        return;
      }

      this.logService.devInfo('handle event - subject', m.subject, getHeaderMap(m.headers), m.json());

      switch (getSchema(headers)) {
        /* Allgemein */
        case SCHEMA_FUEHRUNGSEBENE:
          this.handleFuehrungsebene(m);
          break;
        case SCHEMA_EINSATZTAGEBUCH:
          this.handleEinsatztagebuch(m);
          break;
        case SCHEMA_LAGE:
          this.handleLage(m);
          break;
        case SCHEMA_LAGEBESPRECHUNG:
          this.handleLagebesprechung(m);
          break;
        case SCHEMA_LAGE_MASSNAMHMEN:
          this.handleLageMassnahme(m);
          break;
        case SCHEMA_MELDUNG:
          this.handleMeldung(m);
          break;
        case SCHEMA_WETTER:
          this.handleWetter(m);
          break;
        /* Taktische Zeichen */
        case SCHEMA_TZ_FUEHRUNGSEBENE_MASSNAHME:
          this.handleFuehrungsebeneMassnahme(m);
          break;
        case SCHEMA_TZ_ANLASS:
          this.handleAnlass(m);
          break;
        case SCHEMA_TZ_BEFEHLSSTELLE:
          this.handleBefehlsstelle(m);
          break;
        case SCHEMA_TZ_FAHRZEUG:
          this.handleFahrzeug(m);
          break;
        case SCHEMA_TZ_FOTO:
          this.handleFoto(m);
          break;
        case SCHEMA_TZ_GEBAUDE:
          this.handleGebaeude(m);
          break;
        case SCHEMA_TZ_GEBIET:
          this.handleGebiet(m);
          break;
        case SCHEMA_TZ_GEFAHR:
          this.handleGefahr(m);
          break;
        case SCHEMA_TZ_PERSON:
          this.handlePerson(m);
          break;
        case SCHEMA_TZ_PERSONENSCHADEN:
          this.handlePersonenschaden(m);
          break;
        case SCHEMA_TZ_STELLE:
          this.handleStelle(m);
          break;
        case SCHEMA_TZ_TAKTISCHE_FORMATION:
          this.handleTaktischeFormation(m);
          break;
        case SCHEMA_TZ_TIERSCHADEN:
          this.handleTierschaden(m);
          break;
        /* Bibliothek */
        case SCHEMA_VERWALTUNGSEBENE_DELETED:
          this.handleVerwaltungsebeneDeleted(m);
          break;
        case SCHEMA_VERWALTUNGSEBENEN_HIERARCHIE:
          this.handleVerwaltungsebenenHierarchie(m);
          break;
        case SCHEMA_MAP_LAYER_CONFIG:
          this.handleMapLayerConfig(m);
          break;
        case SCHEMA_BIB_TZ_FAHRZEUG:
          this.handleBibFahrzeug(m);
          break;
        case SCHEMA_BIB_TZ_PERSON:
          this.handleBibPerson(m);
          break;
        case SCHEMA_BIB_TZ_TAKTISCHE_FORMATION:
          this.handleBibTaktischeFormation(m);
          break;
        case SCHEMA_EINSATZFILTER:
          this.handleEinsatzfilter(m);
          break;
        default:
          logUnkownEvent(m);
          break;
      }
    }

    // TODO sicherstellen, dass Event auch verarbeitet wurde?
    m.ack();
  };

  /**
   * Prüft, ob die Header die eigene SourceID enthält.
   *
   * Eigene Source-ID = nicht relevant (selbst ausgelöst)
   * Unbekannte / keine Source-ID = relevant
   *
   * @param headers
   * @returns
   */
  isRelevant = (headers: MsgHdrs) => {
    return getIseSourceId(headers) !== this.sourceIdService.getIseSourceId();
  };

  /*
  ######################
  Lage
  ######################
  */

  private handleLage(message: JsMsg) {
    this.handler(
      message,
      () => this.store.dispatch(lageActions.addLage({ newLage: message.json() as LageDTO })),
      () => this.store.dispatch(lageActions.patchLageSuccess({ patchedLage: message.json() as LageDTO }))
    );
  }

  /*
  ######################
  Lagebesprechung
  ######################
  */

  private handleLagebesprechung(message: JsMsg) {
    const handle = () => {
      this.lagebesprechungService.updateLagebesprechung(message.json() as LagebesprechungDTO);
    };

    this.handler(message, handle, handle);
  }

  /*
  ######################
  Lage Massnahme
  ######################
  */

  private handleLageMassnahme(message: JsMsg) {
    this.handler(
      message,
      () =>
        this.store.dispatch(
          lageMassnahmeActions.addLageMassnahmeSuccess({ massnahme: message.json() as LageMassnahmeDTO })
        ),
      () =>
        this.store.dispatch(
          lageMassnahmeActions.patchLageMassnahmeSuccess({ patchedMassnahme: message.json() as LageMassnahmeDTO })
        )
    );
  }

  /*
  ######################
  Taktische Zeichen
  ######################
  */

  private handleAnlass(message: JsMsg) {
    const anlassDTO = message.json() as AnlassDTO;
    this.handler(
      message,
      () => this.store.dispatch(anlassActions.createAnlassSuccess({ createdAnlassDTO: anlassDTO })),
      () => this.store.dispatch(anlassActions.patchAnlassSuccess({ patchedAnlassDTO: anlassDTO })),
      () => this.store.dispatch(anlassActions.deleteAnlassSuccess({ deletedAnlassDTO: anlassDTO }))
    );
  }

  private handleBefehlsstelle(message: JsMsg) {
    const befehlsstelleDTO = message.json() as BefehlsstelleDTO;
    this.handler(
      message,
      () =>
        this.store.dispatch(
          befehlsstelleActions.createBefehlsstelleSuccess({ createdBefehlsstelleDTO: befehlsstelleDTO })
        ),
      () =>
        this.store.dispatch(
          befehlsstelleActions.patchBefehlsstelleSuccess({ patchedBefehlsstelleDTO: befehlsstelleDTO })
        ),
      () =>
        this.store.dispatch(
          befehlsstelleActions.deleteBefehlsstelleSuccess({ deletedBefehlsstelleDTO: befehlsstelleDTO })
        )
    );
  }

  private handleFahrzeug(message: JsMsg) {
    const fahrzeugDTO = message.json() as FahrzeugDTO;
    this.handler(
      message,
      () => this.store.dispatch(fahrzeugActions.createFahrzeugSuccess({ newFahrzeug: fahrzeugDTO })),
      () => this.store.dispatch(fahrzeugActions.patchFahrzeugSuccess({ patchedFahrzeug: fahrzeugDTO })),
      () => this.store.dispatch(fahrzeugActions.deleteFahrzeugSuccess({ deletedFahrzeugDTO: fahrzeugDTO }))
    );
  }

  private handleFoto(message: JsMsg) {
    const fotoDTO = message.json() as FotoDTO;
    this.handler(
      message,
      () => this.store.dispatch(fotoActions.createFotoSuccess({ createdFotoDTO: fotoDTO })),
      () => this.store.dispatch(fotoActions.patchFotoSuccess({ patchedFotoDTO: fotoDTO })),
      () => this.store.dispatch(fotoActions.deleteFotoSuccess({ deletedFotoDTO: fotoDTO }))
    );
  }

  private handleGebaeude(message: JsMsg) {
    const gebaeudeDTO = message.json() as GebaeudeDTO;
    this.handler(
      message,
      () => this.store.dispatch(gebaeudeActions.createGebaeudeSuccess({ createdGebaeudeDTO: gebaeudeDTO })),
      () => this.store.dispatch(gebaeudeActions.patchGebaeudeSuccess({ patchedGebaeudeDTO: gebaeudeDTO })),
      () => this.store.dispatch(gebaeudeActions.deleteGebaeudeSuccess({ deletedGebaeudeDTO: gebaeudeDTO }))
    );
  }

  private handleGebiet(message: JsMsg) {
    const gebietDTO = message.json() as GebietDTO;
    this.handler(
      message,
      () => this.store.dispatch(gebietActions.createGebietSuccess({ createdGebietDTO: gebietDTO })),
      () => this.store.dispatch(gebietActions.patchGebietSuccess({ patchedGebietDTO: gebietDTO })),
      () => this.store.dispatch(gebietActions.deleteGebietSuccess({ deletedGebietDTO: gebietDTO }))
    );
  }

  private handleGefahr(message: JsMsg) {
    const gefahrDTO = message.json() as GefahrDTO;
    this.handler(
      message,
      () => this.store.dispatch(gefahrActions.createGefahrSuccess({ createdGefahrDTO: gefahrDTO })),
      () => this.store.dispatch(gefahrActions.patchGefahrSuccess({ patchedGefahrDTO: gefahrDTO })),
      () => this.store.dispatch(gefahrActions.deleteGefahrSuccess({ deletedGefahrDTO: gefahrDTO }))
    );
  }

  private handleFuehrungsebeneMassnahme(message: JsMsg) {
    const massnahmeDTO = message.json() as FuehrungsebeneMassnahmeDTO;
    this.handler(
      message,
      () =>
        this.store.dispatch(
          fuehrungsebeneMassnahmeActions.createFuehrungsebeneMassnahmeSuccess({
            createdFuehrungsebeneMassnahmeDTO: massnahmeDTO,
          })
        ),
      () =>
        this.store.dispatch(
          fuehrungsebeneMassnahmeActions.patchFuehrungsebeneMassnahmeSuccess({
            patchedFuehrungsebeneMassnahmeDTO: massnahmeDTO,
          })
        ),
      () =>
        this.store.dispatch(
          fuehrungsebeneMassnahmeActions.deleteFuehrungsebeneMassnahmeSuccess({
            deletedFuehrungsebeneMassnahmeDTO: massnahmeDTO,
          })
        )
    );
  }

  private handlePerson(message: JsMsg) {
    const personDTO = message.json() as PersonDTO;
    this.handler(
      message,
      () => this.store.dispatch(personActions.createPersonSuccess({ newPerson: personDTO })),
      () => this.store.dispatch(personActions.patchPersonSuccess({ patchedPerson: personDTO })),
      () => this.store.dispatch(personActions.deletePersonSuccess({ deletedPersonDTO: personDTO }))
    );
  }

  private handlePersonenschaden(message: JsMsg) {
    const personenschadenDTO = message.json() as PersonenschadenDTO;
    this.handler(
      message,
      () =>
        this.store.dispatch(
          personenschadenActions.createPersonenschadenSuccess({ createdPersonenschadenDTO: personenschadenDTO })
        ),
      () =>
        this.store.dispatch(
          personenschadenActions.patchPersonenschadenSuccess({ patchedPersonenschadenDTO: personenschadenDTO })
        ),
      () =>
        this.store.dispatch(
          personenschadenActions.deletePersonenschadenSuccess({ deletedPersonenschadenDTO: personenschadenDTO })
        )
    );
  }

  private handleStelle(message: JsMsg) {
    const stelleDTO = message.json() as StelleDTO;
    this.handler(
      message,
      () => this.store.dispatch(stelleActions.createStelleSuccess({ createdStelleDTO: stelleDTO })),
      () => this.store.dispatch(stelleActions.patchStelleSuccess({ patchedStelleDTO: stelleDTO })),
      () => this.store.dispatch(stelleActions.deleteStelleSuccess({ deletedStelleDTO: stelleDTO }))
    );
  }

  private handleTaktischeFormation(message: JsMsg) {
    const formationDTO = message.json() as TaktischeFormationDTO;
    this.handler(
      message,
      () =>
        this.store.dispatch(
          taktischeFormationActions.createTaktischeFormationSuccess({ createdTaktischeFormationDTO: formationDTO })
        ),
      () =>
        this.store.dispatch(
          taktischeFormationActions.patchTaktischeFormationSuccess({ patchedTaktischeFormationDTO: formationDTO })
        ),
      () =>
        this.store.dispatch(
          taktischeFormationActions.deleteTaktischeFormationSuccess({ deletedTaktischeFormationDTO: formationDTO })
        )
    );
  }

  private handleTierschaden(message: JsMsg) {
    const tierschadenDTO = message.json() as TierschadenDTO;
    this.handler(
      message,
      () => this.store.dispatch(tierschadenActions.createTierschadenSuccess({ createdTierschadenDTO: tierschadenDTO })),
      () => this.store.dispatch(tierschadenActions.patchTierschadenSuccess({ patchedTierschadenDTO: tierschadenDTO })),
      () => this.store.dispatch(tierschadenActions.deleteTierschadenSuccess({ deletedTierschadenDTO: tierschadenDTO }))
    );
  }

  /*
  ######################
  Wetter
  ######################
  */

  private handleWetter(message: JsMsg) {
    this.handler(
      message,
      () =>
        this.store.dispatch(
          wetterActions.createWetterSuccess({
            createdWetter: message.json() as WetterDTO,
          })
        ),
      () =>
        this.store.dispatch(
          wetterActions.patchWetterSuccess({
            patchedWetter: message.json() as WetterDTO,
          })
        )
    );
  }

  /*
  ######################
  Fuehrungsebene
  ######################
  */
  private handleFuehrungsebene(message: JsMsg) {
    const fuehrungsebeneDTO = message.json() as FuehrungsebeneDTO;
    this.handler(
      message,
      () =>
        this.store.dispatch(
          fuehrungsebeneActions.createFuehrungsebeneSuccess({ createdFuehrungsebene: fuehrungsebeneDTO })
        ),
      () =>
        this.store.dispatch(
          fuehrungsebeneActions.patchFuehrungsebeneSuccess({ patchedFuehrungsebene: fuehrungsebeneDTO })
        ),
      () =>
        this.store.dispatch(
          fuehrungsebeneActions.deleteFuehrungsebeneSuccess({ deletedFuehrungsebeneDTO: fuehrungsebeneDTO })
        )
    );
  }

  /*
  ######################
  Meldung
  ######################
  */
  private handleMeldung(message: JsMsg) {
    this.handler(
      message,
      () => this.store.dispatch(meldungActions.createMeldungSuccess({ newMeldung: message.json() as MeldungDTO })),
      () => this.store.dispatch(meldungActions.patchMeldungSuccess({ patchedMeldung: message.json() as MeldungDTO })),
      () => {
        const deletedMeldungId = (message.json() as MeldungDTO).id;
        if (deletedMeldungId) {
          this.store.dispatch(meldungActions.deleteMeldungSuccess({ deletedMeldungId: deletedMeldungId }));
        }
      }
    );
  }

  /**
   * Einsatztagebuch
   */
  private handleEinsatztagebuch(message: JsMsg) {
    this.handler(
      message,
      () =>
        this.store.dispatch(
          einsatztagebuchActions.createEinsatztagebucheintragSuccess({
            newEintrag: message.json() as EinsatztagebucheintragDTO,
          })
        ),
      () =>
        this.store.dispatch(
          einsatztagebuchActions.patchEinsatztagebucheintragSuccess({
            patchedEintrag: message.json() as EinsatztagebucheintragDTO,
          })
        )
    );
  }

  /*
  ######################
  Bibliothek
  ######################
  */

  private handleVerwaltungsebeneDeleted(message: JsMsg) {
    this.handler(
      message,
      () => logUnkownEvent(message),
      () => logUnkownEvent(message),
      () =>
        this.store.dispatch(
          bibliothekActions.deleteVerwaltungsebeneSuccess({
            deletedVerwaltungsebene: message.json() as VerwaltungsebeneDeletedDTO,
          })
        )
    );
  }

  private handleVerwaltungsebenenHierarchie(message: JsMsg) {
    this.handler(
      message,
      () =>
        this.store.dispatch(
          bibliothekActions.createVerwaltungsebeneSuccess({
            newVerwaltungsebenenHierarchy: message.json() as VerwaltungsebenenHierarchyDTO,
          })
        ),
      () =>
        this.store.dispatch(
          bibliothekActions.moveVerwaltungsebeneSuccess({
            newVerwaltungsebenenHierarchy: message.json() as VerwaltungsebenenHierarchyDTO,
          })
        ),
      () => logUnkownEvent(message)
    );
  }

  private handleBibFahrzeug(message: JsMsg) {
    this.handler(
      message,
      () =>
        this.store.dispatch(bibliothekActions.createFahrzeugSuccess({ newFahrzeug: message.json() as BibFahrzeugDTO })),
      () =>
        this.store.dispatch(
          bibliothekActions.patchFahrzeugSuccess({ patchedFahrzeug: message.json() as BibFahrzeugDTO })
        ),
      () => {
        const deletedFahrzeug = message.json() as BibFahrzeugDTO;
        if (deletedFahrzeug.id) {
          this.store.dispatch(bibliothekActions.deleteFahrzeugSuccess({ deleteFahrzeugId: deletedFahrzeug.id }));
        }
      }
    );
  }

  private handleBibPerson(message: JsMsg) {
    this.handler(
      message,
      () => this.store.dispatch(bibliothekActions.createPersonSuccess({ newPerson: message.json() as BibPersonDTO })),
      () =>
        this.store.dispatch(bibliothekActions.patchPersonSuccess({ patchedPerson: message.json() as BibPersonDTO })),
      () => {
        const deletedPerson = message.json() as BibPersonDTO;
        if (deletedPerson.id) {
          this.store.dispatch(
            bibliothekActions.deletePersonSuccess({
              deletePersonId: deletedPerson.id,
            })
          );
        }
      }
    );
  }

  private handleBibTaktischeFormation(message: JsMsg) {
    this.handler(
      message,
      () =>
        this.store.dispatch(
          bibliothekActions.createTaktischeFormationSuccess({
            newTaktischeFormation: message.json() as BibTaktischeFormationDTO,
          })
        ),
      () =>
        this.store.dispatch(
          bibliothekActions.patchTaktischeFormationSuccess({
            patchedTaktischeFormation: message.json() as BibTaktischeFormationDTO,
          })
        ),
      () => {
        const deletedFormation = message.json() as BibTaktischeFormationDTO;
        if (deletedFormation.id) {
          this.store.dispatch(
            bibliothekActions.deleteTaktischeFormationSuccess({
              deleteTaktischeFormationId: deletedFormation.id,
            })
          );
        }
      }
    );
  }

  private handleMapLayerConfig(message: JsMsg) {
    this.handler(
      message,
      () =>
        this.store.dispatch(
          mapLayerConfigActions.createMapLayerConfigSuccess({
            createdMapLayerConfig: message.json() as MapLayerConfig,
          })
        ),
      () =>
        this.store.dispatch(
          mapLayerConfigActions.patchMapLayerConfigSuccess({
            patchedMapLayerConfig: message.json() as MapLayerConfig,
          })
        ),
      () => {
        const deletedConfig = message.json() as MapLayerConfig;
        if (deletedConfig.id) {
          this.store.dispatch(
            mapLayerConfigActions.deleteMapLayerConfigSuccess({
              deletedMapLayerConfigId: deletedConfig.id,
            })
          );
        }
      }
    );
  }

  private handleEinsatzfilter(message: JsMsg) {
    this.handler(
      message,
      () =>
        this.store.dispatch(
          einsatzfilterActions.createEinsatzfilterSuccess({
            createdEinsatzfilterDTO: message.json() as EinsatzfilterDTO,
          })
        ),
      () =>
        this.store.dispatch(
          einsatzfilterActions.patchEinsatzfilterSuccess({
            patchedEinsatzfilterDTO: message.json() as EinsatzfilterDTO,
          })
        ),
      () => {
        const deletedFilter = message.json() as EinsatzfilterDTO;
        if (deletedFilter.id) {
          this.store.dispatch(
            einsatzfilterActions.deleteEinsatzfilterSuccess({
              deletedEinsatzfilterId: deletedFilter.id,
            })
          );
        }
      }
    );
  }

  /**
   * Verallgemeineter Handler, der je nach 'method' im Header die übergebene Funktion auslöst
   *
   * @param message Event-Nachricht die zu verarbeiten ist.
   * @param created Funktion, die bei CREATED aufzurufen ist, um `message` zu verarbeiten.
   * @param updated Funktion, die bei UPDATED aufzurufen ist, um `message` zu verarbeiten.
   * @param deleted Funktion, die bei DELETED aufzurufen ist, um `message` zu verarbeiten.
   */
  private handler(
    message: JsMsg,
    created: (message: JsMsg) => void,
    updated: (message: JsMsg) => void,
    deleted = (message: JsMsg) => {
      this.logService.info('Löschen nicht implementiert für NATS-Message:', message);
    }
  ) {
    if (!message.headers) {
      return;
    }

    const headerMethod = getMethod(message.headers);
    switch (headerMethod) {
      case METHOD_CREATED:
        created(message);
        break;
      case METHOD_UPDATED:
        updated(message);
        break;
      case METHOD_DELETED:
        deleted(message);
        break;
      default:
        logUnkownEvent(message);
    }
  }
}
