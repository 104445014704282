<div class="flex-col">
  <mat-button-toggle-group vertical="true">
    <mat-button-toggle
      *ngFor="let tool of tools"
      [value]="tool"
      [checked]="tool === selectedTool"
      (change)="selectTool($event)"
      matTooltip="{{ tool.name }}"
      matTooltipPosition="right"
      matTooltipClass="tooltip"
    >
      <mat-icon fontSet="material-icons-outlined">{{ tool.icon }}</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <mat-button-toggle-group vertical="true">
    <!-- Color -->
    <mat-button-toggle
      id="color-settings-toggle"
      (click)="toggleSettingVisibility('color')"
      [checked]="colorSettingsVisible"
      matTooltip="Farbe"
      matTooltipPosition="right"
      matTooltipClass="tooltip"
    >
      <mat-icon>palette</mat-icon>
      <div class="color-preview" [style.background-color]="toolSettings!.color"></div>
    </mat-button-toggle>
    <!-- Opacity -->
    <mat-button-toggle
      id="opacity-settings-toggle"
      (click)="toggleSettingVisibility('opacity')"
      [checked]="opacitySettingsVisible"
      matTooltip="Deckkraft"
      matTooltipPosition="right"
      matTooltipClass="tooltip"
    >
      <mat-icon>opacity</mat-icon>
      <p>{{ formatOpacity(toolSettings!.fillOpacity) }}</p>
    </mat-button-toggle>
    <!-- Stroke width -->
    <mat-button-toggle
      id="weight-settings-toggle"
      (click)="toggleSettingVisibility('weight')"
      [checked]="weightSettingsVisible"
      matTooltip="Strichstärke"
      matTooltipPosition="right"
      matTooltipClass="tooltip"
    >
      <mat-icon>line_weight</mat-icon>
      <p>{{ formatWeight(toolSettings!.weight) }}</p>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <mat-card id="color-settings" class="settings" [ngClass]="{ show: colorSettingsVisible }">
    <mat-card-content>
      <h3>Farbe</h3>
      <div class="color-wrapper">
        <div
          *ngFor="let toolColor of toolColors"
          class="color-div"
          [ngClass]="{ 'selected-color': toolColor === toolSettings!.color }"
          (click)="changeToolSetting('color', toolColor)"
          [style.background-color]="toolColor"
        ></div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card id="opacity-settings" class="settings" [ngClass]="{ show: opacitySettingsVisible }">
    <mat-card-content>
      <h3>Deckkraft: {{ formatOpacity(toolSettings!.fillOpacity) }}</h3>
      <div>
        <mat-slider [min]="0" [max]="1" [step]="0.1" [showTickMarks]="true">
          <input
            matSliderThumb
            [value]="toolSettings!.fillOpacity"
            (valueChange)="changeToolSetting('fillOpacity', $event)"
          />
        </mat-slider>
      </div>
      <div class="slider-label">
        <h4>0%</h4>
        <h4>100%</h4>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card id="weight-settings" class="settings" [ngClass]="{ show: weightSettingsVisible }">
    <mat-card-content>
      <h3>Strichstärke: {{ formatWeight(toolSettings!.weight) }}</h3>
      <div>
        <mat-slider [min]="1" [max]="5" [step]="1" [showTickMarks]="true">
          <input matSliderThumb [value]="toolSettings!.weight" (valueChange)="changeToolSetting('weight', $event)" />
        </mat-slider>
      </div>
      <div class="slider-label">
        <h4>1px</h4>
        <h4>5px</h4>
      </div>
    </mat-card-content>
  </mat-card>
</div>
