<mat-toolbar>
  <h2>Einsatzfilter auswählen</h2>
</mat-toolbar>

<div class="content">
  <mat-card>
    <app-einsatzfilter-list
      (filterSelected)="selectFilter($event)"
      [checkedEinsatzfilterDTOs]="selectedEinsatzfilterDTOs"
      (filtersChecked)="selectedEinsatzfilterDTOs = $event"
      [multiSelect]="true"
      [showDeleteButton]="false"
    />
  </mat-card>

  <mat-card>
    <app-einsatz-list
      title="Vorschau: ELS Einsätze zu ausgewähltem Filter"
      (refreshClicked)="refreshFilteredEinsatzDTOs()"
      [einsatzDTOs$]="filteredEinsatzDTOs$"
    />
  </mat-card>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="save()" class="mr-1">Speichern</button>
</mat-dialog-actions>
