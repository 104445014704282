import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import {
  Einheit,
  EinheitId,
  Fachaufgabe,
  FachaufgabeId,
  Funktion,
  FunktionId,
  Grundzeichen,
  GrundzeichenId,
  Organisation,
  OrganisationId,
  Symbol,
  SymbolId,
  TaktischesZeichen,
  Verwaltungsstufe,
  VerwaltungsstufeId,
  einheiten,
  erzeugeTaktischesZeichen,
  fachaufgaben,
  funktionen,
  grundzeichen,
  organisationen,
  symbole,
  verwaltungsstufen,
} from 'taktische-zeichen-core';

type HasLabel = Grundzeichen | Symbol | Fachaufgabe | Organisation | Einheit | Verwaltungsstufe | Funktion;
export function compareLabels(a: HasLabel, b: HasLabel) {
  return a.label.localeCompare(b.label);
}

@Component({
  selector: 'app-taktische-zeichen-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    CleanableFormFieldComponent,
  ],
  templateUrl: './taktische-zeichen-form.component.html',
  styleUrls: ['./taktische-zeichen-form.component.scss'],
})
export class TaktischeZeichenFormComponent {
  zeichenDataUrl = '';

  private formBuilder = inject(NonNullableFormBuilder);

  fcGrundzeichenId = this.formBuilder.control<GrundzeichenId>('ohne', [Validators.required]);
  fcFachaufgabeId = this.formBuilder.control<FachaufgabeId | undefined>(undefined);
  fcOrganisationId = this.formBuilder.control<OrganisationId | undefined>(undefined);
  fcEinheitId = this.formBuilder.control<EinheitId | undefined>(undefined);
  fcVerwaltungsstufeId = this.formBuilder.control<VerwaltungsstufeId | undefined>(undefined);
  fcFunktionId = this.formBuilder.control<FunktionId | undefined>(undefined);
  fcSymbolId = this.formBuilder.control<SymbolId | undefined>(undefined);
  fcText = this.formBuilder.control<string | undefined>(undefined, [Validators.pattern('[a-zA-Z0-9-/]*')]);
  fcName = this.formBuilder.control<string | undefined>(undefined, [Validators.pattern('[a-zA-Z0-9-/]*')]);
  fcOrganisationName = this.formBuilder.control<string | undefined>(undefined, [Validators.pattern('[a-zA-Z0-9-/]*')]);

  formGroup = this.formBuilder.group({
    grundzeichenId: this.fcGrundzeichenId,
    fachaufgabeId: this.fcFachaufgabeId,
    organisationId: this.fcOrganisationId,
    einheitId: this.fcEinheitId,
    verwaltungsstufeId: this.fcVerwaltungsstufeId,
    funktionId: this.fcFunktionId,
    symbolId: this.fcSymbolId,
    text: this.fcText,
    name: this.fcName,
    organisationName: this.fcOrganisationName,
  });

  // Dropdown-Werte
  grundzeichenValues: Grundzeichen[] = grundzeichen.sort(compareLabels);
  fachaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  organisationValues: Organisation[] = organisationen.sort(compareLabels);
  einheitValues: Einheit[] = einheiten.sort(compareLabels);
  verwaltungsstufeValues: Verwaltungsstufe[] = verwaltungsstufen.sort(compareLabels);
  funktionValues: Funktion[] = funktionen.sort(compareLabels);
  symbolValues: Symbol[] = symbole.sort(compareLabels);

  /**
   * GrundzeichenIds für die das Auswahlfeld "Fachaufgabe" sichtbar sein soll.
   */
  readonly fachaufgabeGrundzeichenIds: GrundzeichenId[] = [
    'taktische-formation',
    'befehlsstelle',
    'stelle',
    'ortsfeste-stelle',
    'person',
    'gebaeude',
    'fahrzeug',
    'kraftfahrzeug-landgebunden',
    'kraftfahrzeug-gelaendegaengig',
    'wechsellader',
    'abrollbehaelter',
    'anhaenger',
    'schienenfahrzeug',
    'kettenfahrzeug',
    'wasserfahrzeug',
  ];

  /**
   * GrundzeichenIds für die das Auswahlfeld "Organisation" sichtbar sein soll.
   */
  readonly organisationGrundzeichenIds: GrundzeichenId[] = [
    'taktische-formation',
    'befehlsstelle',
    'stelle',
    'ortsfeste-stelle',
    'person',
    'gebaeude',
    'fahrzeug',
    'kraftfahrzeug-landgebunden',
    'kraftfahrzeug-gelaendegaengig',
    'wechsellader',
    'abrollbehaelter',
    'anhaenger',
    'schienenfahrzeug',
    'kettenfahrzeug',
    'wasserfahrzeug',
    'flugzeug',
    'hubschrauber',
  ];

  /**
   * GrundzeichenIds für die das Auswahlfeld "Einheit" sichtbar sein soll.
   */
  readonly einheitGrundzeichenIds: GrundzeichenId[] = [
    'taktische-formation',
    'befehlsstelle',
    'person',
    'fahrzeug',
    'kraftfahrzeug-landgebunden',
    'kraftfahrzeug-gelaendegaengig',
    'wechsellader',
    'kettenfahrzeug',
    'wasserfahrzeug',
    'flugzeug',
    'hubschrauber',
  ];

  /**
   * GrundzeichenIds für die das Auswahlfeld "Verwaltungsstufe" sichtbar sein soll.
   */
  readonly verwaltungsstufeGrundzeichenIds: GrundzeichenId[] = [
    'taktische-formation',
    'befehlsstelle',
    'person',
    'fahrzeug',
    'kraftfahrzeug-landgebunden',
    'kraftfahrzeug-gelaendegaengig',
    'wechsellader',
    'kettenfahrzeug',
    'wasserfahrzeug',
    'flugzeug',
    'hubschrauber',
  ];

  /**
   * GrundzeichenIds für die das Auswahlfeld "Symbol" sichtbar sein soll.
   */
  readonly symbolGrundzeichenIds: GrundzeichenId[] = [
    'taktische-formation',
    'befehlsstelle',
    'stelle',
    'ortsfeste-stelle',
    'person',
    'gebaeude',
    'fahrzeug',
    'kraftfahrzeug-landgebunden',
    'kraftfahrzeug-gelaendegaengig',
    'wechsellader',
    'abrollbehaelter',
    'anhaenger',
    'schienenfahrzeug',
    'kettenfahrzeug',
    'massnahme',
    'anlass',
    'gefahr',
    'gefahr-vermutet',
    'gefahr-akut',
    'ohne',
  ];

  /**
   * GrundzeichenIds für die das Freitextfeld "Text" sichtbar sein soll.
   */
  readonly textGrundzeichenIds: GrundzeichenId[] = [
    'taktische-formation',
    'befehlsstelle',
    'stelle',
    'ortsfeste-stelle',
    'person',
    'gebaeude',
    'fahrzeug',
    'kraftfahrzeug-landgebunden',
    'kraftfahrzeug-gelaendegaengig',
    'wechsellader',
    'abrollbehaelter',
    'anhaenger',
    'schienenfahrzeug',
    'kettenfahrzeug',
    'massnahme',
    'anlass',
    'gefahr',
    'gefahr-vermutet',
    'gefahr-akut',
    'ohne',
  ];

  /**
   * GrundzeichenIds für die das Freitextfeld "Name" sichtbar sein soll.
   */
  readonly nameGrundzeichenIds: GrundzeichenId[] = [
    'taktische-formation',
    'befehlsstelle',
    'gebaeude',
    'fahrzeug',
    'kraftfahrzeug-landgebunden',
    'kraftfahrzeug-gelaendegaengig',
    'wechsellader',
    'abrollbehaelter',
    'anhaenger',
    'schienenfahrzeug',
    'kettenfahrzeug',
    'wasserfahrzeug',
  ];

  /**
   * GrundzeichenIds für die das Freitextfeld "Name Organisation" sichtbar sein soll.
   */
  readonly organisationNameGrundzeichenIds: GrundzeichenId[] = [
    'taktische-formation',
    'befehlsstelle',
    'gebaeude',
    'fahrzeug',
    'kraftfahrzeug-landgebunden',
    'kraftfahrzeug-gelaendegaengig',
    'wechsellader',
    'abrollbehaelter',
    'anhaenger',
    'schienenfahrzeug',
    'kettenfahrzeug',
    'wasserfahrzeug',
  ];

  // Mouseover-Zustände
  grundzeichenMouseover = false;
  fachaufgabeMouseover = false;
  organisationMouseover = false;
  einheitMouseover = false;
  verwaltungsstufeMouseover = false;
  funktionMouseover = false;
  symbolMouseover = false;

  constructor() {
    this.fcGrundzeichenId.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => this.resetHiddenFields());
    this.formGroup.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => this.generateZeichen());
    this.generateZeichen();
  }

  /**
   * Wenn ausgewähltes Grundzeichen gewisse Felder nicht zulässt, diese wieder zurücksetzen.
   */
  private resetHiddenFields() {
    if (!this.shouldBeVisible('fachaufgabeId')) {
      this.fcFachaufgabeId.reset();
    }

    if (!this.shouldBeVisible('organisationId')) {
      this.fcOrganisationId.reset();
    }

    if (!this.shouldBeVisible('einheitId')) {
      this.fcEinheitId.reset();
    }

    if (!this.shouldBeVisible('verwaltungsstufeId')) {
      this.fcVerwaltungsstufeId.reset();
    }

    if (!this.shouldBeVisible('funktionId')) {
      this.fcFunktionId.reset();
    }

    if (!this.shouldBeVisible('symbolId')) {
      this.fcSymbolId.reset();
    }

    if (!this.shouldBeVisible('text')) {
      this.fcText.reset();
    }

    if (!this.shouldBeVisible('name')) {
      this.fcName.reset();
    }

    if (!this.shouldBeVisible('organisationName')) {
      this.fcOrganisationName.reset();
    }
  }

  private generateZeichen() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const tz: TaktischesZeichen = {
      grundzeichen: this.fcGrundzeichenId.value,
      fachaufgabe: this.fcFachaufgabeId.value,
      organisation: this.fcOrganisationId.value,
      einheit: this.fcEinheitId.value,
      verwaltungsstufe: this.fcVerwaltungsstufeId.value,
      funktion: this.fcFunktionId.value,
      symbol: this.fcSymbolId.value,
      text: this.fcText.value?.trim(),
      name: this.fcName.value?.trim(),
      organisationName: this.fcOrganisationName.value?.trim(),
    };

    this.zeichenDataUrl = erzeugeTaktischesZeichen(tz).dataUrl;
  }

  shouldBeVisible(formControlName: string): boolean {
    const selectedGrundzeichen = this.fcGrundzeichenId.value;
    switch (formControlName) {
      case 'fachaufgabeId':
        return this.fachaufgabeGrundzeichenIds.includes(selectedGrundzeichen);
      case 'organisationId':
        return this.organisationGrundzeichenIds.includes(selectedGrundzeichen);
      case 'einheitId':
        return this.einheitGrundzeichenIds.includes(selectedGrundzeichen);
      case 'verwaltungsstufeId':
        return this.verwaltungsstufeGrundzeichenIds.includes(selectedGrundzeichen);
      case 'funktionId':
        return selectedGrundzeichen === 'person';
      case 'symbolId':
        return this.symbolGrundzeichenIds.includes(selectedGrundzeichen);
      case 'text':
        return this.textGrundzeichenIds.includes(selectedGrundzeichen);
      case 'name':
        return this.nameGrundzeichenIds.includes(selectedGrundzeichen);
      case 'organisationName':
        return this.organisationNameGrundzeichenIds.includes(selectedGrundzeichen);
      default:
        return false;
    }
  }
}
