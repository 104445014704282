import { Component, Input, inject } from '@angular/core';
import { FuehrungsebeneDTO } from 'src/app/api/build/openapi';
import { IconAndTextComponent, Size } from 'src/app/shared/components/icon-and-text/icon-and-text.component';
import { FuehrungsebeneService } from '../fuehrungsebene.service';

@Component({
  selector: 'app-fuehrungsebene-icon-and-text',
  standalone: true,
  imports: [IconAndTextComponent],
  templateUrl: './fuehrungsebene-icon-and-text.component.html',
  styleUrl: './fuehrungsebene-icon-and-text.component.scss',
})
export class FuehrungsebeneIconAndTextComponent {
  @Input({ required: true }) fuehrungsebeneDTO!: FuehrungsebeneDTO;
  @Input() iconSize: Size = 'medium';
  @Input() textSize: Size = 'medium';

  protected fuehrungsebeneService = inject(FuehrungsebeneService);

  /**
   * Erzeugt einen Tooltip aus Typ und Führungsebenenname: z.B.: Einsatzraum: 'Nord'
   */
  generateTooltip() {
    const typeLongname = this.fuehrungsebeneService.fuehrungsebenentypMapping.get(this.fuehrungsebeneDTO.typ)?.longname;
    const dtoName = this.fuehrungsebeneDTO.name;
    return `${typeLongname}: ${dtoName}`;
  }
}
