import { Injectable } from '@angular/core';
import { icon, MarkerOptions, PolylineOptions } from 'leaflet';

import { BehaviorSubject, Subject } from 'rxjs';
import {
  GebietDTO,
  Gebietsauspraegung,
  Gebietstyp,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
} from '../api/build/openapi';
import { FuehrungsebeneDTO } from '../api/build/openapi/model/fuehrungsebeneDTO';
import { FuehrungsebeneService } from './fuehrungsebene/fuehrungsebene.service';
import { Layer } from './layer.interface';
import { ToolSettings } from './tool.interface';

export interface GeometryStyle {
  markerOptions?: MarkerOptions;
  polygonOptions?: PolylineOptions;
}

@Injectable({
  providedIn: 'root',
})
export class LayerService {
  // "Dynamische Layer, die beim Zeichnen genutzt werden (deprecated)"
  layers: Layer[] = [
    {
      name: 'Layer 1',
      visible: true,
      featureCollection: {
        type: 'FeatureCollection',
        features: [
          // Hier landen die gezeichneten Features
        ],
      },
      index: 0,
    },
  ];

  private currentLayer: Layer | null = this.layers[0];
  currentLayer$ = new BehaviorSubject<Layer | null>(this.currentLayer);
  currentFeature$ = new BehaviorSubject<GeoJSON.Feature | null>(null);
  featureStyleChanged$ = new Subject<GeoJSON.Feature>();

  layerVisibilityChanged$ = new Subject<Layer>();
  layerDeleted$ = new Subject<Layer>();

  // Verwaltet die Sichtbarkeit der Layer-Sidebar der Karte
  layerSidebarVisibility$ = new BehaviorSubject<boolean>(false);

  // Verwaltet das Zulassen von Zoomen/Draggen der Lagekarte
  mapLocked$ = new BehaviorSubject<boolean>(false);

  readonly gebietstypColorMapping: Map<Gebietstyp, string> = new Map<Gebietstyp, string>([
    [Gebietstyp.Flaechenbrand, 'red'],
    [Gebietstyp.Ausfall, 'magenta'],
    [Gebietstyp.Duerre, 'brown'],
    [Gebietstyp.Ueberschwemmung, 'blue'],
    [Gebietstyp.Sonstiges, 'orange'],
    [Gebietstyp.Kontamination, 'yellow'],
  ]);

  /**
   * Mapping, das für jeden Fuehrungsebenentypen die Darstellung als Marker bzw. Polygon definiert.
   */

  /*public readonly fuehrungsebenentypGeometryStyleMapping = new Map<Fuehrungsebenentyp, GeometryStyle>([
    [
      Fuehrungsebenentyp.Lage,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Lage),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.Schadengebiet,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Schadengebiet),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.Einsatzraum,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Einsatzraum),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.Einsatzstelle,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Einsatzstelle),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.Einsatzabschnitt,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Einsatzabschnitt),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.Unterabschnitt,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Unterabschnitt),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.Bereitstellungsraum,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Bereitstellungsraum),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.Eigenschutz,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Eigenschutz),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.Grundschutz,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Grundschutz),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.LogistikEinsatzabschnitt,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.LogistikEinsatzabschnitt),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.LogistikEinsatzstelle,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.LogistikEinsatzstelle),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.Oertlich,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Oertlich),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.AdministrativOrganisatorisch,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.AdministrativOrganisatorisch),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.Leitstelle,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.Leitstelle),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.OperativTaktisch,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.OperativTaktisch),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
    [
      Fuehrungsebenentyp.PolitischGesamtverantwortlicher,
      {
        markerOptions: this.generateFuehrungsebeneMarkerIconOption(Fuehrungsebenentyp.PolitischGesamtverantwortlicher),
        polygonOptions: this.generatePolygonOption(),
      },
    ],
  ]);*/

  constructor(private fuehrungsebeneService: FuehrungsebeneService) {
    this.currentLayer$.next(this.currentLayer);
  }

  /**
   * Generiert einen Marker Style mit passendem Icon für den übergebenen Fuehrungsebenentypen.
   */
  public generateFuehrungsebeneMarkerIconOption(
    fuehrungsebeneDto: FuehrungsebeneDTO,
    highlighted = false
  ): MarkerOptions {
    return this.generateMarkerIconOption(
      this.fuehrungsebeneService.getFuehrungsebeneIcon(fuehrungsebeneDto),
      highlighted
    );
  }

  /**
   * Leaflet Style für Icon-Marker. Wenn gehighlighted, wird um das Icon noch ein Rahmen gezogen.
   */
  public generateMarkerIconOption(iconUrl: string, highlighted = false): MarkerOptions {
    return {
      icon: icon({
        className: highlighted ? 'icon-border' : '',
        iconUrl: iconUrl,
        iconSize: [30, 30],
        tooltipAnchor: [0, -15],
      }),
    };
  }

  /**
   * Leaflet Style für Polygone. Wenn highlighted, wird ein dickerer Rahmen verwendet.
   */
  public generatePolygonOption(highlighted = false): PolylineOptions {
    return {
      color: '#000',
      weight: highlighted ? 3 : 1,
    };
  }

  /**
   * Generiert einen Polygon Style mit passendem Icon zu dem übergebenen Taktischen Zeichen.
   * Gebiete können als Sonderfall zusätzlich gestreift sein.
   */
  public generateTzPolygonOption(taktischesZeichen?: TaktischesZeichenDTO): PolylineOptions {
    if (taktischesZeichen && taktischesZeichen.typ === TaktischesZeichenTyp.Gebiet) {
      const gebietDTO = taktischesZeichen as GebietDTO;
      if (gebietDTO.gebietstyp) {
        if (
          gebietDTO.gebietsauspraegung === Gebietsauspraegung.Drohend ||
          gebietDTO.gebietsauspraegung === Gebietsauspraegung.Ehemals
        ) {
          return {
            color: '#000',
            fillColor: `url(${this.getPolygonFillPatternId(gebietDTO.gebietstyp, gebietDTO.gebietsauspraegung)})`,
            fillOpacity: 0.4,
            weight: 1,
          };
        }
        return {
          color: '#000',
          fillColor: this.gebietstypColorMapping.get(gebietDTO.gebietstyp),
          weight: 1,
        };
      }
    }
    return this.generatePolygonOption();
  }

  /**
   * Setzt die ID eines SVG Patterns aus base-map.html zusammen, um eine gestreifte Polygon-Füllfarbe zu laden
   */
  private getPolygonFillPatternId(gebietstyp: Gebietstyp, gebietsauspraegung: Gebietsauspraegung): string {
    if (!gebietstyp || !gebietsauspraegung || gebietsauspraegung === Gebietsauspraegung.Akut) {
      return '';
    }

    const direction = gebietsauspraegung === Gebietsauspraegung.Drohend ? 'diagonal' : 'horizontal';
    const color = this.gebietstypColorMapping.get(gebietstyp);

    return `#striped-${direction}-${color}`;
  }

  public generatePolylineOption(): PolylineOptions {
    return {
      color: '#000',
      weight: 3,
    };
  }

  getFuehrungsebeneGeometryStyle(
    fuehrungsebeneDTO: FuehrungsebeneDTO,
    highlighted: boolean
  ): GeometryStyle | undefined {
    return {
      polygonOptions: this.generatePolygonOption(highlighted),
      markerOptions: this.generateMarkerIconOption(
        this.fuehrungsebeneService.getFuehrungsebeneIcon(fuehrungsebeneDTO),
        highlighted
      ),
    };
  }

  addLayer(layerName: string): Layer | null {
    if (!layerName || layerName == '') {
      return null;
    }

    const newLayer: Layer = {
      name: layerName,
      visible: true,
      featureCollection: {
        type: 'FeatureCollection',
        features: [],
      },
      index: this.layers.length,
    };

    this.layers.push(newLayer);
    this.setCurrentLayer(newLayer);
    return newLayer;
  }

  hasLayer(name: string): boolean {
    return this.layers.filter((l) => l.name === name).length > 0;
  }

  getLayer(name: string): Layer {
    return this.layers.filter((l) => l.name === name)[0];
  }

  toggleLayerVisibility(layer: Layer) {
    layer.visible = !layer.visible;
    this.layerVisibilityChanged$.next(layer);
  }

  setCurrentLayer(layer: Layer | null) {
    this.currentLayer = layer;
    this.currentLayer$.next(this.currentLayer);
  }

  setCurrentFeature(feature: GeoJSON.Feature | null) {
    this.currentFeature$.next(feature);
  }

  deleteCurrentLayer() {
    if (this.currentLayer) {
      this.layers.splice(this.layers.indexOf(this.currentLayer), 1);
      this.setCurrentLayer(null);
      this.layerDeleted$.next(this.currentLayer);
    }
  }

  toggleLayerSidebarVisibility() {
    this.layerSidebarVisibility$.next(!this.layerSidebarVisibility$.getValue());
    window.dispatchEvent(new Event('resize'));
  }

  toggleMapLocked() {
    this.mapLocked$.next(!this.mapLocked$.getValue());
  }

  /**
   * Legt Point GeoJSON-Feature zu aktuell ausgewählter FeatureCollection hinzu
   */
  addPointToCurrentLayer(lngLat: [number, number], toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.currentLayer) {
      return null;
    }

    const point: GeoJSON.Feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: lngLat,
      },
      properties: {
        name: 'Punkt',
        shape: 'Point',
        style: {
          color: toolSettings.color,
          fillOpacity: toolSettings.fillOpacity,
          weight: toolSettings.weight,
        },
      },
    };

    this.currentLayer.featureCollection.features = [...this.currentLayer.featureCollection.features, point];
    return point;
  }

  /**
   * Legt Ping GeoJSON-Feature zu aktuell ausgewählter FeatureCollection hinzu
   */
  addPingToCurrentLayer(lngLat: [number, number], toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.currentLayer) {
      return null;
    }

    const point: GeoJSON.Feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: lngLat,
      },
      properties: {
        name: 'Ping',
        shape: 'Ping',
        style: {
          color: toolSettings.color,
          fillOpacity: toolSettings.fillOpacity,
          weight: toolSettings.weight,
        },
      },
    };

    this.currentLayer.featureCollection.features = [...this.currentLayer.featureCollection.features, point];
    return point;
  }

  /**
   * Legt Line GeoJSON-Feature zu aktuell ausgewählter FeatureCollection hinzu
   */
  addLineToCurrentLayer(coords: [number, number][], toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.currentLayer) {
      return null;
    }

    const line: GeoJSON.Feature = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coords,
      },
      properties: {
        name: 'Straße',
        shape: 'Line',
        style: {
          color: toolSettings.color,
          fillOpacity: toolSettings.fillOpacity,
          weight: toolSettings.weight,
        },
      },
    };

    this.currentLayer.featureCollection.features = [...this.currentLayer.featureCollection.features, line];
    return line;
  }

  /**
   * Legt Circle GeoJSON-Feature zu aktuell ausgewählter FeatureCollection hinzu
   */
  addCircleToCurrentLayer(
    lngLat: [number, number],
    radius: number,
    toolSettings: ToolSettings
  ): GeoJSON.Feature | null {
    if (!this.currentLayer) {
      return null;
    }

    const point: GeoJSON.Feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: lngLat,
      },
      properties: {
        name: 'Kreis',
        shape: 'Circle',
        radius: radius,
        style: {
          color: toolSettings.color,
          fillOpacity: toolSettings.fillOpacity,
          weight: toolSettings.weight,
        },
      },
    };
    this.currentLayer.featureCollection.features = [...this.currentLayer.featureCollection.features, point];
    return point;
  }

  /**
   * Legt Rectangle GeoJSON-Feature zu aktuell ausgewählter FeatureCollection hinzu
   */
  addRecangleToCurrentLayer(coords: [number, number][][], toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.currentLayer) {
      return null;
    }

    const polygon: GeoJSON.Feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: coords,
      },
      properties: {
        name: 'Rechteck',
        shape: 'Rectangle',
        style: {
          color: toolSettings.color,
          fillOpacity: toolSettings.fillOpacity,
          weight: toolSettings.weight,
        },
      },
    };

    this.currentLayer.featureCollection.features = [...this.currentLayer.featureCollection.features, polygon];
    return polygon;
  }

  /**
   * Legt Polygon GeoJSON-Feature zu aktuell ausgewählter FeatureCollection hinzu
   */
  addPolygonToCurrentLayer(coords: [number, number][][], toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.currentLayer) {
      return null;
    }

    const polygon: GeoJSON.Feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: coords,
      },
      properties: {
        name: 'Gebiet',
        shape: 'Polygon',
        style: {
          color: toolSettings.color,
          fillOpacity: toolSettings.fillOpacity,
          weight: toolSettings.weight,
        },
      },
    };

    this.currentLayer.featureCollection.features = [...this.currentLayer.featureCollection.features, polygon];
    return polygon;
  }

  /**
   * Legt Text GeoJSON-Feature zu aktuell ausgewählter FeatureCollection hinzu
   */
  addTextToCurrentLayer(lngLat: [number, number], text: string): GeoJSON.Feature | null {
    if (!this.currentLayer) {
      return null;
    }

    const point: GeoJSON.Feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: lngLat,
      },
      properties: {
        name: 'Text',
        shape: 'Text',
        text: text,
      },
    };

    this.currentLayer.featureCollection.features = [...this.currentLayer.featureCollection.features, point];
    return point;
  }
}
