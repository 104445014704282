<div class="container">
  <mat-toolbar class="header">
    <h2>Lage</h2>
    <button mat-icon-button (click)="onClickReload()"><mat-icon>refresh</mat-icon></button>
  </mat-toolbar>
  <div class="dashboard-container">
    <mat-card class="card-schadensgebiet element" *ngIf="schadensgebiet">
      <h2>Schadengebiet</h2>
      <div class="header">
        <mat-card class="item">Einsatzstellen: {{ schadensgebiet.einsatzstellen }}</mat-card>
        <mat-card class="item">Einsatzräume:{{ einsatzraeumne.length }}</mat-card>
      </div>
      <div class="chart-container">
        <canvas id="tzchart" #tzchart aria-label="Hello ARIA World" role="img">efwefwef</canvas>
      </div>
    </mat-card>
    <mat-card class="card-einsatzraeume">
      <div class="card-einsatzraeume-elements" #einsatzraume></div>
    </mat-card>
  </div>
</div>
