<a
  mat-button
  [routerLink]="['/informationen/releasenotes']"
  [ngClass]="{ 'nav-selected': router.url === '/informationen/releasenotes' }"
>
  Releasenotes
</a>

<a
  mat-button
  [routerLink]="['/informationen/feedback']"
  [ngClass]="{ 'nav-selected': router.url === '/informationen/feedback' }"
>
  Feedback
</a>

<a
  mat-button
  [routerLink]="['/informationen/lizenzen']"
  [ngClass]="{ 'nav-selected': router.url === '/informationen/lizenzen' }"
>
  Lizenzen
</a>

<a
  mat-button
  [routerLink]="['/informationen/cookies']"
  [ngClass]="{ 'nav-selected': router.url === '/informationen/cookies' }"
>
  Cookies
</a>

<a
  mat-button
  [routerLink]="['/informationen/datenschutz']"
  [ngClass]="{ 'nav-selected': router.url === '/informationen/datenschutz' }"
>
  Datenschutz
</a>

<a
  mat-button
  [routerLink]="['/informationen/impressum']"
  [ngClass]="{ 'nav-selected': router.url === '/informationen/impressum' }"
>
  Impressum
</a>
