import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { currentLageSelector } from './+state/lage.selectors';

/**
 * Guard, der prüft, ob aktuell eine Lage ausgewählt ist.
 * Wird im Routing eingesetzt und verhindert, dass z.B. zu Meldungen oder Lagedarstellung über die URL gewechselt wird.
 */
@Injectable()
export class LageSelectedGuard {
  private lageSelected = false;

  constructor(private store: Store<AppStateInterface>, private router: Router) {
    this.store
      .select(currentLageSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((lage) => (this.lageSelected = !!lage));
  }

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // Navigiere zu lagen, wenn keine Lage ausgewählt ist
    if (!this.lageSelected) {
      this.router.navigate(['/lagen']);
    }
    return this.lageSelected;
  }
}
