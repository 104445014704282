import { createReducer, on } from '@ngrx/store';
import { GebietDTO } from 'src/app/api/build/openapi';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { GebietStateInterface } from './gebiet-state.interface';
import { gebietActions } from './gebiet.actions';

export const initialState: GebietStateInterface = {
  isCreating: false,
  isLoading: false,
  isPatching: false,
  isDeleting: false,
  gebiete: [],
  loadedLageId: '',
};

export const gebietReducer = createReducer(
  initialState,

  // Reset Store
  on(gebietActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Gebiete
  on(gebietActions.getGebiete, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(gebietActions.getGebieteSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    gebiete: action.gebietDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(gebietActions.getGebieteFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Gebiet
  on(gebietActions.createGebiet, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(gebietActions.createGebietSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    gebiete: [...state.gebiete, action.createdGebietDTO],
    errorResponse: undefined,
  })),
  on(gebietActions.createGebietFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Gebiet
  on(gebietActions.patchGebiet, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(gebietActions.patchGebietSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    gebiete: replaceOrKeepCurrent(state.gebiete, action.patchedGebietDTO),
    errorResponse: undefined,
  })),
  on(gebietActions.patchGebietFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Gebiet
  on(gebietActions.deleteGebiet, (state) => ({ ...state, isDeleting: true })),
  on(gebietActions.deleteGebietSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    gebiete: state.gebiete.filter((gebiet: GebietDTO) => gebiet.id !== action.deletedGebietDTO.id),
  })),
  on(gebietActions.deleteGebietFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  }))
);
