import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  BefehlsstelleDTO,
  BibliothekResourceService,
  FahrzeugDTO,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischeFormationNestedDTO,
  TaktischeFormationPatchDTO,
  TaktischeFormationResourceService,
  TaktischeFormationSelectableInfo,
} from 'src/app/api/build/openapi';

@Injectable({
  providedIn: 'root',
})
export class TaktischeFormationService {
  private bibliothekResourceService = inject(BibliothekResourceService);
  private taktischeFormationResourceService = inject(TaktischeFormationResourceService);

  /**
   * Patcht eine Taktische Formation auf unterschiedliche Wege, je nachdem, ob die Formation in einer Lage oder in der Bibliothek liegt.
   * TODO: Lässt sich das nicht vereinen? Müssen wir in Lagen immer die lageId mitsenden oder reicht es nicht, wenn sie in der Formation gehalten wird?
   */
  public patchTaktischeFormation(taktischeFormationDTO: TaktischeFormationDTO): Observable<TaktischeFormationDTO> {
    if (!taktischeFormationDTO.id) {
      return throwError(() => new Error('Taktische Formation muss zum Patchen eine Id besitzen'));
    }
    if (taktischeFormationDTO.lageId) {
      return this.taktischeFormationResourceService.patchTaktischeFormation(
        taktischeFormationDTO.lageId,
        taktischeFormationDTO.id,
        taktischeFormationDTO
      );
    }
    return this.bibliothekResourceService.patchBibliothekTaktischeFormation(
      taktischeFormationDTO.id,
      taktischeFormationDTO
    );
  }

  /**
   * Liefert ein TaktischeFormationNestedDTO zur übergebenen id. Der Unterschied zum normalen DTO ist, dass alle Referenz-Objekte (z.B. Einheiten, Befehlsstelle, usw.) als ganze Objekte mitgeliefert werden.
   */
  getTaktischeFormationNested(taktischeFormationId: string): Observable<TaktischeFormationNestedDTO> {
    return this.taktischeFormationResourceService.getTaktischeFormationById('x', taktischeFormationId);
  }

  /**
   * Liefert alle Personen, die als Einheiten für eine Taktische Formation ausgewählt werden dürfen.
   * Die Auswahl ist abhängig davon, ob sich die Formation in der Bibliothek oder in einer Lage befindet und ob es eine neue oder bereits existierende Taktische Formation ist.
   */
  getSelectablePersonen(selectableInfo: TaktischeFormationSelectableInfo): Observable<PersonDTO[]> {
    return this.bibliothekResourceService.getSelectablePersonen(selectableInfo);
  }

  /**
   * Liefert alle Fahrzeuge, die als Einheiten für eine Taktische Formation ausgewählt werden dürfen.
   * Die Auswahl ist abhängig davon, ob sich die Formation in der Bibliothek oder in einer Lage befindet und ob es eine neue oder bereits existierende Taktische Formation ist.
   */
  getSelectableFahrzeuge(selectableInfo: TaktischeFormationSelectableInfo): Observable<FahrzeugDTO[]> {
    return this.bibliothekResourceService.getSelectableFahrzeuge(selectableInfo);
  }

  /**
   * Liefert alle Taktischen Formationen, die als Einheiten für eine Taktische Formation ausgewählt werden dürfen.
   * Die Auswahl ist abhängig davon, ob sich die Formation in der Bibliothek oder in einer Lage befindet und ob es eine neue oder bereits existierende Taktische Formation ist.
   */
  getSelectableTaktischeFormationen(
    selectableInfo: TaktischeFormationSelectableInfo
  ): Observable<TaktischeFormationDTO[]> {
    return this.bibliothekResourceService.getSelectableTaktischeFormationen(selectableInfo);
  }

  /**
   * Liefert alle Befehlsstellen, die für eine Taktische Formation ausgewählt werden dürfen.
   * Die Auswahl ist abhängig davon, ob sich die Formation in der Bibliothek oder in einer Lage befindet.
   */
  getSelectableBefehlsstellen(selectableInfo: TaktischeFormationSelectableInfo): Observable<BefehlsstelleDTO[]> {
    return this.bibliothekResourceService.getSelectableBefehlsstellen(selectableInfo);
  }

  /**
   * Liefert die obersten Taktischen Formationen aller Hierarchien, denen die übergebene Taktische Formation angehört.
   */
  getTaktischeFormationRoots(taktischeFormationId: string): Observable<TaktischeFormationNestedDTO[]> {
    return this.bibliothekResourceService.getTaktischeFormationRoots(taktischeFormationId);
  }

  /**
   * Setzt die Führungseinheit einer Taktischen Formation
   */
  setTaktischeFormationFuehrungseinheit(
    taktischeFormationId: string,
    fuehrungseinheitId?: string
  ): Observable<TaktischeFormationDTO> {
    return this.bibliothekResourceService.setTaktischeFormationFuehrungseinheit(taktischeFormationId, {
      fuehrungseinheitId,
    });
  }

  /**
   * Setzt den Einheitsführer einer Taktischen Formation
   */
  setTaktischeFormationEinheitsfuehrer(
    taktischeFormationId: string,
    einheitsfuehrerId?: string
  ): Observable<TaktischeFormationDTO> {
    return this.bibliothekResourceService.setTaktischeFormationEinheitsfuehrer(taktischeFormationId, {
      einheitsfuehrerId,
    });
  }

  /**
   * Setzt die Befehlsstelle einer Taktischen Formation
   */
  setTaktischeFormationBefehlsstelle(
    taktischeFormationId: string,
    befehlsstelleId?: string
  ): Observable<TaktischeFormationDTO> {
    return this.bibliothekResourceService.setTaktischeFormationBefehlsstelle(taktischeFormationId, { befehlsstelleId });
  }

  /**
   * Setzt die übergebenen Einheiten als direkte Einheiten einer Taktischen Formation
   */
  setTaktischeFormationEinheiten(
    taktischeFormationId: string,
    einheiten: TaktischeFormationPatchDTO
  ): Observable<TaktischeFormationDTO> {
    return this.bibliothekResourceService.setTaktischeFormationEinheiten(taktischeFormationId, einheiten);
  }
}
