import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loadable',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, AsyncPipe],
  templateUrl: './loadable-component.component.html',
  styleUrls: ['./loadable-component.component.scss'],
})
export class LoadableComponentComponent {
  @Input({ required: true })
  isLoading$!: Observable<boolean>;
}
