<mat-card>
  <mat-toolbar>
    <h3>
      {{ header }}:
      {{ verletzt.value + tot.value + betroffen.value }}
    </h3>
  </mat-toolbar>

  <form *ngIf="tierschadenSubset" [formGroup]="formGroup">
    <div class="row">
      <div class="column">
        <div class="column-header">
          <img src="assets/svg/tz/tier-verletzt.svg" />
          <h4>verletzt</h4>
        </div>
        <mat-form-field>
          <input matInput type="number" [formControl]="verletzt" min="0" />
        </mat-form-field>
      </div>

      <div class="column">
        <div class="column-header">
          <img src="assets/svg/tz/tier-tot.svg" />
          <h4>tot</h4>
        </div>
        <mat-form-field>
          <input matInput type="number" [formControl]="tot" min="0" />
        </mat-form-field>
      </div>

      <div class="column">
        <div class="column-header">
          <img src="assets/svg/tz/tier.svg" />
          <h4>betroffen</h4>
        </div>
        <mat-form-field>
          <input matInput type="number" [formControl]="betroffen" min="0" />
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-card>
