import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, StabMassnahmeDTO } from 'src/app/api/build/openapi';

export const stabMassnahmenActions = createActionGroup({
  source: 'StabMassnahmen',
  events: {
    'Reset Store': emptyProps(),

    'Get Stab Massnahmen': props<{ lageId: string }>(),
    'Get Stab Massnahmen Success': props<{ massnahmen: StabMassnahmeDTO[] }>(),
    'Get Stab Massnahmen Failure': props<{ errorResponse: ErrorResponse }>(),

    'Add Stab Massnahme': props<{ lageId: string | undefined; massnahme: StabMassnahmeDTO }>(),
    'Add Stab Massnahme Success': props<{ massnahme: StabMassnahmeDTO }>(),
    'Add Stab Massnahme Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Stab Massnahme': props<{ lageid: string; massnahmeId: string; massnahme: StabMassnahmeDTO }>(),
    'Patch Stab Massnahme Success': props<{ patchedMassnahme: StabMassnahmeDTO }>(),
    'Patch Stab Massnahme Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
