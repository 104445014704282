<div class="form-container">
  <form [formGroup]="formGroup" class="actual-form">
    <mat-card>
      <mat-card-subtitle>Zeichen Informationen</mat-card-subtitle>

      <!-- Anzeigename -->
      <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />

      <!-- Organisation -->
      <mat-form-field
        class="less-margin"
        (mouseover)="organisationMouseover = true"
        (mouseleave)="organisationMouseover = false"
      >
        <mat-label>Organisation</mat-label>
        <mat-select formControlName="organisation">
          <mat-option *ngFor="let organisation of organisationValues" [value]="organisation">
            {{ organisation.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="fcOrganisation.value && organisationMouseover"
          matSuffix
          mat-icon-button
          (click)="fcOrganisation.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Fachaufgabe -->
      <mat-form-field
        class="less-margin"
        (mouseover)="fachaufgabeMouseover = true"
        (mouseleave)="fachaufgabeMouseover = false"
      >
        <mat-label>Fachaufgabe</mat-label>
        <mat-select formControlName="fachaufgabe">
          <mat-option *ngFor="let fachaufgabe of fachaufgabeValues" [value]="fachaufgabe">
            {{ fachaufgabe.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="fcFachaufgabe.value && fachaufgabeMouseover"
          matSuffix
          mat-icon-button
          (click)="fcFachaufgabe.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Größenordnung -->
      <mat-form-field
        class="less-margin"
        (mouseover)="groessenordnungMouseover = true"
        (mouseleave)="groessenordnungMouseover = false"
      >
        <mat-label>Größenordnung</mat-label>
        <mat-select formControlName="groessenordnung">
          <mat-option *ngFor="let groessenordnung of groessenordnungValues" [value]="groessenordnung">
            {{ groessenordnung.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="fcGroessenordnung.value && groessenordnungMouseover"
          matSuffix
          mat-icon-button
          (click)="fcGroessenordnung.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Eingabe Mannschaftsstärke in drei Kategorien mit automatischer Summierung -->
      <mat-form-field
        class="less-margin"
        (mouseover)="mannschaftsstaerkeMousoever = true"
        (mouseleave)="mannschaftsstaerkeMousoever = false"
      >
        <mat-label>Mannschaftsstärke</mat-label>
        <div class="flex-row">
          <input matInput type="number" min="0" [formControl]="fcMannschaftsstaerke1" [defaultValue]="0" />
          <span class="mr-05 op-70">/</span>
          <input matInput type="number" min="0" [formControl]="fcMannschaftsstaerke2" [defaultValue]="0" />
          <span class="mr-05 op-70">/</span>
          <input matInput type="number" min="0" [formControl]="fcMannschaftsstaerke3" [defaultValue]="0" />
          <span class="mr-05 op-70">=</span>
          <span>
            {{ fcMannschaftsstaerke1.value + fcMannschaftsstaerke2.value + fcMannschaftsstaerke3.value }}
          </span>
        </div>
        <button
          *ngIf="
            (fcMannschaftsstaerke1.value || fcMannschaftsstaerke2.value || fcMannschaftsstaerke3.value) &&
            mannschaftsstaerkeMousoever
          "
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="resetMannschaftsstaerke()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Anzahl TaktischeFormationen -->
      <mat-form-field
        class="less-margin"
        (mouseover)="summeTaktischeFormationenMouseover = true"
        (mouseleave)="summeTaktischeFormationenMouseover = false"
      >
        <mat-label>Anzahl Taktische Formationen</mat-label>
        <input matInput type="number" [formControl]="fcSummeTaktischeFormationen" />
        <button
          *ngIf="fcSummeTaktischeFormationen.value && summeTaktischeFormationenMouseover"
          matSuffix
          mat-icon-button
          (click)="fcSummeTaktischeFormationen.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Anzahl Fahrzeuge -->
      <mat-form-field
        class="less-margin"
        (mouseover)="summeFahrzeugeMouseover = true"
        (mouseleave)="summeFahrzeugeMouseover = false"
      >
        <mat-label>Anzahl Fahrzeuge</mat-label>
        <input matInput type="number" [formControl]="fcSummeFahrzeuge" />
        <button
          *ngIf="fcSummeFahrzeuge.value && summeFahrzeugeMouseover"
          matSuffix
          mat-icon-button
          (click)="fcSummeFahrzeuge.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Verwaltungsstufe -->
      <mat-form-field
        class="less-margin"
        (mouseover)="verwaltungsstufeMouseover = true"
        (mouseleave)="verwaltungsstufeMouseover = false"
      >
        <mat-label>Verwaltungsstufe</mat-label>
        <mat-select formControlName="verwaltungsstufe">
          <mat-option *ngFor="let verwaltungsstufe of verwaltungsstufeValues" [value]="verwaltungsstufe">
            {{ verwaltungsstufe.label }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="fcVerwaltungsstufe.value && verwaltungsstufeMouseover"
          matSuffix
          mat-icon-button
          (click)="fcVerwaltungsstufe.reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Symbol -->
      <mat-form-field class="less-margin" (mouseover)="symbolMouseover = true" (mouseleave)="symbolMouseover = false">
        <mat-label>Symbol</mat-label>
        <mat-select formControlName="symbol">
          <mat-option *ngFor="let symbol of symbolValues" [value]="symbol">
            {{ symbol.label }}
          </mat-option>
        </mat-select>
        <button *ngIf="fcSymbol.value && symbolMouseover" matSuffix mat-icon-button (click)="fcSymbol.reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- ZeichenText (Text in der Mitte des Zeichens) -->
      <mat-form-field class="less-margin">
        <mat-label>Text</mat-label>
        <input matInput [formControl]="fcZeichenText" />
        <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['maxlength']">{{
          getErrorMessage(fcZeichenText)
        }}</mat-error>
        <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['pattern']"
          >Keine Umlaute oder Sonderzeichen</mat-error
        >
      </mat-form-field>

      <!-- ZeichenText Name (Text oben links im Zeichen) -->
      <mat-form-field class="less-margin">
        <mat-label>Text Name</mat-label>
        <input matInput [formControl]="fcZeichenTextName" />
        <mat-error *ngIf="fcZeichenTextName.errors !== null && fcZeichenTextName.errors['maxlength']">{{
          getErrorMessage(fcZeichenTextName)
        }}</mat-error>
        <mat-error *ngIf="fcZeichenTextName.errors !== null && fcZeichenTextName.errors['pattern']"
          >Keine Umlaute oder Sonderzeichen</mat-error
        >
      </mat-form-field>

      <!-- ZeichenText Organisation (Text unten rechts im Zeichen) -->
      <mat-form-field class="less-margin">
        <mat-label>Text Organisation</mat-label>
        <input matInput [formControl]="fcTextOrganisation" />
        <mat-error *ngIf="fcTextOrganisation.errors !== null && fcTextOrganisation.errors['maxlength']">{{
          getErrorMessage(fcTextOrganisation)
        }}</mat-error>
        <mat-error *ngIf="fcTextOrganisation.errors !== null && fcTextOrganisation.errors['pattern']"
          >Keine Umlaute oder Sonderzeichen</mat-error
        >
      </mat-form-field>
    </mat-card>

    <mat-card>
      <mat-card-subtitle>Information zur Stelle/Einrichtung</mat-card-subtitle>

      <!-- Ortsangabe -->
      <app-cleanable-form-field label="Ortsangabe" [formGroup]="formGroup" formControlName="ortsangabe" />

      <!-- Einheitsführer -->
      <app-tz-combobox
        label="Einheitsführer"
        formControlName="einheitsfuehrer"
        [formGroup]="formGroup"
        [data$]="einheitsfuehrerValues$"
        [displayedFields]="['anzeigename']"
      />

      <!-- Führungseinheit -->
      <app-tz-combobox
        label="Führungseinheit"
        formControlName="fuehrungseinheit"
        [formGroup]="formGroup"
        [data$]="fuehrungseinheitValues$"
        [displayedFields]="['anzeigename']"
      />

      <!-- Befehlsstelle -->
      <app-tz-combobox
        label="Befehlsstelle"
        formControlName="befehlsstelle"
        [formGroup]="formGroup"
        [data$]="befehlsstelleValues$"
        [displayedFields]="['anzeigename']"
      />

      <mat-radio-group [formControl]="fcEinsatzweise">
        <mat-radio-button [value]="Einsatzweise.Geschlossen">Geschlossener Einsatz</mat-radio-button>
        <mat-radio-button [value]="Einsatzweise.Getrennt">Getrennter Einsatz</mat-radio-button>
      </mat-radio-group>

      <button mat-raised-button color="accent" (click)="openTaktischeFormationHierarchie()" [disabled]="!dtoToEdit?.id">
        <mat-icon fontSet="material-icons-outlined">account_tree</mat-icon>
        Einheiten verwalten
      </button>
    </mat-card>
  </form>

  <app-kontakt-list [kommunikationOptionen]="dtoToEdit?.kommunikationOptionen || []" />
  <app-auftrag-list />
</div>
