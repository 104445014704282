import { createReducer, on } from '@ngrx/store';
import { StelleDTO } from 'src/app/api/build/openapi';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { StelleStateInterface } from './stelle-state.interface';
import { stelleActions } from './stelle.actions';

export const initialState: StelleStateInterface = {
  isCreating: false,
  isLoading: false,
  isDeleting: false,
  isPatching: false,
  stellen: [],
  loadedLageId: '',
};

export const stelleReducer = createReducer(
  initialState,

  // Reset Store
  on(stelleActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Stellen
  on(stelleActions.getStellen, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(stelleActions.getStellenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    stellen: action.stelleDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(stelleActions.getStellenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Stelle
  on(stelleActions.createStelle, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(stelleActions.createStelleSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    stellen: [...state.stellen, action.createdStelleDTO],
    errorResponse: undefined,
  })),
  on(stelleActions.createStelleFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Stelle
  on(stelleActions.patchStelle, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(stelleActions.patchStelleSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    stellen: replaceOrKeepCurrent(state.stellen, action.patchedStelleDTO),
    errorResponse: undefined,
  })),
  on(stelleActions.patchStelleFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Stelle
  on(stelleActions.deleteStelle, (state) => ({ ...state, isDeleting: true, errorResponse: undefined })),
  on(stelleActions.deleteStelleSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    stellen: state.stellen.filter((stelle: StelleDTO) => stelle.id !== action.deletedStelleDTO.id),
    errorResponse: undefined,
  })),
  on(stelleActions.deleteStelleFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  }))
);
