import { createReducer, on } from '@ngrx/store';
import { BefehlsstelleDTO } from 'src/app/api/build/openapi';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { BefehlsstelleStateInterface } from './befehlsstelle-state.interface';
import { befehlsstelleActions } from './befehlsstelle.actions';

export const initialState: BefehlsstelleStateInterface = {
  isCreating: false,
  isLoading: false,
  isDeleting: false,
  isPatching: false,
  befehlsstellen: [],
  loadedLageId: '',
};

export const befehlsstelleReducer = createReducer(
  initialState,

  // Reset Store
  on(befehlsstelleActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Befehlsstellen
  on(befehlsstelleActions.getBefehlsstellen, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(befehlsstelleActions.getBefehlsstellenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    befehlsstellen: action.befehlsstelleDTOs,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(befehlsstelleActions.getBefehlsstellenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Befehlsstelle
  on(befehlsstelleActions.createBefehlsstelle, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(befehlsstelleActions.createBefehlsstelleSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    befehlsstellen: [...state.befehlsstellen, action.createdBefehlsstelleDTO],
    errorResponse: undefined,
  })),
  on(befehlsstelleActions.createBefehlsstelleFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Befehlsstelle
  on(befehlsstelleActions.patchBefehlsstelle, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(befehlsstelleActions.patchBefehlsstelleSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    befehlsstellen: replaceOrKeepCurrent(state.befehlsstellen, action.patchedBefehlsstelleDTO),
    errorResponse: undefined,
  })),
  on(befehlsstelleActions.patchBefehlsstelleFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Befehlsstelle
  on(befehlsstelleActions.deleteBefehlsstelle, (state) => ({ ...state, isDeleting: true, errorResponse: undefined })),
  on(befehlsstelleActions.deleteBefehlsstelleSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    befehlsstellen: state.befehlsstellen.filter(
      (befehlsstelle: BefehlsstelleDTO) => befehlsstelle.id != action.deletedBefehlsstelleDTO.id
    ),
    errorResponse: undefined,
  })),
  on(befehlsstelleActions.deleteBefehlsstelleFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  }))
);
