import { createSelector } from '@ngrx/store';
import { AppStateInterface, personenschaeden } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[personenschaeden];

export const personenschaedenSelector = createSelector(selectFeature, (state) => state.personenschaeden);
export const personenschaedenErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingPersonenschaedenSelector = createSelector(selectFeature, (state) => state.isLoading);
export const personenschaedenLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const personenschaedenOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.personenschaeden.filter((personenschaden) => personenschaden.fuehrungsebeneId === fuehrungsebeneId)
  );
