import { NgIf, AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { LageMapComponent, MapLayout } from 'src/app/lagedarstellung/lage-map/lage-map.component';
import { WindowManagerService } from 'src/app/lagedarstellung/monitor-manager/window-manager.service';
import { KennzahlenDashboardComponent } from '../kennzahlen-dashboard/kennzahlen-dashboard.component';
import { MassnahmenDashboardComponent } from '../massnahmen-dashboard/massnahmen-dashboard.component';

@Component({
  selector: 'app-lage-k-window-tabs',
  standalone: true,
  imports: [
    NgIf,
    MatTabsModule,
    LageMapComponent,
    AsyncPipe,
    KennzahlenDashboardComponent,
    MassnahmenDashboardComponent,
  ],
  templateUrl: './lage-k-window-tabs.component.html',
  styleUrl: './lage-k-window-tabs.component.scss',
})
export class LageKWindowTabsComponent implements OnInit, OnDestroy {
  MapLayout = MapLayout;
  constructor(protected monitorManager: WindowManagerService) {}

  ngOnInit(): void {
    this.monitorManager.openSecondaryWindows();
  }

  ngOnDestroy(): void {
    this.monitorManager.closeSecondaryWindows();
  }

  // Resize Event feuern, da Leaflet Map beim Tab-Wechsel sonst nicht auf gesamtes Div gestreckt sind
  selectedTabChange(event: MatTabChangeEvent) {
    window.dispatchEvent(new Event('resize'));
  }
}
