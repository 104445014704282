import { NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Store } from '@ngrx/store';
import { FuehrungsebeneDTO } from 'src/app/api/build/openapi';
import { currentLogistikChildrenSelector, currentLogistikSelector } from '../../+state/fuehrungsebene.selectors';
import { OrganigrammFuehrungsebeneCardComponent } from '../../../organigramm/organigramm-fuehrungsebene-card/organigramm-fuehrungsebene-card.component';

/**
 * Große Logistik-Komponente zur Bearbeitung der Logistik.
 * Öffnet sich durch Klick auf den Edit Button der kleinen Logistik-Komponente.
 */
@Component({
  selector: 'app-logistik-dialog',
  templateUrl: './logistik-dialog.component.html',
  styleUrls: ['./logistik-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, OrganigrammFuehrungsebeneCardComponent, NgIf, NgFor, MatButtonModule, MatToolbarModule],
})
export class LogistikDialogComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private store = inject(Store);

  logistikDTO?: FuehrungsebeneDTO;
  logistikChildren: FuehrungsebeneDTO[] = [];

  ngOnInit(): void {
    this.store
      .select(currentLogistikSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((logistik) => {
        this.logistikDTO = logistik.length ? logistik[0] : undefined;
      });

    this.store
      .select(currentLogistikChildrenSelector)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((logistikChildren) => {
        this.logistikChildren = logistikChildren;
      });
  }
}
