/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AnalogRelaisbetrieb = 'RS1' | 'RS2' | 'RS3' | 'RS4';

export const AnalogRelaisbetrieb = {
    Rs1: 'RS1' as AnalogRelaisbetrieb,
    Rs2: 'RS2' as AnalogRelaisbetrieb,
    Rs3: 'RS3' as AnalogRelaisbetrieb,
    Rs4: 'RS4' as AnalogRelaisbetrieb
};

