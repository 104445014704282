<mat-toolbar>
  <span>
    <h3>Einsatzfilter</h3>
    <button mat-icon-button (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
  </span>

  <span>
    <button mat-icon-button (click)="addClicked()">
      <mat-icon>add</mat-icon>
    </button>

    <button mat-icon-button (click)="editClicked()" [disabled]="!selectedEinsatzfilterDTO">
      <mat-icon>edit</mat-icon>
    </button>

    <button mat-icon-button (click)="deleteClicked()" [disabled]="!selectedEinsatzfilterDTO" *ngIf="showDeleteButton">
      <mat-icon>delete</mat-icon>
    </button>
  </span>
</mat-toolbar>

<div class="content">
  <app-loadable [isLoading$]="isLoading$">
    <table mat-table [dataSource]="availableEinsatzfilterDTOs">
      <ng-container matColumnDef="select" *ngIf="multiSelect">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAll() : null" [checked]="isAllSelected()" />
        </th>
        <td mat-cell *matCellDef="let filter">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? toggle(filter) : null"
            [checked]="isSelected(filter)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let filterDTO">{{ filterDTO.name }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="multiSelect ? displayedColumnsMulti : displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let filterDTO; columns: multiSelect ? displayedColumnsMulti : displayedColumns"
        class="list-row"
        [ngClass]="{
          'selected-list-row': filterDTO.id === selectedEinsatzfilterDTO?.id
        }"
        (click)="selectFilter(filterDTO)"
        (dblclick)="editFilter(filterDTO)"
      ></tr>
    </table>

    <h3 class="keine-daten-text" *ngIf="!availableEinsatzfilterDTOs.length">Keine Einsatzfilter vorhanden</h3>
  </app-loadable>
</div>
