import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable()
export class SvgSanitizer {
  constructor(public sanitizer: DomSanitizer) {}

  /**
   * TODO  Sicherheitslücke? -> Ticket: LAGE-6
   *
   *
   * @param dataUrl
   * @returns `undefined` wenn keine SVG-DataURL
   */
  sanitize(dataUrl: string): SafeHtml | undefined {
    if (!dataUrl.startsWith('data:image/svg+xml;base64,')) {
      return undefined;
    }
    const dataUrlWithoutPrefix = dataUrl.replace('data:image/svg+xml;base64,', '');
    const decodedDataURL = window.atob(dataUrlWithoutPrefix);

    return this.sanitizer.bypassSecurityTrustHtml(decodedDataURL);
  }
}
