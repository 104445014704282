import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

/**
 * @description
 * Direktive, die ein `<tag>` ergänzen kann, um Datei-Drops zu erkennen.
 * Liefert "fallengelassene" Dateien über das `filesDropped`-Event zurück.
 * Setzt für die Dauer von dragover bis dragleave die CSS-Klasse `fileover`
 *
 *
 * @emits `filesDropped:FileList`
 *
 * @example
 * ```
 *  <div appDragAndDrop (filesDropped)="filesDropped($event)"></div>
 * ```
 *
 * Nach Beispiel von:
 * @see https://medium.com/@tarekabdelkhalek/how-to-create-a-drag-and-drop-file-uploading-in-angular-78d9eba0b854
 */
@Directive({
  selector: '[appDragAndDrop]',
  standalone: true,
})
export class DragAndDropDirective {
  @HostBinding('class.fileover') fileOver = false;
  @Output() filesDropped = new EventEmitter<FileList>();

  // Drag Over
  @HostListener('dragover', ['$event']) onFileOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  // Drag Exit
  @HostListener('dragleave', ['$event']) onFileExited(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  // Drop
  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.filesDropped.emit(files);
    }
  }
}
