<div class="tree-node-row-content">
  <div class="col">
    <app-fuehrungsebene-icon-and-text [fuehrungsebeneDTO]="node.fuehrungsebeneDTO" iconSize="small" />

    <div class="special-fuehrungsebenen">
      <mat-icon
        class="special-fuehrungsebene-icon"
        (click)="toggleLayerVisibility(node, VisibilityProperty.GESAMT, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.GESAMT, true)"
        matTooltip="Gesamt"
      >
        {{ node.showAll ? "visibility" : "visibility_off" }}
      </mat-icon>

      <img
        *ngIf="!!node.fuehrungsebeneDTO.geometry"
        (click)="toggleLayerVisibility(node, VisibilityProperty.GEOMETRIE, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.GEOMETRIE, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/geometry' + (node.showGeometry ? '.png' : '-disabled.png')"
        matTooltip="Geometrien"
      />

      <img
        *ngIf="node.politischGesamtverantwortlichDTO"
        (click)="toggleLayerVisibility(node, VisibilityProperty.POLITISCH_GESAMTVERANTWORTLICH, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.POLITISCH_GESAMTVERANTWORTLICH, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/pg' + (node.showPolitischGesamtverantwortlich ? '.png' : '-disabled.png')"
        matTooltip="Politisch Gesamtverantwortlich"
      />

      <img
        *ngIf="node.administativOrganisatorischDTO"
        (click)="toggleLayerVisibility(node, VisibilityProperty.ADMINISTRATIV_ORGANISATORISCH, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.ADMINISTRATIV_ORGANISATORISCH, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/ao' + (node.showAdministrativOrganisatorisch ? '.png' : '-disabled.png')"
        matTooltip="Administrativ Organisatorisch"
      />

      <img
        *ngIf="node.operativTaktischDTO"
        (click)="toggleLayerVisibility(node, VisibilityProperty.OPERATIV_TAKTISCH, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.OPERATIV_TAKTISCH, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/ot' + (node.showOperativTaktisch ? '.png' : '-disabled.png')"
        matTooltip="Operativ Taktisch"
      />

      <img
        *ngIf="node.leitstelleDTO"
        (click)="toggleLayerVisibility(node, VisibilityProperty.LEITSTELLE, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.LEITSTELLE, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/leitstelle' + (node.showLeitstelle ? '.png' : '-disabled.png')"
        matTooltip="Leitstelle"
      />

      <img
        *ngIf="node.bereitstellungsraumChildNodes.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.BEREITSTELLUNGSRAUM, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.BEREITSTELLUNGSRAUM, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/bereitstellungsraum' + (node.showBereitstellungsraeume ? '.png' : '-disabled.png')"
        matTooltip="Bereitstellungsräume"
      />

      <img
        *ngIf="node.grundschutzChildNodes.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.GRUNDSCHUTZ, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.GRUNDSCHUTZ, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/grundschutz' + (node.showGrundschutz ? '.png' : '-disabled.png')"
        matTooltip="Grundschutz"
      />

      <img
        *ngIf="node.oertlichChildNodes.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.OERTLICH, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.OERTLICH, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/oertlich' + (node.showOertlich ? '.png' : '-disabled.png')"
        matTooltip="Örtliche Einheiten"
      />

      <img
        *ngIf="node.einsatzDtos.length && (componentService.isShowDevelopment$ | async)"
        (click)="toggleLayerVisibility(node, VisibilityProperty.EINSATZ, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.EINSATZ, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/einsatz' + (node.showEinsaetze ? '.png' : '-disabled.png')"
        matTooltip="ELS Einsätze"
      />

      <img
        *ngIf="node.taktischeFormationDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.TAKTISCHE_FORMATION, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.TAKTISCHE_FORMATION, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/taktische-formation' + (node.showTaktischeFormationen ? '.png' : '-disabled.png')"
        matTooltip="Taktische Formationen"
      />

      <img
        *ngIf="node.fahrzeugDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.FAHRZEUG, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.FAHRZEUG, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/fahrzeug' + (node.showFahrzeuge ? '.png' : '-disabled.png')"
        matTooltip="Fahrzeuge"
      />

      <img
        *ngIf="node.personDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.PERSON, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.PERSON, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/person' + (node.showPersonen ? '.png' : '-disabled.png')"
        matTooltip="Personen"
      />

      <img
        *ngIf="node.anlassDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.ANLASS, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.ANLASS, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/anlass' + (node.showAnlaesse ? '.png' : '-disabled.png')"
        matTooltip="Anlässe/Ereignisse"
      />

      <img
        *ngIf="node.befehlsstelleDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.BEFEHLSSTELLE, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.BEFEHLSSTELLE, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/befehlsstelle' + (node.showBefehlsstellen ? '.png' : '-disabled.png')"
        matTooltip="Befehlsstellen"
      />

      <img
        *ngIf="node.fotoDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.FOTO, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.FOTO, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/foto' + (node.showFotos ? '.png' : '-disabled.png')"
        matTooltip="Fotos"
      />

      <img
        *ngIf="node.gebaeudeDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.GEBAEUDE, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.GEBAEUDE, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/gebaeude' + (node.showGebaeude ? '.png' : '-disabled.png')"
        matTooltip="Gebäude"
      />

      <img
        *ngIf="node.gebietDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.GEBIET, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.GEBIET, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/gebiet' + (node.showGebiete ? '.png' : '-disabled.png')"
        matTooltip="Gebiete"
      />

      <img
        *ngIf="node.gefahrDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.GEFAHR, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.GEFAHR, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/gefahr' + (node.showGefahren ? '.png' : '-disabled.png')"
        matTooltip="Gefahren"
      />

      <img
        *ngIf="node.massnahmeDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.MASSNAHME, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.MASSNAHME, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/massnahme' + (node.showMassnahmen ? '.png' : '-disabled.png')"
        matTooltip="Maßnahmen"
      />

      <img
        *ngIf="node.personenschadenDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.PERSONENSCHADEN, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.PERSONENSCHADEN, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/person' + (node.showPersonenschaeden ? '.png' : '-disabled.png')"
        matTooltip="Personenschäden"
      />

      <img
        *ngIf="node.stelleDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.STELLE, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.STELLE, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/stelle' + (node.showStellen ? '.png' : '-disabled.png')"
        matTooltip="Stellen"
      />

      <img
        *ngIf="node.tierschadenDTOs.length"
        (click)="toggleLayerVisibility(node, VisibilityProperty.TIERSCHADEN, false)"
        (contextmenu)="toggleLayerVisibility(node, VisibilityProperty.TIERSCHADEN, true)"
        class="special-fuehrungsebene-icon"
        [src]="'assets/png/tz/tier' + (node.showTierschaeden ? '.png' : '-disabled.png')"
        matTooltip="Tierschäden"
      />
    </div>
  </div>
</div>
