<form [formGroup]="formGroup">
  <mat-form-field
    class="less-margin"
    (mouseover)="grundzeichenMouseover = true"
    (mouseleave)="grundzeichenMouseover = false"
  >
    <mat-label>Grundzeichen</mat-label>
    <mat-select formControlName="grundzeichenId">
      <mat-option *ngFor="let grundzeichen of grundzeichenValues" [value]="grundzeichen.id">
        {{ grundzeichen.label }}
      </mat-option>
    </mat-select>
    <button
      *ngIf="fcGrundzeichenId.value && grundzeichenMouseover"
      matSuffix
      mat-icon-button
      (click)="fcGrundzeichenId.reset()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field
    class="less-margin"
    (mouseover)="fachaufgabeMouseover = true"
    (mouseleave)="fachaufgabeMouseover = false"
    *ngIf="shouldBeVisible('fachaufgabeId')"
  >
    <mat-label>Fachaufgabe</mat-label>
    <mat-select formControlName="fachaufgabeId">
      <mat-option *ngFor="let fachaufgabe of fachaufgabeValues" [value]="fachaufgabe.id">
        {{ fachaufgabe.label }}
      </mat-option>
    </mat-select>
    <button
      *ngIf="fcFachaufgabeId.value && fachaufgabeMouseover"
      matSuffix
      mat-icon-button
      (click)="fcFachaufgabeId.reset()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field
    class="less-margin"
    (mouseover)="organisationMouseover = true"
    (mouseleave)="organisationMouseover = false"
    *ngIf="shouldBeVisible('organisationId')"
  >
    <mat-label>Organisation</mat-label>
    <mat-select formControlName="organisationId">
      <mat-option *ngFor="let organisation of organisationValues" [value]="organisation.id">
        {{ organisation.label }}
      </mat-option>
    </mat-select>
    <button
      *ngIf="fcOrganisationId.value && organisationMouseover"
      matSuffix
      mat-icon-button
      (click)="fcOrganisationId.reset()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field
    class="less-margin"
    (mouseover)="einheitMouseover = true"
    (mouseleave)="einheitMouseover = false"
    *ngIf="shouldBeVisible('einheitId')"
  >
    <mat-label>Einheit</mat-label>
    <mat-select formControlName="einheitId">
      <mat-option *ngFor="let einheit of einheitValues" [value]="einheit.id">
        {{ einheit.label }}
      </mat-option>
    </mat-select>
    <button *ngIf="fcEinheitId.value && einheitMouseover" matSuffix mat-icon-button (click)="fcEinheitId.reset()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field
    class="less-margin"
    (mouseover)="verwaltungsstufeMouseover = true"
    (mouseleave)="verwaltungsstufeMouseover = false"
    *ngIf="shouldBeVisible('verwaltungsstufeId')"
  >
    <mat-label>Verwaltungsstufe</mat-label>
    <mat-select formControlName="verwaltungsstufeId">
      <mat-option *ngFor="let verwaltungsstufe of verwaltungsstufeValues" [value]="verwaltungsstufe.id">
        {{ verwaltungsstufe.label }}
      </mat-option>
    </mat-select>
    <button
      *ngIf="fcVerwaltungsstufeId.value && verwaltungsstufeMouseover"
      matSuffix
      mat-icon-button
      (click)="fcVerwaltungsstufeId.reset()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field
    class="less-margin"
    (mouseover)="funktionMouseover = true"
    (mouseleave)="funktionMouseover = false"
    *ngIf="shouldBeVisible('funktionId')"
  >
    <mat-label>Funktion</mat-label>
    <mat-select formControlName="funktionId">
      <mat-option *ngFor="let funktion of funktionValues" [value]="funktion.id">
        {{ funktion.label }}
      </mat-option>
    </mat-select>
    <button *ngIf="fcFunktionId.value && funktionMouseover" matSuffix mat-icon-button (click)="fcFunktionId.reset()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field
    class="less-margin"
    (mouseover)="symbolMouseover = true"
    (mouseleave)="symbolMouseover = false"
    *ngIf="shouldBeVisible('symbolId')"
  >
    <mat-label>Symbol</mat-label>
    <mat-select formControlName="symbolId">
      <mat-option *ngFor="let symbol of symbolValues" [value]="symbol.id">
        {{ symbol.label }}
      </mat-option>
    </mat-select>
    <button *ngIf="fcSymbolId.value && symbolMouseover" matSuffix mat-icon-button (click)="fcSymbolId.reset()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field *ngIf="shouldBeVisible('text')" class="less-margin">
    <mat-label>Text</mat-label>
    <input matInput [formControl]="fcText" />
    <mat-error *ngIf="fcText.errors">Keine Umlaute oder Sonderzeichen</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="shouldBeVisible('name')" class="less-margin">
    <mat-label>Name</mat-label>
    <input matInput [formControl]="fcName" />
    <mat-error *ngIf="fcName.errors">Keine Umlaute oder Sonderzeichen</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="shouldBeVisible('organisationName')" class="less-margin">
    <mat-label>Name Organisation</mat-label>
    <input matInput [formControl]="fcOrganisationName" />
    <mat-error *ngIf="fcOrganisationName.errors">Keine Umlaute oder Sonderzeichen</mat-error>
  </mat-form-field>
</form>

<img *ngIf="zeichenDataUrl" [src]="zeichenDataUrl" />
