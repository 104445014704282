import { createSelector } from '@ngrx/store';
import { AppStateInterface, fuehrungsebeneMassnahmen } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[fuehrungsebeneMassnahmen];

export const fuehrungsebeneMassnahmenSelector = createSelector(
  selectFeature,
  (state) => state.fuehrungsebeneMassnahmen
);
export const fuehrungsebeneMassnahmenErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingFuehrungsebeneMassnahmenSelector = createSelector(selectFeature, (state) => state.isLoading);
export const fuehrungsebeneMassnahmenLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const fuehrungsebeneMassnahmenOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.fuehrungsebeneMassnahmen.filter(
      (fuehrungsebeneMassnahme) => fuehrungsebeneMassnahme.fuehrungsebeneId === fuehrungsebeneId
    )
  );
