import { Injectable } from '@angular/core';
import { divIcon, LeafletMouseEvent, Map, marker } from 'leaflet';
import { LayerService } from '../layer.service';
import { ToolSettings } from '../tool.interface';
import { FeatureGenerator } from './tool-handler';

/**
 * Generator für einen Marker auf einer Leaflet-Karte über den ToolHandler
 */
@Injectable({
  providedIn: 'root',
})
export class PointGenerator implements FeatureGenerator {
  private toolPoint: L.Marker | null = null;
  private map: Map | null = null;

  constructor(private layerService: LayerService) {}

  init(map: Map): void {
    this.map = map;
  }

  createFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): void {
    this.toolPoint = marker([event.latlng.lat, event.latlng.lng], {
      icon: PointGenerator.getPinDivIcon(toolSettings.color),
    });
    this.map?.addLayer(this.toolPoint);
  }

  updateFeature(event: LeafletMouseEvent): void {
    if (this.toolPoint) {
      this.toolPoint.setLatLng(event.latlng);
    }
  }

  extendFeature(event: LeafletMouseEvent): void {
    // Do nothing
  }

  finalizeFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.toolPoint) {
      return null;
    }
    this.map?.removeLayer(this.toolPoint);
    this.toolPoint = null;
    return this.layerService.addPointToCurrentLayer([event.latlng.lng, event.latlng.lat], toolSettings);
  }

  /**
   * Marker-SVG Definition. Muss textuell vorliegen, um die Farbe überschreiben zu können.
   */
  public static getPinDivIcon(color: string) {
    // Opacity und Weight erstmal nicht verwenden.
    const pinSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 256 256" xml:space="preserve">
      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
        <path d="M 45 0 C 25.463 0 9.625 15.838 9.625 35.375 c 0 8.722 3.171 16.693 8.404 22.861 L 45 90 l 26.97 -31.765 c 5.233 -6.167 8.404 -14.139 8.404 -22.861 C 80.375 15.838 64.537 0 45 0 z M 45 48.705 c -8.035 0 -14.548 -6.513 -14.548 -14.548 c 0 -8.035 6.513 -14.548 14.548 -14.548 s 14.548 6.513 14.548 14.548 C 59.548 42.192 53.035 48.705 45 48.705 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: ${color}; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
      </g>
    </svg>`;
    return divIcon({
      html: `<div style="transform: translate(-6px, -18px)">${pinSvg}</div>`,
      className: 'hide-default-div',
    });
  }
}
