import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneDTO } from 'src/app/api/build/openapi';
import { childFuehrungsebenenSelector, currentFuehrungsebeneSelector } from './+state/fuehrungsebene.selectors';

@Injectable({
  providedIn: 'root',
})
export class FuehrungsebeneDisplayService {
  readonly LOCAL_STORAGE_KEY = 'displayedFuehrungsebenen';
  readonly MAX_DISPLAYED_FUEHRUNGSEBENEN_COUNT = 6;
  private selectedFuehrungsebenen$: BehaviorSubject<Map<string, string[]>> = new BehaviorSubject(
    new Map<string, string[]>()
  );

  /**
   * Angezeigte Führungsebenen ergeben sich aus dem currentFuehrungsebene und ob der Benutzer aktiv Child-Führungsebenen zur Anzeige selektiert hat.
   * Hat er keine Führungsebenen selektiert, werden bis zu 6 Child-Führungsebenen angezeigt. Andernfalls wird seine Auswahl angezeigt.
   */
  displayedFuehrungsebenen$ = new BehaviorSubject<FuehrungsebeneDTO[]>([]);

  constructor(store: Store<AppStateInterface>) {
    combineLatest([
      store.select(currentFuehrungsebeneSelector),
      this.selectedFuehrungsebenen$,
      store.select(childFuehrungsebenenSelector),
    ]).subscribe((value) => {
      const currentFuehrungsebene: FuehrungsebeneDTO | null = value[0];
      // Wenn keine Current-Führungsebene da, können keine Child-Führungsebenen angezeigt werden
      if (!currentFuehrungsebene) {
        this.displayedFuehrungsebenen$.next([]);
        return;
      }
      const selectedFuehrungsebeneeMap: Map<string, string[]> = value[1];
      const childFuehrungsebenen: FuehrungsebeneDTO[] = value[2];

      // Skippen, wenn Children nicht zum Parent passen
      if (childFuehrungsebenen.length && childFuehrungsebenen[0].parentFuehrungsebeneId !== currentFuehrungsebene.id) {
        this.displayedFuehrungsebenen$.next([]);
        return;
      }

      // Prüfen, ob alle ausgewählten Führungsebenen noch existieren und ggf. aufräumen
      const selectedFuehrungsebeneIds = currentFuehrungsebene.id
        ? selectedFuehrungsebeneeMap.get(currentFuehrungsebene.id)
        : [];
      const filteredFuehrungsebeneIds = selectedFuehrungsebeneIds?.filter((fuehrungsebeneId) =>
        childFuehrungsebenen.some((fuehrungsebene) => fuehrungsebene.id === fuehrungsebeneId)
      );

      if (selectedFuehrungsebeneIds?.length !== filteredFuehrungsebeneIds?.length && currentFuehrungsebene?.id) {
        if (filteredFuehrungsebeneIds?.length === 0) {
          this.clearSelectedFuehrungsebenen(currentFuehrungsebene.id);
        } else {
          this.saveSelectedFuehrungsebenen(currentFuehrungsebene.id, filteredFuehrungsebeneIds || []);
        }
        return;
      }

      const hasSelectedFuehrungsebenen =
        currentFuehrungsebene.id && !!selectedFuehrungsebeneeMap.get(currentFuehrungsebene.id);
      // Hat der Benutzer keine Führungsebenen ausgewählt, werden die ersten 6 Child-Führungsebenen angezeigt
      if (!hasSelectedFuehrungsebenen) {
        this.displayedFuehrungsebenen$.next(
          childFuehrungsebenen.slice(0, Math.min(childFuehrungsebenen.length, this.MAX_DISPLAYED_FUEHRUNGSEBENEN_COUNT))
        );
        return;
      }

      // Ansonsten werden die ausgewählten Führungsebenen angezeigt
      this.displayedFuehrungsebenen$.next(
        childFuehrungsebenen
          .filter((fuehrungsebene) => fuehrungsebene.id && selectedFuehrungsebeneIds?.includes(fuehrungsebene.id))
          .slice(0, Math.min(childFuehrungsebenen.length, this.MAX_DISPLAYED_FUEHRUNGSEBENEN_COUNT))
      );
    });

    const displayedFuehrungsebenen = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (!displayedFuehrungsebenen) {
      return;
    }

    this.selectedFuehrungsebenen$.next(new Map(JSON.parse(displayedFuehrungsebenen)));
  }

  /**
   * Liefert eine Liste anzuzeigender Führungsebenen für eine Parent-Führungsebene
   */
  getSelectedFuehrungsebenen(parentFuehrungsebeneId: string): string[] | undefined {
    return this.selectedFuehrungsebenen$.value.get(parentFuehrungsebeneId);
  }

  /**
   * Löscht anzuzeigende Führungsebenen zu übergebener Parent-Führungsebene im LocalStorage
   */
  clearSelectedFuehrungsebenen(parentFuehrungsebeneId: string): void {
    const mapCopy = new Map(this.selectedFuehrungsebenen$.value);
    mapCopy.delete(parentFuehrungsebeneId);

    this.selectedFuehrungsebenen$.next(mapCopy);
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(Array.from(mapCopy.entries())));
  }

  /**
   * Speichert die übergebene Liste anzuzeigender Führungsebenen für eine Parent-Führungsebene im LocalStorage
   */
  saveSelectedFuehrungsebenen(parentFuehrungsebeneId: string, selectedFuehrungsebeneIds: string[]): void {
    const mapCopy = new Map(this.selectedFuehrungsebenen$.value);
    mapCopy.set(parentFuehrungsebeneId, selectedFuehrungsebeneIds);

    this.selectedFuehrungsebenen$.next(mapCopy);
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(Array.from(mapCopy.entries())));
  }
}
