import { createReducer, on } from '@ngrx/store';
import { addOrReplaceWithLatest } from 'src/app/shared/dto-version-tools';
import { LageMassnahmeStateInterface } from './lage-massnahme-state.interface';
import { lageMassnahmeActions } from './lage-massnahme.actions';

const initialState: LageMassnahmeStateInterface = {
  isLoading: false,
  isAdding: false,
  isPatching: false,
  currentMassnahmen: [],
  errorResponse: undefined,
  loadedLageId: '',
};

export const lageMassnahmeReducer = createReducer(
  initialState,
  //Get Massnahmen
  on(lageMassnahmeActions.getLageMassnahmen, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(lageMassnahmeActions.getLageMassnahmenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    currentMassnahmen: action.massnahmen,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(lageMassnahmeActions.getLageMassnahmenFailure, (state, action) => ({
    ...state,
    isLoadingInformation: false,
    errorResponse: action.errorResponse,
  })),
  // Add Lagemassnahme
  on(lageMassnahmeActions.addLageMassnahme, (state) => ({ ...state, isAdding: true, errorResponse: undefined })),
  on(lageMassnahmeActions.addLageMassnahmeSuccess, (state, action) => ({
    ...state,
    isAdding: false,
    currentMassnahmen: [...state.currentMassnahmen, action.massnahme],
    errorResponse: undefined,
  })),
  on(lageMassnahmeActions.addLageMassnahmeFailure, (state, action) => ({
    ...state,
    isAdding: false,
    errorResponse: action.errorResponse,
  })),
  // Patch Lagemassnahme
  on(lageMassnahmeActions.patchLageMassnahme, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(lageMassnahmeActions.patchLageMassnahmeSuccess, (state, action) => ({
    ...state,
    currentMassnahmen: addOrReplaceWithLatest(state.currentMassnahmen, action.patchedMassnahme),
    isPatching: false,
    errorResponse: undefined,
  })),
  on(lageMassnahmeActions.patchLageMassnahmeFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  }))
);
