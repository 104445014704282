import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import {
  BefehlsstelleDTO,
  Beweglichkeit,
  FahrzeugDTO,
  FuehrungsebeneDTO,
  GebaeudeDTO,
} from 'src/app/api/build/openapi';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { KontaktIconsComponent } from 'src/app/lagedarstellung/kontakt/kontakt-icons/kontakt-icons.component';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { fahrzeugByIdSelector } from '../../fahrzeuge/+state/fahrzeug.selectors';
import { gebaeudeByIdSelector } from '../../gebaeude/+state/gebaeude.selectors';
import { TaktischesZeichenPopup } from '../../taktische-zeichen.interface';
import { TaktischesZeichenStatusPipe } from '../../taktisches-zeichen-status.pipe';
import { TzPopupToolbarComponent } from '../../tz-popup-toolbar/tz-popup-toolbar.component';

@Component({
  selector: 'app-befehlsstelle-popup',
  standalone: true,
  imports: [
    CommonModule,
    KontaktIconsComponent,
    DateTimePipe,
    FuehrungsebeneIconAndTextComponent,
    TzPopupToolbarComponent,
    MatTooltipModule,
    TaktischesZeichenStatusPipe,
  ],
  templateUrl: './befehlsstelle-popup.component.html',
  styleUrls: ['./befehlsstelle-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BefehlsstellePopupComponent implements OnInit, TaktischesZeichenPopup {
  @Input({ required: true }) dto!: BefehlsstelleDTO;

  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private store = inject(Store);

  protected parentFuehrungsebene?: FuehrungsebeneDTO;
  protected fahrzeugDTO?: FahrzeugDTO;
  protected gebaeudeDTO?: GebaeudeDTO;

  Beweglichkeit = Beweglichkeit;

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.dto.fuehrungsebeneId);

    if (this.dto.beweglichkeit === Beweglichkeit.Beweglich && this.dto.fahrzeugId) {
      this.store
        .select(fahrzeugByIdSelector(this.dto.fahrzeugId))
        .pipe(take(1))
        .subscribe((fahrzeug) => (this.fahrzeugDTO = fahrzeug));
    } else if (this.dto.beweglichkeit === Beweglichkeit.Ortsfest && this.dto.gebaeudeId) {
      this.store
        .select(gebaeudeByIdSelector(this.dto.gebaeudeId))
        .pipe(take(1))
        .subscribe((gebaeude) => (this.gebaeudeDTO = gebaeude));
    }
  }
}
