import { AsyncPipe, KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import {
  CookieHinweisComponent,
  DatenschutzerklaerungComponent,
  ImpressumComponent,
} from '@product/ise-product-information-lib';
import { PopupManagerService } from 'src/app/lagedarstellung/monitor-manager/popup-manager.service';
import { LageKNavigationComponent } from 'src/app/lagek/lage-k-navigation/lage-k-navigation.component';
import { erzeugeTaktischesZeichen } from 'taktische-zeichen-core';
import { loginPath } from '../../app.routes';
import { AppService, ApplicationType } from '../../app.service';
import { AuthService } from '../../auth/auth.service';
import { WindowManagerService } from '../../lagedarstellung/monitor-manager/window-manager.service';
import { ComponentQualificationService } from '../../misc/component-qualification.service';
import { SimulationConfigDialogComponent } from '../../misc/simulation/simulation-config-dialog/simulation-config-dialog.component';
import { NatsStatusComponent } from '../../shared/nats-status/nats-status.component';
import { FormatService, LageDateTimeFormat } from '../../shared/services/format.service';
import { InformationenNavigationComponent } from '../informationen-navigation/informationen-navigation.component';
import { LageNavigationComponent } from '../lage-navigation/lage-navigation.component';
import { PlanungNavigationComponent } from '../planung-navigation/planung-navigation.component';

@Component({
  selector: 'app-application-toolbar',
  templateUrl: './application-toolbar.component.html',
  styleUrls: ['./application-toolbar.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    RouterLink,
    NgIf,
    MatButtonModule,
    NgClass,
    MatMenuModule,
    MatIconModule,
    NgFor,
    AsyncPipe,
    KeyValuePipe,
    MatDialogModule,
    PlanungNavigationComponent,
    MatTooltipModule,
    InformationenNavigationComponent,
    LageNavigationComponent,
    LageKNavigationComponent,
    NatsStatusComponent,
  ],
})
export class ApplicationToolbarComponent {
  ApplicationType = ApplicationType;

  private destroyRef = inject(DestroyRef);
  private componentService = inject(ComponentQualificationService);
  protected isShowDevelopment$ = this.componentService.isShowDevelopment$;
  protected selectedApplication$ = this.appService.selectedApplicationObservable();

  isAuthenticated = false;
  userName?: string;
  tenant?: string;
  accountManagementUrl?: string;
  userIconPath?: string;
  logoPath: string = '';
  darkMode = false;

  // Damit Enum im Template verfügbar ist
  LageDateTimeFormat = LageDateTimeFormat;
  currentTimeFormat = this.formatService.getLageDateTimeFormat();

  constructor(
    protected router: Router,
    private authService: AuthService,
    private appService: AppService,
    protected formatService: FormatService,
    protected monitorService: WindowManagerService,
    private dialog: MatDialog,
    protected popupManagerService: PopupManagerService
  ) {
    this.authService.isAuthenticated$.pipe(takeUntilDestroyed()).subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });

    this.authService.userInfo$.pipe(takeUntilDestroyed()).subscribe((userInfo) => {
      const username = userInfo.name || userInfo.upn || userInfo.nickname;
      if (username) {
        this.userName = username;
        this.tenant = userInfo.tenant;
        this.accountManagementUrl = userInfo.accountmanagementurl;
        this.userIconPath = this.userIconPath = erzeugeTaktischesZeichen({
          grundzeichen: 'person',
          organisation: 'hilfsorganisation',
          text: this.userName
            ?.split(' ')
            .map((part) => (part.length ? part[0] : ''))
            .join(''),
        }).dataUrl;
      } else {
        this.userName = undefined;
        this.tenant = undefined;
        this.accountManagementUrl = undefined;
        this.userIconPath = undefined;
      }
    });

    this.darkMode = appService.isDarkMode();

    this.appService
      .selectedApplicationObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((a) => (this.logoPath = a !== undefined ? `${a}_Logo.svg` : 'ise.svg'));
  }

  toggleAuth() {
    if (this.isAuthenticated) {
      this.authService.isAuthenticated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isAuthenticated) => {
        if (!isAuthenticated) {
          // Monitore schließen
          this.monitorService.closeSecondaryWindows();
          // Auf Loginseite umleiten
          this.router.navigate([loginPath]);
        }
      });

      this.authService.logout();
    } else {
      this.authService.login();
    }
  }

  toggleDarkMode(event: MouseEvent) {
    event.stopPropagation();
    this.darkMode = this.appService.toggleDarkMode();
  }

  toggleShowDevelopmentComponents(event: MouseEvent) {
    event.stopPropagation();
    this.componentService.toggleShowDevelopment();
  }

  setDateTimeFormat(event: MouseEvent, key: LageDateTimeFormat) {
    event.stopPropagation();
    this.formatService.setLageDateTimeFormat(key);
    this.currentTimeFormat = key;
  }

  openSimulationDialog() {
    this.dialog.open(SimulationConfigDialogComponent, { width: '30rem' });
  }

  openImpressumDialog() {
    this.dialog.open(ImpressumComponent, { width: '95vw', maxHeight: '95vh' });
  }

  openDsgvoDialog() {
    this.dialog.open(DatenschutzerklaerungComponent, { width: '95vw', maxHeight: '95vh' });
  }

  openCookieDialog() {
    this.dialog.open(CookieHinweisComponent, { width: '95vw', maxHeight: '95vh' });
  }

  getMultiMonitorTooltip() {
    return !this.monitorService.hasUserPermission$.value
      ? 'Nicht verfügbar. Browser-Berechtigung "Fensterverwaltung" fehlt.'
      : 'LAGE auf 3 Monitoren darstellen.';
  }
}
