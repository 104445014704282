import { Component, EventEmitter, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { Prioritaet, StabMassnahmeDTO } from 'src/app/api/build/openapi';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LagePrio } from 'src/app/lagedarstellung/massnahmen/massnahme-add-dialog/massnahme-add-dialog.component';
import { StabMassnahmenService } from './stab-massnahmen.service';
import { StabMassnahmeAddDialogComponent } from './stab-massnahme-add-dialog/stab-massnahme-add-dialog.component';
import { MassnahmenService } from 'src/app/lagedarstellung/massnahmen.service';
import { FormsModule, ReactiveFormsModule, FormControl, NonNullableFormBuilder, FormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StabMassnahmeBeendenDialogComponent } from './stab-massnahme-beenden-dialog/stab-massnahme-beenden-dialog.component';

@Component({
  selector: 'app-stab-massnahmen',
  templateUrl: './stab-massnahmen.component.html',
  styleUrls: ['./stab-massnahmen.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgClass,
    AsyncPipe,
    DateTimePipe,
  ],
})
export class StabMassnahmenComponent {
  @Output()
  clickedReload: EventEmitter<void> = new EventEmitter<void>();

  massnahmen: StabMassnahmeDTO[] = [];
  hoveredMassnahmeId = '';

  sortierung: 'date' | 'prio' = 'prio';
  searchControll: FormGroup = inject(NonNullableFormBuilder).group({
    searchstring: [''],
  });
  search = '';

  prioMapping: Map<Prioritaet, LagePrio> = MassnahmenService.prioMapping;
  protected showErledigt = false;

  constructor(public massnahmenService: StabMassnahmenService, private dialog: MatDialog) {
    massnahmenService.currentMassnahmen$.pipe(takeUntilDestroyed()).subscribe((m) => {
      let newM = m;
      if (this.search) {
        newM = this.massnahmenService.getMassnahmenSearch(this.search);
      }
      this.massnahmen = [...newM].sort((a, b) => this.sortMassnahmen(a, b));
    });

    this.searchControll
      .get('searchstring')
      ?.valueChanges.pipe(debounceTime(100), distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((searchstring) => {
        if (searchstring) {
          this.massnahmen = this.massnahmenService
            .getMassnahmenSearch(searchstring)
            .sort((a, b) => this.sortMassnahmen(a, b));
        } else {
          this.massnahmen = [...this.massnahmenService.getMAssnahmenAll()].sort((a, b) => this.sortMassnahmen(a, b));
        }
      });
  }

  onClickHinzufuegen() {
    this.dialog
      .open(StabMassnahmeAddDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined) {
          this.massnahmenService.addMassnahme(result);
        }
      });
  }

  onClickBeenden(massnahme: StabMassnahmeDTO) {
    this.dialog
      .open(StabMassnahmeBeendenDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.massnahmenService.massnahmeBeenden(massnahme, result);
        }
      });
  }

  onClickBearbeiten(massnahme: StabMassnahmeDTO) {
    this.dialog
      .open(StabMassnahmeAddDialogComponent, {
        data: massnahme,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined) {
          this.massnahmenService.updateMassnahme(result);
        }
      });
  }

  onClickShowErledigt() {
    this.showErledigt = !this.showErledigt;
  }

  onClickReload() {
    this.clickedReload.emit();
  }

  updateSortierung() {
    this.massnahmen = [...this.massnahmen].sort((a, b) => this.sortMassnahmen(a, b));
  }

  setHoveredMassnahme(massnahme: StabMassnahmeDTO | undefined) {
    if (massnahme) {
      this.hoveredMassnahmeId = massnahme.id!;
    } else {
      this.hoveredMassnahmeId = '';
    }
  }

  private sortMassnahmen(a: StabMassnahmeDTO, b: StabMassnahmeDTO): number {
    switch (this.sortierung) {
      case 'prio': {
        if (!a || !b) {
          return 0;
        }

        if (a.abgelaufen || b.abgelaufen) {
          return a.abgelaufen ? -1 : 1;
        }

        if (a.erledigt || b.erledigt) {
          return a.erledigt ? 1 : -1;
        }

        if (a.prioritaet === b.prioritaet) {
          const dA: Date = new Date(a.bisWannDatum!);
          const dB: Date = new Date(b.bisWannDatum!);
          return dA.getTime() - dB.getTime();
        } else {
          return this.prioMapping.get(b.prioritaet!)!.wert - this.prioMapping.get(a.prioritaet!)!.wert;
        }
      }

      case 'date':
        return new Date(a.bisWannDatum!).getTime() - new Date(b.bisWannDatum!).getTime();
    }
  }
}
