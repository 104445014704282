<!-- Einsatzräume -->
<h3 *ngIf="showEinsatzraumCount">
  Einsatzräume: ({{ einsatzraumChildrenCountDisplayed }}/{{ einsatzraumChildren.length }})
</h3>

<!-- Einsatzstellen -->
<h3 *ngIf="showEinsatzstelleCount">
  Einsatzstellen: ({{ einsatzstelleChildrenCountDisplayed }}/{{ einsatzstelleChildren.length }})
</h3>

<!-- Einsatzabschnitte -->
<h3 *ngIf="showEinsatzabschnittCount">
  Einsatzabschnitte: ({{ einsatzabschnittChildrenCountDisplayed }}/{{ einsatzabschnittChildren.length }})
</h3>

<!-- Unterabschnitte -->
<h3 *ngIf="showUnterabschnittCount">
  Unterabschnitte: ({{ unterabschnittChildrenCountDisplayed }}/{{ unterabschnittChildren.length }})
</h3>
