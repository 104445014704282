<div class="container">
  <!--div class="tooltip">
    <p>{{ currentTooltip }}</p>
  </div-->

  <!--div class="button-container">
    <button
      class="button"
      *ngFor="let setting of data"
      mat-mini-fab
      color="primary"
      (click)="do(setting.function)"
      color="accent"
      (mouseover)="setTooltip(setting.tooltip)"
      (mouseout)="setTooltip('')"
    >
      <mat-icon>{{ setting.matIcon }}</mat-icon>
    </button>
  </div-->
  <div class="button-container-circle">
    <button
      [class]="'button-circle ' + i"
      *ngFor="let setting of data; index as i"
      mat-mini-fab
      color="accent"
      (click)="do(setting.action)"
      (mouseover)="setTooltip(setting.tooltip)"
      (mouseout)="setTooltip('')"
      [matTooltip]="currentTooltip"
    >
      <mat-icon fontSet="material-icons-outlined">{{ setting.matIcon }}</mat-icon>
    </button>
  </div>
</div>
