import { Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store, select } from '@ngrx/store';
import { ConsumerMessages, DebugEvents, Events, NatsConnection, connect, jwtAuthenticator } from 'nats.ws';
import {
  BehaviorSubject,
  EMPTY,
  OperatorFunction,
  Subject,
  catchError,
  combineLatest,
  filter,
  from,
  iif,
  map,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  AuthenticationResourceService,
  ConsumerDTO,
  CreateConsumerDTO,
  JwtDTO,
  NatsConnectionDTO,
} from 'src/app/api/build/openapi';
import { IseSourceIdService } from 'src/app/auth/ise-source-id.service';
import { currentLageSelector } from 'src/app/lagedarstellung/lagen/+state/lage.selectors';

import { combinedLoadedLageSelector } from 'src/app/+state/meta.selectors';
import { NatsEventHandlerService } from './nats-event-handler.service';

export enum NatsConnectionStatus {
  UNKNOWN = 'unbekannt',
  CONNECTING = 'verbinde ...',
  CONNECTED = 'verbunden',
  WARNING = 'Warnung',
  ERROR = 'Fehler',
}

// TODO Fehlerbehandlung / -darstellung
// TODO ist die Zeit zwischen allg. Auflistung Lagen und wechsel auf konkrete Lage problematisch, wenn zu schnell geklickt, aber Netzwerk langsam? -> delay in "lagenLoadedInStates$"

@Injectable({
  providedIn: 'root',
})
export class NatsConnectorService implements OnDestroy {
  // Encoder für den 'jwtAuthenticator'
  private encoder = new TextEncoder();

  // Das aktuelle JWT für die NATS-Auth. Wird regelmäßig aktualisiert.
  private natsJwt$ = new Subject<JwtDTO>();

  /**
   * DTO mit Infos zur aktuellen Connection
   */
  private natsConnectionDTO$ = new Subject<NatsConnectionDTO>();

  /**
   * Aktuelle Verbindung zu NATS
   */
  private natsConnection$ = new BehaviorSubject<NatsConnection | undefined>(undefined);

  /**
   * DTO zum aktuellen Consumer
   */
  private currentConsumerDTO$ = new Subject<ConsumerDTO>();

  /**
   * Aktueller und aktiver Consumer
   */
  private activeConsumer$ = new BehaviorSubject<ConsumerMessages | undefined>(undefined);

  /**
   * Aktuelles DTO mit dem der Client einen Consumer im Backend anfragt/aktualisiert
   */
  private createConsumerDTO$ = new BehaviorSubject<CreateConsumerDTO | undefined>(undefined);

  /**
   * Aktuell ausgewählte Lage, die aber ggf. noch nicht geladen ist! siehe `loadedLageId$`
   */
  private currentSelectedLage$ = this.store.pipe(takeUntilDestroyed(), select(currentLageSelector));

  /**
   * ID der aktuell im Store geladenen Lage
   */
  private loadedLageId$ = new Subject<string>();

  /**
   * Subject mit dem das Schließen des aktuellen Consumers und das Abbauen der aktuellen NATS-Verbindung ausgelöst werden soll
   */
  private closeCurrentConnectionSubject$ = new Subject();

  natsConnectionStatus$ = new BehaviorSubject<NatsConnectionStatus>(NatsConnectionStatus.UNKNOWN);

  ngOnDestroy(): void {
    this.closeCurrentConnectionSubject$.next(null);
  }

  constructor(
    private authApiService: AuthenticationResourceService,
    private natsHandler: NatsEventHandlerService,
    private store: Store<AppStateInterface>,
    private iseSourceIdService: IseSourceIdService
  ) {
    // Schließen des aktuellen Consumers und Abbauen der aktuellen NATS-Verbindung
    this.closeCurrentConnectionSubject$
      .pipe(
        takeUntilDestroyed(),
        switchMap(() => {
          const activeConsumer = this.activeConsumer$.getValue();
          return activeConsumer ? from(activeConsumer.close()) : EMPTY;
        }),
        switchMap(() => {
          const currentConnection = this.natsConnection$.getValue();
          return currentConnection ? from(currentConnection.drain()) : EMPTY;
        })
      )
      .subscribe();

    // Sobald ein JWT existiert, wird ein Timer mit der angegebenen Gültigkeitstdauer des JWT gestartet (Pufferzeit im BE enthalten, hier nicht notwendig).
    // Mit Auslösen des Timers wird ein neues JWT angefordert, ersetzt und damit der nächste Timer gesetzt
    this.natsJwt$
      .pipe(
        takeUntilDestroyed(),
        map((jwtDto) => jwtDto.duration || 120), // 120 sec default
        switchMap((jwtDuration) => timer(jwtDuration * 1000)), // startet unmittelbar
        switchMap(this.updateJWT$) // nach Ablauf wird JWT-Update ausgelöst
      )
      .subscribe();

    // Wenn es neue "Connection Infos" oder ein neues "NATS JWT" gibt, eine neue "NATS Connection" erzeugen.
    //
    // Aktuellen Consumer "schließen", um keine Events beim Wechsel zu verlieren - Consumer bleibt noch eine Weile im BE
    // Aktuelle NATS-Connection beenden
    // neue Verbindung mit neuem JWT aufbauen
    // Neue Connection lokal setzen
    combineLatest([this.natsConnectionDTO$, this.natsJwt$])
      .pipe(
        takeUntilDestroyed(),
        // TODO die Reihenfolge wichtig? Ist die immer korrekt?
        tap(() => this.closeCurrentConnectionSubject$.next(null)),
        // delay(15_000), // HACK Unterbrechung zwischen Beenden/Schließen des/der NATS-Consumers/Verbindung für manuellen Test, ob Events korrekt "nachgesendet" werden. Events müssen in diesen 15 sec manuell ausgelöst werden.
        switchMap(([connection, jwtDto]) => {
          // console.log('create new Connection');
          const authenticator = jwtAuthenticator(jwtDto.jwt, this.encoder.encode(jwtDto.seedNKey));
          // TODO was ist, wenn Verbindung nicht aufgebaut werden konnte? - "maximum control line exceeded"; nach BE Neustart Problem weg
          return from(connect({ servers: connection.websocketUrl, authenticator })).pipe(
            catchError((err) => this.handleError(err, 'connect to NATS'))
          );
        })
      )
      .subscribe((natsConnection: NatsConnection) => {
        // neue Verbindung setzen
        natsConnection.closed().then((err) => {
          if (err) {
            this.natsConnectionStatus$.next(NatsConnectionStatus.ERROR);
          } else {
            this.natsConnectionStatus$.next(NatsConnectionStatus.UNKNOWN);
          }
        });

        this.natsConnection$.next(natsConnection);
      });

    this.natsConnection$
      .pipe(
        takeUntilDestroyed(),
        filter((v) => !!v) as OperatorFunction<NatsConnection | undefined, NatsConnection>, // TODO Filter-Methode extrahieren?
        switchMap((connection: NatsConnection) => {
          this.natsConnectionStatus$.next(NatsConnectionStatus.CONNECTED);
          return connection.status();
        })
      )
      .subscribe((status) => {
        const type = status.type;

        switch (type) {
          case DebugEvents.Reconnecting:
            this.natsConnectionStatus$.next(NatsConnectionStatus.CONNECTING);
            break;
          case Events.Reconnect:
            this.natsConnectionStatus$.next(NatsConnectionStatus.CONNECTED);
            break;
          case Events.Disconnect:
            this.natsConnectionStatus$.next(NatsConnectionStatus.ERROR);
            break;
          case Events.Error:
            this.natsConnectionStatus$.next(NatsConnectionStatus.ERROR);
            break;
        }
      });

    /**
     * Es gibt eine neue Connection oder die selektierte Lage hat sich verändert: Consumer erstellen lassen und aktuelles ConsumerDTO aktualisieren
     *
     */
    combineLatest([this.natsConnection$, this.createConsumerDTO$])
      .pipe(
        takeUntilDestroyed(),
        filter(([connection, consumerDto]) => !!connection && !!consumerDto),
        switchMap(([connection, consumerDto]) =>
          this.authApiService.createConsumer(consumerDto).pipe(
            map((consumerDto: ConsumerDTO) => ({
              conn: connection,
              consumerDto,
            }))
          )
        )
      )
      .subscribe((consumer) => {
        this.currentConsumerDTO$.next(consumer.consumerDto);
      });

    /**
     * Ändert sich der Consumer (DTO) und ist eine natsConnection vorhanden, dann wird ein neues
     */
    this.currentConsumerDTO$
      .pipe(
        takeUntilDestroyed(),
        switchMap((currentConsumerDto) =>
          iif(
            () => !!this.natsConnection$.getValue(),
            from(
              // durch iif abgesichert
              // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
              this.natsConnection$
                .getValue()!
                .jetstream()
                .consumers.get(currentConsumerDto.streamName, currentConsumerDto.consumerName)
            ),
            EMPTY
          )
        ),
        switchMap((consumer) => from(consumer.consume({ callback: this.natsHandler.handle })))
      )
      .subscribe((activeConsumer) => {
        this.activeConsumer$.next(activeConsumer);
      });

    // Sicherstellen, dass die ausgewählte Lage komplett geladen ist
    combineLatest([this.lagenLoadedInStates$, this.currentSelectedLage$])
      .pipe(
        takeUntilDestroyed(),
        map(([loaded, selected]) => [loaded, selected && selected.id ? selected.id : '']),
        map((loadedLagenIds) => new Set(loadedLagenIds)),
        filter((set) => set.size === 1),
        map((set) => set.values().next().value)
      )
      .subscribe((lageId: string) => {
        this.loadedLageId$.next(lageId);

        // für konkrete Lage anmelden
        if (lageId) {
          this.updateSubscriptions(lageId, true, false);
        }
      });

    /*
    Initialisierung
    */

    // initiale NATS-Verindungsinfos holen
    // TODO müssen die Infos nochmal aktualisiert werden?
    this.authApiService
      .getNatsConnection()
      .pipe(take(1))
      .subscribe((connectionDto) => this.natsConnectionDTO$.next(connectionDto));
    // initiale NATS-JWT Infos holen
    this.authApiService
      .getJwt()
      .pipe(take(1))
      .subscribe((jwtDto: JwtDTO) => this.natsJwt$.next(jwtDto));

    /*
    Logs-Subscribtions für Entwicklung!
    */

    // Loggt, welche Lage geladen ist.
    // loadedLageId$
    //   .pipe(takeUntilDestroyed())
    //   .subscribe((loadedLageId) =>
    //     console.log(
    //       'combineLatest selected and loaded Lage ID:',
    //       loadedLageId === '' ? '<nicht gesetzt>' : loadedLageId
    //     )
    //   );

    // aktiven Consumer beobachten
    // interval(10_000)
    //   .pipe(takeUntilDestroyed())
    //   .subscribe(() => {
    //     const consumer = this.activeConsumer$.getValue() as PullConsumerMessagesImpl;
    //     console.log('monitoring activeConsumer', consumer?.consumer?.name, consumer);
    //   });

    // this.activeConsumer$
    //   .pipe(takeUntilDestroyed())
    //   .subscribe((consumer) => console.log('activeConsumer set', consumer));

    // Neues CreateConsumerDTO anlegen
    // this.createConsumerDTO$.pipe(takeUntilDestroyed()).subscribe((v) => console.log('createConsumerDTO', v));
  }

  /**
   *  Bereitet das CreateConsumerDTO vor und aktualisiert dieses
   *
   * @param subscribeForLageId optionale Lage-ID auf die gehört werden soll
   * @param subscribeForLagen soll übergeordnet auf alle Lagen gehört werden?
   * @param subscribeForPlanung soll auf Events im Planungs-Bereich gehört werden?
   */
  public updateSubscriptions = (
    subscribeForLageId: string | undefined,
    subscribeForLagen: boolean,
    subscribeForPlanung: boolean
  ) => {
    // console.log(
    //   'update subscribtion',
    //   'LageID',
    //   subscribeForLageId,
    //   'AllLagen',
    //   subscribeForLagen,
    //   'Planung',
    //   subscribeForPlanung
    // );

    const newCreateConsumerDTO: CreateConsumerDTO = {
      clientId: this.iseSourceIdService.getIseSourceId(),
      subscribeForPlanung,
      subscribeForLagen,
    };

    if (subscribeForLageId) {
      newCreateConsumerDTO.subscribeForLageId = subscribeForLageId;
    }

    this.createConsumerDTO$.next(newCreateConsumerDTO);
  };

  /**
   * Fragt ein neues JWT vom Backend an und aktualisiert dies lokal.
   */
  private updateJWT$ = () => this.authApiService.getJwt().pipe(tap((jwtDto) => this.natsJwt$.next(jwtDto)));

  /**
   *
   */
  private lagenLoadedInStates$ = this.store.pipe(
    select(combinedLoadedLageSelector),
    map((loadedLagenIds) => new Set(Object.values(loadedLagenIds))),
    filter((set) => set.size === 1),
    map((set) => set.values().next().value)
    // tap((v) => console.log('lagenLoadedInStates$ BEFORE delay', v)),
    // delay(10_000), // HACK Unterbrechung beim Setzen der aktuellen Lage-ID, um zu prüfen, ob Events, die während des Ladens einer Lage später im Anschluss verarbeitet werden - ist das ein sicherer Test?
    // tap((v) => console.log('lagenLoadedInStates$ AFTER delay', v))
  );

  /**
   * Simpler Error-Handler
   *
   * // TODO verbessern
   *
   * @param error
   * @param name
   * @returns
   */
  private handleError = (error: Error, name: string) => {
    console.error('error in: ', name, error);
    this.natsConnectionStatus$.next(NatsConnectionStatus.ERROR);
    return EMPTY;
  };
}
