import { Injectable } from '@angular/core';
import {
  AnalogBand,
  AnalogBetriebsart,
  AnalogRelaisbetrieb,
  AnalogTonruf,
  DigitalBetriebsart,
  Funktechnik,
  Kommunikation,
  KontaktTyp,
} from 'src/app/api/build/openapi';

@Injectable({
  providedIn: 'root',
})
export class KontaktService {
  /**
   * Mappings für verschiedene Enums mit Text und teilweise Icons für die Darstellung
   */

  public readonly KontaktTypMapping = new Map([
    [KontaktTyp.Email, { text: 'E-Mail', icon: 'mail' }],
    [KontaktTyp.Fax, { text: 'Fax', icon: 'fax' }],
    [KontaktTyp.Festnetz, { text: 'Festnetz', icon: 'phone' }],
    [KontaktTyp.Mobiltelefon, { text: 'Mobiltelefon', icon: 'smartphone' }],
    [KontaktTyp.Funk, { text: 'Funk', icon: 'cell_tower' }],
  ]);

  public readonly FunktechnikMapping = new Map([
    [Funktechnik.Digital, 'Digitalfunk BOS'],
    [Funktechnik.Analog, 'Analog BOS Funk'],
  ]);

  public readonly DigitalBetriebsartMapping = new Map([
    [DigitalBetriebsart.Tmo, 'TMO'],
    [DigitalBetriebsart.Dmo, 'DMO'],
  ]);

  public readonly AnalogBandMapping = new Map([
    [AnalogBand.ZweiMeter, '2m'],
    [AnalogBand.VierMeter, '4m'],
  ]);

  public readonly AnalogBetriebsartMapping = new Map([
    [AnalogBetriebsart.W, 'W - Simplex / Wechselsprechen'],
    [AnalogBetriebsart.G, 'G - Duplex / Gegensprechen'],
    [AnalogBetriebsart.Bg, 'bG - Semi-, Halbduplex / bedingtes Gegensprechen'],
  ]);

  public readonly AnalogRelaisbetriebMapping = new Map([
    [AnalogRelaisbetrieb.Rs1, 'RS1'],
    [AnalogRelaisbetrieb.Rs2, 'RS2'],
    [AnalogRelaisbetrieb.Rs3, 'RS3'],
    [AnalogRelaisbetrieb.Rs4, 'RS4'],
  ]);

  public readonly AnalogTonrufMapping = new Map([
    [AnalogTonruf.Tonruf1, 'Tonruf I'],
    [AnalogTonruf.Tonruf2, 'Tonruf II'],
  ]);

  /**
   * Fasst Kommunkation-Model textuell zusammen, damit alle Informationen in einer Zeile angezeigt werden können.
   */
  public summarizeKommunikation(kommunikation: Kommunikation): string {
    if (!kommunikation.kontaktTyp) {
      return '';
    }
    const prefix = `[${this.KontaktTypMapping.get(kommunikation.kontaktTyp)?.text}] ${kommunikation.name}`;
    switch (kommunikation.kontaktTyp) {
      case KontaktTyp.Mobiltelefon:
        return `${prefix}: ${kommunikation.mobiltelefon}`;
      case KontaktTyp.Festnetz:
        return `${prefix}: ${kommunikation.festnetz}`;
      case KontaktTyp.Fax:
        return `${prefix}: ${kommunikation.fax}`;
      case KontaktTyp.Email:
        return `${prefix}: ${kommunikation.email}`;
      case KontaktTyp.Funk:
        if (kommunikation.funktechnik == Funktechnik.Digital) {
          const betriebsart = kommunikation.digitalBetriebsart
            ? this.DigitalBetriebsartMapping.get(kommunikation.digitalBetriebsart)
            : '';
          return `${prefix}: Digital, ${betriebsart}, ${kommunikation.digitalFunkrufgruppe}, ${kommunikation.funkrufname}`;
        } else {
          const band = kommunikation.analogBand ? this.AnalogBandMapping.get(kommunikation.analogBand) : '';
          const betriebsart = kommunikation.analogBetriebsart
            ? this.AnalogBetriebsartMapping.get(kommunikation.analogBetriebsart)?.split(' ')[0]
            : '';
          const relais = kommunikation.analogRelaisbetrieb
            ? this.AnalogRelaisbetriebMapping.get(kommunikation.analogRelaisbetrieb)
            : '';
          const tonruf = kommunikation.analogTonruf ? this.AnalogTonrufMapping.get(kommunikation.analogTonruf) : '';
          return `${prefix}: Analog, ${band}, ${betriebsart}, ${relais}, ${tonruf}, ${kommunikation.analogFunkkanal}, ${kommunikation.funkrufname}`;
        }
      default:
        return '';
    }
  }
}
