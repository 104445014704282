import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorService } from '@product/ise-error-lib';
import { EMPTY, catchError } from 'rxjs';
import {
  AddZeichenToLageDTO,
  BibliothekResourceService,
  ErrorResponse,
  FahrzeugDTO,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischesZeichenStatus,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { TaktischeZeichenSelectionService } from 'src/app/planung/bibliothek/verwaltungsebene-details/taktische-zeichen-selection.service';
import { BibliothekBaseComponent } from '../../../planung/bibliothek/bibliothek-base/bibliothek-base.component';
import { TaktischeZeichenItemComponent } from '../taktische-zeichen-container/taktische-zeichen-item/taktische-zeichen-item.component';

export interface BibliothekSelectionDialogInputData {
  lageId: string;
  fuehrungsebeneId: string;
  state: TaktischesZeichenStatus;
}

@Component({
  selector: 'app-bibliothek-selection-dialog',
  standalone: true,
  templateUrl: './bibliothek-selection-dialog.component.html',
  styleUrls: ['./bibliothek-selection-dialog.component.scss'],
  imports: [
    CommonModule,
    BibliothekBaseComponent,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    TaktischeZeichenItemComponent,
  ],
})
export class BibliothekSelectionDialogComponent {
  private errorService = inject(ErrorService);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: BibliothekSelectionDialogInputData,
    public dialogRef: MatDialogRef<BibliothekSelectionDialogComponent>,
    public selectionService: TaktischeZeichenSelectionService,
    private bibliothekService: BibliothekResourceService
  ) {
    dialogRef
      .beforeClosed()
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.selectionService.clear());
  }

  removeAt(index: number) {
    this.selectionService.removeAt(index);
  }

  /**
   * Übermittelt (beim Schließen des Dialogs) die selektierten Zeichen aus der Bibliothek (im SelektionMode) ans Backend.
   * Im Backend werden die betroffenen Zeichen der übergebenen Lage bzw. Führungsebene zugewiesen.
   * Mittels Events werden die Clients über aktualisierte Zeichen informiert.
   *
   */
  submitSelection() {
    const addToLage: AddZeichenToLageDTO = {
      lageId: this.data.lageId,
      fuehrungsebeneId: this.data.fuehrungsebeneId,
      statusInfo: { status: this.data.state, zeitpunktStatusWechsel: new Date().toISOString() },
      fahrzeuge: this.selectionService.selectedTaktischeZeichen$.value.filter(
        (z) => z.typ === TaktischesZeichenTyp.Fahrzeug
      ) as FahrzeugDTO[],
      personen: this.selectionService.selectedTaktischeZeichen$.value.filter(
        (z) => z.typ === TaktischesZeichenTyp.Person
      ) as PersonDTO[],
      taktischeFormationen: this.selectionService.selectedTaktischeZeichen$.value.filter(
        (z) => z.typ === TaktischesZeichenTyp.TaktischeFormation
      ) as TaktischeFormationDTO[],
    };

    this.bibliothekService
      .addZeichenToLage(addToLage)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorResponse: ErrorResponse = error.error;
          this.errorService.showError(errorResponse);
          return EMPTY;
        })
      )
      .subscribe(() => this.dialogRef.close());
  }
}
