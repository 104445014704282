import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { Observable, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { BefehlsstelleDTO, PersonDTO, StelleDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { AuftragListComponent } from 'src/app/lagedarstellung/auftrag/auftrag-list/auftrag-list.component';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { TzComboboxComponent } from 'src/app/shared/tz-combobox/tz-combobox.component';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import {
  Fachaufgabe,
  GrundzeichenId,
  Organisation,
  Symbol,
  TaktischesZeichen,
  erzeugeTaktischesZeichen,
  fachaufgaben,
  organisationen,
  symbole,
} from 'taktische-zeichen-core';
import { befehlsstellenSelector } from '../../befehlsstelle/+state/befehlsstelle.selectors';
import { personenSelector } from '../../personen/+state/person.selectors';
import { DataUrlChangeEvent } from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

/**
 * Aufträge müssen noch angebunden werden. Werden noch nicht persistiert.
 */
@Component({
  selector: 'app-stelle-einrichtung-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    KontaktListComponent,
    AuftragListComponent,
    CleanableFormFieldComponent,
    TzComboboxComponent,
    MatInputModule,
  ],
  templateUrl: './stelle-einrichtung-form.component.html',
  styleUrls: ['./stelle-einrichtung-form.component.scss'],
})
export class StelleEinrichtungFormComponent implements TaktischesZeichenForm {
  @ViewChild(KontaktListComponent)
  kontaktList!: KontaktListComponent;

  @ViewChild(AuftragListComponent)
  auftragList!: AuftragListComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  dataUrlChanged = new EventEmitter<DataUrlChangeEvent>();

  stelleToEdit?: StelleDTO;

  readonly EMPTY_ZEICHEN: TaktischesZeichen = { grundzeichen: 'stelle' };
  editorZeichen: TaktischesZeichen = { ...this.EMPTY_ZEICHEN };
  currentZeichenDataUrl: string = erzeugeTaktischesZeichen(this.editorZeichen).dataUrl;
  customZeichen = false;

  // Dropdown-Werte
  organisationValues: Organisation[] = organisationen.sort(compareLabels);
  fachaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  symbolValues: Symbol[] = symbole.sort(compareLabels);
  leiterValues$: Observable<PersonDTO[]>;
  befehlsstelleValues$: Observable<BefehlsstelleDTO[]>;

  organisationMouseover = false;
  fachaufgabeMouseover = false;
  symbolMouseover = false;
  leiterMouseover = false;
  befehlsstelleMouseover = false;

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);

  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcGrundzeichenId = this.formBuilder.control<GrundzeichenId>('stelle');
  fcFachaufgabe = this.formBuilder.control<Fachaufgabe | undefined>(undefined);
  fcOrganisation = this.formBuilder.control<Organisation | undefined>(undefined);
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [
    Validators.maxLength(255),
    Validators.pattern('[a-zA-Z0-9-/]*'),
  ]);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcLeiter = this.formBuilder.control<PersonDTO | undefined>(undefined);
  fcBefehlsstelle = this.formBuilder.control<BefehlsstelleDTO | undefined>(undefined);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    grundzeichenId: this.fcGrundzeichenId,
    fachaufgabe: this.fcFachaufgabe,
    organisation: this.fcOrganisation,
    symbol: this.fcSymbol,
    zeichenText: this.fcZeichenText,
    ortsangabe: this.fcOrtsangabe,
    leiter: this.fcLeiter,
    befehlsstelle: this.fcBefehlsstelle,
  });

  constructor(store: Store<AppStateInterface>) {
    this.leiterValues$ = store.select(personenSelector).pipe(takeUntilDestroyed());
    this.befehlsstelleValues$ = store.select(befehlsstellenSelector).pipe(takeUntilDestroyed());

    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });

    this.fcGrundzeichenId.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.grundzeichen = v;
        this.generateTaktischesZeichen();
      }
    });

    this.fcFachaufgabe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.fachaufgabe = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.organisation = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.symbol = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen && this.fcZeichenText.valid) {
        this.editorZeichen.text = v?.trim();
        this.generateTaktischesZeichen();
      }
    });
  }

  setCustomTz(dataUrl: string) {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
      this.customZeichen = true;
    } else {
      this.customZeichen = false;
      this.generateTaktischesZeichen();
    }
  }

  setDTO(stelleDTO: StelleDTO) {
    this.stelleToEdit = stelleDTO;
    if (this.stelleToEdit) {
      this.dtoToFormGroup(this.stelleToEdit);
      if (this.stelleToEdit.customZeichen && this.stelleToEdit.dataUrl) {
        this.generateTaktischesZeichen(this.stelleToEdit.dataUrl);
        return;
      }
    }
    this.generateTaktischesZeichen();
  }

  /**
   * Aktualisiertes StelleDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): StelleDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): StelleDTO {
    return {
      ...this.stelleToEdit,
      anzeigename: this.fcAnzeigename.value,
      grundzeichen: this.fcGrundzeichenId.value,
      organisation: this.fcOrganisation.value?.id,
      fachaufgabe: this.fcFachaufgabe.value?.id,
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      ortsangabe: this.fcOrtsangabe.value?.trim(),
      leiterId: this.fcLeiter.value?.id,
      befehlsstelleId: this.fcBefehlsstelle.value?.id,
      dataUrl: this.currentZeichenDataUrl,
      kommunikationOptionen: this.kontaktList.getKommunikationOptionen(),
      auftraege: this.auftragList.getAuftraege(),
      customZeichen: this.customZeichen,
      typ: TaktischesZeichenTyp.Stelle,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(stelleDTO: StelleDTO): void {
    this.fcAnzeigename.setValue(stelleDTO.anzeigename);
    this.fcGrundzeichenId.setValue((stelleDTO.grundzeichen as GrundzeichenId) || 'stelle');
    this.fcOrganisation.setValue(organisationen.find((v) => v.id === stelleDTO.organisation));
    this.fcFachaufgabe.setValue(fachaufgaben.find((v) => v.id === stelleDTO.fachaufgabe));
    this.fcSymbol.setValue(symbole.find((v) => v.id === stelleDTO.symbol));
    this.fcZeichenText.setValue(stelleDTO.zeichenText);
    this.fcOrtsangabe.setValue(stelleDTO.ortsangabe);

    if (stelleDTO.befehlsstelleId) {
      this.befehlsstelleValues$
        .pipe(take(1))
        .subscribe((befehlsstelle) =>
          this.fcBefehlsstelle.setValue(
            befehlsstelle.find((befehlsstelle) => befehlsstelle.id === stelleDTO.befehlsstelleId)
          )
        );
    }
    if (stelleDTO.leiterId) {
      this.leiterValues$
        .pipe(take(1))
        .subscribe((leiter) => this.fcLeiter.setValue(leiter.find((l) => l.id === stelleDTO.leiterId)));
    }
    this.auftragList.setAuftraege(stelleDTO.auftraege || []);
    this.customZeichen = stelleDTO.customZeichen || false;
  }

  /**
   * DataURL an Parent senden und dort aktualisieren.
   */
  generateTaktischesZeichen(dataUrl = '') {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
    } else {
      const zeichen = erzeugeTaktischesZeichen(this.editorZeichen);
      this.currentZeichenDataUrl = zeichen.dataUrl;
    }
    this.dataUrlChanged.emit({ dataUrl: this.currentZeichenDataUrl, customZeichen: this.customZeichen });
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
