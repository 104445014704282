import { Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZeitstrahlComponent } from './zeitstrahl/zeitstrahl.component';
import {
  FuehrungsebeneMassnahmeDTO,
  StabMassnahmeDTO,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ZeitstrahlService } from './zeitstrahl.service';
import { StabMassnahmenComponent } from '../stab-massnahmen/stab-massnahmen.component';
import {
  EditTzDialogData,
  TaktischeZeichenDialogComponent,
} from 'src/app/lagedarstellung/taktische-zeichen/taktische-zeichen-dialog/taktische-zeichen-dialog.component';

@Component({
  selector: 'app-massnahmen-dashboard',
  templateUrl: './massnahmen-dashboard.component.html',
  styleUrls: ['./massnahmen-dashboard.component.scss'],
  standalone: true,
  imports: [CommonModule, ZeitstrahlComponent, StabMassnahmenComponent, MatDialogModule],
})
export class MassnahmenDashboardComponent {
  private dialog: MatDialog = inject(MatDialog);
  private zeitstrahlservice: ZeitstrahlService = inject(ZeitstrahlService);

  @ViewChild(StabMassnahmenComponent)
  massnahmen!: StabMassnahmenComponent;

  @ViewChild(ZeitstrahlComponent)
  zeitstrahl!: ZeitstrahlComponent;

  onClickZeitstrtahlElement(massnahme: StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO) {
    if (ZeitstrahlService.isStabMassnahme(massnahme)) {
      this.massnahmen.onClickBearbeiten(massnahme);
    } else {
      this.editTaktischesZeichen(massnahme, TaktischesZeichenTyp.Massnahme);
    }
  }

  onHoverZeitstrahlElement(massnahme: StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO | undefined) {
    if (massnahme && ZeitstrahlService.isStabMassnahme(massnahme)) {
      this.massnahmen!.setHoveredMassnahme(massnahme);
    } else {
      this.massnahmen!.setHoveredMassnahme(undefined);
    }
  }

  onElementBeenden(massnahme: StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO) {
    if (massnahme && ZeitstrahlService.isStabMassnahme(massnahme)) {
      this.massnahmen.onClickBeenden(massnahme);
    } else {
      this.editTaktischesZeichen(massnahme, TaktischesZeichenTyp.Massnahme);
    }
  }

  onClickReloadMassnahmen() {
    this.zeitstrahlservice.reload();
    this.zeitstrahl.updateDate();
  }

  editTaktischesZeichen(
    dto: StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO,
    taktischesZeichenType: TaktischesZeichenTyp
  ) {
    const editTzDialogData: EditTzDialogData = {
      dto: dto as TaktischesZeichenDTO,
      taktischesZeichenTyp: taktischesZeichenType,
    };
    this.dialog.open(TaktischeZeichenDialogComponent, {
      data: editTzDialogData,
      disableClose: false,
    });
  }
}
