import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  BibFahrzeugDTO,
  BibPersonDTO,
  BibTaktischeFormationDTO,
  FahrzeugDTO,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { bibliothekActions } from './+state/bibliothek.actions';
import { BibTzDTO } from './verwaltungsebene-details/verwaltungsebene-details.component';

@Injectable({
  providedIn: 'root',
})
export class BibliothekZeichenService {
  constructor(private store: Store<AppStateInterface>, private actions$: Actions) {}

  saveBibZeichenDto = (dtoToSave: BibTzDTO, successCallback?: (bibTzDto: BibTzDTO) => void) => {
    if (dtoToSave.id) {
      switch (dtoToSave.typ) {
        case TaktischesZeichenTyp.Fahrzeug:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.patchFahrzeugSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.patchedFahrzeug));
          }
          this.store.dispatch(
            bibliothekActions.patchFahrzeug({ fahrzeugId: dtoToSave.id, fahrzeugDTO: dtoToSave as BibFahrzeugDTO })
          );
          break;
        case TaktischesZeichenTyp.Person:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.patchPersonSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.patchedPerson));
          }
          this.store.dispatch(
            bibliothekActions.patchPerson({ personId: dtoToSave.id, personDTO: dtoToSave as BibPersonDTO })
          );
          break;
        case TaktischesZeichenTyp.TaktischeFormation:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.patchTaktischeFormationSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.patchedTaktischeFormation));
          }
          this.store.dispatch(
            bibliothekActions.patchTaktischeFormation({
              taktischeFormationId: dtoToSave.id,
              taktischeFormationDTO: dtoToSave as BibTaktischeFormationDTO,
            })
          );
          break;
      }
    } else {
      switch (dtoToSave.typ) {
        case TaktischesZeichenTyp.Fahrzeug:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.createFahrzeugSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.newFahrzeug));
          }
          this.store.dispatch(bibliothekActions.createFahrzeug({ fahrzeug: dtoToSave as BibFahrzeugDTO }));
          break;
        case TaktischesZeichenTyp.Person:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.createPersonSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.newPerson));
          }
          this.store.dispatch(bibliothekActions.createPerson({ person: dtoToSave as BibPersonDTO }));
          break;
        case TaktischesZeichenTyp.TaktischeFormation:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.createTaktischeFormationSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.newTaktischeFormation));
          }
          this.store.dispatch(
            bibliothekActions.createTaktischeFormation({ taktischeFormation: dtoToSave as BibTaktischeFormationDTO })
          );
          break;
      }
    }
  };

  isBibZeichen(tzDto: TaktischesZeichenDTO) {
    if (
      tzDto.typ === TaktischesZeichenTyp.Person ||
      tzDto.typ === TaktischesZeichenTyp.Fahrzeug ||
      tzDto.typ === TaktischesZeichenTyp.TaktischeFormation
    ) {
      return !(tzDto as FahrzeugDTO | PersonDTO | TaktischeFormationDTO).lageId;
    }
    return false;
  }
}
