import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EinsatzortDTO } from 'src/app/api/build/openapi';

@Component({
  selector: 'app-einsatzort-form',
  standalone: true,
  imports: [ReactiveFormsModule, MatInputModule, MatFormFieldModule],
  templateUrl: './einsatzort-form.component.html',
  styleUrl: './einsatzort-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinsatzortFormComponent implements OnChanges {
  @Input() header = 'Einsatzort';
  @Input() einsatzOrtDTO?: EinsatzortDTO;

  private formBuilder = inject(NonNullableFormBuilder);

  fcLand = this.formBuilder.control<string | undefined>(undefined);
  fcBundesland = this.formBuilder.control<string | undefined>(undefined);
  fcPlz = this.formBuilder.control<string | undefined>(undefined);
  fcGemeinde = this.formBuilder.control<string | undefined>(undefined);
  fcGemeindeteil = this.formBuilder.control<string | undefined>(undefined);
  fcStrasse = this.formBuilder.control<string | undefined>(undefined);
  fcHausnummer = this.formBuilder.control<string | undefined>(undefined);
  fcObjekt = this.formBuilder.control<string | undefined>(undefined);
  fcRegierungsbezirk = this.formBuilder.control<string | undefined>(undefined);
  fcLandkreis = this.formBuilder.control<string | undefined>(undefined);
  fcKreisfreieStadt = this.formBuilder.control<string | undefined>(undefined);
  fcVerbandsgemeinde = this.formBuilder.control<string | undefined>(undefined);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['einsatzOrtDTO']) {
      this.dtoToFormGroup();
    }
  }

  private dtoToFormGroup() {
    if (!this.einsatzOrtDTO) {
      return;
    }
    this.fcLand.setValue(this.einsatzOrtDTO.land);
    this.fcBundesland.setValue(this.einsatzOrtDTO.bundesland);
    this.fcPlz.setValue(this.einsatzOrtDTO.postleitzahl);
    this.fcGemeinde.setValue(this.einsatzOrtDTO.gemeinde);
    this.fcGemeindeteil.setValue(this.einsatzOrtDTO.gemeindeteil);
    this.fcStrasse.setValue(this.einsatzOrtDTO.strasse);
    this.fcHausnummer.setValue(this.einsatzOrtDTO.hausnummer);
    this.fcObjekt.setValue(this.einsatzOrtDTO.objekt);
    this.fcRegierungsbezirk.setValue(this.einsatzOrtDTO.regierungsbezirk);
    this.fcLandkreis.setValue(this.einsatzOrtDTO.landkreis);
    this.fcKreisfreieStadt.setValue(this.einsatzOrtDTO.kreisfreieStadt);
    this.fcVerbandsgemeinde.setValue(this.einsatzOrtDTO.verbandsgemeinde);
  }
}
