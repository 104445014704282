<div
  class="item mat-elevation-z3"
  [ngClass]="{ 'mat-elevation-z6': hovering, 'selected-tz': selected }"
  (click)="clicked()"
  (mouseover)="hovering = true"
  (mouseleave)="hovering = false"
>
  <div class="zeichen-image-wrapper">
    <img [src]="einsatzService.EINSATZ_ICON" />
  </div>
  <div class="zeichen-text" [matTooltip]="einsatzDto.einsatznummer || ''" [matTooltipShowDelay]="500">
    {{ einsatzDto.einsatznummer }}
  </div>
</div>
