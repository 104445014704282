import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { ToolbarAction } from 'src/app/shared/toolbar-actions';
import { lageActions } from '../+state/lage.actions';

/**
 * Toolbar für die LageOverview mit Buttons zum Hinzufügen/Bearbeiten von Lagen.
 */
@Component({
  selector: 'app-lage-toolbar',
  templateUrl: './lage-toolbar.component.html',
  styleUrls: ['./lage-toolbar.component.scss'],
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatIconModule, MatTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LageToolbarComponent {
  @Output()
  toolbarEvent = new EventEmitter<ToolbarAction>();

  @Input()
  itemSelected = false;

  constructor(private store: Store<AppStateInterface>) {}

  refreshLagen() {
    this.store.dispatch(lageActions.getLagen());
  }

  fireAddEvent() {
    this.toolbarEvent.emit(ToolbarAction.ADD);
  }

  fireEditEvent() {
    this.toolbarEvent.emit(ToolbarAction.EDIT);
  }
}
