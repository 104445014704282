import { ConnectedPosition, Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { Portal } from '@angular/cdk/portal';
import { Subscription, filter, fromEvent, take } from 'rxjs';

export const contextMenuPositions: ConnectedPosition[] = [
  {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  },
];

export const positionStrategy = (overlay: Overlay, mouseEvent: MouseEvent): PositionStrategy =>
  overlay.position().flexibleConnectedTo({ x: mouseEvent.x, y: mouseEvent.y }).withPositions(contextMenuPositions);

/**
 * Observable, dass darauf hört, ob irgendwo anders gelickt wird als auf dem "eigenen" Element.
 *
 *
 * @todo um weitere Maus-Events erweitern
 *
 * @param contextMenuOverlayRef
 * @returns
 */
export const clickWhereElseThan$ = (contextMenuOverlayRef: OverlayRef) =>
  fromEvent<MouseEvent>(document, 'click').pipe(
    filter((event) => {
      const clickTarget = event.target as HTMLElement;
      return !!contextMenuOverlayRef && !contextMenuOverlayRef.overlayElement.contains(clickTarget);
    }),
    take(1)
  );

/**
 * Hilfsklasse, die beim Öffnen und Handling eines Kontextmenüs mittels "Portal" und "Overlay" unterstützt
 *
 * nach:
 * @see "Medium" https://netbasal.com/context-menus-made-easy-with-angular-cdk-963797e679fc
 */
export class ContextMenuHelper {
  contextMenuOverlayRef: OverlayRef | null = null;
  contextMenuSubscription: Subscription | undefined;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private constructor(private portal: Portal<any>, private overlay: Overlay, private event: MouseEvent) {
    this.contextMenuOverlayRef = this.overlay.create({
      positionStrategy: positionStrategy(this.overlay, event),
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.contextMenuOverlayRef.attach(portal);

    // Wenn wo anders geklickt wird, ContextMenü schließen
    this.contextMenuSubscription = clickWhereElseThan$(this.contextMenuOverlayRef).subscribe(() => {
      this.closeContextMenu();
    });
  }

  /**
   * Schließt das geöffnete Kontextmenü
   */
  public closeContextMenu() {
    this.contextMenuSubscription && this.contextMenuSubscription.unsubscribe();

    if (this.contextMenuOverlayRef) {
      this.contextMenuOverlayRef.dispose();
      this.contextMenuOverlayRef = null;
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public static spawn(portal: Portal<any>, overlay: Overlay, event: MouseEvent) {
    return new ContextMenuHelper(portal, overlay, event);
  }
}
