export const HEADER_CREATED = 'ise.created';
export const HEADER_METHOD = 'ise.method';
export const HEADER_SCHEMA = 'ise.schema';
export const HEADER_ENCODING = 'ise.encoding';
export const HEADER_SOURCE_ID = 'ise.source.id';

/* ise.method */

export const METHOD_CREATED = 'CREATED';
export const METHOD_UPDATED = 'UPDATED';
export const METHOD_DELETED = 'DELETED';

/* ise.schema */

// Allgemein
export const SCHEMA_FUEHRUNGSEBENE = 'com.ise112.lage.fuehrungsebene.FuehrungsebeneDTO';
export const SCHEMA_EINSATZTAGEBUCH = 'com.ise112.lage.einsatztagebuch.EinsatztagebucheintragDTO';
export const SCHEMA_MELDUNG = 'com.ise112.lage.meldung.MeldungDTO';
export const SCHEMA_LAGE = 'com.ise112.lage.fuehrungsebene.lage.LageDTO';
export const SCHEMA_LAGEBESPRECHUNG = 'com.ise112.lage.fuehrungsebene.lage.lagebesprechung.LagebesprechungDTO';
export const SCHEMA_LAGE_MASSNAMHMEN = 'com.ise112.lage.fuehrungsebene.lage.lagemassnahme.LageMassnahmeDTO';
export const SCHEMA_WETTER = 'com.ise112.lage.fuehrungsebene.lage.wetter.WetterDTO';

// Taktische Zeichen
export const SCHEMA_TZ_FUEHRUNGSEBENE_MASSNAHME =
  'com.ise112.lage.taktischezeichen.massnahme.FuehrungsebeneMassnahmeDTO';
export const SCHEMA_TZ_ANLASS = 'com.ise112.lage.taktischezeichen.anlass.AnlassDTO';
export const SCHEMA_TZ_BEFEHLSSTELLE = 'com.ise112.lage.taktischezeichen.befehlsstelle.BefehlsstelleDTO';
export const SCHEMA_TZ_FAHRZEUG = 'com.ise112.lage.taktischezeichen.fahrzeug.FahrzeugDTO';
export const SCHEMA_TZ_FOTO = 'com.ise112.lage.taktischezeichen.foto.FotoPreviewDTO';
export const SCHEMA_TZ_GEBAUDE = 'com.ise112.lage.taktischezeichen.gebaeude.GebaeudeDTO';
export const SCHEMA_TZ_GEBIET = 'com.ise112.lage.taktischezeichen.gebiet.GebietDTO';
export const SCHEMA_TZ_GEFAHR = 'com.ise112.lage.taktischezeichen.gefahr.GefahrDTO';
export const SCHEMA_TZ_PERSON = 'com.ise112.lage.taktischezeichen.person.PersonDTO';
export const SCHEMA_TZ_PERSONENSCHADEN = 'com.ise112.lage.taktischezeichen.personenschaden.PersonenschadenDTO';
export const SCHEMA_TZ_STELLE = 'com.ise112.lage.taktischezeichen.stelle.StelleDTO';
export const SCHEMA_TZ_TAKTISCHE_FORMATION =
  'com.ise112.lage.taktischezeichen.taktischeformation.TaktischeFormationDTO';
export const SCHEMA_TZ_TIERSCHADEN = 'com.ise112.lage.taktischezeichen.tierschaden.TierschadenDTO';

// Bibliothek
export const SCHEMA_VERWALTUNGSEBENE_DELETED = 'com.ise112.lage.planung.bibliothek.VerwaltungsebeneDeletedDTO';
export const SCHEMA_VERWALTUNGSEBENEN_HIERARCHIE = 'com.ise112.lage.planung.bibliothek.VerwaltungsebenenHierarchyDTO';

export const SCHEMA_BIB_TZ_FAHRZEUG = 'com.ise112.lage.planung.bibliothek.BibFahrzeugDTO';
export const SCHEMA_BIB_TZ_PERSON = 'com.ise112.lage.planung.bibliothek.BibPersonDTO';
export const SCHEMA_BIB_TZ_TAKTISCHE_FORMATION = 'com.ise112.lage.planung.bibliothek.BibTaktischeFormationDTO';

export const SCHEMA_MAP_LAYER_CONFIG = 'com.ise112.lage.planung.karte.MapLayerConfig';
export const SCHEMA_EINSATZFILTER = 'com.ise112.lage.einsatz.filter.EinsatzfilterDTO';
