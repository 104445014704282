import { createReducer, on } from '@ngrx/store';
import { FuehrungsebeneDTO } from 'src/app/api/build/openapi';
import { Fuehrungsebenentyp } from 'src/app/api/build/openapi/model/fuehrungsebenentyp';
import { getNewest, replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { FuehrungsebeneStateInterface } from './fuehrungsebene-state.interface';
import { fuehrungsebeneActions } from './fuehrungsebene.actions';

const initialState: FuehrungsebeneStateInterface = {
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  allFuehrungsebenen: [],
  currentFuehrungsebene: null,
  parentFuehrungsebene: null,
  childFuehrungsebenen: [],
  logistikChildren: [],
  loadedLageId: '',
};

/**
 * Fuehrungsebenentypen, die links und rechts neben der Karte dargestellt werden dürfen.
 */
const childFuehrungsebenentypen = [
  Fuehrungsebenentyp.Einsatzraum,
  Fuehrungsebenentyp.Einsatzstelle,
  Fuehrungsebenentyp.Einsatzabschnitt,
  Fuehrungsebenentyp.Unterabschnitt,
  Fuehrungsebenentyp.LogistikEinsatzstelle,
  Fuehrungsebenentyp.LogistikEinsatzstelleChild,
  Fuehrungsebenentyp.LogistikEinsatzabschnitt,
  Fuehrungsebenentyp.LogistikEinsatzabschnittChild,
];

/**
 * Prüft, ob der übergebene Fuehrungsebenentyp neben der Karte dargestellt werden darf.
 */
function isMainChildFuehrungsebene(potentialChild: FuehrungsebeneDTO, parent: FuehrungsebeneDTO | null) {
  // Kein Parent zum Vergleich
  if (!parent) {
    return false;
  }

  // Child ist kein Main-Type
  if (!childFuehrungsebenentypen.includes(potentialChild.typ)) {
    return false;
  }

  // Parent und Child sind nicht direkt verbunden
  if (potentialChild.parentFuehrungsebeneId !== parent.id) {
    return false;
  }

  return true;
}

export const fuehrungsebeneReducer = createReducer(
  initialState,

  // Clear Fuehrungsebenen
  on(fuehrungsebeneActions.resetStore, (): FuehrungsebeneStateInterface => {
    return { ...initialState };
  }),

  // Get Fuehrungsebenen
  on(
    fuehrungsebeneActions.getFuehrungsebenen,
    (state): FuehrungsebeneStateInterface => ({ ...state, isLoading: true, errorResponse: undefined })
  ),
  on(fuehrungsebeneActions.getFuehrungsebenenSuccess, (state, action): FuehrungsebeneStateInterface => {
    /**
     * Wenn Fuehrungsebenen neu geladen werden, den currentFuehrungsebene beibehalten, falls gleiche Lage wie vorher und der currentFuehrungsebene nicht gelöscht wurde.
     * Wenn keine Current-Führungsebene oder andere Lage, Schadengebiet der Lage als currentFuehrungsebene wählen.
     */
    const gleicheLage = state.currentFuehrungsebene?.lageId === action.fuehrungsebenen[0].lageId;
    const currentFuehrungsebeneExistiertNoch = action.fuehrungsebenen.find(
      (fuehrungsebene) => fuehrungsebene.id === state.currentFuehrungsebene?.id
    );

    const currentFuehrungsebene =
      gleicheLage && currentFuehrungsebeneExistiertNoch
        ? state.currentFuehrungsebene
        : action.fuehrungsebenen.find((fuehrungsebene) => fuehrungsebene.typ === Fuehrungsebenentyp.Schadengebiet) ||
          null;

    const parentFuehrungsebene =
      action.fuehrungsebenen.find(
        (fuehrungsebene) => fuehrungsebene.id === currentFuehrungsebene?.parentFuehrungsebeneId
      ) || null;

    const childFuehrungsebenen = action.fuehrungsebenen.filter((fuehrungsebene) =>
      isMainChildFuehrungsebene(fuehrungsebene, currentFuehrungsebene)
    );

    return {
      ...state,
      isLoading: false,
      allFuehrungsebenen: action.fuehrungsebenen,
      currentFuehrungsebene: currentFuehrungsebene,
      parentFuehrungsebene: parentFuehrungsebene,
      childFuehrungsebenen: childFuehrungsebenen,
      errorResponse: undefined,
      loadedLageId: action.loadedLageId,
    };
  }),
  on(
    fuehrungsebeneActions.getFuehrungsebenenFailure,
    (state, action): FuehrungsebeneStateInterface => ({
      ...state,
      isLoading: false,
      errorResponse: action.errorResponse,
    })
  ),

  // Set Current Fuehrungsebene
  on(fuehrungsebeneActions.setCurrentFuehrungsebene, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      currentFuehrungsebene: action.currentFuehrungsebene,
      parentFuehrungsebene:
        state.allFuehrungsebenen.find(
          (fuehrungsebene) => fuehrungsebene.id === action.currentFuehrungsebene?.parentFuehrungsebeneId
        ) || null,
      childFuehrungsebenen: state.allFuehrungsebenen.filter((fuehrungsebene) =>
        isMainChildFuehrungsebene(fuehrungsebene, action.currentFuehrungsebene)
      ),
      errorResponse: undefined,
    };
  }),

  // Set Current Fuehrungsebene Id
  on(fuehrungsebeneActions.setCurrentFuehrungsebeneId, (state, action): FuehrungsebeneStateInterface => {
    const currentFuehrungsebene =
      state.allFuehrungsebenen.find((fuehrungsebene) => fuehrungsebene.id === action.currentFuehrungsebeneId) || null;
    return {
      ...state,
      currentFuehrungsebene: currentFuehrungsebene,
      parentFuehrungsebene:
        state.allFuehrungsebenen.find(
          (fuehrungsebene) => fuehrungsebene.id === currentFuehrungsebene?.parentFuehrungsebeneId
        ) || null,
      childFuehrungsebenen: state.allFuehrungsebenen.filter((fuehrungsebene) =>
        isMainChildFuehrungsebene(fuehrungsebene, currentFuehrungsebene)
      ),
      errorResponse: undefined,
    };
  }),

  // Create Fuehrungsebene
  on(
    fuehrungsebeneActions.createFuehrungsebene,
    (state): FuehrungsebeneStateInterface => ({ ...state, isSaving: true, errorResponse: undefined })
  ),
  on(fuehrungsebeneActions.createFuehrungsebeneSuccess, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      allFuehrungsebenen: [...state.allFuehrungsebenen, action.createdFuehrungsebene],
      childFuehrungsebenen: isMainChildFuehrungsebene(action.createdFuehrungsebene, state.currentFuehrungsebene)
        ? [...state.childFuehrungsebenen, action.createdFuehrungsebene]
        : state.childFuehrungsebenen,
      isSaving: false,
      errorResponse: undefined,
    };
  }),

  on(
    fuehrungsebeneActions.createFuehrungsebeneFailure,
    (state, action): FuehrungsebeneStateInterface => ({
      ...state,
      isSaving: false,
      errorResponse: action.errorResponse,
    })
  ),

  // Patch Fuehrungsebene
  on(
    fuehrungsebeneActions.patchFuehrungsebene,
    (state): FuehrungsebeneStateInterface => ({ ...state, isSaving: true, errorResponse: undefined })
  ),

  on(fuehrungsebeneActions.patchFuehrungsebeneSuccess, (state, action): FuehrungsebeneStateInterface => {
    const allFuehrungsebenen = replaceOrKeepCurrent(state.allFuehrungsebenen, action.patchedFuehrungsebene);

    const currentFuehrungsebene =
      state.currentFuehrungsebene && state.currentFuehrungsebene.id === action.patchedFuehrungsebene.id
        ? getNewest(state.currentFuehrungsebene, action.patchedFuehrungsebene)
        : state.currentFuehrungsebene;

    const childFuehrungsebenen = allFuehrungsebenen.filter((fuehrungsebene) =>
      isMainChildFuehrungsebene(fuehrungsebene, currentFuehrungsebene)
    );

    const parentFuehrungsebene =
      state.parentFuehrungsebene && state.parentFuehrungsebene.id === action.patchedFuehrungsebene.id
        ? getNewest(state.parentFuehrungsebene, action.patchedFuehrungsebene)
        : state.parentFuehrungsebene;

    return {
      ...state,
      currentFuehrungsebene,
      parentFuehrungsebene,
      allFuehrungsebenen: allFuehrungsebenen,
      childFuehrungsebenen: childFuehrungsebenen,
      isSaving: false,
      errorResponse: undefined,
    };
  }),

  on(fuehrungsebeneActions.patchFuehrungsebeneFailure, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      isSaving: false,
      errorResponse: action.errorResponse,
    };
  }),

  /**
   * Delete Fuehrungsebene
   * TODO: Wenn Löschen sauber implementiert, muss "deleteFuehrungsebeneSuccess" nochmal überarbeitet werden.
   * Löschen eines Fuehrungsebenes führt dazu, dass alle Fuehrungsebenen, Fahrzeuge, Personen, usw. darunter auch gelöscht werden.
   */
  on(fuehrungsebeneActions.deleteFuehrungsebene, (state): FuehrungsebeneStateInterface => {
    return { ...state, isDeleting: true, errorResponse: undefined };
  }),

  on(fuehrungsebeneActions.deleteFuehrungsebeneSuccess, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      currentFuehrungsebene:
        state.currentFuehrungsebene?.id === action.deletedFuehrungsebeneDTO.id
          ? state.allFuehrungsebenen.find((fuehrungsebene) => fuehrungsebene.typ == Fuehrungsebenentyp.Schadengebiet) ||
            null
          : state.currentFuehrungsebene,
      parentFuehrungsebene:
        state.currentFuehrungsebene?.id === action.deletedFuehrungsebeneDTO.id ? null : state.parentFuehrungsebene,
      allFuehrungsebenen: state.allFuehrungsebenen.filter(
        (fuehrungsebene) => fuehrungsebene.id !== action.deletedFuehrungsebeneDTO.id
      ),
      childFuehrungsebenen: state.childFuehrungsebenen.filter(
        (fuehrungsebene) => fuehrungsebene.id !== action.deletedFuehrungsebeneDTO.id
      ),
      isDeleting: false,
      errorResponse: undefined,
    };
  }),

  on(fuehrungsebeneActions.deleteFuehrungsebeneFailure, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      isDeleting: false,
      errorResponse: action.errorResponse,
    };
  }),

  on(fuehrungsebeneActions.getPersonenuebersichtSuccess, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      personenuebersicht: action.personenuebersicht,
      errorResponse: undefined,
    };
  }),

  on(fuehrungsebeneActions.getPersonenuebersichtFailure, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      personenuebersicht: undefined,
      errorResponse: action.errorResponse,
    };
  }),

  on(fuehrungsebeneActions.getPersonenuebersichtLage, (state): FuehrungsebeneStateInterface => {
    return {
      ...state,
      isLoading: true,
      errorResponse: undefined,
    };
  }),

  on(fuehrungsebeneActions.getPersonenuebersichtLageSuccess, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      isLoading: false,
      personenuebersichtLage: action.personenuebersicht,
      errorResponse: undefined,
    };
  }),

  on(fuehrungsebeneActions.getPersonenuebersichtLageFailure, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      isLoading: false,
      personenuebersichtLage: undefined,
      errorResponse: action.errorResponse,
    };
  }),

  on(fuehrungsebeneActions.getEinsatzraumuebersichtLage, (state): FuehrungsebeneStateInterface => {
    return {
      ...state,
      isLoading: true,
      errorResponse: undefined,
    };
  }),
  on(fuehrungsebeneActions.getEinsatzraumuebersichtLageSuccess, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      einsatzraumuebersicht: action.einsatzraumuebersicht,
      isLoading: false,
      errorResponse: undefined,
    };
  }),
  on(fuehrungsebeneActions.getEinsatzraumuebersichtLageFailure, (state, action): FuehrungsebeneStateInterface => {
    return {
      ...state,
      einsatzraumuebersicht: [],
      isLoading: false,
      errorResponse: action.errorResponse,
    };
  })
);
