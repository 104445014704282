<div class="form-container">
  <form [formGroup]="formGroup" class="actual-form">
    <mat-card>
      <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />

      <mat-form-field class="less-margin" (mouseover)="symbolMouseover = true" (mouseleave)="symbolMouseover = false">
        <mat-label>Symbol</mat-label>
        <mat-select formControlName="symbol">
          <mat-option *ngFor="let symbol of symbolValues" [value]="symbol">
            {{ symbol.label }}
          </mat-option>
        </mat-select>
        <button *ngIf="fcSymbol.value && symbolMouseover" matSuffix mat-icon-button (click)="fcSymbol.reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Text</mat-label>
        <input matInput [formControl]="fcZeichenText" />
        <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['maxlength']">{{
          getErrorMessage(fcZeichenText)
        }}</mat-error>
        <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['pattern']"
          >Keine Umlaute oder Sonderzeichen</mat-error
        >
      </mat-form-field>
    </mat-card>
  </form>

  <app-tagebuch class="tagebuch" [tagebucheintraege]="anlassToEdit?.tagebuch || []" />
</div>
