import { KeyValuePipe, NgForOf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorService } from '@product/ise-error-lib';
import { AnwesenheitslisteService } from 'src/app/anwesenheitsliste/anwesenheitsliste.service';
import { AnwesenheitDTO, Rolle } from 'src/app/api/build/openapi';
import { MassnahmenService } from 'src/app/lagedarstellung/massnahmen.service';
import { MassnahmeBeendenDialogComponent } from 'src/app/lagedarstellung/massnahmen/massnahme-beenden-dialog/massnahme-beenden-dialog.component';

export interface PersonenInfo {
  erledigtDurchAnwesenheit: AnwesenheitDTO | undefined | null;
  erledigtDurchDatum: string | undefined | null;
}

@Component({
  selector: 'app-stab-massnahme-beenden-dialog',
  templateUrl: './stab-massnahme-beenden-dialog.component.html',
  styleUrls: ['./stab-massnahme-beenden-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    MatOptionModule,
    MatCardModule,
    KeyValuePipe,
    NgForOf,
  ],
})
export class StabMassnahmeBeendenDialogComponent {
  Rolle = Rolle;
  roleTextMapping = MassnahmenService.rolleMapping;
  errorService = inject(ErrorService);
  anwesenheitenService = inject(AnwesenheitslisteService);
  private fb: NonNullableFormBuilder = inject(NonNullableFormBuilder);

  formGroup = this.fb.group({
    erledigtDurchAnwesenheit: this.fb.control<AnwesenheitDTO | undefined>(undefined, Validators.required),
    erledigtDurchDatum: ['', Validators.required],
  });

  constructor(private matDialogRef: MatDialogRef<MassnahmeBeendenDialogComponent>) {}

  protected onClickBeenden() {
    if (this.formGroup.valid) {
      const person: PersonenInfo = this.formGroup.getRawValue();
      this.matDialogRef.close({
        ...person,
        erledigtDurchDatum: new Date(this.formGroup.get('erledigtDurchDatum')!.value!).toISOString(),
      });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  protected onClickAbbrechen() {
    this.matDialogRef.close(undefined);
  }

  getErrorMessage(formControlName: string): string {
    const formControl = this.formGroup.get(formControlName);
    if (!formControl) {
      return '';
    }
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
