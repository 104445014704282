import { Component, HostListener, inject, AfterViewInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { FuehrungsebeneMassnahmeDTO, Prioritaet, StabMassnahmeDTO } from 'src/app/api/build/openapi';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { MatButtonModule } from '@angular/material/button';
import { LagePrio } from 'src/app/lagedarstellung/massnahmen/massnahme-add-dialog/massnahme-add-dialog.component';
import { ZeitstrahlElement, ZeitstrahlService } from '../zeitstrahl.service';
import { MassnahmenService } from 'src/app/lagedarstellung/massnahmen.service';

interface Zoomstufe {
  id: number;
  zeitspanneInH: number;
  threshhold: number;
  stepInMins: number;
}

@Component({
  selector: 'app-zeitstrahl',
  standalone: true,
  imports: [CommonModule, NgClass, NgIf, MatIconModule, MatTooltipModule, MatButtonModule, DateTimePipe],
  templateUrl: './zeitstrahl.component.html',
  styleUrls: ['./zeitstrahl.component.scss'],
})
export class ZeitstrahlComponent implements AfterViewInit {
  @Output()
  elementClicked: EventEmitter<StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO> = new EventEmitter<
    StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO
  >();

  @Output()
  elementHovered: EventEmitter<StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO | undefined> = new EventEmitter<
    StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO | undefined
  >();

  @Output()
  elementBeenden: EventEmitter<StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO> = new EventEmitter<
    StabMassnahmeDTO | FuehrungsebeneMassnahmeDTO
  >();

  readonly zoomstufen: Zoomstufe[] = [
    { id: 0, zeitspanneInH: 2, threshhold: 70, stepInMins: 30 },
    { id: 1, zeitspanneInH: 4, threshhold: 70, stepInMins: 30 },
    { id: 2, zeitspanneInH: 6, threshhold: 70, stepInMins: 30 },
    { id: 3, zeitspanneInH: 8, threshhold: 70, stepInMins: 30 },
    { id: 4, zeitspanneInH: 10, threshhold: 70, stepInMins: 30 },
    { id: 5, zeitspanneInH: 12, threshhold: 70, stepInMins: 30 },
    { id: 6, zeitspanneInH: 14, threshhold: 70, stepInMins: 30 },
    { id: 7, zeitspanneInH: 16, threshhold: 70, stepInMins: 30 },
    { id: 8, zeitspanneInH: 18, threshhold: 70, stepInMins: 30 },
    { id: 9, zeitspanneInH: 20, threshhold: 70, stepInMins: 30 },
    { id: 10, zeitspanneInH: 22, threshhold: 70, stepInMins: 30 },
    { id: 11, zeitspanneInH: 24, threshhold: 70, stepInMins: 30 },
    { id: 12, zeitspanneInH: 36, threshhold: 100, stepInMins: 60 },
    { id: 13, zeitspanneInH: 48, threshhold: 100, stepInMins: 60 },
    { id: 14, zeitspanneInH: 60, threshhold: 100, stepInMins: 60 },
    { id: 15, zeitspanneInH: 72, threshhold: 100, stepInMins: 60 },
    { id: 16, zeitspanneInH: 84, threshhold: 130, stepInMins: 120 },
    { id: 17, zeitspanneInH: 96, threshhold: 130, stepInMins: 120 },
  ];

  currentZoomstufe: Zoomstufe = this.zoomstufen[11];

  offsetInMins = 0;
  zeitpunktStart: Date = new Date();
  zeitpunktJetzt: Date = new Date();
  zeitpunktEnde: Date = new Date();
  hiddenLeft = 0;
  hiddenRight = 0;

  isDragging = false;
  dragStartX = 0;

  zeitstrahlelemente: ZeitstrahlElement[] = [];
  nowIndicator: ZeitstrahlElement = { id: 'now', top: true, positionInProzent: 50 };

  zs: ZeitstrahlService = inject(ZeitstrahlService);
  ref: ElementRef = inject(ElementRef);

  prioMapping: Map<Prioritaet, LagePrio> = MassnahmenService.prioMapping;

  constructor() {
    this.updateDate();
  }
  ngAfterViewInit(): void {
    this.nowIndicator.positionInProzent = ((new Date().valueOf() - this.zeitpunktStart.valueOf()) / length) * 100;

    this.zs.stabMassnahmen$.subscribe((massnahmen) => {
      const length = this.zeitpunktEnde.valueOf() - this.zeitpunktStart.valueOf();
      massnahmen.forEach((m, index) => {
        if (!this.zeitstrahlelemente.find((e) => e.id === `${m.lageId}/${m.id}`)) {
          const pos = ((new Date(m.bisWannDatum!).valueOf() - this.zeitpunktStart.valueOf()) / length) * 100;
          const id = `${m.lageId}/${m.id}`;
          const element: ZeitstrahlElement = { id: id, massnahme: m, top: index % 2 > 0, positionInProzent: pos };
          if (pos > 100) {
            this.hiddenRight++;
          } else if (pos < 0) {
            this.hiddenLeft++;
          }
          this.zeitstrahlelemente.push(element);
        } else {
          this.zeitstrahlelemente.find((e) => e.id === `${m.lageId}/${m.id}`)!.massnahme = m;
        }
      });
      this.updateElements();
    });

    this.zs.abschnittMassnahmen$.subscribe((abschnittMassnahmen) => {
      const length = this.zeitpunktEnde.valueOf() - this.zeitpunktStart.valueOf();
      abschnittMassnahmen.forEach((am) => {
        if (!this.zeitstrahlelemente.find((e) => e.id === `${am.fuehrungsebeneId}/${am.id}`)) {
          if (am.bisWannDatum) {
            const pos = ((new Date(am.bisWannDatum!).valueOf() - this.zeitpunktStart.valueOf()) / length) * 100;
            const id = `${am.fuehrungsebeneId}/${am.id}`;
            const element: ZeitstrahlElement = { id: id, massnahme: am, top: true, positionInProzent: pos };
            element.dataUrl = am.dataUrl;
            this.zeitstrahlelemente.push(element);
          }
        } else {
          const el: ZeitstrahlElement = this.zeitstrahlelemente.find(
            (e) => e.id === `${am.fuehrungsebeneId}/${am.id}`
          )!;
          el.massnahme = am;
          el.dataUrl = am.dataUrl;
        }
      });
      this.updateElements();
    });
  }

  updateElements() {
    this.zeitstrahlelemente.sort((a, b) => {
      const dateA: Date = new Date(a.massnahme!.bisWannDatum!);
      const dateB: Date = new Date(b.massnahme!.bisWannDatum!);
      return dateA.valueOf() - dateB.valueOf();
    });
    this.zeitstrahlelemente.forEach((e, i) => (e.top = i % 2 === 0));
  }

  @HostListener('mousewheel', ['$event']) onMousewheel(event: WheelEvent) {
    let currentId = this.currentZoomstufe.id;

    if (event.deltaY < 0 && currentId < this.zoomstufen.length - 1) {
      currentId++;
    } else if (event.deltaY > 0 && currentId > 0) {
      currentId--;
    }
    this.currentZoomstufe = this.zoomstufen[currentId];
    this.updateDate();
  }

  @HostListener('window:mouseup', ['$event']) mouseUp(event: MouseEvent) {
    if (this.isDragging) {
      this.endDrag(event);
    }
  }

  @HostListener('window:mousemove', ['$event']) mouseMove(event: MouseEvent) {
    if (this.isDragging) {
      const step = (this.currentZoomstufe.zeitspanneInH * 60) / 100;
      const threshhold = this.ref.nativeElement.getBoundingClientRect().width / 100;

      if (event.x > this.dragStartX && event.x - this.dragStartX >= threshhold) {
        this.offsetInMins -= step;
        this.dragStartX = event.x;
        this.updateDate();
      }
      if (event.x < this.dragStartX && this.dragStartX - event.x >= threshhold) {
        this.dragStartX = event.x;
        this.offsetInMins += step;
        this.updateDate();
      }
    }
  }

  updateDate() {
    this.zeitpunktJetzt = new Date();
    this.zeitpunktJetzt.setMinutes(this.zeitpunktJetzt.getMinutes() + this.offsetInMins);
    this.zeitpunktStart = new Date();
    this.zeitpunktStart.setMinutes(
      this.zeitpunktStart.getMinutes() - (this.currentZoomstufe.zeitspanneInH * 60) / 2 + this.offsetInMins
    );
    this.zeitpunktEnde = new Date();
    this.zeitpunktEnde.setMinutes(
      this.zeitpunktEnde.getMinutes() + (this.currentZoomstufe.zeitspanneInH * 60) / 2 + this.offsetInMins
    );

    const length = this.zeitpunktEnde.valueOf() - this.zeitpunktStart.valueOf();
    this.hiddenLeft = 0;
    this.hiddenRight = 0;
    this.zeitstrahlelemente.forEach((element) => {
      const pos =
        ((new Date(element.massnahme!.bisWannDatum!).valueOf() - this.zeitpunktStart.valueOf()) / length) * 100;
      element.positionInProzent = pos;
      if (pos > 100) {
        this.hiddenRight++;
      } else if (pos < 0) {
        this.hiddenLeft++;
      }
    });
    this.nowIndicator.positionInProzent = ((new Date().valueOf() - this.zeitpunktStart.valueOf()) / length) * 100;
  }

  startDrag(event: MouseEvent) {
    this.isDragging = true;
    this.dragStartX = event.x;
  }

  endDrag(event: MouseEvent) {
    this.isDragging = false;
    const step = (this.currentZoomstufe.zeitspanneInH * 60) / 100;

    if (event.x > this.dragStartX) {
      this.offsetInMins -= step;
      this.dragStartX = event.x;
      this.updateDate();
    }
    if (event.x < this.dragStartX) {
      this.dragStartX = event.x;
      this.offsetInMins += step;
      this.updateDate();
    }
  }

  onClickCenterZeitstrahl() {
    this.offsetInMins = 0;
    this.updateDate();
  }

  onClickElement(element: ZeitstrahlElement) {
    this.elementClicked.emit(element.massnahme);
  }

  onClickMassnahmeBeenden(event: MouseEvent, element: ZeitstrahlElement) {
    event.stopPropagation();
    this.elementBeenden.emit(element.massnahme!);
  }

  onMouseEnter(element: ZeitstrahlElement | undefined = undefined) {
    if (element) {
      this.elementHovered.emit(element.massnahme!);
    } else {
      this.elementHovered.emit(undefined);
    }
  }
}
