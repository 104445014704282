import { createReducer, on } from '@ngrx/store';
import { BibliothekStateInterface } from './bibliothek-state.interface';
import { bibliothekActions } from './bibliothek.actions';

const initialState: BibliothekStateInterface = {
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  isMoving: false,
  verwaltungsebenen: [],
  fahrzeuge: [],
  personen: [],
  taktischeFormationen: [],
};

export const bibliothekReducer = createReducer(
  initialState,

  // Clear Verwaltungsebenen
  on(bibliothekActions.resetStore, (): BibliothekStateInterface => {
    return { ...initialState };
  }),

  // Alle Verwaltungsebenen holen
  on(bibliothekActions.getVerwaltungsebenen, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(bibliothekActions.getVerwaltungsebenenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    verwaltungsebenen: action.verwaltungsebenen,
    errorResponse: undefined,
  })),
  on(bibliothekActions.getVerwaltungsebenenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Neue Verwaltungsebene anlegen
  on(bibliothekActions.createVerwaltungsebene, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(bibliothekActions.createVerwaltungsebeneSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    verwaltungsebenen: action.newVerwaltungsebenenHierarchy.hierarchy || [],
    errorResponse: undefined,
  })),
  on(bibliothekActions.createVerwaltungsebeneFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Verwaltungsebene aktualisieren
  on(bibliothekActions.patchVerwaltungsebene, (state) => ({ ...state, isSaving: true, errorResponse: undefined })),
  on(bibliothekActions.patchVerwaltungsebeneSuccess, (state, action) => {
    return {
      ...state,
      isSaving: false,
      verwaltungsebenen: action.newVerwaltungsebenenHierarchy.hierarchy || [],
      errorResponse: undefined,
    };
  }),
  on(bibliothekActions.patchVerwaltungsebeneFailure, (state, action) => ({
    ...state,
    isSaving: false,
    errorResponse: action.errorResponse,
  })),

  // Verwaltungsebene verschieben
  on(bibliothekActions.moveVerwaltungsebene, (state) => ({ ...state, isMoving: true, errorResponse: undefined })),
  on(bibliothekActions.moveVerwaltungsebeneSuccess, (state, action) => ({
    ...state,
    isMoving: false,
    // komplette Hierarchie ersetzten
    verwaltungsebenen: action.newVerwaltungsebenenHierarchy.hierarchy || [],
    errorResponse: undefined,
  })),
  on(bibliothekActions.moveVerwaltungsebeneFailure, (state, action) => ({
    ...state,
    isMoving: false,
    errorResponse: action.errorResponse,
  })),

  // Verwaltungsebene löschen
  on(bibliothekActions.deleteVerwaltungsebene, (state) => ({ ...state, isDeleting: true, errorResponse: undefined })),
  on(bibliothekActions.deleteVerwaltungsebeneSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    verwaltungsebenen: action.deletedVerwaltungsebene.updatedVerwaltungsebenenHierarchy?.hierarchy || [],
    fahrzeuge: state.fahrzeuge.filter(
      (fahrzeug) => fahrzeug.verwaltungsebeneId !== action.deletedVerwaltungsebene.deletedId
    ),
    personen: state.personen.filter((person) => person.verwaltungsebeneId !== action.deletedVerwaltungsebene.deletedId),
    taktischeFormationen: state.taktischeFormationen.filter(
      (taktischeFormation) => taktischeFormation.verwaltungsebeneId !== action.deletedVerwaltungsebene.deletedId
    ),
    errorResponse: undefined,
  })),
  on(bibliothekActions.deleteVerwaltungsebeneFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  })),

  // #########################################
  // Fahrzeuge
  // TODO bekommt man eine bessere Separierung hin? Eigene Klassen/Dateien/...
  // #########################################

  // Create Fahrzeug
  on(bibliothekActions.createFahrzeug, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(bibliothekActions.createFahrzeugSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    fahrzeuge: [...state.fahrzeuge, action.newFahrzeug],
    errorResponse: undefined,
  })),
  on(bibliothekActions.createFahrzeugFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Fahrzeug
  on(bibliothekActions.patchFahrzeug, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(bibliothekActions.patchFahrzeugSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    fahrzeuge: state.fahrzeuge.map((fahrzeug) =>
      fahrzeug.id === action.patchedFahrzeug.id ? action.patchedFahrzeug : fahrzeug
    ),
    errorResponse: undefined,
  })),
  on(bibliothekActions.patchFahrzeugFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Fahrzeug
  on(bibliothekActions.deleteFahrzeug, (state) => ({ ...state, isDeleting: true, errorResponse: undefined })),
  on(bibliothekActions.deleteFahrzeugSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    fahrzeuge: state.fahrzeuge.filter((fahrzeug) => fahrzeug.id !== action.deleteFahrzeugId),
    errorResponse: undefined,
  })),
  on(bibliothekActions.deleteFahrzeugFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  })),

  // Fahrzeuge mit Verwaltungsebenen laden
  on(bibliothekActions.getFahrzeugeWithVerwaltungsebene, (state) => ({
    ...state,
    isLoading: true,
    errorResponse: undefined,
  })),
  on(bibliothekActions.getFahrzeugeWithVerwaltungsebeneSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    fahrzeuge: action.fahrzeuge,
    errorResponse: undefined,
  })),
  on(bibliothekActions.getFahrzeugeWithVerwaltungsebeneFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // #########################################
  // Personen
  // #########################################

  // Create Person
  on(bibliothekActions.createPerson, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(bibliothekActions.createPersonSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    personen: [...state.personen, action.newPerson],
    errorResponse: undefined,
  })),
  on(bibliothekActions.createPersonFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Person
  on(bibliothekActions.patchPerson, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(bibliothekActions.patchPersonSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    personen: state.personen.map((person) => (person.id === action.patchedPerson.id ? action.patchedPerson : person)),
    errorResponse: undefined,
  })),
  on(bibliothekActions.patchPersonFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Person
  on(bibliothekActions.deletePerson, (state) => ({ ...state, isDeleting: true, errorResponse: undefined })),
  on(bibliothekActions.deletePersonSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    personen: state.personen.filter((person) => person.id !== action.deletePersonId),
    errorResponse: undefined,
  })),
  on(bibliothekActions.deletePersonFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  })),

  // Personen mit Verwaltungsebenen laden
  on(bibliothekActions.getPersonenWithVerwaltungsebene, (state) => ({
    ...state,
    isLoading: true,
    errorResponse: undefined,
  })),
  on(bibliothekActions.getPersonenWithVerwaltungsebeneSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    personen: action.personen,
    errorResponse: undefined,
  })),
  on(bibliothekActions.getPersonenWithVerwaltungsebeneFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // #########################################
  // TaktischeFormationen
  // #########################################

  // Create TaktischeFormation
  on(bibliothekActions.createTaktischeFormation, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(bibliothekActions.createTaktischeFormationSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    taktischeFormationen: [...state.taktischeFormationen, action.newTaktischeFormation],
    errorResponse: undefined,
  })),
  on(bibliothekActions.createTaktischeFormationFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch TaktischeFormation
  on(bibliothekActions.patchTaktischeFormation, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(bibliothekActions.patchTaktischeFormationSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    taktischeFormationen: state.taktischeFormationen.map((taktischeFormation) =>
      taktischeFormation.id === action.patchedTaktischeFormation.id
        ? action.patchedTaktischeFormation
        : taktischeFormation
    ),
    errorResponse: undefined,
  })),
  on(bibliothekActions.patchTaktischeFormationFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete TaktischeFormation
  on(bibliothekActions.deleteTaktischeFormation, (state) => ({ ...state, isDeleting: true, errorResponse: undefined })),
  on(bibliothekActions.deleteTaktischeFormationSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    taktischeFormationen: state.taktischeFormationen.filter(
      (taktischeFormation) => taktischeFormation.id !== action.deleteTaktischeFormationId
    ),
    errorResponse: undefined,
  })),
  on(bibliothekActions.deleteTaktischeFormationFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  })),

  // TaktischeFormationen mit Verwaltungsebenen laden
  on(bibliothekActions.getTaktischeFormationenWithVerwaltungsebene, (state) => ({
    ...state,
    isLoading: true,
    errorResponse: undefined,
  })),
  on(bibliothekActions.getTaktischeFormationenWithVerwaltungsebeneSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    taktischeFormationen: action.taktischeFormationen,
    errorResponse: undefined,
  })),
  on(bibliothekActions.getTaktischeFormationenWithVerwaltungsebeneFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  }))
);
