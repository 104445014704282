import { createSelector } from '@ngrx/store';
import { AppStateInterface, einsatzfilter } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[einsatzfilter];

export const einsatzfilterSelector = createSelector(selectFeature, (state) => state.einsatzfilter);
export const isLoading = createSelector(selectFeature, (state) => state.isLoading);

export const einsatzfilterOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.einsatzfilter.filter((filter) => !!fuehrungsebeneId && filter.fuehrungsebeneIds?.includes(fuehrungsebeneId))
  );
