import { createSelector } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';

/*
Selektoren werden für den Zugriff auf "Stücke"/"Slices" des Stores verwendet.

https://ngrx.io/guide/store/selectors
*/

export const selectFeature = (appState: AppStateInterface) => appState.meldungen;
export const isCreatingSelector = createSelector(selectFeature, (state) => state.isCreating);
export const isLoadingMeldungenSelector = createSelector(selectFeature, (state) => state.isLoading);
export const isPatchingSelector = createSelector(selectFeature, (state) => state.isPatching);
export const meldungenSelector = createSelector(selectFeature, (state) => state.meldungen);
export const errorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const currentMeldungSelector = createSelector(selectFeature, (state) => state.currentMeldung);
export const schlussmeldungExistsSelector = createSelector(selectFeature, (state) => state.schlussmeldungExists);
export const meldungenErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
