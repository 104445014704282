/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AnalogBand = 'ZWEI_METER' | 'VIER_METER';

export const AnalogBand = {
    ZweiMeter: 'ZWEI_METER' as AnalogBand,
    VierMeter: 'VIER_METER' as AnalogBand
};

