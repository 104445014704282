import { Component } from '@angular/core';
import { LageKWindowTabsComponent } from '../lage-k-window-tabs/lage-k-window-tabs.component';

@Component({
  selector: 'app-lage-k-base',
  standalone: true,
  imports: [LageKWindowTabsComponent],
  templateUrl: './lage-k-base.component.html',
  styleUrl: './lage-k-base.component.scss',
})
export class LageKBaseComponent {}
