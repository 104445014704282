import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FuehrungsebeneDTO, StelleDTO } from 'src/app/api/build/openapi';
import { AuftragHeaderElementComponent } from 'src/app/lagedarstellung/auftrag/auftrag-header-element/auftrag-header-element.component';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { KontaktIconsComponent } from 'src/app/lagedarstellung/kontakt/kontakt-icons/kontakt-icons.component';
import { TaktischesZeichenPopup } from '../../taktische-zeichen.interface';
import { TzPopupToolbarComponent } from '../../tz-popup-toolbar/tz-popup-toolbar.component';

@Component({
  selector: 'app-stelle-popup',
  standalone: true,
  imports: [
    CommonModule,
    FuehrungsebeneIconAndTextComponent,
    KontaktIconsComponent,
    AuftragHeaderElementComponent,
    TzPopupToolbarComponent,
  ],
  templateUrl: './stelle-popup.component.html',
  styleUrls: ['./stelle-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StellePopupComponent implements OnInit, TaktischesZeichenPopup {
  @Input({ required: true }) dto!: StelleDTO;

  private fuehrungsebeneService = inject(FuehrungsebeneService);

  protected parentFuehrungsebene?: FuehrungsebeneDTO;

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.dto.fuehrungsebeneId);
  }
}
