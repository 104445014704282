import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuftragDTO } from 'src/app/api/build/openapi';
import { AuftragDetailsDialogComponent } from '../auftrag-details-dialog/auftrag-details-dialog.component';

@Component({
  selector: 'app-auftrag-list',
  templateUrl: './auftrag-list.component.html',
  styleUrls: ['./auftrag-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
  ],
})
export class AuftragListComponent {
  protected auftraege: AuftragDTO[] = [];
  protected auftraegeDisplay: AuftragDTO[] = [];
  protected auftraegeErledigtCount = 0;
  protected showErledigteAuftraege = true;
  private dialog = inject(MatDialog);

  constructor() {
    const lsv = localStorage.getItem('showErledigteAuftraege');
    if (lsv) {
      this.showErledigteAuftraege = lsv === 'true';
    }
    this.generateAuftraegeDisplay();
  }

  setAuftraege(auftraege: AuftragDTO[]): void {
    this.auftraege = auftraege;
    this.updateAuftragErledigtCount();
    this.generateAuftraegeDisplay();
  }

  getAuftraege(): AuftragDTO[] {
    return this.auftraege;
  }

  private updateAuftragErledigtCount(): void {
    this.auftraegeErledigtCount = this.auftraege.filter((auftrag) => auftrag.erledigt).length;
  }

  protected updateSelection(selectionChange: MatSelectionListChange): void {
    if (selectionChange.options.length) {
      const selectedAuftrag = selectionChange.options[0].value;
      const updatedAuftrag = { ...selectedAuftrag, erledigt: !selectedAuftrag.erledigt };
      this.auftraege = this.auftraege.map((auftrag) => (auftrag === selectedAuftrag ? updatedAuftrag : auftrag));
      this.updateAuftragErledigtCount();
      this.generateAuftraegeDisplay();
    }
  }

  protected newAuftrag(): void {
    const newAuftrag: AuftragDTO = {
      name: '',
      auftrag: '',
    };

    this.dialog
      .open(AuftragDetailsDialogComponent, {
        data: { auftragToEdit: newAuftrag },
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.auftraege = [...this.auftraege, dialogResult];
          this.updateAuftragErledigtCount();
          this.generateAuftraegeDisplay();
        }
      });
  }

  protected editAuftrag(event: MouseEvent, auftrag: AuftragDTO): void {
    event.stopPropagation(); // verhindern, dass Auftrag in der Liste getoggelt wird

    const auftragToEdit = auftrag;

    this.dialog
      .open(AuftragDetailsDialogComponent, {
        data: { auftragToEdit: { ...auftragToEdit } },
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.auftraege = this.auftraege.map((auftrag) => (auftrag == auftragToEdit ? dialogResult : auftrag));
          this.updateAuftragErledigtCount();
          this.generateAuftraegeDisplay();
        }
      });
  }

  protected toggleErledigteAufgaben() {
    this.showErledigteAuftraege = !this.showErledigteAuftraege;
    localStorage.setItem('showErledigteAuftraege', `${this.showErledigteAuftraege}`);
    this.generateAuftraegeDisplay();
  }

  private generateAuftraegeDisplay() {
    if (this.showErledigteAuftraege) {
      this.auftraegeDisplay = this.auftraege;
    } else {
      this.auftraegeDisplay = this.auftraege.filter((a) => !a.erledigt);
    }
  }
}
