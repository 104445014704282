import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogAction, DialogService } from '@product/ise-dialog-lib';
import { BehaviorSubject } from 'rxjs';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { EinsatzfilterDTO, FuehrungsebeneDTO } from '../../api/build/openapi';
import {
  EinsatzfilterSelectDialogComponent,
  EinsatzfilterSelectDialogInputOutputData,
} from '../einsatzfilter-select-dialog/einsatzfilter-select-dialog.component';

/**
 * Liste ausgewählter Einsatzfilter.
 *
 * Einsatzfilter können hinzugefügt oder entfernt werden.
 */
@Component({
  selector: 'app-einsatzfilter-selection-list',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatListModule,
  ],
  templateUrl: './einsatzfilter-selection-list.component.html',
  styleUrl: './einsatzfilter-selection-list.component.scss',
})
export class EinsatzfilterSelectionListComponent implements OnChanges {
  @Input({ required: true })
  fuehrungsebeneDTO: FuehrungsebeneDTO | undefined = undefined;

  @Input()
  einsatzfilterDTOs: EinsatzfilterDTO[] = [];

  protected einsatzfilterDTOs$ = new BehaviorSubject<EinsatzfilterDTO[]>([]);

  private dialog = inject(MatDialog);
  private dialogService = inject(DialogService);
  private fuehrungsebeneService = inject(FuehrungsebeneService);

  /**
   * Angezeigter Text im Header
   */
  @Input()
  headerText = 'Einsatzfilter';

  selectedIndex = -1;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['einsatzfilterDTOs']) {
      this.einsatzfilterDTOs$.next(this.einsatzfilterDTOs);
    }
  }

  /**
   * Öffnet den EinsatzfilterSelectDialog, um alle gewünschten Einsatzfilter auszuwählen
   */
  openEinsatzfilterSelectDialog() {
    const dialogData: EinsatzfilterSelectDialogInputOutputData = {
      selectedEinsatzfilterDTOs: this.einsatzfilterDTOs$.value,
    };
    this.dialog
      .open(EinsatzfilterSelectDialogComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((dialogResult: EinsatzfilterSelectDialogInputOutputData) => {
        if (dialogResult) {
          this.einsatzfilterDTOs$.next(dialogResult.selectedEinsatzfilterDTOs);
          this.selectedIndex = this.einsatzfilterDTOs$.value.length - 1;
        }
      });
  }

  openEinsatzfilterMoveDialog() {
    if (this.selectedIndex < 0) {
      console.warn('Kein Einsatzfilter ausgewählt');
      return;
    }

    if (!this.fuehrungsebeneDTO) {
      console.warn('Keine Führungsebene vorhanden');
      return;
    }

    const filterDTOToMove = this.einsatzfilterDTOs$.value[this.selectedIndex];

    this.fuehrungsebeneService
      .openMoveEinsatzfilterDialog(filterDTOToMove, this.fuehrungsebeneDTO)
      .subscribe((result) => {
        if (result) {
          this.einsatzfilterDTOs$.next(this.einsatzfilterDTOs$.value.filter((f) => f.id !== filterDTOToMove.id));
        }
      });
  }

  /**
   * Nachfragen, ob die ausgewählter Einsatzfilter gelöscht werden soll
   */
  askDelete() {
    this.dialogService
      .openConfirmDialog('Einsatzfilter löschen?', 'Einsatzfilter sicher löschen?')
      .afterClosed()
      .subscribe((result: DialogAction) => {
        if (result === DialogAction.OK) {
          this.einsatzfilterDTOs$.next([
            ...this.einsatzfilterDTOs$.value.filter((_, index) => index !== this.selectedIndex),
          ]);
          this.selectedIndex = -1;
        }
      });
  }

  getEinsatzfilterDTOs(): EinsatzfilterDTO[] {
    return this.einsatzfilterDTOs$.getValue();
  }
}
