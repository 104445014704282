import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FahrzeugeDataset } from '../kennzahlen-dashboad.service';

@Component({
  selector: 'app-dashboard-fahrzeuge',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatToolbarModule],
  templateUrl: './dashboard-fahrzeuge.component.html',
  styleUrls: ['./dashboard-fahrzeuge.component.scss'],
})
export class DashboardFahrzeugeComponent {
  @Input()
  fahrzeugeDataset?: FahrzeugeDataset;
}
