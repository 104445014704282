<mat-toolbar>
  <h2>Auftrag {{ auftragToEdit ? "bearbeiten" : "anlegen" }}</h2>
</mat-toolbar>

<div class="container">
  <form [formGroup]="generalFG">
    <div class="flex-row auftrag-header">
      <app-cleanable-form-field
        class="flex-grow"
        label="Name"
        tooltip="Optionale Bezeichnung des Auftrags"
        [formGroup]="generalFG"
        [formControlName]="name"
      />
      <mat-checkbox [formControl]="fcErledigt" matTooltip="Wurde der Auftrag erledigt?"> erledigt</mat-checkbox>
    </div>
  </form>

  <div class="type-selector">
    <mat-radio-group [(ngModel)]="selectedBefehltyp">
      <mat-radio-button *ngFor="let typ of befehlTyp | keyvalue" [value]="typ.value">{{ typ.key }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="selectedBefehltyp === befehlTyp.Befehl; else langzeitbefehlFelder" class="container inner-container">
    <form [formGroup]="shortOrderFG">
      <!-- mat-card work-around ... sonst kommt von irgendwoher eine mini-scrollbar ... -->
      <mat-card>
        <app-cleanable-form-field label="Einheit" [formGroup]="shortOrderFG" [formControlName]="einheit" />
        <app-cleanable-form-field label="Auftrag" [formGroup]="shortOrderFG" [formControlName]="auftragName" />
        <app-cleanable-form-field label="Mittel" [formGroup]="shortOrderFG" [formControlName]="mittel" />
        <app-cleanable-form-field label="Ziel" [formGroup]="shortOrderFG" [formControlName]="ziel" />
        <app-cleanable-form-field
          label="Weg"
          [formGroup]="shortOrderFG"
          [formControlName]="weg"
          [useTextarea]="true"
          placeholder="LANGER LANGER TEXT"
        />
      </mat-card>
    </form>

    <p class="hint">Maximal 500 Zeichen pro Feld</p>
  </div>

  <ng-template #langzeitbefehlFelder>
    <div class="container inner-container">
      <form [formGroup]="longOrderFG">
        <mat-card>
          <app-cleanable-form-field
            label="Lage"
            [formGroup]="longOrderFG"
            [formControlName]="lage"
            [useTextarea]="true"
          />
          <app-cleanable-form-field
            label="Auftrag"
            [formGroup]="longOrderFG"
            [formControlName]="auftragLong"
            [useTextarea]="true"
          />
          <app-cleanable-form-field
            label="Durchführung"
            [formGroup]="longOrderFG"
            [formControlName]="durchfuehrung"
            [useTextarea]="true"
          />
          <app-cleanable-form-field
            label="Versorgung"
            [formGroup]="longOrderFG"
            [formControlName]="versorgung"
            [useTextarea]="true"
          />
          <app-cleanable-form-field
            label="Führung und Kommando"
            [formGroup]="longOrderFG"
            [formControlName]="fuehrungUndKommando"
            [useTextarea]="true"
            placeholder="LANGER LANGER TEXT"
          />
        </mat-card>
      </form>

      <p class="hint">Maximal 2000 Zeichen pro Feld</p>
    </div>
  </ng-template>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="save()" class="mr-05">Speichern</button>
</mat-dialog-actions>
