<mat-card class="full-height flex-col">
  <app-fuehrungsebene-header [fuehrungsebeneDTO]="fuehrungsebeneDTO" (edit)="edit.emit()"></app-fuehrungsebene-header>

  <app-taktische-zeichen-container
    class="flex-grow"
    [fuehrungsebeneDTO]="fuehrungsebeneDTO!"
    [taktischesZeichenStatus]="fuehrungsebeneService.fuehrungsebenentypStatusMapping.get(fuehrungsebeneDTO!.typ!)!"
    [groupItems]="true"
  ></app-taktische-zeichen-container>
</mat-card>
