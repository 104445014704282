import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable } from 'rxjs';
import { PersonenuebersichtDTO } from 'src/app/api/build/openapi';
import { TaktischesZeichenPopup } from '../../taktische-zeichen/taktische-zeichen.interface';

/**
 * Kleine Komponente zur Übersicht von Personen verschiedener Zustände.
 * Personenanzahl wird nur angezeigt und kann nicht vom Benutzer editiert werden.
 */
@Component({
  selector: 'app-personenuebersicht-subset-small',
  templateUrl: './personenuebersicht-subset-small.component.html',
  styleUrls: ['./personenuebersicht-subset-small.component.scss'],
  standalone: true,
  imports: [NgIf, MatToolbarModule, MatCardModule, MatTooltipModule, AsyncPipe],
})
export class PersonenuebersichtSubsetSmallComponent implements TaktischesZeichenPopup {
  @Input()
  header = 'Personenübersicht - Gesamtzahl';

  @Input({ required: true })
  personenuebersicht$!: Observable<PersonenuebersichtDTO | undefined>;
}
