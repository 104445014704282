<div class="container">
  <mat-card *ngFor="let index of [1, 2, 3, 4, 5]">
    <div #compcontainer [id]="'card-' + index">
      <button
        mat-icon-button
        [matMenuTriggerFor]="appMenu"
        [matMenuTriggerData]="{ card: 'card-' + index }"
        *ngIf="!isCardSet('card-' + index)"
        [disabled]="disableAddingGSs"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-card>

  <mat-menu #appMenu="matMenu">
    <ng-template matMenuContent let-card="card">
      <div class="element-options">
        <div class="elements-option" *ngFor="let comp of components | keyvalue" (click)="onClickAdd(card, comp.value)">
          <img src="assets/png/custom-elemente/{{ comp.value.icon }}" />
          <h1>{{ comp.value.name }}</h1>
        </div>
      </div>
    </ng-template>
  </mat-menu>
</div>
