import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { SimulationResourceService } from 'src/app/api/build/openapi';

@Component({
  selector: 'app-simulation-config-dialog',
  templateUrl: './simulation-config-dialog.component.html',
  styleUrls: ['./simulation-config-dialog.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    NgIf,
    MatProgressSpinnerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationConfigDialogComponent {
  isLoading = false;

  private formBuilder = inject(NonNullableFormBuilder);
  fcAnzahlLagen = this.formBuilder.control<number>(1, [Validators.required, Validators.min(1), Validators.max(1000)]);
  fcMaxMeldungenProLage = this.formBuilder.control<number>(50, [
    Validators.required,
    Validators.min(0),
    Validators.max(100),
  ]);
  fcMaxFuehrungsebenenProFuehrungsebene = this.formBuilder.control<number>(3, [
    Validators.required,
    Validators.min(0),
    Validators.max(5),
  ]);
  fcMaxFahrzeugeProFuehrungsebene = this.formBuilder.control<number>(3, [
    Validators.required,
    Validators.min(0),
    Validators.max(500),
  ]);
  fcMaxPersonenProFuehrungsebene = this.formBuilder.control<number>(3, [
    Validators.required,
    Validators.min(0),
    Validators.max(500),
  ]);

  formGroup = this.formBuilder.group({
    anzahlLagen: this.fcAnzahlLagen,
    maxMeldungenProLage: this.fcMaxMeldungenProLage,
    maxFuehrungsebenenProFuehrungsebene: this.fcMaxFuehrungsebenenProFuehrungsebene,
    maxFahrzeugeProFuehrungsebene: this.fcMaxFahrzeugeProFuehrungsebene,
    maxPersonenProFuehrungsebene: this.fcMaxPersonenProFuehrungsebene,
  });

  constructor(
    private dialogRef: MatDialogRef<SimulationConfigDialogComponent>,
    private simulationService: SimulationResourceService,
    private store: Store<AppStateInterface>,
    private errorService: ErrorService
  ) {}

  validate() {
    if (this.formGroup.valid) {
      return true;
    }
    this.formGroup.markAllAsTouched();
    return false;
  }

  generateData() {
    throw Error('Simulation zurzeit nicht verfügbar!');

    // if (this.validate()) {
    //   const simulationsDatenDTO: SimulationsDatenDTO = {
    //     anzahlLagen: this.fcAnzahlLagen.value,
    //     maxMeldungenProLage: this.fcMaxMeldungenProLage.value,
    //     maxFuehrungsebenenProFuehrungsebene: this.fcMaxFuehrungsebenenProFuehrungsebene.value,
    //     maxFahrzeugeProFuehrungsebene: this.fcMaxFahrzeugeProFuehrungsebene.value,
    //     maxPersonenProFuehrungsebene: this.fcMaxPersonenProFuehrungsebene.value,
    //   };
    //   this.isLoading = true;
    //   this.formGroup.disable();
    //   this.simulationService.erzeugeDaten(simulationsDatenDTO).subscribe((result) => {
    //     if (result) {
    //       this.isLoading = false;
    //       this.formGroup.enable();
    //       this.dialogRef.close();
    //       this.store.dispatch(lageActions.getLagen());
    //     }
    //   });
    // }
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
