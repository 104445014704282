import { createSelector } from '@ngrx/store';
import { AppStateInterface, mapConfigs } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[mapConfigs];

/**
 * Liefert eine Liste aller MapLayerConfigs
 */
export const mapLayerConfigsSelector = createSelector(selectFeature, (state) => state.mapLayerConfigs);

/**
 * Liefert eine Liste der Basiskarten MapLayerConfigs
 */
export const mapBaseLayerConfigsSelector = createSelector(selectFeature, (state) =>
  state.mapLayerConfigs.filter((config) => config.isBaseLayerConfig)
);

/**
 * Liefert eine sortierte Liste der Overlay MapLayerConfigs
 */
export const mapOverlayConfigsSelector = createSelector(selectFeature, (state) =>
  state.mapLayerConfigs
    .filter((config) => !config.isBaseLayerConfig)
    .sort((a, b) => {
      if (!a.orderNumber) {
        if (!b.orderNumber) {
          return 0;
        }
        return -1;
      }

      if (!b.orderNumber) {
        return 1;
      }
      return a.orderNumber - b.orderNumber;
    })
);
