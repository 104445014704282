import { TextFieldModule } from '@angular/cdk/text-field';
import { NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, Inject, ViewChild, inject } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Geometry } from 'geojson';
import {
  FahrzeugDTO,
  FuehrungsebeneDTO,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischesZeichenStatus,
} from 'src/app/api/build/openapi';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { LageValidators } from 'src/app/shared/lage-validators';
import { GeometryPipe } from 'src/app/shared/pipes/geometry.pipe';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { SimpleMapComponent } from '../../shared/simple-map/simple-map.component';
import { TaktischesZeichenStatusPipe } from '../taktische-zeichen/taktisches-zeichen-status.pipe';
import { TzGeometryMapComponent } from '../taktische-zeichen/tz-geometry-map/tz-geometry-map.component';

export interface StatuswechselDialogInputData {
  taktischesZeichenDTO: PersonDTO | FahrzeugDTO | TaktischeFormationDTO;
  fromFuehrungsebeneDTO: FuehrungsebeneDTO;
  toFuehrungsebeneDTO: FuehrungsebeneDTO;
  newTaktischesZeichenStatus: TaktischesZeichenStatus;
}

export interface StatuswechselDialogOutputData {
  taktischesZeichenDTO: PersonDTO | FahrzeugDTO | TaktischeFormationDTO;
}

@Component({
  selector: 'app-statuswechsel-dialog',
  templateUrl: './statuswechsel-dialog.component.html',
  styleUrls: ['./statuswechsel-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    TextFieldModule,
    SimpleMapComponent,
    NgFor,
    MatButtonModule,
    MatIconModule,
    GeometryPipe,
    DatetimeLocalAccessorDirective,
    MatToolbarModule,
    TzGeometryMapComponent,
  ],
})
export class StatuswechselDialogComponent implements AfterViewInit {
  @ViewChild(TzGeometryMapComponent) map!: TzGeometryMapComponent;

  heading = 'Statuswechsel';
  fuehrungsebeneMessage?: string;
  statusMessage?: string;

  geometry?: Geometry;

  private clipboardService = inject(ClipboardService);
  private geometryPipe = inject(GeometryPipe);
  private taktischesZeichenStatusPipe = inject(TaktischesZeichenStatusPipe);

  private formBuilder = inject(NonNullableFormBuilder);

  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined);
  fcDatum = this.formBuilder.control<Date>(new Date(), [Validators.required, LageValidators.dateTimeValidator]);

  formGroup = this.formBuilder.group({
    ortsangabe: this.fcOrtsangabe,
    datum: this.fcDatum,
  });

  taktischesZeichenDTO?: PersonDTO | FahrzeugDTO | TaktischeFormationDTO;
  fromFuehrungsebeneDTO?: FuehrungsebeneDTO;
  toFuehrungsebeneDTO?: FuehrungsebeneDTO;

  private dialogRef = inject(MatDialogRef<StatuswechselDialogComponent>);

  private importantTzStatus = [
    TaktischesZeichenStatus.Alarmiert,
    TaktischesZeichenStatus.Angefordert,
    TaktischesZeichenStatus.AufMarsch,
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: StatuswechselDialogInputData) {
    if (dialogData) {
      this.taktischesZeichenDTO = dialogData.taktischesZeichenDTO;
      this.fromFuehrungsebeneDTO = dialogData.fromFuehrungsebeneDTO;
      this.toFuehrungsebeneDTO = dialogData.toFuehrungsebeneDTO;

      this.fcOrtsangabe.setValue(this.taktischesZeichenDTO.ortsangabe);
      this.geometry = this.taktischesZeichenDTO.geometry as Geometry;

      if (this.fromFuehrungsebeneDTO.id !== this.toFuehrungsebeneDTO.id) {
        this.fuehrungsebeneMessage = 'Neue Führungsebene: ' + this.toFuehrungsebeneDTO.name;
      }

      if (
        dialogData.newTaktischesZeichenStatus !== this.taktischesZeichenDTO.status?.status &&
        this.importantTzStatus.includes(dialogData.newTaktischesZeichenStatus)
      ) {
        this.statusMessage =
          'Neuer Status: ' + this.taktischesZeichenStatusPipe.transform(dialogData.newTaktischesZeichenStatus);
      }

      const headingStart =
        this.statusMessage && this.fuehrungsebeneMessage
          ? 'Führungsebenen- und Statuswechsel'
          : this.statusMessage
          ? 'Statuswechsel'
          : 'Führungsebenenwechsel';
      this.heading = headingStart + ' für ' + this.taktischesZeichenDTO.anzeigename;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.map && this.taktischesZeichenDTO) {
        this.map.setMarkerIcon(this.taktischesZeichenDTO.dataUrl || '');
        this.map.setTaktischesZeichen({
          ...this.taktischesZeichenDTO,
          fuehrungsebeneId: this.dialogData.toFuehrungsebeneDTO.id,
        });
      }
    });
  }

  setKoordinate(geometrie: GeoJSON.Geometry) {
    this.geometry = geometrie;
  }

  prepareResult(): void {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (this.taktischesZeichenDTO) {
      const response: StatuswechselDialogOutputData = {
        taktischesZeichenDTO: {
          ...this.taktischesZeichenDTO,
          ortsangabe: this.fcOrtsangabe.value,
          geometry: this.geometry,
          fuehrungsebeneId: this.toFuehrungsebeneDTO?.id,
          status: {
            zeitpunktStatusWechsel: this.fcDatum.value.toISOString(),
            status: this.dialogData.newTaktischesZeichenStatus,
          },
        },
      };
      this.dialogRef.close(response);
    }
  }

  copyGeometryToClipboard(event: MouseEvent): void {
    this.clipboardService.copy(this.geometryPipe.transform(this.geometry));
    event.stopPropagation();
  }
}
