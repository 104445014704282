import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, FahrzeugDTO } from 'src/app/api/build/openapi';

export const fahrzeugActions = createActionGroup({
  source: 'Fahrzeuge',
  events: {
    'Reset Store': emptyProps(),

    'Get Fahrzeuge': props<{ lageId: string }>(),
    'Get Fahrzeuge Success': props<{ fahrzeuge: FahrzeugDTO[]; lageId: string }>(),
    'Get Fahrzeuge Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Fahrzeug': props<{ lageId: string; fahrzeug: FahrzeugDTO }>(),
    'Create Fahrzeug Success': props<{ newFahrzeug: FahrzeugDTO }>(),
    'Create Fahrzeug Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Fahrzeug': props<{ lageId: string; fahrzeugId: string; fahrzeugDTO: FahrzeugDTO }>(),
    'Patch Fahrzeug Success': props<{ patchedFahrzeug: FahrzeugDTO }>(),
    'Patch Fahrzeug Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Fahrzeug': props<{ fahrzeugDTO: FahrzeugDTO; lageId: string }>(),
    'Delete Fahrzeug Success': props<{ deletedFahrzeugDTO: FahrzeugDTO }>(),
    'Delete Fahrzeug Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
