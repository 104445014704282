import { createReducer, on } from '@ngrx/store';
import { anwesenheitenActions } from './anwesenheiten.actions';
import { AnwesenheitenStateInterface } from './anwesenheitenState.interface';
import { addOrReplaceWithLatest } from 'src/app/shared/dto-version-tools';

const initialState: AnwesenheitenStateInterface = {
  isLoading: false,
  isCreating: false,
  isPatching: false,
  isDeleting: false,
  anwesenheiten: [],
  errorResponse: undefined,
  loadedLageId: '',
};

export const anwesenheitenReducer = createReducer(
  initialState,

  on(anwesenheitenActions.resetStore, (): AnwesenheitenStateInterface => {
    return { ...initialState };
  }),
  on(
    anwesenheitenActions.getAnwesenheiten,
    (state): AnwesenheitenStateInterface => ({ ...state, isLoading: true, errorResponse: undefined })
  ),
  on(
    anwesenheitenActions.getAnwesenheitenSuccess,
    (state, action): AnwesenheitenStateInterface => ({
      ...state,
      isLoading: false,
      anwesenheiten: action.anwesenheiten,
      errorResponse: undefined,
    })
  ),
  on(
    anwesenheitenActions.getAnwesenheitenFailure,
    (state, action): AnwesenheitenStateInterface => ({
      ...state,
      isLoading: false,
      errorResponse: action.errorResponse,
    })
  ),
  on(
    anwesenheitenActions.createAnwesenheit,
    (state): AnwesenheitenStateInterface => ({ ...state, isCreating: true, errorResponse: undefined })
  ),
  on(
    anwesenheitenActions.createAnwesenheitSuccess,
    (state, action): AnwesenheitenStateInterface => ({
      ...state,
      isCreating: false,
      anwesenheiten: [...state.anwesenheiten, action.anwesenheit],
      errorResponse: undefined,
    })
  ),
  on(
    anwesenheitenActions.createAnwesenheitFailure,
    (state, action): AnwesenheitenStateInterface => ({
      ...state,
      isCreating: false,
      errorResponse: action.errorResponse,
    })
  ),
  on(
    anwesenheitenActions.patchAnwesenheit,
    (state): AnwesenheitenStateInterface => ({ ...state, isPatching: true, errorResponse: undefined })
  ),
  on(
    anwesenheitenActions.patchAnwesenheitSuccess,
    (state, action): AnwesenheitenStateInterface => ({
      ...state,
      isPatching: false,
      anwesenheiten: addOrReplaceWithLatest(state.anwesenheiten, action.patchedAnwesenheit),
      errorResponse: undefined,
    })
  ),
  on(
    anwesenheitenActions.createAnwesenheitFailure,
    (state, action): AnwesenheitenStateInterface => ({
      ...state,
      isPatching: false,
      errorResponse: action.errorResponse,
    })
  ),
  on(
    anwesenheitenActions.deleteAnwesenheit,
    (state): AnwesenheitenStateInterface => ({ ...state, isDeleting: true, errorResponse: undefined })
  ),
  on(anwesenheitenActions.deleteAnwesenheitSuccess, (state, action): AnwesenheitenStateInterface => {
    const newAnwesenheiten = state.anwesenheiten!.filter((a) => a.id != action.deletedAnwesenheitId);
    return { ...state, isDeleting: false, anwesenheiten: newAnwesenheiten, errorResponse: undefined };
  }),
  on(
    anwesenheitenActions.deleteAnwesenheitFailure,
    (state, action): AnwesenheitenStateInterface => ({
      ...state,
      isDeleting: false,
      errorResponse: action.errorResponse,
    })
  )
);
