import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }

  /**
   * Erwartet ein Datum und liefert einen Date-String in Form dd.MM.yyyy
   */
  override format(date: Date, displayFormat: object): string {
    if (!date) {
      return '';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).padStart(2, '0');
    return `${day}.${month}.${year}`;
  }

  /**
   * Erwartet einen Wert in Form dd.MM.yyyy und erzeugt daraus ein Date Objekt.
   * Liefert null, wenn Wert nicht in Datum umgewandelt werden kann.
   */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  override parse(value: any, parseFormat?: any): Date | null {
    if (!value) {
      return null;
    }

    const split = String(value)?.split('.');
    if (!split || split.length != 3) {
      return null;
    }

    try {
      return new Date(Number(split[2]), Number(split[1]) - 1, Number(split[0]));
    } catch (e) {
      return null;
    }
  }
}
