import { createReducer, on } from '@ngrx/store';
import { getNewest, replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { LageStateInterface } from './lage-state.interface';
import { lageActions } from './lage.actions';

const initialState: LageStateInterface = {
  isLoading: false,
  isSaving: false,
  lagen: [],
  isLoadingEinsaetze: false,
  currentLage: null,
  /**
   * Lagezeit ist zwar Teil der currentLage Lage, wird jedoch separat verwaltet.
   * Wird die Lagezeit editiert, wird dadurch die aktuelle Lage nicht neu gesetzt
   * und somit das Neuladen aller Abhängigen Entites vermieden.
   */
  lagezeit: undefined,
};

export const lageReducer = createReducer(
  initialState,

  // Get Lagen
  on(lageActions.getLagen, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(lageActions.getLagenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    lagen: action.lagen,
    errorResponse: undefined,
  })),
  on(lageActions.getLagenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Current Lage
  on(lageActions.setCurrentLage, (state, action) => ({
    ...state,
    currentLage: action.currentLage,
    lagezeit: action.currentLage?.lagezeit,
  })),

  // Add Lage
  on(lageActions.addLage, (state, action) => ({
    ...state,
    lagen: [...state.lagen, action.newLage],
  })),

  // Create Lage
  on(lageActions.createLage, (state) => ({ ...state, isSaving: true, errorResponse: undefined })),
  on(lageActions.createLageSuccess, (state, action) => ({
    ...state,
    lagen: [...state.lagen, action.newLage],
    currentLage: action.newLage,
    lagezeit: action.newLage?.lagezeit,
    isSaving: false,
    errorResponse: undefined,
  })),
  on(lageActions.createLageFailure, (state, action) => ({
    ...state,
    isSaving: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Lage
  on(lageActions.patchLage, (state) => ({ ...state, isSaving: true, errorResponse: undefined })),
  on(lageActions.patchLageSuccess, (state, action) => {
    const currentLage =
      state.currentLage && state.currentLage?.id === action.patchedLage.id
        ? getNewest(state.currentLage, action.patchedLage)
        : state.currentLage;

    return {
      ...state,
      lagen: replaceOrKeepCurrent(state.lagen, action.patchedLage),
      currentLage,
      lagezeit: currentLage?.lagezeit,
      isSaving: false,
      errorResponse: undefined,
    };
  }),
  on(lageActions.patchLageFailure, (state, action) => ({
    ...state,
    isSaving: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Lagezeit
  on(lageActions.patchLagezeit, (state) => ({ ...state, isSaving: true, errorResponse: undefined })),
  on(lageActions.patchLagezeitSuccess, (state, action) => ({
    ...state,
    lagezeit: action.patchedLage.lagezeit,
    isSaving: false,
    errorResponse: undefined,
  })),
  on(lageActions.patchLageFailure, (state, action) => ({
    ...state,
    isSaving: false,
    errorResponse: action.errorResponse,
  }))
);
