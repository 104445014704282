import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { Store } from '@ngrx/store';
import { currentLageSelector } from '../+state/lage.selectors';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';

@Component({
  selector: 'app-lage-infobar',
  templateUrl: './lage-infobar.component.html',
  styleUrls: ['./lage-infobar.component.scss'],
  standalone: true,
  imports: [NgIf, MatChipsModule, NgFor, AsyncPipe, DateTimePipe, MatCardModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LageInfobarComponent {
  private store = inject(Store);
  currentLage$ = this.store.select(currentLageSelector);
}
