import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TaktischesZeichenTyp, TierschadenDTO } from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { TaktischesZeichen, erzeugeTaktischesZeichen } from 'taktische-zeichen-core';
import { DataUrlChangeEvent } from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';
import { TierschadenSubset } from '../tierschaden-subset.interface';
import { TierschadenSubsetComponent } from '../tierschaden-subset/tierschaden-subset.component';

@Component({
  selector: 'app-tierschaden-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    CleanableFormFieldComponent,
    TierschadenSubsetComponent,
  ],
  templateUrl: './tierschaden-form.component.html',
  styleUrls: ['./tierschaden-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TierschadenFormComponent implements TaktischesZeichenForm {
  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  dataUrlChanged = new EventEmitter<DataUrlChangeEvent>();

  @ViewChild('tierschadenComponent')
  tierschadenComponent!: TierschadenSubsetComponent;

  @ViewChild('tierschadenEkComponent')
  tierschadenEkComponent!: TierschadenSubsetComponent;

  readonly EMPTY_ZEICHEN: TaktischesZeichen = { symbol: 'veterinaerwesen' };
  editorZeichen: TaktischesZeichen = { ...this.EMPTY_ZEICHEN };
  currentZeichenDataUrl: string = erzeugeTaktischesZeichen(this.editorZeichen).dataUrl;
  customZeichen = false;

  dtoToEdit?: TierschadenDTO;

  private formBuilder = inject(NonNullableFormBuilder);
  fcAnzeigename = this.formBuilder.control('', [Validators.maxLength(30), Validators.required]);
  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
  });

  tierschaden: TierschadenSubset = {
    verletzt: 0,
    tot: 0,
    betroffen: 0,
  };

  tierschadenEk: TierschadenSubset = {
    verletzt: 0,
    tot: 0,
    betroffen: 0,
  };

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });
  }

  setDTO(dto: TaktischesZeichenDTO) {
    this.dtoToEdit = dto as TierschadenDTO;
    if (this.dtoToEdit) {
      this.dtoToFormGroup(this.dtoToEdit);
      if (this.dtoToEdit.customZeichen && this.dtoToEdit.dataUrl) {
        this.generateTaktischesZeichen(this.dtoToEdit.dataUrl);
        return;
      }
    }
    this.generateTaktischesZeichen();
  }

  setCustomTz(dataUrl: string) {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
      this.customZeichen = true;
    } else {
      this.customZeichen = false;
      this.generateTaktischesZeichen();
    }
  }

  /**
   * Aktualisiertes DTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): TierschadenDTO | null {
    if (!this.formGroup.valid || !this.tierschadenComponent.isValid() || !this.tierschadenEkComponent.isValid()) {
      this.formGroup.markAllAsTouched();
      return null;
    }

    const tierschaden = this.tierschadenComponent.getDto();
    const tierschadenEk = this.tierschadenEkComponent.getDto();

    return {
      ...this.dtoToEdit,
      anzeigename: this.fcAnzeigename?.value.trim(),
      tierVerletzt: tierschaden.verletzt,
      tierTot: tierschaden.tot,
      tierBetroffen: tierschaden.betroffen,
      ekVerletzt: tierschadenEk.verletzt,
      ekTot: tierschadenEk.tot,
      ekBetroffen: tierschadenEk.betroffen,
      dataUrl: this.currentZeichenDataUrl,
      customZeichen: this.customZeichen,
      typ: TaktischesZeichenTyp.Tierschaden,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(dto: TierschadenDTO): void {
    this.fcAnzeigename.setValue(dto.anzeigename);
    this.tierschaden = {
      verletzt: dto.tierVerletzt || 0,
      tot: dto.tierTot || 0,
      betroffen: dto.tierBetroffen || 0,
    };

    this.tierschadenEk = {
      verletzt: dto.ekVerletzt || 0,
      tot: dto.ekTot || 0,
      betroffen: dto.ekBetroffen || 0,
    };

    this.customZeichen = dto.customZeichen || false;
  }

  /**
   * DataURL an Parent senden und dort aktualisieren.
   */
  generateTaktischesZeichen(dataUrl = '') {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
    } else {
      const zeichen = erzeugeTaktischesZeichen(this.editorZeichen);
      this.currentZeichenDataUrl = zeichen.dataUrl;
    }

    this.dataUrlChanged.emit({ dataUrl: this.currentZeichenDataUrl, customZeichen: this.customZeichen });
  }
}
