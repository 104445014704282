import { createReducer, on } from '@ngrx/store';
import { getNewest } from 'src/app/shared/dto-version-tools';
import { WetterStateInterface } from './wetter-state.interface';
import { wetterActions } from './wetter.actions';

const initialState: WetterStateInterface = {
  isLoading: false,
  isCreating: false,
  isPatching: false,
  errorResponse: undefined,
  wetter: undefined,
  loadedLageId: '',
};

export const wetterReducers = createReducer(
  initialState,

  // Get Wetter
  on(wetterActions.getWetter, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(wetterActions.getWetterSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    wetter: action.wetter,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(wetterActions.getWetterFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Wetter
  on(wetterActions.createWetter, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(wetterActions.createWetterSuccess, (state, action) => ({
    ...state,
    wetter: action.createdWetter,
    isCreating: false,
    errorResponse: undefined,
  })),
  on(wetterActions.createWetterFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Wetter
  on(wetterActions.patchWetter, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(wetterActions.patchWetterSuccess, (state, action) => ({
    ...state,
    wetter: state.wetter ? getNewest(state.wetter, action.patchedWetter) : state.wetter,
    isPatching: false,
    errorResponse: undefined,
  })),
  on(wetterActions.patchWetterFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  }))
);
