import { Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Chart, registerables } from 'chart.js/auto';
import { PersonenDataset, ManvDataset } from '../kennzahlen-dashboad.service';
Chart.register(...registerables);

@Component({
  selector: 'app-dashboard-personen',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatToolbarModule],
  templateUrl: './dashboard-personen.component.html',
  styleUrls: ['./dashboard-personen.component.scss'],
})
export class DashboardPersonenComponent implements AfterViewInit {
  @ViewChild('personenchart')
  personenCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('manvchart')
  manvCanvas!: ElementRef<HTMLCanvasElement>;

  private personenChart?: Chart<any>;
  private manvChart?: Chart<any>;

  @Input()
  set einsatzkraefteDataset(dataset: PersonenDataset) {
    this.updatePersonenChart(dataset);
  }

  @Input()
  set bevoelkerungDataset(dataset: PersonenDataset) {
    this.updatePersonenChart(dataset);
  }

  @Input()
  set manvDataset(dataset: ManvDataset) {
    this.updateManvChart(dataset);
  }

  ngAfterViewInit(): void {
    const ctxPersonen = this.personenCanvas.nativeElement;
    const ctxManv = this.manvCanvas.nativeElement;

    const labels = ['verletzt', 'tot', 'vermisst', 'betroffen', 'gerettet', 'transportiert'];
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Bevölkerung',
          data: [0, 0, 0, 0, 0, 0],
          borderColor: 'rgb(255, 77, 174)',
          backgroundColor: 'rgb(255, 77, 174)',
        },
        {
          label: 'Einsatzkräfte',
          data: [0, 0, 0, 0, 0, 0],
          borderColor: 'rgb(179, 18, 106)',
          backgroundColor: 'rgb(179, 18, 106)',
        },
      ],
    };

    this.personenChart = new Chart(ctxPersonen, {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Personenschäden',
          },
        },
      },
    });

    this.manvChart = new Chart(ctxManv, {
      type: 'doughnut',
      data: {
        labels: ['I', 'II', 'III', 'IV', 'Tote'],

        datasets: [
          {
            label: 'MANV',
            data: [0, 0, 0, 0, 0],
            backgroundColor: ['#d74042', '#f2d74e', '#3b804f', '#187bb4', '202125'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'MANV',
          },
          legend: {
            display: true,
            position: 'top',
            align: 'start',

            labels: {
              generateLabels: (chart) => {
                const datasets: any = chart.data.datasets;
                return datasets[0].data.map((data: any, i: number) => ({
                  text: `${chart.data.labels![i]} ${data}`,
                  fillStyle: datasets![0].backgroundColor![i],
                  index: i,
                }));
              },
            },
          },
        },
      },
    });
  }

  private updatePersonenChart(personenDataset: PersonenDataset) {
    if (this.personenChart) {
      const dataset = this.personenChart.data.datasets.filter((ds) => ds.label === personenDataset.name)[0];
      const p = personenDataset.personen;
      dataset.data[0] = p.verletzt;
      dataset.data[1] = p.tot;
      dataset.data[2] = p.vermisst;
      dataset.data[3] = p.betroffen;
      dataset.data[4] = p.gerettet;
      dataset.data[5] = p.transportiert;
      this.personenChart.update();
    }
  }

  private updateManvChart(manvChart: ManvDataset) {
    if (this.manvChart) {
      const data = this.manvChart.data.datasets[0].data;
      const manv = manvChart.manv;
      data[0] = manv.category1;
      data[1] = manv.category2;
      data[2] = manv.category3;
      data[3] = manv.category4;
      data[4] = manv.dead;
      this.manvChart.update();
    }
  }
}
