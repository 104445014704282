<mat-toolbar class="personen-uebersicht-header">
  <h2>Personenübersicht</h2>
  <span class="spacer"></span>
  <button mat-icon-button (click)="edit()" matTooltip="Personenübersicht anzeigen">
    <mat-icon>visibility</mat-icon>
  </button>
</mat-toolbar>

<app-loadable [isLoading$]="isLoadingPersonenuebersicht$">
  <app-personenuebersicht-subset-small
    header="Personenübersicht - Gesamtzahl"
    [personenuebersicht$]="personenuebersicht$"
  />
  <app-manv-small />
</app-loadable>
