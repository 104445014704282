import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, Input, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ErrorService } from '@product/ise-error-lib';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import { first } from 'rxjs';
import { Einsatzleitungstyp, FuehrungsebeneDTO, Fuehrungsebenentyp, LageDTO } from 'src/app/api/build/openapi';
import { ComponentQualificationService } from 'src/app/misc/component-qualification.service';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { erzeugeTaktischesZeichen } from 'taktische-zeichen-core';
import { EinsatzfilterSelectionListComponent } from '../../../einsatz/einsatzfilter-selection-list/einsatzfilter-selection-list.component';
import { SimpleMapComponent } from '../../../shared/simple-map/simple-map.component';
import { AuftragListComponent } from '../../auftrag/auftrag-list/auftrag-list.component';
import { KontaktListComponent } from '../../kontakt/kontakt-list/kontakt-list.component';
import { LayerService } from '../../layer.service';
import { FuehrungsebeneService } from '../fuehrungsebene.service';

@Component({
  selector: 'app-fuehrungsebene-details-panel',
  templateUrl: './fuehrungsebene-details-panel.component.html',
  styleUrls: ['./fuehrungsebene-details-panel.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatDatepickerModule,
    KontaktListComponent,
    AuftragListComponent,
    EinsatzfilterSelectionListComponent,
    DatetimeLocalAccessorDirective,
    MatCheckboxModule,
    MatRadioModule,
    SimpleMapComponent,
  ],
})
export class FuehrungsebeneDetailsPanelComponent implements AfterViewInit {
  @ViewChild(KontaktListComponent)
  kontaktList!: KontaktListComponent;

  @ViewChild(AuftragListComponent)
  auftragList!: AuftragListComponent;

  @ViewChild(EinsatzfilterSelectionListComponent)
  einsatzfilterList!: EinsatzfilterSelectionListComponent;

  @Input()
  fuehrungsebeneDTO!: FuehrungsebeneDTO | LageDTO;

  other = this.prepareEmptyFeatureCollection();

  Fuehrungsebenentyp = Fuehrungsebenentyp;
  Einsatzleitungstyp = Einsatzleitungstyp;

  private destroyRef = inject(DestroyRef);
  private layerService = inject(LayerService);
  private componentService = inject(ComponentQualificationService);
  private errorService = inject(ErrorService);
  protected fuehrungsebeneService = inject(FuehrungsebeneService);

  // Wenn Parent ein Schadengebiet ist, darf Nutzer bei neuer Führungsebene zwischen Einsatzraum und Einsatzstelle wählen
  showTypeSelection = false;

  @Input()
  filteredTypes: Fuehrungsebenentyp[] = [];
  selectableTypes: Fuehrungsebenentyp[] = [];

  showEinsatzleitung = false;
  showMedizinischeRettung = false;
  showEinsatzfilter = false;

  readonly medizinischeRettungFuehrungsebenentypen = [
    Fuehrungsebenentyp.Einsatzraum,
    Fuehrungsebenentyp.Einsatzstelle,
    Fuehrungsebenentyp.Einsatzabschnitt,
    Fuehrungsebenentyp.Unterabschnitt,
  ];

  geometryValid = true;

  private formBuilder = inject(NonNullableFormBuilder);
  fcName = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcKuerzel = this.formBuilder.control<string | undefined>(undefined, [
    Validators.maxLength(4),
    Validators.pattern('[a-zA-Z0-9-/]*'),
  ]);
  fcAdresse = this.formBuilder.control<string | undefined>(undefined);
  fcStartedOn = this.formBuilder.control<Date | undefined>(undefined);
  fcLeiterName = this.formBuilder.control<string | undefined>(undefined);
  fcLeiterDienstgrad = this.formBuilder.control<string | undefined>(undefined);
  fcLeiterFunkrufname = this.formBuilder.control<string | undefined>(undefined);
  fcEinsatzleitung = this.formBuilder.control<boolean>(false);
  fcEinsatzleitungstyp = this.formBuilder.control<Einsatzleitungstyp | undefined>(undefined);
  fcMedizinischeRettung = this.formBuilder.control<boolean>(false);

  formGroup = this.formBuilder.group({
    name: this.fcName,
    kuerzel: this.fcKuerzel,
    adresse: this.fcAdresse,
    startedOn: this.fcStartedOn,
    leiterName: this.fcLeiterName,
    leiterDienstgrad: this.fcLeiterDienstgrad,
    leiterFunkrufname: this.fcLeiterFunkrufname,
    einsatzleitung: this.fcEinsatzleitung,
    einsatzleitungstyp: this.fcEinsatzleitungstyp,
    medizinischeRettung: this.fcMedizinischeRettung,
  });

  // aktuell ausgewählte Kommunikationsmöglichkeit
  protected selectedIndex = -1;

  feature: Feature | undefined;

  ngAfterViewInit(): void {
    setTimeout(() => {
      const parentFuehrungsebene = this.fuehrungsebeneService
        .getAllFuehrungsebenen()
        .find((fuehrungsebene) => fuehrungsebene.id === this.fuehrungsebeneDTO.parentFuehrungsebeneId);
      if (parentFuehrungsebene) {
        this.selectableTypes = this.fuehrungsebeneService.getAvailableChildTypes(
          parentFuehrungsebene,
          this.filteredTypes
        );
      }
      this.showTypeSelection = !this.fuehrungsebeneDTO.id && this.selectableTypes.length > 1;

      this.showEinsatzleitung =
        this.fuehrungsebeneDTO && this.fuehrungsebeneDTO.typ === Fuehrungsebenentyp.OperativTaktisch;
      if (this.showEinsatzleitung) {
        this.fcEinsatzleitung.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
          if (value) {
            this.fcEinsatzleitungstyp.enable();
          } else {
            this.fcEinsatzleitungstyp.disable();
            this.fcEinsatzleitungstyp.setValue(undefined);
          }
        });
      }

      this.showMedizinischeRettung = this.medizinischeRettungFuehrungsebenentypen.includes(this.fuehrungsebeneDTO.typ);

      this.componentService.isShowDevelopment$.pipe(first()).subscribe((showDevelopment) => {
        this.showEinsatzfilter =
          showDevelopment && this.fuehrungsebeneService.mainFuehrungsebenentypen.includes(this.fuehrungsebeneDTO.typ);
      });

      this.modelToFormGroup();
      this.setFeature();

      const preparedOthers = this.prepareEmptyFeatureCollection();

      // Lage zeichnen (falls gerade nicht die Lage selbst bearbeitet wird)
      const lage = this.fuehrungsebeneService.getCurrentLage();
      if (lage && lage.id !== this.fuehrungsebeneDTO.id) {
        this.mergeFeatureCollections(preparedOthers, this.prepareLageFeatureCollection(lage));
      }

      const fuehrungsebenen = this.fuehrungsebeneService.getAllFuehrungsebenen();
      if (fuehrungsebenen) {
        this.mergeFeatureCollections(
          preparedOthers,
          this.prepareFuehrungsebenenFeatureCollection(
            fuehrungsebenen.filter((f) => f.id !== this.fuehrungsebeneDTO.id)
          )
        );
      }

      if (preparedOthers.features.length > 0) {
        this.other = preparedOthers;
      }

      this.fcKuerzel.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
        if (this.fcKuerzel.valid) {
          this.formGroupToModel();
          this.setFeature();
        }
      });

      window.dispatchEvent(new Event('resize'));
    });
  }

  private setFeature() {
    const style = this.layerService.getFuehrungsebeneGeometryStyle(this.fuehrungsebeneDTO, false);

    const f: Feature = {
      type: 'Feature',
      geometry: this.fuehrungsebeneDTO.geometry as Geometry,
      properties: { style, tooltip: this.fuehrungsebeneDTO.name },
    };
    this.feature = f;
  }

  private mergeFeatureCollections(main: FeatureCollection, other: FeatureCollection) {
    main.features.push(...other.features);
  }

  private prepareEmptyFeatureCollection(): FeatureCollection {
    return { type: 'FeatureCollection', features: [] };
  }

  private prepareFuehrungsebenenFeatureCollection(fuehrungsebenenDTOs: FuehrungsebeneDTO[]) {
    const otherFeatureCollection: FeatureCollection = this.prepareEmptyFeatureCollection();

    fuehrungsebenenDTOs.forEach((f) => otherFeatureCollection.features.push(this.prepareFeature(f)));
    return otherFeatureCollection;
  }

  private prepareLageFeatureCollection(lageDTO: LageDTO) {
    const collection = this.prepareEmptyFeatureCollection();

    const style = this.layerService.getFuehrungsebeneGeometryStyle(lageDTO, false);
    const properties = { style, tooltip: lageDTO.name };

    collection.features.push({
      type: 'Feature',
      geometry: lageDTO.geometry as Geometry,
      properties,
    } as Feature);

    return collection;
  }

  private prepareFeature(fuehrungsebeneDTO: FuehrungsebeneDTO): Feature {
    const style = this.layerService.getFuehrungsebeneGeometryStyle(fuehrungsebeneDTO, false);
    const properties = { style, tooltip: fuehrungsebeneDTO.name };

    return {
      type: 'Feature',
      geometry: fuehrungsebeneDTO.geometry as Geometry,
      properties,
    } as Feature;
  }

  /**
   * Wenn sich der Fuehrungsebenentyp ändert, muss Geometrie auf Karte neugezeichnet werden,
   * damit Icon, Tooltip und Polygonfarbe zum Fuehrungsebenentypen passen
   */
  updateGeometryStyle() {
    if (this.fuehrungsebeneDTO) {
      this.formGroupToModel();
      this.setFeature();
    }
  }

  /**
   * Validiert alle Form-Felder und markiert ungültige Felder.
   */
  validate(): boolean {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return false;
    }

    return true;
  }

  /**
   * Schreibt Werte aus der FormGroup in das aktuelle FuehrungsebeneDTO
   */
  formGroupToModel(): void {
    if (!this.fuehrungsebeneDTO) {
      return;
    }
    this.fuehrungsebeneDTO.name = this.fcName.value.trim();
    this.fuehrungsebeneDTO.kuerzel = this.fcKuerzel.value?.trim();
    if (this.fuehrungsebeneDTO.kuerzel) {
      this.fuehrungsebeneDTO.symbol = erzeugeTaktischesZeichen({
        grundzeichen: 'stelle',
        organisation: 'fuehrung',
        text: this.fuehrungsebeneDTO.kuerzel,
      }).dataUrl;
    }
    this.fuehrungsebeneDTO.adresse = this.fcAdresse.value?.trim();
    this.fuehrungsebeneDTO.startedOn = this.fcStartedOn.value?.toISOString();
    this.fuehrungsebeneDTO.leiterName = this.fcLeiterName.value?.trim();
    this.fuehrungsebeneDTO.leiterDienstgrad = this.fcLeiterDienstgrad.value?.trim();
    this.fuehrungsebeneDTO.leiterFunkrufname = this.fcLeiterFunkrufname.value?.trim();
    this.fuehrungsebeneDTO.einsatzleitung = this.fcEinsatzleitung.value;
    this.fuehrungsebeneDTO.einsatzleitungstyp = this.fcEinsatzleitungstyp.value;
    this.fuehrungsebeneDTO.medizinischeRettung = this.fcMedizinischeRettung.value;
    this.fuehrungsebeneDTO.kommunikationOptionen = this.kontaktList.getKommunikationOptionen();
    this.fuehrungsebeneDTO.auftraege = this.auftragList.getAuftraege();
    if (this.showEinsatzfilter) {
      this.fuehrungsebeneDTO.einsatzfilter = this.einsatzfilterList.getEinsatzfilterDTOs();
    }
  }

  /**
   * Schreibt Werte aus dem aktuellen FuehrungsebeneDTO in die FormGroup
   */
  modelToFormGroup(): void {
    if (!this.fuehrungsebeneDTO) {
      return;
    }
    this.formGroup.reset();
    this.fcName.setValue(this.fuehrungsebeneDTO.name);
    this.fcKuerzel.setValue(this.fuehrungsebeneDTO.kuerzel);
    this.fcAdresse.setValue(this.fuehrungsebeneDTO.adresse);
    this.fcStartedOn.setValue(
      this.fuehrungsebeneDTO.startedOn ? new Date(this.fuehrungsebeneDTO.startedOn) : undefined
    );
    this.fcLeiterName.setValue(this.fuehrungsebeneDTO.leiterName);
    this.fcLeiterDienstgrad.setValue(this.fuehrungsebeneDTO.leiterDienstgrad);
    this.fcLeiterFunkrufname.setValue(this.fuehrungsebeneDTO.leiterFunkrufname);
    this.fcEinsatzleitung.setValue(this.fuehrungsebeneDTO.einsatzleitung || false);
    this.fcEinsatzleitungstyp.setValue(this.fuehrungsebeneDTO.einsatzleitungstyp);
    this.fcMedizinischeRettung.setValue(this.fuehrungsebeneDTO.medizinischeRettung || false);
    this.auftragList.setAuftraege(this.fuehrungsebeneDTO.auftraege || []);
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  featureChanged = (feature: Feature) => {
    this.fuehrungsebeneDTO.geometry = feature.geometry;
  };

  showError($event: string) {
    this.errorService.showError({ errorMessages: [$event] });
  }
}
