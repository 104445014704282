import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
// import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { ComponentQualificationService } from 'src/app/misc/component-qualification.service';
// import { InjectionToken } from '@angular/core';

export enum LogLevel {
  Info = 1,
  Warn = 2,
  Error = 3,
  Fatal = 4,
}
// const BASE_PATH = new InjectionToken<string>('basePath');

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Service zum einheitlichen Loggen von Log-Meldungen.
 * Je nach Loglevel werden die Log-Meldungen client- und serverseitig gesendet.
 * Loggt zusätzlich Zeitpunkt und Loglevel.
 *
 * TODO: Noch fehlt eine Backend-Anbindung für das serverseitige Logging.
 */
@Injectable({
  providedIn: 'root',
})
export class LogService {
  // LogLevel, ab dem auf Client geloggt wird
  clientLogLevel: LogLevel = LogLevel.Info;
  // LogLevel, ab dem auf Server geloggt wird
  serverLogLevel: LogLevel = LogLevel.Error;
  private showDevelopmentComponents = false;

  constructor(private qualificationService: ComponentQualificationService) {
    this.qualificationService.isShowDevelopment$
      .pipe(takeUntilDestroyed())
      .subscribe((show) => (this.showDevelopmentComponents = show));
  }

  private log(logLevel: LogLevel, message?: any, ...optionalParams: any[]) {
    if (logLevel >= this.clientLogLevel) {
      const date = new Date().toISOString();
      switch (logLevel) {
        case LogLevel.Info:
          optionalParams.length
            ? console.info(`${date} [INFO] ${message}`, ...optionalParams)
            : console.info(`${date} [INFO] ${message}`);
          break;
        case LogLevel.Warn:
          optionalParams.length
            ? console.warn(`${date} [WARN] ${message}`, ...optionalParams)
            : console.warn(`${date} [WARN] ${message}`);
          break;
        case LogLevel.Error:
          optionalParams.length
            ? console.error(`${date} [ERROR] ${message}`, ...optionalParams)
            : console.error(`${date} [ERROR] ${message}`);
          break;
        case LogLevel.Fatal:
          optionalParams.length
            ? console.error(`${date} [FATAL] ${message}`, ...optionalParams)
            : console.error(`${date} [FATAL] ${message}`);
          break;
        default:
          optionalParams.length
            ? console.log(`${date} [DEFAULT] ${message}`, ...optionalParams)
            : console.log(`${date} [DEFAULT] ${message}`);
      }
    }

    if (logLevel >= this.serverLogLevel) {
      // TODO: Log an Backend senden
    }
  }

  info(message?: any, ...optionalParams: any[]) {
    optionalParams.length ? this.log(LogLevel.Info, message, optionalParams) : this.log(LogLevel.Info, message);
  }

  warn(message?: any, ...optionalParams: any[]) {
    optionalParams.length ? this.log(LogLevel.Warn, message, optionalParams) : this.log(LogLevel.Warn, message);
  }

  error(message?: any, ...optionalParams: any[]) {
    optionalParams.length ? this.log(LogLevel.Error, message, optionalParams) : this.log(LogLevel.Error, message);
  }

  fatal(message?: any, ...optionalParams: any[]) {
    optionalParams.length ? this.log(LogLevel.Fatal, message, optionalParams) : this.log(LogLevel.Fatal, message);
  }

  /**
   * Info-LOG, wenn "Development"-Komponenten aktiviert
   *
   * @param message
   * @param optionalParams
   */
  devInfo(message?: any, ...optionalParams: any[]) {
    if (this.showDevelopmentComponents) {
      this.info(message, optionalParams);
    }
  }

  /**
   * Info-LOG, wenn "Development"-Komponenten aktiviert
   *
   * @param message
   * @param optionalParams
   */
  devWarn(message?: any, ...optionalParams: any[]) {
    if (this.showDevelopmentComponents) {
      this.warn(message, optionalParams);
    }
  }
}
