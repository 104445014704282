import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, of } from 'rxjs';
import { stabMassnahmenActions } from './stabmassnahmen.actions';
import { StabMassnahmeResourceService } from 'src/app/api/build/openapi/api/stabMassnahmeResource.service';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';

@Injectable()
export class StabMassnahmenEffects {
  /**
   * Wenn Sich die aktuelle Lage ändert, Neuladen der Wetterinformation anstoßen
   */

  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage) {
          return stabMassnahmenActions.getStabMassnahmen({ lageId: props.currentLage!.id! });
        } else {
          return stabMassnahmenActions.resetStore();
        }
      })
    )
  );

  getMassnahmen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stabMassnahmenActions.getStabMassnahmen),
      switchMap((props) =>
        this.stabMassnahmeResourceService.getStabMassnahmenByLageId(props.lageId).pipe(
          map((result) => stabMassnahmenActions.getStabMassnahmenSuccess({ massnahmen: result })),
          catchError((error) => of(stabMassnahmenActions.getStabMassnahmenFailure({ errorResponse: error.message })))
        )
      )
    )
  );

  addMassnahme$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(stabMassnahmenActions.addStabMassnahme),
        switchMap((props) => this.stabMassnahmeResourceService.createStabMassnahme(props.lageId!, props.massnahme))
      )
      .pipe(
        map((massnahme) => stabMassnahmenActions.addStabMassnahmeSuccess({ massnahme })),
        catchError((error) => {
          return of(stabMassnahmenActions.addStabMassnahmeFailure({ errorResponse: error.message }));
        })
      )
  );

  patchMassnahme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stabMassnahmenActions.patchStabMassnahme),
      switchMap((props) =>
        this.stabMassnahmeResourceService.patchStabMassnahme(props.lageid, props.massnahmeId, props.massnahme).pipe(
          map((patchedMassnahme) => stabMassnahmenActions.patchStabMassnahmeSuccess({ patchedMassnahme })),
          catchError((error) => of(stabMassnahmenActions.patchStabMassnahmeFailure({ errorResponse: error.message })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private stabMassnahmeResourceService: StabMassnahmeResourceService) {}
}
