import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Store } from '@ngrx/store';
import { DialogAction, DialogMode, DialogService } from '@product/ise-dialog-lib';
import { einsatzfilterActions } from '../+state/einsatzfilter.actions';
import { einsatzfilterSelector, isLoading } from '../+state/einsatzfilter.selectors';
import { EinsatzfilterDTO } from '../../api/build/openapi';
import { FuehrungsebeneService } from '../../lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { LoadableComponentComponent } from '../../shared/components/loadable-component/loadable-component.component';
import {
  EinsatzfilterDialogComponent,
  EinsatzfilterDialogInputData,
  EinsatzfilterDialogOutputData,
} from '../einsatzfilter-dialog/einsatzfilter-dialog.component';

/**
 * Auflistung aller existierenden Einsatzfilter.
 *
 * Erlaubt das Hinzufügen, Bearbeiten und Löschen von Einsatzfiltern.
 *
 * Wenn multiSelect aktiviert wird, erscheinen Checkboxen zum Auswählen mehrerer EinsatzfilterDTOs.
 * Die ausgewählten FilterDTOs landen dann in checkedEinsatzfilterDTOs
 */
@Component({
  selector: 'app-einsatzfilter-list',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    LoadableComponentComponent,
    MatCheckboxModule,
  ],
  templateUrl: './einsatzfilter-list.component.html',
  styleUrls: ['./einsatzfilter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinsatzfilterListComponent {
  private dialog = inject(MatDialog);
  private store = inject(Store);
  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private dialogService = inject(DialogService);

  @Input() multiSelect = false;
  @Input() checkedEinsatzfilterDTOs: EinsatzfilterDTO[] = [];
  @Input() showDeleteButton = true;

  @Output() filterSelected = new EventEmitter<EinsatzfilterDTO>();
  @Output() filtersChecked = new EventEmitter<EinsatzfilterDTO[]>();

  isLoading$ = this.store.select(isLoading);
  availableEinsatzfilterDTOs: EinsatzfilterDTO[] = [];
  selectedEinsatzfilterDTO?: EinsatzfilterDTO;

  displayedColumns = ['name'];
  displayedColumnsMulti = ['select', 'name'];

  constructor() {
    this.store
      .select(einsatzfilterSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((einsatzfilterDTOs) => {
        this.availableEinsatzfilterDTOs = einsatzfilterDTOs;
      });
  }

  addClicked() {
    const inputData: EinsatzfilterDialogInputData = {
      dialogMode: DialogMode.CREATE,
      einsatzfilterDTO: {},
    };
    this.dialog
      .open(EinsatzfilterDialogComponent, { data: inputData })
      .afterClosed()
      .subscribe((outputData: EinsatzfilterDialogOutputData) => {
        if (outputData?.einsatzfilterDTO) {
          this.selectFilter(outputData.einsatzfilterDTO);
        }
      });
  }

  editClicked() {
    if (!this.selectedEinsatzfilterDTO) {
      return;
    }
    const inputData: EinsatzfilterDialogInputData = {
      dialogMode: DialogMode.EDIT,
      einsatzfilterDTO: this.selectedEinsatzfilterDTO,
    };
    this.dialog
      .open(EinsatzfilterDialogComponent, { data: inputData })
      .afterClosed()
      .subscribe((outputData: EinsatzfilterDialogOutputData) => {
        if (outputData?.einsatzfilterDTO) {
          this.selectFilter(outputData.einsatzfilterDTO);
        }
      });
  }

  editFilter(filterDTO: EinsatzfilterDTO) {
    this.selectFilter(filterDTO);
    this.editClicked();
  }

  deleteClicked() {
    if (!this.selectedEinsatzfilterDTO) {
      return;
    }

    const lageId = this.fuehrungsebeneService.getCurrentLage()?.id;
    if (!lageId) {
      console.warn('Keine Lage verfügbar, um zu speichern.');
      return;
    }

    this.dialogService
      .openConfirmDialog(
        'Einsatzfilter löschen?',
        'Mit dem Löschen des Filters, werden auch entsprechende ELS Einsätze entfernt.'
      )
      .afterClosed()
      .subscribe((result: DialogAction) => {
        if (result === DialogAction.OK && this.selectedEinsatzfilterDTO) {
          this.store.dispatch(
            einsatzfilterActions.deleteEinsatzfilter({
              lageId: lageId,
              einsatzfilterDTO: this.selectedEinsatzfilterDTO,
            })
          );
          this.filterSelected.emit(undefined);
        }
      });
  }

  refresh() {
    const lageId = this.fuehrungsebeneService.getCurrentLage()?.id;
    if (!lageId) {
      console.warn('Keine LageId gefunden');
      return;
    }
    this.store.dispatch(einsatzfilterActions.getEinsatzfilter({ lageId }));
  }

  selectFilter(filterDTO: EinsatzfilterDTO) {
    if (!filterDTO) {
      return;
    }
    this.selectedEinsatzfilterDTO = filterDTO;
    this.filterSelected.emit(this.selectedEinsatzfilterDTO);
  }

  isAllSelected() {
    return this.checkedEinsatzfilterDTOs.length === this.availableEinsatzfilterDTOs.length;
  }

  isSelected(filterDTO: EinsatzfilterDTO) {
    return this.checkedEinsatzfilterDTOs.find((f) => f.id === filterDTO.id);
  }

  toggle(filterDTO: EinsatzfilterDTO) {
    if (this.isSelected(filterDTO)) {
      this.checkedEinsatzfilterDTOs = this.checkedEinsatzfilterDTOs.filter((f) => f.id !== filterDTO.id);
    } else {
      this.checkedEinsatzfilterDTOs = [...this.checkedEinsatzfilterDTOs, filterDTO];
      this.selectFilter(filterDTO);
    }
    this.filtersChecked.emit(this.checkedEinsatzfilterDTOs);
  }

  toggleAll() {
    this.checkedEinsatzfilterDTOs = this.isAllSelected() ? [] : [...this.availableEinsatzfilterDTOs];
    this.filtersChecked.emit(this.checkedEinsatzfilterDTOs);
  }
}
