import { Verwaltungsebenentyp } from 'src/app/api/build/openapi';

export const verwaltungsebenenMap = new Map<Verwaltungsebenentyp, string>([
  [Verwaltungsebenentyp.Keine, 'Keine'],
  [Verwaltungsebenentyp.Eu, 'EU'],
  [Verwaltungsebenentyp.Bund, 'Bund'],
  [Verwaltungsebenentyp.Bundesland, 'Bundesland'],
  [Verwaltungsebenentyp.Stadtstaat, 'Stadtstaat'],
  [Verwaltungsebenentyp.Regierungsbezirk, 'Regierungsbezirk'],
  [Verwaltungsebenentyp.LandKreis, '(Land-)Kreis'],
  [Verwaltungsebenentyp.KreisfreieStadt, 'Kreisfreie Stadt'],
  [Verwaltungsebenentyp.Amt, 'Amt'],
  [Verwaltungsebenentyp.Gemeinde, 'Gemeinde'],
]);
