import { Injectable } from '@angular/core';
import { divIcon, LeafletMouseEvent, Map, marker } from 'leaflet';
import { LayerService } from '../layer.service';
import { ToolSettings } from '../tool.interface';
import { FeatureGenerator } from './tool-handler';

/**
 * Generator für einen blinkenden Punkt auf einer Leaflet-Karte über den ToolHandler
 */
@Injectable({
  providedIn: 'root',
})
export class PingGenerator implements FeatureGenerator {
  private toolPing: L.Marker | null = null;
  private map: Map | null = null;

  constructor(private layerService: LayerService) {}

  init(map: Map): void {
    this.map = map;
  }

  createFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): void {
    this.toolPing = marker(event.latlng, {
      icon: PingGenerator.getPingDivIcon(toolSettings.color),
    });
    this.map?.addLayer(this.toolPing);
  }

  updateFeature(event: LeafletMouseEvent): void {
    if (this.toolPing) {
      this.toolPing.setLatLng(event.latlng);
    }
  }

  extendFeature(event: LeafletMouseEvent): void {
    // Do nothing
  }

  finalizeFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (this.toolPing) {
      this.map?.removeLayer(this.toolPing);
      this.toolPing = null;
    }
    return this.layerService.addPingToCurrentLayer([event.latlng.lng, event.latlng.lat], toolSettings);
  }

  /**
   * Ping-SVG Definition. Muss textuell vorliegen, um die Farbe überschreiben zu können.
   */
  public static getPingDivIcon(color: string) {
    // Opacity und Weight erstmal nicht verwenden. Für Ping bräuchte man als Default opacity 1, weight 1px statt opacity 0.2 und weight 3px
    return divIcon({
      html: `
        <div style="background-color: ${color}; border-radius: 10px; width: 10px; height: 10px; opacity: 1">
          <div style="animation: blink 2s infinite ease-out; border-radius: 60px; box-shadow: inset 0 0 0 1px ${color}; content: ''; width: 10px; height: 10px; top: 45%; left: 45%; opacity: 1; position: absolute; transform: translate(-50%, -50%);"></div>
          <div style="animation: blink 4s infinite ease-out; border-radius: 60px; box-shadow: inset 0 0 0 1px ${color}; content: ''; width: 10px; height: 10px; top: 45%; left: 45%; opacity: 1; position: absolute; transform: translate(-50%, -50%);"></div>
        <div>
      `,
      className: 'hide-default-div',
    });
  }
}
