import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, PersonenschadenDTO } from 'src/app/api/build/openapi';

export const personenschadenActions = createActionGroup({
  source: 'Personenschaeden',
  events: {
    'Reset Store': emptyProps(),

    'Get Personenschaeden': props<{ lageId: string }>(),
    'Get Personenschaeden Success': props<{ personenschadenDTOs: PersonenschadenDTO[]; lageId: string }>(),
    'Get Personenschaeden Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Personenschaden': props<{ lageId: string; personenschadenDTO: PersonenschadenDTO }>(),
    'Create Personenschaden Success': props<{ createdPersonenschadenDTO: PersonenschadenDTO }>(),
    'Create Personenschaden Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Personenschaden': props<{ lageId: string; personenschadenDTO: PersonenschadenDTO }>(),
    'Patch Personenschaden Success': props<{ patchedPersonenschadenDTO: PersonenschadenDTO }>(),
    'Patch Personenschaden Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Personenschaden': props<{ lageId: string; personenschadenDTO: PersonenschadenDTO }>(),
    'Delete Personenschaden Success': props<{ deletedPersonenschadenDTO: PersonenschadenDTO }>(),
    'Delete Personenschaden Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
