import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { Observable, combineLatest, map, take, timer } from 'rxjs';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { FuehrungsebeneService } from '../fuehrungsebene/fuehrungsebene.service';
import { lageActions } from '../lagen/+state/lage.actions';
import { lagezeitSelector } from '../lagen/+state/lage.selectors';

@Component({
  selector: 'app-lagezeit',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    ReactiveFormsModule,
    OverlayModule,
    FormsModule,
    DateTimePipe,
    DatetimeLocalAccessorDirective,
  ],
  templateUrl: './lagezeit.component.html',
  styleUrls: ['./lagezeit.component.scss'],
})
export class LagezeitComponent {
  private store = inject(Store);
  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private errorService = inject(ErrorService);

  lagezeit$: Observable<string | undefined>;
  lagezeit?: string;
  lagezeitClass$: Observable<string>;
  fcLagezeit = new FormControl<Date | null>(null, Validators.required);

  editingLagezeit = false;
  openLegende: boolean = false;

  constructor() {
    this.lagezeit$ = this.store.select(lagezeitSelector);
    this.lagezeit$.pipe(takeUntilDestroyed()).subscribe((lagezeit) => (this.lagezeit = lagezeit));

    /*
     * Minütliche Prüfung, ob sich die Lagezeit geändert hat.
     * Liefert eine CSS Klasse zum Setzen der Hintergrundfarbe:
     * - Nach 1 Stunde gelber Hintergrund
     * - Nach 2 Stunden orangener Hintergrund
     * - Nach 3 Stunden roter Hintergrund
     */
    this.lagezeitClass$ = combineLatest([timer(0, 60000), this.lagezeit$]).pipe(
      map(([_, lagezeit]) => {
        if (!lagezeit) {
          return '';
        }

        const timeSinceLagezeit = Date.now() - new Date(lagezeit).getTime();
        if (timeSinceLagezeit >= 1000 * 60 * 60 * 3) {
          return 'lagezeit-red';
        } else if (timeSinceLagezeit >= 1000 * 60 * 60 * 2) {
          return 'lagezeit-orange';
        } else if (timeSinceLagezeit >= 1000 * 60 * 60) {
          return 'lagezeit-yellow';
        }
        return '';
      }),
      takeUntilDestroyed()
    );
  }

  protected editLagezeit() {
    this.editingLagezeit = true;
    this.lagezeit$.pipe(take(1)).subscribe((lagezeit) => {
      this.fcLagezeit.setValue(lagezeit ? new Date(lagezeit) : new Date());
    });
  }

  protected saveLagezeit() {
    if (!this.fcLagezeit.valid || !this.fcLagezeit.value || isNaN(this.fcLagezeit.value.getTime())) {
      return;
    }

    const currentLageId = this.fuehrungsebeneService.getCurrentLage()?.id;
    if (!currentLageId) {
      return;
    }

    this.store.dispatch(
      lageActions.patchLagezeit({
        lageId: currentLageId,
        lagezeit: this.fcLagezeit.value?.toISOString() || '',
      })
    );
    this.editingLagezeit = false;
  }

  protected cancelEditLagezeit() {
    this.editingLagezeit = false;
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
