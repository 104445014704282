<app-tz-popup-toolbar [tzDto]="dto" />

<div class="content">
  <app-fuehrungsebene-icon-and-text *ngIf="parentFuehrungsebene" [fuehrungsebeneDTO]="parentFuehrungsebene" />

  <div class="row">
    <div class="links"></div>

    <img class="zeichen" [src]="dto.dataUrl" />

    <div class="rechts"></div>
  </div>

  <div class="unten"></div>

  <!-- Kontakte -->
  <app-kontakt-icons
    *ngIf="dto.kommunikationOptionen && dto.kommunikationOptionen.length"
    [kontaktmoeglichkeiten]="dto.kommunikationOptionen"
  />
</div>
