import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, map, switchMap } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  BefehlsstelleDTO,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischeFormationNestedDTO,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { AuftragListComponent } from 'src/app/lagedarstellung/auftrag/auftrag-list/auftrag-list.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { bibliothekActions } from 'src/app/planung/bibliothek/+state/bibliothek.actions';
import { taktischeFormationActions } from '../+state/taktische-formation.actions';
import { TaktischeFormationContainerComponent } from '../taktische-formation-container/taktische-formation-container.component';
import {
  SelectDialogInputData,
  SelectDialogOutputData,
  TaktischeFormationSelectDialogComponent,
} from '../taktische-formation-select-dialog/taktische-formation-select-dialog.component';
import { TaktischeFormationTreeComponent } from '../taktische-formation-tree/taktische-formation-tree.component';
import { TaktischeFormationService } from '../taktische-formation.service';

export enum TaktischeFormationDialogOrigin {
  LAGE,
  BIBLIOTHEK,
}

export interface TaktischeFormationDialogInputData {
  taktischeFormationNestedDTO: TaktischeFormationNestedDTO;
  origin: TaktischeFormationDialogOrigin;
}

/**
 * Übersichts-Dialog zu einer Taktischen Formation (TF):
 * - Befehlsstelle, Einheitsführung und Führungseinheit
 * - Hierarchie-Bäume in denen sich die ausgewählte Formation befindet
 * - Alle direkt untergeordneten Einheiten: Personen, Fahrzeuge und Taktische Formationen
 */
@Component({
  selector: 'app-taktische-formation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    TaktischeFormationTreeComponent,
    TaktischeFormationContainerComponent,
    KontaktListComponent,
    AuftragListComponent,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatCardModule,
    MatTooltipModule,
  ],
  templateUrl: './taktische-formation-dialog.component.html',
  styleUrls: ['./taktische-formation-dialog.component.scss'],
})
export class TaktischeFormationDialogComponent {
  /**
   * Alle obersten Elemente der Anfangs-TF.
   * Eine TF kann zu mehreren Formationen gehören, daher können auch mehrere Root-Elemente entstehen.
   */
  rootTaktischeFormationen$ = new BehaviorSubject<TaktischeFormationNestedDTO[]>([]);

  /**
   * Die ausgewählte Taktische Formation des Baums. Das Root-Element bleibt hierbei bestehen.
   * Alle angezeigten Daten (Befehlsstelle, Einheiten, Führung, ...) beziehen sich immer auf die selectedTaktischeFormation.
   */
  selectedTaktischeFormationNested$: BehaviorSubject<TaktischeFormationNestedDTO>;

  startTaktischeFormation: TaktischeFormationNestedDTO;

  einheitsfuehrer$: Observable<PersonDTO | undefined>;
  fuehrungseinheit$: Observable<TaktischeFormationDTO | undefined>;
  befehlsstelle$: Observable<BefehlsstelleDTO | undefined>;

  private taktischeFormationService = inject(TaktischeFormationService);
  private fuehrungsebeneService = inject(FuehrungsebeneService);

  constructor(
    private store: Store<AppStateInterface>,
    private dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA) private data: TaktischeFormationDialogInputData
  ) {
    this.startTaktischeFormation = data.taktischeFormationNestedDTO;

    // Root-TFs für den Hierarchie-Baum laden
    this.reloadRoot();

    // Start-TF zu Beginn auswählen
    this.selectedTaktischeFormationNested$ = new BehaviorSubject<TaktischeFormationNestedDTO>(
      this.startTaktischeFormation
    );

    /**
     * Holt das passende PersonDTO 'Einheitsführer' der ausgewählten Taktischen Formation
     */
    this.einheitsfuehrer$ = this.selectedTaktischeFormationNested$.pipe(
      takeUntilDestroyed(),
      map((taktischeFormation) => taktischeFormation.einheitsfuehrer)
    );

    /**
     * Holt das passende TaktischeFormationDTO 'Führungseinheit' der ausgewählten Taktischen Formation
     */
    this.fuehrungseinheit$ = this.selectedTaktischeFormationNested$.pipe(
      takeUntilDestroyed(),
      map((taktischeFormation) => taktischeFormation.fuehrungseinheit)
    );

    /**
     * Holt das passende BefehlsstelleDTO 'Befehlsstelle' der ausgewählten Taktischen Formation
     */
    this.befehlsstelle$ = this.selectedTaktischeFormationNested$.pipe(
      takeUntilDestroyed(),
      map((taktischeFormation) => taktischeFormation.befehlsstelle)
    );
  }

  /**
   * Neuen Dialog mit allen Personen öffnen, um eine davon als Einheitsführer auszuwählen.
   * Nach positiver Bestätigung des Dialogs, wird der ausgewählte Einheitsführer in die aktuelle Taktische Formation gesetzt.
   */
  selectEinheitsfuehrer(): void {
    const selectedTaktischeFormationId = this.selectedTaktischeFormationNested$.value.id;
    if (!selectedTaktischeFormationId) {
      return;
    }

    const selectedTaktischeFormationEinheitsfuehrer = this.selectedTaktischeFormationNested$.value.einheitsfuehrer;
    const dialogData: SelectDialogInputData = {
      taktischeFormationId: selectedTaktischeFormationId,
      shownTzs: [TaktischesZeichenTyp.Person],
      multiSelect: false,
      headerText: 'Leiter auswählen',
      selectedPersonen: selectedTaktischeFormationEinheitsfuehrer ? [selectedTaktischeFormationEinheitsfuehrer] : [],
    };
    this.dialog
      .open(TaktischeFormationSelectDialogComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((dialogResult: SelectDialogOutputData) => {
        if (!dialogResult) {
          return;
        }

        const taktischeFormation = this.selectedTaktischeFormationNested$.value;
        if (!taktischeFormation.id) {
          return;
        }

        if (dialogResult.personIds.length > 1) {
          return;
        }

        const einheitsfuehrerId = dialogResult.personIds?.length ? dialogResult.personIds[0] : undefined;
        this.taktischeFormationService
          .setTaktischeFormationEinheitsfuehrer(taktischeFormation.id, einheitsfuehrerId)
          .pipe(
            switchMap((patchedTaktischeFormation) => {
              if (!patchedTaktischeFormation.id) {
                return EMPTY;
              }
              return this.taktischeFormationService.getTaktischeFormationNested(patchedTaktischeFormation.id);
            })
          )
          .subscribe((patchedTaktischeFormationNested) => {
            this.updatePatchedTaktischeFormation(patchedTaktischeFormationNested);
          });
      });
  }

  /**
   * Neuen Dialog mit allen Taktischen Formationen öffnen, um eine davon als Führungseinheit auszuwählen.
   * Nach positiver Bestätigung des Dialogs, wird die ausgewählte Führungseinheit in die aktuelle Taktische Formation gesetzt.
   */
  selectFuehrungseinheit(): void {
    const selectedTaktischeFormationId = this.selectedTaktischeFormationNested$.value.id;
    if (!selectedTaktischeFormationId) {
      return;
    }

    const selectedTaktischeFormationFuehrungseinheit = this.selectedTaktischeFormationNested$.value.fuehrungseinheit;
    const dialogData: SelectDialogInputData = {
      taktischeFormationId: selectedTaktischeFormationId,
      shownTzs: [TaktischesZeichenTyp.TaktischeFormation],
      multiSelect: false,
      headerText: 'Führungseinheit auswählen',
      selectedTaktischeFormationen: selectedTaktischeFormationFuehrungseinheit
        ? [selectedTaktischeFormationFuehrungseinheit]
        : [],
    };
    this.dialog
      .open(TaktischeFormationSelectDialogComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((dialogResult: SelectDialogOutputData) => {
        if (!dialogResult) {
          return;
        }

        const taktischeFormation = this.selectedTaktischeFormationNested$.value;
        if (!taktischeFormation.id) {
          return;
        }

        if (dialogResult.taktischeFormationIds.length > 1) {
          return;
        }

        const fuehrungseinheitId = dialogResult.taktischeFormationIds?.length
          ? dialogResult.taktischeFormationIds[0]
          : undefined;
        this.taktischeFormationService
          .setTaktischeFormationFuehrungseinheit(taktischeFormation.id, fuehrungseinheitId)
          .pipe(
            switchMap((patchedTaktischeFormation) => {
              if (!patchedTaktischeFormation.id) {
                return EMPTY;
              }
              return this.taktischeFormationService.getTaktischeFormationNested(patchedTaktischeFormation.id);
            })
          )
          .subscribe((patchedTaktischeFormationNested) => {
            this.updatePatchedTaktischeFormation(patchedTaktischeFormationNested);
          });
      });
  }

  /**
   * Neuen Dialog mit allen Befehlsstellen öffnen, um eine davon als Befehlsstellen auszuwählen.
   * Nach positiver Bestätigung des Dialogs, wird die ausgewählte Befehlsstelle in die aktuelle Taktische Formation gesetzt.
   */
  selectBefehlsstelle(): void {
    const selectedTaktischeFormationId = this.selectedTaktischeFormationNested$.value.id;
    if (!selectedTaktischeFormationId) {
      return;
    }

    const selectedTaktischeFormationBefehlsstelle = this.selectedTaktischeFormationNested$.value.befehlsstelle;
    const dialogData: SelectDialogInputData = {
      taktischeFormationId: selectedTaktischeFormationId,
      shownTzs: [TaktischesZeichenTyp.Befehlsstelle],
      multiSelect: false,
      headerText: 'Befehlsstelle auswählen',
      selectedBefehlsstellen: selectedTaktischeFormationBefehlsstelle ? [selectedTaktischeFormationBefehlsstelle] : [],
    };
    this.dialog
      .open(TaktischeFormationSelectDialogComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((dialogResult: SelectDialogOutputData) => {
        if (!dialogResult) {
          return;
        }

        const taktischeFormation = this.selectedTaktischeFormationNested$.value;
        if (!taktischeFormation.id) {
          return;
        }

        if (dialogResult.befehlsstelleIds.length > 1) {
          return;
        }

        const befehlsstelleId = dialogResult.befehlsstelleIds?.length ? dialogResult.befehlsstelleIds[0] : undefined;
        this.taktischeFormationService
          .setTaktischeFormationBefehlsstelle(taktischeFormation.id, befehlsstelleId)
          .pipe(
            switchMap((patchedTaktischeFormation) => {
              if (!patchedTaktischeFormation.id) {
                return EMPTY;
              }
              return this.taktischeFormationService.getTaktischeFormationNested(patchedTaktischeFormation.id);
            })
          )
          .subscribe((patchedTaktischeFormationNested) => {
            this.updatePatchedTaktischeFormation(patchedTaktischeFormationNested);
          });
      });
  }

  private updatePatchedTaktischeFormation(patchedTaktischeFormationNested: TaktischeFormationNestedDTO) {
    if (this.fuehrungsebeneService.getCurrentLage()) {
      this.store.dispatch(
        taktischeFormationActions.patchTaktischeFormationSuccess({
          patchedTaktischeFormationDTO: patchedTaktischeFormationNested,
        })
      );
    } else {
      this.store.dispatch(
        bibliothekActions.patchTaktischeFormationSuccess({ patchedTaktischeFormation: patchedTaktischeFormationNested })
      );
    }
    this.selectedTaktischeFormationNested$.next(patchedTaktischeFormationNested);
    this.reloadRoot();
  }

  reloadRoot(): void {
    if (!this.startTaktischeFormation.id) {
      return;
    }
    this.taktischeFormationService
      .getTaktischeFormationRoots(this.startTaktischeFormation.id)
      .subscribe((taktischeFormationNestedDTOs: TaktischeFormationNestedDTO[]) => {
        this.rootTaktischeFormationen$.next(taktischeFormationNestedDTOs);
        const updatedStartTf = this.findStart(taktischeFormationNestedDTOs);
        if (updatedStartTf) {
          this.selectedTaktischeFormationNested$.next(updatedStartTf);
        }
      });
  }

  /**
   * Die Start-Formation rekursiv aus übergebener Liste von Formationen finden und zurückliefern.
   */
  findStart(nested: TaktischeFormationNestedDTO[]): TaktischeFormationNestedDTO | undefined {
    if (!this.startTaktischeFormation.id) {
      return undefined;
    }

    for (const tf of nested) {
      if (this.startTaktischeFormation.id === tf.id) {
        return tf;
      }
      const childTf = this.findStart(tf.taktischeFormationen || []);
      if (childTf) {
        return childTf;
      }
    }
    return undefined;
  }
}
