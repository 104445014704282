import { Injectable } from '@angular/core';
import { LatLng, LeafletMouseEvent, Map, Polyline } from 'leaflet';
import { LayerService } from '../layer.service';
import { ToolSettings } from '../tool.interface';
import { FeatureGenerator } from './tool-handler';

/**
 * Generator für Linien auf einer Leaflet-Karte über den ToolHandler
 */
@Injectable({
  providedIn: 'root',
})
export class LineGenerator implements FeatureGenerator {
  private toolStartCoords: L.LatLng = new LatLng(0, 0);
  private toolLine: L.Polyline | null = null;
  private map: Map | null = null;

  constructor(private layerService: LayerService) {}

  init(map: Map): void {
    this.map = map;
  }

  createFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): void {
    this.toolStartCoords = event.latlng;
    if (!this.toolLine) {
      this.toolLine = new Polyline([this.toolStartCoords, this.toolStartCoords], toolSettings);
      this.map?.addLayer(this.toolLine);
    }
  }

  updateFeature(event: LeafletMouseEvent): void {
    if (this.toolLine) {
      const latLngs = this.toolLine.getLatLngs();
      latLngs[latLngs.length - 1] = event.latlng;
      this.toolLine.setLatLngs(latLngs);
    }
  }

  extendFeature(event: LeafletMouseEvent): void {
    this.toolLine?.addLatLng(event.latlng);
  }

  finalizeFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.toolLine) {
      return null;
    }

    const lineCoords: L.LatLng[] = <L.LatLng[]>this.toolLine.getLatLngs();
    lineCoords.pop();

    this.map?.removeLayer(this.toolLine);
    this.toolLine = null;

    return this.layerService.addLineToCurrentLayer(
      lineCoords.map((value) => [value.lng, value.lat]),
      toolSettings
    );
  }
}
