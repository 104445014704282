import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorService } from '@product/ise-error-lib';
import {
  BefehlsstelleDTO,
  FahrzeugDTO,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischeFormationSelectableInfo,
  TaktischeFormationSelectableInfoType,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { TaktischeZeichenItemComponent } from '../../taktische-zeichen-container/taktische-zeichen-item/taktische-zeichen-item.component';
import { TaktischeFormationService } from '../taktische-formation.service';

/**
 * Objekt, das der Konfiguration beim Öffnen des Dialogs dient.
 */
export interface SelectDialogInputData {
  taktischeFormationId: string;
  shownTzs: TaktischesZeichenTyp[];
  multiSelect: boolean;
  headerText?: string;
  // Personen, Fahrzeuge, Taktische Formationen, die schon beim Öffnen selektiert sein sollen
  selectedFahrzeuge?: FahrzeugDTO[];
  selectedPersonen?: PersonDTO[];
  selectedTaktischeFormationen?: TaktischeFormationDTO[];
  selectedBefehlsstellen?: BefehlsstelleDTO[];
}

/**
 * Objekt, das mit Positiver Bestätigung des Dialogs zurückgeliefert wird.
 * Ausgewählte TZs werden in ihre jeweiligen Listen einsortiert.
 */
export interface SelectDialogOutputData {
  fahrzeugIds: string[];
  personIds: string[];
  taktischeFormationIds: string[];
  befehlsstelleIds: string[];
}

/**
 * Dialog, der eine Liste von Taktischen Zeichen zur Auswahl anbietet.
 * - Über die SelectDialogData kann definiert werden, welche Taktischezeichentypen angeboten werden.
 * - Einzel- und Mehrfachauswahl ist über das multiSelect-Flag einstellbar.
 * - Ein Filter für nicht-zugewiesene TZs ist über das onlyUnused-Flag einstellbar.
 */
@Component({
  selector: 'app-taktische-formation-select-dialog',
  templateUrl: './taktische-formation-select-dialog.component.html',
  styleUrls: ['./taktische-formation-select-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    TaktischeZeichenItemComponent,
    MatIconModule,
    MatProgressBarModule,
  ],
})
export class TaktischeFormationSelectDialogComponent implements OnInit {
  headerText = 'Taktische Zeichen auswählen';

  // Taktische Formation für die Einheiten ausgewählt werden
  taktischeFormationId = '';
  // True, wenn mehrere Objekte gleichzeitig ausgewählt werden dürfen
  multiSelect = true;
  // TZ Typen, die zur Auswahl stehen sollen
  shownTzs: TaktischesZeichenTyp[] = [
    TaktischesZeichenTyp.Fahrzeug,
    TaktischesZeichenTyp.Person,
    TaktischesZeichenTyp.TaktischeFormation,
  ];

  selectedFahrzeuge: FahrzeugDTO[] = [];
  selectedPersonen: PersonDTO[] = [];
  selectedTaktischeFormationen: TaktischeFormationDTO[] = [];
  selectedBefehlsstellen: BefehlsstelleDTO[] = [];

  availableFahrzeuge: FahrzeugDTO[] = [];
  availablePersonen: PersonDTO[] = [];
  availableTaktischeFormationen: TaktischeFormationDTO[] = [];
  availableBefehlsstellen: BefehlsstelleDTO[] = [];

  isLoadingFahrzeuge = false;
  isLoadingPersonen = false;
  isLoadingBefehlsstellen = false;
  isLoadingTaktischeFormationen = false;

  private errorService = inject(ErrorService);
  private taktischeFormationService = inject(TaktischeFormationService);

  constructor(@Inject(MAT_DIALOG_DATA) private data: SelectDialogInputData) {}

  ngOnInit(): void {
    if (this.data) {
      this.taktischeFormationId = this.data.taktischeFormationId;
      this.shownTzs = this.data.shownTzs;
      this.multiSelect = this.data.multiSelect;
      this.headerText = this.data.headerText || this.headerText;
      this.selectedPersonen = this.data.selectedPersonen ? [...this.data.selectedPersonen] : [];
      this.selectedFahrzeuge = this.data.selectedFahrzeuge ? [...this.data.selectedFahrzeuge] : [];
      this.selectedTaktischeFormationen = this.data.selectedTaktischeFormationen
        ? [...this.data.selectedTaktischeFormationen]
        : [];
      this.selectedBefehlsstellen = this.data.selectedBefehlsstellen ? [...this.data.selectedBefehlsstellen] : [];
    }

    const selectableInfo: TaktischeFormationSelectableInfo = {
      type: TaktischeFormationSelectableInfoType.Existing,
      taktischeFormationId: this.taktischeFormationId,
    };

    /**
     * Alle auswählbaren Fahrzeuge holen.
     */
    if (this.shownTzs.includes(TaktischesZeichenTyp.Fahrzeug)) {
      this.isLoadingFahrzeuge = true;
      this.taktischeFormationService.getSelectableFahrzeuge(selectableInfo).subscribe((fahrzeugDTOs) => {
        this.availableFahrzeuge = this.combineNoDuplicates(this.selectedFahrzeuge, fahrzeugDTOs) as FahrzeugDTO[];
        this.isLoadingFahrzeuge = false;
      });
    }

    /**
     * Alle auswählbaren Personen holen.
     */
    if (this.shownTzs.includes(TaktischesZeichenTyp.Person)) {
      this.isLoadingPersonen = true;
      this.taktischeFormationService.getSelectablePersonen(selectableInfo).subscribe((personDTOs) => {
        this.availablePersonen = this.combineNoDuplicates(this.selectedPersonen, personDTOs) as PersonDTO[];
        this.isLoadingPersonen = false;
      });
    }

    /**
     * Alle auswählbaren Taktischen Formationen holen.
     */
    if (this.shownTzs.includes(TaktischesZeichenTyp.TaktischeFormation)) {
      this.isLoadingTaktischeFormationen = true;
      this.taktischeFormationService
        .getSelectableTaktischeFormationen(selectableInfo)
        .subscribe((taktischeFormationDTOs) => {
          this.availableTaktischeFormationen = this.combineNoDuplicates(
            this.selectedTaktischeFormationen,
            taktischeFormationDTOs
          ) as TaktischeFormationDTO[];
          this.isLoadingTaktischeFormationen = false;
        });
    }

    /**
     * Alle auswählbaren Befehlsstellen holen.
     */
    if (this.shownTzs.includes(TaktischesZeichenTyp.Befehlsstelle)) {
      this.isLoadingBefehlsstellen = true;
      this.taktischeFormationService.getSelectableBefehlsstellen(selectableInfo).subscribe((befehlsstelleDTOs) => {
        this.availableBefehlsstellen = this.combineNoDuplicates(
          this.selectedBefehlsstellen,
          befehlsstelleDTOs
        ) as BefehlsstelleDTO[];
        this.isLoadingBefehlsstellen = false;
      });
    }
  }

  private combineNoDuplicates(dtos1: TaktischesZeichenDTO[], dtos2: TaktischesZeichenDTO[]) {
    const result = [...dtos1];
    dtos2.forEach((dto2) => {
      if (!dtos1.find((dto) => dto.id === dto2.id)) {
        result.push({ ...dto2 });
      }
    });
    return result;
  }

  createDialogResult(): SelectDialogOutputData {
    return {
      fahrzeugIds: this.selectedFahrzeuge.map((fahrzeug) => fahrzeug.id as string),
      personIds: this.selectedPersonen.map((person) => person.id as string),
      taktischeFormationIds: this.selectedTaktischeFormationen.map((formation) => formation.id as string),
      befehlsstelleIds: this.selectedBefehlsstellen.map((befehlsstelle) => befehlsstelle.id as string),
    };
  }

  isSelected(tzDto: TaktischesZeichenDTO): boolean {
    if (tzDto.typ === TaktischesZeichenTyp.Befehlsstelle) {
      return !!this.selectedBefehlsstellen.find((selected) => selected.id === tzDto.id);
    }

    if (tzDto.typ === TaktischesZeichenTyp.Fahrzeug) {
      return !!this.selectedFahrzeuge.find((selected) => selected.id === tzDto.id);
    }

    if (tzDto.typ === TaktischesZeichenTyp.Person) {
      return !!this.selectedPersonen.find((selected) => selected.id === tzDto.id);
    }

    if (tzDto.typ === TaktischesZeichenTyp.TaktischeFormation) {
      return !!this.selectedTaktischeFormationen.find((selected) => selected.id === tzDto.id);
    }
    return false;
  }

  /*
   * Befehlsstellen
   */
  toggleBefehlsstelle(befehlsstelleDto: BefehlsstelleDTO) {
    if (!this.multiSelect) {
      this.selectedBefehlsstellen = [befehlsstelleDto];
      return;
    }

    if (this.isSelected(befehlsstelleDto)) {
      this.selectedBefehlsstellen = this.selectedBefehlsstellen.filter(
        (selected) => selected.id !== befehlsstelleDto.id
      );
    } else {
      this.selectedBefehlsstellen = [...this.selectedBefehlsstellen, befehlsstelleDto];
    }
  }

  removeBefehlsstelleFromSelectionAtIndex(befehlsstelleDto: BefehlsstelleDTO, index: number) {
    if (this.selectedBefehlsstellen[index].id === befehlsstelleDto.id) {
      this.selectedBefehlsstellen.splice(index, 1);
    }
  }

  /*
   * Fahrzeuge
   */

  clickAvailableFahrzeug(fahrzeugDto: FahrzeugDTO) {
    if (!this.multiSelect) {
      this.selectedFahrzeuge = [fahrzeugDto];
      return;
    }

    if (fahrzeugDto.lageId) {
      this.clickFahrzeugLage(fahrzeugDto);
    } else {
      this.clickFahrzeugBib(fahrzeugDto);
    }
  }

  /**
   * Fügt Fahrzeug zu Selection hinzu oder entfernt sie, falls bereits ausgewählt
   */
  private clickFahrzeugLage(fahrzeugDto: FahrzeugDTO) {
    if (this.isSelected(fahrzeugDto)) {
      this.selectedFahrzeuge = this.selectedFahrzeuge.filter((selected) => selected.id !== fahrzeugDto.id);
    } else {
      this.selectedFahrzeuge = [...this.selectedFahrzeuge, fahrzeugDto];
    }
  }

  /**
   * Fügt Fahrzeug zu Selection hinzu oder entfernt sie, falls bereits ausgewählt.
   * Unterschied zu Lage-Fahrzeugen: Bib-Vorlagen können mehrfach ausgewählt werden.
   */
  private clickFahrzeugBib(fahrzeugDto: FahrzeugDTO) {
    if (this.isSelected(fahrzeugDto) && !fahrzeugDto.vorlage) {
      this.selectedFahrzeuge = this.selectedFahrzeuge.filter((selected) => selected.id !== fahrzeugDto.id);
    } else {
      this.selectedFahrzeuge = [...this.selectedFahrzeuge, fahrzeugDto];
    }
  }

  /**
   * Selected Fahrzeug entfernen (Index benötigt, da Bib-Vorlagen mit identischer Id
   * mehrfach ausgewählt sein könnten und nur das angeklickte weg soll)
   */
  clickSelectedFahrzeug(fahrzeugDto: FahrzeugDTO, index: number) {
    if (this.selectedFahrzeuge[index].id === fahrzeugDto.id) {
      this.selectedFahrzeuge.splice(index, 1);
    }
  }

  /*
   * Personen
   */

  clickAvailablePerson(personDto: PersonDTO) {
    if (!this.multiSelect) {
      this.selectedPersonen = [personDto];
      return;
    }

    if (personDto.lageId) {
      this.clickPersonLage(personDto);
    } else {
      this.clickPersonBib(personDto);
    }
  }

  /**
   * Fügt Person zu Selection hinzu oder entfernt sie, falls bereits ausgewählt
   */
  private clickPersonLage(personDto: PersonDTO) {
    if (this.isSelected(personDto)) {
      this.selectedPersonen = this.selectedPersonen.filter((selected) => selected.id !== personDto.id);
    } else {
      this.selectedPersonen = [...this.selectedPersonen, personDto];
    }
  }

  /**
   * Fügt Person zu Selection hinzu oder entfernt sie, falls bereits ausgewählt.
   * Unterschied zu Lage-Personen: Bib-Vorlagen können mehrfach ausgewählt werden.
   */
  private clickPersonBib(personDto: PersonDTO) {
    if (this.isSelected(personDto) && !personDto.vorlage) {
      this.selectedPersonen = this.selectedPersonen.filter((selected) => selected.id !== personDto.id);
    } else {
      this.selectedPersonen = [...this.selectedPersonen, personDto];
    }
  }

  clickSelectedPerson(personDto: PersonDTO, index: number) {
    if (this.selectedPersonen[index].id === personDto.id) {
      this.selectedPersonen.splice(index, 1);
    }
  }

  /*
   * Taktische Formationen
   */

  clickAvailableFormation(formationDto: TaktischeFormationDTO) {
    if (!this.multiSelect) {
      this.selectedTaktischeFormationen = [formationDto];
      return;
    }

    if (formationDto.lageId) {
      this.clickFormationLage(formationDto);
    } else {
      this.clickFormationBib(formationDto);
    }
  }

  /**
   * Fügt TaktischeFormation zu Selection hinzu oder entfernt sie, falls bereits ausgewählt
   */
  private clickFormationLage(formationDto: TaktischeFormationDTO) {
    if (this.isSelected(formationDto)) {
      this.selectedTaktischeFormationen = this.selectedTaktischeFormationen.filter(
        (selected) => selected.id !== formationDto.id
      );
    } else {
      this.selectedTaktischeFormationen = [...this.selectedTaktischeFormationen, formationDto];
    }
  }

  /**
   * Fügt TaktischeFormation zu Selection hinzu oder entfernt sie, falls bereits ausgewählt.
   * Unterschied zu Lage-TaktischeFormationen: Bib-Vorlagen können mehrfach ausgewählt werden.
   */
  private clickFormationBib(formationDto: TaktischeFormationDTO) {
    if (this.isSelected(formationDto) && !formationDto.vorlage) {
      this.selectedTaktischeFormationen = this.selectedTaktischeFormationen.filter(
        (selected) => selected.id !== formationDto.id
      );
    } else {
      this.selectedTaktischeFormationen = [...this.selectedTaktischeFormationen, formationDto];
    }
  }

  clickSelectedFormation(taktischeFormationDto: TaktischeFormationDTO, index: number) {
    if (this.selectedTaktischeFormationen[index].id === taktischeFormationDto.id) {
      this.selectedTaktischeFormationen.splice(index, 1);
    }
  }
}
