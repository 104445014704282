import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { GebietDTO, Gebietsauspraegung, Gebietstyp, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { DataUrlChangeEvent } from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-gebiet-form',
  templateUrl: './gebiet-form.component.html',
  styleUrls: ['./gebiet-form.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    CleanableFormFieldComponent,
  ],
})
export class GebietFormComponent implements TaktischesZeichenForm {
  Gebietstyp = Gebietstyp;
  Gebietsauspraegung = Gebietsauspraegung;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  dataUrlChanged = new EventEmitter<DataUrlChangeEvent>();

  @Output()
  taktischesZeichenChanged = new EventEmitter<TaktischesZeichenDTO>();

  private taktischeZeichenService = inject(TaktischeZeichenService);

  currentZeichenDataUrl = '';
  customZeichen = false;

  dtoToEdit?: GebietDTO;

  formBuilder = inject(NonNullableFormBuilder);

  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcGebietstyp = this.formBuilder.control<Gebietstyp | undefined>(undefined);
  fcGebietsauspraegung = this.formBuilder.control<Gebietsauspraegung | undefined>(undefined);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    gebietstyp: this.fcGebietstyp,
    gebietsauspraegung: this.fcGebietsauspraegung,
    ortsangabe: this.fcOrtsangabe,
  });

  gebietstypMouseover = false;
  gebietsauspraegungMouseover = false;

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim() || '');
    });

    // Beim Ändern von Gebietstyp und -ausprägung, Neuzeichnen mit passender Polygon-Füllung anstoßen
    this.fcGebietstyp.valueChanges.pipe(takeUntilDestroyed()).subscribe((value?: Gebietstyp) => {
      if (!value) {
        this.generateTaktischesZeichen(this.customZeichen ? this.currentZeichenDataUrl : undefined);
        this.fcGebietsauspraegung.setValue(undefined);
        return;
      }
      this.generateTaktischesZeichen(
        this.customZeichen ? this.currentZeichenDataUrl : this.taktischeZeichenService.gebietDataUrlMapping.get(value)
      );
      this.taktischesZeichenChanged.emit(this.formGroupToDto());
    });

    this.fcGebietsauspraegung.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.taktischesZeichenChanged.emit(this.formGroupToDto());
    });
  }

  setCustomTz(dataUrl: string) {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
      this.customZeichen = true;
    } else {
      this.customZeichen = false;
      if (this.fcGebietstyp.value) {
        this.generateTaktischesZeichen(this.taktischeZeichenService.gebietDataUrlMapping.get(this.fcGebietstyp.value));
      } else {
        this.generateTaktischesZeichen();
      }
    }
  }

  setDTO(dto: GebietDTO) {
    this.dtoToEdit = dto;
    if (this.dtoToEdit) {
      this.dtoToFormGroup(this.dtoToEdit);
      if (this.dtoToEdit.customZeichen && this.dtoToEdit.dataUrl) {
        this.generateTaktischesZeichen(this.dtoToEdit.dataUrl);
        return;
      }
    }
  }

  /**
   * Aktualisiertes GebietDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): GebietDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): GebietDTO {
    return {
      ...this.dtoToEdit,
      anzeigename: this.fcAnzeigename.value.trim(),
      gebietstyp: this.fcGebietstyp.value,
      gebietsauspraegung: this.fcGebietsauspraegung.value,
      ortsangabe: this.fcOrtsangabe.value?.trim(),
      dataUrl: this.currentZeichenDataUrl,
      customZeichen: this.customZeichen,
      typ: TaktischesZeichenTyp.Gebiet,
    };
  }

  /**
   * FormGroup mit Werten aus DTO füllen
   */
  dtoToFormGroup(dto: GebietDTO): void {
    this.fcAnzeigename.setValue(dto.anzeigename);
    this.fcGebietstyp.setValue(dto.gebietstyp);
    this.fcGebietsauspraegung.setValue(dto.gebietsauspraegung);
    this.fcOrtsangabe.setValue(dto.ortsangabe);
    this.customZeichen = dto.customZeichen || false;
    this.generateTaktischesZeichen(dto.dataUrl);
  }

  /**
   * DataURL an Parent senden und dort aktualisieren.
   */
  generateTaktischesZeichen(dataUrl = '') {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
    } else {
      this.currentZeichenDataUrl =
        this.taktischeZeichenService.taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.Gebiet)?.dataUrl || '';
    }

    this.dataUrlChanged.emit({ dataUrl: this.currentZeichenDataUrl, customZeichen: this.customZeichen });
  }
}
