import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { AppService, ApplicationType } from 'src/app/app.service';

@Component({
  selector: 'app-program-select',
  standalone: true,
  imports: [MatCardModule],
  templateUrl: './program-select.component.html',
  styleUrl: './program-select.component.scss',
})
export class ProgramSelectComponent {
  ApplicationType = ApplicationType;

  private router: Router = inject(Router);
  private appService: AppService = inject(AppService);

  onClick(app: ApplicationType) {
    this.appService.selectApplication(app);
    this.router.navigate(['lagen']);
  }
}
