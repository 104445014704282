import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom, isDevMode } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_SCROLL_STRATEGY, MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DIALOG_SCROLL_STRATEGY, Dialog } from '@angular/cdk/dialog';
import { Overlay, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreModule, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { AnwesenheitenEffects } from './app/anwesenheitsliste/+state/anwesenheiten.effects';
import { anwesenheitenReducer } from './app/anwesenheitsliste/+state/anwesenheiten.reducer';
import { ApiModule } from './app/api/build/openapi';
import { AppComponent } from './app/app.component';
import { apiConfig } from './app/app.module';
import { routes } from './app/app.routes';
import { AuthInterceptor } from './app/auth/auth.interceptor';
import { EinsatzfilterEffects } from './app/einsatz/+state/einsatzfilter.effects';
import { einsatzfilterReducer } from './app/einsatz/+state/einsatzfilter.reducer';
import { EinsatztagebuchEffects } from './app/einsatztagebuch/+state/einsatztagebuch.effects';
import { einsatztagebuchReducer } from './app/einsatztagebuch/+state/einsatztagebuch.reducer';
import { FuehrungsebeneEffects } from './app/lagedarstellung/fuehrungsebene/+state/fuehrungsebene.effects';
import { fuehrungsebeneReducer } from './app/lagedarstellung/fuehrungsebene/+state/fuehrungsebene.reducer';
import { LageEffects } from './app/lagedarstellung/lagen/+state/lage.effects';
import { lageReducer } from './app/lagedarstellung/lagen/+state/lage.reducer';
import { LageSelectedGuard } from './app/lagedarstellung/lagen/lage-selected-guard';
import { LageMassnahmeEffects } from './app/lagedarstellung/massnahmen/+state/lage-massnahme.effects';
import { lageMassnahmeReducer } from './app/lagedarstellung/massnahmen/+state/lage-massnahme.reducer';
import { AnlassEffects } from './app/lagedarstellung/taktische-zeichen/anlass-ereignis/+state/anlass.effects';
import { anlassReducer } from './app/lagedarstellung/taktische-zeichen/anlass-ereignis/+state/anlass.reducer';
import { BefehlsstelleEffects } from './app/lagedarstellung/taktische-zeichen/befehlsstelle/+state/befehlsstelle.effects';
import { befehlsstelleReducer } from './app/lagedarstellung/taktische-zeichen/befehlsstelle/+state/befehlsstelle.reducer';
import { FahrzeugEffects } from './app/lagedarstellung/taktische-zeichen/fahrzeuge/+state/fahrzeug.effects';
import { fahrzeugReducer } from './app/lagedarstellung/taktische-zeichen/fahrzeuge/+state/fahrzeug.reducer';
import { FotoEffects } from './app/lagedarstellung/taktische-zeichen/foto/+state/foto.effects';
import { fotoReducer } from './app/lagedarstellung/taktische-zeichen/foto/+state/foto.reducer';
import { GebaeudeEffects } from './app/lagedarstellung/taktische-zeichen/gebaeude/+state/gebaeude.effects';
import { gebaeudeReducer } from './app/lagedarstellung/taktische-zeichen/gebaeude/+state/gebaeude.reducer';
import { GebietEffects } from './app/lagedarstellung/taktische-zeichen/gebiete/+state/gebiet.effects';
import { gebietReducer } from './app/lagedarstellung/taktische-zeichen/gebiete/+state/gebiet.reducer';
import { GefahrEffects } from './app/lagedarstellung/taktische-zeichen/gefahren/+state/gefahr.effects';
import { gefahrReducer } from './app/lagedarstellung/taktische-zeichen/gefahren/+state/gefahr.reducer';
import { FuehrungsebeneMassnahmeEffects } from './app/lagedarstellung/taktische-zeichen/massnahmen/+state/fuehrungsebene-massnahme.effects';
import { fuehrungsebeneMassnahmeReducer } from './app/lagedarstellung/taktische-zeichen/massnahmen/+state/fuehrungsebene-massnahme.reducer';
import { PersonEffects } from './app/lagedarstellung/taktische-zeichen/personen/+state/person.effects';
import { personReducer } from './app/lagedarstellung/taktische-zeichen/personen/+state/person.reducer';
import { PersonenschadenEffects } from './app/lagedarstellung/taktische-zeichen/personenschaden/+state/personenschaden.effects';
import { personenschadenReducer } from './app/lagedarstellung/taktische-zeichen/personenschaden/+state/personenschaden.reducer';
import { StelleEffects } from './app/lagedarstellung/taktische-zeichen/stelle-einrichtung/+state/stelle.effects';
import { stelleReducer } from './app/lagedarstellung/taktische-zeichen/stelle-einrichtung/+state/stelle.reducer';
import { TaktischeFormationEffects } from './app/lagedarstellung/taktische-zeichen/taktische-formation/+state/taktische-formation.effects';
import { taktischeFormationReducer } from './app/lagedarstellung/taktische-zeichen/taktische-formation/+state/taktische-formation.reducer';
import { TaktischesZeichenStatusPipe } from './app/lagedarstellung/taktische-zeichen/taktisches-zeichen-status.pipe';
import { TierschadenEffects } from './app/lagedarstellung/taktische-zeichen/tierschaden/+state/tierschaden.effects';
import { tierschadenReducer } from './app/lagedarstellung/taktische-zeichen/tierschaden/+state/tierschaden.reducer';
import { WetterEffects } from './app/lagedarstellung/wetter/+state/wetter.effects';
import { wetterReducers } from './app/lagedarstellung/wetter/+state/wetter.reducer';
import { StabMassnahmenEffects } from './app/lagek/stab-massnahmen/+state/stabmassnahmen.effects';
import { stabMassnahmenReducer } from './app/lagek/stab-massnahmen/+state/stabmassnahmen.reducer';
import { MeldungEffects } from './app/meldungen/+state/meldung.effects';
import { meldungReducer } from './app/meldungen/+state/meldung.reducer';
import { BibliothekEffects } from './app/planung/bibliothek/+state/bibliothek.effects';
import { bibliothekReducer } from './app/planung/bibliothek/+state/bibliothek.reducers';
import { MapLayerConfigEffects } from './app/planung/karte/+state/map-layer-config.effects';
import { mapLayerConfigReducer } from './app/planung/karte/+state/map-layer-config.reducer';
import { CustomDateAdapter } from './app/shared/custom-date.adapter';
import { DateTimePipe } from './app/shared/pipes/date-time.pipe';
import { DefaultDateTimePipe } from './app/shared/pipes/default-date-time.pipe';
import { GeometryPipe } from './app/shared/pipes/geometry.pipe';
import { NatoDateTimePipe } from './app/shared/pipes/nato-date-time.pipe';
import { NatoZuluDateTimePipe } from './app/shared/pipes/nato-long-date-time.pipe';
import { SvgSanitizer } from './app/shared/svg-sanitizer';
import { taktischeZeichenReducer } from './app/taktische-zeichen/+state/taktische-zeichen.reducer';

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    importProvidersFrom(
      BrowserModule,
      MatToolbarModule,
      MatIconModule,
      MatMenuModule,
      MatButtonModule,
      MatChipsModule,
      MatFormFieldModule,
      MatDatepickerModule,
      MatNativeDateModule,
      StoreModule.forRoot({}),
      EffectsModule.forRoot(),
      ApiModule.forRoot(apiConfig),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      })
    ),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: DefaultDateTimePipe },
    { provide: DateTimePipe },
    { provide: DatePipe },
    { provide: NatoDateTimePipe },
    { provide: NatoZuluDateTimePipe },
    { provide: GeometryPipe },
    { provide: TaktischesZeichenStatusPipe },
    { provide: SvgSanitizer },
    { provide: MatSnackBar },
    { provide: MatDialog },
    {
      provide: MAT_DIALOG_SCROLL_STRATEGY,
      useFactory: (scrollStrategyOptions: ScrollStrategyOptions) => scrollStrategyOptions.noop,
      deps: [ScrollStrategyOptions],
    },
    {
      provide: DIALOG_SCROLL_STRATEGY,
      useFactory: (scrollStrategyOptions: ScrollStrategyOptions) => scrollStrategyOptions.noop,
      deps: [ScrollStrategyOptions],
    },
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: { hideSingleSelectionIndicator: true, hideMultiSelectionIndicator: true },
    },
    { provide: Dialog },
    { provide: Clipboard },
    { provide: LageSelectedGuard },
    { provide: Overlay },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { color: 'accent', appearance: 'outline', floatLabel: 'always' },
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 500 } },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideStore({
      anlaesse: anlassReducer,
      befehlsstellen: befehlsstelleReducer,
      bibliothek: bibliothekReducer,
      einsatztagebuch: einsatztagebuchReducer,
      einsatzfilter: einsatzfilterReducer,
      fahrzeuge: fahrzeugReducer,
      fotos: fotoReducer,
      fuehrungsebenen: fuehrungsebeneReducer,
      'fuehrungsebene-massnahmen': fuehrungsebeneMassnahmeReducer,
      gebaeude: gebaeudeReducer,
      gebiete: gebietReducer,
      gefahren: gefahrReducer,
      lagen: lageReducer,
      'lage-massnahmen': lageMassnahmeReducer,
      'map-configs': mapLayerConfigReducer,
      meldungen: meldungReducer,
      personen: personReducer,
      personenschaeden: personenschadenReducer,
      stellen: stelleReducer,
      'taktische-formationen': taktischeFormationReducer,
      'taktische-zeichen': taktischeZeichenReducer,
      tierschaeden: tierschadenReducer,
      wetter: wetterReducers,
      'stab-massnahmen': stabMassnahmenReducer,
      anwesenheiten: anwesenheitenReducer,
    }),
    provideEffects([
      AnlassEffects,
      BefehlsstelleEffects,
      BibliothekEffects,
      EinsatztagebuchEffects,
      EinsatzfilterEffects,
      FahrzeugEffects,
      FotoEffects,
      FuehrungsebeneEffects,
      FuehrungsebeneMassnahmeEffects,
      GebaeudeEffects,
      GebietEffects,
      GefahrEffects,
      LageEffects,
      LageMassnahmeEffects,
      MapLayerConfigEffects,
      MeldungEffects,
      PersonEffects,
      PersonenschadenEffects,
      StelleEffects,
      TaktischeFormationEffects,
      TierschadenEffects,
      WetterEffects,
      StabMassnahmenEffects,
      AnwesenheitenEffects,
    ]),
    !environment.production
      ? provideStoreDevtools({
          maxAge: 25,
          logOnly: environment.production,
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
          connectInZone: true,
        })
      : [],
  ],
}).catch((err) => console.error(err));
