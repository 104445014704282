import { KeyValuePipe, NgForOf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorService } from '@product/ise-error-lib';
import { Rolle } from 'src/app/api/build/openapi';
import { LageValidators } from 'src/app/shared/lage-validators';
import { MassnahmenService } from '../../massnahmen.service';

export interface PersonenInfo {
  rolle: Rolle;
  name: string;
  datum: Date;
}

@Component({
  selector: 'app-massnahme-beenden-dialog',
  templateUrl: './massnahme-beenden-dialog.component.html',
  styleUrls: ['./massnahme-beenden-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    MatOptionModule,
    MatCardModule,
    KeyValuePipe,
    NgForOf,
  ],
})
export class MassnahmeBeendenDialogComponent {
  Rolle = Rolle;
  roleTextMapping = MassnahmenService.rolleMapping;
  errorService = inject(ErrorService);

  protected formGroup: FormGroup = inject(FormBuilder).group({
    rolle: [[], [Validators.required]],
    name: [[], [Validators.required]],
    datum: [[''], [Validators.required, LageValidators.dateTimeValidator]],
  });

  constructor(private matDialogRef: MatDialogRef<MassnahmeBeendenDialogComponent>) {}

  protected onClickBeenden() {
    if (this.formGroup.valid) {
      const person: PersonenInfo = this.formGroup.getRawValue();
      this.matDialogRef.close({ ...person, datum: new Date(this.formGroup.get('datum')?.value).toISOString() });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  protected onClickAbbrechen() {
    this.matDialogRef.close(undefined);
  }

  getErrorMessage(formControlName: string): string {
    const formControl = this.formGroup.get(formControlName);
    if (!formControl) {
      return '';
    }
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
