import { Pipe, PipeTransform } from '@angular/core';
import { Geometry } from 'geojson';

@Pipe({
  name: 'geometryAsString',
  standalone: true,
})
export class GeometryPipe implements PipeTransform {
  transform(geometry?: Geometry): string {
    if (!geometry) {
      return '';
    }

    switch (geometry.type) {
      case 'Point': {
        const point = geometry.coordinates;
        return point ? `${point[1]}, ${point[0]}` : '';
      }
      case 'LineString':
        return 'Linie (GeoJSON)';
      case 'Polygon':
        return 'Polygon (GeoJSON)';
      default:
        return 'Nicht implementiert';
    }
  }
}
