import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Observable, map, of, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  BibliothekResourceService,
  VerwaltungsebeneDTO,
  VerwaltungsebenenHierarchyDTO,
} from 'src/app/api/build/openapi';

import { Actions, ofType } from '@ngrx/effects';
import { bibliothekActions } from '../+state/bibliothek.actions';
import { VerwaltungsebenenTreeComponent } from '../verwaltungsebenen-tree/verwaltungsebenen-tree.component';

export interface DialogData {
  verwaltungsebeneToMoveId: string;
}

@Component({
  selector: 'app-verwaltungsebene-move-component',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatDialogModule,
    VerwaltungsebenenTreeComponent,
    MatTooltipModule,
    MatButtonModule,
  ],
  templateUrl: './verwaltungsebene-move.component.html',
  styleUrls: ['./verwaltungsebene-move.component.scss'],
})
export class VerwaltungsebeneMoveComponent {
  moveOptions$: Observable<VerwaltungsebeneDTO[]> = of([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
    private dialogRef: MatDialogRef<VerwaltungsebeneMoveComponent>,
    private store: Store<AppStateInterface>,
    private bibService: BibliothekResourceService,
    private actions$: Actions
  ) {
    this.moveOptions$ = bibService.moveOptionsVerwaltungsebene(dialogData.verwaltungsebeneToMoveId).pipe(
      takeUntilDestroyed(),
      map((hierarchy: VerwaltungsebenenHierarchyDTO) => hierarchy.hierarchy || [])
    );
  }

  move(newParentVerwaltungsebeneId: string) {
    this.actions$.pipe(ofType(bibliothekActions.moveVerwaltungsebeneSuccess), take(1)).subscribe((_) => {
      this.dialogRef.close();
    });

    this.store.dispatch(
      bibliothekActions.moveVerwaltungsebene({
        verwaltungsebeneMoveDTO: {
          verwaltugnsebeneToMoveId: this.dialogData.verwaltungsebeneToMoveId,
          newParentVerwaltungsebeneId,
        },
      })
    );
  }
}
