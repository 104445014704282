<div class="container" (mousedown)="startDrag($event)" [ngClass]="{ dragging: isDragging }">
  <div class="indicator">Zeistpanne: {{ currentZoomstufe.zeitspanneInH }}h</div>

  <div class="start-marker">
    <p>
      <strong>{{ zeitpunktStart | date : "dd.MM.yy HH:mm" }}</strong>
    </p>
  </div>
  <div class="center-marker">
    <p>
      <strong>{{ zeitpunktJetzt | date : "dd.MM.yy HH:mm" }}</strong>
    </p>
  </div>
  <div class="end-marker">
    <p>
      <strong>{{ zeitpunktEnde | date : "dd.MM.yy HH:mm" }}</strong>
    </p>
  </div>
  <div #elementscontainer class="element-container">
    <div
      *ngFor="let element of zeitstrahlelemente"
      class="zeitstrahl-element"
      [ngClass]="{ top: element.top, bottom: !element.top, icon: element.dataUrl, }"
      [style]="
        'left: ' +
        element.positionInProzent +
        '%;' +
        (element.massnahme!.prioritaet
          ? 'background-color: ' + prioMapping.get(element.massnahme!.prioritaet)?.farbe + ';'
          : '')
      "
      [matTooltip]="
        element.massnahme!.anzeigename + ' eledigen bis ' + (element.massnahme!.bisWannDatum | dateTime | async)
      "
      matTooltipPosition="above"
      (click)="onClickElement(element)"
      (mouseenter)="onMouseEnter(element)"
      (mouseleave)="onMouseEnter()"
    >
      <img *ngIf="element.dataUrl" [src]="element.dataUrl" />
      <mat-icon *ngIf="!element.dataUrl && element.massnahme!.prioritaet">{{
        prioMapping.get(element.massnahme!.prioritaet)?.icon
      }}</mat-icon>
      <div class="aufgabe-erledigt-checkmark" *ngIf="element.massnahme!.erledigt!">
        <mat-icon>done</mat-icon>
      </div>
      <div
        class="beenden-button"
        (click)="onClickMassnahmeBeenden($event, element)"
        *ngIf="!element.massnahme!.erledigt!"
      >
        <mat-icon>check</mat-icon>
      </div>
    </div>
    <div
      class="zeitstrahl-now-indicator"
      [style]="'left: ' + nowIndicator.positionInProzent + '%'"
      [ngClass]="{ top: nowIndicator.top, bottom: !nowIndicator.top }"
      (click)="onClickCenterZeitstrahl()"
    >
      <h2>Jetzt</h2>
    </div>
  </div>

  <div class="start-hidden-elements-indicator" *ngIf="hiddenLeft > 0">
    <h2>{{ hiddenLeft }}</h2>
  </div>
  <div class="end-hidden-elements-indicator" *ngIf="hiddenRight > 0">
    <h2>{{ hiddenRight }}</h2>
  </div>
</div>
