import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  BibFahrzeugDTO,
  BibPersonDTO,
  BibTaktischeFormationDTO,
  ErrorResponse,
  VerwaltungsebeneDTO,
  VerwaltungsebeneDeletedDTO,
  VerwaltungsebeneMoveDTO,
  VerwaltungsebenenHierarchyDTO,
} from 'src/app/api/build/openapi';

export const bibliothekActions = createActionGroup({
  source: 'Bibliothek',
  events: {
    'Reset Store': emptyProps(),

    // Alle Verwaltungsebene holen
    'Get Verwaltungsebenen': emptyProps(),
    'Get Verwaltungsebenen Success': props<{ verwaltungsebenen: VerwaltungsebeneDTO[] }>(),
    'Get Verwaltungsebenen Failure': props<{ errorResponse: ErrorResponse }>(),

    // Eine Verwaltungsebene anlegen
    'Create Verwaltungsebene': props<{ verwaltungsebene: VerwaltungsebeneDTO }>(),
    'Create Verwaltungsebene Success': props<{ newVerwaltungsebenenHierarchy: VerwaltungsebenenHierarchyDTO }>(),
    'Create Verwaltungsebene Failure': props<{ errorResponse: ErrorResponse }>(),

    // Eine Verwaltungsebene aktualisieren
    'Patch Verwaltungsebene': props<{ verwaltungsebene: VerwaltungsebeneDTO }>(),
    'Patch Verwaltungsebene Success': props<{ newVerwaltungsebenenHierarchy: VerwaltungsebenenHierarchyDTO }>(),
    'Patch Verwaltungsebene Failure': props<{ errorResponse: ErrorResponse }>(),

    // Eine Verwaltungsebene löschen
    'Delete Verwaltungsebene': props<{ verwaltungsebeneId: string }>(),
    'Delete Verwaltungsebene Success': props<{ deletedVerwaltungsebene: VerwaltungsebeneDeletedDTO }>(),
    'Delete Verwaltungsebene Failure': props<{ errorResponse: ErrorResponse }>(),

    // Eine Verwaltungsebene verschieben
    'Move Verwaltungsebene': props<{ verwaltungsebeneMoveDTO: VerwaltungsebeneMoveDTO }>(),
    'Move Verwaltungsebene Success': props<{ newVerwaltungsebenenHierarchy: VerwaltungsebenenHierarchyDTO }>(),
    'Move Verwaltungsebene Failure': props<{ errorResponse: ErrorResponse }>(),

    // #########################################
    // Fahrzeuge
    // #########################################

    'Create Fahrzeug': props<{ fahrzeug: BibFahrzeugDTO }>(),
    'Create Fahrzeug Success': props<{ newFahrzeug: BibFahrzeugDTO }>(),
    'Create Fahrzeug Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Fahrzeug': props<{ fahrzeugId: string; fahrzeugDTO: BibFahrzeugDTO }>(),
    'Patch Fahrzeug Success': props<{ patchedFahrzeug: BibFahrzeugDTO }>(),
    'Patch Fahrzeug Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Fahrzeug': props<{ fahrzeugId: string }>(),
    'Delete Fahrzeug Success': props<{ deleteFahrzeugId: string }>(),
    'Delete Fahrzeug Failure': props<{ errorResponse: ErrorResponse }>(),

    // Fahrzeuge mit Verwaltungsebene
    'Get Fahrzeuge With Verwaltungsebene': emptyProps(),
    'Get Fahrzeuge With Verwaltungsebene Success': props<{ fahrzeuge: BibFahrzeugDTO[] }>(),
    'Get Fahrzeuge With Verwaltungsebene Failure': props<{ errorResponse: ErrorResponse }>(),

    // #########################################
    // Personen
    // #########################################

    'Create Person': props<{ person: BibPersonDTO }>(),
    'Create Person Success': props<{ newPerson: BibPersonDTO }>(),
    'Create Person Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Person': props<{ personId: string; personDTO: BibPersonDTO }>(),
    'Patch Person Success': props<{ patchedPerson: BibPersonDTO }>(),
    'Patch Person Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Person': props<{ personId: string }>(),
    'Delete Person Success': props<{ deletePersonId: string }>(),
    'Delete Person Failure': props<{ errorResponse: ErrorResponse }>(),

    // Personen mit Verwaltungsebene
    'Get Personen With Verwaltungsebene': emptyProps(),
    'Get Personen With Verwaltungsebene Success': props<{ personen: BibPersonDTO[] }>(),
    'Get Personen With Verwaltungsebene Failure': props<{ errorResponse: ErrorResponse }>(),

    // #########################################
    // TaktischeFormationen
    // #########################################

    'Create TaktischeFormation': props<{ taktischeFormation: BibTaktischeFormationDTO }>(),
    'Create TaktischeFormation Success': props<{ newTaktischeFormation: BibTaktischeFormationDTO }>(),
    'Create TaktischeFormation Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch TaktischeFormation': props<{
      taktischeFormationId: string;
      taktischeFormationDTO: BibTaktischeFormationDTO;
    }>(),
    'Patch TaktischeFormation Success': props<{ patchedTaktischeFormation: BibTaktischeFormationDTO }>(),
    'Patch TaktischeFormation Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete TaktischeFormation': props<{ taktischeFormationId: string }>(),
    'Delete TaktischeFormation Success': props<{ deleteTaktischeFormationId: string }>(),
    'Delete TaktischeFormation Failure': props<{ errorResponse: ErrorResponse }>(),

    // TaktischeFormationen mit Verwaltungsebene
    'Get TaktischeFormationen With Verwaltungsebene': emptyProps(),
    'Get TaktischeFormationen With Verwaltungsebene Success': props<{
      taktischeFormationen: BibTaktischeFormationDTO[];
    }>(),
    'Get TaktischeFormationen With Verwaltungsebene Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
