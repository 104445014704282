import { createReducer, on } from '@ngrx/store';
import { Meldungstyp } from 'src/app/api/build/openapi';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { MeldungStateInterface } from './meldung-state.interface';
import { meldungActions } from './meldung.actions';

/*
 * Default-Objekt mit dem der Store initial befüllt wird.
 */
export const initialState: MeldungStateInterface = {
  isCreating: false,
  isLoading: false,
  isPatching: false,
  isDeleting: false,
  meldungen: [],
  currentMeldung: null,
  schlussmeldungExists: false,
};

/*
 * Reducer überführen den aktuellen "State" des Stores in einen neuen (angepassten) "State" - Transition.
 *
 *  "Reducers are pure functions in that they produce the same output for a given input.
 *   They are without side effects and handle each state transition synchronously.
 *   Each reducer function takes the latest Action dispatched, the current state, and determines
 *   whether to return a newly modified state or the original state."
 *
 * aus https://ngrx.io/guide/store/reducers
 */
export const meldungReducer = createReducer(
  initialState,

  // Get Meldungen
  on(meldungActions.getMeldungen, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(meldungActions.getMeldungenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    meldungen: action.meldungen,
    errorResponse: undefined,
    schlussmeldungExists:
      action.meldungen.filter(
        (meldung) =>
          meldung.meldungstyp === Meldungstyp.Schlussmeldung ||
          meldung.meldungstyp === Meldungstyp.SofortUndSchlussmeldung
      ).length > 0,
  })),
  on(meldungActions.getMeldungenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Current Meldung
  on(meldungActions.setCurrentMeldung, (state, action) => ({
    ...state,
    currentMeldung: action.currentMeldung,
    errorResponse: undefined,
  })),

  // Create Meldung
  on(meldungActions.createMeldung, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(meldungActions.createMeldungSuccess, (state, action) => ({
    ...state,
    meldungen: [...state.meldungen, action.newMeldung],
    schlussmeldungExists:
      state.schlussmeldungExists ||
      action.newMeldung.meldungstyp === Meldungstyp.Schlussmeldung ||
      action.newMeldung.meldungstyp === Meldungstyp.SofortUndSchlussmeldung,
    currentMeldung: action.newMeldung,
    isCreating: false,
    errorResponse: undefined,
  })),
  on(meldungActions.createMeldungFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Meldung
  on(meldungActions.patchMeldung, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(meldungActions.patchMeldungSuccess, (state, action) => ({
    ...state,
    meldungen: replaceOrKeepCurrent(state.meldungen, action.patchedMeldung),
    currentMeldung: action.patchedMeldung,
    errorResponse: undefined,
    schlussmeldungExists:
      state.schlussmeldungExists ||
      action.patchedMeldung.meldungstyp === Meldungstyp.Schlussmeldung ||
      action.patchedMeldung.meldungstyp === Meldungstyp.SofortUndSchlussmeldung,
    isPatching: false,
  })),
  on(meldungActions.patchMeldungFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Meldung
  on(meldungActions.deleteMeldung, (state) => ({ ...state, isDeleting: true, errorResponse: undefined })),
  on(meldungActions.deleteMeldungSuccess, (state, action) => ({
    ...state,
    meldungen: state.meldungen.filter((meldung) => meldung.id != action.deletedMeldungId),
    schlussmeldungExists:
      state.meldungen.filter(
        (meldung) =>
          (meldung.id != action.deletedMeldungId && meldung.meldungstyp === Meldungstyp.Schlussmeldung) ||
          meldung.meldungstyp === Meldungstyp.SofortUndSchlussmeldung
      ).length > 0,
    currentMeldung: null,
    isDeleting: false,
    errorResponse: undefined,
  })),
  on(meldungActions.deleteMeldungFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  }))
);
