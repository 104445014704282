<div class="form-container">
  <form [formGroup]="formGroup" class="actual-form">
    <!-- Linke Spalte -->
    <mat-card>
      <mat-card-subtitle>Zeichen Informationen</mat-card-subtitle>
      <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />

      <mat-form-field class="less-margin" (mouseover)="symbolMouseover = true" (mouseleave)="symbolMouseover = false">
        <mat-label>Symbol</mat-label>
        <mat-select formControlName="symbol">
          <mat-option *ngFor="let symbol of symbolValues" [value]="symbol">
            {{ symbol.label }}
          </mat-option>
        </mat-select>
        <button *ngIf="fcSymbol.value && symbolMouseover" matSuffix mat-icon-button (click)="fcSymbol.reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Text</mat-label>
        <input matInput [formControl]="fcZeichenText" />
        <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['maxlength']">{{
          getErrorMessage(fcZeichenText)
        }}</mat-error>
        <mat-error *ngIf="fcZeichenText.errors !== null && fcZeichenText.errors['pattern']"
          >Keine Umlaute oder Sonderzeichen</mat-error
        >
      </mat-form-field>

      <mat-card-subtitle>Informationen zur Maßnahme</mat-card-subtitle>

      <mat-form-field class="less-margin">
        <mat-label>Beschreibung</mat-label>
        <textarea matInput formControlName="beschreibung" required="true" rows="3"></textarea>
        <mat-error *ngIf="fcBeschreibung.errors">{{ getErrorMessage(fcBeschreibung) }}</mat-error>
      </mat-form-field>

      <mat-form-field class="less-margin">
        <mat-label>Priorität</mat-label>
        <mat-select formControlName="prioritaet">
          <mat-select-trigger>
            <mat-icon>{{ prios.get(fcPrioritaet.value)!.icon }}</mat-icon>
            {{ prios.get(fcPrioritaet.value)!.name }}
          </mat-select-trigger>

          <mat-option *ngFor="let entry of prios | keyvalue : prioSort" [value]="entry.key">
            <mat-icon>{{ entry.value.icon }}</mat-icon> {{ entry.value.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-checkbox formControlName="erledigt">Erledigt</mat-checkbox>
    </mat-card>

    <!-- Rechte Spalte -->
    <mat-card>
      <mat-card-subtitle>Erledigen durch</mat-card-subtitle>

      <mat-form-field class="less-margin">
        <mat-label>Sachgebiet</mat-label>
        <mat-select formControlName="zuErledigenSachgebiet">
          <mat-option [value]="Sachgebiet.S1">S1</mat-option>
          <mat-option [value]="Sachgebiet.S2">S2</mat-option>
          <mat-option [value]="Sachgebiet.S3">S3</mat-option>
          <mat-option [value]="Sachgebiet.S4">S4</mat-option>
          <mat-option [value]="Sachgebiet.S5">S5</mat-option>
          <mat-option [value]="Sachgebiet.S6">S6</mat-option>
          <mat-option [value]="Sachgebiet.Fernmeldedienst">Fernmeldedienst</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="zuErledigenDatum" dateTimeLocalAsDate />
      </mat-form-field>

      <mat-card-subtitle>Erledigen bis wann</mat-card-subtitle>

      <mat-form-field class="less-margin">
        <mat-label>Rolle</mat-label>
        <mat-select formControlName="bisWannRolle">
          <mat-option [value]="role.value" *ngFor="let role of Rolle | keyvalue">{{
            roleTextMapping.get(role.value)
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <app-tz-combobox
        label="Zu erledigen von"
        formControlName="bisWannPerson"
        [formGroup]="formGroup"
        [data$]="personenValues$"
        [displayedFields]="['anzeigename']"
      />

      <mat-form-field>
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="bisWannDatum" dateTimeLocalAsDate />
      </mat-form-field>

      <mat-card-subtitle>Erledigt durch</mat-card-subtitle>

      <mat-form-field class="less-margin">
        <mat-label>Rolle</mat-label>
        <mat-select formControlName="erledigtDurchRolle">
          <mat-option [value]="role.value" *ngFor="let role of Rolle | keyvalue">{{
            roleTextMapping.get(role.value)
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <app-tz-combobox
        label="Erledigt von"
        formControlName="erledigtDurchPerson"
        [formGroup]="formGroup"
        [data$]="personenValues$"
        [displayedFields]="['anzeigename']"
      />

      <mat-form-field>
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="erledigtDurchDatum" dateTimeLocalAsDate />
      </mat-form-field>
    </mat-card>
  </form>

  <app-tagebuch class="tagebuch" [tagebucheintraege]="massnahmeToEdit?.tagebuch || []" />
</div>
