import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ErrorService } from '@product/ise-error-lib';
import { AnlassDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TagebuchComponent } from 'src/app/lagedarstellung/tagebuch/tagebuch/tagebuch.component';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { Symbol, TaktischesZeichen, erzeugeTaktischesZeichen, symbole } from 'taktische-zeichen-core';
import { DataUrlChangeEvent } from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-anlass-ereignis-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    TagebuchComponent,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    CleanableFormFieldComponent,
  ],
  templateUrl: './anlass-ereignis-form.component.html',
  styleUrls: ['./anlass-ereignis-form.component.scss'],
})
export class AnlassEreignisFormComponent implements TaktischesZeichenForm {
  @ViewChild(TagebuchComponent)
  tagebuch!: TagebuchComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  dataUrlChanged = new EventEmitter<DataUrlChangeEvent>();

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);
  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [
    Validators.maxLength(255),
    Validators.pattern('[a-zA-Z0-9-/]*'),
  ]);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    symbol: this.fcSymbol,
    zeichenText: this.fcZeichenText,
  });

  anlassToEdit?: AnlassDTO;

  readonly EMPTY_ZEICHEN: TaktischesZeichen = { grundzeichen: 'anlass' };
  editorZeichen: TaktischesZeichen = { ...this.EMPTY_ZEICHEN };
  currentZeichenDataUrl: string = erzeugeTaktischesZeichen(this.editorZeichen).dataUrl;
  customZeichen = false;

  symbolValues: Symbol[] = symbole.sort((a: Symbol, b: Symbol) => a.label.localeCompare(b.label));
  symbolMouseover = false;

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.symbol = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen && this.fcZeichenText.valid) {
        this.editorZeichen.text = v?.trim();
        this.generateTaktischesZeichen();
      }
    });
  }

  setDTO(anlassDTO: AnlassDTO) {
    this.anlassToEdit = anlassDTO;
    if (this.anlassToEdit) {
      this.dtoToFormGroup(this.anlassToEdit);
      if (this.anlassToEdit.customZeichen && this.anlassToEdit.dataUrl) {
        this.generateTaktischesZeichen(this.anlassToEdit.dataUrl);
        return;
      }
    }
    this.generateTaktischesZeichen();
  }

  /**
   * Aktualisiertes AnlassDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): AnlassDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  setCustomTz(dataUrl: string) {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
      this.customZeichen = true;
    } else {
      this.customZeichen = false;
      this.generateTaktischesZeichen();
    }
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): AnlassDTO {
    return {
      ...this.anlassToEdit,
      anzeigename: this.fcAnzeigename.value.trim(),
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      tagebuch: this.tagebuch.getTagebucheintraege(),
      dataUrl: this.currentZeichenDataUrl,
      customZeichen: this.customZeichen,
      typ: TaktischesZeichenTyp.Anlass,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(anlassDTO: AnlassDTO): void {
    this.fcAnzeigename.setValue(anlassDTO.anzeigename);
    this.fcSymbol.setValue(symbole.find((v) => v.id === anlassDTO.symbol));
    this.fcZeichenText.setValue(anlassDTO.zeichenText);
    this.customZeichen = anlassDTO.customZeichen || false;
  }

  /**
   * DataURL an Parent senden und dort aktualisieren.
   */
  generateTaktischesZeichen(dataUrl = '') {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
    } else {
      const zeichen = erzeugeTaktischesZeichen(this.editorZeichen);
      this.currentZeichenDataUrl = zeichen.dataUrl;
    }
    this.dataUrlChanged.emit({ dataUrl: this.currentZeichenDataUrl, customZeichen: this.customZeichen });
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
