import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, LageDTO } from 'src/app/api/build/openapi';

export const lageActions = createActionGroup({
  source: 'Lagen',
  events: {
    'Get Lagen': emptyProps(),
    'Get Lagen Success': props<{ lagen: LageDTO[] }>(),
    'Get Lagen Failure': props<{ errorResponse: ErrorResponse }>(),

    'Set Current Lage': props<{ currentLage: LageDTO | null }>(),

    /**
     * Lage wurde von anderem Client hinzugefügt
     */
    'Add Lage': props<{ newLage: LageDTO }>(),

    /**
     * Lage wurde von diesem Client hinzugefügt
     */
    'Create Lage': props<{ newLage: LageDTO }>(),
    'Create Lage Success': props<{ newLage: LageDTO }>(),
    'Create Lage Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Lage': props<{ patchedLage: LageDTO }>(),
    'Patch Lage Success': props<{ patchedLage: LageDTO }>(),
    'Patch Lage Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Lagezeit': props<{ lageId: string; lagezeit: string }>(),
    'Patch Lagezeit Success': props<{ patchedLage: LageDTO }>(),
    'Patch Lagezeit Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
