import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';

export interface ComponentQualification {
  component: string;
  qualification: 'development';
}

@Injectable({
  providedIn: 'root',
})
export class ComponentQualificationService {
  private showDevelopment$ = new BehaviorSubject<boolean>(true);
  public isShowDevelopment$ = from(this.showDevelopment$);
  private showDevelopment = true;

  private stati: ComponentQualification[] = [];
  private subscriptions: Map<string, BehaviorSubject<boolean>> = new Map();
  constructor() {
    this.initFromLocalStore();
  }

  toggleShowDevelopment(): boolean {
    this.showDevelopment = !this.showDevelopment;
    this.showDevelopment$.next(this.showDevelopment);
    this.handleToggle();
    return this.showDevelopment;
  }

  subcribeComponent(component: string): BehaviorSubject<boolean> {
    const obs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    this.subscriptions.set(component, obs);
    obs.next(this.qualifies(component));
    return obs;
  }

  private qualifies(component: string): boolean {
    const qual: ComponentQualification | null = this.stati.filter((qual) => qual.component === component)[0];
    if (qual) {
      switch (qual.qualification) {
        case 'development':
          return this.showDevelopment;
        default:
          return false;
      }
    }
    return false;
  }

  private initFromLocalStore() {
    if (!localStorage.getItem('showDevelopmentComponents')) {
      this.showDevelopment = false;
      this.showDevelopment$.next(this.showDevelopment);
      this.handleToggle();
      return;
    }

    const dev: string | null = localStorage.getItem('showDevelopmentComponents');
    this.showDevelopment = dev !== null && dev === 'true';
    this.showDevelopment$.next(this.showDevelopment);
  }

  private handleToggle() {
    localStorage.setItem('showDevelopmentComponents', this.showDevelopment.toString());

    this.subscriptions.forEach((val, key) => val.next(this.qualifies(key)));
  }
}
