import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TaktischeZeichenFormComponent } from '../taktische-zeichen-form/taktische-zeichen-form.component';

/**
 * Dialog, der die Zusammenstellung eines Taktischen Zeichens nach den Regeln von https://taktische-zeichen.dev/ erlaubt.
 * Liefert mit dem Schließen des Dialogs die DataUrl des generierten Zeichens zurück.
 */
@Component({
  selector: 'app-taktische-zeichen-editor-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatToolbarModule, TaktischeZeichenFormComponent],
  templateUrl: './taktische-zeichen-editor-dialog.component.html',
  styleUrls: ['./taktische-zeichen-editor-dialog.component.scss'],
})
export class TaktischeZeichenEditorDialogComponent {
  @ViewChild(TaktischeZeichenFormComponent)
  taktischeZeichenForm!: TaktischeZeichenFormComponent;

  constructor(private dialogRef: MatDialogRef<TaktischeZeichenEditorDialogComponent>) {}

  submit() {
    this.dialogRef.close({ dataUrl: this.taktischeZeichenForm.zeichenDataUrl });
  }
}
