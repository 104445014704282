<!-- 
  Header mit Zusammenfassung, wie viele Taktische Formationen, Personen und Fahrzeuge 
  die ausgewählte Taktische Formation insgesamt besitzt 
-->
<mat-toolbar *ngIf="selectedTaktischeFormationNested$ | async as selectedTaktischeFormationNested">
  <div class="header-col">
    <h3>Mannschaftsstärke</h3>
    <div class="header-row">
      <div class="header-item" matTooltip="Taktische Formationen">
        <span>{{ selectedTaktischeFormationNested.summeTaktischeFormationen || 0 }}</span>
        <img width="24px" [src]="taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.TaktischeFormation)?.dataUrl" />
      </div>
      <div class="header-item" matTooltip="Fahrzeuge">
        <span>{{ selectedTaktischeFormationNested.summeFahrzeuge || 0 }}</span>
        <img width="24px" [src]="taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.Fahrzeug)?.dataUrl" />
      </div>
    </div>
    <div class="header-row mannschaftsstaerke" matTooltip="Mannschaftsstärke">
      {{ selectedTaktischeFormationNested.mannschaftsstaerke1 }} /
      {{ selectedTaktischeFormationNested.mannschaftsstaerke2 }} /
      {{ selectedTaktischeFormationNested.mannschaftsstaerke3 }} =
      {{
        (selectedTaktischeFormationNested.mannschaftsstaerke1 || 0) +
          (selectedTaktischeFormationNested.mannschaftsstaerke2 || 0) +
          (selectedTaktischeFormationNested.mannschaftsstaerke3 || 0)
      }}
    </div>
  </div>

  <div class="header-col">
    <h3>Summe Einheitenstärke</h3>
    <div class="header-row">
      <div class="header-item" matTooltip="Taktische Formationen">
        <span>{{ selectedTaktischeFormationNested.summeTaktischeFormationenBerechnet || 0 }}</span>
        <img width="24px" [src]="taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.TaktischeFormation)?.dataUrl" />
      </div>
      <div class="header-item" matTooltip="Fahrzeuge">
        <span>{{ selectedTaktischeFormationNested.summeFahrzeugeBerechnet || 0 }}</span>
        <img width="24px" [src]="taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.Fahrzeug)?.dataUrl" />
      </div>
    </div>
    <div class="header-row mannschaftsstaerke" matTooltip="Mannschaftsstärke">
      {{ selectedTaktischeFormationNested.mannschaftsstaerke1Berechnet }} /
      {{ selectedTaktischeFormationNested.mannschaftsstaerke2Berechnet }} /
      {{ selectedTaktischeFormationNested.mannschaftsstaerke3Berechnet }} =
      {{
        (selectedTaktischeFormationNested.mannschaftsstaerke1Berechnet || 0) +
          (selectedTaktischeFormationNested.mannschaftsstaerke2Berechnet || 0) +
          (selectedTaktischeFormationNested.mannschaftsstaerke3Berechnet || 0)
      }}
    </div>
  </div>
</mat-toolbar>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
  <!-- Blatt-Element (Taktische Formation) im Baum -->
  <mat-tree-node *matTreeNodeDef="let taktischeFormation" matTreeNodeToggle>
    <div
      class="taktische-formation-node"
      (click)="selectTaktischeFormation(taktischeFormation)"
      [ngClass]="{
        'selected-row': taktischeFormation.id === selectedTaktischeFormationNested$.value.id,
        vorlage: taktischeFormation.vorlage,
      }"
    >
      <div class="zeichen">
        <img [src]="taktischeFormation.dataUrl" width="32px" />
        <mat-icon
          color="warn"
          class="unavailable-badge"
          *ngIf="taktischeFormation.unavailable"
          matTooltip="Nicht verfügbar"
          >cancel</mat-icon
        >
      </div>
      <span>{{ taktischeFormation.anzeigename }}</span>
    </div>
  </mat-tree-node>

  <!-- Knoten-Element im Baum -->
  <mat-nested-tree-node *matTreeNodeDef="let taktischeFormation; when: hasChild">
    <div class="mat-tree-node">
      <!-- Button zum Ein-/Ausklappen -->
      <mat-icon class="toggle-icon" matTreeNodeToggle>
        {{ treeControl.isExpanded(taktischeFormation) ? "expand_more" : "chevron_right" }}
      </mat-icon>

      <!-- Knoten-Inhalt (Taktische Formation) -->
      <div
        class="taktische-formation-node"
        (click)="selectTaktischeFormation(taktischeFormation)"
        [ngClass]="{
          'selected-row': taktischeFormation.id === selectedTaktischeFormationNested$.value.id,
          vorlage: taktischeFormation.vorlage,
          unavailable: taktischeFormation.unavailable
        }"
      >
        <img [src]="taktischeFormation.dataUrl" width="32px" />
        {{ taktischeFormation.anzeigename }}
      </div>
    </div>

    <!-- Outlet für Children -->
    <div [class.example-tree-invisible]="!treeControl.isExpanded(taktischeFormation)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
