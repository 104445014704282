/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TaktischesZeichenStatus = 'ANGEFORDERT' | 'ALARMIERT' | 'AUF_MARSCH' | 'LAGE' | 'SCHADENGEBIET' | 'EINSATZRAUM' | 'EINSATZSTELLE' | 'EINSATZABSCHNITT' | 'UNTERABSCHNITT' | 'BEREITSTELLUNGSRAUM' | 'GRUNDSCHUTZ' | 'LOGISTIK_EINSATZSTELLE' | 'LOGISTIK_EINSATZABSCHNITT' | 'LOGISTIK_EINSATZSTELLE_CHILD' | 'LOGISTIK_EINSATZABSCHNITT_CHILD' | 'OERTLICH';

export const TaktischesZeichenStatus = {
    Angefordert: 'ANGEFORDERT' as TaktischesZeichenStatus,
    Alarmiert: 'ALARMIERT' as TaktischesZeichenStatus,
    AufMarsch: 'AUF_MARSCH' as TaktischesZeichenStatus,
    Lage: 'LAGE' as TaktischesZeichenStatus,
    Schadengebiet: 'SCHADENGEBIET' as TaktischesZeichenStatus,
    Einsatzraum: 'EINSATZRAUM' as TaktischesZeichenStatus,
    Einsatzstelle: 'EINSATZSTELLE' as TaktischesZeichenStatus,
    Einsatzabschnitt: 'EINSATZABSCHNITT' as TaktischesZeichenStatus,
    Unterabschnitt: 'UNTERABSCHNITT' as TaktischesZeichenStatus,
    Bereitstellungsraum: 'BEREITSTELLUNGSRAUM' as TaktischesZeichenStatus,
    Grundschutz: 'GRUNDSCHUTZ' as TaktischesZeichenStatus,
    LogistikEinsatzstelle: 'LOGISTIK_EINSATZSTELLE' as TaktischesZeichenStatus,
    LogistikEinsatzabschnitt: 'LOGISTIK_EINSATZABSCHNITT' as TaktischesZeichenStatus,
    LogistikEinsatzstelleChild: 'LOGISTIK_EINSATZSTELLE_CHILD' as TaktischesZeichenStatus,
    LogistikEinsatzabschnittChild: 'LOGISTIK_EINSATZABSCHNITT_CHILD' as TaktischesZeichenStatus,
    Oertlich: 'OERTLICH' as TaktischesZeichenStatus
};

