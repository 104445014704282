import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, LageMassnahmeDTO } from 'src/app/api/build/openapi';

export const lageMassnahmeActions = createActionGroup({
  source: 'LageMassnahmen',
  events: {
    'Reset Store': emptyProps(),

    'Get Lage Massnahmen': props<{ lageId: string }>(),
    'Get Lage Massnahmen Success': props<{ massnahmen: LageMassnahmeDTO[]; lageId: string }>(),
    'Get Lage Massnahmen Failure': props<{ errorResponse: ErrorResponse }>(),

    'Add Lage Massnahme': props<{ lageId: string | undefined; massnahme: LageMassnahmeDTO }>(),
    'Add Lage Massnahme Success': props<{ massnahme: LageMassnahmeDTO }>(),
    'Add Lage Massnahme Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Lage Massnahme': props<{ lageid: string; massnahmeId: string; massnahme: LageMassnahmeDTO }>(),
    'Patch Lage Massnahme Success': props<{ patchedMassnahme: LageMassnahmeDTO }>(),
    'Patch Lage Massnahme Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
