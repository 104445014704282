<div class="full-height flex-col flex-center flex-gap-1 p-1 warning">
  <h1 class="font-warning"><b>Wichtiger Hinweis</b></h1>
  <p>
    Die Version von LAGE ist weder für den Produktivbetrieb noch freigegeben. Eingegebene Daten können jederzeit ohne
    Vorwarnung gelöscht werden.
  </p>
  <p>
    Die iSE GmbH wird sich bemühen, diese Version möglichst unterbrechungsfrei zur Verfügung zu stellen. Ausfallzeiten
    können jedoch nicht ausgeschlossen werden.
  </p>
  <p>
    Alle Inhalte und Strukturen dieser Version sind urheber- und leistungsschutzrechtlich geschützt. Jede vom deutschen
    Urheberrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung der iSE GmbH.
  </p>
  <p>Wir bedanken uns bei allen, die beim gemeinsamen Evaluieren der iSE-COBRA 4e LAGE mitmachen.</p>
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close color="warn">Ich habe verstanden!</button>
  </mat-dialog-actions>
</div>
