/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Rolle = 'EINSATZLEITER' | 'LEITER_FUEHRUNGSSTAB' | 'FACHBERATER' | 'VERBINDUNGSPERSON' | 'S1_LEITER' | 'S2_LEITER' | 'S3_LEITER' | 'S4_LEITER' | 'S5_LEITER' | 'S6_LEITER' | 'FERNMELDEDIENST_LEITER' | 'S1_FUEHRUNGSASSISTENT' | 'S2_FUEHRUNGSASSISTENT' | 'S3_FUEHRUNGSASSISTENT' | 'S4_FUEHRUNGSASSISTENT' | 'S5_FUEHRUNGSASSISTENT' | 'S6_FUEHRUNGSASSISTENT' | 'FERNMELDEDIENST_FUEHRUNGSASSISTENT' | 'S1_FUEHRUNGSHILFSPERSONAL' | 'S2_FUEHRUNGSHILFSPERSONAL' | 'S3_FUEHRUNGSHILFSPERSONAL' | 'S4_FUEHRUNGSHILFSPERSONAL' | 'S5_FUEHRUNGSHILFSPERSONAL' | 'S6_FUEHRUNGSHILFSPERSONAL' | 'FERNMELDEDIENST_FUEHRUNGSHILFSPERSONAL';

export const Rolle = {
    Einsatzleiter: 'EINSATZLEITER' as Rolle,
    LeiterFuehrungsstab: 'LEITER_FUEHRUNGSSTAB' as Rolle,
    Fachberater: 'FACHBERATER' as Rolle,
    Verbindungsperson: 'VERBINDUNGSPERSON' as Rolle,
    S1Leiter: 'S1_LEITER' as Rolle,
    S2Leiter: 'S2_LEITER' as Rolle,
    S3Leiter: 'S3_LEITER' as Rolle,
    S4Leiter: 'S4_LEITER' as Rolle,
    S5Leiter: 'S5_LEITER' as Rolle,
    S6Leiter: 'S6_LEITER' as Rolle,
    FernmeldedienstLeiter: 'FERNMELDEDIENST_LEITER' as Rolle,
    S1Fuehrungsassistent: 'S1_FUEHRUNGSASSISTENT' as Rolle,
    S2Fuehrungsassistent: 'S2_FUEHRUNGSASSISTENT' as Rolle,
    S3Fuehrungsassistent: 'S3_FUEHRUNGSASSISTENT' as Rolle,
    S4Fuehrungsassistent: 'S4_FUEHRUNGSASSISTENT' as Rolle,
    S5Fuehrungsassistent: 'S5_FUEHRUNGSASSISTENT' as Rolle,
    S6Fuehrungsassistent: 'S6_FUEHRUNGSASSISTENT' as Rolle,
    FernmeldedienstFuehrungsassistent: 'FERNMELDEDIENST_FUEHRUNGSASSISTENT' as Rolle,
    S1Fuehrungshilfspersonal: 'S1_FUEHRUNGSHILFSPERSONAL' as Rolle,
    S2Fuehrungshilfspersonal: 'S2_FUEHRUNGSHILFSPERSONAL' as Rolle,
    S3Fuehrungshilfspersonal: 'S3_FUEHRUNGSHILFSPERSONAL' as Rolle,
    S4Fuehrungshilfspersonal: 'S4_FUEHRUNGSHILFSPERSONAL' as Rolle,
    S5Fuehrungshilfspersonal: 'S5_FUEHRUNGSHILFSPERSONAL' as Rolle,
    S6Fuehrungshilfspersonal: 'S6_FUEHRUNGSHILFSPERSONAL' as Rolle,
    FernmeldedienstFuehrungshilfspersonal: 'FERNMELDEDIENST_FUEHRUNGSHILFSPERSONAL' as Rolle
};

