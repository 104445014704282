import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BubbleMenueConfig } from '../bubblemenue.service';
import { LogService } from '../../log.service';

@Component({
  selector: 'app-bubblemenue',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
  templateUrl: './bubblemenue.component.html',
  styleUrls: ['./bubblemenue.component.scss'],
})
export class BubblemenueComponent {
  currentTooltip = '';
  private logservice: LogService = inject(LogService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BubbleMenueConfig[],
    private ref: MatDialogRef<BubblemenueComponent>
  ) {}
  setTooltip(tooltip?: string) {
    this.currentTooltip = tooltip ? tooltip : '';
  }

  do(action: (() => void) | undefined) {
    if (action) {
      this.ref.close(action());
      return;
    }
    this.logservice.info('Keine Action hinterlegt');
  }
}
