import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { FormatService } from '../services/format.service';
import { DefaultDateTimePipe } from './default-date-time.pipe';

@Pipe({
  name: 'dateTime',
  standalone: true,
})
export class DateTimePipe implements PipeTransform {
  constructor(private formatService: FormatService) {}

  /**
   *
   * @returns liefert ein Datum als konvertierten String zurück. Entweder als "default" oder in NATO-Zeit
   */
  transform(value: Date | string | undefined, showSeconds = false, noValueDefault = '-'): Observable<string> {
    if (!value) {
      return of(noValueDefault);
    }

    if (typeof value == 'string') {
      value = new Date(value);
    }

    return this.formatService.timeFormat$.pipe(
      map((pipe) => {
        if (pipe instanceof DefaultDateTimePipe) {
          return pipe.transform(value as Date, showSeconds);
        }
        return pipe.transform(value);
      })
    );
  }
}
