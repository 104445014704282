import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, combineLatest, from } from 'rxjs';
import {
  AnlassDTO,
  BefehlsstelleDTO,
  FahrzeugDTO,
  FotoDTO,
  FuehrungsebeneDTO,
  FuehrungsebeneMassnahmeDTO,
  FuehrungsebeneResourceService,
  FuehrungsebeneVisibilityNode,
  Fuehrungsebenentyp,
  GebaeudeDTO,
  GebietDTO,
  GefahrDTO,
  PersonDTO,
  PersonenschadenDTO,
  StelleDTO,
  TaktischeFormationDTO,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
  TierschadenDTO,
} from 'src/app/api/build/openapi';
import { EinsatzService } from 'src/app/einsatz/einsatz.service';
import { VisibilityProperty } from '../fuehrungsebene-hierarchie/fuehrungsebene-hierarchie.interface';
import { fuehrungsebeneActions } from '../fuehrungsebene/+state/fuehrungsebene.actions';
import { currentFuehrungsebeneSelector } from '../fuehrungsebene/+state/fuehrungsebene.selectors';
import { FuehrungsebeneService } from '../fuehrungsebene/fuehrungsebene.service';
import { anlassActions } from '../taktische-zeichen/anlass-ereignis/+state/anlass.actions';
import { befehlsstelleActions } from '../taktische-zeichen/befehlsstelle/+state/befehlsstelle.actions';
import { fahrzeugActions } from '../taktische-zeichen/fahrzeuge/+state/fahrzeug.actions';
import { fotoActions } from '../taktische-zeichen/foto/+state/foto.actions';
import { gebaeudeActions } from '../taktische-zeichen/gebaeude/+state/gebaeude.actions';
import { gebietActions } from '../taktische-zeichen/gebiete/+state/gebiet.actions';
import { gefahrActions } from '../taktische-zeichen/gefahren/+state/gefahr.actions';
import { fuehrungsebeneMassnahmeActions } from '../taktische-zeichen/massnahmen/+state/fuehrungsebene-massnahme.actions';
import { personActions } from '../taktische-zeichen/personen/+state/person.actions';
import { personenschadenActions } from '../taktische-zeichen/personenschaden/+state/personenschaden.actions';
import { stelleActions } from '../taktische-zeichen/stelle-einrichtung/+state/stelle.actions';
import { taktischeFormationActions } from '../taktische-zeichen/taktische-formation/+state/taktische-formation.actions';
import { tierschadenActions } from '../taktische-zeichen/tierschaden/+state/tierschaden.actions';

@Injectable({
  providedIn: 'root',
})
export class FuehrungsebeneHierarchieService {
  private destroyRef = inject(DestroyRef);

  private currentFuehrungsebene: FuehrungsebeneDTO | null = null;

  // fuehrungsebeneId <-> Node
  private nodeMapping: Map<string, FuehrungsebeneVisibilityNode> = new Map();

  fuehrungsebeneTree$ = new BehaviorSubject<FuehrungsebeneVisibilityNode | undefined>(undefined);
  nodeChanged$ = new Subject<FuehrungsebeneVisibilityNode>();
  nodeDeleted$ = new Subject<FuehrungsebeneVisibilityNode>();

  private readonly STORAGEKEY_VISIBILITY_AUTO = 'LAGE-mapVisibilityAuto';
  private mapVisibilityAutoSubj$ = new BehaviorSubject<boolean>(
    localStorage.getItem(this.STORAGEKEY_VISIBILITY_AUTO) === 'true' || false
  );
  public mapVisibilityAuto$ = from(this.mapVisibilityAutoSubj$);

  private einsatzService = inject(EinsatzService);
  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private fuehrungsebeneResourceService = inject(FuehrungsebeneResourceService);
  private store = inject(Store);
  private actions$ = inject(Actions);

  constructor() {
    this.fetchFuehrungsebeneVisibilityTree();

    /**
     * Beim Wechsel der Führungsebenen die Sichtbarkeit aller Items im Baum aktualisieren
     */
    combineLatest([this.fuehrungsebeneTree$, this.store.select(currentFuehrungsebeneSelector)])
      .pipe(takeUntilDestroyed())
      .subscribe(([rootNode, currentFuehrungsebene]) => {
        this.applyNodeHighlighting(this.currentFuehrungsebene, currentFuehrungsebene);

        this.currentFuehrungsebene = currentFuehrungsebene;
        if (rootNode) {
          this.applyAutoLayerVisibility(rootNode, true);
        }
      });

    this.subscribeToFuehrungsebeneEvents();
    this.subscribeToAnlassEvents();
    this.subscribeToBefehlsstelleEvents();
    this.subscribeToFahrzeugEvents();
    this.subscribeToFotoEvents();
    this.subscribeToFuehrungsebeneMassnahmeEvents();
    this.subscribeToGebaeudeEvents();
    this.subscribeToGebietEvents();
    this.subscribeToGefahrEvents();
    this.subscribeToPersonEvents();
    this.subscribeToPersonenschadenEvents();
    this.subscribeToStelleEvents();
    this.subscribeToTaktischeFormationEvents();
    this.subscribeToTierschadenEvents();
    // TODO: Wenn sich Einsatzfilter oder Einsätze ändern -> aktualisieren
    // this.subscribeToEinsatzEvents();
  }

  public fetchFuehrungsebeneVisibilityTree() {
    const lageId = this.fuehrungsebeneService.getCurrentLage()?.id;
    if (lageId) {
      this.fuehrungsebeneResourceService.getFuehrungsebeneVisibilityTree(lageId).subscribe((rootNode) => {
        this.buildNodeMapping(rootNode);
        this.fuehrungsebeneTree$.next(rootNode);
      });
    }
  }

  private buildNodeMapping(rootNode: FuehrungsebeneVisibilityNode) {
    this.nodeMapping = new Map<string, FuehrungsebeneVisibilityNode>();
    this.addNodeToMapping(rootNode);
  }

  private addNodeToMapping(node: FuehrungsebeneVisibilityNode) {
    const fuehrungsebeneId = node.fuehrungsebeneDTO.id;
    if (!fuehrungsebeneId) {
      console.warn(
        'Erstellung des NodeMappings fehlgeschlagen: Führungsebene nicht in Node vorhanden oder sie hat keine id.'
      );
      return;
    }

    if (this.nodeMapping.has(fuehrungsebeneId)) {
      console.warn('Erstellung des NodeMappings fehlgeschlagen: FührungsebeneId doppelt');
      return;
    }

    this.nodeMapping.set(fuehrungsebeneId, node);

    node.bereitstellungsraumChildNodes.forEach((brNode) => this.addNodeToMapping(brNode));
    node.grundschutzChildNodes.forEach((gsNode) => this.addNodeToMapping(gsNode));
    node.oertlichChildNodes.forEach((oeNode) => this.addNodeToMapping(oeNode));
    node.mainTypeChildNodes.forEach((childNode) => this.addNodeToMapping(childNode));
  }

  public getNodeByFuehrungsebeneId(fuehrungsebeneId: string) {
    return this.nodeMapping.get(fuehrungsebeneId);
  }

  public toggleMapVisibilityAuto() {
    this.mapVisibilityAutoSubj$.next(!this.mapVisibilityAutoSubj$.value);
    localStorage.setItem(this.STORAGEKEY_VISIBILITY_AUTO, '' + this.mapVisibilityAutoSubj$.value);

    // Automatische Sichtbarkeit setzen, wenn gewünscht
    if (this.fuehrungsebeneTree$.value && this.mapVisibilityAutoSubj$.value) {
      this.applyAutoLayerVisibility(this.fuehrungsebeneTree$.value, true);
    }
  }

  /**
   * Setzt die Sichtbarkeit der Karten-Objekte einer Führungsebene abhängig von der Current-Führungsebene.
   * - Ist die übergebene Führungsebene die Current-Führungsebene, sind alle Karten-Objekte sichtbar
   * - Ist die übergebene Führungsebene der Parent, ein Child oder ein Sibling von der Current-Führungsebene, so sollen nur deren Geometrien sichtbar sein
   * - Alle anderen Führungsebenen werden nicht gezeichnet
   *
   * Die Sichtbarkeit wird nur über den Hierarchie-Baum gesezt. Alles kann also weiterhin auf Wunsch vom Benutzer ein- und ausgeblendet werden.
   */
  private applyAutoLayerVisibility(node: FuehrungsebeneVisibilityNode, recursively: boolean) {
    // Wenn automatische Sichtbarkeit deaktiviert, abbrechen
    if (!this.mapVisibilityAutoSubj$.value) {
      return;
    }

    const isCurrentFuehrungsebene = node.fuehrungsebeneDTO.id === this.currentFuehrungsebene?.id;

    // Wenn Current Führungsebene -> Alles anzeigen, Sonst erstmal alles ausblenden
    this.setLayerVisibility(node, VisibilityProperty.GESAMT, isCurrentFuehrungsebene, false);

    const isParentOfCurrentFuehrungsebene =
      node.fuehrungsebeneDTO.id === this.currentFuehrungsebene?.parentFuehrungsebeneId;
    const isChildOfCurrentFuehrungsebene =
      node.fuehrungsebeneDTO.parentFuehrungsebeneId === this.currentFuehrungsebene?.id;
    const isSiblingOfCurrentFuehrungsebene =
      node.fuehrungsebeneDTO.parentFuehrungsebeneId === this.currentFuehrungsebene?.parentFuehrungsebeneId;

    // Wenn Nachbar-Führungsebene -> Nur Geometrie anzeigen
    if (isParentOfCurrentFuehrungsebene || isChildOfCurrentFuehrungsebene || isSiblingOfCurrentFuehrungsebene) {
      this.setLayerVisibility(node, VisibilityProperty.GEOMETRIE, true, false);
      this.setLayerVisibility(node, VisibilityProperty.POLITISCH_GESAMTVERANTWORTLICH, true, false);
      this.setLayerVisibility(node, VisibilityProperty.ADMINISTRATIV_ORGANISATORISCH, true, false);
      this.setLayerVisibility(node, VisibilityProperty.OPERATIV_TAKTISCH, true, false);
      this.setLayerVisibility(node, VisibilityProperty.LEITSTELLE, true, false);
    }

    if (recursively) {
      node.mainTypeChildNodes.forEach((child) => this.applyAutoLayerVisibility(child, recursively));
    }
  }

  private applyNodeHighlighting(
    oldCurrentFuehrungsebene: FuehrungsebeneDTO | null,
    newCurrentFuehrungsebene: FuehrungsebeneDTO | null
  ) {
    // Highlighting der alten "CurrentFuehrungsebene" entfernen
    if (oldCurrentFuehrungsebene?.id) {
      const oldNode = this.nodeMapping.get(oldCurrentFuehrungsebene.id);
      if (oldNode) {
        oldNode.highlighted = false;
        this.nodeChanged$.next(oldNode);
      }
    }

    // Highlighting der alten "CurrentFuehrungsebene" hinzufügen
    if (newCurrentFuehrungsebene?.id) {
      const newNode = this.nodeMapping.get(newCurrentFuehrungsebene.id);
      if (newNode) {
        newNode.highlighted = true;
        this.nodeChanged$.next(newNode);
      }
    }
  }

  /**
   * Wenn übergebene FührungsebeneId nicht das oberste Element des Baums ist, wird davon ausgegangen, dass es einen Parent hat.
   */
  fuehrungsebeneHasParent(fuehrungsebeneId: string) {
    if (!this.fuehrungsebeneTree$.value) {
      return false;
    }
    return this.fuehrungsebeneTree$.value.fuehrungsebeneDTO.id !== fuehrungsebeneId;
  }

  /**
   * Prüft, ob Führungsebene weitere Haupt-Führungsebenen unter sich hat
   */
  fuehrungsebeneHasChild(fuehrungsebeneId: string): boolean {
    if (!this.fuehrungsebeneTree$.value) {
      return false;
    }
    return this.treeNodeHasChild(fuehrungsebeneId, this.fuehrungsebeneTree$.value);
  }

  /**
   * Prüft, ob Führungsebene weitere Haupt-Führungsebenen unter sich hat. Haupt-Führungsebenen sind SG, ER, ESt, EA, UA
   */
  private treeNodeHasChild(fuehrungsebeneId: string, treeNode: FuehrungsebeneVisibilityNode): boolean {
    if (!treeNode.mainTypeChildNodes.length) {
      return false;
    }

    if (treeNode.fuehrungsebeneDTO.id === fuehrungsebeneId) {
      return true;
    }
    return treeNode.mainTypeChildNodes.some((childNode) => this.treeNodeHasChild(fuehrungsebeneId, childNode));
  }

  setLayerVisibility(
    node: FuehrungsebeneVisibilityNode | undefined,
    visibilityProperty: VisibilityProperty,
    visible: boolean,
    recursively: boolean
  ) {
    if (!node) {
      return;
    }

    switch (visibilityProperty) {
      case VisibilityProperty.GESAMT:
        node.showAll = visible;

        node.showGeometry = visible;
        node.showPolitischGesamtverantwortlich = visible;
        node.showAdministrativOrganisatorisch = visible;
        node.showOperativTaktisch = visible;
        node.showLeitstelle = visible;
        node.showBereitstellungsraeume = visible;
        node.showGrundschutz = visible;
        node.showOertlich = visible;

        node.showAnlaesse = visible;
        node.showBefehlsstellen = visible;
        node.showFahrzeuge = visible;
        node.showFotos = visible;
        node.showGebaeude = visible;
        node.showGebiete = visible;
        node.showGefahren = visible;
        node.showMassnahmen = visible;
        node.showPersonen = visible;
        node.showPersonenschaeden = visible;
        node.showStellen = visible;
        node.showTaktischeFormationen = visible;
        node.showTierschaeden = visible;

        node.showEinsaetze = visible;
        break;

      case VisibilityProperty.ANLASS:
        node.showAnlaesse = visible;
        if (node.showAnlaesse && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.BEFEHLSSTELLE:
        node.showBefehlsstellen = visible;
        if (node.showBefehlsstellen && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.FAHRZEUG:
        node.showFahrzeuge = visible;
        if (node.showFahrzeuge && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.FOTO:
        node.showFotos = visible;
        if (node.showFotos && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.GEBAEUDE:
        node.showGebaeude = visible;
        if (node.showGebaeude && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.GEBIET:
        node.showGebiete = visible;
        if (node.showGebiete && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.GEFAHR:
        node.showGefahren = visible;
        if (node.showAnlaesse && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.MASSNAHME:
        node.showMassnahmen = visible;
        if (node.showMassnahmen && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.PERSON:
        node.showPersonen = visible;
        if (node.showPersonen && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.PERSONENSCHADEN:
        node.showPersonenschaeden = visible;
        if (node.showPersonenschaeden && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.STELLE:
        node.showStellen = visible;
        if (node.showStellen && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.TAKTISCHE_FORMATION:
        node.showTaktischeFormationen = visible;
        if (node.showTaktischeFormationen && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.TIERSCHADEN:
        node.showTierschaeden = visible;
        if (node.showTierschaeden && !node.showAll) {
          node.showAll = true;
        }
        break;

      case VisibilityProperty.GEOMETRIE:
        node.showGeometry = visible;
        if (node.showGeometry && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.POLITISCH_GESAMTVERANTWORTLICH:
        node.showPolitischGesamtverantwortlich = visible;
        if (node.showPolitischGesamtverantwortlich && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.ADMINISTRATIV_ORGANISATORISCH:
        node.showAdministrativOrganisatorisch = visible;
        if (node.showAdministrativOrganisatorisch && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.OPERATIV_TAKTISCH:
        node.showOperativTaktisch = visible;
        if (node.showOperativTaktisch && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.LEITSTELLE:
        node.showLeitstelle = visible;
        if (node.showLeitstelle && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.BEREITSTELLUNGSRAUM:
        node.showBereitstellungsraeume = visible;
        if (node.showBereitstellungsraeume && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.GRUNDSCHUTZ:
        node.showGrundschutz = visible;
        if (node.showGrundschutz && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.OERTLICH:
        node.showOertlich = visible;
        if (node.showOertlich && !node.showAll) {
          node.showAll = true;
        }
        break;
      case VisibilityProperty.EINSATZ:
        node.showEinsaetze = visible;
        if (node.showEinsaetze && !node.showAll) {
          node.showAll = true;
        }
        break;
    }

    this.nodeChanged$.next(node);

    if (recursively) {
      node.mainTypeChildNodes.forEach((child) =>
        this.setLayerVisibility(child, visibilityProperty, visible, recursively)
      );
    }
  }

  toggleLayerVisibility(
    node: FuehrungsebeneVisibilityNode | undefined,
    visibilityProperty: VisibilityProperty,
    recursively: boolean
  ) {
    if (!node) {
      return;
    }

    switch (visibilityProperty) {
      case VisibilityProperty.GESAMT:
        this.setLayerVisibility(node, visibilityProperty, !node.showAll, recursively);
        break;

      case VisibilityProperty.ANLASS:
        this.setLayerVisibility(node, visibilityProperty, !node.showAnlaesse, recursively);
        break;
      case VisibilityProperty.BEFEHLSSTELLE:
        this.setLayerVisibility(node, visibilityProperty, !node.showBefehlsstellen, recursively);
        break;
      case VisibilityProperty.FAHRZEUG:
        this.setLayerVisibility(node, visibilityProperty, !node.showFahrzeuge, recursively);
        break;
      case VisibilityProperty.FOTO:
        this.setLayerVisibility(node, visibilityProperty, !node.showFotos, recursively);
        break;
      case VisibilityProperty.GEBAEUDE:
        this.setLayerVisibility(node, visibilityProperty, !node.showGebaeude, recursively);
        break;
      case VisibilityProperty.GEBIET:
        this.setLayerVisibility(node, visibilityProperty, !node.showGebiete, recursively);
        break;
      case VisibilityProperty.GEFAHR:
        this.setLayerVisibility(node, visibilityProperty, !node.showGefahren, recursively);
        break;
      case VisibilityProperty.MASSNAHME:
        this.setLayerVisibility(node, visibilityProperty, !node.showMassnahmen, recursively);
        break;
      case VisibilityProperty.PERSON:
        this.setLayerVisibility(node, visibilityProperty, !node.showPersonen, recursively);
        break;
      case VisibilityProperty.PERSONENSCHADEN:
        this.setLayerVisibility(node, visibilityProperty, !node.showPersonenschaeden, recursively);
        break;
      case VisibilityProperty.STELLE:
        this.setLayerVisibility(node, visibilityProperty, !node.showStellen, recursively);
        break;
      case VisibilityProperty.TAKTISCHE_FORMATION:
        this.setLayerVisibility(node, visibilityProperty, !node.showTaktischeFormationen, recursively);
        break;
      case VisibilityProperty.TIERSCHADEN:
        this.setLayerVisibility(node, visibilityProperty, !node.showTierschaeden, recursively);
        break;

      case VisibilityProperty.GEOMETRIE:
        this.setLayerVisibility(node, visibilityProperty, !node.showGeometry, recursively);
        break;
      case VisibilityProperty.POLITISCH_GESAMTVERANTWORTLICH:
        this.setLayerVisibility(node, visibilityProperty, !node.showPolitischGesamtverantwortlich, recursively);
        break;
      case VisibilityProperty.ADMINISTRATIV_ORGANISATORISCH:
        this.setLayerVisibility(node, visibilityProperty, !node.showAdministrativOrganisatorisch, recursively);
        break;
      case VisibilityProperty.OPERATIV_TAKTISCH:
        this.setLayerVisibility(node, visibilityProperty, !node.showOperativTaktisch, recursively);
        break;
      case VisibilityProperty.LEITSTELLE:
        this.setLayerVisibility(node, visibilityProperty, !node.showLeitstelle, recursively);
        break;
      case VisibilityProperty.BEREITSTELLUNGSRAUM:
        this.setLayerVisibility(node, visibilityProperty, !node.showBereitstellungsraeume, recursively);
        break;
      case VisibilityProperty.GRUNDSCHUTZ:
        this.setLayerVisibility(node, visibilityProperty, !node.showGrundschutz, recursively);
        break;
      case VisibilityProperty.OERTLICH:
        this.setLayerVisibility(node, visibilityProperty, !node.showOertlich, recursively);
        break;
      case VisibilityProperty.EINSATZ:
        this.setLayerVisibility(node, visibilityProperty, !node.showEinsaetze, recursively);
        break;
    }
  }

  /**
   * Liefert den Node, der zur Führungsebene des übergebenen TZs passt.
   */
  private findNodeByTzFuehrungsebene(tzDTO: TaktischesZeichenDTO) {
    const fuehrungsebeneId = tzDTO.fuehrungsebeneId;
    if (!fuehrungsebeneId) {
      throw new Error('TZ hat keine Führungsebene. Event wird verworfen');
    }

    const fuehrungsebeneNode = this.nodeMapping.get(fuehrungsebeneId);
    if (!fuehrungsebeneNode) {
      throw new Error('Führungsebene von TZ existiert nicht als Node im Mapping. Event wird verworfen');
    }
    return fuehrungsebeneNode;
  }

  /**
   * Durchläuft alle Nodes und liefert den ersten Node zurück, der das übergebene TZ enthält.
   */
  private findNodeByAssignedTzs(tzDto: TaktischesZeichenDTO) {
    for (const node of this.nodeMapping.values()) {
      const nodeTzDtos = this.getNodeTzsByTyp(node, tzDto.typ);
      if (nodeTzDtos.some((tz) => tz.id === tzDto.id)) {
        return node;
      }
    }
    return undefined;
  }

  /**
   * Liefert die korrekte TZ-Liste eines Nodes anhand des übergebenen Tz-Typs zurück.
   */
  private getNodeTzsByTyp(node: FuehrungsebeneVisibilityNode, tzTyp: TaktischesZeichenTyp) {
    switch (tzTyp) {
      case TaktischesZeichenTyp.Anlass:
        return node.anlassDTOs;
      case TaktischesZeichenTyp.Befehlsstelle:
        return node.befehlsstelleDTOs;
      case TaktischesZeichenTyp.Fahrzeug:
        return node.fahrzeugDTOs;
      case TaktischesZeichenTyp.Foto:
        return node.fotoDTOs;
      case TaktischesZeichenTyp.Gebaeude:
        return node.gebaeudeDTOs;
      case TaktischesZeichenTyp.Gebiet:
        return node.gebietDTOs;
      case TaktischesZeichenTyp.Gefahr:
        return node.gefahrDTOs;
      case TaktischesZeichenTyp.Massnahme:
        return node.massnahmeDTOs;
      case TaktischesZeichenTyp.Person:
        return node.personDTOs;
      case TaktischesZeichenTyp.Personenschaden:
        return node.personenschadenDTOs;
      case TaktischesZeichenTyp.Stelle:
        return node.stelleDTOs;
      case TaktischesZeichenTyp.TaktischeFormation:
        return node.taktischeFormationDTOs;
      case TaktischesZeichenTyp.Tierschaden:
        return node.tierschadenDTOs;
      default:
        return [];
    }
  }

  private findNodeByFuehrungsebeneId(fuehrungsebeneId: string) {
    const node = this.nodeMapping.get(fuehrungsebeneId);
    if (!node) {
      throw new Error('Führungsebene existiert nicht als Node im Mapping');
    }
    return node;
  }

  /**
   * Sucht den Node, der zur Führungsebene des übergebenen TZs passt
   * und fügt das TZ anschließend zur passenden TZ-Liste hinzu.
   */
  private addTzToMatchingNode(tzDto: TaktischesZeichenDTO) {
    const node = this.findNodeByTzFuehrungsebene(tzDto);
    switch (tzDto.typ) {
      case TaktischesZeichenTyp.Anlass:
        node.anlassDTOs.push(tzDto as AnlassDTO);
        break;
      case TaktischesZeichenTyp.Befehlsstelle:
        node.befehlsstelleDTOs.push(tzDto as BefehlsstelleDTO);
        break;
      case TaktischesZeichenTyp.Fahrzeug:
        node.fahrzeugDTOs.push(tzDto as FahrzeugDTO);
        break;
      case TaktischesZeichenTyp.Foto:
        node.fotoDTOs.push(tzDto as FotoDTO);
        break;
      case TaktischesZeichenTyp.Gebaeude:
        node.gebaeudeDTOs.push(tzDto as GebaeudeDTO);
        break;
      case TaktischesZeichenTyp.Gebiet:
        node.gebietDTOs.push(tzDto as GebietDTO);
        break;
      case TaktischesZeichenTyp.Gefahr:
        node.gefahrDTOs.push(tzDto as GefahrDTO);
        break;
      case TaktischesZeichenTyp.Massnahme:
        node.massnahmeDTOs.push(tzDto as FuehrungsebeneMassnahmeDTO);
        break;
      case TaktischesZeichenTyp.Person:
        node.personDTOs.push(tzDto as PersonDTO);
        break;
      case TaktischesZeichenTyp.Personenschaden:
        node.personenschadenDTOs.push(tzDto as PersonenschadenDTO);
        break;
      case TaktischesZeichenTyp.Stelle:
        node.stelleDTOs.push(tzDto as StelleDTO);
        break;
      case TaktischesZeichenTyp.TaktischeFormation:
        node.taktischeFormationDTOs.push(tzDto as TaktischeFormationDTO);
        break;
      case TaktischesZeichenTyp.Tierschaden:
        node.tierschadenDTOs.push(tzDto as TierschadenDTO);
        break;
      default:
        return;
    }
    this.nodeChanged$.next(node);
  }

  /**
   * Sucht den Node, der zur Führungsebene des übergebenen TZs passt.
   * Wenn das TZ bereits im Node enthalten ist, wird es einfach ersetzt.
   * Andernfalls hat das TZ die Führungsebene gewechselt und es muss
   * aus dem alten Node gelöscht und zum neuen Node hinzugefügt werden.
   */
  private replaceTzInMatchingNode(tzDto: TaktischesZeichenDTO) {
    const currentNode = this.findNodeByTzFuehrungsebene(tzDto);
    const currentNodeTzs = this.getNodeTzsByTyp(currentNode, tzDto.typ);

    const tzNodeDidntChange = currentNodeTzs.some((t) => t.id === tzDto.id);

    // Wenn gleiche Führungsebene wie vor dem Patchen, TZ durch aktuelle Version ersetzen
    if (tzNodeDidntChange) {
      switch (tzDto.typ) {
        case TaktischesZeichenTyp.Anlass:
          currentNode.anlassDTOs = currentNode.anlassDTOs.map((a) => (a.id === tzDto.id ? (tzDto as AnlassDTO) : a));
          break;
        case TaktischesZeichenTyp.Befehlsstelle:
          currentNode.befehlsstelleDTOs = currentNode.befehlsstelleDTOs.map((b) =>
            b.id === tzDto.id ? (tzDto as BefehlsstelleDTO) : b
          );
          break;
        case TaktischesZeichenTyp.Fahrzeug:
          currentNode.fahrzeugDTOs = currentNode.fahrzeugDTOs.map((f) =>
            f.id === tzDto.id ? (tzDto as FahrzeugDTO) : f
          );
          break;
        case TaktischesZeichenTyp.Foto:
          currentNode.fotoDTOs = currentNode.fotoDTOs.map((f) => (f.id === tzDto.id ? (tzDto as FotoDTO) : f));
          break;
        case TaktischesZeichenTyp.Gebaeude:
          currentNode.gebaeudeDTOs = currentNode.gebaeudeDTOs.map((g) =>
            g.id === tzDto.id ? (tzDto as GebaeudeDTO) : g
          );
          break;
        case TaktischesZeichenTyp.Gebiet:
          currentNode.gebietDTOs = currentNode.gebietDTOs.map((g) => (g.id === tzDto.id ? (tzDto as GebietDTO) : g));
          break;
        case TaktischesZeichenTyp.Gefahr:
          currentNode.gefahrDTOs = currentNode.gefahrDTOs.map((g) => (g.id === tzDto.id ? (tzDto as GefahrDTO) : g));
          break;
        case TaktischesZeichenTyp.Massnahme:
          currentNode.massnahmeDTOs = currentNode.massnahmeDTOs.map((m) =>
            m.id === tzDto.id ? (tzDto as FuehrungsebeneMassnahmeDTO) : m
          );
          break;
        case TaktischesZeichenTyp.Person:
          currentNode.personDTOs = currentNode.personDTOs.map((p) => (p.id === tzDto.id ? (tzDto as PersonDTO) : p));
          break;
        case TaktischesZeichenTyp.Personenschaden:
          currentNode.personenschadenDTOs = currentNode.personenschadenDTOs.map((a) =>
            a.id === tzDto.id ? (tzDto as PersonenschadenDTO) : a
          );
          break;
        case TaktischesZeichenTyp.Stelle:
          currentNode.stelleDTOs = currentNode.stelleDTOs.map((s) => (s.id === tzDto.id ? (tzDto as StelleDTO) : s));
          break;
        case TaktischesZeichenTyp.TaktischeFormation:
          currentNode.taktischeFormationDTOs = currentNode.taktischeFormationDTOs.map((t) =>
            t.id === tzDto.id ? (tzDto as TaktischeFormationDTO) : t
          );
          break;
        case TaktischesZeichenTyp.Tierschaden:
          currentNode.tierschadenDTOs = currentNode.tierschadenDTOs.map((t) =>
            t.id === tzDto.id ? (tzDto as TierschadenDTO) : t
          );
          break;
        default:
          return;
      }
      this.nodeChanged$.next(currentNode);
    }
    // Sonst hat ein Führungsebenenwechsel stattgefunden
    else {
      // TZ aus dem Node der alten Führungsebene entfernen
      const oldNode = this.findNodeByAssignedTzs(tzDto);
      if (oldNode) {
        this.removeTzFromMatchingNode(tzDto, oldNode);
      }

      // TZ zum Node der neuen Führungsebene hinzufügen
      this.addTzToMatchingNode(tzDto);
    }
  }

  /**
   * Sucht den Node, der zur Führungsebene des übergebenen TZs passt
   * und löscht das TZ anschließend aus der passenden TZ-Liste.
   *
   * @param fixNode Wenn fixNode gesetzt ist, wird kein passender Node gesucht, sondern stattdessen der fixNode genutzt
   */
  private removeTzFromMatchingNode(tzDto: TaktischesZeichenDTO, fixNode?: FuehrungsebeneVisibilityNode) {
    const node = fixNode || this.findNodeByTzFuehrungsebene(tzDto);
    switch (tzDto.typ) {
      case TaktischesZeichenTyp.Anlass:
        node.anlassDTOs = node.anlassDTOs.filter((a) => a.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Befehlsstelle:
        node.befehlsstelleDTOs = node.befehlsstelleDTOs.filter((b) => b.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Fahrzeug:
        node.fahrzeugDTOs = node.fahrzeugDTOs.filter((f) => f.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Foto:
        node.fotoDTOs = node.fotoDTOs.filter((f) => f.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Gebaeude:
        node.gebaeudeDTOs = node.gebaeudeDTOs.filter((g) => g.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Gebiet:
        node.gebietDTOs = node.gebietDTOs.filter((g) => g.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Gefahr:
        node.gefahrDTOs = node.gefahrDTOs.filter((g) => g.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Massnahme:
        node.massnahmeDTOs = node.massnahmeDTOs.filter((m) => m.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Person:
        node.personDTOs = node.personDTOs.filter((p) => p.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Personenschaden:
        node.personenschadenDTOs = node.personenschadenDTOs.filter((p) => p.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Stelle:
        node.stelleDTOs = node.stelleDTOs.filter((s) => s.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.TaktischeFormation:
        node.taktischeFormationDTOs = node.taktischeFormationDTOs.filter((t) => t.id !== tzDto.id);
        break;
      case TaktischesZeichenTyp.Tierschaden:
        node.tierschadenDTOs = node.tierschadenDTOs.filter((t) => t.id !== tzDto.id);
        break;
      default:
        return;
    }
    this.nodeChanged$.next(node);
  }

  // Anlass
  private subscribeToAnlassEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(anlassActions.createAnlassSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.createdAnlassDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(anlassActions.patchAnlassSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedAnlassDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(anlassActions.deleteAnlassSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedAnlassDTO));
  }

  // Befehlsstelle
  private subscribeToBefehlsstelleEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(befehlsstelleActions.createBefehlsstelleSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.createdBefehlsstelleDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(befehlsstelleActions.patchBefehlsstelleSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedBefehlsstelleDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(befehlsstelleActions.deleteBefehlsstelleSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedBefehlsstelleDTO));
  }

  // Fahrzeug
  private subscribeToFahrzeugEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(fahrzeugActions.createFahrzeugSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.newFahrzeug));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(fahrzeugActions.patchFahrzeugSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedFahrzeug));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(fahrzeugActions.deleteFahrzeugSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedFahrzeugDTO));
  }

  // Foto
  private subscribeToFotoEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(fotoActions.createFotoSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.createdFotoDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(fotoActions.patchFotoSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedFotoDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(fotoActions.deleteFotoSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedFotoDTO));
  }

  // Gebaeude
  private subscribeToGebaeudeEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(gebaeudeActions.createGebaeudeSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.createdGebaeudeDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(gebaeudeActions.patchGebaeudeSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedGebaeudeDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(gebaeudeActions.deleteGebaeudeSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedGebaeudeDTO));
  }

  // Gebiet
  private subscribeToGebietEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(gebietActions.createGebietSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.createdGebietDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(gebietActions.patchGebietSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedGebietDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(gebietActions.deleteGebietSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedGebietDTO));
  }

  // Gefahr
  private subscribeToGefahrEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(gefahrActions.createGefahrSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.createdGefahrDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(gefahrActions.patchGefahrSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedGefahrDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(gefahrActions.deleteGefahrSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedGefahrDTO));
  }

  // FuehrungsebeneMassnahme
  private subscribeToFuehrungsebeneMassnahmeEvents() {
    this.actions$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        ofType(fuehrungsebeneMassnahmeActions.createFuehrungsebeneMassnahmeSuccess)
      )
      .subscribe((action) => this.addTzToMatchingNode(action.createdFuehrungsebeneMassnahmeDTO));

    this.actions$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        ofType(fuehrungsebeneMassnahmeActions.patchFuehrungsebeneMassnahmeSuccess)
      )
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedFuehrungsebeneMassnahmeDTO));

    this.actions$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        ofType(fuehrungsebeneMassnahmeActions.deleteFuehrungsebeneMassnahmeSuccess)
      )
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedFuehrungsebeneMassnahmeDTO));
  }

  // Person
  private subscribeToPersonEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(personActions.createPersonSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.newPerson));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(personActions.patchPersonSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedPerson));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(personActions.deletePersonSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedPersonDTO));
  }

  // Personenschaden
  private subscribeToPersonenschadenEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(personenschadenActions.createPersonenschadenSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.createdPersonenschadenDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(personenschadenActions.patchPersonenschadenSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedPersonenschadenDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(personenschadenActions.deletePersonenschadenSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedPersonenschadenDTO));
  }

  // Stelle
  private subscribeToStelleEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(stelleActions.createStelleSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.createdStelleDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(stelleActions.patchStelleSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedStelleDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(stelleActions.deleteStelleSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedStelleDTO));
  }

  // TaktischeFormation
  private subscribeToTaktischeFormationEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(taktischeFormationActions.createTaktischeFormationSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.createdTaktischeFormationDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(taktischeFormationActions.patchTaktischeFormationSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedTaktischeFormationDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(taktischeFormationActions.deleteTaktischeFormationSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedTaktischeFormationDTO));
  }

  // Tierschaden
  private subscribeToTierschadenEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(tierschadenActions.createTierschadenSuccess))
      .subscribe((action) => this.addTzToMatchingNode(action.createdTierschadenDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(tierschadenActions.patchTierschadenSuccess))
      .subscribe((action) => this.replaceTzInMatchingNode(action.patchedTierschadenDTO));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(tierschadenActions.deleteTierschadenSuccess))
      .subscribe((action) => this.removeTzFromMatchingNode(action.deletedTierschadenDTO));
  }

  // Fuehrungsebene
  private subscribeToFuehrungsebeneEvents() {
    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(fuehrungsebeneActions.createFuehrungsebeneSuccess))
      .subscribe((action) => this.fuehrungsebeneCreated(action.createdFuehrungsebene));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(fuehrungsebeneActions.patchFuehrungsebeneSuccess))
      .subscribe((action) => this.fuehrungsebenePatched(action.patchedFuehrungsebene));

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(fuehrungsebeneActions.deleteFuehrungsebeneSuccess))
      .subscribe((action) => this.fuehrungsebeneDeleted(action.deletedFuehrungsebeneDTO));
  }

  /**
   * Sucht den Node der Parent-Führungsebene und fügt die neue Führungsebene als eigener Node in diesen ein.
   */
  private fuehrungsebeneCreated(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    if (!fuehrungsebeneDTO.id || !fuehrungsebeneDTO.parentFuehrungsebeneId) {
      throw new Error(
        'Führungsebene kann nicht als Node hinzugefügt werden, da sie keine Id bzw. Parent-Führungsebene besitzt.'
      );
    }

    const parentNode = this.findNodeByFuehrungsebeneId(fuehrungsebeneDTO.parentFuehrungsebeneId);

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.PolitischGesamtverantwortlicher) {
      parentNode.politischGesamtverantwortlichDTO = fuehrungsebeneDTO;
      this.nodeChanged$.next(parentNode);
      return;
    }

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.AdministrativOrganisatorisch) {
      parentNode.administativOrganisatorischDTO = fuehrungsebeneDTO;
      this.nodeChanged$.next(parentNode);
      return;
    }

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.OperativTaktisch) {
      parentNode.operativTaktischDTO = fuehrungsebeneDTO;
      this.nodeChanged$.next(parentNode);
      return;
    }

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.Leitstelle) {
      parentNode.leitstelleDTO = fuehrungsebeneDTO;
      this.nodeChanged$.next(parentNode);
      return;
    }

    // Prüfen, ob Node bereits existiert, sonst einen neuen anlegen
    let newNode: FuehrungsebeneVisibilityNode;
    try {
      newNode = this.findNodeByFuehrungsebeneId(fuehrungsebeneDTO.id);
      newNode.fuehrungsebeneDTO = fuehrungsebeneDTO;
      this.refreshNode(newNode);
    } catch (e) {
      newNode = this.createNode(fuehrungsebeneDTO);
    }

    if (this.fuehrungsebeneService.mainFuehrungsebenentypen.includes(fuehrungsebeneDTO.typ)) {
      parentNode.mainTypeChildNodes = [...parentNode.mainTypeChildNodes, newNode];
      this.nodeChanged$.next(parentNode);
      this.nodeChanged$.next(newNode);
      return;
    }

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.Bereitstellungsraum) {
      parentNode.bereitstellungsraumChildNodes = [...parentNode.bereitstellungsraumChildNodes, newNode];
      this.nodeChanged$.next(parentNode);
      this.nodeChanged$.next(newNode);
      return;
    }

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.Grundschutz) {
      parentNode.grundschutzChildNodes = [...parentNode.grundschutzChildNodes, newNode];
      this.nodeChanged$.next(parentNode);
      this.nodeChanged$.next(newNode);
      return;
    }

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.Oertlich) {
      parentNode.oertlichChildNodes = [...parentNode.oertlichChildNodes, newNode];
      this.nodeChanged$.next(parentNode);
      this.nodeChanged$.next(newNode);
      return;
    }
  }

  private refreshNode(node: FuehrungsebeneVisibilityNode) {
    this.nodeChanged$.next(node);
    node.mainTypeChildNodes.forEach((child) => this.refreshNode(child));
    node.bereitstellungsraumChildNodes.forEach((child) => this.refreshNode(child));
    node.grundschutzChildNodes.forEach((child) => this.refreshNode(child));
    node.oertlichChildNodes.forEach((child) => this.refreshNode(child));
  }

  private fuehrungsebenePatched(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    if (!fuehrungsebeneDTO.id) {
      throw new Error('Führungsebene hat keine Id');
    }

    const node = this.findNodeByFuehrungsebeneId(fuehrungsebeneDTO.id);

    const parentChanged = node.fuehrungsebeneDTO.parentFuehrungsebeneId !== fuehrungsebeneDTO.id;
    if (parentChanged) {
      const oldFuehrungsebeneDto = node.fuehrungsebeneDTO;
      this.removeFuehrungsebeneFromParentNode(oldFuehrungsebeneDto);
      this.fuehrungsebeneCreated(fuehrungsebeneDTO);
      return;
    }

    if (
      this.fuehrungsebeneService.mainFuehrungsebenentypen.includes(fuehrungsebeneDTO.typ) ||
      this.fuehrungsebeneService.specialFuehrungsebenentypen.includes(fuehrungsebeneDTO.typ)
    ) {
      node.fuehrungsebeneDTO = fuehrungsebeneDTO;
      this.nodeChanged$.next(node);
    }
  }

  private fuehrungsebeneDeleted(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    this.deleteNodeFromMapping(fuehrungsebeneDTO);

    if (!fuehrungsebeneDTO.parentFuehrungsebeneId) {
      throw new Error('Führungsebene kann nicht als Node gelöscht werden, da sie keine Parent-Führungsebene besitzt.');
    }

    this.removeFuehrungsebeneFromParentNode(fuehrungsebeneDTO);
  }

  private removeFuehrungsebeneFromParentNode(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    if (!fuehrungsebeneDTO.parentFuehrungsebeneId) {
      throw new Error(
        'Führungsebene kann nicht aus Parent gelöscht werden, da sie keine Parent-Führungsebene besitzt.'
      );
    }

    const parentNode = this.findNodeByFuehrungsebeneId(fuehrungsebeneDTO.parentFuehrungsebeneId);

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.PolitischGesamtverantwortlicher) {
      parentNode.politischGesamtverantwortlichDTO = undefined;
      return this.nodeChanged$.next(parentNode);
    }

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.AdministrativOrganisatorisch) {
      parentNode.administativOrganisatorischDTO = undefined;
      return this.nodeChanged$.next(parentNode);
    }

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.OperativTaktisch) {
      parentNode.operativTaktischDTO = undefined;
      return this.nodeChanged$.next(parentNode);
    }

    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.Leitstelle) {
      parentNode.leitstelleDTO = undefined;
      return this.nodeChanged$.next(parentNode);
    }

    // Gelöschte Führungsebene ist Haupttyp
    if (this.fuehrungsebeneService.mainFuehrungsebenentypen.includes(fuehrungsebeneDTO.typ)) {
      parentNode.mainTypeChildNodes = parentNode.mainTypeChildNodes.filter((n) => {
        if (n.fuehrungsebeneDTO.id === fuehrungsebeneDTO.id) {
          this.nodeDeleted$.next(n);
          return false;
        }
        return true;
      });
      return this.nodeChanged$.next(parentNode);
    }

    // Gelöschte Führungsebene ist Bereitstellungsraum
    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.Bereitstellungsraum) {
      parentNode.bereitstellungsraumChildNodes = parentNode.bereitstellungsraumChildNodes.filter((n) => {
        if (n.fuehrungsebeneDTO.id === fuehrungsebeneDTO.id) {
          this.nodeDeleted$.next(n);
          return false;
        }
        return true;
      });
      return this.nodeChanged$.next(parentNode);
    }

    // Gelöschte Führungsebene ist Grundschutz
    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.Grundschutz) {
      parentNode.grundschutzChildNodes = parentNode.grundschutzChildNodes.filter((n) => {
        if (n.fuehrungsebeneDTO.id === fuehrungsebeneDTO.id) {
          this.nodeDeleted$.next(n);
          return false;
        }
        return true;
      });
      return this.nodeChanged$.next(parentNode);
    }

    // Gelöschte Führungsebene ist ÖrtlicheEinheit
    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.Oertlich) {
      parentNode.oertlichChildNodes = parentNode.oertlichChildNodes.filter((n) => {
        if (n.fuehrungsebeneDTO.id === fuehrungsebeneDTO.id) {
          this.nodeDeleted$.next(n);
          return false;
        }
        return true;
      });
      return this.nodeChanged$.next(parentNode);
    }
  }

  private createNode(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    if (!fuehrungsebeneDTO.id) {
      throw new Error('Führungsebene muss eine Id besitzen, um einen Node zu generieren');
    }

    if (this.nodeMapping.get(fuehrungsebeneDTO.id)) {
      throw new Error('Führungsebene ist bereits im Mapping. Weiterer Node kann nicht erzeugt werden.');
    }

    const node: FuehrungsebeneVisibilityNode = {
      fuehrungsebeneDTO: fuehrungsebeneDTO,
      mainTypeChildNodes: [],
      bereitstellungsraumChildNodes: [],
      grundschutzChildNodes: [],
      oertlichChildNodes: [],
      anlassDTOs: [],
      befehlsstelleDTOs: [],
      fahrzeugDTOs: [],
      fotoDTOs: [],
      gebaeudeDTOs: [],
      gebietDTOs: [],
      gefahrDTOs: [],
      massnahmeDTOs: [],
      personDTOs: [],
      personenschadenDTOs: [],
      stelleDTOs: [],
      taktischeFormationDTOs: [],
      tierschadenDTOs: [],
      einsatzDtos: this.einsatzService.getDistinctEinsatzDtos(fuehrungsebeneDTO.einsatzfilter),
      showAll: true,
      showGeometry: true,
      showPolitischGesamtverantwortlich: true,
      showAdministrativOrganisatorisch: true,
      showOperativTaktisch: true,
      showLeitstelle: true,
      showBereitstellungsraeume: true,
      showGrundschutz: true,
      showOertlich: true,
      showAnlaesse: true,
      showBefehlsstellen: true,
      showFahrzeuge: true,
      showFotos: true,
      showGebaeude: true,
      showGebiete: true,
      showGefahren: true,
      showMassnahmen: true,
      showPersonen: true,
      showPersonenschaeden: true,
      showStellen: true,
      showTaktischeFormationen: true,
      showTierschaeden: true,
      showEinsaetze: true,
    };
    this.applyAutoLayerVisibility(node, false);
    this.nodeMapping.set(fuehrungsebeneDTO.id, node);
    this.nodeChanged$.next(node);
    return node;
  }

  private deleteNodeFromMapping(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    if (!fuehrungsebeneDTO.id) {
      throw new Error('Führungsebene Node kann nicht gelöscht werden. DTO besitzt keine Id');
    }

    if (this.nodeMapping.has(fuehrungsebeneDTO.id)) {
      this.nodeMapping.delete(fuehrungsebeneDTO.id);
    }
  }
}
