/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Funktion = 'LEITER' | 'STELLVERTRETER' | 'MITARBEITER' | 'INNERER_DIENST_LEITER' | 'LAGE_UND_DOKUMENTATION_LEITER' | 'LAGE_UND_DOKUMENTATION_MITARBEITER' | 'SICHERHEIT_UND_ORDNUNG' | 'EINSATZLEITUNG' | 'FEUER_UND_KATASTROPHENSCHUTZ' | 'GESUNDHEIT' | 'UMWELT' | 'SOZIALES' | 'POLIZEI' | 'BEHOERDE' | 'GEMEINDE' | 'FACHKUNDIGER' | 'SCHREIBDIENST';

export const Funktion = {
    Leiter: 'LEITER' as Funktion,
    Stellvertreter: 'STELLVERTRETER' as Funktion,
    Mitarbeiter: 'MITARBEITER' as Funktion,
    InnererDienstLeiter: 'INNERER_DIENST_LEITER' as Funktion,
    LageUndDokumentationLeiter: 'LAGE_UND_DOKUMENTATION_LEITER' as Funktion,
    LageUndDokumentationMitarbeiter: 'LAGE_UND_DOKUMENTATION_MITARBEITER' as Funktion,
    SicherheitUndOrdnung: 'SICHERHEIT_UND_ORDNUNG' as Funktion,
    Einsatzleitung: 'EINSATZLEITUNG' as Funktion,
    FeuerUndKatastrophenschutz: 'FEUER_UND_KATASTROPHENSCHUTZ' as Funktion,
    Gesundheit: 'GESUNDHEIT' as Funktion,
    Umwelt: 'UMWELT' as Funktion,
    Soziales: 'SOZIALES' as Funktion,
    Polizei: 'POLIZEI' as Funktion,
    Behoerde: 'BEHOERDE' as Funktion,
    Gemeinde: 'GEMEINDE' as Funktion,
    Fachkundiger: 'FACHKUNDIGER' as Funktion,
    Schreibdienst: 'SCHREIBDIENST' as Funktion
};

