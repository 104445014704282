import { Pipe, PipeTransform } from '@angular/core';
import { TaktischesZeichenStatus } from 'src/app/api/build/openapi';

@Pipe({
  name: 'taktischesZeichenStatus',
  standalone: true,
})
export class TaktischesZeichenStatusPipe implements PipeTransform {
  transform(tzStatus?: TaktischesZeichenStatus): string {
    if (!tzStatus) {
      return '';
    }

    switch (tzStatus) {
      case TaktischesZeichenStatus.Alarmiert:
        return 'Alarmiert';
      case TaktischesZeichenStatus.Angefordert:
        return 'Angefordert';
      case TaktischesZeichenStatus.AufMarsch:
        return 'Auf Marsch';
      case TaktischesZeichenStatus.Lage:
        return 'Lage';
      case TaktischesZeichenStatus.Schadengebiet:
        return 'Schadengebiet';
      case TaktischesZeichenStatus.Einsatzraum:
        return 'Einsatzraum';
      case TaktischesZeichenStatus.Einsatzstelle:
        return 'Einsatzstelle';
      case TaktischesZeichenStatus.Einsatzabschnitt:
        return 'Einsatzabschnitt';
      case TaktischesZeichenStatus.Unterabschnitt:
        return 'Unterabschnitt';
      case TaktischesZeichenStatus.Bereitstellungsraum:
        return 'Bereitstellungsraum';
      case TaktischesZeichenStatus.Grundschutz:
        return 'Grundschutz';
      case TaktischesZeichenStatus.LogistikEinsatzstelle:
        return 'Logistik-Einsatzstelle';
      case TaktischesZeichenStatus.LogistikEinsatzabschnitt:
        return 'Logistik-Einsatzabschnitt';
      case TaktischesZeichenStatus.LogistikEinsatzstelleChild:
        return 'Logistik-Einsatzabschnitt';
      case TaktischesZeichenStatus.LogistikEinsatzabschnittChild:
        return 'Logistik-Unterabschnitt';
      case TaktischesZeichenStatus.Oertlich:
        return 'Örtliche Einheiten';
      default:
        return '';
    }
  }
}
