import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DialogMode } from '@product/ise-dialog-lib';
import { ErrorService } from '@product/ise-error-lib';
import { BehaviorSubject, take } from 'rxjs';
import { einsatzfilterActions } from '../+state/einsatzfilter.actions';
import { EinsatzDTO, EinsatzResourceService, EinsatzfilterDTO } from '../../api/build/openapi';
import { FuehrungsebeneService } from '../../lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { ChipListInputComponent } from '../../shared/components/chip-list-input/chip-list-input.component';
import { EinsatzListComponent } from '../einsatz-list/einsatz-list.component';

export interface EinsatzfilterDialogInputData {
  dialogMode: DialogMode;
  einsatzfilterDTO: EinsatzfilterDTO;
}

export interface EinsatzfilterDialogOutputData {
  einsatzfilterDTO?: EinsatzfilterDTO;
}

/**
 * Dialog zum Bearbeiten von EinsatzfilterDTOs.
 */
@Component({
  selector: 'app-einsatzfilter-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    EinsatzListComponent,
    MatCardModule,
    MatChipsModule,
    ChipListInputComponent,
  ],
  templateUrl: './einsatzfilter-dialog.component.html',
  styleUrls: ['./einsatzfilter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinsatzfilterDialogComponent {
  DialogMode = DialogMode;

  private store = inject(Store);
  private actions$ = inject(Actions);
  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private einsatzResourceService = inject(EinsatzResourceService);
  private errorService = inject(ErrorService);
  private dialogRef = inject(MatDialogRef);
  private dialogData: EinsatzfilterDialogInputData = inject(MAT_DIALOG_DATA);
  protected dialogMode: DialogMode = DialogMode.CREATE;
  protected einsatzfilterDTO: EinsatzfilterDTO = {};

  // Filter, die noch keine Werte besitzen und somit noch keinen Chip-Input besitzen
  availableFilterFields: string[] = [];
  // Filter, die bereits Werte haben und für die ein Chip-Input angezeigt wird
  usedFilterFields$ = new BehaviorSubject<string[]>([]);

  private formBuilder = inject(NonNullableFormBuilder);
  protected fcFilterField = this.formBuilder.control<string>('einsatznummer');

  protected fcName = this.formBuilder.control<string | undefined>(undefined, [
    Validators.required,
    Validators.maxLength(255),
  ]);
  protected fcFilterEinsatznummern = this.formBuilder.control<string[]>([]);
  protected fcFilterEinsatzstatus = this.formBuilder.control<string[]>([]);
  protected fcFilterEinsatzeroeffnungen = this.formBuilder.control<string[]>([]);
  protected fcFilterEinsatzarten1 = this.formBuilder.control<string[]>([]);
  protected fcFilterEinsatzstichworte1 = this.formBuilder.control<string[]>([]);
  protected fcFilterEinsatzarten2 = this.formBuilder.control<string[]>([]);
  protected fcFilterEinsatzstichworte2 = this.formBuilder.control<string[]>([]);
  protected fcFilterMeldebilder = this.formBuilder.control<string[]>([]);
  protected fcFilterLaender = this.formBuilder.control<string[]>([]);
  protected fcFilterBundeslaender = this.formBuilder.control<string[]>([]);
  protected fcFilterRegierungsbezirke = this.formBuilder.control<string[]>([]);
  protected fcFilterLandkreise = this.formBuilder.control<string[]>([]);
  protected fcFilterKreisfreieStaedte = this.formBuilder.control<string[]>([]);
  protected fcFilterVerbandsgemeinden = this.formBuilder.control<string[]>([]);
  protected fcFilterPostleitzahlen = this.formBuilder.control<string[]>([]);
  protected fcFilterGemeinden = this.formBuilder.control<string[]>([]);
  protected fcFilterGemeindeteile = this.formBuilder.control<string[]>([]);
  protected fcFilterStrassen = this.formBuilder.control<string[]>([]);
  protected fcFilterHausnummern = this.formBuilder.control<string[]>([]);
  protected fcFilterObjekte = this.formBuilder.control<string[]>([]);

  /**
   * Mapping von Attribut-Name auf Label für die FormControls
   */
  readonly filterFieldLabelMapping = new Map<string, string>([
    ['einsatznummern', 'Einsatznummern'],
    ['einsatzstatus', 'Einsatzstatus'],
    ['einsatzeroeffnungen', 'Einsatzeröffnungen'],
    ['einsatzarten1', 'Einsatzarten 1'],
    ['einsatzstichworte1', 'Einsatzstichworte 1'],
    ['einsatzarten2', 'Einsatzarten 2'],
    ['einsatzstichworte2', 'Einsatzstichworte 2'],
    ['meldebilder', 'Meldebilder'],
    ['laender', 'Länder'],
    ['bundeslaender', 'Bundesländer'],
    ['regierungsbezirke', 'Regierungsbezirke'],
    ['landkreise', 'Landkreise'],
    ['kreisfreieStaedte', 'Kreisfreie Städte'],
    ['verbandsgemeinden', 'Verbandsgemeinden'],
    ['postleitzahlen', 'Postleitzahlen'],
    ['gemeinden', 'Gemeinden'],
    ['gemeindeteile', 'Gemeindeteile'],
    ['strassen', 'Straßen'],
    ['hausnummern', 'Hausnummern'],
    ['objekte', 'Objekte'],
  ]);

  /**
   * Mapping von Attribut-Name auf Form-Control
   */
  readonly filterFieldControlMapping = new Map<string, FormControl>([
    ['einsatznummern', this.fcFilterEinsatznummern],
    ['einsatzstatus', this.fcFilterEinsatzstatus],
    ['einsatzeroeffnungen', this.fcFilterEinsatzeroeffnungen],
    ['einsatzarten1', this.fcFilterEinsatzarten1],
    ['einsatzstichworte1', this.fcFilterEinsatzstichworte1],
    ['einsatzarten2', this.fcFilterEinsatzarten2],
    ['einsatzstichworte2', this.fcFilterEinsatzstichworte2],
    ['meldebilder', this.fcFilterMeldebilder],
    ['laender', this.fcFilterLaender],
    ['bundeslaender', this.fcFilterBundeslaender],
    ['regierungsbezirke', this.fcFilterRegierungsbezirke],
    ['landkreise', this.fcFilterLandkreise],
    ['kreisfreieStaedte', this.fcFilterKreisfreieStaedte],
    ['verbandsgemeinden', this.fcFilterVerbandsgemeinden],
    ['postleitzahlen', this.fcFilterPostleitzahlen],
    ['gemeinden', this.fcFilterGemeinden],
    ['gemeindeteile', this.fcFilterGemeindeteile],
    ['strassen', this.fcFilterStrassen],
    ['hausnummern', this.fcFilterHausnummern],
    ['objekte', this.fcFilterObjekte],
  ]);

  protected previewEinsatzDTOs$ = new BehaviorSubject<EinsatzDTO[]>([]);

  constructor() {
    if (this.dialogData) {
      this.dialogMode = this.dialogData.dialogMode;
      this.einsatzfilterDTO = this.dialogData.einsatzfilterDTO;
    }
    this.dtoToFormGroup();
  }

  /**
   * Ausgewähltes FilterField auswählen, wodurch ein neuer Chip-Input
   * zur Eingabe von Werten für diesen Filter entsteht.
   */
  useCurrentFilterField() {
    const filterField = this.fcFilterField.value;
    if (
      filterField &&
      this.availableFilterFields.includes(filterField) &&
      !this.usedFilterFields$.value.includes(filterField)
    ) {
      this.availableFilterFields = this.availableFilterFields.filter((f) => f !== filterField);
      this.usedFilterFields$.next([...this.usedFilterFields$.value, filterField]);
    }
    this.selectFirstAvailableFilterField();
  }

  /**
   * Den ersten Eintrag des MatSelect-FilterFields vorbelegen
   */
  private selectFirstAvailableFilterField() {
    if (this.availableFilterFields.length) {
      this.fcFilterField.setValue(this.availableFilterFields[0]);
    }
  }

  /**
   * Alle FormControls mit Werten aus dem EinsatzfilterDTO befüllen.
   * Anschließend Chip-Inputs für alle Attribute mit Werten erzeugen.
   */
  dtoToFormGroup() {
    this.fcName.setValue(this.einsatzfilterDTO.name);
    this.fcFilterEinsatznummern.setValue(this.einsatzfilterDTO.einsatznummern || []);
    this.fcFilterEinsatzstatus.setValue(this.einsatzfilterDTO.einsatzstatus || []);
    this.fcFilterEinsatzeroeffnungen.setValue(this.einsatzfilterDTO.einsatzeroeffnungen || []);
    this.fcFilterEinsatzarten1.setValue(this.einsatzfilterDTO.einsatzarten1 || []);
    this.fcFilterEinsatzstichworte1.setValue(this.einsatzfilterDTO.einsatzstichworte1 || []);
    this.fcFilterEinsatzarten2.setValue(this.einsatzfilterDTO.einsatzarten2 || []);
    this.fcFilterEinsatzstichworte2.setValue(this.einsatzfilterDTO.einsatzstichworte2 || []);
    this.fcFilterMeldebilder.setValue(this.einsatzfilterDTO.meldebilder || []);
    this.fcFilterLaender.setValue(this.einsatzfilterDTO.laender || []);
    this.fcFilterBundeslaender.setValue(this.einsatzfilterDTO.bundeslaender || []);
    this.fcFilterRegierungsbezirke.setValue(this.einsatzfilterDTO.regierungsbezirke || []);
    this.fcFilterLandkreise.setValue(this.einsatzfilterDTO.landkreise || []);
    this.fcFilterKreisfreieStaedte.setValue(this.einsatzfilterDTO.kreisfreieStaedte || []);
    this.fcFilterVerbandsgemeinden.setValue(this.einsatzfilterDTO.verbandsgemeinden || []);
    this.fcFilterPostleitzahlen.setValue(this.einsatzfilterDTO.postleitzahlen || []);
    this.fcFilterGemeinden.setValue(this.einsatzfilterDTO.gemeinden || []);
    this.fcFilterGemeindeteile.setValue(this.einsatzfilterDTO.gemeindeteile || []);
    this.fcFilterStrassen.setValue(this.einsatzfilterDTO.strassen || []);
    this.fcFilterHausnummern.setValue(this.einsatzfilterDTO.hausnummern || []);
    this.fcFilterObjekte.setValue(this.einsatzfilterDTO.objekte || []);

    const usedFilterFields: string[] = [];

    /*
     * Alle Properties durchlaufen und die Attribute, die einen Wert besitzen in "usedFilterFields" schieben,
     * damit zu diesen Attributen die Chip-Inputs erstellt werden.
     */
    for (const filterName of this.filterFieldControlMapping.keys()) {
      const filterValue = this.einsatzfilterDTO[filterName as keyof typeof this.einsatzfilterDTO];
      const filterList = filterValue as string[];
      if (filterList?.length) {
        usedFilterFields.push(filterName);
      } else {
        this.availableFilterFields.push(filterName);
      }
    }
    this.usedFilterFields$.next(usedFilterFields);
    this.selectFirstAvailableFilterField();
  }

  /**
   * Schreibt alle Werte aus den FormControls in das aktuelle EinsatzfilterDTO
   */
  formGroupToDTO(): EinsatzfilterDTO {
    return {
      ...this.einsatzfilterDTO,
      name: this.fcName.value,
      einsatznummern: this.fcFilterEinsatznummern.value,
      einsatzstatus: this.fcFilterEinsatzstatus.value,
      einsatzeroeffnungen: this.fcFilterEinsatzeroeffnungen.value,
      einsatzarten1: this.fcFilterEinsatzarten1.value,
      einsatzstichworte1: this.fcFilterEinsatzstichworte1.value,
      einsatzarten2: this.fcFilterEinsatzarten2.value,
      einsatzstichworte2: this.fcFilterEinsatzstichworte2.value,
      meldebilder: this.fcFilterMeldebilder.value,
      laender: this.fcFilterLaender.value,
      bundeslaender: this.fcFilterBundeslaender.value,
      regierungsbezirke: this.fcFilterRegierungsbezirke.value,
      landkreise: this.fcFilterLandkreise.value,
      kreisfreieStaedte: this.fcFilterKreisfreieStaedte.value,
      verbandsgemeinden: this.fcFilterVerbandsgemeinden.value,
      postleitzahlen: this.fcFilterPostleitzahlen.value,
      gemeinden: this.fcFilterGemeinden.value,
      gemeindeteile: this.fcFilterGemeindeteile.value,
      strassen: this.fcFilterStrassen.value,
      hausnummern: this.fcFilterHausnummern.value,
      objekte: this.fcFilterObjekte.value,
    };
  }

  /**
   * Aktuellen Einsatzfilter speichern. (Entweder create() oder patch())
   */
  save() {
    if (this.fcName.invalid) {
      return;
    }
    this.einsatzfilterDTO = this.formGroupToDTO();

    const lageId = this.fuehrungsebeneService.getCurrentLage()?.id;
    if (!lageId) {
      console.warn('Keine Lage verfügbar, um zu speichern.');
      return;
    }

    if (this.dialogMode === DialogMode.CREATE) {
      this.einsatzfilterDTO.lageId = lageId;
      this.actions$.pipe(ofType(einsatzfilterActions.createEinsatzfilterSuccess), take(1)).subscribe((result) => {
        const outputData: EinsatzfilterDialogOutputData = {
          einsatzfilterDTO: result.createdEinsatzfilterDTO,
        };
        this.dialogRef.close(outputData);
      });
      this.store.dispatch(
        einsatzfilterActions.createEinsatzfilter({ lageId: lageId, einsatzfilterDTO: this.einsatzfilterDTO })
      );
    } else {
      this.actions$.pipe(ofType(einsatzfilterActions.patchEinsatzfilterSuccess), take(1)).subscribe((result) => {
        const outputData: EinsatzfilterDialogOutputData = {
          einsatzfilterDTO: result.patchedEinsatzfilterDTO,
        };
        this.dialogRef.close(outputData);
      });
      this.store.dispatch(
        einsatzfilterActions.patchEinsatzfilter({ lageId: lageId, einsatzfilterDTO: this.einsatzfilterDTO })
      );
    }
  }

  refreshPreviewEinsatzDTOs() {
    this.einsatzResourceService
      .previewEinsaetzeByFilterDTO(this.formGroupToDTO())
      .subscribe((einsaetze) => this.previewEinsatzDTOs$.next(einsaetze));
  }

  getErrorMessage(control: FormControl): string {
    return this.errorService.getErrorMessage(control.errors);
  }
}
