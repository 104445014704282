/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Fuehrungsebenentyp = 'LAGE' | 'SCHADENGEBIET' | 'EINSATZRAUM' | 'EINSATZSTELLE' | 'EINSATZABSCHNITT' | 'UNTERABSCHNITT' | 'LOGISTIK_EINSATZSTELLE' | 'LOGISTIK_EINSATZSTELLE_CHILD' | 'LOGISTIK_EINSATZABSCHNITT' | 'LOGISTIK_EINSATZABSCHNITT_CHILD' | 'BEREITSTELLUNGSRAUM' | 'GRUNDSCHUTZ' | 'OERTLICH' | 'POLITISCH_GESAMTVERANTWORTLICHER' | 'ADMINISTRATIV_ORGANISATORISCH' | 'OPERATIV_TAKTISCH' | 'LEITSTELLE';

export const Fuehrungsebenentyp = {
    Lage: 'LAGE' as Fuehrungsebenentyp,
    Schadengebiet: 'SCHADENGEBIET' as Fuehrungsebenentyp,
    Einsatzraum: 'EINSATZRAUM' as Fuehrungsebenentyp,
    Einsatzstelle: 'EINSATZSTELLE' as Fuehrungsebenentyp,
    Einsatzabschnitt: 'EINSATZABSCHNITT' as Fuehrungsebenentyp,
    Unterabschnitt: 'UNTERABSCHNITT' as Fuehrungsebenentyp,
    LogistikEinsatzstelle: 'LOGISTIK_EINSATZSTELLE' as Fuehrungsebenentyp,
    LogistikEinsatzstelleChild: 'LOGISTIK_EINSATZSTELLE_CHILD' as Fuehrungsebenentyp,
    LogistikEinsatzabschnitt: 'LOGISTIK_EINSATZABSCHNITT' as Fuehrungsebenentyp,
    LogistikEinsatzabschnittChild: 'LOGISTIK_EINSATZABSCHNITT_CHILD' as Fuehrungsebenentyp,
    Bereitstellungsraum: 'BEREITSTELLUNGSRAUM' as Fuehrungsebenentyp,
    Grundschutz: 'GRUNDSCHUTZ' as Fuehrungsebenentyp,
    Oertlich: 'OERTLICH' as Fuehrungsebenentyp,
    PolitischGesamtverantwortlicher: 'POLITISCH_GESAMTVERANTWORTLICHER' as Fuehrungsebenentyp,
    AdministrativOrganisatorisch: 'ADMINISTRATIV_ORGANISATORISCH' as Fuehrungsebenentyp,
    OperativTaktisch: 'OPERATIV_TAKTISCH' as Fuehrungsebenentyp,
    Leitstelle: 'LEITSTELLE' as Fuehrungsebenentyp
};

