/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TaktischesZeichenTyp = 'TAKTISCHE_FORMATION' | 'ANLASS' | 'GEFAHR' | 'MASSNAHME' | 'GEBIET' | 'FAHRZEUG' | 'PERSON' | 'BEFEHLSSTELLE' | 'STELLE' | 'GEBAEUDE' | 'PERSONENSCHADEN' | 'TIERSCHADEN' | 'FOTO';

export const TaktischesZeichenTyp = {
    TaktischeFormation: 'TAKTISCHE_FORMATION' as TaktischesZeichenTyp,
    Anlass: 'ANLASS' as TaktischesZeichenTyp,
    Gefahr: 'GEFAHR' as TaktischesZeichenTyp,
    Massnahme: 'MASSNAHME' as TaktischesZeichenTyp,
    Gebiet: 'GEBIET' as TaktischesZeichenTyp,
    Fahrzeug: 'FAHRZEUG' as TaktischesZeichenTyp,
    Person: 'PERSON' as TaktischesZeichenTyp,
    Befehlsstelle: 'BEFEHLSSTELLE' as TaktischesZeichenTyp,
    Stelle: 'STELLE' as TaktischesZeichenTyp,
    Gebaeude: 'GEBAEUDE' as TaktischesZeichenTyp,
    Personenschaden: 'PERSONENSCHADEN' as TaktischesZeichenTyp,
    Tierschaden: 'TIERSCHADEN' as TaktischesZeichenTyp,
    Foto: 'FOTO' as TaktischesZeichenTyp
};

