<mat-toolbar>
  <h2>{{ headerText }}</h2>

  <button
    mat-icon-button
    (click)="toggleTree()"
    [matTooltip]="'Hierarchie ' + (expandNavTree ? 'zusammenklappen' : 'ausklappen')"
  >
    <mat-icon>{{ expandNavTree ? "unfold_less" : "unfold_more" }}</mat-icon>
  </button>
</mat-toolbar>

<mat-dialog-content>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- Blatt-Element (Führungsebene) im Baum -->
    <mat-tree-node *matTreeNodeDef="let fuehrungsebeneNestedDTO" matTreeNodeToggle>
      <app-fuehrungsebene-icon-and-text
        class="fuehrungsebene-node"
        (click)="selectFuehrungsebene(fuehrungsebeneNestedDTO)"
        [ngClass]="{ 'current-fuehrungsebene': fuehrungsebeneNestedDTO.id === selectedFuehrungsebeneId }"
        [fuehrungsebeneDTO]="fuehrungsebeneNestedDTO"
        iconSize="small"
      />
    </mat-tree-node>

    <!-- Knoten-Element im Baum -->
    <mat-nested-tree-node *matTreeNodeDef="let fuehrungsebeneNestedDTO; when: hasChild">
      <div class="mat-tree-node">
        <!-- Button zum Ein-/Ausklappen -->
        <mat-icon class="toggle-icon" matTreeNodeToggle>
          {{ treeControl.isExpanded(fuehrungsebeneNestedDTO) ? "expand_more" : "chevron_right" }}
        </mat-icon>

        <!-- Knoten-Inhalt (Führungsebene) -->
        <app-fuehrungsebene-icon-and-text
          class="fuehrungsebene-node"
          (click)="selectFuehrungsebene(fuehrungsebeneNestedDTO)"
          [ngClass]="{ 'current-fuehrungsebene': fuehrungsebeneNestedDTO.id === selectedFuehrungsebeneId }"
          [fuehrungsebeneDTO]="fuehrungsebeneNestedDTO"
          iconSize="small"
        />
      </div>

      <!-- Outlet für Children -->
      <div [class.example-tree-invisible]="!treeControl.isExpanded(fuehrungsebeneNestedDTO)" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="accent">Abbrechen</button>
</mat-dialog-actions>
