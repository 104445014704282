import { createReducer, on } from '@ngrx/store';
import { replaceOrKeepCurrent } from 'src/app/shared/dto-version-tools';
import { PersonStateInterface } from './person-state.interface';
import { personActions } from './person.actions';

/**
 * @param loadedLageId enthält die ID der Lage, für die die aktuellen Personen geladen sind, wird IMMER nach dem erfolgreichen Laden gesetzt.
 */
export const initialState: PersonStateInterface = {
  isCreating: false,
  isLoading: false,
  isUpdating: false,
  isUpdatingStatus: false,
  isDeleting: false,
  isPatching: false,
  personen: [],
  loadedLageId: '',
};

export const personReducer = createReducer(
  initialState,

  // Reset Store
  on(personActions.resetStore, () => {
    return { ...initialState };
  }),

  // Get Personen
  on(personActions.getPersonen, (state) => ({ ...state, isLoading: true, errorResponse: undefined })),
  on(personActions.getPersonenSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    personen: action.personen,
    errorResponse: undefined,
    loadedLageId: action.lageId,
  })),
  on(personActions.getPersonenFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorResponse: action.errorResponse,
  })),

  // Create Person
  on(personActions.createPerson, (state) => ({ ...state, isCreating: true, errorResponse: undefined })),
  on(personActions.createPersonSuccess, (state, action) => ({
    ...state,
    isCreating: false,
    personen: [...state.personen, action.newPerson],
    errorResponse: undefined,
  })),
  on(personActions.createPersonFailure, (state, action) => ({
    ...state,
    isCreating: false,
    errorResponse: action.errorResponse,
  })),

  // Patch Person
  on(personActions.patchPerson, (state) => ({ ...state, isPatching: true, errorResponse: undefined })),
  on(personActions.patchPersonSuccess, (state, action) => ({
    ...state,
    isPatching: false,
    // ersetzt bekannte Personen oder fügt Unbekannte neu hinzu
    personen: replaceOrKeepCurrent(state.personen, action.patchedPerson),
    errorResponse: undefined,
  })),
  on(personActions.patchPersonFailure, (state, action) => ({
    ...state,
    isPatching: false,
    errorResponse: action.errorResponse,
  })),

  // Delete Person
  on(personActions.deletePerson, (state) => ({ ...state, isDeleting: true, errorResponse: undefined })),
  on(personActions.deletePersonSuccess, (state, action) => ({
    ...state,
    isDeleting: false,
    personen: state.personen.filter((person) => person.id !== action.deletedPersonDTO.id),
    errorResponse: undefined,
  })),
  on(personActions.deletePersonFailure, (state, action) => ({
    ...state,
    isDeleting: false,
    errorResponse: action.errorResponse,
  }))
);
