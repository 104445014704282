import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable, inject } from '@angular/core';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  private clipboard = inject(Clipboard);
  private snackbarService = inject(SnackbarService);

  /**
   * Kopiert den übergebenen Text in die Zwischenablage und zeigt dem Client eine Info an.
   */
  copy(text: string): void {
    if (text && text.length) {
      this.clipboard.copy(text);
      this.snackbarService.showShort('In Zwischenablage kopiert');
    }
  }
}
