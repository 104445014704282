import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorResponse, FuehrungsebeneMassnahmeResourceService } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { fuehrungsebeneMassnahmeActions } from './fuehrungsebene-massnahme.actions';

@Injectable()
export class FuehrungsebeneMassnahmeEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der FuehrungsebeneMassnahmen anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return fuehrungsebeneMassnahmeActions.getFuehrungsebeneMassnahmen({ lageId: props.currentLage.id });
        } else {
          return fuehrungsebeneMassnahmeActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle FuehrungsebeneMassnahmen einer Lage laden
   */
  getFuehrungsebeneMassnahmen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneMassnahmeActions.getFuehrungsebeneMassnahmen),
      mergeMap((props) =>
        this.fuehrungsebeneMassnahmenResourceService.getFuehrungsebeneMassnahmenByLageId(props.lageId).pipe(
          map((massnahmen) =>
            fuehrungsebeneMassnahmeActions.getFuehrungsebeneMassnahmenSuccess({
              fuehrungsebeneMassnahmeDTOs: massnahmen,
              lageId: props.lageId,
            })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(
              fuehrungsebeneMassnahmeActions.getFuehrungsebeneMassnahmenFailure({
                errorResponse,
              })
            );
          })
        )
      )
    )
  );

  /**
   * Neue FuehrungsebeneMassnahme anlegen
   */
  createFuehrungsebeneMassnahme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneMassnahmeActions.createFuehrungsebeneMassnahme),
      mergeMap((props) => {
        return this.fuehrungsebeneMassnahmenResourceService
          .createFuehrungsebeneMassnahme(props.lageId, props.fuehrungsebeneMassnahmeDTO)
          .pipe(
            map((createdFuehrungsebeneMassnahmeDTO) =>
              fuehrungsebeneMassnahmeActions.createFuehrungsebeneMassnahmeSuccess({
                createdFuehrungsebeneMassnahmeDTO,
              })
            ),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(
                fuehrungsebeneMassnahmeActions.createFuehrungsebeneMassnahmeFailure({
                  errorResponse,
                })
              );
            })
          );
      })
    )
  );

  /**
   * Existierende FuehrungsebeneMassnahme editieren
   */
  patchFuehrungsebeneMassnahme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneMassnahmeActions.patchFuehrungsebeneMassnahme),
      mergeMap((props) => {
        if (!props.fuehrungsebeneMassnahmeDTO.id) {
          return throwError(() => new Error('Maßnahme muss eine Id besitzen.'));
        }
        return this.fuehrungsebeneMassnahmenResourceService
          .patchFuehrungsebeneMassnahme(
            props.lageId,
            props.fuehrungsebeneMassnahmeDTO.id,
            props.fuehrungsebeneMassnahmeDTO
          )
          .pipe(
            map((patchedFuehrungsebeneMassnahmeDTO) =>
              fuehrungsebeneMassnahmeActions.patchFuehrungsebeneMassnahmeSuccess({ patchedFuehrungsebeneMassnahmeDTO })
            ),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(
                fuehrungsebeneMassnahmeActions.patchFuehrungsebeneMassnahmeFailure({
                  errorResponse,
                })
              );
            })
          );
      })
    )
  );

  /**
   * Existierende FuehrungsebeneMassnahme löschen
   */
  deleteMassnahme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneMassnahmeActions.deleteFuehrungsebeneMassnahme),
      mergeMap((props) => {
        if (!props.fuehrungsebeneMassnahmeDTO.id) {
          return throwError(() => new Error('Maßnahme muss eine Id besitzen.'));
        }
        return this.fuehrungsebeneMassnahmenResourceService
          .deleteFuehrungsebeneMassnahme(props.lageId, props.fuehrungsebeneMassnahmeDTO.id)
          .pipe(
            map(() =>
              fuehrungsebeneMassnahmeActions.deleteFuehrungsebeneMassnahmeSuccess({
                deletedFuehrungsebeneMassnahmeDTO: props.fuehrungsebeneMassnahmeDTO,
              })
            ),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(
                fuehrungsebeneMassnahmeActions.deleteFuehrungsebeneMassnahmeFailure({
                  errorResponse,
                })
              );
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private fuehrungsebeneMassnahmenResourceService: FuehrungsebeneMassnahmeResourceService
  ) {}
}
