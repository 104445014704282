import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, throwError } from 'rxjs';
import { ErrorResponse, MeldungDTO, MeldungResourceService } from 'src/app/api/build/openapi';

import { HttpErrorResponse } from '@angular/common/http';
import { meldungActions } from './meldung.actions';

/*
 * Der Store arbeitet synchron.
 * Für den Aufruf von asynchronen Änderungen (z.B. Server-Zugriffen), werden "Effects" verwendet
 *
 * https://ngrx.io/guide/effects
 */

@Injectable()
export class MeldungEffects {
  /**
   * Alle Meldungen einer Lage laden
   */
  getMeldungen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(meldungActions.getMeldungen),
      mergeMap((props) =>
        this.meldungenResourceService.getMeldungenByLageId(props.lageId).pipe(
          map((meldungen) => meldungActions.getMeldungenSuccess({ meldungen })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(meldungActions.getMeldungenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Neue Meldung anlegen
   */
  createMeldung$ = createEffect(() =>
    this.actions$.pipe(
      ofType(meldungActions.createMeldung),
      switchMap((props) =>
        this.meldungenResourceService.createMeldung(props.copy, props.lageId).pipe(
          map((newMeldung) => meldungActions.createMeldungSuccess({ newMeldung })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(meldungActions.createMeldungFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Existierende Meldung editieren
   */
  patchMeldung$ = createEffect(() =>
    this.actions$.pipe(
      ofType(meldungActions.patchMeldung),
      switchMap((props) => {
        if (!props.patchedMeldung.id || !props.patchedMeldung.lageId) {
          return throwError(() => new Error('Meldung muss eine Lage und eine Id besitzen'));
        }
        return this.meldungenResourceService
          .patchMeldung(props.patchedMeldung.lageId, props.patchedMeldung.id, props.patchedMeldung)
          .pipe(
            map((patchedMeldung) => meldungActions.patchMeldungSuccess({ patchedMeldung })),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(meldungActions.patchMeldungFailure({ errorResponse }));
            })
          );
      })
    )
  );

  /**
   * Existierende Meldung löschen
   */
  deleteMeldung$ = createEffect(() =>
    this.actions$.pipe(
      ofType(meldungActions.deleteMeldung),
      map((action) => action.meldung),
      switchMap((meldung: MeldungDTO) => {
        if (!meldung.id || !meldung.lageId) {
          return throwError(() => new Error('Meldung muss eine Lage und eine Id besitzen'));
        }
        return this.meldungenResourceService.deleteMeldung(meldung.lageId, meldung.id).pipe(
          map(() => meldungActions.deleteMeldungSuccess({ deletedMeldungId: meldung.id as string })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(meldungActions.deleteMeldungFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private meldungenResourceService: MeldungResourceService) {}
}
