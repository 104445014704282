/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Sachgebiet = 'S1' | 'S2' | 'S3' | 'S4' | 'S5' | 'S6' | 'FERNMELDEDIENST';

export const Sachgebiet = {
    S1: 'S1' as Sachgebiet,
    S2: 'S2' as Sachgebiet,
    S3: 'S3' as Sachgebiet,
    S4: 'S4' as Sachgebiet,
    S5: 'S5' as Sachgebiet,
    S6: 'S6' as Sachgebiet,
    Fernmeldedienst: 'FERNMELDEDIENST' as Sachgebiet
};

