<app-tz-popup-toolbar [tzDto]="dto" />

<div class="content">
  <app-fuehrungsebene-icon-and-text *ngIf="parentFuehrungsebene" [fuehrungsebeneDTO]="parentFuehrungsebene" />

  <div class="row">
    <div class="links">
      <ng-container *ngIf="dto.tagebuch && dto.tagebuch.length">
        <mat-icon [matBadge]="dto.tagebuch.length" aria-hidden="false">menu_book</mat-icon>
        <span>Tagebuch</span>
      </ng-container>
    </div>

    <img class="zeichen" [src]="dto.dataUrl" />

    <div class="rechts"></div>
  </div>

  <div class="unten"></div>
</div>
