/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Meldungstyp = 'SOFORTMELDUNG' | 'FOLGEMELDUNG' | 'SCHLUSSMELDUNG' | 'SOFORT_UND_SCHLUSSMELDUNG';

export const Meldungstyp = {
    Sofortmeldung: 'SOFORTMELDUNG' as Meldungstyp,
    Folgemeldung: 'FOLGEMELDUNG' as Meldungstyp,
    Schlussmeldung: 'SCHLUSSMELDUNG' as Meldungstyp,
    SofortUndSchlussmeldung: 'SOFORT_UND_SCHLUSSMELDUNG' as Meldungstyp
};

