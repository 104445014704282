import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DialogAction, DialogService } from '@product/ise-dialog-lib';
import { Observable, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneDTO, Fuehrungsebenentyp, LageDTO } from 'src/app/api/build/openapi';
import { fuehrungsebeneActions } from '../+state/fuehrungsebene.actions';
import { isSavingSelector } from '../+state/fuehrungsebene.selectors';
import { LastUpdatedComponent } from '../../../shared/last-updated/last-updated.component';
import { FuehrungsebeneDetailsPanelComponent } from '../fuehrungsebene-details-panel/fuehrungsebene-details-panel.component';
import { FuehrungsebeneService } from '../fuehrungsebene.service';

/**
 * Klasse zum Übergeben eines FuehrungsebeneDTOs in einen FuehrungsebeneDetailsDialog
 */
export interface FuehrungsebeneDialogData {
  fuehrungsebeneDTO: FuehrungsebeneDTO | LageDTO;
  filteredTypes?: Fuehrungsebenentyp[];
}

@Component({
  selector: 'app-fuehrungsebene-details-dialog',
  templateUrl: './fuehrungsebene-details-dialog.component.html',
  styleUrls: ['./fuehrungsebene-details-dialog.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatDialogModule,
    FuehrungsebeneDetailsPanelComponent,
    NgIf,
    MatButtonModule,
    AsyncPipe,
    LastUpdatedComponent,
  ],
})
export class FuehrungsebeneDetailsDialogComponent {
  @ViewChild(FuehrungsebeneDetailsPanelComponent)
  fuehrungsebeneDetailsPanel!: FuehrungsebeneDetailsPanelComponent;

  fuehrungsebeneDTO: FuehrungsebeneDTO;
  protected filteredTypes: Fuehrungsebenentyp[] = [];

  protected isSaving$: Observable<boolean>;
  Fuehrungsebenentyp = Fuehrungsebenentyp;

  private actions$ = inject(Actions);
  private dialogService = inject(DialogService);

  constructor(
    public dialogRef: MatDialogRef<FuehrungsebeneDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: FuehrungsebeneDialogData,
    private store: Store<AppStateInterface>,
    protected fuehrungsebeneService: FuehrungsebeneService // private actions$: Actions
  ) {
    this.fuehrungsebeneDTO = data.fuehrungsebeneDTO;
    this.filteredTypes = data.filteredTypes || [];

    this.isSaving$ = store.select(isSavingSelector).pipe(takeUntilDestroyed());
    dialogRef.updateSize('60%');
  }

  save(): void {
    if (this.fuehrungsebeneDetailsPanel.validate()) {
      this.fuehrungsebeneDetailsPanel.formGroupToModel();

      /**
       * Beim Speichern wird isSaving im Store auf true gesetzt.
       * Nachdem Speichern abgeschlossen, Fenster schließen.
       */
      this.isSaving$.subscribe((isSaving) => {
        if (!isSaving) {
          this.dialogRef.close(this.fuehrungsebeneDTO);
        }
      });

      if (this.fuehrungsebeneDTO.id) {
        this.store.dispatch(fuehrungsebeneActions.patchFuehrungsebene({ fuehrungsebene: this.fuehrungsebeneDTO }));
      } else {
        this.store.dispatch(fuehrungsebeneActions.createFuehrungsebene({ fuehrungsebene: this.fuehrungsebeneDTO }));
      }
    }
  }

  /**
   * Abfrage, ob die aktuelle Führungsebene gelöscht werden soll.
   */
  askDelete() {
    if (this.fuehrungsebeneDTO) {
      this.dialogService
        .openConfirmDialog(
          'Führungsebene löschen?',
          'Mit dem Löschen der Führungsebene werden darunterliegende Führungsebenen sowie darin befindliche Taktische Zeichen gelöscht. Das Löschen kann nicht rückgängig gemacht werden.',
          'delete'
        )
        .afterClosed()
        .subscribe((result: DialogAction) => {
          if (result === DialogAction.OK) {
            this.actions$.pipe(ofType(fuehrungsebeneActions.deleteFuehrungsebeneSuccess), take(1)).subscribe(() => {
              this.dialogRef.close();
            });
            this.store.dispatch(fuehrungsebeneActions.deleteFuehrungsebene({ fuehrungsebene: this.fuehrungsebeneDTO }));
          }
        });
    }
  }
}
