import { createSelector } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState.fahrzeuge;

export const fahrzeugeSelector = createSelector(selectFeature, (state) => state.fahrzeuge);
export const fahrzeugeErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingFahrzeugeSelector = createSelector(selectFeature, (state) => state.isLoading);
export const fahrzeugeLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const fahrzeugeOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.fahrzeuge.filter((fahrzeug) => fahrzeug.fuehrungsebeneId === fuehrungsebeneId)
  );

export const fahrzeugByIdSelector = (fahrzeugId?: string) =>
  createSelector(selectFeature, (state) => state.fahrzeuge.find((fahrzeug) => fahrzeug.id === fahrzeugId));

/**
 * Fahrzeuge, die keine Vorlage bzw. unavailable sind
 */
export const actualFahrzeugeSelector = createSelector(selectFeature, (state) =>
  state.fahrzeuge.filter((fahrzeug) => !fahrzeug.vorlage && !fahrzeug.unavailable)
);
