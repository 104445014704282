import {
  ApplicationRef,
  ComponentRef,
  DestroyRef,
  EnvironmentInjector,
  Injectable,
  Injector,
  createComponent,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as L from 'leaflet';
import { delay } from 'rxjs';
import {
  EinsatzDTO,
  FuehrungsebeneDTO,
  FuehrungsebeneVisibilityNode,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { EinsatzPopupComponent } from 'src/app/einsatz/einsatz-popup/einsatz-popup.component';
import { EinsatzService } from 'src/app/einsatz/einsatz.service';
import { ComponentQualificationService } from 'src/app/misc/component-qualification.service';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { FuehrungsebeneHierarchieService } from '../fuehrungsebene-hierarchie/fuehrungsebene-hierarchie.service';
import { FuehrungsebenePopupComponent } from '../fuehrungsebene/fuehrungsebene-popup/fuehrungsebene-popup.component';
import { LayerService } from '../layer.service';
import { AnlassPopupComponent } from '../taktische-zeichen/anlass-ereignis/anlass-popup/anlass-popup.component';
import { BefehlsstellePopupComponent } from '../taktische-zeichen/befehlsstelle/befehlsstelle-popup/befehlsstelle-popup.component';
import { FahrzeugPopupComponent } from '../taktische-zeichen/fahrzeuge/fahrzeug-popup/fahrzeug-popup.component';
import { FotoPopupComponent } from '../taktische-zeichen/foto/foto-popup/foto-popup.component';
import { GebaeudePopupComponent } from '../taktische-zeichen/gebaeude/gebaeude-popup/gebaeude-popup.component';
import { GebietPopupComponent } from '../taktische-zeichen/gebiete/gebiet-popup/gebiet-popup.component';
import { GefahrPopupComponent } from '../taktische-zeichen/gefahren/gefahr-popup/gefahr-popup.component';
import { MassnahmePopupComponent } from '../taktische-zeichen/massnahmen/massnahme-popup/massnahme-popup.component';
import { PersonPopupComponent } from '../taktische-zeichen/personen/person-popup/person-popup.component';
import { PersonenschadenPopupComponent } from '../taktische-zeichen/personenschaden/personenschaden-popup/personenschaden-popup.component';
import { StellePopupComponent } from '../taktische-zeichen/stelle-einrichtung/stelle-popup/stelle-popup.component';
import { TaktischeFormationPopupComponent } from '../taktische-zeichen/taktische-formation/taktische-formation-popup/taktische-formation-popup.component';
import { TaktischesZeichenPopup } from '../taktische-zeichen/taktische-zeichen.interface';
import { TierschadenPopupComponent } from '../taktische-zeichen/tierschaden/tierschaden-popup/tierschaden-popup.component';

/**
 * Handler, der sich um das Zeichnen der Führungsebenen kümmert.
 */
@Injectable({
  providedIn: 'root',
})
export class MapFuehrungsebeneRenderer {
  private destroyRef = inject(DestroyRef);
  // Karte, auf der Objekte angelegt werden
  private map: L.Map | null = null;

  private visibilityRootNode?: FuehrungsebeneVisibilityNode;
  /**
   * Mapping von FührungsebeneId zu zugehörigem TreeNode und somit den Sichtbarkeiten der darunterliegenden Führungsebenen und TZs.
   */
  private nodeLayerGroupMapping: Map<string, L.LayerGroup> = new Map();

  // Dynamisch erzeugte Elemente/Komponenten für Popups
  private nodePopupElementMapping: Map<string, HTMLElement[]> = new Map();
  private nodePopupComponentMapping: Map<string, ComponentRef<TaktischesZeichenPopup>[]> = new Map();

  private einsatzService = inject(EinsatzService);
  private layerService = inject(LayerService);
  private hierarchieService = inject(FuehrungsebeneHierarchieService);
  private taktischeZeichenService = inject(TaktischeZeichenService);
  private componentService = inject(ComponentQualificationService);

  private showDevelopment = false;

  constructor(
    private injector: Injector,
    private environmentInjector: EnvironmentInjector,
    private applicationRef: ApplicationRef
  ) {
    this.hierarchieService.fuehrungsebeneTree$.pipe(takeUntilDestroyed()).subscribe((node) => {
      // Wenn sich der gesamte Baum ändert, den aktuellen Baum von der Karte entfernen
      if (this.visibilityRootNode) {
        this.removeNode(this.visibilityRootNode, true);
      }

      // Neuen Baum setzen und neuzeichnen
      this.visibilityRootNode = node;
      if (this.visibilityRootNode) {
        this.redrawNode(this.visibilityRootNode, true);
      }
    });

    this.hierarchieService.nodeChanged$.pipe(takeUntilDestroyed()).subscribe((node) => {
      // Wenn sich ein Knoten ändert, diesen neu zeichnen
      this.redrawNode(node, false);
    });

    this.hierarchieService.nodeDeleted$.pipe(takeUntilDestroyed()).subscribe((node) => {
      // Wenn ein Knoten gelöscht wurde, diesen und alle darunterliegenden Knoten von der Karte entfernen
      this.removeNode(node, true);
    });

    this.componentService.isShowDevelopment$.pipe(takeUntilDestroyed()).subscribe((showDevelopment) => {
      this.showDevelopment = showDevelopment;
      if (this.visibilityRootNode) {
        this.redrawNode(this.visibilityRootNode, true);
      }
    });
  }

  init(map: L.Map) {
    this.map = map;
    this.hierarchieService.fetchFuehrungsebeneVisibilityTree();
  }

  /**
   * LayerGroup von der Karte entfernen und neu erzeugen
   */
  private resetNodeLayerGroup(node: FuehrungsebeneVisibilityNode) {
    const fuehrungsebeneId = node.fuehrungsebeneDTO.id;
    if (!fuehrungsebeneId) {
      console.warn('Keine Führungsebene vorhanden');
      return;
    }

    // LayerGroup von der Karte löschen
    this.removeNodeLayerGroup(node);

    // LayerGroup anlegen, im Mapping hinterlegen und auf die Karte bringen
    const layerGroup = new L.LayerGroup();
    this.map?.addLayer(layerGroup);
    this.nodeLayerGroupMapping.set(fuehrungsebeneId, layerGroup);
  }

  /**
   * Löscht eine Layergroup mit allen Elementen von der Karte und aus dem Mapping.
   */
  private removeNodeLayerGroup(node: FuehrungsebeneVisibilityNode) {
    const fuehrungsebeneId = node.fuehrungsebeneDTO.id;
    if (!fuehrungsebeneId) {
      console.warn('Keine Führungsebene vorhanden');
      return;
    }

    // LayerGroup für Node neu anlegen oder leeren, damit neugezeichnet werden kann
    const layerGroup = this.nodeLayerGroupMapping.get(fuehrungsebeneId);
    if (layerGroup) {
      this.map?.removeLayer(layerGroup);
      layerGroup.eachLayer((layer) => layer.clearAllEventListeners());
      layerGroup.clearLayers();
      this.nodeLayerGroupMapping.delete(fuehrungsebeneId);
    }
  }

  /**
   * Entfernt Popups eines VisibilityNodes von der Karte und befüllt das Mapping mit Default-Einträgen.
   */
  private resetNodePopups(node: FuehrungsebeneVisibilityNode) {
    this.removeNodePopups(node);

    const fuehrungsebeneId = node.fuehrungsebeneDTO.id;
    if (!fuehrungsebeneId) {
      console.warn('Keine Führungsebene vorhanden');
      return;
    }

    this.nodePopupElementMapping.set(fuehrungsebeneId, []);
    this.nodePopupComponentMapping.set(fuehrungsebeneId, []);
  }

  /**
   * Entfernt Popups eines VisibilityNodes von der Karte und löscht die Einträge aus dem Mapping.
   */
  private removeNodePopups(node: FuehrungsebeneVisibilityNode) {
    const fuehrungsebeneId = node.fuehrungsebeneDTO.id;
    if (!fuehrungsebeneId) {
      console.warn('Keine Führungsebene vorhanden');
      return;
    }

    const popupElements = this.nodePopupElementMapping.get(fuehrungsebeneId);
    if (popupElements) {
      popupElements.forEach((element) => element.remove());
      this.nodePopupElementMapping.delete(fuehrungsebeneId);
    }

    const popupComponents = this.nodePopupComponentMapping.get(fuehrungsebeneId);
    if (popupComponents) {
      popupComponents.forEach((component) => component.destroy());
      this.nodePopupComponentMapping.delete(fuehrungsebeneId);
    }
  }

  /**
   * Löscht einen Knoten (ggf. rekursiv) von der Karte und aus allen Mappings
   */
  private removeNode(node: FuehrungsebeneVisibilityNode, recursively: boolean) {
    this.removeNodePopups(node);
    this.removeNodeLayerGroup(node);

    if (recursively) {
      node.mainTypeChildNodes.forEach((n) => this.removeNode(n, recursively));
      node.bereitstellungsraumChildNodes.forEach((n) => this.removeNode(n, recursively));
      node.grundschutzChildNodes.forEach((n) => this.removeNode(n, recursively));
      node.oertlichChildNodes.forEach((n) => this.removeNode(n, recursively));
    }
  }

  /**
   * Zeichnet Führungsebenen und Taktische Zeichen eines Knotens (ggf. rekursiv) auf die Karte.
   */
  private redrawNode(node: FuehrungsebeneVisibilityNode, recursively: boolean) {
    this.resetNodePopups(node);
    this.resetNodeLayerGroup(node);

    const fuehrungsebeneId = node.fuehrungsebeneDTO.id;
    if (!fuehrungsebeneId) {
      console.warn('Keine Führungsebene vorhanden');
      return;
    }

    const layerGroup = this.nodeLayerGroupMapping.get(fuehrungsebeneId);
    if (!layerGroup) {
      console.warn('Konnte LayerGroup nicht vorbereiten');
      return;
    }

    // Alle TZs der Node-Führungsebene zeichnen
    if (node.showAnlaesse) {
      node.anlassDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showBefehlsstellen) {
      node.befehlsstelleDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showFahrzeuge) {
      node.fahrzeugDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showFotos) {
      node.fotoDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showGebaeude) {
      node.gebaeudeDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showGebiete) {
      node.gebietDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showGefahren) {
      node.gefahrDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showMassnahmen) {
      node.massnahmeDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showPersonen) {
      node.personDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showPersonenschaeden) {
      node.personenschadenDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showStellen) {
      node.stelleDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showTaktischeFormationen) {
      node.taktischeFormationDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    if (node.showTierschaeden) {
      node.tierschadenDTOs.forEach((dto) => this.drawTaktischesZeichen(dto, layerGroup));
    }

    // Geometrie der Node-Führungsebene zeichnen
    if (node.showGeometry) {
      this.drawFuehrungsebene(node.fuehrungsebeneDTO, layerGroup, node.highlighted);
    }

    // Spezial-Führungsebenen und deren TZs zeichnen
    if (node.showPolitischGesamtverantwortlich && node.politischGesamtverantwortlichDTO) {
      this.drawFuehrungsebene(node.politischGesamtverantwortlichDTO, layerGroup);
    }

    if (node.showAdministrativOrganisatorisch && node.administativOrganisatorischDTO) {
      this.drawFuehrungsebene(node.administativOrganisatorischDTO, layerGroup);
    }

    if (node.showOperativTaktisch && node.operativTaktischDTO) {
      this.drawFuehrungsebene(node.operativTaktischDTO, layerGroup);
    }

    if (node.showLeitstelle && node.leitstelleDTO) {
      this.drawFuehrungsebene(node.leitstelleDTO, layerGroup);
    }

    node.bereitstellungsraumChildNodes.forEach((br) => {
      if (node.showBereitstellungsraeume) {
        this.redrawNode(br, false);
      } else {
        this.removeNode(br, false);
      }
    });

    node.grundschutzChildNodes.forEach((gs) => {
      if (node.showGrundschutz) {
        this.redrawNode(gs, false);
      } else {
        this.removeNode(gs, false);
      }
    });

    node.oertlichChildNodes.forEach((oe) => {
      if (node.showOertlich) {
        this.redrawNode(oe, false);
      } else {
        this.removeNode(oe, false);
      }
    });

    if (node.showEinsaetze && this.showDevelopment) {
      node.einsatzDtos.forEach((einsatzDto) => this.drawEinsatz(einsatzDto, node.fuehrungsebeneDTO, layerGroup));
    }

    if (recursively) {
      node.mainTypeChildNodes.forEach((child) => this.redrawNode(child, true));
    }
  }

  /**
   * Zeichnet eine Führungsebene auf die Karte
   */
  private drawFuehrungsebene(fuehrungsebeneDTO: FuehrungsebeneDTO, layerGroup: L.LayerGroup, highlighted?: boolean) {
    if (!fuehrungsebeneDTO.geometry) {
      return;
    }

    const geometryStyle = this.layerService.getFuehrungsebeneGeometryStyle(fuehrungsebeneDTO, highlighted || false);
    if (!geometryStyle) {
      return;
    }

    const geometry = fuehrungsebeneDTO.geometry as GeoJSON.Geometry;

    let marker: L.Marker | undefined;
    if (geometry.type == 'Polygon') {
      const polygon = L.geoJSON(geometry, geometryStyle.polygonOptions);
      layerGroup.addLayer(polygon);
      marker = L.marker(polygon.getBounds().getCenter(), geometryStyle.markerOptions);
      layerGroup.addLayer(marker);

      // Führungsebenen (außer dem Aktuellen) beim Hovern highlighten
      if (!highlighted) {
        polygon.on('mouseover', () => {
          if (marker) {
            this.toggleFuehrungsgebenePolygonHighlight(polygon, marker, fuehrungsebeneDTO, true);
          }
        });
        polygon.on('mouseout', () => {
          if (marker) {
            this.toggleFuehrungsgebenePolygonHighlight(polygon, marker, fuehrungsebeneDTO, false);
          }
        });
        marker.on('mouseover', () => {
          if (marker) {
            this.toggleFuehrungsgebenePolygonHighlight(polygon, marker, fuehrungsebeneDTO, true);
          }
        });
        marker.on('mouseout', () => {
          if (marker) {
            this.toggleFuehrungsgebenePolygonHighlight(polygon, marker, fuehrungsebeneDTO, false);
          }
        });
      }
    } else if (geometry.type === 'Point') {
      marker = L.marker(L.latLng(geometry.coordinates[1], geometry.coordinates[0]), geometryStyle.markerOptions);
      layerGroup.addLayer(marker);

      // Führungsebenen (außer dem Aktuellen) beim Hovern highlighten
      if (!highlighted) {
        marker.on('mouseover', () => {
          if (marker) {
            this.toggleFuehrungsebeneMarkerHighlight(marker, fuehrungsebeneDTO, true);
          }
        });
        marker.on('mouseout', () => {
          if (marker) {
            this.toggleFuehrungsebeneMarkerHighlight(marker, fuehrungsebeneDTO, false);
          }
        });
      }
    }

    if (marker) {
      this.addFuehrungsebenePopup(fuehrungsebeneDTO, marker);
    }
    marker?.bindTooltip(fuehrungsebeneDTO.name || '', { direction: 'top' });
  }

  /**
   * Zeichnet ein Taktisches Zeichen auf die Karte
   */
  private drawEinsatz(einsatzDto: EinsatzDTO, fuehrungsebeneDto: FuehrungsebeneDTO, layerGroup: L.LayerGroup) {
    if (!einsatzDto.koordinate) {
      return;
    }

    const koordinate = einsatzDto.koordinate as GeoJSON.Point;
    const markerOptions = this.layerService.generateMarkerIconOption(this.einsatzService.EINSATZ_ICON || '');
    const tzTooltip = `[ELS Einsatz] ${einsatzDto.einsatznummer || ''}`;
    const pointMarker = L.marker(L.latLng(koordinate.coordinates[1], koordinate.coordinates[0]), markerOptions);
    pointMarker?.bindTooltip(tzTooltip, { direction: 'top' });
    this.addEinsatzPopup(einsatzDto, fuehrungsebeneDto, pointMarker);
    layerGroup.addLayer(pointMarker);
  }

  /**
   * Zeichnet ein Taktisches Zeichen auf die Karte
   */
  private drawTaktischesZeichen(tzDTO: TaktischesZeichenDTO, layerGroup: L.LayerGroup) {
    if (!tzDTO.geometry) {
      return;
    }

    const geometry = tzDTO.geometry as GeoJSON.Geometry;

    const markerOptions = this.layerService.generateMarkerIconOption(tzDTO.dataUrl || '');

    const tzTooltip = `[${this.taktischeZeichenService.taktischeZeichenInfoMapping.get(tzDTO.typ)?.name}] ${
      tzDTO.anzeigename || ''
    }`;

    switch (geometry.type) {
      case 'Point': {
        const point = tzDTO.geometry as GeoJSON.Point;
        const pointMarker = L.marker(L.latLng(point.coordinates[1], point.coordinates[0]), markerOptions);
        pointMarker?.bindTooltip(tzTooltip, { direction: 'top' });
        this.addTaktischesZeichenPopup(tzDTO, pointMarker);
        layerGroup.addLayer(pointMarker);
        break;
      }
      case 'LineString': {
        const lineString = tzDTO.geometry as GeoJSON.LineString;
        // Polyline
        const polyline = L.polyline(
          lineString.coordinates.map((position) => L.latLng(position[1], position[0])),
          this.layerService.generatePolylineOption()
        );
        layerGroup.addLayer(polyline);

        // Marker für Polyline
        const lineMarker = L.marker(polyline.getBounds().getCenter(), markerOptions);
        lineMarker?.bindTooltip(tzTooltip, { direction: 'top' });
        this.addTaktischesZeichenPopup(tzDTO, lineMarker);
        layerGroup.addLayer(lineMarker);
        break;
      }
      case 'Polygon': {
        // Polygon
        const polygon = L.geoJSON(geometry, this.layerService.generateTzPolygonOption(tzDTO));
        layerGroup.addLayer(polygon);

        // Marker für Polygon
        const polygonMarker = L.marker(polygon.getBounds().getCenter(), markerOptions);
        polygonMarker?.bindTooltip(tzTooltip, { direction: 'top' });
        this.addTaktischesZeichenPopup(tzDTO, polygonMarker);
        layerGroup.addLayer(polygonMarker);
        break;
      }
    }
  }

  /**
   * Fügt Border zu Marker hinzu oder entfernt sie, jenachdem ob der Marker gehighlightet werden soll oder nicht.
   */
  private toggleFuehrungsebeneMarkerHighlight(
    marker: L.Marker,
    fuehrungsebeneDTO: FuehrungsebeneDTO,
    highlight: boolean
  ) {
    const icon = this.layerService.generateFuehrungsebeneMarkerIconOption(fuehrungsebeneDTO, highlight).icon;
    if (icon) {
      marker?.setIcon(icon);
    }
  }

  /**
   * Erhöht/verringert die Strichstärke des Polygons jenachdem ob es gehighlightet werden soll oder nicht.
   * Highlighted außerdem den zugehörigen Marker
   */
  private toggleFuehrungsgebenePolygonHighlight(
    polygon: L.GeoJSON,
    marker: L.Marker,
    fuehrungsebeneDTO: FuehrungsebeneDTO,
    highlight: boolean
  ) {
    this.toggleFuehrungsebeneMarkerHighlight(marker, fuehrungsebeneDTO, highlight);
    polygon.setStyle(this.layerService.generatePolygonOption(highlight));
  }

  private addEinsatzPopup(einsatzDto: EinsatzDTO, fuehrungsebeneDto: FuehrungsebeneDTO, marker: L.Marker) {
    if (!fuehrungsebeneDto.id) {
      console.warn('Popups können nicht erstellt werden. Einsatz besitzt keine Führungsebene');
      return;
    }

    const popupElements = this.nodePopupElementMapping.get(fuehrungsebeneDto.id);
    const popupComponents = this.nodePopupComponentMapping.get(fuehrungsebeneDto.id);
    if (!popupElements || !popupComponents) {
      console.warn('PopupElements/Components wurden nicht gefunden');
      return;
    }

    const element = document.createElement('div');
    const component = createComponent(EinsatzPopupComponent, {
      elementInjector: this.injector,
      environmentInjector: this.environmentInjector,
      hostElement: element,
    });
    if (component) {
      popupElements.push(element);
      popupComponents.push(component);
    } else {
      element.remove();
      return;
    }
    component.instance.einsatzDto = einsatzDto;
    component.instance.parentFuehrungsebeneDto = fuehrungsebeneDto;

    this.applicationRef.attachView(component.hostView);

    // Popup erzeugen und an Marker binden
    const popup = L.popup({
      closeButton: false,
      content: element,
    });
    marker.bindPopup(popup);
  }

  /**
   * Fügt ein Popup zu einem Marker hinzu. (Aktuell nur für Personenschaden implementiert)
   */
  private addTaktischesZeichenPopup(tzDto: TaktischesZeichenDTO, marker: L.Marker) {
    if (!tzDto.fuehrungsebeneId) {
      console.warn('Popups können nicht erstellt werden. TZ besitzt keine Führungsebene');
      return;
    }
    const popupElements = this.nodePopupElementMapping.get(tzDto.fuehrungsebeneId);
    const popupComponents = this.nodePopupComponentMapping.get(tzDto.fuehrungsebeneId);

    if (!popupElements || !popupComponents) {
      console.warn('PopupElements/Components wurden nicht gefunden');
      return;
    }

    if (tzDto.typ === TaktischesZeichenTyp.Foto) {
      // Dynamsche Erzeugung der FotoPopup Component
      const element = document.createElement('div');
      const component = createComponent(FotoPopupComponent, {
        elementInjector: this.injector,
        environmentInjector: this.environmentInjector,
        hostElement: element,
      });
      popupElements.push(element);
      popupComponents.push(component);
      this.applicationRef.attachView(component.hostView);
      component.instance.fotoPreviewDTO = tzDto;
      // Popup schließen, wenn aus dem Popup heraus der Dialog zur größeren Anzeige geöffnet wird
      component.instance.actionExecuted.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => marker.closePopup());
      // Popup nochmal neu öffnen, nachdem Foto geladen wurde, damit Popup neu positioniert wird.
      // (Sonst ist es um die halbe Foto-Breite verschoben und zeigt nicht auf das TZ)
      component.instance.fotoLoaded
        .pipe(takeUntilDestroyed(this.destroyRef), delay(20))
        .subscribe(() => marker.openPopup());

      // Popup erzeugen und an Marker binden
      const popup = L.popup({
        closeButton: false,
        content: element,
      });

      marker.bindPopup(popup);
      marker.on('popupopen', () => {
        component.instance.loadFoto();
      });
    } else {
      // Dynamsche Erzeugung der Popup Component
      const element = document.createElement('div');
      const component = this.createPopupComponent(tzDto.typ, element);
      if (component) {
        popupElements.push(element);
        popupComponents.push(component);
      } else {
        element.remove();
        return;
      }
      component.instance.dto = tzDto;
      this.applicationRef.attachView(component.hostView);

      // Popup erzeugen und an Marker binden
      const popup = L.popup({
        closeButton: false,
        content: element,
      });
      marker.bindPopup(popup);
    }
  }

  private createPopupComponent(taktischesZeichenTyp: TaktischesZeichenTyp, element: HTMLDivElement) {
    const options = {
      elementInjector: this.injector,
      environmentInjector: this.environmentInjector,
      hostElement: element,
    };

    switch (taktischesZeichenTyp) {
      case TaktischesZeichenTyp.Anlass:
        return createComponent(AnlassPopupComponent, options);
      case TaktischesZeichenTyp.Befehlsstelle:
        return createComponent(BefehlsstellePopupComponent, options);
      case TaktischesZeichenTyp.Fahrzeug:
        return createComponent(FahrzeugPopupComponent, options);
      case TaktischesZeichenTyp.Gebaeude:
        return createComponent(GebaeudePopupComponent, options);
      case TaktischesZeichenTyp.Gebiet:
        return createComponent(GebietPopupComponent, options);
      case TaktischesZeichenTyp.Gefahr:
        return createComponent(GefahrPopupComponent, options);
      case TaktischesZeichenTyp.Massnahme:
        return createComponent(MassnahmePopupComponent, options);
      case TaktischesZeichenTyp.Person:
        return createComponent(PersonPopupComponent, options);
      case TaktischesZeichenTyp.Personenschaden:
        return createComponent(PersonenschadenPopupComponent, options);
      case TaktischesZeichenTyp.Stelle:
        return createComponent(StellePopupComponent, options);
      case TaktischesZeichenTyp.TaktischeFormation:
        return createComponent(TaktischeFormationPopupComponent, options);
      case TaktischesZeichenTyp.Tierschaden:
        return createComponent(TierschadenPopupComponent, options);
      default:
        return undefined;
    }
  }

  /**
   * Fügt ein Kontextmenü als Popup zu einem Marker hinzu, um mit einer Führungsebene auf der Karte interagieren zu können.
   */
  private addFuehrungsebenePopup(fuehrungsebeneDTO: FuehrungsebeneDTO, marker: L.Marker) {
    if (!fuehrungsebeneDTO.id) {
      console.warn('Popups können nicht erstellt werden. Führungsebene besitzt keine Id');
      return;
    }

    // Dynamsche Erzeugung der Führungsebene-Popup Component
    const element = document.createElement('div');
    const component = createComponent(FuehrungsebenePopupComponent, {
      elementInjector: this.injector,
      environmentInjector: this.environmentInjector,
      hostElement: element,
    });
    this.nodePopupElementMapping.get(fuehrungsebeneDTO.id)?.push(element);
    this.nodePopupComponentMapping.get(fuehrungsebeneDTO.id)?.push(component);
    this.applicationRef.attachView(component.hostView);
    component.instance.fuehrungsebeneDTO = fuehrungsebeneDTO;
    component.instance.actionExecuted.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => marker.closePopup());

    // Popup erzeugen und an Marker binden
    marker.bindPopup(
      L.popup({
        closeButton: false,
        content: element,
        offset: L.point(0, -5),
      })
    );
  }
}
