<mat-toolbar>
  <h2>Einsatz anzeigen</h2>
</mat-toolbar>

<div class="content">
  <form class="flex-col">
    <h3>Einsatzinfos</h3>

    <span class="flex-row gap-1">
      <mat-form-field class="flex-1">
        <mat-label>Einsatznummer</mat-label>
        <input matInput [formControl]="fcEinsatznummer" readonly />
      </mat-form-field>

      <mat-form-field class="flex-1">
        <mat-label>Muttereinsatznummer</mat-label>
        <input matInput [formControl]="fcMuttereinsatznummer" readonly />
      </mat-form-field>
    </span>

    <span class="flex-row gap-1">
      <mat-form-field class="flex-1">
        <mat-label>Einsatzstatus</mat-label>
        <input matInput [formControl]="fcEinsatzstatus" readonly />
      </mat-form-field>

      <mat-form-field class="flex-1">
        <mat-label>Einsatzbeginn</mat-label>
        <input matInput [formControl]="fcEinsatzeroeffnung" type="datetime-local" dateTimeLocalAsDate readonly />
      </mat-form-field>
    </span>
  </form>

  <!-- Einsatzort -->
  <app-einsatzort-form [einsatzOrtDTO]="einsatzDTO?.einsatzort" />

  <h3>Anlass</h3>
  <span class="flex-row gap-1">
    <mat-form-field class="flex-1">
      <mat-label>Einsatzart 1</mat-label>
      <input matInput [formControl]="fcEinsatzart1" readonly />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Stichwort 1</mat-label>
      <input matInput [formControl]="fcEinsatzstichwort1" readonly />
    </mat-form-field>
  </span>

  <span class="flex-row gap-1">
    <mat-form-field class="flex-1">
      <mat-label>Einsatzart 2</mat-label>
      <input matInput [formControl]="fcEinsatzart2" readonly />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Stichwort 2</mat-label>
      <input matInput [formControl]="fcEinsatzstichwort2" readonly />
    </mat-form-field>
  </span>

  <mat-form-field class="flex-1">
    <mat-label>Meldebild</mat-label>
    <input matInput [formControl]="fcMeldebild" readonly />
  </mat-form-field>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="accent">Schließen</button>
</mat-dialog-actions>
