<div class="container" cdkDropListGroup>
  <!-- <div class="side_box">
    <div class="spacer"></div>
    <app-zeichen-leiste [id]="'L'" [direction]="'column'"></app-zeichen-leiste>
    <div class="spacer"></div>
  </div>
  <div class="center_box">
    <app-zeichen-leiste [id]="'T'" [direction]="'row'"></app-zeichen-leiste> -->
  <div id="map" class="map">
    <!-- Unsichtbarer Trigger für das ContextMenu. Gibt die Position vor, an der das ContextMenu geöffnet wird -->
    <div
      style="visibility: hidden; position: fixed"
      [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y"
      [matMenuTriggerFor]="contextMenu"
    ></div>
  </div>
  <!-- <app-zeichen-leiste [id]="'B'" [direction]="'row'"></app-zeichen-leiste> -->
  <mat-card class="tool-hint" *ngIf="toolService.showToolHint$ | async"
    >Werkzeugnutzung mit Rechtsklick abschließen</mat-card
  >
  <!-- </div> -->
  <!-- <div class="side_box">
    <div class="spacer"></div>
    <app-zeichen-leiste [id]="'R'" [direction]="'column'"></app-zeichen-leiste>
    <div class="spacer"></div>
  </div> -->
</div>

<mat-menu id="context-menu" #contextMenu hasBackdrop="false">
  <button mat-menu-item [matMenuTriggerFor]="tzMenu">Taktisches Zeichen anlegen</button>
  <button mat-menu-item (click)="copyCoordinateToClipboard()">Koordinate kopieren</button>
</mat-menu>

<mat-menu id="tz-menu" #tzMenu>
  @for (tzTyp of taktischeZeichenInfoMapping.keys(); track $index) {
  <button mat-menu-item (click)="openTzDialog(tzTyp)">
    <div class="tz-item">
      <img class="zeichen-icon" [src]="taktischeZeichenInfoMapping.get(tzTyp)?.icon" />
      <span>{{ taktischeZeichenInfoMapping.get(tzTyp)?.name }}</span>
    </div>
  </button>
  }
</mat-menu>

<svg class="hidden-svg">
  <defs>
    <!-- Horizontale Streifen -->
    <pattern
      id="striped-horizontal-red"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(90)"
    >
      <line stroke="red" stroke-width="20px" y2="20" />
    </pattern>

    <pattern
      id="striped-horizontal-magenta"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(90)"
    >
      <line stroke="magenta" stroke-width="20px" y2="20" />
    </pattern>

    <pattern
      id="striped-horizontal-brown"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(90)"
    >
      <line stroke="brown" stroke-width="20px" y2="20" />
    </pattern>

    <pattern
      id="striped-horizontal-blue"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(90)"
    >
      <line stroke="blue" stroke-width="20px" y2="20" />
    </pattern>

    <pattern
      id="striped-horizontal-orange"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(90)"
    >
      <line stroke="orange" stroke-width="20px" y2="20" />
    </pattern>

    <pattern
      id="striped-horizontal-yellow"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(90)"
    >
      <line stroke="yellow" stroke-width="20px" y2="20" />
    </pattern>

    <!-- Diagonale Streifen -->
    <pattern
      id="striped-diagonal-red"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45)"
    >
      <line stroke="#ff0000" stroke-width="20px" y2="20" />
    </pattern>

    <pattern
      id="striped-diagonal-magenta"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45)"
    >
      <line stroke="magenta" stroke-width="20px" y2="20" />
    </pattern>

    <pattern
      id="striped-diagonal-brown"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45)"
    >
      <line stroke="brown" stroke-width="20px" y2="20" />
    </pattern>

    <pattern
      id="striped-diagonal-blue"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45)"
    >
      <line stroke="blue" stroke-width="20px" y2="20" />
    </pattern>

    <pattern
      id="striped-diagonal-orange"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45)"
    >
      <line stroke="orange" stroke-width="20px" y2="20" />
    </pattern>

    <pattern
      id="striped-diagonal-yellow"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45)"
    >
      <line stroke="yellow" stroke-width="20px" y2="20" />
    </pattern>
  </defs>
</svg>
