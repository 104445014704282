<mat-card class="fuehrungsebene-container">
  <mat-toolbar class="image-header">
    <!-- TZ-Icon der Führungsebene und Name -->
    <span class="icon-and-name" *ngIf="fuehrungsebeneDTO">
      <img class="icon" [src]="fuehrungsebeneService.getFuehrungsebeneIcon(fuehrungsebeneDTO)" />
      <h3
        class="name"
        [matTooltip]="fuehrungsebeneDTO.name"
        [ngClass]="{ underlined: fuehrungsebeneDTO.id === currentFuehrungsebene?.id }"
      >
        {{ fuehrungsebeneDTO.name }}
      </h3>
    </span>
    <span class="icon-and-name" *ngIf="!fuehrungsebeneDTO">
      <img class="icon" [src]="fuehrungsebenentypSettings?.icon" />
      <h3 class="name" [matTooltip]="fuehrungsebenentypSettings?.longname || ''">
        {{ fuehrungsebenentypSettings?.longname }}
      </h3>
    </span>

    <!-- Buttons zum Bearbeiten, Navigieren und Hinzufügen -->
    <span class="buttons">
      <button
        mat-icon-button
        (click)="navigateToFuehrungsebene()"
        *ngIf="fuehrungsebeneNavigationAllowed && fuehrungsebeneDTO?.id !== currentFuehrungsebene?.id"
        [matTooltip]="'In ' + fuehrungsebenentypSettings?.longname + ' springen'"
      >
        <mat-icon>south_east</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="add()"
        [matTooltip]="fuehrungsebenentypSettings?.longname + ' hinzufügen'"
        *ngIf="!fuehrungsebeneDTO && allowAddOfType"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="edit()"
        [matTooltip]="fuehrungsebeneDTO ? fuehrungsebenentypSettings?.longname + ' bearbeiten' : ''"
        *ngIf="fuehrungsebeneDTO && !!fuehrungsebeneDTO.typ"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </span>
  </mat-toolbar>

  <!-- Inhalt -->
  <div class="grid-container" *ngIf="fuehrungsebeneDTO">
    <div class="grid-item">Aufträge:</div>
    <div class="grid-item">
      <app-auftrag-header-element
        class="flex-grow center"
        [auftraege]="fuehrungsebeneDTO.auftraege || []"
      ></app-auftrag-header-element>
    </div>
    <div class="grid-item">Leitung:</div>
    <div class="grid-item">{{ getLeitung() }}</div>
    <div class="grid-item">Kontakt:</div>
    <div class="grid-item">
      <ng-container
        *ngIf="fuehrungsebeneDTO?.kommunikationOptionen && (fuehrungsebeneDTO?.kommunikationOptionen)!.length > 0"
      >
        <mat-icon
          *ngFor="let kommunikation of fuehrungsebeneDTO?.kommunikationOptionen"
          [matTooltip]="kontaktService.summarizeKommunikation(kommunikation)"
          >{{ kontaktService.KontaktTypMapping.get(kommunikation.kontaktTyp!)?.icon }}</mat-icon
        >
      </ng-container>
      <ng-container
        *ngIf="
          !fuehrungsebeneDTO?.kommunikationOptionen ||
          (fuehrungsebeneDTO?.kommunikationOptionen && (fuehrungsebeneDTO?.kommunikationOptionen)!.length === 0)
        "
      >
        keine
      </ng-container>
    </div>
  </div>

  <div class="no-fuehrungsebene" *ngIf="!fuehrungsebeneDTO">Nicht definiert</div>
</mat-card>
