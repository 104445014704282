import { NgIf } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, ViewChild, inject } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Store, select } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { Fuehrungsebenentyp, LageDTO } from 'src/app/api/build/openapi';
import { ChipListInputComponent } from 'src/app/shared/components/chip-list-input/chip-list-input.component';
import { lageActions } from '../+state/lage.actions';
import { isSavingSelector } from '../+state/lage.selectors';
import { LastUpdatedComponent } from '../../../shared/last-updated/last-updated.component';
import {
  FuehrungsebeneDetailsDialogComponent,
  FuehrungsebeneDialogData,
} from '../../fuehrungsebene/fuehrungsebene-details-dialog/fuehrungsebene-details-dialog.component';
import { FuehrungsebeneDetailsPanelComponent } from '../../fuehrungsebene/fuehrungsebene-details-panel/fuehrungsebene-details-panel.component';
import { FuehrungsebeneService } from '../../fuehrungsebene/fuehrungsebene.service';
import { KontaktService } from '../../kontakt/kontakt.service';

@Component({
  selector: 'app-lage-details-dialog',
  templateUrl: './lage-details-dialog.component.html',
  styleUrls: ['./lage-details-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FuehrungsebeneDetailsPanelComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatToolbarModule,
    ChipListInputComponent,
    LastUpdatedComponent,
    NgIf,
  ],
})
export class LageDetailsDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild(FuehrungsebeneDetailsPanelComponent)
  fuehrungsebeneDetailsPanel!: FuehrungsebeneDetailsPanelComponent;

  lageDTO: LageDTO;

  private destroyed$ = new Subject();
  private isSaving$: Observable<boolean>;
  Fuehrungsebenentyp = Fuehrungsebenentyp;

  private formBuilder = inject(NonNullableFormBuilder);

  fcStichworte = this.formBuilder.control<string[]>([], [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<FuehrungsebeneDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: FuehrungsebeneDialogData,
    private store: Store<AppStateInterface>,
    protected fuehrungsebeneService: FuehrungsebeneService,
    protected kontaktService: KontaktService,
    private errorService: ErrorService
  ) {
    this.lageDTO = data.fuehrungsebeneDTO as LageDTO;
    this.isSaving$ = store.pipe(select(isSavingSelector));
    dialogRef.updateSize('60%');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.fcStichworte.setValue(this.lageDTO.stichworte);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  save(): void {
    if (this.validateForm()) {
      this.formGroupToModel();

      /**
       * Beim Speichern wird isSaving im Store auf true gesetzt.
       * Nachdem Speichern abgeschlossen, Fenster schließen.
       */
      this.isSaving$.pipe(takeUntil(this.destroyed$)).subscribe((isSaving) => {
        if (!isSaving) {
          this.dialogRef.close();
        }
      });

      if (this.lageDTO.id) {
        this.store.dispatch(lageActions.patchLage({ patchedLage: this.lageDTO }));
      } else {
        this.store.dispatch(lageActions.createLage({ newLage: this.lageDTO }));
      }
    }
  }

  /**
   * Validiert alle Form-Felder und markiert ungültige Felder.
   */
  private validateForm(): boolean {
    if (!this.fuehrungsebeneDetailsPanel.validate() || !this.fcStichworte.valid) {
      this.fcStichworte.markAsTouched();
      return false;
    }

    return true;
  }

  /**
   * Schreibt alle Werte aus der FormGroup zurück ins Model und konvertiert/bereinigt.
   */
  private formGroupToModel() {
    this.fuehrungsebeneDetailsPanel.formGroupToModel();
    if (!this.lageDTO) {
      return;
    }
    this.lageDTO.stichworte = this.fcStichworte.value;
  }

  getErrorMessage(): string {
    return this.errorService.getErrorMessage(this.fcStichworte.errors);
  }
}
