import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, throwError } from 'rxjs';
import { EinsatztagebuchResourceService, EinsatztagebucheintragDTO, ErrorResponse } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { einsatztagebuchActions } from './einsatztagebuch.actions';

@Injectable()
export class EinsatztagebuchEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Führungsebenen anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return einsatztagebuchActions.getEinsatztagebuch({ lageId: props.currentLage.id });
        } else {
          return einsatztagebuchActions.resetStore();
        }
      })
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(einsatztagebuchActions.getEinsatztagebuch),
      map((action) => action.lageId),
      mergeMap((lageId: string) =>
        this.einsatztagebuchResourceService.getEinsatztagebuchEintraege(lageId).pipe(
          map((eintraege) => einsatztagebuchActions.getEinsatztagebuchSuccess({ eintraege, lageId })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(einsatztagebuchActions.getEinsatztagebuchFailure({ errorResponse }));
          })
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(einsatztagebuchActions.createEinsatztagebucheintrag),
      map((action) => action.newEintrag),
      switchMap((newEintrag: EinsatztagebucheintragDTO) => {
        if (!newEintrag.lageId) {
          return throwError(() => new Error('Tagebucheintrag braucht LageId'));
        }
        return this.einsatztagebuchResourceService.createEinsatztagebucheintrag(newEintrag.lageId, newEintrag).pipe(
          map((newEintrag) => einsatztagebuchActions.createEinsatztagebucheintragSuccess({ newEintrag })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(einsatztagebuchActions.createEinsatztagebucheintragFailure({ errorResponse }));
          })
        );
      })
    )
  );

  patch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(einsatztagebuchActions.patchEinsatztagebucheintrag),
      map((action) => action.patchedEintrag),
      switchMap((patchedEintrag: EinsatztagebucheintragDTO) => {
        if (!patchedEintrag.id || !patchedEintrag.lageId) {
          throw Error('Tagebucheintrag braucht ID und LageId');
        }
        return this.einsatztagebuchResourceService
          .patchEinsatztagebucheintrag(patchedEintrag.id, patchedEintrag.lageId, patchedEintrag)
          .pipe(
            map((patchedEintrag) => einsatztagebuchActions.patchEinsatztagebucheintragSuccess({ patchedEintrag })),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(einsatztagebuchActions.patchEinsatztagebucheintragFailure({ errorResponse }));
            })
          );
      })
    )
  );

  constructor(private actions$: Actions, private einsatztagebuchResourceService: EinsatztagebuchResourceService) {}
}
