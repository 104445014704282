import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorResponse, MapLayerConfig } from 'src/app/api/build/openapi';

export const mapLayerConfigActions = createActionGroup({
  source: 'MapConfigs',
  events: {
    'Reset Store': emptyProps(),

    'Get Map Layer Configs': emptyProps(),
    'Get Map Layer Configs Success': props<{ mapLayerConfigs: MapLayerConfig[] }>(),
    'Get Map Layer Configs Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Map Layer Config': props<{ mapLayerConfig: MapLayerConfig }>(),
    'Create Map Layer Config Success': props<{ createdMapLayerConfig: MapLayerConfig }>(),
    'Create Map Layer Config Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Map Layer Config': props<{ mapLayerConfig: MapLayerConfig }>(),
    'Patch Map Layer Config Success': props<{ patchedMapLayerConfig: MapLayerConfig }>(),
    'Patch Map Layer Config Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Map Layer Config': props<{ mapLayerConfig: MapLayerConfig }>(),
    'Delete Map Layer Config Success': props<{ deletedMapLayerConfigId: number }>(),
    'Delete Map Layer Config Failure': props<{ errorResponse: ErrorResponse }>(),

    'Set Favorite Base Layer Config': props<{ configId: string }>(),
    'Set Favorite Overlay Configs': props<{ configIds: string[] }>(),
    'Set Map Layer Order': props<{ configId: string; newOrderNumber: number }>(),
  },
});
