<mat-toolbar>
  <h2>Taktisches Zeichen duplizieren</h2>
</mat-toolbar>

<div class="content">
  <mat-form-field>
    <mat-label>Anzahl Duplikate (max. 10)</mat-label>
    <input matInput type="number" min="1" max="10" [formControl]="fcCount" />
    <mat-error>{{ errorService.getErrorMessage(fcCount.errors) }}</mat-error>
  </mat-form-field>

  <ng-container *ngIf="inputData.tzDto.typ === TaktischesZeichenTyp.TaktischeFormation">
    <mat-checkbox [formControl]="fcRecursive">Untereinheiten ebenfalls kopieren</mat-checkbox>
    <mat-checkbox [formControl]="fcReference" *ngIf="isBibFormation"
      >Untereinheiten referenzieren, statt zu kopieren</mat-checkbox
    >
  </ng-container>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="multiply()" [disabled]="isSaving">Duplizieren</button>
</mat-dialog-actions>
