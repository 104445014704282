import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { BefehlsstelleResourceService, ErrorResponse } from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { befehlsstelleActions } from './befehlsstelle.actions';

@Injectable()
export class BefehlsstelleEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Befehlsstellen anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return befehlsstelleActions.getBefehlsstellen({ lageId: props.currentLage.id });
        } else {
          return befehlsstelleActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle Befehlsstellen einer Lage laden
   */
  getBefehlsstellen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(befehlsstelleActions.getBefehlsstellen),
      mergeMap((props) =>
        this.befehlsstellenResourceService.getBefehlsstellenByLageId(props.lageId).pipe(
          map((befehlsstellen) =>
            befehlsstelleActions.getBefehlsstellenSuccess({ befehlsstelleDTOs: befehlsstellen, lageId: props.lageId })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(befehlsstelleActions.getBefehlsstellenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Eine neue Befehlsstelle anlegen
   */
  createBefehlsstelle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(befehlsstelleActions.createBefehlsstelle),
      mergeMap((props) =>
        this.befehlsstellenResourceService.createBefehlsstelle(props.lageId, props.befehlsstelleDTO).pipe(
          map((createdBefehlsstelleDTO) =>
            befehlsstelleActions.createBefehlsstelleSuccess({ createdBefehlsstelleDTO })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(befehlsstelleActions.createBefehlsstelleFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Eine existierende Befehlsstelle editieren
   */
  patchBefehlsstelle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(befehlsstelleActions.patchBefehlsstelle),
      mergeMap((props) => {
        if (!props.befehlsstelleDTO.id) {
          return throwError(() => new Error('Befehlsstelle muss eine Id besitzen.'));
        }
        return this.befehlsstellenResourceService
          .patchBefehlsstelle(props.befehlsstelleDTO.id, props.lageId, props.befehlsstelleDTO)
          .pipe(
            map((patchedBefehlsstelleDTO) =>
              befehlsstelleActions.patchBefehlsstelleSuccess({ patchedBefehlsstelleDTO })
            ),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(befehlsstelleActions.patchBefehlsstelleFailure({ errorResponse }));
            })
          );
      })
    )
  );

  /**
   * Eine existierende Befehlsstelle löschen
   */
  deleteBefehlsstelle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(befehlsstelleActions.deleteBefehlsstelle),
      mergeMap((props) => {
        if (!props.befehlsstelleDTO.id) {
          return throwError(() => new Error('Befehlsstelle muss eine Id besitzen.'));
        }
        return this.befehlsstellenResourceService.deleteBefehlsstelle(props.befehlsstelleDTO.id, props.lageId).pipe(
          map(() =>
            befehlsstelleActions.deleteBefehlsstelleSuccess({ deletedBefehlsstelleDTO: props.befehlsstelleDTO })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(befehlsstelleActions.deleteBefehlsstelleFailure({ errorResponse }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private befehlsstellenResourceService: BefehlsstelleResourceService) {}
}
