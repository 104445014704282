import { createSelector } from '@ngrx/store';
import { AppStateInterface, stellen } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[stellen];

export const stellenSelector = createSelector(selectFeature, (state) => state.stellen);
export const stellenErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingStellenSelector = createSelector(selectFeature, (state) => state.isLoading);
export const stellenLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const stellenOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.stellen.filter((stelle) => stelle.fuehrungsebeneId === fuehrungsebeneId)
  );
