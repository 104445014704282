import { createSelector } from '@ngrx/store';
import { AppStateInterface, fotos } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[fotos];

export const fotosSelector = createSelector(selectFeature, (state) => state.fotos);
export const fotosErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingFotosSelector = createSelector(selectFeature, (state) => state.isLoading);
export const fotosLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const fotosOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) => state.fotos.filter((fotos) => fotos.fuehrungsebeneId === fuehrungsebeneId));
