<mat-toolbar>
  <app-icon-and-text [icon]="einsatzService.EINSATZ_ICON" [text]="'ELS Einsatz: ' + einsatzDto.einsatznummer" />
  <mat-icon (click)="openEinsatzDialog()" fontSet="material-icons-outlined">info</mat-icon>
</mat-toolbar>

<div class="content">
  <app-fuehrungsebene-icon-and-text [fuehrungsebeneDTO]="parentFuehrungsebeneDto" />

  <hr />

  <table>
    <tr>
      <th>Einsatznummer</th>
      <td>{{ einsatzDto.einsatznummer }}</td>
    </tr>
    <tr>
      <th>Muttereinsatznummer</th>
      <td>{{ einsatzDto.muttereinsatznummer }}</td>
    </tr>
    <tr>
      <th>Einsatzstatus</th>
      <td>{{ einsatzDto.einsatzstatus }}</td>
    </tr>
    <tr>
      <th>Einsatzeröffnung</th>
      <td>{{ einsatzDto.einsatzeroeffnung | dateTime | async }}</td>
    </tr>
    <tr>
      <th>Einsatzart 1</th>
      <td>{{ einsatzDto.einsatzart1 }}</td>
    </tr>
    <tr>
      <th>Stichwort 1</th>
      <td>{{ einsatzDto.einsatzstichwort1 }}</td>
    </tr>
    <tr>
      <th>Einsatzart 2</th>
      <td>{{ einsatzDto.einsatzart2 }}</td>
    </tr>
    <tr>
      <th>Stichwort 2</th>
      <td>{{ einsatzDto.einsatzstichwort2 }}</td>
    </tr>
  </table>
</div>
