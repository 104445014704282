<div
  class="item mat-elevation-z3"
  [ngClass]="{ 'mat-elevation-z6': hovering }"
  (mouseover)="hovering = true"
  (mouseleave)="hovering = false"
>
  <div class="zeichen-image-wrapper" [matBadge]="einsatzDtos.length">
    <img [src]="einsatzService.EINSATZ_ICON" />
  </div>
  <div class="zeichen-text" matTooltip="ELS Einsätze" [matTooltipShowDelay]="500">ELS Einsätze</div>
</div>
