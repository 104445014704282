import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TileLayer, TileLayerOptions, WMSOptions, tileLayer } from 'leaflet';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { MapConfigTyp, MapLayerConfig } from 'src/app/api/build/openapi';
import { mapLayerConfigActions } from './+state/map-layer-config.actions';
import { mapBaseLayerConfigsSelector, mapOverlayConfigsSelector } from './+state/map-layer-config.selectors';

export interface LocalStorageConfigSelection {
  selectedBaseLayerConfigId?: string;
  selectedOverlayConfigIds?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class LayerConfigService {
  readonly LOCAL_STORAGE_KEY = 'selectedMapLayers';

  // Basislayer
  baseLayerConfigs$: Observable<MapLayerConfig[]>;
  selectedBaseLayerConfig$ = new BehaviorSubject<MapLayerConfig | undefined>(undefined);

  // Overlay layer
  overlayConfigs$: Observable<MapLayerConfig[]>;
  selectedOverlayConfigs$ = new BehaviorSubject<MapLayerConfig[]>([]);

  private store = inject(Store<AppStateInterface>);

  constructor() {
    this.store.dispatch(mapLayerConfigActions.getMapLayerConfigs());
    this.baseLayerConfigs$ = this.store.select(mapBaseLayerConfigsSelector).pipe(takeUntilDestroyed());
    this.store
      .select(mapBaseLayerConfigsSelector)
      .pipe(
        takeUntilDestroyed(),
        map((configs) => {
          const localStorageSelection = localStorage.getItem(this.LOCAL_STORAGE_KEY);
          if (localStorageSelection) {
            const selection: LocalStorageConfigSelection = JSON.parse(localStorageSelection);
            if (selection.selectedBaseLayerConfigId) {
              return configs.find((config) => '' + config.id === selection.selectedBaseLayerConfigId);
            }
          }
          return configs.find((config) => config.isFavorite);
        })
      )
      .subscribe((baseLayerConfig) => this.selectedBaseLayerConfig$.next(baseLayerConfig));

    this.overlayConfigs$ = this.store.select(mapOverlayConfigsSelector).pipe(takeUntilDestroyed());
    this.store
      .select(mapOverlayConfigsSelector)
      .pipe(
        takeUntilDestroyed(),
        map((configs) => {
          const localStorageSelection = localStorage.getItem(this.LOCAL_STORAGE_KEY);
          if (localStorageSelection) {
            const selection: LocalStorageConfigSelection = JSON.parse(localStorageSelection);
            if (selection.selectedOverlayConfigIds) {
              return configs.filter((config) => selection.selectedOverlayConfigIds?.includes('' + config.id));
            }
          }
          return configs.filter((config) => config.isFavorite);
        })
      )
      .subscribe((overlayConfigs) => this.selectedOverlayConfigs$.next(overlayConfigs));
  }

  private readLocalStorageConfigSelection(): LocalStorageConfigSelection {
    const localStorageSelection = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (localStorageSelection) {
      return JSON.parse(localStorageSelection);
    }
    return {};
  }

  private writeLocalStorageConfigSelection(selection: LocalStorageConfigSelection) {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(selection));
  }

  selectBaseLayerConfig(baseLayerConfig: MapLayerConfig) {
    this.selectedBaseLayerConfig$.next(baseLayerConfig);

    const localStorageSelection = this.readLocalStorageConfigSelection();
    localStorageSelection.selectedBaseLayerConfigId = '' + baseLayerConfig.id;
    this.writeLocalStorageConfigSelection(localStorageSelection);
  }

  setOverlayConfigs(overlayConfigs: MapLayerConfig[]) {
    this.selectedOverlayConfigs$.next(overlayConfigs);

    const localStorageSelection = this.readLocalStorageConfigSelection();
    localStorageSelection.selectedOverlayConfigIds = overlayConfigs.map((config) => '' + config.id);
    this.writeLocalStorageConfigSelection(localStorageSelection);
  }

  /**
   * Erstellt einen TileLayer
   */
  createTileLayerFromConfig(config: MapLayerConfig): TileLayer | undefined {
    if (!config.url) {
      return;
    }

    if (config.typ === MapConfigTyp.Wms) {
      return tileLayer.wms(config.url, this.configToTileLayerOptions(config));
    }
    return tileLayer(config.url, this.configToTileLayerOptions(config));
  }

  /**
   * Convertiert eine MapLayerConfig zu TileLayerOptions.
   * Diese kann wiederum zur Erstellung eines TileLayers für Leaflet Maps genutzt werden.
   */
  private configToTileLayerOptions(config: MapLayerConfig): TileLayerOptions {
    const options: TileLayerOptions = {
      attribution: config.attribution,
      minZoom: config.minZoom,
      maxZoom: config.maxZoom,
      opacity: config.opacity,
      zIndex: config.isBaseLayerConfig ? 0 : (config.orderNumber || 0) + 3, // Basislayer immer auf 0 und Overlays darüber
    };

    if (config.typ === MapConfigTyp.Wms) {
      const wmsOptions = options as WMSOptions;
      if (config.format) {
        wmsOptions.format = config.format;
      }
      wmsOptions.layers = config.layer;
      wmsOptions.transparent = config.transparent;
      return wmsOptions;
    }

    return options;
  }
}
