import { createSelector } from '@ngrx/store';
import { AppStateInterface, tierschaeden } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[tierschaeden];

export const tierschaedenSelector = createSelector(selectFeature, (state) => state.tierschaeden);
export const tierschaedenErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingtierschaedenSelector = createSelector(selectFeature, (state) => state.isLoading);
export const tierschaedenLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const tierschaedenOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.tierschaeden.filter((tierschaden) => tierschaden.fuehrungsebeneId === fuehrungsebeneId)
  );
