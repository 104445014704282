import { Injectable } from '@angular/core';
import { divIcon, LeafletMouseEvent, Map, marker, Marker } from 'leaflet';
import { LayerService } from '../layer.service';
import { ToolSettings } from '../tool.interface';
import { FeatureGenerator } from './tool-handler';

/**
 * Generator für Texte auf einer Leaflet-Karte über den ToolHandler.
 */
@Injectable({
  providedIn: 'root',
})
export class TextGenerator implements FeatureGenerator {
  private toolText: Marker | null = null;
  private map: Map | null = null;
  // TODO: Inputfield einbauen und statischen Text ersetzen
  private text = 'Test Text';

  constructor(private layerService: LayerService) {}

  init(map: Map): void {
    this.map = map;
  }

  createFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): void {
    const textIcon = divIcon({ className: 'tool-text', html: this.text });
    this.toolText = marker(event.latlng, {
      icon: textIcon,
    });
    this.map?.addLayer(this.toolText);
  }

  updateFeature(event: LeafletMouseEvent): void {
    // TODO: Text auf Text von InputField setzen
    if (this.toolText) {
      this.toolText.setLatLng(event.latlng);
    }
  }

  extendFeature(): void {
    // Do nothing
  }

  finalizeFeature(event: LeafletMouseEvent, toolSettings: ToolSettings): GeoJSON.Feature | null {
    if (!this.toolText) {
      return null;
    }

    this.map?.removeLayer(this.toolText);
    this.toolText = null;
    return this.layerService.addTextToCurrentLayer([event.latlng.lng, event.latlng.lat], this.text);
  }
}
