import { createSelector } from '@ngrx/store';
import { AppStateInterface, gebiete } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[gebiete];

export const gebieteSelector = createSelector(selectFeature, (state) => state.gebiete);
export const gebieteErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingGebieteSelector = createSelector(selectFeature, (state) => state.isLoading);
export const gebieteLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const gebieteOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.gebiete.filter((gebiet) => gebiet.fuehrungsebeneId === fuehrungsebeneId)
  );
