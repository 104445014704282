import { AfterViewInit, Component, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableModule } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { debounceTime, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { Fuehrungsebenentyp, LageDTO } from 'src/app/api/build/openapi';
import { LayerService } from 'src/app/lagedarstellung/layer.service';
import { FormatService } from 'src/app/shared/services/format.service';
import { ToolbarAction } from 'src/app/shared/toolbar-actions';
import { lageActions } from '../+state/lage.actions';

import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { AppService, ApplicationType } from 'src/app/app.service';
import { LoadableComponentComponent } from 'src/app/shared/components/loadable-component/loadable-component.component';
import { CompareService } from 'src/app/shared/services/compare.service';
import { LogService } from 'src/app/shared/services/log.service';
import { NatsConnectorService } from 'src/app/shared/services/nats/nats-connector.service';
import { currentLageSelector, isLoadingSelector, lagenSelector } from '../+state/lage.selectors';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { LageDetailsDialogComponent } from '../lage-details-dialog/lage-details-dialog.component';
import { LageToolbarComponent } from '../lage-toolbar/lage-toolbar.component';
import { VersionInfoDialogComponent } from './version-info-dialog/version-info-dialog.component';

const ONE_DAY_IN_MILLIS = 86400000; // 1000 * 60 * 60 * 24;

/**
 * Tabelle mit allen Lagen
 * - Lagen können über Toolbar hinzugefügt/bearbeitet werden
 * - Über Buttons in Lage-Zeile kann zu Meldungen/Lagedarstellung navigiert werden
 */
@Component({
  selector: 'app-lage-overview',
  templateUrl: './lage-overview.component.html',
  styleUrls: ['./lage-overview.component.scss'],
  standalone: true,
  imports: [
    LageToolbarComponent,
    MatTableModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    NgIf,
    NgFor,
    NgClass,
    AsyncPipe,
    DateTimePipe,
    MatTooltipModule,
    LoadableComponentComponent,
    MatSortModule,
  ],
})
export class LageOverviewComponent implements AfterViewInit {
  ApplicationType = ApplicationType;

  displayedColumns: string[] = ['startedOn', 'name', 'adresse', 'nav', 'stichworte'];

  @ViewChild('lageTable')
  lageTable!: MatTable<LageDTO>;

  isLoading$ = this.store.select(isLoadingSelector).pipe(debounceTime(500));
  currentLage$ = this.store.select(currentLageSelector);
  lagen: LageDTO[] = [];
  lagenSorted: LageDTO[] = [];
  currentSort: Sort = {
    active: 'startedOn',
    direction: 'desc',
  };

  // Als erste Seite nach Login, müssen diese Services initialisiert werden
  private natsConnector = inject(NatsConnectorService);
  private logService = inject(LogService);
  private compareService = inject(CompareService);
  public selectedApplication?: ApplicationType;

  constructor(
    private store: Store<AppStateInterface>,
    protected layerService: LayerService,
    protected formatService: FormatService,
    private dialog: MatDialog,
    private router: Router,
    private appService: AppService
  ) {
    // für alle Lagen anmelden
    this.natsConnector.updateSubscriptions(undefined, true, false);

    this.store.dispatch(lageActions.getLagen());
    this.store.dispatch(lageActions.setCurrentLage({ currentLage: null }));

    this.store
      .select(lagenSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((lagen) => {
        this.lagen = lagen;
        this.sortData(this.currentSort);
      });

    this.appService
      .selectedApplicationObservable()
      .pipe(takeUntilDestroyed())
      .subscribe((a) => (this.selectedApplication = a));
  }

  sortData(sort: Sort) {
    this.currentSort = sort;

    if (!sort.active || sort.direction === '') {
      this.lagenSorted = this.lagen;
      return;
    }

    this.lagenSorted = [...this.lagen].sort((a, b) => {
      switch (sort.active) {
        case 'startedOn':
          return this.compareService.compareDateStrings(sort.direction, a.startedOn, b.startedOn);
        case 'name':
          return this.compareService.compareStrings(sort.direction, a.name, b.name);
        case 'adresse':
          return this.compareService.compareStrings(sort.direction, a.adresse, b.adresse);
        case 'stichworte':
          return this.compareService.compareStrings(sort.direction, a.stichworte[0], b.stichworte[0]);
        default:
          return this.compareService.compareStrings(sort.direction, a.id, b.id);
      }
    });
  }

  ngAfterViewInit(): void {
    this.checkAndShowWarningDialog();
  }

  handleToolbarEvent(toolbarAction: ToolbarAction) {
    switch (toolbarAction) {
      case ToolbarAction.ADD:
        this.addLage();
        break;
      case ToolbarAction.EDIT:
        this.editLage();
    }
  }

  updateSelection(lage: LageDTO | null) {
    this.store.dispatch(lageActions.setCurrentLage({ currentLage: lage }));
  }

  // FIXME wird eine Lage in der Liste angeglick, dann wird diese geladen. Mit Doppelklick im Anschluss, wird sie nochmals geladen...
  doubleClickAction(lage: LageDTO) {
    this.updateSelection(lage);
    switch (this.selectedApplication) {
      case ApplicationType.LAGE:
        this.router.navigateByUrl('/lagedarstellung');
        break;
      case ApplicationType.LAGEK:
        this.router.navigateByUrl('/lage-k');
        break;
    }
  }

  onClickMap(lage: LageDTO) {
    this.doubleClickAction(lage);
  }

  onClickMeldungen(lage: LageDTO) {
    this.updateSelection(lage);
    this.router.navigateByUrl('/meldungen');
  }

  onClickEinsatztagebuch(lage: LageDTO) {
    this.updateSelection(lage);
    this.router.navigateByUrl('/einsatztagebuch');
  }

  /**
   * Neue Lage über Dialog anlegen
   */
  addLage() {
    this.updateSelection(null);

    const newLage: LageDTO = {
      name: '',
      typ: Fuehrungsebenentyp.Lage,
      startedOn: new Date().toISOString(),
      stichworte: [],
    };

    this.openLageDialog(newLage);
  }

  /**
   * Bestehende Lage über Dialog bearbeiten
   */
  editLage() {
    this.currentLage$.pipe(take(1)).subscribe((currentLage) => {
      if (currentLage) {
        this.openLageDialog({ ...currentLage });
      }
    });
  }

  private openLageDialog(lageDTO: LageDTO) {
    this.dialog.open(LageDetailsDialogComponent, {
      data: { fuehrungsebeneDTO: lageDTO },
    });
  }

  private checkAndShowWarningDialog() {
    const lastShownString = localStorage.getItem('lastWarningDialogShown');
    if (lastShownString) {
      const dontShow = new Date().getTime() - new Date(JSON.parse(lastShownString)).getTime() < ONE_DAY_IN_MILLIS;

      if (dontShow) {
        return;
      }
    }

    this.dialog
      .open(VersionInfoDialogComponent)
      .afterClosed()
      .subscribe(() => localStorage.setItem('lastWarningDialogShown', JSON.stringify(new Date())));
  }
}
