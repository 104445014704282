<mat-toolbar>
  <h2>Beispiel-Daten generieren</h2>
</mat-toolbar>

<mat-dialog-content>
  <p>Durch Bestätigung werden Beispieldaten in angegebener Menge generiert.</p>

  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Anzahl Lagen (1-1000)</mat-label>
      <input matInput type="number" min="1" max="1000" [formControl]="fcAnzahlLagen" />
      <mat-error>{{ getErrorMessage(fcAnzahlLagen) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Max. Meldungen pro Lage (0-100)</mat-label>
      <input matInput type="number" min="0" max="100" [formControl]="fcMaxMeldungenProLage" />
      <mat-error>{{ getErrorMessage(fcMaxMeldungenProLage) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Max. Führungsebenen pro Führungsebene (0-5)</mat-label>
      <input matInput type="number" min="0" max="5" [formControl]="fcMaxFuehrungsebenenProFuehrungsebene" />
      <mat-error>{{ getErrorMessage(fcMaxFuehrungsebenenProFuehrungsebene) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Max. Fahrzeuge pro Führungsebene (0-500)</mat-label>
      <input matInput type="number" min="0" max="500" [formControl]="fcMaxFahrzeugeProFuehrungsebene" />
      <mat-error>{{ getErrorMessage(fcMaxFahrzeugeProFuehrungsebene) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Max. Personen pro Führungsebene (0-500)</mat-label>
      <input matInput type="number" min="0" max="500" maxlength="2" [formControl]="fcMaxPersonenProFuehrungsebene" />
      <mat-error>{{ getErrorMessage(fcMaxPersonenProFuehrungsebene) }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close [disabled]="isLoading">Abbrechen</button>
  <button mat-raised-button color="accent" (click)="generateData()" [disabled]="isLoading" class="mr-05">
    Generieren
  </button>
</mat-dialog-actions>

<mat-spinner class="loading-spinner" color="accent" *ngIf="isLoading"></mat-spinner>
