import { CommonModule, KeyValuePipe } from '@angular/common';
import {
  Component,
  ComponentRef,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { FuehrungsebeneDTO, Fuehrungsebenentyp } from 'src/app/api/build/openapi';
import {
  currentFuehrungsebeneSelector,
  currentGrundschutzeSelector,
} from '../fuehrungsebene/+state/fuehrungsebene.selectors';
import { FuehrungsebeneService } from '../fuehrungsebene/fuehrungsebene.service';
import { GrundschutzComponent } from '../fuehrungsebene/grundschutz/grundschutz.component';

export interface ComponentAddedEvent {
  name: string;
  slot: string;
}

export interface CustomElement {
  name: string;
  icon: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  klasse: any;
}

@Component({
  selector: 'app-lagedarstellung-custom-componenten',
  templateUrl: './lagedarstellung-custom-componenten.component.html',
  styleUrls: ['./lagedarstellung-custom-componenten.component.scss'],
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, MatIconModule, MatMenuModule, KeyValuePipe],
})
export class LagedarstellungCustomComponentenComponent implements OnInit {
  @ViewChildren('compcontainer', { read: ViewContainerRef })
  containers?: QueryList<ViewContainerRef>;

  @Input()
  fuehrungsebeneCreatedEvent$?: Observable<FuehrungsebeneDTO>;

  @Input()
  fuehrungsebenen$?: Observable<FuehrungsebeneDTO[]>;

  @Output()
  componentCreatedEvent: EventEmitter<ComponentAddedEvent> = new EventEmitter<ComponentAddedEvent>();

  @Output()
  editFuehrungsebeneEvent: EventEmitter<FuehrungsebeneDTO> = new EventEmitter<FuehrungsebeneDTO>();

  disableAddingGSs = true;

  private store = inject(Store);
  private destroyRef = inject(DestroyRef);
  private fuehrungsebeneService = inject(FuehrungsebeneService);

  readonly components: Map<string, CustomElement> = new Map<string, CustomElement>([
    ['GRUNDSCHUTZ', { name: 'Grundschutz', icon: 'grundschutz-icon.png', klasse: GrundschutzComponent }],
  ]);

  setCards: Map<string, string> = new Map<string, string>();

  ngOnInit(): void {
    if (this.fuehrungsebeneCreatedEvent$ && this.containers) {
      this.fuehrungsebeneCreatedEvent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((fuehrungsebeneDTO) => {
        const slot: string = this.getSlotForFuehrungsebene(fuehrungsebeneDTO);
        const component = this.components.get(fuehrungsebeneDTO.typ.toString());
        if (component) {
          this.insert(slot, component, { fuehrungsebeneDTO: fuehrungsebeneDTO });
        }
      });
    }

    this.fuehrungsebenen$?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((fuehrungsebenen) => {
      this.setCards.clear();
      this.containers?.forEach((c) => c.clear());
      fuehrungsebenen.forEach((fuehrungsebeneDTO) => {
        const slot = this.getSlotForFuehrungsebene(fuehrungsebeneDTO);
        const component = this.components.get(fuehrungsebeneDTO.typ);
        if (component) {
          this.insert(slot, component, { fuehrungsebeneDTO: fuehrungsebeneDTO });
        }
      });
    });

    combineLatest([this.store.select(currentFuehrungsebeneSelector), this.store.select(currentGrundschutzeSelector)])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([currentFuehrungsebeneDTO, _]) => {
        if (!currentFuehrungsebeneDTO) {
          this.disableAddingGSs = true;
          return;
        }

        this.disableAddingGSs = !this.fuehrungsebeneService.canFuehrungsebeneHaveMoreChildrenOfType(
          currentFuehrungsebeneDTO,
          Fuehrungsebenentyp.Grundschutz
        );
      });
  }

  onClickAdd(slot: string, element: CustomElement) {
    this.componentCreatedEvent.emit({ name: element.name, slot: slot });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private insert(slot: string, element: CustomElement, data: any = undefined) {
    const cont = this.containers?.filter((cont) => cont.element.nativeElement.id === slot)[0];
    if (cont) {
      switch (element.name) {
        case 'Grundschutz': {
          const compt: ComponentRef<GrundschutzComponent> = cont.createComponent(element.klasse);
          compt.setInput('grundschutz', data.fuehrungsebeneDTO);
          compt.instance.editClicked.subscribe((fuehrungsebeneDTO) =>
            this.editFuehrungsebeneEvent.emit(fuehrungsebeneDTO)
          );
          compt.location.nativeElement.setAttribute('class', 'grundschutz');
          compt.changeDetectorRef.detectChanges();
          break;
        }
      }
    }
  }

  /**
   * Platzhalter Funktion, solange bis es ein ordentliches Konzept für das Speichern der Positionen gibt.
   */
  private getSlotForFuehrungsebene(fuehrungsebene: FuehrungsebeneDTO): string {
    if (!fuehrungsebene.id) {
      return 'card-1';
    }
    const cardOpt = this.setCards.get(fuehrungsebene.id);
    if (cardOpt) {
      return cardOpt;
    } else {
      const card = `card-` + (this.setCards.size + 1);
      this.setCards.set(fuehrungsebene.id, card);
      return card;
    }
  }

  isCardSet(card: string) {
    return [...this.setCards.values()].includes(card);
  }
}
