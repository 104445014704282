import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EinsatztagebucheintragDTO, ErrorResponse } from 'src/app/api/build/openapi';

export const einsatztagebuchActions = createActionGroup({
  source: 'Einsatztagebuch',
  events: {
    'Reset Store': emptyProps(),

    'Get Einsatztagebuch': props<{ lageId: string }>(),
    'Get Einsatztagebuch Success': props<{ eintraege: EinsatztagebucheintragDTO[]; lageId: string }>(),
    'Get Einsatztagebuch Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Einsatztagebucheintrag': props<{ newEintrag: EinsatztagebucheintragDTO }>(),
    'Create Einsatztagebucheintrag Success': props<{ newEintrag: EinsatztagebucheintragDTO }>(),
    'Create Einsatztagebucheintrag Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Einsatztagebucheintrag': props<{ patchedEintrag: EinsatztagebucheintragDTO }>(),
    'Patch Einsatztagebucheintrag Success': props<{ patchedEintrag: EinsatztagebucheintragDTO }>(),
    'Patch Einsatztagebucheintrag Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
