<mat-card>
  <form [formGroup]="formGroup">
    <app-cleanable-form-field label="Anzeigename" [formGroup]="formGroup" formControlName="anzeigename" />
  </form>

  <app-personenuebersicht-subset #personenBevComponent header="Bevölkerung" [personen]="personenschadenBev" />
  <app-personenuebersicht-subset #personenEkComponent header="Einsatzkräfte" [personen]="personenschadenEk" />

  <mat-error *ngIf="showVerletzteError"
    >Anzahl Verletzte in MANV darf Summe der Verletzten aus Einsatzkräften und Bevölkerung nicht
    überschreiten.</mat-error
  >
  <mat-error *ngIf="showToteError"
    >Anzahl Tote in MANV darf Summe der Toten aus Einsatzkräften und Bevölkerung nicht überschreiten.</mat-error
  >

  <app-manv #manvComponent header="Einsatzkräfte" [manv]="manv" />
</mat-card>
