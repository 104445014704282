import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DialogAction, DialogService } from '@product/ise-dialog-lib';
import { take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { VerwaltungsebeneDTO, Verwaltungsebenentyp } from 'src/app/api/build/openapi';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { bibliothekActions } from '../+state/bibliothek.actions';
import { isDeletingSelector } from '../+state/bibliothek.selectors';
import { VerwaltungsebeneMoveComponent } from '../verwaltungsebene-move/verwaltungsebene-move.component';
import { verwaltungsebenenMap } from '../verwaltungsebenenMap';

export interface DialogData {
  verwaltungsebene: VerwaltungsebeneDTO;
  parentVerwaltungsebene?: VerwaltungsebeneDTO;
  canDelete: boolean;
}

@Component({
  selector: 'app-verwaltungsebene-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    KontaktListComponent,
    ReactiveFormsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  templateUrl: './verwaltungsebene-dialog.component.html',
  styleUrls: ['./verwaltungsebene-dialog.component.scss'],
})
export class VerwaltungsebeneDialogComponent implements OnInit {
  private dialogService = inject(DialogService);
  private actions$ = inject(Actions);
  // Setup
  private fb = inject(NonNullableFormBuilder);
  Verwaltungsebenentyp = Verwaltungsebenentyp;
  verwaltungsebenenMap = verwaltungsebenenMap;

  /**
   * "compareFn" für die `keyvalue`-Pipe, damit diese nicht alphabetisch sortiert. D.h. die gegebene Reihenfolge bleibt erhalten.
   *
   * @returns 0
   */
  keepOrder = () => 0;

  @ViewChild(KontaktListComponent) kontaktList!: KontaktListComponent;

  verwaltungsebeneToEdit: VerwaltungsebeneDTO | undefined;
  parentVerwaltungsebene: VerwaltungsebeneDTO | undefined;

  // Controls
  nameControl = this.fb.control('', Validators.required);
  verwaltungsebeneControl = this.fb.control(Verwaltungsebenentyp.Keine);

  // FormGroup
  verwaltungsebeneForm = new FormGroup({
    name: this.nameControl,
    verwaltungsebene: this.verwaltungsebeneControl,
  });

  // Gibt an, ob eine Verwaltungseben gelöscht werden kann
  deletingDisabled = true;
  isDeleting = false;

  tooltip = this.deletingDisabled
    ? 'Verwaltungsebene enthält Zeichen, die in einer Lage verwendet werden'
    : 'Löscht die Verwaltungsebene';

  constructor(
    private store: Store<AppStateInterface>,
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
    private dialogRef: MatDialogRef<VerwaltungsebeneDialogComponent>,
    private dialog: MatDialog
  ) {
    if (dialogData) {
      if (dialogData.verwaltungsebene) {
        this.verwaltungsebeneToEdit = dialogData.verwaltungsebene;
      }
      if (dialogData.parentVerwaltungsebene) {
        this.parentVerwaltungsebene = dialogData.parentVerwaltungsebene;
      }

      this.deletingDisabled = dialogData.canDelete;
    }

    this.store
      .select(isDeletingSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((isDeleting) => (this.isDeleting = isDeleting));
  }

  ngOnInit(): void {
    if (this.verwaltungsebeneToEdit) {
      this.nameControl.setValue(this.verwaltungsebeneToEdit.name || '');
      this.verwaltungsebeneControl.setValue(this.verwaltungsebeneToEdit.verwaltungsebene || Verwaltungsebenentyp.Keine);
    }
  }

  protected save() {
    const parentVerwaltungsebeneId = this.verwaltungsebeneToEdit
      ? this.verwaltungsebeneToEdit.parentVerwaltungsebeneId
      : this.parentVerwaltungsebene?.id;

    const verwaltungsebene: VerwaltungsebeneDTO = {
      ...this.verwaltungsebeneToEdit,
      name: this.nameControl.value,
      verwaltungsebene: this.verwaltungsebeneControl.value,
      kommunikationOptionen: this.kontaktList.getKommunikationOptionen(),
      parentVerwaltungsebeneId,
    };

    if (verwaltungsebene.id) {
      this.store.dispatch(bibliothekActions.patchVerwaltungsebene({ verwaltungsebene }));
    } else {
      this.store.dispatch(bibliothekActions.createVerwaltungsebene({ verwaltungsebene }));
    }

    // TODO Fehler abfangen
    this.dialogRef.close();
  }

  protected initializeMoveVerwaltungsebene() {
    if (!this.verwaltungsebeneToEdit) {
      return;
    }

    this.dialog
      .open(VerwaltungsebeneMoveComponent, {
        data: { verwaltungsebeneToMoveId: this.verwaltungsebeneToEdit.id },
      })
      .afterClosed()
      .subscribe((_) => this.dialogRef.close());
  }

  /**
   * Abfrage, ob der Verwaltungsebene gelöscht werden soll.
   */
  protected askDelete() {
    const verwaltungsebeneToDelete = this.verwaltungsebeneToEdit;

    if (!verwaltungsebeneToDelete?.id) {
      return;
    }

    this.dialogService
      .openConfirmDialog(
        'Verwaltungsebene löschen?',
        'Mit dem Löschen der Verwaltungsebene werden darunterliegende Verwaltungsebenen sowie darin befindliche Taktische Zeichen gelöscht. Das Löschen kann nicht rückgängig gemacht werden.'
      )
      .afterClosed()
      // FIXME verschahtelte subscribes raus!
      .subscribe((result: DialogAction) => {
        if (result === DialogAction.OK) {
          this.actions$.pipe(ofType(bibliothekActions.deleteVerwaltungsebeneSuccess), take(1)).subscribe(() => {
            this.dialogRef.close();
          });

          this.store.dispatch(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            bibliothekActions.deleteVerwaltungsebene({ verwaltungsebeneId: verwaltungsebeneToDelete.id! })
          );
        }
      });
  }
}
