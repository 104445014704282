import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

export type Size = 'small' | 'medium' | 'large';

@Component({
  selector: 'app-icon-and-text',
  standalone: true,
  imports: [MatTooltipModule, NgClass],
  templateUrl: './icon-and-text.component.html',
  styleUrl: './icon-and-text.component.scss',
})
export class IconAndTextComponent {
  @Input({ required: true }) icon!: string;
  @Input() iconSize: Size = 'medium';

  @Input({ required: true }) text!: string;
  @Input() textSize: Size = 'medium';

  @Input() tooltip?: string;
}
