import { CommonModule, NgIf } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormGroup, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Observable } from 'rxjs';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';

/* eslint-disable  @typescript-eslint/no-explicit-any */
@Component({
  selector: 'app-tz-combobox',
  templateUrl: './tz-combobox.component.html',
  styleUrls: ['./tz-combobox.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class TzComboboxComponent implements ControlValueAccessor, AfterViewInit {
  private destroyRef = inject(DestroyRef);

  @Input() label!: string;
  /** Name des zugehörigen 'FormControl' */
  @Input() formControlName!: string;
  /** `FormGroup` in des das Feld verwendet wird */
  @Input() formGroup!: FormGroup;
  /**Text für die Options */
  @Input() displayedFields!: string[];
  /** Daten die angeboten werden sollen */
  @Input() data$!: Observable<TaktischesZeichenDTO[]>;

  mouseover = false;
  selectedItemText = '';
  selectedItemSvg = '';
  value: any = '';

  public ngControl: NgControl = inject(NgControl);

  constructor() {
    this.ngControl.valueAccessor = this;
  }

  writeValue(value: any): void {
    this.value = value;
    if (value) {
      this.selectedItemText = this.getText(value);
      this.selectedItemSvg = value.dataUrl;
    }
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChange = () => {
    // Kann überschrieben werden
  };

  onTouched = () => {
    // Kann überschrieben werden
  };

  ngAfterViewInit(): void {
    this.formGroup
      .get(this.formControlName)
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: any) => {
        if (value) {
          this.selectedItemText = this.getText(value);
          this.selectedItemSvg = value.dataUrl;
        }
      });
  }

  getText(item: any): string {
    let text = '';
    this.displayedFields.forEach((s) => (text = text + ' ' + item[s]));
    return text;
  }

  compareTZs(tz1: TaktischesZeichenDTO, tz2: TaktischesZeichenDTO) {
    return tz1?.id === tz2?.id;
  }
}
