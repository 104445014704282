import { createSelector } from '@ngrx/store';
import { AppStateInterface, befehlsstellen } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState[befehlsstellen];

export const befehlsstellenSelector = createSelector(selectFeature, (state) => state.befehlsstellen);
export const befehlsstellenErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const isLoadingBefehlsstellenSelector = createSelector(selectFeature, (state) => state.isLoading);
export const befehlsstellenLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);

export const befehlsstellenOfFuehrungsebeneSelector = (fuehrungsebeneId?: string) =>
  createSelector(selectFeature, (state) =>
    state.befehlsstellen.filter((befehlsstelle) => befehlsstelle.fuehrungsebeneId === fuehrungsebeneId)
  );
