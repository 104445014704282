import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Manv } from '../manv.interface';

/**
 * Große farbige MANV Komponente zur Anzeige im großen Dialog.
 * Erlaubt das Editieren der Personenanzahl in jedem Feld außer "Summe".
 * Die ergibt sich aus den ersten drei Kategorien.
 */
@Component({
  selector: 'app-manv',
  templateUrl: './manv.component.html',
  styleUrls: ['./manv.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
  ],
})
export class ManvComponent implements OnChanges {
  @Input()
  manv?: Manv;

  @Input()
  disabled = false;

  private formBuilder = inject(NonNullableFormBuilder);
  protected category1 = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected category2 = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected category3 = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected category4 = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected dead = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);

  formGroup = this.formBuilder.group({
    category1: this.category1,
    category2: this.category2,
    category3: this.category3,
    category4: this.category4,
    dead: this.dead,
  });

  constructor() {
    this.formGroup.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      if (this.formGroup.valid) {
        this.manv = {
          category1: value.category1 || 0,
          category2: value.category2 || 0,
          category3: value.category3 || 0,
          category4: value.category4 || 0,
          dead: value.dead || 0,
        };
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['manv']) {
      this.formGroup.setValue(changes['manv'].currentValue);
    }
  }

  isValid(): boolean {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return false;
    }
    return true;
  }

  getDto(): Manv {
    return {
      category1: this.category1.value || 0,
      category2: this.category2.value || 0,
      category3: this.category3.value || 0,
      category4: this.category4.value || 0,
      dead: this.dead.value || 0,
    };
  }
}
