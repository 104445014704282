import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ComponentQualificationService } from 'src/app/misc/component-qualification.service';
import { NatsConnectionStatus, NatsConnectorService } from '../services/nats/nats-connector.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-nats-status',
  standalone: true,
  imports: [MatCardModule, MatProgressSpinnerModule, NgClass, NgIf, AsyncPipe, MatTooltipModule],
  templateUrl: './nats-status.component.html',
  styleUrl: './nats-status.component.scss',
})
export class NatsStatusComponent {
  private destroyRef = inject(DestroyRef);
  public componentService = inject(ComponentQualificationService);

  showStatus = false;

  statusColor: Record<string, boolean> = { gray: true };
  statusText = 'Status unbekannt';
  spinner = false;

  statusTooltip = 'Status unbekannt.';

  constructor(private natsConnectorSerivce: NatsConnectorService) {
    natsConnectorSerivce.natsConnectionStatus$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status) => {
      this.setCurrentState(status);
    });
  }

  private setCurrentState(status: NatsConnectionStatus) {
    this.showStatus = status !== NatsConnectionStatus.CONNECTED && status !== NatsConnectionStatus.UNKNOWN;

    this.statusColor = {
      gray: status === NatsConnectionStatus.UNKNOWN,
      green: status === NatsConnectionStatus.CONNECTED,
      orange: status === NatsConnectionStatus.CONNECTING,
      red: status === NatsConnectionStatus.ERROR,
    };

    this.spinner = status === NatsConnectionStatus.CONNECTING;
    this.statusText = status;

    this.setTooltip(status);
  }

  private setTooltip(status: NatsConnectionStatus) {
    switch (status) {
      case NatsConnectionStatus.CONNECTED:
        this.statusTooltip = 'mit Eventsystem verbunden.';
        break;
      case NatsConnectionStatus.CONNECTING:
        this.statusTooltip = 'Stelle Verbindung zu Eventsystem her ...';
        break;
      case NatsConnectionStatus.ERROR:
        this.statusTooltip = 'Verbindung zu Eventsystem konnte nicht aufgebaut werden. Anwendung neu laden!';
        break;
      default:
        this.statusTooltip = 'Status des Eventsystems unbekannt!';
    }
  }
}
