import { createSelector } from '@ngrx/store';
import { AppStateInterface, bibliothek } from 'src/app/+state/appState.interface';
import { VerwaltungsebeneDTO } from 'src/app/api/build/openapi';

export const selectFeature = (appState: AppStateInterface) => appState[bibliothek];

export const bibliothekErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);

export const isLoadingSelector = createSelector(selectFeature, (state) => state.isLoading);
export const isSavingSelector = createSelector(selectFeature, (state) => state.isSaving);
export const isDeletingSelector = createSelector(selectFeature, (state) => state.isDeleting);

export const verwaltungsebenenSelector = createSelector(selectFeature, (state) => state.verwaltungsebenen);

export const verwaltungsebeneIdSelector = (verwaltungsebeneId: string) =>
  createSelector(selectFeature, (state) => {
    if (verwaltungsebeneId) {
      const verwaltungsebenen = findVerwaltungsebene(verwaltungsebeneId, state.verwaltungsebenen);

      return verwaltungsebenen?.length === 1 ? verwaltungsebenen[0] : undefined;
    } else {
      return undefined;
    }
  });

/**
 * Holt die Verwaltungsebene mit der übergebenen ID oder alle Verwaltungsenenen.
 * Gibt das Ergebnis in einem Array zurück
 *
 * @param verwaltungsebeneId
 * @returns
 */
export const verwaltungsebenenIdSelector = (verwaltungsebeneId: string | undefined) =>
  createSelector(selectFeature, (state) => {
    if (verwaltungsebeneId) {
      return findVerwaltungsebene(verwaltungsebeneId, state.verwaltungsebenen);
    } else {
      return state.verwaltungsebenen;
    }
  });

const findVerwaltungsebene = (
  id: string,
  verwaltungsebenen: VerwaltungsebeneDTO[]
): VerwaltungsebeneDTO[] | undefined => {
  const result = verwaltungsebenen.find((v) => v.id === id);

  if (!result) {
    for (const v of verwaltungsebenen) {
      if (v.verwaltungsebenen && v.verwaltungsebenen.length > 0) {
        const opPesult = findVerwaltungsebene(id, v.verwaltungsebenen);

        if (opPesult) {
          return opPesult;
        }
      }
    }
  }

  return result ? [result] : undefined;
};

// #########################################
// Fahrzeuge
// #########################################

export const fahrzeugeWithVerwaltungsebeneSelector = createSelector(selectFeature, (state) =>
  state.fahrzeuge.filter((f) => f.verwaltungsebeneId !== undefined && f.verwaltungsebeneId !== '')
);

export const fahrzeugeWithVerwaltungsebeneIdSelector = (verwaltungsebeneId: string) =>
  createSelector(selectFeature, (state) => state.fahrzeuge.filter((f) => f.verwaltungsebeneId === verwaltungsebeneId));

/**
 * Liefert Fahrzeuge, die eine der übergebenen Verwaltungseben-IDs haben.
 *
 * @param verwaltungsebeneIds IDs von Verwaltungsebenen
 * @returns
 */
export const fahrzeugeWithAnyVerwaltungsebeneIdSelector = (verwaltungsebeneIds: string[]) =>
  createSelector(selectFeature, (state) =>
    state.fahrzeuge.filter((f) => verwaltungsebeneIds.some((id) => id === f.verwaltungsebeneId))
  );
// #########################################
// Personen
// #########################################

export const personenWithVerwaltungsebeneSelector = createSelector(selectFeature, (state) =>
  state.personen.filter((p) => p.verwaltungsebeneId !== undefined && p.verwaltungsebeneId !== '')
);

export const personenWithVerwaltungsebeneIdSelector = (verwaltungsebeneId: string) =>
  createSelector(selectFeature, (state) => state.personen.filter((f) => f.verwaltungsebeneId === verwaltungsebeneId));

/**
 * Liefert Personen, die eine der übergebenen Verwaltungseben-IDs haben.
 *
 * @param verwaltungsebeneIds IDs von Verwaltungsebenen
 * @returns
 */
export const personenWithAnyVerwaltungsebeneIdSelector = (verwaltungsebeneIds: string[]) =>
  createSelector(selectFeature, (state) =>
    state.personen.filter((p) => verwaltungsebeneIds.some((id) => id === p.verwaltungsebeneId))
  );

// #########################################
// TaktischeFormationen
// #########################################

export const taktischeFormationenWithVerwaltungsebeneSelector = createSelector(selectFeature, (state) =>
  state.taktischeFormationen.filter((tf) => tf.verwaltungsebeneId !== undefined && tf.verwaltungsebeneId !== '')
);

export const taktischeFormationenWithVerwaltungsebeneIdSelector = (verwaltungsebeneId: string) =>
  createSelector(selectFeature, (state) =>
    state.taktischeFormationen.filter((tf) => tf.verwaltungsebeneId === verwaltungsebeneId)
  );

/**
 * Liefert Taktische Formationen, die eine der übergebenen Verwaltungseben-IDs haben.
 *
 * @param verwaltungsebeneIds IDs von Verwaltungsebenen
 * @returns
 */
export const taktischeFormationenWithAnyVerwaltungsebeneIdSelector = (verwaltungsebeneIds: string[]) =>
  createSelector(selectFeature, (state) =>
    state.taktischeFormationen.filter((tf) => verwaltungsebeneIds.some((id) => id === tf.verwaltungsebeneId))
  );
