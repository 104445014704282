import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuftragDTO, BefehlTyp } from 'src/app/api/build/openapi';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';

export interface DialogData {
  newAuftrag: boolean;
  auftragToEdit?: AuftragDTO;
}

@Component({
  selector: 'app-auftrag-details-dialog',
  templateUrl: './auftrag-details-dialog.component.html',
  styleUrls: ['./auftrag-details-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatRadioModule,
    MatCheckboxModule,
    CleanableFormFieldComponent,
  ],
})
export class AuftragDetailsDialogComponent implements OnInit {
  fuehrungsebeneId!: string;
  lageId!: string;
  auftragToEdit?: AuftragDTO;

  befehlTyp = BefehlTyp;

  selectedBefehltyp: BefehlTyp = BefehlTyp.Befehl;

  // allgemein
  readonly name = 'name';
  readonly erledigt = 'erledigt';

  fcName = new FormControl<string>('', Validators.required);
  fcErledigt = new FormControl<boolean>(false);
  generalFG = new FormGroup({ [this.name]: this.fcName, [this.erledigt]: this.fcErledigt });

  // (Kurz-) Befehl
  readonly einheit = 'einheit';
  readonly auftragName = 'auftrag';
  readonly mittel = 'mittel';
  readonly ziel = 'ziel';
  readonly weg = 'weg';

  fcEinheit = new FormControl<string | null>('', Validators.required);
  fcAuftrag = new FormControl<string | null>('', Validators.required);
  fcMittel = new FormControl<string | null>('');
  fcZiel = new FormControl<string | null>('');
  fcWeg = new FormControl<string | null>('');

  shortOrderFG = new FormGroup({
    [this.einheit]: this.fcEinheit,
    [this.auftragName]: this.fcAuftrag,
    [this.mittel]: this.fcMittel,
    [this.ziel]: this.fcZiel,
    [this.weg]: this.fcWeg,
  });

  // "Langzeitbefehl"
  readonly lage = 'lage';
  readonly auftragLong = 'auftragLong';
  readonly durchfuehrung = 'durchfuehrung';
  readonly versorgung = 'versorgung';
  readonly fuehrungUndKommando = 'fuehrungUndKommando';

  fcLage = new FormControl<string | null>('');
  fcAuftragLong = new FormControl<string | null>('');
  fcDurchfuehrung = new FormControl<string | null>('');
  fcVersorgung = new FormControl<string | null>('');
  fcFuehrungUndKommando = new FormControl<string | null>('');

  longOrderFG = new FormGroup({
    [this.lage]: this.fcLage,
    [this.auftragLong]: this.fcAuftragLong,
    [this.durchfuehrung]: this.fcDurchfuehrung,
    [this.versorgung]: this.fcVersorgung,
    [this.fuehrungUndKommando]: this.fcFuehrungUndKommando,
  });

  constructor(
    public dialogRef: MatDialogRef<AuftragDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData
  ) {}

  ngOnInit() {
    if (this.data.auftragToEdit) {
      this.auftragToEdit = this.data.auftragToEdit;
      this.setFormForAuftrag(this.auftragToEdit);
      this.selectedBefehltyp = this.auftragToEdit.befehlTyp || BefehlTyp.Befehl;
    }
  }

  private setFormForAuftrag(auftrag: AuftragDTO) {
    this.fcName.setValue(auftrag.name || '');
    this.fcErledigt.setValue(auftrag.erledigt || false);

    if (auftrag.befehlTyp === BefehlTyp.Befehl) {
      this.fcEinheit.setValue(auftrag.einheit || '');
      this.fcAuftrag.setValue(auftrag.auftrag || '');
      this.fcMittel.setValue(auftrag.mittel || '');
      this.fcZiel.setValue(auftrag.ziel || '');
      this.fcWeg.setValue(auftrag.weg || '');
    } else {
      this.fcLage.setValue(auftrag.lageText || '');
      this.fcAuftragLong.setValue(auftrag.auftrag || '');
      this.fcDurchfuehrung.setValue(auftrag.durchfuehrung || '');
      this.fcVersorgung.setValue(auftrag.versorgung || '');
      this.fcFuehrungUndKommando.setValue(auftrag.fuehrungUndKommando || '');
    }
  }

  private toDTO(): AuftragDTO {
    // TODO Bearbeitungsmodus

    if (this.selectedBefehltyp == BefehlTyp.Befehl) {
      return this.toBefehl();
    } else {
      return this.toLangzeitBefehl();
    }
  }

  private toBefehl(): AuftragDTO {
    return {
      id: this.auftragToEdit?.id,
      version: this.auftragToEdit?.version,
      erledigt: this.fcErledigt.value || false,
      name: this.fcName.value || '',
      befehlTyp: BefehlTyp.Befehl,
      einheit: this.fcEinheit.value || undefined,
      auftrag: this.fcAuftrag.value || '',
      mittel: this.fcMittel.value || undefined,
      ziel: this.fcZiel.value || undefined,
      weg: this.fcZiel.value || undefined,
    };
  }

  private toLangzeitBefehl(): AuftragDTO {
    return {
      id: this.auftragToEdit?.id,
      version: this.auftragToEdit?.version,
      erledigt: this.fcErledigt.value || false,
      name: this.fcName.value || '',
      befehlTyp: BefehlTyp.Langzeitbefehl,
      lageText: this.fcLage.value || undefined,
      auftrag: this.fcAuftragLong.value || '',
      durchfuehrung: this.fcDurchfuehrung.value || undefined,
      versorgung: this.fcVersorgung.value || undefined,
      fuehrungUndKommando: this.fcFuehrungUndKommando.value || undefined,
    };
  }

  isInvalid(): boolean {
    if (this.selectedBefehltyp == BefehlTyp.Befehl) {
      return this.generalFG.invalid || this.shortOrderFG.invalid;
    } else {
      return this.generalFG.invalid || this.longOrderFG.invalid;
    }
  }

  save() {
    if (this.isInvalid()) {
      this.generalFG.markAllAsTouched();
      this.shortOrderFG.markAllAsTouched();
      this.longOrderFG.markAllAsTouched();
      return;
    }

    const auftrag: AuftragDTO = this.toDTO();
    this.dialogRef.close(auftrag);
  }
}
