import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorService } from '@product/ise-error-lib';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import {
  BefehlsstelleDTO,
  Einsatzweise,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischeFormationNestedDTO,
  TaktischeFormationSelectableInfo,
  TaktischeFormationSelectableInfoType,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { AuftragListComponent } from 'src/app/lagedarstellung/auftrag/auftrag-list/auftrag-list.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { TzComboboxComponent } from 'src/app/shared/tz-combobox/tz-combobox.component';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import {
  Einheit,
  EinheitId,
  Fachaufgabe,
  GrundzeichenId,
  Organisation,
  Symbol,
  TaktischesZeichen,
  Verwaltungsstufe,
  einheiten,
  erzeugeTaktischesZeichen,
  fachaufgaben,
  organisationen,
  symbole,
  verwaltungsstufen,
} from 'taktische-zeichen-core';
import { DataUrlChangeEvent } from '../../taktische-zeichen-dialog/taktische-zeichen-dialog.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';
import {
  TaktischeFormationDialogComponent,
  TaktischeFormationDialogInputData,
  TaktischeFormationDialogOrigin,
} from '../taktische-formation-dialog/taktische-formation-dialog.component';
import { TaktischeFormationService } from '../taktische-formation.service';

@Component({
  selector: 'app-taktische-formation-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    KontaktListComponent,
    AuftragListComponent,
    CleanableFormFieldComponent,
    TzComboboxComponent,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatRadioModule,
  ],
  templateUrl: './taktische-formation-form.component.html',
  styleUrls: ['./taktische-formation-form.component.scss'],
})
export class TaktischeFormationFormComponent implements TaktischesZeichenForm {
  @ViewChild(KontaktListComponent)
  kontaktList!: KontaktListComponent;

  @ViewChild(AuftragListComponent)
  auftragList!: AuftragListComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  dataUrlChanged = new EventEmitter<DataUrlChangeEvent>();

  dtoToEdit?: TaktischeFormationNestedDTO;

  Einsatzweise = Einsatzweise;

  readonly EMPTY_ZEICHEN: TaktischesZeichen = { grundzeichen: 'taktische-formation' };
  editorZeichen: TaktischesZeichen = { ...this.EMPTY_ZEICHEN };
  currentZeichenDataUrl: string = erzeugeTaktischesZeichen(this.editorZeichen).dataUrl;
  customZeichen = false;

  // Dropdown-Werte
  organisationValues: Organisation[] = organisationen.sort(compareLabels);
  fachaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  groessenordnungValues: Einheit[] = einheiten.sort(compareLabels);
  verwaltungsstufeValues: Verwaltungsstufe[] = verwaltungsstufen.sort(compareLabels);
  symbolValues: Symbol[] = symbole.sort(compareLabels);

  einheitsfuehrerValues$: Observable<PersonDTO[]> = of([]);
  fuehrungseinheitValues$: Observable<TaktischeFormationDTO[]> = of([]);
  befehlsstelleValues$: Observable<BefehlsstelleDTO[]> = of([]);

  organisationMouseover = false;
  fachaufgabeMouseover = false;
  groessenordnungMouseover = false;
  verwaltungsstufeMouseover = false;
  symbolMouseover = false;
  einheitsfuehrerMouseover = false;
  fuehrungseinheitMouseover = false;
  befehlsstelleMouseover = false;
  mannschaftsstaerkeMousoever = false;
  summeFahrzeugeMouseover = false;
  summeTaktischeFormationenMouseover = false;

  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private taktischeFormationService = inject(TaktischeFormationService);
  private errorService = inject(ErrorService);

  private formBuilder = inject(NonNullableFormBuilder);

  fcAnzeigename = this.formBuilder.control('', [Validators.maxLength(30), Validators.required]);
  fcGrundzeichenId = this.formBuilder.control<GrundzeichenId>('taktische-formation');
  fcFachaufgabe = this.formBuilder.control<Fachaufgabe | undefined>(undefined);
  fcOrganisation = this.formBuilder.control<Organisation | undefined>(undefined);
  fcGroessenordnung = this.formBuilder.control<Einheit | undefined>(undefined);
  fcVerwaltungsstufe = this.formBuilder.control<Verwaltungsstufe | undefined>(undefined);
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [
    Validators.maxLength(255),
    Validators.pattern('[a-zA-Z0-9-/]*'),
  ]);
  fcZeichenTextName = this.formBuilder.control<string | undefined>(undefined, [
    Validators.maxLength(255),
    Validators.pattern('[a-zA-Z0-9-/]*'),
  ]);
  fcTextOrganisation = this.formBuilder.control<string | undefined>(undefined, [
    Validators.maxLength(255),
    Validators.pattern('[a-zA-Z0-9-/]*'),
  ]);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcEinheitsfuehrer = this.formBuilder.control<PersonDTO | undefined>(undefined);
  fcFuehrungseinheit = this.formBuilder.control<TaktischeFormationDTO | undefined>(undefined);
  fcBefehlsstelle = this.formBuilder.control<BefehlsstelleDTO | undefined>(undefined);
  fcMannschaftsstaerke1 = this.formBuilder.control<number>(0, [Validators.min(0)]);
  fcMannschaftsstaerke2 = this.formBuilder.control<number>(0, [Validators.min(0)]);
  fcMannschaftsstaerke3 = this.formBuilder.control<number>(0, [Validators.min(0)]);
  fcSummeFahrzeuge = this.formBuilder.control<number>(0, [Validators.min(0)]);
  fcSummeTaktischeFormationen = this.formBuilder.control<number>(0, [Validators.min(0)]);
  fcEinsatzweise = this.formBuilder.control<Einsatzweise>(Einsatzweise.Geschlossen);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    grundzeichen: this.fcGrundzeichenId,
    fachaufgabe: this.fcFachaufgabe,
    organisation: this.fcOrganisation,
    groessenordnung: this.fcGroessenordnung,
    verwaltungsstufe: this.fcVerwaltungsstufe,
    symbol: this.fcSymbol,
    zeichenText: this.fcZeichenText,
    zeichenTextName: this.fcZeichenTextName,
    zeichenTextOrganisation: this.fcTextOrganisation,
    ortsangabe: this.fcOrtsangabe,
    einheitsfuehrer: this.fcEinheitsfuehrer,
    fuehrungseinheit: this.fcFuehrungseinheit,
    befehlsstelle: this.fcBefehlsstelle,
    mannschaftsstaerke1: this.fcMannschaftsstaerke1,
    mannschaftsstaerke2: this.fcMannschaftsstaerke2,
    mannschaftsstaerke3: this.fcMannschaftsstaerke3,
    summeFahrzeuge: this.fcSummeFahrzeuge,
    summeTaktischeFormationen: this.fcSummeTaktischeFormationen,
    einsatzweise: this.fcEinsatzweise,
  });

  readonly mannschaftsstarkeMap = new Map<EinheitId, number[]>([
    ['trupp', [0, 0, 3]],
    ['zugtrupp', [0, 1, 2]],
    ['staffel', [0, 1, 5]],
    ['gruppe', [0, 1, 8]],
    ['zug', [1, 3, 18]],
    ['bereitschaft', [4, 10, 59]],
    ['abteilung', [20, 30, 177]],
    ['grossverband', [68, 90, 531]],
  ]);

  private dialog = inject(MatDialog);

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });

    this.fcFachaufgabe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.fachaufgabe = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.organisation = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcGroessenordnung.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.einheit = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcGroessenordnung.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (v && v.id) {
        if (!this.customZeichen) {
          this.editorZeichen.einheit = v.id;
        }
        this.setMannschaftsstaerke(this.mannschaftsstarkeMap.get(v.id));
      }

      if (!this.customZeichen) {
        this.generateTaktischesZeichen();
      }
    });

    this.fcVerwaltungsstufe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.verwaltungsstufe = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen) {
        this.editorZeichen.symbol = v?.id;
        this.generateTaktischesZeichen();
      }
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen && this.fcZeichenText.valid) {
        this.editorZeichen.text = v?.trim();
        this.generateTaktischesZeichen();
      }
    });

    this.fcZeichenTextName.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen && this.fcZeichenTextName.valid) {
        this.editorZeichen.name = v?.trim();
        this.generateTaktischesZeichen();
      }
    });

    this.fcTextOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (!this.customZeichen && this.fcTextOrganisation.valid) {
        this.editorZeichen.organisationName = v?.trim();
        this.generateTaktischesZeichen();
      }
    });
  }

  setCustomTz(dataUrl: string) {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
      this.customZeichen = true;
    } else {
      this.customZeichen = false;
      this.generateTaktischesZeichen();
    }
  }

  setDTO(dto: TaktischesZeichenDTO) {
    this.dtoToEdit = dto as TaktischeFormationNestedDTO;
    if (this.dtoToEdit) {
      const selectableInfo: TaktischeFormationSelectableInfo = {
        type: this.dtoToEdit.id
          ? TaktischeFormationSelectableInfoType.Existing
          : this.fuehrungsebeneService.getCurrentLage()
          ? TaktischeFormationSelectableInfoType.NewForLage
          : TaktischeFormationSelectableInfoType.NewForBib,
        lageId: this.fuehrungsebeneService.getCurrentLage()?.id,
        taktischeFormationId: this.dtoToEdit.id,
      };

      this.einheitsfuehrerValues$ = this.taktischeFormationService.getSelectablePersonen(selectableInfo);
      this.fuehrungseinheitValues$ = this.taktischeFormationService.getSelectableTaktischeFormationen(selectableInfo);
      this.befehlsstelleValues$ = this.taktischeFormationService.getSelectableBefehlsstellen(selectableInfo);

      this.dtoToFormGroup(this.dtoToEdit);

      if (this.dtoToEdit.customZeichen && this.dtoToEdit.dataUrl) {
        this.generateTaktischesZeichen(this.dtoToEdit.dataUrl);
        return;
      }
    }
    this.generateTaktischesZeichen();
  }

  /**
   * Aktualisiertes StelleDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): TaktischeFormationDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in DTO schreiben und zurückliefern
   */
  formGroupToDto(): TaktischeFormationDTO {
    return {
      ...this.dtoToEdit,
      anzeigename: this.fcAnzeigename.value.trim(),
      grundzeichen: this.fcGrundzeichenId.value,
      organisation: this.fcOrganisation.value?.id,
      fachaufgabe: this.fcFachaufgabe.value?.id,
      groessenordnung: this.fcGroessenordnung.value?.id,
      mannschaftsstaerke1: this.fcMannschaftsstaerke1.value,
      mannschaftsstaerke2: this.fcMannschaftsstaerke2.value,
      mannschaftsstaerke3: this.fcMannschaftsstaerke3.value,
      summeFahrzeuge: this.fcSummeFahrzeuge.value,
      summeTaktischeFormationen: this.fcSummeTaktischeFormationen.value,
      verwaltungsstufe: this.fcVerwaltungsstufe.value?.id,
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      zeichenTextName: this.fcZeichenTextName.value?.trim(),
      zeichenTextOrganisation: this.fcTextOrganisation.value?.trim(),
      ortsangabe: this.fcOrtsangabe.value?.trim(),
      einheitsfuehrerId: this.fcEinheitsfuehrer.value?.id,
      fuehrungseinheitId: this.fcFuehrungseinheit.value?.id,
      befehlsstelleId: this.fcBefehlsstelle.value?.id,
      dataUrl: this.currentZeichenDataUrl,
      customZeichen: this.customZeichen,
      kommunikationOptionen: this.kontaktList.getKommunikationOptionen(),
      auftraege: this.auftragList.getAuftraege(),
      typ: TaktischesZeichenTyp.TaktischeFormation,
      einsatzweise: this.fcEinsatzweise.value,
    };
  }

  /**
   * FormGroup mit Werten aus DTO füllen
   */
  dtoToFormGroup(dto: TaktischeFormationNestedDTO): void {
    this.fcAnzeigename.setValue(dto.anzeigename);
    this.fcGrundzeichenId.setValue((dto.grundzeichen as GrundzeichenId) || 'taktische-formation');
    this.fcOrganisation.setValue(organisationen.find((v) => v.id === dto.organisation));
    this.fcFachaufgabe.setValue(fachaufgaben.find((v) => v.id === dto.fachaufgabe));
    this.fcGroessenordnung.setValue(einheiten.find((v) => v.id === dto.groessenordnung));
    this.fcVerwaltungsstufe.setValue(verwaltungsstufen.find((v) => v.id === dto.verwaltungsstufe));
    this.fcSymbol.setValue(symbole.find((v) => v.id === dto.symbol));
    this.fcZeichenText.setValue(dto.zeichenText);
    this.fcZeichenTextName.setValue(dto.zeichenTextName);
    this.fcTextOrganisation.setValue(dto.zeichenTextOrganisation);
    this.fcOrtsangabe.setValue(dto.ortsangabe);
    this.fcEinheitsfuehrer.setValue(dto.einheitsfuehrer);
    this.fcFuehrungseinheit.setValue(dto.fuehrungseinheit);
    this.fcBefehlsstelle.setValue(dto.befehlsstelle);
    this.fcMannschaftsstaerke1.setValue(dto.mannschaftsstaerke1 || 0);
    this.fcMannschaftsstaerke2.setValue(dto.mannschaftsstaerke2 || 0);
    this.fcMannschaftsstaerke3.setValue(dto.mannschaftsstaerke3 || 0);
    this.fcSummeFahrzeuge.setValue(dto.summeFahrzeuge);
    this.fcSummeTaktischeFormationen.setValue(dto.summeTaktischeFormationen);
    this.auftragList.setAuftraege(dto.auftraege || []);
    this.customZeichen = dto.customZeichen || false;
    this.fcEinsatzweise.setValue(dto.einsatzweise || Einsatzweise.Geschlossen);
  }

  /**
   * DataURL an Parent senden und dort aktualisieren.
   */
  generateTaktischesZeichen(dataUrl = '') {
    if (dataUrl) {
      this.currentZeichenDataUrl = dataUrl;
    } else {
      const zeichen = erzeugeTaktischesZeichen(this.editorZeichen);
      this.currentZeichenDataUrl = zeichen.dataUrl;
    }
    this.dataUrlChanged.emit({ dataUrl: this.currentZeichenDataUrl, customZeichen: this.customZeichen });
  }

  openTaktischeFormationHierarchie() {
    if (!this.dtoToEdit?.id) {
      return;
    }
    const dialogData: TaktischeFormationDialogInputData = {
      taktischeFormationNestedDTO: this.dtoToEdit,
      origin: TaktischeFormationDialogOrigin.LAGE,
    };
    this.dialog
      .open(TaktischeFormationDialogComponent, { data: dialogData })
      .afterClosed()
      .pipe(
        switchMap(() => {
          if (!this.dtoToEdit?.id) {
            return EMPTY;
          }
          return this.taktischeFormationService.getTaktischeFormationNested(this.dtoToEdit.id);
        })
      )
      .subscribe((taktischeFormationNested) => {
        if (taktischeFormationNested) {
          this.setDTO(taktischeFormationNested);
        }
      });
  }

  /**
   * Felder für Mannschaftsstärke zurücksetzen
   */
  resetMannschaftsstaerke() {
    this.fcMannschaftsstaerke1.setValue(0);
    this.fcMannschaftsstaerke2.setValue(0);
    this.fcMannschaftsstaerke3.setValue(0);
  }

  private setMannschaftsstaerke(mannschaftsstaerke: number[] | undefined) {
    if (!mannschaftsstaerke) return;

    this.fcMannschaftsstaerke1.setValue(mannschaftsstaerke[0]);
    this.fcMannschaftsstaerke2.setValue(mannschaftsstaerke[1]);
    this.fcMannschaftsstaerke3.setValue(mannschaftsstaerke[2]);
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
