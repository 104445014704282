<span class="lagezeit">
  <h2>Lagezeit</h2>

  <!-- Anzeige der Lagezeit -->
  <ng-container *ngIf="!editingLagezeit">
    <h3
      [class]="lagezeitClass$ | async"
      (mouseenter)="openLegende = true"
      (mouseleave)="openLegende = false"
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
    >
      {{ lagezeit | dateTime | async }}
    </h3>
    <button mat-icon-button (click)="editLagezeit()" matTooltip="Lagezeit bearbeiten">
      <mat-icon>edit</mat-icon>
    </button>

    <ng-template
      #cdkOverlayOrigin
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="openLegende"
    >
      <mat-card class="legend">
        <table>
          <tr>
            <td class="legende-farbfeld lagezeit-yellow"></td>
            <td>Älter als eine Stunde</td>
          </tr>
          <tr>
            <td class="legende-farbfeld lagezeit-orange"></td>
            <td>Älter als zwei Stunden</td>
          </tr>
          <tr>
            <td class="legende-farbfeld lagezeit-red"></td>
            <td>Älter als drei Stunden</td>
          </tr>
        </table>
      </mat-card>
    </ng-template>
  </ng-container>

  <!-- Editor für Lagezeit -->
  <ng-container *ngIf="editingLagezeit">
    <mat-form-field class="less-margin">
      <mat-label>Neue Lagezeit</mat-label>
      <input matInput type="datetime-local" [formControl]="fcLagezeit" dateTimeLocalAsDate />
      <mat-error>{{ getErrorMessage(fcLagezeit) }}</mat-error>
    </mat-form-field>

    <!-- Buttons: Speichern, Abbrechen -->
    <span>
      <button mat-icon-button (click)="saveLagezeit()" matTooltip="Lagezeit speichern">
        <mat-icon>done</mat-icon>
      </button>
      <button mat-icon-button (click)="cancelEditLagezeit()" matTooltip="Abbrechen">
        <mat-icon>close</mat-icon>
      </button>
    </span>
  </ng-container>
</span>
