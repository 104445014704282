import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AuftragDTO } from 'src/app/api/build/openapi';

@Component({
  selector: 'app-auftrag-header-element',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule],
  templateUrl: './auftrag-header-element.component.html',
  styleUrls: ['./auftrag-header-element.component.scss'],
})
export class AuftragHeaderElementComponent implements OnChanges {
  @Input()
  auftraege: AuftragDTO[] = [];

  auftraegeErledigtCount = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['auftraege']) {
      this.auftraegeErledigtCount = this.auftraege.filter((auftrag) => auftrag.erledigt).length;
    }
  }
}
