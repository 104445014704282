<form [formGroup]="massnahmeForm">
  <mat-toolbar>
    <h2>Maßnahme {{ isEdit ? "bearbeiten" : "hinzufügen" }}</h2>
  </mat-toolbar>
  <mat-dialog-content class="grid-container">
    <mat-card class="grid-prio">
      <div class="prio" *ngIf="this.massnahmeForm.value.prioritaet">
        <div
          class="prio-circle"
          [style]="'background-color:' + prioMapping.get(this.massnahmeForm.value.prioritaet)?.farbe"
        >
          <mat-icon>{{ prioMapping.get(this.massnahmeForm.value.prioritaet)?.icon }}</mat-icon>
        </div>

        <mat-form-field>
          <mat-label>Priorität</mat-label>
          <mat-select formControlName="prioritaet">
            <mat-option [value]="Prioritaet.Blitz">
              <mat-icon>flash_on</mat-icon>
              Blitz
            </mat-option>
            <mat-option [value]="Prioritaet.Sofort">
              <mat-icon>arrow_upward</mat-icon>
              Sofort
            </mat-option>
            <mat-option [value]="Prioritaet.Normal">
              <mat-icon>format_align_justify</mat-icon>
              Normal
            </mat-option>
            <mat-option [value]="Prioritaet.Zurueckgestellt">
              <mat-icon>arrow_downward</mat-icon>
              Zurückgestellt
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="erledigt">
        <input class="example-margin" type="checkbox" formControlName="erledigt" />
        <p>erledigt</p>
      </div>
    </mat-card>

    <mat-card class="grid-person-zuerledigen">
      <h3>Erledigen durch</h3>
      <mat-form-field class="textfield-100">
        <mat-label>Sachgebiet</mat-label>
        <mat-select formControlName="zuErledigenSachgebiet">
          <mat-option [value]="Sachgebiet.S1">S1</mat-option>
          <mat-option [value]="Sachgebiet.S2">S2</mat-option>
          <mat-option [value]="Sachgebiet.S3">S3</mat-option>
          <mat-option [value]="Sachgebiet.S4">S4</mat-option>
          <mat-option [value]="Sachgebiet.S5">S5</mat-option>
          <mat-option [value]="Sachgebiet.S6">S6</mat-option>
          <mat-option [value]="Sachgebiet.Fernmeldedienst">Fernmeldedienst</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="textfield-100" appearance="outline">
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="zuErledigenDatum" />
      </mat-form-field>
    </mat-card>

    <mat-card class="grid-person-biswann">
      <h3>Erledigen bis</h3>
      <mat-form-field class="textfield-100">
        <mat-label>Rolle</mat-label>
        <mat-select formControlName="bisWannRolle">
          <mat-option [value]="role.value" *ngFor="let role of Rolle | keyvalue">{{
            roleTextMapping.get(role.value)
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="textfield-100">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="bisWannName" />
      </mat-form-field>
      <mat-form-field class="textfield-100" appearance="outline">
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="bisWannDatum" />
        <mat-error>Bitte gültiges Datum eingeben</mat-error>
      </mat-form-field>
    </mat-card>

    <mat-card class="grid-massnahme">
      <mat-form-field class="aufgabe-text-area">
        <mat-label>Aufgaben, Benachrichtigungen, Notizen</mat-label>
        <textarea matInput cdkTextareaAutosize formControlName="text"></textarea>
      </mat-form-field>
    </mat-card>

    <mat-card class="grid-person-erledigtdurch">
      <h3>Erledigt durch</h3>
      <mat-form-field class="textfield-100">
        <mat-label>Rolle</mat-label>
        <mat-select formControlName="erledigtDurchRolle" [disabled]="true">
          <mat-option [value]="role.value" *ngFor="let role of Rolle | keyvalue">{{
            roleTextMapping.get(role.value)
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="textfield-100">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="erledigtDurchName" />
      </mat-form-field>
      <mat-form-field class="textfield-100" appearance="outline">
        <mat-label>Datum/Uhrzeit</mat-label>
        <input matInput type="datetime-local" formControlName="erledigtDurchDatum" />
      </mat-form-field>
    </mat-card>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button mat-d (click)="onClickAbbrechen()">Abbrechen</button>
    <button mat-raised-button mat-d color="accent" (click)="onClickSpeichern()">Speichern</button>
  </mat-dialog-actions>
</form>
