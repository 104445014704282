import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AnlassDTO, ErrorResponse } from 'src/app/api/build/openapi';

export const anlassActions = createActionGroup({
  source: 'Anlaesse',
  events: {
    'Reset Store': emptyProps(),

    'Get Anlaesse': props<{ lageId: string }>(),
    'Get Anlaesse Success': props<{ anlassDTOs: AnlassDTO[]; lageId: string }>(),
    'Get Anlaesse Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Anlass': props<{ lageId: string; anlassDTO: AnlassDTO }>(),
    'Create Anlass Success': props<{ createdAnlassDTO: AnlassDTO }>(),
    'Create Anlass Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Anlass': props<{ lageId: string; anlassDTO: AnlassDTO }>(),
    'Patch Anlass Success': props<{ patchedAnlassDTO: AnlassDTO }>(),
    'Patch Anlass Failure': props<{ errorResponse: ErrorResponse }>(),

    'Delete Anlass': props<{ lageId: string; anlassDTO: AnlassDTO }>(),
    'Delete Anlass Success': props<{ deletedAnlassDTO: AnlassDTO }>(),
    'Delete Anlass Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
