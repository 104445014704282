import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FuehrungsebeneDTO, GebaeudeDTO } from 'src/app/api/build/openapi';
import { FuehrungsebeneIconAndTextComponent } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { KontaktIconsComponent } from 'src/app/lagedarstellung/kontakt/kontakt-icons/kontakt-icons.component';
import { TaktischesZeichenPopup } from '../../taktische-zeichen.interface';
import { TzPopupToolbarComponent } from '../../tz-popup-toolbar/tz-popup-toolbar.component';

@Component({
  selector: 'app-gebaeude-popup',
  standalone: true,
  imports: [CommonModule, FuehrungsebeneIconAndTextComponent, KontaktIconsComponent, TzPopupToolbarComponent],
  templateUrl: './gebaeude-popup.component.html',
  styleUrls: ['./gebaeude-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GebaeudePopupComponent implements OnInit, TaktischesZeichenPopup {
  @Input({ required: true }) dto!: GebaeudeDTO;

  private fuehrungsebeneService = inject(FuehrungsebeneService);

  protected parentFuehrungsebene?: FuehrungsebeneDTO;

  ngOnInit(): void {
    this.parentFuehrungsebene = this.fuehrungsebeneService
      .getAllFuehrungsebenen()
      .find((fuehrungsebeneDTO) => fuehrungsebeneDTO.id === this.dto.fuehrungsebeneId);
  }
}
