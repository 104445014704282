<div class="container">
  <mat-toolbar class="header">
    <div class="unerledigt-overlay" *ngIf="this.massnahmenService.getUnerledigt() > 0"></div>

    <h2>Maßnahmen</h2>
    <span>
      <button
        mat-icon-button
        [matTooltip]="'Erledigte Maßnahmen ' + (showErledigt ? 'ausblenden' : 'einblenden')"
        (click)="onClickShowErledigt()"
      >
        <mat-icon>{{ showErledigt ? "visibility" : "visibility_off" }}</mat-icon>
      </button>
      <button class="add-button" mat-icon-button (click)="onClickHinzufuegen()" matTooltip="Maßnahme hinzufügen">
        <mat-icon>add</mat-icon>
      </button>
    </span>
  </mat-toolbar>

  <div class="progress-bar">
    <mat-progress-bar mode="determinate" [value]="massnahmenService.getErledigtProzent()" color="accent" />
    <p>{{ massnahmenService.erledigteMassnahmen }} von {{ massnahmenService.mengeMassnahmen }} erledigt</p>
  </div>

  <div class="aufgaben-liste">
    <app-loadable [isLoading$]="isLoadingMassnahmen$">
      <div
        class="aufgabe"
        *ngFor="let massnahme of massnahmen"
        [ngClass]="{ invisible: massnahme.erledigt && !showErledigt }"
      >
        <mat-toolbar class="aufgabe-menu">
          <button mat-icon-button (click)="onClickBeenden(massnahme)" [disabled]="massnahme.erledigt">
            <mat-icon>check</mat-icon>
          </button>
          <button mat-icon-button (click)="onClickBearbeiten(massnahme)">
            <mat-icon>edit</mat-icon>
          </button>
        </mat-toolbar>

        <div
          class="prio"
          [ngClass]="{
          done: massnahme.erledigt,
          abgelaufen: massnahme.abgelaufen,
        }"
        >
          <div class="circle" [style]="'background-color: ' + this.prioMapping.get(massnahme.prioritaet!)?.farbe">
            <span class="prio-text"
              ><mat-icon>{{ this.prioMapping.get(massnahme.prioritaet!)?.icon }}</mat-icon></span
            >
          </div>
        </div>
        <div class="text">
          <div class="aufgabe-text">{{ massnahme.text }}</div>
          <hr />
          <span class="info-text">Erledigen bis: {{ massnahme.bisWannDatum | dateTime | async }}</span>
        </div>
      </div>
    </app-loadable>
    <h3 class="keine-daten-text" *ngIf="!massnahmen.length">Keine Maßnahmen vorhanden</h3>
    <h3 class="keine-daten-text" *ngIf="!showErledigt && massnahmen.length && !unerledigtMassnahmen.length">
      Alle Maßnahmen erledigt
    </h3>
  </div>
</div>
